import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, Inject, ViewChildren, ElementRef, QueryList, Renderer2 } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';

import { DataTableModel } from '../../../models/shared/cell-data-model';
import { ResponsiveListInlineEditObject, TableAction, TableDeleteMode, TableSelectionMode } from '../../../models/shared/table-action';
import { Utils } from '../../../utils/survey-utils';
import { MenuActionObject, MenuActionEmittedObject } from '../../../models/shared/datatable-objects.model';
import { SelectionEmittedObject } from '../responsive-list-view/responsive-list-view.component';
import { FormControl, FormGroup } from '@angular/forms';
import { TableFilterTypeEnum } from '../../../models/shared/table-filter.model';
import { LookupModel } from '../../../models/shared/lookup.model';
import { LookupService } from '../../../services/lookups/lookup.service';
import { DropdownComparator } from '../component-base/dropdown-comparator';
import { SettingsService } from '../../../shared/services/settings.service';
import { MonthService } from '../../../services/shared/month.service';
import { CustomDataSource } from '../responsive-list-view/custom-data-source';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.sass']
})
export class CardListComponent extends DropdownComparator implements OnInit, OnDestroy  {
  @Input() tableColumns:  DataTableModel<any>[];
  @Input() selectionMode: number = TableSelectionMode.NONE_MODE;
  @Input() withAdd: boolean = false;
  @Input() deleteMode: number = TableDeleteMode.NONE_MODE;
  @Input() maxStringLength: number;
  @Input() showBackButton: boolean = true;
  @Input() inlineEditable: boolean = false;

  @Input() _tableMenuAction: MenuActionObject[];
  @Output() menuActionEventEmitter = new EventEmitter<MenuActionEmittedObject>();

  @Input() tableDataSource : CustomDataSource<any>;
  @Input() isLoading: boolean = false;
  @Input() databaseChangeEventSubject: Observable<CustomDataSource<any>>;
  private databaseChangeEventSubscription: Subscription;

  @Output() mode = new EventEmitter<TableAction>();
  @Output() deleteEventEmitter = new EventEmitter<number>();
  @Output() selectionEventEmitter = new EventEmitter<SelectionEmittedObject>();
  @Input() selection;
  @Input() addButtonLabel: string;
  @Input() tableTitleLabel: string = "";
  @Input() showFilterButton: boolean = false;
  @Input() withInlineMenu: boolean = true;

  public tableDeleteMode: typeof TableDeleteMode = TableDeleteMode;
  public tableSelectionMode: typeof TableSelectionMode = TableSelectionMode;

  @Input() activeInlineEditingRows: Map<number, ResponsiveListInlineEditObject> = new Map<number, ResponsiveListInlineEditObject>();

  displayedColumnsWithAction: string[];
  errorMessage: string = "";
  private elementsWithMenuActions: number[] = [];

  // editInlineButton: MenuActionObject = { name: "Edit", actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg" };
  saveInlineButton: MenuActionObject = { name: "Save", actionName: "SAVE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg" };
  cancelInlineButton: MenuActionObject = { name: "Cancel", actionName: "CANCEL_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg" };

  tableFilterTypeEnum: typeof TableFilterTypeEnum = TableFilterTypeEnum;
  public lookups: Map<string, LookupModel[]> = new Map();
  public lookupObjects: Map<string, LookupModel[]> = new Map();

  filterValue: string;

  constructor(
    private location: Location,
    private lookupService: LookupService,
    private settingsService: SettingsService,
    private renderer: Renderer2,
    private _translateService: TranslateService,
    ){
    super();
  }

  goBack(){
    this.location.back();
  }

  ngOnInit(): void {
    // this.height = window.innerHeight - 550;
    this.displayedColumnsWithAction = Utils.deepClone(this.tableColumns?.map(col => col.columnDef));
    if(this.selectionMode != TableSelectionMode.NONE_MODE) this.displayedColumnsWithAction.unshift("select");
    this.databaseChangeEventSubscription = this.databaseChangeEventSubject.subscribe(tableDataSource => {
      if(tableDataSource.getAdditionalParam()){
        this.getLookups();
      }
    });
    this.filterValue = this.tableDataSource?.filter ? this.tableDataSource?.filter : "";
    this.getLookups();
  }

  ngOnDestroy() {
    if(this.databaseChangeEventSubscription) this.databaseChangeEventSubscription.unsubscribe();
  }

  getLookups(){
    let lookupNames: string[] = [];
    let lookupObjectNames: string[] = [];
    let needsMonthLookup: boolean = false;
    this.tableColumns.forEach(column => {
      if(column.lookupName && column.type && column.type == TableFilterTypeEnum.Lookup ) lookupNames.push(column.lookupName);
      if(column.lookupName && column.type && column.type == TableFilterTypeEnum.LookupObject) lookupObjectNames.push(column.lookupName);
      if(column.lookupName && column.type && column.type == TableFilterTypeEnum.LookupObjectEnumeration) lookupObjectNames.push(column.lookupName);
      if(column.lookupName && column.type && column.type == TableFilterTypeEnum.Month) needsMonthLookup = true;
    });

    if(lookupNames && lookupNames.length > 0){
      this.lookupService.getLookupsService(lookupNames, false, true).subscribe(response => {
        if(response?.body){
          lookupNames.forEach(name => {
            let currLookup: LookupModel[] = response.body[name];
            if(currLookup) this.lookups.set(name, currLookup);
          });
        }
      });
    }

    if(needsMonthLookup) this.lookupObjects.set("Months", MonthService.getMonthsLookupList());
    if(lookupObjectNames && lookupObjectNames.length > 0){
      this.lookupService.getLookupObjectsService(lookupObjectNames, false, true).subscribe(response => {
        if(response?.body){
          lookupObjectNames.forEach(name => {
            let currLookup: LookupModel[] = response.body[name];
            if(currLookup) this.lookupObjects.set(name, currLookup);
          });
        }
      });
    }
  }

  getLookupByName(filterByName: string): LookupModel[]{
    let response: LookupModel[] = [];
    if(this.lookups && filterByName != undefined && this.lookups.size > 0){
      let hasLookup : boolean =false;
      if(this.lookups.size > 0){
        response = this.lookups.get(filterByName);
      }
    }
    return response;
  }
  getLookupObjectByName(filterByName: string): LookupModel[]{
    let response: LookupModel[] = [];
    if(this.lookupObjects?.size > 0){
        response = this.lookupObjects.get(filterByName);
      }
    return response;
  }

  getMonthLookupValueForObject(value: any): string{
    let response: string = undefined;
    if(value != undefined){
      for(let element of MonthService.getMonthsLookupList()){
        if(response != undefined) break;
        if(element.id == value) response = element.value;
      }
    }
    return response;
  }

  getBenefScores(value: any): string {
    if (value && value !== 'undefined') {
      let scores = value.split(",");
      return !scores[0].includes("undefined") ? scores[0] : this._translateService.instant('beneficiary-form.titles.no-score');
    } else {
      return '';
    }
  }

  getBorderColor(value: any): string {
    if (value && value !== 'undefined') {
      let scores = value.split(",");
      return scores[1];
    } else {
      return '';
    }
  }

  // TABLE FILTER
  applyFilter(event: Event) {
    if(this.tableDataSource){
      const filterValue = (event.target as HTMLInputElement).value;
      this.tableDataSource.filter = filterValue.trim().toLowerCase();
      if (this.tableDataSource.paginator) {
        this.tableDataSource.paginator.firstPage();
      }
    }
  }

  add(){
    this.mode.emit({mode: TableAction.CREATE_MODE});
  }

  delete(id: number){
    if(id != undefined) this.deleteEventEmitter.emit(id);
  }

  edit(id: any){
    this.mode.emit({mode: TableAction.EDIT_MODE, id: id});
  }

  popupModalClosed(){
    this.errorMessage = "";
  }

  onScroll(event: any) {
    // if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    //   console.log("End");
    // }
  }

  handleSelection(event: MatCheckboxChange, row: any){
    this.selectionEventEmitter.next(new SelectionEmittedObject(row, event.checked));
  }


  onContextMenuClicked(object: any, action: string,form: FormGroup){
    if(action=="CANCEL_ACTION"){
      this.activeInlineEditingRows.delete(object.id);
      if(object.id < 0){
        let currentIndex = 0;
        let elementIndex = -1;
        for(let element of this.tableDataSource.data){
          if(element.id == object.id) {
            elementIndex = currentIndex;
            break;
          } else currentIndex++;
        }
        if(elementIndex >= 0){
          this.tableDataSource.data.splice(elementIndex, 1);
          this.tableDataSource.data = [...this.tableDataSource.data];
        }
      }
    }else if(object.id && object.id < 0){
      object=this.activeInlineEditingRows.get(object.id).form.getRawValue();
    }

    if(object.id && object.id > 0 && (form != undefined && !form.contains('id'))){
      form.addControl('id', new FormControl({value:  object.id, disabled: true}));
      form.value.id = object.id;
    }

    form == undefined ? this.menuActionEventEmitter.next(new MenuActionEmittedObject(object, action,form)) : this.menuActionEventEmitter.next(new MenuActionEmittedObject(form.getRawValue(), action,form));
  }

  // isAllowedAction(element: any, menuAction: MenuActionObject): boolean{
  //   return element && menuAction && menuAction?.accessRightVariablePath != undefined ? element[menuAction?.accessRightVariablePath] : true;
  // }
  // isAllowedAction(element: any, menuAction: MenuActionObject): boolean{
  //   let allowedAction = element && menuAction && menuAction?.accessRightVariablePath != undefined ? element[menuAction?.accessRightVariablePath] : true;
  //   if(allowedAction) this.elementsWithMenuActions.push(element.id);
  //   return allowedAction;
  // }

  isAllowedAction(element: any, menuAction: MenuActionObject): boolean{
    let allowedAction = Utils.isAllowedAction(element, menuAction);
    if(allowedAction) this.elementsWithMenuActions.push(element.id);
    return allowedAction;
  }

  hasNoMenuElement(element: any){
    return this.elementsWithMenuActions && element && element.id != undefined && !this.elementsWithMenuActions.includes(element.id);
  }

  isCellEditable(elementId: number): boolean{
    let isCellEditable: boolean = false;
    if(this.inlineEditable && this.activeInlineEditingRows != null && (this.activeInlineEditingRows.has(elementId) && !this.activeInlineEditingRows.get(elementId).afterSave)){

      isCellEditable = true;
    }
    return isCellEditable;
  }

  getObjectFormGroup(elementId: number): FormGroup{
    let form: FormGroup;
    if(this.inlineEditable && this.activeInlineEditingRows != null && this.activeInlineEditingRows.has(elementId)){

      form = this.activeInlineEditingRows.get(elementId).form;
    }
    return form;
  }


  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  onKeyDownEvent(event: any){
    if(event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }
}

