import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { TableDeleteMode, TableSelectionMode } from '@siren-survey/app/models/shared/table-action';
import { TableFilterElement, TableFilterTypeEnum, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { SurveyList } from '@siren-survey/app/models/survey/surveyList';
import { SurveyService } from '@siren-survey/app/services/survey.service';
import { ISurveyAuthenticationService } from '@siren-survey/app/shared/services/interfaces/authentication.interface';
import { Subject } from 'rxjs';
import { SurveyTopicInput } from '../survey-link-selection/survey-link-selection.component';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { SurveySelectionService } from 'src/app/services/admin/survey-selection.service';

@Component({
  selector: 'app-survey-selection',
  templateUrl: './survey-selection.component.html',
  styleUrls: ['./survey-selection.component.sass']
})
export class SurveySelectionComponent  extends DropdownComparatorWithBrowserListener implements OnInit {

  @Output()  selectionEventEmitter = new EventEmitter<SurveyTopicInput>();
  public tableDeleteMode: typeof TableDeleteMode = TableDeleteMode;
  public tableSelectionMode: typeof TableSelectionMode = TableSelectionMode;
  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();

  consumerPermissionEnum;
  hasListAccess: boolean = false;
  selectedOrganization: number;
  userActiveOrganizations: LookupModel[] = [];

  public additionalParams : Map<string,any> = new Map<string, any>();

  _tableColumns  : DataTableModel<SurveyList> []= [
    {  columnDef: 'title', headerTranslationPath: "siren-survey-translation.survey-form.fields.title", header: 'Title',  cell: (element: SurveyList) => `${element.title}`, width: undefined},
    {  columnDef: 'description', headerTranslationPath: "siren-survey-translation.survey-form.fields.description", header: 'Description',  cell: (element: SurveyList) => `${element.description}`, width: undefined},
    {  columnDef: 'startDateTime', headerTranslationPath: "siren-survey-translation.survey-form.fields.start-date", header: 'Start Date',  cell: (element: SurveyList) => `${element.startDateTime}`, width: undefined},
    {  columnDef: 'endDateTime', headerTranslationPath: "siren-survey-translation.survey-form.fields.end-date",  header: 'End Date',  cell: (element: SurveyList) => `${element.endDateTime}`, width: undefined},
    {  columnDef: 'surveyType', headerTranslationPath: "siren-survey-translation.survey-form.fields.survey-type", header: 'Survey Type',  cell: (element: SurveyList) => `${element.surveyType.value}`, width: undefined},
    {  columnDef: 'enabled', headerTranslationPath: "siren-survey-translation.survey-form.fields.enabled", header: 'Enabled',  cell: (element: SurveyList) => `${element.enabled}`, width: '100px'},
    {  columnDef: 'published', headerTranslationPath: "siren-survey-translation.survey-form.fields.published", header: 'Published',  cell: (element: SurveyList) => `${element.published}`, width: '100px'},
  ];

  filters: TableFilterElement[] = [
    { name: "title", translationPath: "siren-survey-translation.survey-form.fields.title", displayName: "Title", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like , cssClass: 'col-xs-12 col-sm-6 col-md-4' },
    { name: "description", translationPath: "siren-survey-translation.survey-form.fields.description", displayName: "Description", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "maxGrade", translationPath: "siren-survey-translation.survey-form.fields.max-grade", displayName: "Maximum Grade", type: TableFilterTypeEnum.Number, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "surveyType.id", translationPath: "siren-survey-translation.survey-form.fields.survey-type", displayName: "Survey Type", type: TableFilterTypeEnum.Lookup, lookupName: "SurveyType", lookupsValueById: true, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "enabled", translationPath: "siren-survey-translation.survey-form.fields.enabled", displayName: "Enabled", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "published", translationPath: "siren-survey-translation.survey-form.fields.published", displayName: "Published", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "startDateTime", translationPath: "siren-survey-translation.survey-form.fields.start-date", displayName: "Start Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator['Greater Or Equal'], cssClass: 'col-xs-12 col-sm-6 col-md-4' },
    { name: "endDateTime", translationPath: "siren-survey-translation.survey-form.fields.end-date", displayName: "End Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator['Less Or Equal'], cssClass: 'col-xs-12 col-sm-6 col-md-4'  },
    { name: "creationDate", translationPath: "siren-survey-translation.survey-form.fields.creation-date", displayName: "Creation Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal , cssClass: 'col-xs-12 col-sm-6 col-md-4' },
  ];

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  constructor(public service : SurveySelectionService, private router: Router, private route : ActivatedRoute,private authService: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public data: any,private matDialog: MatDialog, public authenticationService: ISurveyAuthenticationService) {
    super();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        case "surveyType": return item[property]?.value;
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        if(key && key == 'surveyType'){
          return currentTerm + data[key]?.value;
        } else{
          return currentTerm + data[key];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.additionalParams.set('linkTo', data?.linkTo);
    this.consumerPermissionEnum = this.authenticationService.getPermissionEnumeration();
    this.hasListAccess = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SURVEY_LIST);
    this.userActiveOrganizations = this.authService.getSessionService().getActiveSession().getActiveOrganizations();
  }

  ngOnInit(): void {
  }


  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  handleSelectionSubmission(objects: any){
    let surveyTopicInput: SurveyTopicInput={
      fromComponent : undefined,
      id : objects?.at(0)?.id,
      organizationId: this.selectedOrganization,
      linkTo:""
    }
    this.selectionEventEmitter.emit(surveyTopicInput);
  }

}
