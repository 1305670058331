import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ActivityExecution, ActivityExecutionCreation, ProgramActivityExecutionDisplayList } from 'src/app/models/admin/program/activities/activity-execution';
import { ProgramActivity } from 'src/app/models/admin/program/program/program';

@Injectable({
  providedIn: 'root'
})
export class ProgramActivityService extends BaseService<ProgramActivity, ActivityExecution> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/program-activity", http, injector);
  }

  getActivityExecutionDisplayList(programActivityId: number): Observable<HttpResponse<ProgramActivityExecutionDisplayList>> {
    return this.http.get<ProgramActivityExecutionDisplayList>("/v1/program-activity/activity-execution/display/" + programActivityId);
  }

  getActivityExecutionById(activityExecutionId: number): Observable<HttpResponse<ActivityExecution>> {
    return this.http.get<ActivityExecution>("/v1/program-activity/activity-execution/" + activityExecutionId);
  }

  saveActivityExecution(body : ActivityExecutionCreation): Observable<HttpResponse<ActivityExecutionCreation>> {
    return this.http.post<ActivityExecutionCreation>("/v1/program-activity/activity-execution" , body);
  }

  deleteActivityExecution(activityExecutionId: number): Observable<HttpResponse<String>> {
    return this.http.delete<String>("/v1/program-activity/activity-execution/" + activityExecutionId);
  }
}
