import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { PasswordComplexity } from '../password-complexity';
import { CustomValidators } from 'src/app/services/shared/validators.service';

@Component({
  selector: 'app-public-reset-password-submission',
  templateUrl: './public-reset-password-submission.component.html',
  styleUrls: ['./public-reset-password-submission.component.sass']
})
export class PublicResetPasswordSubmissionComponent implements OnInit{

  userOtp: String;
  validOtp = false;
  form: FormGroup;

  error: String;
  success: boolean;

  showPasswordConfirmation: boolean = false;
  showPassword: boolean = false;

  errorPassComplexity: string[] = [];
  errorPassNoMatch: boolean = false;
  passwordComplexityMessage: string;

  constructor(private route : ActivatedRoute, private router: Router, private http: HttpClient, private settingsService: SettingsService,
    private readonly _translateService: TranslateService, private passwordComplexity: PasswordComplexity){
    this.userOtp = this.route.snapshot.params['otp'];
    this.passwordComplexityMessage = this.getPasswordInfoMessage();
    this.validateOtp();
  }

  ngOnInit(): void {
  }

  initForm(){
    this.form = new FormGroup({
      username: new FormControl(null,  [Validators.required, CustomValidators.noWhitespaceValidator]),
      password: new FormControl(null,  [Validators.required, CustomValidators.noWhitespaceValidator]),
      passwordConfirmation: new FormControl(null,  [Validators.required, CustomValidators.noWhitespaceValidator])
    });
  }

  tooglePassword(){
    this.showPassword = !this.showPassword;
  }

  tooglePasswordConfirmation(){
    this.showPasswordConfirmation = !this.showPasswordConfirmation;
  }

  validateOtp(){
    if(!this.userOtp){
      this.router.navigate(['/login']);
    } else {
      this.userOtp = this.userOtp.trim();
      let requestBody = { "token": this.userOtp };
      this.http.post<boolean>(this.settingsService.settings.backendBaseUrl + "/v1/otp/validate", requestBody).subscribe(response => {
        if(response){
          this.validOtp = response;
          this.initForm();
        } else {
          this.router.navigate(['/login']);
        }
      }, error => {
        this.router.navigate(['/login']);
      });
    }
  }

  submit() {
    this.error = "";
    this.success = false;

    if (this.validateFormBeforeSubmit()){
      let username: String = this.form.get("username").getRawValue();
      let password: String = this.form.get("password").getRawValue();
      let passwordConfirmation: String = this.form.get("passwordConfirmation").getRawValue();
      username = username.trim();
      password = password.trim();
      passwordConfirmation = passwordConfirmation.trim();
      if(password != passwordConfirmation) {
        this.error = "Password combination do not match";
      } else {
        let requestBody = { "code": this.userOtp, "username": username, "password": password  };
        this.http.post<String>(this.settingsService.settings.backendBaseUrl + "/v1/auth/reset_password/otp", requestBody).subscribe(response => {
          this.success = true;
        }, error => {
          this.error = this._translateService.instant('siren-admin-translation.reset_password_confirmation.something_wrong');
        });
      }
    }
  }

  isStringEmpty(value: String): boolean{
    return value == undefined || value.trim() == "";
  }

  public navigateTo(path: String) {
    this.router.navigate([path]);
  }

  validateFormBeforeSubmit(){
    let validForm = this.form.valid;
    if(validForm){
      this.errorPassNoMatch = false;
      this.errorPassComplexity = [];
      let password: string = this.form.get("password").getRawValue();
      let passwordConfirmation: String = this.form.get("passwordConfirmation").getRawValue();
      // validate password match
      if(password != passwordConfirmation) {
        this.errorPassNoMatch = true;
        validForm = false;
      }
      // validate password complexity
      this.errorPassComplexity = this.validatePasswordComplexity(password,this._translateService);
      if(this.errorPassComplexity != undefined && this.errorPassComplexity.length > 0) validForm = false;
    }
    return validForm;
  }

  getPasswordInfoMessage(): string{
    let passwordMessage = "";
    if(this.settingsService.settings.maxLength) passwordMessage = this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least") + this.settingsService.settings.minLength + this._translateService.instant("siren-admin-translation.admin_module.password-complexity.characters");
    if(this.settingsService.settings.minLength) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-maximum") + this.settingsService.settings.maxLength +  this._translateService.instant("siren-admin-translation.admin_module.password-complexity.characters"));
    if(this.settingsService.settings.alphabet){
      if(this.settingsService.settings.lowerCase) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least-one-lower-case-letter"));
      if(this.settingsService.settings.upperCase) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least-one-upper-case-letter"));
    }

    if(this.settingsService.settings.digits) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least-one-digit"));
    if(this.settingsService.settings.specialCharacters) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least-one-special-char"));

    if(this.settingsService.settings.noWhiteSpace) passwordMessage = this.concatPasswordErrorStringWithDivider(passwordMessage, ". ", this._translateService.instant("siren-admin-translation.admin_module.password-complexity.pass-should-not-contain-space") );
    passwordMessage = passwordMessage != undefined && passwordMessage.trim() != "" ? this._translateService.instant("siren-admin-translation.admin_module.password-complexity.pass-must-contain")  + passwordMessage: "";
    return passwordMessage;
  }

  concatPasswordErrorString(base: string, suffix: string){
    return this.concatPasswordErrorStringWithDivider(base, ', ', suffix);
  }

  concatPasswordErrorStringWithDivider(base: string, divider: string, suffix: string){
    return base != undefined ? base += divider + suffix : suffix;
  }

  validatePasswordComplexity(password: string,_translateService: TranslateService): string[]{
    let error: string[] = [];
    if(password != undefined){
      if(this.settingsService.settings.noWhiteSpace && this.passwordComplexity.checkIfContainsSpaces(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-cannot-contain-spaces"));
      if(this.settingsService.settings.maxLength && !this.passwordComplexity.checkMaximumLength(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-be-less-than") + this.settingsService.settings.maxLength + _translateService.instant("siren-admin-translation.admin_module.password-complexity.characters"));
      if(this.settingsService.settings.minLength && !this.passwordComplexity.checkMinimumLength(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-be-at-least") + this.settingsService.settings.minLength + _translateService.instant("siren-admin-translation.admin_module.password-complexity.characters"));
      if(this.settingsService.settings.alphabet) {
        if(this.settingsService.settings.lowerCase && !this.passwordComplexity.checkIfContainsLowerCaseLetters(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-contain-at-least-one-lower-case letter"));
        if(this.settingsService.settings.upperCase && !this.passwordComplexity.checkIfContainsUpperCaseLetters(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-contain-at-least-one-upper-case-letter"));
      }
      if(this.settingsService.settings.digits && !this.passwordComplexity.checkIfContainsDigits(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-contain-at-least-one-number"));
      if(this.settingsService.settings.specialCharacters && !this.passwordComplexity.checkIfContainsSpecialCaseLetters(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-contain-at-least-one-special-character"));
    } else{
      error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-cannot-be-empty"));
    }
    return error;
  }

}
