import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Organization } from '../../models/admin/organization/organization';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';

@Injectable({
  providedIn: 'root'
})
export class MarchUserService extends BaseService<Organization, Organization> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/march-user", http, injector);
  }

  getUserLookupList(): Observable<HttpResponse<LookupModel[]>> {
    return this.http.get<any>('/v1/march-user/lookup-list');
  }

  getUserActivities(userId: number, from: string, to: string, page: number, size: number): Observable<HttpResponse<any>> {
    let url = '/v1/logging';
    let extension = '';
    if(page == undefined) page = 0;
    if(size == undefined) size = 10;
    if(userId != undefined && userId > 0) extension += 'user=' + userId;
    if(from != undefined && from.trim() != "") extension = this.concatString(extension, '&', 'from=' + from);
    if(to != undefined && to.trim() != "") extension = this.concatString(extension, '&', 'to=' + to);
    extension = this.concatString(extension, '&', 'page=' + page);
    extension = this.concatString(extension, '&', 'size=' + size);
    url = this.concatString(url, '?', extension);
    return this.http.get<any>(url);
  }

  concatString(base: string, separator: string, suffix: string){
    let response = base;
    if(suffix != undefined && suffix != ''){
      if(response == undefined) response = '';
      if(response.trim() != '') response += separator;
      response += suffix;
    }
    return response;
  }
}



export interface UserActivity{
  id: number;
  uuid: string;
  userId: number;
  requestPath: string;
  requestParameters: string;
  requestMethod: string;
  requestBody: string;
  requestCreationDate: string;
  responsePath: string;
  responseStatusCode: string;
  responseMethod: string;
  responsesBody: string;
  responseCreationDate: string;
}
