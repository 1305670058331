import { Pipe, PipeTransform } from '@angular/core';
import { byteHelper } from '@siren-survey/app/shared/helpers/byte-helper';

@Pipe({
  name: 'convertBytesToSize'
})
export class BytePipe implements PipeTransform {

  transform(fileSize: number): unknown {
    return byteHelper.convertBytesToSize(fileSize);
  }

}
