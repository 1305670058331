<ng-container *ngIf="isTouched() || isSubmitted()">
    <div *ngIf="hasError('required'); else whitespaceError" class="error">
        {{ 'siren-admin-translation.form_errors.field_required' | translate }}
    </div>
    <ng-template #whitespaceError>
        <div *ngIf="hasError('whitespace'); else serviceWorkerPhoneFormatFormatError" class="error">
            {{ 'siren-admin-translation.form_errors.incorrect_format' | translate }}
        </div>
    </ng-template>
    <ng-template #serviceWorkerPhoneFormatFormatError>
        <div *ngIf="hasError('pattern') && isServiceWorkerPhoneFormatError(); else phoneFormatError" class="error">
            {{ 'siren-admin-translation.form_errors.service_worker_phone_number_formatting' | translate }}
        </div>
    </ng-template>
    <ng-template #phoneFormatError>
        <div *ngIf="hasError('pattern') && isPhoneFormatError(); else equalPassFormatError" class="error">
            {{ 'siren-admin-translation.form_errors.phone_number_formatting' | translate }}
        </div>
    </ng-template>
    <ng-template #equalPassFormatError>
      <div *ngIf="hasError('equalTo'); else hotlineFormatError" class="error">
        {{ 'siren-admin-translation.form_errors.password_matching' | translate }}
      </div>
    </ng-template>
    <ng-template #hotlineFormatError>
        <div *ngIf="hasError('pattern') && isHotlineFormatError(); else numberFormatError" class="error">
            {{ 'siren-admin-translation.form_errors.hotline_formatting' | translate }}
        </div>
    </ng-template>
    <ng-template #numberFormatError>
        <div *ngIf="hasError('pattern') && isNumberFormatError(); else formatError" class="error">
            {{ 'siren-admin-translation.form_errors.number_formatting' | translate }}
        </div>
    </ng-template>
    <ng-template #formatError>
        <div *ngIf="hasError('pattern') && !isServiceWorkerPhoneFormatError() && !isNumberFormatError() && !isPhoneFormatError() &&!isHotlineFormatError() ; else minMaxLengthError" class="error">
            {{ 'siren-admin-translation.form_errors.incorrect_format' | translate }}
        </div>
    </ng-template>
    <ng-template #minMaxLengthError>
        <div *ngIf="hasError('length') && this.control.errors.maxLength && this.control.errors.minLength; else minLengthError" class="error">

            {{'siren-admin-translation.form_errors.min_max_length_formatting1' | translate}} {{toArabic(this.control.errors.minLength)}} {{'form_errors.min_max_length_formatting2' | translate}} {{toArabic(this.control.errors.maxLength)}}
        </div>
    </ng-template>
    <ng-template #minLengthError>
        <div *ngIf="hasError('length') && !this.control.errors.maxLength && this.control.errors.minLength; else maxLengthError" class="error">
            {{'siren-admin-translation.form_errors.min_length_formatting1' | translate}} {{toArabic(this.control.errors.minLength)}} {{'form_errors.min_length_formatting2' | translate}}
        </div>
    </ng-template>
    <ng-template #maxLengthError>
        <div *ngIf="hasError('length') && this.control.errors.maxLength && !this.control.errors.minLength; else genericError" class="error">
            {{'siren-admin-translation.form_errors.max_length_formatting1' | translate}} {{toArabic(this.control.errors.maxLength)}} {{'form_errors.max_length_formatting2' | translate}}
        </div>
    </ng-template>
    <ng-template #genericError>
        <div class="error">
            {{getErrorMessage() | translate}}
        </div>
    </ng-template>
</ng-container>
