import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { AuthService } from '../services/auth.service';
import { IAdminAuthenticationService } from '../services/adminAuthentication.interface';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        public authService: IAdminAuthenticationService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //TODO check getUser how to handle it
      // const user = this.authService.getUser();
        if (this.authService.getAccessToken()
            // && this.authService.isAdmin()
        ) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
