import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@siren-auth/app/shared/services/snackbar.service';
import { PasswordError, PasswordRequest } from 'src/app/models/profile/profile.model';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { PasswordComplexity } from '../../password-complexity';
import { CustomValidators } from 'src/app/services/shared/validators.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent  extends DropdownComparatorWithBrowserListener implements OnInit {

  passwordForm: FormGroup;

  error: PasswordError = { error: false, message: '', withTranslate: true };

  showPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  passwordError: string;
  errorPassNoMatch: boolean = false;
  errorPassComplexity: string[] = [];

  constructor(private profileService: ProfileService, private snacks: SnackbarService,
             public lookupService: LookupService, public passwordComplexity: PasswordComplexity, public readonly _translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.passwordForm = new FormGroup({
      oldPassword: new FormControl(null,  [Validators.required, CustomValidators.noWhitespaceValidator]),
      newPassword: new FormControl(null,  [Validators.required, CustomValidators.noWhitespaceValidator]),
      confirmNewPassword: new FormControl(null,  [Validators.required, CustomValidators.noWhitespaceValidator])
    });
  }

  onSubmit() {
    if(this.validateFormBeforeSubmit()){
      const passwordToSubmit: PasswordRequest = this.passwordForm.getRawValue();
      this.profileService.changePassword(passwordToSubmit).subscribe(res => {
          this.error.error = false;
          this.snacks.openSnackBar("Password Updated");
        }, err => {
          if (err.message === "Data Conflict") {
            this.error = { error: true, message: "generic.incorrect_old_password", withTranslate: true }
          } else {
            this.error = { error: true, message: err.message, withTranslate: false }

            this.snacks.openWarningSnackBar(err.error.message);

          }
        })
    }
    else{
      this.snacks.openWarningSnackBar("Form Invalid");
    }
  }

  // validateFormBeforeSubmit(): boolean{
  //   let validForm = true;
  //   this.errorPassNoMatch = false;
  //   this.errorPassComplexity = [];
  //   this.passwordForm.markAllAsTouched();
  //   let password: String = this.passwordForm.get("newPassword").getRawValue();
  //   let confirmNewPassword: String = this.passwordForm.get("confirmNewPassword").getRawValue();
  //   password = password?.trim();
  //   confirmNewPassword = confirmNewPassword?.trim();
  //   if(password != confirmNewPassword) {
  //     this.errorPassNoMatch = true;
  //     validForm = false;
  //     this.error = { error: true, message: 'siren-admin-translation.generic.do_not_match', withTranslate: true }
  //     this.passwordForm.controls["confirmNewPassword"].setErrors({ 'incorrect': true });
  //   }
  //   if(!this.passwordForm.valid && (password == undefined || confirmNewPassword == undefined)){
  //     validForm = false;
  //     this.snacks.openWarningSnackBar("Form Invalid");
  //   } else if(!this.passwordForm.valid && (password != '' || confirmNewPassword != '')){
  //     this.errorPassComplexity= true;
  //     validForm = false;
  //     this.snacks.openWarningSnackBar("Form Invalid");
  //   }

  //   return validForm;
  // }


  validateFormBeforeSubmit(){
    this.passwordForm.markAllAsTouched();
    let validForm = this.passwordForm.valid;
    if(validForm){
      this.errorPassNoMatch = false;
      this.errorPassComplexity = [];
      let password: string = this.passwordForm.get("newPassword").getRawValue();
      let passwordConfirmation: string = this.passwordForm.get("confirmNewPassword").getRawValue();
      // validate password match
      if(password != passwordConfirmation) {
        this.errorPassNoMatch = true;
        validForm = false;
        this.error = { error: true, message: 'siren-admin-translation.generic.do_not_match', withTranslate: true }
        this.passwordForm.controls["confirmNewPassword"].setErrors({ 'incorrect': true });
      }
      // validate password complexity
      this.errorPassComplexity = this.passwordComplexity.validatePasswordComplexity(password,this._translateService);
      if(this.errorPassComplexity != undefined && this.errorPassComplexity.length > 0) validForm = false;
    }
    return validForm;
  }

  tooglePassword(){
    this.showPassword = !this.showPassword;
  }
  toogleNewPassword(){
    this.showNewPassword = !this.showNewPassword;
  }
  toogleConfirmPassword(){
    this.showConfirmPassword = !this.showConfirmPassword;
  }

}

