import { CodeVerificationDialogComponent } from '../components/code-verification-dialog/code-verification-dialog.component';
import { TextInputComponent } from './../components/text-input/text-input.component';
import { ErrorDisplayComponent } from './../components/error-display/error-display.component';
import { NgModule } from '@angular/core';
import { ThirdPartiesModule } from './third-parties.module';
import { DirectivesModule } from './directives.module';
import { PipesModule } from './pipes.module';
import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { SubmitButtonComponent } from '../components/submit-button/submit-button.component';
import { DatepickerComponent } from '../components/datepicker/datepicker.component';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { HeaderComponent } from '../components/header/header.component';
import { AuthenticationDialogComponent } from '../components/dialogs/authentication-dialog/authentication-dialog.component';
import { ListDataTableComponent } from '../components/list-data-table/list-data-table.component';
import { DetailsDialogComponent } from '../components/details-dialog/details-dialog.component';
import { SidenavComponent } from '../components/sidenav/sidenav.component';
import { ConfirmComponent } from '../components/confirm/confirm-component';
import { SingleSelectComponent } from '../components/single-select/single-select.component';
import { MultipleSelectComponent } from '../components/multiple-select/multiple-select.component';
import { MainListComponent } from '../components/main-list/main-list.component';
import { ContentHeaderComponent } from '../components/content-header/content-header.component';
import { TextAreaComponent } from '../components/text-area/text-area.component';
import { WarningDialogComponent } from '../components/dialogs/warning-dialog/warning-dialog.component';
import { TreeChecklistComponent } from '../components/tree-checklist/tree-checklist.component';
import { CustomFormComponent } from '../components/custom-form/custom-form.component';
import { CustomFieldComponent } from '../components/custom-field/custom-field.component';
import { UploadFieldComponent } from '../components/upload-field/upload-field.component';
// import { PaymentDialogComponent } from '../components/payment-dialog/payment-dialog.component';
import { ToggleButtonsComponent } from '../components/toggle-buttons/toggle-buttons.component';
import { FilterComponent } from '../components/filter/filter.component';

@NgModule({
    imports: [
        ThirdPartiesModule,
        DirectivesModule,
        PipesModule
    ],
    entryComponents: [
        WarningDialogComponent
    ],
    declarations: [
        ToolbarComponent,
        ErrorDisplayComponent,
        TextInputComponent,
        TextAreaComponent,
        SubmitButtonComponent,
        SingleSelectComponent,
        MultipleSelectComponent,
        DatepickerComponent,
        ConfirmationDialogComponent,
        HeaderComponent,
        AuthenticationDialogComponent,
        ListDataTableComponent,
        DetailsDialogComponent,
        // ToolbarDrawerComponent,
        SidenavComponent,
        MainListComponent,
        ConfirmComponent,
        MainListComponent,
        ContentHeaderComponent,
        CodeVerificationDialogComponent,
        WarningDialogComponent,
        TreeChecklistComponent,
        CustomFormComponent,
        CustomFieldComponent,
        UploadFieldComponent,
        // PaymentDialogComponent,
        ToggleButtonsComponent,
        FilterComponent
    ],
    exports: [
        ThirdPartiesModule,
        PipesModule,
        DirectivesModule,
        ToolbarComponent,
        ErrorDisplayComponent,
        TextInputComponent,
        TextAreaComponent,
        SingleSelectComponent,
        MultipleSelectComponent,
        SubmitButtonComponent,
        DatepickerComponent,
        ConfirmationDialogComponent,
        HeaderComponent,
        AuthenticationDialogComponent,
        ListDataTableComponent,
        DetailsDialogComponent,
        // ToolbarDrawerComponent,
        SidenavComponent,
        MainListComponent,
        ContentHeaderComponent,
        CodeVerificationDialogComponent,
        WarningDialogComponent,
        TreeChecklistComponent,
        CustomFormComponent,
        CustomFieldComponent,
        UploadFieldComponent,
        // PaymentDialogComponent,
        ToggleButtonsComponent,
        FilterComponent
    ]
})
export class ComponentsModule { }
