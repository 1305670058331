import { Component, Input, OnInit, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.sass']
})
export class TextAreaComponent implements ControlValueAccessor {
  @Input() placeholdertext: string;
  @Input() formControlName: string;
  @Input() maxLength?: number = 2000;
  @Input() isPassword:boolean;
  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }
  writeValue(obj: any): void {

  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void { }
  fieldInvalid(): boolean {
    const field = this.ngControl.control;
    return field.touched && field.invalid;
  }
  fieldRequired() {
    const field = this.ngControl.control;
    if (field['validator']) {
      const validator = field.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
      return false;
    }
  }
}
