import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { IncidentType } from 'src/app/models/incident/incident';

@Injectable({
  providedIn: 'root'
})
export class IncidentTypeService extends BaseService<IncidentType, any> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/incident-type", http, injector);
  }

}
