import { Component, Input,  Output,  Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';


@Component({
  selector: 'app-toggle-buttons',
  templateUrl: './toggle-buttons.component.html',
  styleUrls: ['./toggle-buttons.component.sass']
})
export class ToggleButtonsComponent  implements ControlValueAccessor {
  @Input() placeholdertext: string;
  @Input() formControlName: string;
  @Input() isColored: false;
  @Input() firstButtonLabel: string;
  @Input() secondButtonLabel:  string ;
  @Input() list: any[]= [];



  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;


   }
  writeValue(obj: any): void {

  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }


  fieldInvalid(): boolean {
    const field = this.ngControl.control;
    return field.touched && field.invalid;

  }

}
