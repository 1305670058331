<div>
  <div class="table-header-content flexDiv">
    <div class="flexDivStart table-title">{{tableTitleLabel}}</div>
    <div class="flexDivEnd">
      <!-- <ng-content></ng-content> -->
      <button type="button" #filterbutton data-bs-toggle="collapse" data-bs-target="#filterElementContainer" title="Filter" *ngIf="showFilterButton"
        aria-controls="filterElementContainer" aria-expanded="false" aria-label="Toggle navigation" class="button-flat">
        <img [src]="getAssetsUrl('assets/icons/filter-yellow-icon.svg')"  class="iconWidth"> <span class="table-filter-label">{{"siren-survey-translation.global.filter" | translate}}</span>
      </button>
      <!-- <input matInput class="datatable-search-textbox" (keyup)="applyFilter($event)" placeholder="Quick Filter" #input [value]="filterValue"> -->
      <div class="row flexRow ">
        <button type="button" class="btn btn-md table-action-button" *ngIf="withAdd" (click)="add()">
          {{ ( addButtonLabel | translate) != undefined ? " " + ( addButtonLabel | translate) : ("siren-survey-translation.global.add" | translate) }}
        </button>
        <button type="button" class="btn btn-md table-action-button marginLeft" *ngIf="deleteMode == tableDeleteMode.SELECTION_MODE"  (click)="deleteSelection()"><span class="fa fa-minus"></span>
          {{ ( "siren-survey-translation.global.delete" | translate) != undefined ? " " + ( "siren-survey-translation.global.delete" | translate) : ("siren-survey-translation.global.delete" | translate) }}
        </button>
      </div>
      <div class="bottom-border" *ngIf="showBackButton" >
        <button type="button" (click)="goBack(); $event.stopPropagation();" class="btn button-flat"
         [disabled]="false">
         <i class="fa fa-angle-left" aria-hidden="true"></i>
         <span  class="locate-text ">{{'siren-survey-translation.global.back' | translate}}</span>
       </button>
     </div>
    </div>
  </div>
  <ng-content></ng-content>
  <!-- <ng-content select="[filter-button]"></ng-content>
  <ng-content select="[filter-form]"></ng-content> -->
  <div style="position:relative;">
    <div style="text-align:center;position:absolute;height:100%;width:100%;display:flex;align-items:center;justify-content:center;background-color:rgba(255, 255, 255, .6);" *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin fa-2x" style="margin-bottom:10px;"></i>
    </div>
    <mat-table [dataSource]="tableDataSource" matSort>
      <ng-container matColumnDef="select" *ngIf="selectionMode != tableSelectionMode.NONE_MODE">
        <mat-header-cell *matHeaderCellDef class="table-action-button-container">
          <mat-checkbox *ngIf="selectionMode == tableSelectionMode.MULTI_SELECT_MODE"
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="table-action-button-container">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="handleSelection($event, row)"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container *ngFor="let column of tableColumns; let columnIndex = index" [matColumnDef]="column.columnDef">
        <div *ngIf="column.columnDef != '_delete_inline' && column.columnDef != '_select_inline' && column.columnDef != '_menu_inline'">
          <mat-header-cell *matHeaderCellDef mat-sort-header  [ngClass]="column.contentClass != undefined ? column.contentClass : 'centerContent'" [ngStyle]=" {'max-width': column.width != undefnied ? column.width : '' }">
            {{ column.headerTranslationPath && (column.headerTranslationPath | translate) != undefined
              && (column.headerTranslationPath | translate) != column.headerTranslationPath ? (column.headerTranslationPath | translate) : column.header }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" [ngClass]="column.contentClass != undefined ? column.contentClass : 'centerContent'" [ngStyle]=" {'max-width': column.width != undefnied ? column.width : '' }">
            <div #myDiv [id]="'myDiv_' + i + '_' + columnIndex" *ngIf="column.type == tableFilterTypeEnum.BenefScores && !shouldReplaceBooleanValueByIcon(column.columnDef) && (column.cell(row) != 'null' && column.cell(row) != undefined && column.cell(row) != null)">
              {{ getBenefScores(column.cell(row), i,columnIndex) }}
            </div>

            <div *ngIf="!isCellEditable(row.id)">
              <img *ngIf="shouldReplaceBooleanValueByIcon(column.columnDef) && isBooleanTrue(column.cell(row))" [src]="getAssetsUrl('assets/icons/check-icon.svg')" style="width: 20px;">

              <span *ngIf="shouldReplaceBooleanValueByIcon(column.columnDef) && !isBooleanTrue(column.cell(row))">-</span>

              <span *ngIf="column.type == tableFilterTypeEnum.LookupObjectEnumeration && !shouldReplaceBooleanValueByIcon(column.columnDef) && (column.cell(row) != 'null' && column.cell(row) != undefined && column.cell(row) != null)">
                {{ getLookupValueForObjectEnum(column.lookupName, column.cell(row)) }}
              </span>

              <span *ngIf="column.type == tableFilterTypeEnum.Month && !shouldReplaceBooleanValueByIcon(column.columnDef) && (column.cell(row) != 'null' && column.cell(row) != undefined && column.cell(row) != null)">
                {{ getMonthLookupValueForObject(column.cell(row)) }}
              </span>

              <span [style]="column.style != undefined ? column?.style(row) : ''"  *ngIf="column.type != tableFilterTypeEnum.LookupObjectEnumeration && column.type != tableFilterTypeEnum.Month && column.type != tableFilterTypeEnum.BenefScores && !shouldReplaceBooleanValueByIcon(column.columnDef) && (column.cell(row) != 'null' && column.cell(row) != undefined && column.cell(row) != null)">
                {{ (column.cell(row).length > maxCellStringLength) ? (column.cell(row) | slice:0:maxCellStringLength) + '...' : (column.cell(row))}}
              </span>

              <span *ngIf="!shouldReplaceBooleanValueByIcon(column.columnDef) && (column.cell(row) == 'null' || column.cell(row) == undefined || column.cell(row) == null)">
                -
              </span>
            </div>
            <form [formGroup]="getObjectFormGroup(row.id)" *ngIf="isCellEditable(row.id)" class="editable-row" style="width: 95%">

              <div *ngIf="column.type == tableFilterTypeEnum.String">
                <mat-form-field class="full-width-element" appearance="legacy">
                  <mat-label class="" >
                    {{ column.header }}
                  </mat-label>
                  <input matInput class="" autocomplete="off" formControlName="{{column.columnDef}}">
                </mat-form-field>
              </div>
              <div *ngIf="column.type == tableFilterTypeEnum.Lookup">
                <mat-form-field  required class="full-width-element">
                  <mat-label class="" >
                    {{ column.header }}
                  </mat-label>
                   <mat-select  class="" formControlName="{{column.columnDef}}" *ngIf="column?.lookupsValueById" [compareWith]="objectComparisonFunction">
                    <mat-option *ngFor="let lookupElement of getLookupByName(column.lookupName, column.lookupMethod)" [value]="lookupElement" >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                  <mat-select *ngIf="!column?.lookupsValueById" class=""  formControlName="{{column.columnDef}}" [compareWith]="objectKeyComparisonFunction">
                    <mat-option *ngFor="let lookupElement of getLookupByName(column.lookupName)" [value]="lookupElement"  >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="column.type == tableFilterTypeEnum.LookupObject">
                <mat-form-field  required class="full-width-element">
                  <mat-label class="" >
                    {{ column.header }}
                  </mat-label>
                   <mat-select  class="" formControlName="{{column.columnDef}}" *ngIf="column?.lookupsValueById" [compareWith]="objectComparisonFunction">
                    <mat-option *ngFor="let lookupElement of getLookupObjectByName(column.lookupName)" [value]="lookupElement" >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                  <mat-select *ngIf="!column?.lookupsValueById" class=""  formControlName="{{column.columnDef}}" [compareWith]="objectKeyComparisonFunction">
                    <mat-option *ngFor="let lookupElement of getLookupObjectByName(column.lookupName)" [value]="lookupElement"  >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="column.type == tableFilterTypeEnum.LookupObjectEnumeration">
                <mat-form-field  required class="full-width-element">
                  <mat-label class="" >
                    {{ column.header }}
                  </mat-label>
                  <mat-select  class="" formControlName="{{column.columnDef}}" [compareWith]="enumerationObjectComparisonFunction">
                    <mat-option *ngFor="let lookupElement of getLookupObjectByName(column.lookupName)" [value]="lookupElement" >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="column.type == tableFilterTypeEnum.Month">
                <mat-form-field  required class="full-width-element">
                  <mat-label class="" >
                    {{ column.header }}
                  </mat-label>
                  <mat-select  class="" formControlName="{{column.columnDef}}" [compareWith]="enumerationObjectComparisonFunction">
                    <mat-option *ngFor="let lookupElement of getLookupObjectByName(column.lookupName)" [value]="lookupElement" >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="column.type == tableFilterTypeEnum.Number">
                <mat-form-field class="full-width-element" appearance="legacy">
                  <mat-label class="" >
                    {{ column.header }}
                  </mat-label>
                  <input matInput  type="number" class="" (keydown)="onKeyDownEvent($event)" autocomplete="off" formControlName="{{column.columnDef}}">
                </mat-form-field>
              </div>
              <div *ngIf="column.type == tableFilterTypeEnum.Date">
                <mat-form-field class="full-width-element">
                  <mat-label>
                    {{ column.header }}
                  </mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="{{column.columnDef}}">
                  <mat-hint>DD/MM/YYYY</mat-hint>
                  <mat-datepicker-toggle matSuffix  [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div *ngIf="column.type == tableFilterTypeEnum.Boolean">
                <mat-form-field class="full-width-element" class="full-width-element">
                  <mat-label class="" >
                    {{ column.header }}
                  </mat-label>
                  <mat-select class="" formControlName="{{column.columnDef}}">
                    <mat-option [value]="false">False</mat-option>
                    <mat-option [value]="true">True</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </form>
          </mat-cell>
        </div>
        <div *ngIf="column.columnDef == '_delete_inline'" >
          <mat-header-cell *matHeaderCellDef class="table-action-button-container table-action-button-container-last" [ngStyle]=" {'display': (deleteMode == undefined || deleteMode != tableDeleteMode.INLINE_MODE) ? 'none' : '' }"></mat-header-cell>
          <mat-cell *matCellDef="let element" class="table-action-button-container table-action-button-container-last" [ngStyle]=" {'display': (deleteMode == undefined || deleteMode != tableDeleteMode.INLINE_MODE) ? 'none' : '' }">
            <button type="button" *ngIf="deleteMode != undefined && deleteMode == tableDeleteMode.INLINE_MODE && element.deletable " class="btn btn-md table-action-button" style="margin-left :5px" (click)="delete(element.id); $event.stopPropagation();">
              <img [src]="getAssetsUrl('assets/icons/delete-icon.svg')" style="width: 20px;">
            </button>
          </mat-cell>
        </div>

        <div *ngIf="column.columnDef == '_menu_inline'" >
          <mat-header-cell *matHeaderCellDef class="table-menu-action-button-container" ></mat-header-cell>
          <mat-cell *matCellDef="let element" class="table-menu-action-button-container">
            <button type="button" class="no-hover" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu">
              <img [src]="getAssetsUrl('assets/icons/vertical-menu-icon.svg')" style="width: 20px; height: 20px;">
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let menuAction of _tableMenuAction">
                <div *ngIf="(menuAction.actionName != 'EDIT_ACTION' && menuAction.actionName != 'DELETE_ACTION') || !isCellEditable(element.id)">
                  <button *ngIf="isAllowedAction(element, menuAction)" type="button" mat-menu-item (click)="onContextMenuClicked(element, menuAction.actionName,getObjectFormGroup(element.id)); $event.stopPropagation();">
                    <img *ngIf="menuAction.fontAwesomeIcon == undefined && menuAction.actionName != 'EDIT_ACTION' && menuAction.actionName != 'DELETE_ACTION' && menuAction.actionName != 'ENABLE_ACTION' && menuAction.actionName != 'DISABLE_ACTION'
                      && menuAction.iconUrl" [src]="getAssetsUrl(menuAction.iconUrl)" [style]="menuAction.iconStyle">
                    <i *ngIf="menuAction.actionName == 'EDIT_ACTION'" class="fa fa-edit" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                    <i *ngIf="menuAction.actionName === 'DELETE_ACTION'" class="fa fa-trash" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                    <i *ngIf="menuAction.actionName === 'DISABLE_DELETE_ACTION'" class="fa fa-times-circle" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                    <i *ngIf="menuAction.actionName == 'ENABLE_ACTION'" class="fa fa-check-circle" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                    <i *ngIf="menuAction.actionName == 'DISABLE_ACTION'" class="fa fa-times-circle" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                    <i *ngIf="menuAction.fontAwesomeIcon != undefined" [class]="menuAction.fontAwesomeIcon" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                    {{ menuAction.name | translate }}
                  </button>
                </div>
              </ng-container>
              <!-- <div *ngIf="inlineEditable  && !isCellEditable(element.id)">
                <button *ngIf="isAllowedAction(element, editInlineButton)" type="button" mat-menu-item (click)="onContextMenuClicked(element, editInlineButton.actionName,getObjectFormGroup(element.id)); $event.stopPropagation();">
                  <img *ngIf="editInlineButton.iconUrl" [src]="getAssetsUrl(editInlineButton.iconUrl)" [style]="editInlineButton.iconStyle">
                  {{ editInlineButton.name }}
                </button>
              </div> -->
              <div *ngIf="inlineEditable  && isCellEditable(element.id)">
                <button *ngIf="isAllowedAction(element, saveInlineButton)" type="button" mat-menu-item (click)="onContextMenuClicked(element, saveInlineButton.actionName,getObjectFormGroup(element.id)); $event.stopPropagation();">
                  <!-- <img *ngIf="saveInlineButton.iconUrl" [src]="getAssetsUrl(saveInlineButton.iconUrl)" [style]="saveInlineButton.iconStyle"> -->
                  <i class="fa fa-save" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;"></i>
                  {{ saveInlineButton.name }}
                </button>
              <!-- </div>
              <div *ngIf="inlineEditable  && isCellEditable(element.id)"> -->
                <button *ngIf="isAllowedAction(element, cancelInlineButton)" type="button" mat-menu-item (click)="onContextMenuClicked(element, cancelInlineButton.actionName,getObjectFormGroup(element.id)); $event.stopPropagation();">
                  <!-- <img *ngIf="cancelInlineButton.iconUrl" [src]="getAssetsUrl(cancelInlineButton.iconUrl)" [style]="cancelInlineButton.iconStyle"> -->
                  <i class="fa fa-window-close" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                  {{ cancelInlineButton.name }}
                </button>
              </div>
              <div class="no-action-menu" *ngIf="(element && hasNoMenuElement(element)) || this.elementsWithMenuActions?.length == 0">
                <span>No Actions</span>
              </div>
            </mat-menu>
          </mat-cell>
        </div>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsWithAction"></mat-header-row>

      <mat-row (click)="edit(row.id)" *matRowDef="let row; columns: displayedColumnsWithAction; let i = index;"></mat-row>
    </mat-table>
  </div>
</div>
<app-popup-message *ngIf="errorMessage" [message]="errorMessage" (close)="popupModalClosed()"></app-popup-message>
