<span class="dialog-header">{{'siren-survey-translation.survey-form.fields.linked-surveys' | translate}}</span>

<ng-container>
  <div class="response-container">
    <div *ngIf="filterForm != undefined">
      <form class="col-12" [formGroup]="filterForm" (ngSubmit)="applyFilters()">
        <div class="row prog-act-row">
          <div class="col-lg-3 col-sm-6 col-12 activity-elem-no-padding tooltip-input-container "  (click)="$event.stopPropagation();">
              <mat-form-field class="full-width-element">
                <mat-label class=""> {{'beneficiary-form.fields.title' | translate}} </mat-label>
                <input  matInput class="" autocomplete="off" formControlName="title">
              </mat-form-field>
          </div>
          <div class="col-lg-3 col-sm-6 col-12 activity-elem-no-padding tooltip-input-container "  (click)="$event.stopPropagation();">
            <mat-form-field class="full-width-element">
              <mat-label class=""> {{'beneficiary-form.fields.maxGrade' | translate}}</mat-label>
              <input matInput type="number" class="" (keydown)="onKeyDownEvent($event)" autocomplete="off" formControlName="maxGrade">
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-sm-6 col-12 activity-elem-no-padding tooltip-input-container "  (click)="$event.stopPropagation();">
            <mat-form-field class="full-width-element" class="full-width-element">
              <mat-label class="">{{'beneficiary-form.fields.baseline' | translate}} </mat-label>
              <mat-select class="" formControlName="isBaseLine">
                <mat-option [value]=""></mat-option>
                <mat-option [value]="false">{{ 'global.false' | translate }}</mat-option>
                <mat-option [value]="true">{{ 'global.true' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-sm-6 col-12">
            <mat-form-field class="full-width-element">
              <mat-label class="" >
                {{ 'beneficiary-form.fields.submitDate' | translate }} {{ 'global.range' | translate }}
              </mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="valueFrom">
                <input matEndDate formControlName="valueTo">
              </mat-date-range-input>
              <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix  [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 surveyFilterButtonContainer">
          <button type="submit" class="btn btn-primary survey-filter-button marginRight">
            {{ ( "siren-survey-translation.global.search" | translate) != undefined ? ( "siren-survey-translation.global.search" | translate) : "Search" }}
          </button>
          <button type="button" (click)="clearFilters()" class="btn btn-primary survey-filter-cancel-button marginRight">
            {{ ( "siren-survey-translation.global.clear" | translate) != undefined ? ( "siren-survey-translation.global.clear" | translate) : "Clear" }}
          </button>
        </div>
      </form>
    </div>
    <p>
      <span class="grey-label">{{ 'program.survey-selection.view-survey-details' | translate }}</span>
    </p>
    <div>
      <div class="flex-row table-header">
        <div class="row" style="width: 100%;">
          <div class="col-lg-1 col-sm-6 col-12">
            <span>ID</span>
          </div>
          <div class="col-lg-4 col-sm-6 col-12">
            <span>{{'siren-survey-translation.survey-form.fields.title' | translate}}</span>
          </div>
          <div class="col-lg-3 col-sm-6 col-12">
            <span>{{'siren-survey-translation.survey-form.fields.creation-date' | translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-6 col-12">
            <span>{{'siren-survey-translation.survey-form.fields.start-date' | translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-6 col-12">
            <span>{{'siren-survey-translation.survey-form.fields.end-date' | translate}}</span>
          </div>
        </div>
        <div class="flex-row" style="min-width: 35px;">

        </div>
      </div>
      <ng-container *ngIf="this.surveyList != undefined && this.surveyList?.length > 0">
        <mat-accordion class="row table-record full-width-element" *ngFor="let survey of this.surveyList">
          <mat-expansion-panel (opened)="headerOpenState = true" (closed)="headerOpenState = false" style="border: none; box-shadow: none; padding: 0px;">
            <mat-expansion-panel-header style="height: auto !important; padding: 20px 15px;">
              <div class="row row-header" style="width: 100%;">
                <div class="col-lg-1 col-sm-6 col-12">
                  <span>{{ survey.id ? survey.id : "-" }}</span>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <span>{{ survey.title ? survey.title : "-" }}</span>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <span>{{ survey.creationDateTime ? survey.creationDateTime : "-" }}</span>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <span>{{ survey.startDateTime ? survey.startDateTime : "-" }}</span>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <span>{{ survey.endDateTime ? survey.endDateTime : "-" }}</span>
                </div>
              </div>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body>
              <div class="description-panel" style="width: 100%; margin-bottom: 15px;">
                <div style="text-align: left;">
                  <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.description' | translate}}:</span>
                  <span>{{ survey.description ? survey.description : "-" }}</span>
                </div>
                <i *ngIf="survey.id != undefined && survey.id > 0 && survey.surveyType != undefined && survey.surveyType.id != undefined && survey.surveyType.id > 0"
                  class="fa fa-eye" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true" title="View Survey"
                  (click)="openSurveyPreviewForm(survey.id); $event.stopPropagation();"></i>
              </div>
              <div class="flex-row-pannel" style="width: 100%;">
                <div style="margin-inline-end: 30px;">
                  <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.survey-type' | translate}}:</span>
                  <span>{{ survey.surveyType && survey.surveyType.value ? survey.surveyType.value : "-" }}</span>
                </div>
                <div style="margin-inline-end: 30px;">
                  <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.enabled' | translate}}:</span>
                  <span>{{ survey.enabled != undefined && survey.enabled == true ? 'Yes' : 'No' }}</span>
                </div>
                <div style="margin-inline-end: 30px;">
                  <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.graded' | translate}}:</span>
                  <span>{{ survey.graded != undefined && survey.graded == true ? 'Yes' : 'No' }}</span>
                </div >
                <div style="margin-inline-end: 30px;">
                  <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.max-grade' | translate}}:</span>
                  <span>{{ survey.maxGrade ? survey.maxGrade : "-" }}</span>
                </div>
                <div>
                  <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.published' | translate}}:</span>
                  <span>{{ survey.published != undefined && survey.published == true ? 'Yes' : 'No' }}</span>
                </div>
              </div>
            </mat-expansion-panel-body>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
      <div *ngIf="this.surveyList == undefined || this.surveyList?.length == 0">
        <span class="empty-list-label">{{ 'siren-survey-translation.survey-form.no-linked-surveys' | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>
<div class="page-footer" [ngClass]="(this.surveyList == undefined || this.surveyList?.length == 0) ? 'hidden-element' : ''">
  <span style="margin-inline-end: 15px;" >{{ 'siren-survey-translation.global.total-surveys' | translate }} {{ totalElements }}</span>
  <mat-form-field style="width: 100px; margin-inline-end: 10px;">
    <mat-label class="" >{{ 'global.page-size' | translate}}</mat-label>
    <mat-select class="" [(value)]="pageSize" (selectionChange)="changePageSize($event)">
      <mat-option [value]="5">5</mat-option>
      <mat-option [value]="10">10</mat-option>
      <mat-option [value]="20">20</mat-option>
      <mat-option [value]="50">50</mat-option>
      <mat-option [value]="100">100</mat-option>
      <mat-option [value]="150">150</mat-option>
      <mat-option [value]="200">200</mat-option>
    </mat-select>
  </mat-form-field>
  <button *ngIf="currentPage > 0" type="button" (click)="getPreviousPage()" class="btn btn-previous-page">
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
  </button>
  <span>{{ 'global.page' | translate}} {{ currentPage + 1 }} {{ 'global.out-of' | translate}} {{ maxPage }}</span>
  <button *ngIf="currentPage < maxPage -1" type="button" (click)="getNextPage()" class="btn btn-next-page">
    <i class="fa fa-arrow-right" aria-hidden="true"></i>
  </button>
</div>
