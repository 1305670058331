import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

import { DataTableModel } from '../../../models/shared/cell-data-model';
import { ResponsiveListInlineEditObject, TableAction, TableDeleteMode, TableSelectionMode } from '../../../models/shared/table-action';
import { DataTableService } from '../../../services/shared/interfaces/data-table-service';
import { BrowserListener } from '../../../services/utils/browser-listener.service';
import { MatSort } from '@angular/material/sort';
import { TablePageResponse } from '../../../models/shared/table-page-response.model';
import { DialogService } from '../../../services/shared/dialog.service';
import { MenuActionEmittedObject, MenuActionObject } from '../../../models/shared/datatable-objects.model';
import { TranslateService } from '@ngx-translate/core';
import { CustomDataSource } from './custom-data-source';
import { SortingModel } from '@siren-survey/app/models/shared/sorting.model';

@Component({
  selector: 'app-responsive-list-view',
  templateUrl: './responsive-list-view.component.html',
  styleUrls: ['./responsive-list-view.component.sass']
})
export class ResponsiveListViewComponent extends BrowserListener implements OnInit, OnDestroy {
  @Input() tableColumns:  DataTableModel<any>[];
  @Input() service : DataTableService;
  @Input() sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  @Input() filterByJsonString : boolean = false;
  @Input() filteringPredicate : (data: any, filter: string) => boolean;
  @Input() selectionMode: number = TableSelectionMode.NONE_MODE;
  @Input() withAdd: boolean = false;
  @Input() hasListAccess: boolean = false;
  @Input() deleteMode: number = TableDeleteMode.NONE_MODE;
  @Input() maxCellStringLength: number;
  @Input() maxStringLength: number = 20;
  @Input() filterMap: Map<string, any>;
  @Input() _tableMenuAction: MenuActionObject[];
  @Input() booleanIconColumnDisplayList: string[];
  @Input() addButtonLabel: string;
  @Input() showFilterButton: boolean = false;
  @Input() inlineEditable: boolean = false;
  @Input() withInlineMenu: boolean = true;
  @Input() showBackButton: boolean = true;
  @Input() initiateSearchFromFilter: boolean = false;
  @Input() initialPageSize: number = 5;
  @Input() initialPageIndex: number = 0;
  @Input() additionalParams: Map<string, any>;
  @Output() mode = new EventEmitter<TableAction>();
  @Output() deleteEventEmitter = new EventEmitter<number>();
  @Output() selectionEventEmitter = new EventEmitter<object[]>();
  @Output() menuActionEventEmitter = new EventEmitter<MenuActionEmittedObject>();

  @Input() inputFilterEventSubject: Observable<Map<string, any>>;
  private filterEventsSubscription: Subscription;
  @Input() inputReloadEventSubject: Observable<boolean>;
  private reloadEventsSubscription: Subscription;
  @Input() inputInlineEditEventSubject: Observable<ResponsiveListInlineEditObject>;
  private inlineEditEventsSubscription: Subscription;
  @Input() tableTitleLabel: string = "";
  @Input() isServerSideSorting: boolean = false;

  databaseChangeEventSubject: Subject<CustomDataSource<any>> = new Subject<CustomDataSource<any>>();

  // added for unicity of code
  filterValuesMap: Map<string, any> = new Map();

  tableDataSource : CustomDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  initCallDone = false;

  currentPage: TablePageResponse;
  selection = new SelectionModel(true, []);

  activeInlineEditingRows: Map<number, ResponsiveListInlineEditObject> = new Map<number, ResponsiveListInlineEditObject>();

  reloadLookup: boolean;
  isLoading: boolean = false;
  private sorting: SortingModel;

  constructor(private dialogService: DialogService, public readonly _translateService: TranslateService ) {
    super();
  }

  ngOnInit(){
    if(this.inputFilterEventSubject){
      this.filterEventsSubscription = this.inputFilterEventSubject.subscribe(filterMap => {
        this.filterValuesMap = filterMap;
        if(this.paginator) this.paginator?.firstPage();
        this.getCurrentPageData();
      });
    }
    if(this.inputReloadEventSubject){
      this.reloadEventsSubscription = this.inputReloadEventSubject.subscribe(value =>{
        if(this.paginator) this.paginator?.firstPage();
        this.getCurrentPageData();
      })
    }

    if(this.inlineEditable){
      if(this.inputInlineEditEventSubject){
        this.inlineEditEventsSubscription = this.inputInlineEditEventSubject.subscribe(value =>{
          if(value.id < 0 && !value.afterSave){
            this.tableDataSource.filteredData.unshift(value.form.getRawValue());
          }

          if(value.afterSave){
            this.tableDataSource.filteredData.forEach((row,index) => {
              if(row.id == value.idBeforeSave){
                let rowArr = new Array()
                rowArr = this.tableDataSource.filteredData;
                rowArr[index] = value.form.getRawValue();
                this.tableDataSource.filteredData = rowArr;
                // this.tableDataSource.filteredData.unshift(value.form.getRawValue());
                this.reloadLookup = value.reloadLookup;
                this.getServerData(value.reloadLookup);
              }
            });
          }
          this.tableDataSource.data = [...this.tableDataSource.data];
          this.activeInlineEditingRows.set(value.id, value);
        });
      }

    }
  }

  ngOnDestroy() {
    if(this.filterEventsSubscription) this.filterEventsSubscription.unsubscribe();
    if(this.reloadEventsSubscription) this.reloadEventsSubscription.unsubscribe();
    if(this.inputInlineEditEventSubject) this.inlineEditEventsSubscription.unsubscribe();
  }

  updateViewMode(event: TableAction){
    this.mode.emit({mode: event.mode, id: event.id});
  }

  ngAfterViewInit(): void {
    if(!this.initiateSearchFromFilter) this.getTableDataWithParams();
  }

  getParameters(): Map<string, any>{
    let params : Map<string, any> = new Map();
    let pageSize: number = this.initialPageSize;
    let pageIndex: number = this.initialPageIndex;
    let sortingField = this.sorting?.field;
    let sortingDirection = this.sorting?.direction;
    if(this.paginator){
      pageIndex = this.paginator?.pageIndex >= 0 ? this.paginator?.pageIndex : this.initialPageSize;
      pageSize = this.paginator?.pageSize >= 0 ? this.paginator?.pageSize : this.initialPageIndex;
    }
    params.set("page", pageIndex);
    params.set("size", pageSize);
    if(sortingField){
      params.set("sort", sortingField + "," + sortingDirection);
    }
    if(this.additionalParams != undefined){
      for (const [key, value] of this.additionalParams.entries()) {
        params.set(key, value);
      }
    }
    // if(this.predefinedFilter) this.predefinedFilter.forEach(function(value, key){ params.set(key, value); });
    if(this.filterValuesMap) this.filterValuesMap.forEach(function(value, key){ params.set(key, value); });
    return params;
  }

  getTableDataWithParams(reloadLookup?: boolean){
    let params = this.getParameters();
    if(this.hasListAccess && this.checkForInitCall(params)){
      params = this.cleanParams(params);
      this.isLoading = true;
      this.service.getTableDataWithPrarams(params).subscribe(response => {
        this.isLoading = false;
        this.currentPage = response.body;
        this.tableDataSource = new CustomDataSource(this.currentPage.content,reloadLookup);
        this.tableDataSource.paginator = this.paginator;
        this.paginator.length = this.currentPage.totalElements;
        this.adaptDatasourceFilters();
        this.databaseChangeEventSubject.next(this.tableDataSource);
      });
    } else{
      this.cleanInitCallFromFilterValuesMap();
    }
  }


  adaptDatasourceFilters(){
    if(this.tableDataSource){
      if(this.filteringPredicate){
        this.tableDataSource.filterPredicate = this.filteringPredicate;
        this.tableDataSource.sortingDataAccessor = this.sortingDataAccessor ? this.sortingDataAccessor : (data, header) => data[header];
      } else if(this.filterByJsonString){
        this.tableDataSource.sortingDataAccessor = this.sortingDataAccessor ? this.sortingDataAccessor : (data, header) => data[header];
        this.tableDataSource.filterPredicate = (data: any, filter) => {
          const dataStr = JSON.stringify(data).toLowerCase();
          return dataStr.indexOf(filter) != -1;
        }
      }
    }
  }

  getCurrentPageData(reloadLookup?: boolean){
    let params = this.getParameters();
    if(this.checkForInitCall(params)){
      params = this.cleanParams(params);
      this.isLoading = true;
      this.service.getTableDataWithPrarams(params).subscribe(response => {
        this.isLoading = false;
        this.currentPage = response.body;
        this.tableDataSource = new CustomDataSource(this.currentPage?.content, reloadLookup);
        this.paginator.length = this.currentPage.totalElements;
        this.adaptDatasourceFilters();
        this.databaseChangeEventSubject.next(this.tableDataSource);
      });
    } else{
      this.cleanInitCallFromFilterValuesMap();
    }
  }

  checkForInitCall(params: Map<string, any>){
    if(params != undefined && params.has("isInitCall")){
      let isInitCall: boolean = params.get("isInitCall");
      if(isInitCall && this.initCallDone) {
        return false;
      } else{
        this.initCallDone = true;
      }
    }
    return true;
  }

  cleanParams(params: Map<string, any>) : Map<string, any>{
    if(params != undefined) params.delete("isInitCall");
    this.cleanInitCallFromFilterValuesMap();
    return params;
  }

  cleanInitCallFromFilterValuesMap() : void{
    if(this.filterValuesMap != undefined) this.filterValuesMap.delete("isInitCall");
  }

  // TABLE FILTER
  applyFilter(event: Event) {
    if(this.tableDataSource){
      const filterValue = (event.target as HTMLInputElement).value;
      this.tableDataSource.filter = filterValue.trim().toLowerCase();
      if (this.tableDataSource.paginator) {
        this.tableDataSource.paginator.firstPage();
      }
    }
  }

  public getServerData(reloadLookup?: boolean){
    this.getCurrentPageData(reloadLookup);
  }


  deleteObject(elementId: number){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.generic-delete'),
      "message": this._translateService.instant('siren-survey-translation.popup-module.messages.generic-delete'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response)  this.deleteEventEmitter.emit(elementId);
    });
  }

  handleSelection(emittedSelection: SelectionEmittedObject){
    if(this.selectionMode != TableSelectionMode.NONE_MODE){
      if(emittedSelection.checked && this.selectionMode == TableSelectionMode.SINGLE_SELECT_MODE) this.selection.clear();
      this.selection.toggle(emittedSelection.object);
    }
  }

  // TABLE ROW SELECTION
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() : boolean {
    if(this.tableDataSource && this.tableDataSource.data){
      const numSelected = this.selection.selected.length;
      const numRows = this.tableDataSource.data.length;
      return numSelected === numRows;
    } else{
      return false;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.selection.select(...this.tableDataSource.data);
    }
  }

  checkboxLabel(rowId?: number): string {
    // if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${this.selection.isSelected(rowId) ? 'deselect' : 'select'} row ${rowId + 1}`;
  }

  submitSelection(){
    if(this.selection && this.selection?.selected != undefined && this.selection?.selected.length > 0){
      this.selectionEventEmitter.emit(this.selection.selected);
    }
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    this.menuActionEventEmitter.emit(menuAction);
  }

  sortingHandler(sorting: SortingModel){
    this.sorting = sorting;
    this.getCurrentPageData();
  }
}






export class SelectionEmittedObject{
  object: any;
  checked: boolean;

  constructor(object: any, checked: boolean){
    this.object = object;
    this.checked = checked;
  }
}


