import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageFileTypePipe } from './image-file-type.pipe';



@NgModule({
  declarations: [
    ImageFileTypePipe,
  ],
  exports: [
    ImageFileTypePipe,
  ]
})
export class ImageFileTypeModule { }
