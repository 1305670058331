import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.sass']
})
export class WarningDialogComponent implements OnInit {

  public canClose = true;

  constructor(public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.canClose = this.data.canClose;

  }
  /**
   * Close the dialog
   * @param submit : boolean to return to the dialog opener
   */
  public buttonClicked(submit: boolean) {
    this.dialogRef.close(submit);
  }

}
