<form [formGroup]="form" (ngSubmit)="saveIncident()">
  <div class="dialog-header">
    <!-- <h3>{{'incident.fields.new-incident' | translate}}</h3> -->
    <p>
      {{'incident.fields.new-incident' | translate}}
    </p>
  </div>
  <div class="incident-form container">
    <div class="row">
      <mat-form-field class="full-width-element" >
        <mat-label class="">{{'incident.fields.title' | translate}}</mat-label>
        <input  matInput  class="" formControlName="title">
      </mat-form-field>

    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-6 col-12">
        <mat-form-field class="full-width-element">
          <mat-label class="" >{{'incident.fields.incident-type' | translate}}</mat-label>
          <mat-select class="" formControlName="incidentType" [compareWith]="objectComparisonFunction">
            <mat-option *ngFor="let incidentType of incidentTypes" [value]="incidentType" >
              {{ incidentType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-sm-6 col-12">
        <mat-form-field class="full-width-element">
          <mat-label class="" >{{'incident.fields.region' | translate}}</mat-label>
          <mat-select class="" formControlName="region" [compareWith]="objectComparisonFunction">
            <mat-option *ngFor="let region of regions" [value]="region" >
              {{ region.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    <div class="col-lg-4 col-sm-6 col-12">
      <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="{{'incident.fields.choose-date' | translate}}" formControlName="dateTime">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [enableMeridian]="true"
                                [disableMinute]="false" [hideTime]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>

    </div>
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-12">
        <mat-checkbox formControlName="external">
          {{'incident.fields.external-event' | translate}}
        </mat-checkbox>
      </div>
    </div>

    <div class="col-12"  class="involved-institution">
      <h5>{{'incident.fields.involved-security' | translate}}</h5>
      <div class="involved-institution-container" >
        <mat-checkbox *ngFor="let securityForce of securityForces" class="checkbox-container" [checked]="isSecurityForceEnvolved(securityForce.id)"
          (click)="$event.stopPropagation()" (change)="setSecurityForceAsEnvolved($event, securityForce.id)">
          {{ securityForce.value }}
        </mat-checkbox>
      </div>
      </div>
    <div class="col-12">
      <mat-form-field class="full-width-element" >
        <mat-label class="">{{'incident.fields.description' | translate}}</mat-label>
        <textarea  matInput type="text-area" autocomplete="off" min="0" class="" formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <div class="dialog-button-container">
      <button type="submit" [disabled]="disableSubmit" [ngClass]="_translateService.currentLang == 'ar' ? 'btn-submit-rtl' : '' " class="btn btn-submit">
        <span style="white-space: nowrap;">{{'global.save' | translate}}</span>
      </button>
    </div>
  </div>
</form>
