import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataTableModel } from "@siren-survey/app/models/shared/cell-data-model";
import {
  MenuActionEmittedObject,
  MenuActionObject,
} from "@siren-survey/app/models/shared/datatable-objects.model";
import { SurveyUser } from "@siren-survey/app/models/survey/surveyUser";
import { SurveyUsersService } from "@siren-survey/app/services/survey-users.service";
import { Subject } from "rxjs";
import { ResponsiveListViewComponent } from "../../shared/responsive-list-view/responsive-list-view.component";
import {
  ResponsiveListInlineEditObject,
  TableAction,
} from "@siren-survey/app/models/shared/table-action";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TableFilterTypeEnum } from "@siren-survey/app/models/shared/table-filter.model";
import { DatatableInlineEdit } from "../../shared/datatable/datatable-inline-edit.service";
import { CustomValidators } from "src/app/services/shared/validators.service";
import { DialogService } from "@siren-survey/app/services/shared/dialog.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-survey-users",
  templateUrl: "./survey-users.component.html",
  styleUrls: ["./survey-users.component.sass"],
})
export class SurveyUsersComponent
  extends DatatableInlineEdit
  implements AfterViewInit
{
  constructor(
    public service: SurveyUsersService,
    public dialogService: DialogService,
    private _snackbar: MatSnackBar,
    private readonly _translateService: TranslateService
  ) {
    super();
  }

  isInlineEditable(): boolean {
    return true;
  }
  getTableFormGroupModel(): FormGroup<any> {
    return new FormGroup({
      id: new FormControl({ value: undefined, disabled: true }),
      username: new FormControl({ value: "", disabled: false }, [
        Validators.required,
        CustomValidators.noWhitespaceValidator,
      ]),
    });
  }
  triggerInlineEditEventSubject(
    inlineEditObject: ResponsiveListInlineEditObject
  ): void {
    if (this.inlineEditEventSubject)
      this.inlineEditEventSubject.next(inlineEditObject);
  }
  getTableService() {
    return this.service;
  }
  getValidForm(menuAction: MenuActionEmittedObject): boolean {
    menuAction.form.markAllAsTouched();
    menuAction.form.updateValueAndValidity();
    return menuAction.form.valid;
  }

  private _surveyId: number;
  private _isAdding: boolean = false;

  @Input("surveyId") set surveyId(value: number) {
    this._surveyId = value;
    this.service.changeSurvey(value);
  }

  get surveyId() {
    return this._surveyId;
  }

  @ViewChild(ResponsiveListViewComponent, { static: false })
  listView: ResponsiveListViewComponent;

  _tableColumns: DataTableModel<SurveyUser>[] = [
    {
      columnDef: "username",
      type: TableFilterTypeEnum.Lookup,
      lookupName: "users",
      lookupMethod: this.service.getUsersLookup.bind(this.service),
      headerTranslationPath:
        "siren-survey-translation.survey-form.fields.username",
      header: this._translateService.instant(
        "siren-survey-translation.survey-form.fields.username"
      ),
      cell: (element: SurveyUser) => `${element.userName}`,
      width: undefined,
      contentClass: "leftAlign",
    },
    {
      columnDef: "_menu_inline",
      type: TableFilterTypeEnum.String,
      lookupName: undefined,
      lookupsValueById: undefined,
      headerTranslationPath: "",
      header: "",
      cell: undefined,
      width: "25px",
    },
  ];

  _tableMenuAction: MenuActionObject[] = [
    {
      name: this._translateService.instant("global.delete"),
      actionName: "DELETE_ACTION",
      iconStyle:
        "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;",
      iconUrl: "assets/icons/delete-icon.svg",
    },
  ];
  _sortingDataAccessor: (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate: (data: any, filter: string) => boolean;
  filterEventSubject: Subject<Map<string, any>> = new Subject<
    Map<string, any>
  >();
  inlineEditEventSubject: Subject<ResponsiveListInlineEditObject> =
    new Subject<ResponsiveListInlineEditObject>();
  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  initiateSearchFromFilter = true;
  lastCreatedId = -1;

  ngAfterViewInit(): void {
    this.listView.getServerData();
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject) {
    if (menuAction && menuAction.actionName && menuAction.object) {
      if (menuAction.actionName == "DELETE_ACTION") {
        this.dialogService
          .confirmDialog({
            title: this._translateService.instant(
              "siren-survey-translation.survey-form.messages.delete-title"
            ),
            message: this._translateService.instant(
              "siren-survey-translation.survey-form.messages.delete-user-survey-access-message"
            ),
            confirmText: this._translateService.instant(
              "siren-survey-translation.survey-form.actions.yes"
            ),
            cancelText: this._translateService.instant(
              "siren-survey-translation.survey-form.actions.no"
            ),
          })
          .subscribe((response) => {
            if (response) {
              this.service.removeUserSurveyLink(menuAction.object.id).subscribe(
                () => {
                  this.listView.getServerData(true);
                },
                (error) => {
                  let message =
                    error &&
                    error.error &&
                    error.error.message.includes("ConstraintViolationException")
                      ? this._translateService.instant(
                          "error.record-depending-error"
                        )
                      : this._translateService.instant("error.record-error");
                  this._snackbar.open(message, null, { duration: 3000 });
                }
              );
            }
          });
      }
      if (menuAction.actionName == "SAVE_ACTION") {
        this.service
          .addUserSurveyLink(this.surveyId, menuAction.object.username.id)
          .subscribe(() => {
            this._isAdding = false;
            this.listView.getServerData(true);
          });
      }
      if (menuAction.actionName == "CANCEL_ACTION") {
        this._isAdding = false;
      }
    }
  }

  updateViewMode(event: TableAction) {
    if (event.mode == TableAction.CREATE_MODE) {
      if (this._isAdding) {
        this._snackbar.open(this._translateService.instant("siren-survey-translation.survey-form.messages.complete-add-process"), null, { duration: 3000 });
        return;
      }
      this._isAdding = true;
      let editFormGroup = new FormGroup({
        id: new FormControl({ value: this.lastCreatedId, disabled: true }),
        username: new FormControl({ value: "", disabled: false }),
      });
      let inlineEditObject = new ResponsiveListInlineEditObject(
        this.lastCreatedId,
        this.lastCreatedId,
        editFormGroup,
        false
      );
      this.inlineEditEventSubject.next(inlineEditObject);
    }
  }
}
