import { Component, ViewChild, Input, OnDestroy, OnInit , AfterViewInit, Output, EventEmitter, Inject, ElementRef, Renderer2, ViewChildren, QueryList } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';

import { DataTableModel } from '../../../models/shared/cell-data-model';
import { ResponsiveListInlineEditObject, TableAction, TableDeleteMode, TableSelectionMode } from '../../../models/shared/table-action';
import { SelectableTableRow } from '../../../services/shared/interfaces/selectable-table-row';
import { SelectionEmittedObject } from '../responsive-list-view/responsive-list-view.component';
import { TableFilterTypeEnum } from '../../../models/shared/table-filter.model';
import { LookupModel } from '../../../models/shared/lookup.model';
import { LookupService } from '../../../services/lookups/lookup.service';
import { DropdownComparator } from '../component-base/dropdown-comparator';
import { MenuActionObject, MenuActionEmittedObject } from '../../../models/shared/datatable-objects.model';
import { Utils } from '../../../utils/survey-utils';
import { SettingsService } from '../../../shared/services/settings.service';
import { MonthService } from '../../../services/shared/month.service';
import { CustomDataSource } from '../responsive-list-view/custom-data-source';
import { TranslateService } from '@ngx-translate/core';
import { SortingModel } from '@siren-survey/app/models/shared/sorting.model';


@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent extends DropdownComparator implements OnInit, OnDestroy, AfterViewInit  {
  @Input() tableColumns:  DataTableModel<any>[];
  // @Input() withSelect: boolean = false;
  @Input() selectionMode: number = TableSelectionMode.NONE_MODE;
  @Input() withAdd: boolean = false;
  @Input() deleteMode: number = TableDeleteMode.NONE_MODE;
  @Input() maxCellStringLength: number;
  @Input() booleanIconColumnDisplayList: string[];
  @Input() addButtonLabel: string;
  @Input() tableTitleLabel: string = "";
  @Input() showFilterButton: boolean = false;
  @Input() showBackButton: boolean = true;
  @Input() inlineEditable: boolean = false;

  @Input() tableDataSource : CustomDataSource<any>;
  @Input() isLoading: boolean = false;
  // @Input() listAndCount: ListAndCount;
  @Input() databaseChangeEventSubject: Observable<CustomDataSource<any>>;
  private databaseChangeEventSubscription: Subscription;

  @Output() mode = new EventEmitter<TableAction>();
  @Output() deleteEventEmitter = new EventEmitter<number>();
  @Output() selectionEventEmitter = new EventEmitter<SelectionEmittedObject>();

  @Input() _tableMenuAction: MenuActionObject[];
  @Output() menuActionEventEmitter = new EventEmitter<MenuActionEmittedObject>();

  @Input() selection;
  @Input() activeInlineEditingRows: Map<number, ResponsiveListInlineEditObject> = new Map<number, ResponsiveListInlineEditObject>();

  @Input() isServerSideSorting: boolean = false;
  @Output() sortingEventEmitter = new EventEmitter<SortingModel>();

  @ViewChild(MatSort) sort: MatSort;

  public tableDeleteMode: typeof TableDeleteMode = TableDeleteMode;
  public tableSelectionMode: typeof TableSelectionMode = TableSelectionMode;

  displayedColumnsWithAction: string[];

  errorMessage: string = "";

  tableFilterTypeEnum: typeof TableFilterTypeEnum = TableFilterTypeEnum;
  rowAdded : boolean = false;
  public lookups: Map<string, LookupModel[]> = new Map();
  public lookupObjects: Map<string, LookupModel[]> = new Map();

  editInlineButton: MenuActionObject = { name: this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg" };
  saveInlineButton: MenuActionObject = { name: this._translateService.instant('global.save'), actionName: "SAVE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg" };
  cancelInlineButton: MenuActionObject = { name: this._translateService.instant('global.cancel'), actionName: "CANCEL_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg" };
  filterValue: string;
  private sortingSubscription: Subscription;

  constructor(
    private _location: Location,
    private _lookupService: LookupService,
    private _settingsService: SettingsService,
    private renderer: Renderer2,
    private _translateService: TranslateService){
    super();
  }

  private elementsWithMenuActions: number[] = [];
  @ViewChildren('myDiv') myDivs: QueryList<ElementRef>;

  ngOnInit(): void {
    if(this.deleteMode == TableDeleteMode.SELECTION_MODE) this.selectionMode = TableSelectionMode.MULTI_SELECT_MODE;
    this.displayedColumnsWithAction = Utils.deepClone(this.tableColumns?.map(col => col.columnDef));
    if(this.selectionMode != TableSelectionMode.NONE_MODE) this.displayedColumnsWithAction.unshift("select");
    this.databaseChangeEventSubscription = this.databaseChangeEventSubject.subscribe(tableDataSource => {
        if(tableDataSource.getAdditionalParam()){
          this.getLookups();
        }
        if(tableDataSource && !this.isServerSideSorting) tableDataSource.sort = this.sort;
    });
    this.filterValue = this.tableDataSource?.filter ? this.tableDataSource?.filter : "";
    this.getLookups();
  }

  getLookups(){
    let lookupNames: string[] = [];
    let lookupMethods: Map<string, () => Observable<LookupModel[]>> = new Map();
    let lookupObjectNames: string[] = [];
    let needsMonthLookup: boolean = false;
    this.tableColumns.forEach(column => {
      if(column.lookupName && column.type && column.type == TableFilterTypeEnum.Lookup && !column.lookupMethod ) lookupNames.push(column.lookupName);
      if(column.lookupName && column.type && column.type == TableFilterTypeEnum.Lookup && column.lookupMethod ) lookupMethods.set(column.lookupName, column.lookupMethod);
      if(column.lookupName && column.type && column.type == TableFilterTypeEnum.LookupObject) lookupObjectNames.push(column.lookupName);
      if(column.lookupName && column.type && column.type == TableFilterTypeEnum.LookupObjectEnumeration) lookupObjectNames.push(column.lookupName);
      if(column.lookupName && column.type && column.type == TableFilterTypeEnum.Month) needsMonthLookup = true;
    });

    if(lookupNames && lookupNames.length > 0){
      this._lookupService.getLookupsService(lookupNames, false, true).subscribe(response => {
        if(response?.body){
          lookupNames.forEach(name => {
            let currLookup: LookupModel[] = response.body[name];
            if(currLookup) this.lookups.set(name, currLookup);
          });
        }
      });
    }

    if(lookupMethods && lookupMethods.size > 0){
      lookupMethods.forEach((value: () => Observable<LookupModel[]>, key: string) => {
        value().subscribe(response => {
          if(response){
            this.lookups.set(key, response);
          }
        });
      });
    }

    if(needsMonthLookup) this.lookupObjects.set("Months", MonthService.getMonthsLookupList());
    if(lookupObjectNames && lookupObjectNames.length > 0){
      this._lookupService.getLookupObjectsService(lookupObjectNames, false, true).subscribe(response => {
        if(response?.body){
          lookupObjectNames.forEach(name => {
            let currLookup: LookupModel[] = response.body[name];
            if(currLookup) this.lookupObjects.set(name, currLookup);
          });
        }
      });
    }
  }

  getLookupByName(filterByName: string): LookupModel[]{
    let response: LookupModel[] = [];
    if(this.lookups && filterByName != undefined){
      if(this.lookups.size > 0) response = this.lookups.get(filterByName);
    }
    return response;
  }

  getLookupValueForObjectEnum(filterByName: string, value: any): string{
    let response: string = undefined;
    if(this.lookupObjects && filterByName != undefined){
      if(this.lookupObjects.size > 0){
        let lookupList: LookupModel[] = this.lookupObjects.get(filterByName);
        for(let element of lookupList){
          if(response != undefined) break;
          if(element.id == value) response = element.value;
        }
      }
    }
    return response;
  }

  getMonthLookupValueForObject(value: any): string{
    let response: string = undefined;
    if(value != undefined){
      for(let element of MonthService.getMonthsLookupList()){
        if(response != undefined) break;
        if(element.id == value) response = element.value;
      }
    }
    return response;
  }

  getBenefScores(value: any, index: number, columnIndex: number)  {
    const myDivsArray = this.myDivs.toArray();

    // Iterate over each div element and check its ID
    for (const divElement of myDivsArray) {
      const idParts = divElement.nativeElement.id.split('_');
      const divIndex = +idParts[1];
      const divColumnIndex = +idParts[2];

      // Check if the div matches both index and columnIndex
      if (divIndex === index && divColumnIndex === columnIndex) {
        const scores = value.split(",");
        let scoreValue = scores[0].replace('undefined', this._translateService.instant('beneficiary-form.titles.no-score')); // Replace first undefined with 'no-score'
        scoreValue = scoreValue.replace(/\s*-\s*undefined/g, ''); // Remove "- undefined" if found
        const innerHtml = `
        <div class='wrapped-flex'>
          <div class='benef-score-level' style='border-color:${scores[1]}'>
            ${scoreValue}
          </div>
        </div>`;
        this.setInnerHTML(innerHtml, divElement);
        break;
      }
    }
  }

  setInnerHTML(htmlContent: string, divElement: ElementRef) {
    if (divElement && divElement.nativeElement) {
      this.renderer.setProperty(divElement.nativeElement, 'innerHTML', htmlContent);
    }
  }

  getLookupObjectByName(filterByName: string): LookupModel[]{
    let response: LookupModel[] = [];
    if(this.lookupObjects.size > 0){
        response = this.lookupObjects.get(filterByName);
      }
    return response;
  }

  ngOnDestroy() {
    if(this.databaseChangeEventSubscription) this.databaseChangeEventSubscription.unsubscribe();
    if(this.sortingSubscription) this.sortingSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    if(!this.isServerSideSorting){
      if(this.tableDataSource) this.tableDataSource.sort = this.sort;
    }
    else{      
      this.sortingSubscription = this.sort.sortChange.subscribe(() => {
        this.applySorting(this.sort);
      });
    }    
    this.myDivs.changes.subscribe(() => {
      this.updateDivs();
    });
    this.updateDivs();

  }

  // Function to update all divs
  updateDivs() {
    // Ensure the QueryList is not empty
    if (this.myDivs && this.myDivs.length > 0) {
      this.myDivs.forEach((div: ElementRef) => {
      });
    }
  }
  isCellEditable(elementId: number): boolean{
    let isCellEditable: boolean = false;
    if(this.inlineEditable && this.activeInlineEditingRows != null && (this.activeInlineEditingRows.has(elementId) && !this.activeInlineEditingRows.get(elementId).afterSave)){

      isCellEditable = true;
    }
    return isCellEditable;
  }

  getObjectFormGroup(elementId: number): FormGroup{
    let form: FormGroup;
    if(this.inlineEditable && this.activeInlineEditingRows != null && this.activeInlineEditingRows.has(elementId)){

      form = this.activeInlineEditingRows.get(elementId).form;
    }
    return form;
  }

  // TABLE FILTER
  applyFilter(event: Event) {
    if(this.tableDataSource){
      const filterValue = (event.target as HTMLInputElement).value;
      this.tableDataSource.filter = filterValue.trim().toLowerCase();
      if (this.tableDataSource.paginator) {
        this.tableDataSource.paginator.firstPage();
      }
    }
  }

  applySorting(sort: MatSort) {
    if(sort.direction == "" || sort.direction == undefined) {
      this.sortingEventEmitter.emit(null);
    }
    else{
      this.sortingEventEmitter.emit({ 
        field: sort.active, 
        direction: sort.direction 
      });
    }
  }


  canBeDeleted(row?: SelectableTableRow): boolean {
    let canBeSelected = (this.selectionMode != TableSelectionMode.NONE_MODE);
    // if (!row) {
    //   row.id;
    // }
    return canBeSelected;
  }

  add(){
    this.mode.emit({mode: TableAction.CREATE_MODE});
  }

  deleteSelection(){
    if(this.selection.selected.length > 0){
      this.mode.emit({mode: TableAction.CREATE_MODE});
    } else{
      this.errorMessage = "Please select at least one object before deleting.";
    }
  }

  delete(elementId: number){
    if(elementId != undefined) this.deleteEventEmitter.emit(elementId);
  }

  edit(id: any){
    this.mode.emit({mode: TableAction.EDIT_MODE, id: id});
  }

  popupModalClosed(){
    this.errorMessage = "";
  }


  handleSelection(event: MatCheckboxChange, row: any){
    this.selectionEventEmitter.next(new SelectionEmittedObject(row, event.checked));
  }

  shouldReplaceBooleanValueByIcon(value: string): boolean{
    return this.booleanIconColumnDisplayList && this.booleanIconColumnDisplayList.includes(value);
  }

  isBooleanTrue(value: any){
    let isTrue = value != undefined
    if(isTrue){
      value = value.trim().toLowerCase();
      isTrue = value == 'yes' || value == 'true' || value == true;
    }
    return isTrue;
  }

  onContextMenuClicked(object: any, action: string,form: FormGroup){
    if(action=="CANCEL_ACTION"){
      this.activeInlineEditingRows.delete(object.id);
      if(object.id < 0){
        let currentIndex = 0;
        let elementIndex = -1;
        for(let element of this.tableDataSource.data){
          if(element.id == object.id) {
            elementIndex = currentIndex;
            break;
          } else currentIndex++;
        }
        if(elementIndex >= 0){
          this.tableDataSource.data.splice(elementIndex, 1);
          this.tableDataSource.data = [...this.tableDataSource.data];
        }
      }
    } else if(object.id && object.id < 0){
      object = this.activeInlineEditingRows.get(object.id).form.getRawValue();
    }

    if(object.id && object.id > 0 && (form != undefined && !form.contains('id'))){
      form.addControl('id', new FormControl({value:  object.id, disabled: true}));
      form.value.id = object.id;
    }
    form == undefined ? this.menuActionEventEmitter.next(new MenuActionEmittedObject(object, action,form)) : this.menuActionEventEmitter.next(new MenuActionEmittedObject(form.getRawValue(), action,form));
  }

  isAllowedAction(element: any, menuAction: MenuActionObject): boolean{
    let allowedAction = Utils.isAllowedAction(element, menuAction);
    if(allowedAction) this.elementsWithMenuActions.push(element.id);
    return allowedAction;
  }

  // isAllowedAction(element: any, menuAction: MenuActionObject): boolean{
  //   let allowedAction = true;
  //   if(element && menuAction && menuAction.accessRightExpressions != undefined && menuAction.accessRightExpressions.size > 0){
  //     menuAction.accessRightExpressions.forEach(
  //       function(value, key){
  //         if(allowedAction) allowedAction = this.validateFunction(element, key, value);
  //       }
  //     );
  //   }
  //   if(allowedAction) this.elementsWithMenuActions.push(element.id);
  //   return allowedAction;
  // }

  // validateFunction(object: any, accessRightVariablePath: string, accessRightVariableExpression: string): boolean {
  //   let isValid = false;
  //   const variable = object[accessRightVariablePath];
  //   try {
  //     const validateExpression = new Function(accessRightVariablePath, `return ${accessRightVariableExpression}`);
  //     isValid = validateExpression(variable);
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   return isValid;
  // }

  hasNoMenuElement(element: any){
    return this.elementsWithMenuActions && element && element.id != undefined && !this.elementsWithMenuActions.includes(element.id);
  }

  goBack(){
    this._location.back();
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this._settingsService && this._settingsService.settings){
      url = this._settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  onKeyDownEvent(event: any){
    if(event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }
}
