import { Injectable } from '@angular/core';
import {
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Resolve
} from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { LookUpList } from 'src/app/models/admin/program/lookupType/lookupList';
import { LookupTypeService } from 'src/app/services/admin/lookup-type.service';

@Injectable({
  providedIn: 'root'
})

export class LookupResolver implements Resolve<LookUpList> {

  constructor(private _lookupService: LookupTypeService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LookUpList> {
    return this._lookupService.getLookupList().pipe(
            map(result => {
              return result.body;
            })
    );
  }
}
