import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IAdminAuthenticationService } from '@siren-auth/app/shared/services/adminAuthentication.interface';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchModuleEnum } from '../enum/user-role.enum';

@Injectable()
export class AdminGuard implements CanActivate {

  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  constructor(public authService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

   const url = state.url;

   const canAccess = url.includes('roles')?  this.authService.isModuleAccessible(this.marchModuleEnum.USERS_MGT.module): this.authService.isModuleAccessible(this.marchModuleEnum.ROLES_MGT.module);
    if (canAccess) {
        return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}
