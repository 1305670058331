import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CustomFormComponent } from '../custom-form/custom-form.component';
import { CustomFormActionModel, CustomFormModel } from '../custom-form/custom-form.model';

@Component({
  selector: 'bo-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.sass']
})
export class FilterComponent implements OnInit, OnChanges {
  @ViewChild(CustomFormComponent) customFormComponent?: CustomFormComponent;
  @ViewChild(MatExpansionPanel, { static: true }) matExpansionPanelElement: MatExpansionPanel;
  submitAction: CustomFormActionModel = new CustomFormActionModel('siren-admin-translation.generic.filter.search', false, false);
  cancelAction: CustomFormActionModel = new CustomFormActionModel('siren-admin-translation.generic.filter.cancel', false, false);
  @Input() data?: any;
  @Input() listenToChanges?: boolean = false;
  @Input() dataValues?: any;
  @Input() lookups?: any;
  @Input() formObj?: CustomFormModel;
  @Output('clickAction') clickAction = new EventEmitter<any>();
  @Output() fieldChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.formObj = { fields: this.data, lookups: this.lookups, data: this.dataValues };
  }

  changeField(event) {
    this.fieldChange.emit(event);
  }

  apply(event) {
    if (event.apply) {
      Object.entries(event.formVal).forEach(([key, value]) => {
        let find = this.data.find(element => { return (element.prop === key) ? element : null; })
        event.formVal[key] = { value: value, type: find.type };
      });
    }
    else {
      this.customFormComponent.resetForm();
    }
    this.clickAction.emit(event.formVal);
  }

}
