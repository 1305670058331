import { Injectable } from "@angular/core";
import { SettingsService } from "../shared/services/settings.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class PasswordComplexity {

  public static COMPLECITY_SPECIAL_CHAR: string = "_-@#$!%*?&";

  constructor( private settingsService: SettingsService, private _translateService: TranslateService) {
  }

  /*getPasswordPattern(): string{
    let validatorPattern = "[";
    let passwordPattern = "";
    if(this.settingsService.settings.alphabet){
    if(this.settingsService.settings.upperCase) {
        passwordPattern += "(?=.*[A-Z])";
        validatorPattern += "A-Z";
      }
      if(this.settingsService.settings.lowerCase){
        passwordPattern += "(?=.*[a-z])";
        validatorPattern += "a-z";
      }
    }
    if(this.settingsService.settings.digits){
      passwordPattern += "(?=.*\\d)";
      validatorPattern += "\\d";
    }
    if(!this.settingsService.settings.noWhiteSpace) {
       //except space
      validatorPattern += " ";
    }
    if(this.settingsService.settings.specialCharacters){
      passwordPattern += "(?=.*[?_-!@#\$%\^&\*])";
      validatorPattern += PasswordComplexity.COMPLECITY_SPECIAL_CHAR;
    }
    validatorPattern += "]";

    if(this.settingsService.settings.minLength && this.settingsService.settings.maxLength){
      validatorPattern +="{" + this.settingsService.settings.minLength + "," + this.settingsService.settings.maxLength + "}";
    } else if(this.settingsService.settings.minLength){
      validatorPattern +="{"+ this.settingsService.settings.minLength + ",100}";
    } else if(this.settingsService.settings.minLength){
      validatorPattern +="{0,"+ this.settingsService.settings.maxLength+"}";
    }
    passwordPattern += validatorPattern;
    return passwordPattern;
  }*/

  getPasswordInfoMessage(): string{
    let passwordMessage = "";
    if(this.settingsService.settings.maxLength) passwordMessage = this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least") + this.settingsService.settings.minLength + this._translateService.instant("siren-admin-translation.admin_module.password-complexity.characters");
    if(this.settingsService.settings.minLength) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-maximum") + this.settingsService.settings.maxLength +  this._translateService.instant("siren-admin-translation.admin_module.password-complexity.characters"));
    if(this.settingsService.settings.alphabet){
      if(this.settingsService.settings.lowerCase) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least-one-lower-case-letter"));
      if(this.settingsService.settings.upperCase) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least-one-upper-case-letter"));
    }

    if(this.settingsService.settings.digits) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least-one-digit"));
    if(this.settingsService.settings.specialCharacters) passwordMessage = this.concatPasswordErrorString(passwordMessage, this._translateService.instant("siren-admin-translation.admin_module.password-complexity.at-least-one-special-char"));

    if(this.settingsService.settings.noWhiteSpace) passwordMessage = this.concatPasswordErrorStringWithDivider(passwordMessage, ". ", this._translateService.instant("siren-admin-translation.admin_module.password-complexity.pass-should-not-contain-space") );
    passwordMessage = passwordMessage != undefined && passwordMessage.trim() != "" ? this._translateService.instant("siren-admin-translation.admin_module.password-complexity.pass-must-contain")  + passwordMessage: "";
    return passwordMessage;
  }

  concatPasswordErrorString(base: string, suffix: string){
    return this.concatPasswordErrorStringWithDivider(base, ', ', suffix);
  }

  concatPasswordErrorStringWithDivider(base: string, divider: string, suffix: string){
    return base != undefined ? base += divider + suffix : suffix;
  }

  validatePasswordComplexity(password: string,_translateService: TranslateService): string[]{
    let error: string[] = [];
    if(password != undefined){
      if(this.settingsService.settings.noWhiteSpace && this.checkIfContainsSpaces(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-cannot-contain-spaces"));
      if(this.settingsService.settings.maxLength && !this.checkMaximumLength(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-be-less-than") + this.settingsService.settings.maxLength + _translateService.instant("siren-admin-translation.admin_module.password-complexity.characters"));
      if(this.settingsService.settings.minLength && !this.checkMinimumLength(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-be-at-least") + this.settingsService.settings.minLength + _translateService.instant("siren-admin-translation.admin_module.password-complexity.characters"));
      if(this.settingsService.settings.alphabet) {
        if(this.settingsService.settings.lowerCase && !this.checkIfContainsLowerCaseLetters(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-contain-at-least-one-lower-case letter"));
        if(this.settingsService.settings.upperCase && !this.checkIfContainsUpperCaseLetters(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-contain-at-least-one-upper-case-letter"));
      }
      if(this.settingsService.settings.digits && !this.checkIfContainsDigits(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-contain-at-least-one-number"));
      if(this.settingsService.settings.specialCharacters && !this.checkIfContainsSpecialCaseLetters(password)) error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-must-contain-at-least-one-special-character"));
    } else{
      error.push(_translateService.instant("siren-admin-translation.admin_module.password-complexity.password-cannot-be-empty"));
    }
    return error;
  }

  // VALUE CHECKS

  checkIfContainsSpaces(value: string){
    return value.includes(" ");
  }

  checkMinimumLength(value: string){
    return value.length >= this.settingsService.settings.minLength;
  }

  checkMaximumLength(value: string){
    return value.length <= this.settingsService.settings.maxLength;
  }

  checkIfContainsUpperCaseLetters(value: string){
    return this.checkPattern(value, /[A-Z]/);
  }

  checkIfContainsLowerCaseLetters(value: string){
    return this.checkPattern(value, /[a-z]/);
  }

  checkIfContainsDigits(value: string){
    return this.checkPattern(value, /\d/);
  }

  checkIfContainsSpecialCaseLetters(value: string){
    return this.checkPattern(value, /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
  }

  checkPattern(value: string, pattern: any){
    return value != undefined && pattern.test(value);
  }
}
