<div class="header" *ngIf="data?.title != undefined && data?.title.trim() != ''">
    <p>{{data?.title}}</p>
    <mat-icon [mat-dialog-close]="false">clear</mat-icon>
</div>
<div mat-dialog-content>
    {{data?.message}}
</div>
<div mat-dialog-actions [align]="'end'">
    <button mat-raised-button [mat-dialog-close]="false" [ngClass]=" hideCancelButton ? 'hide-cancel-button' : '' ">{{data?.cancelText}}</button>
    <button mat-raised-button color="accent" [mat-dialog-close]="true">{{data?.confirmText}}</button>
</div>
