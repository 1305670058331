<div class="multiselect_wrapper"
    [ngClass]="{'field-invalid' : ngControl.control.invalid }">
  
    <mat-form-field class="single-select" appearance="outline">
        <mat-label>{{ placeholderLabel | translate}}</mat-label>
        <mat-select #select [formControl]="ngControl.control" multiple (openedChange)="openChange($event)" panelClass={{customPanelClass}}>
        
            <mat-option *ngFor="let option of filteredDropdownList" [value]="option.id" (click)="optionSelected()"
                [ngClass]="{'hidden' : (ngControl.control?.value?.includes(option.id) && filteredDropdownList.length < dropdownList.length)}">
                {{option.name}} {{
                option.nameEn || option.nameEn || ""}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
