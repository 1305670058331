<form #questionBankModal [formGroup]="questionBankForm">
  <div class="question-label" style="align-items: center;" [ngClass]="surveyQuestion?.graded && isVisible && surveyQuestion?.question?.answerType?.key == 'GRADE_ONLY' ? 'full-width-element flex-row' : ''">
    <div>
      <span class="question-display" [ngClass]="isVisible ? '' : 'dimmed-question-title'">
        {{ questionIndex + 1 }}- {{surveyQuestion?.question?.question }}
        <span class="error-message" style="margin: 0px 10px;" *ngIf="!surveyQuestion?.optional">*</span>
        <span class="error-message" style="margin: 0px 10px;" *ngIf="isInvalid">{{'siren-survey-translation.error.question_mandatory' | translate}}</span>
      </span>
    </div>
    <!-- grade only -->
    <div  class="question-grade-div" *ngIf="surveyQuestion?.graded && isVisible && surveyQuestion?.question?.answerType?.key == 'GRADE_ONLY'">
      <mat-form-field class="block-div mat-input-without-padding mat-input-without-space-after">
        <input matInput type="number" autocomplete="off" min="0" max="{{surveyMaxGrade}}"  (keydown)="onKeyDownEvent($event)" class="" formControlName="grade">
      </mat-form-field>
      <span class="max-grade-display">/ {{ surveyMaxGrade }}</span>
    </div>
  </div>
  <div *ngIf="isVisible">
    <!-- radio button -->
    <div  class="full-width-element flex-row" *ngIf="surveyQuestion?.question?.answerType?.key == 'SINGLE_SELECT'">
      <mat-radio-group class="flex-column-container" [disabled]="!isEditMode">
        <mat-radio-button class="padding-radios" *ngFor="let answer of  surveyQuestion?.question?.answers"
          (click)="setSelectedSingleChoice(answer);$event.stopPropagation();" [value]="answer.id" [checked]="checkIfSelected(answer.id)">
          {{ answer.answer}}
        </mat-radio-button>
        <mat-radio-button class="padding-radios" (click)="addShowOther(true); $event.stopPropagation()" [checked]="checkIfHasOther(surveyQuestion?.question)" *ngIf="surveyQuestion?.question?.withOther" [value]="-1">
          {{'siren-survey-translation.survey-form.fields.other' | translate}}
        </mat-radio-button>
      </mat-radio-group>
      <div  class="question-grade-div" *ngIf="surveyGraded && surveyQuestion?.graded && !surveyQuestion?.question?.gradeByAnswer">
        <mat-form-field class="block-div mat-input-without-padding mat-input-without-space-after">
          <input matInput type="number" autocomplete="off" min="0" max="{{surveyMaxGrade}}"  (keydown)="onKeyDownEvent($event)" class="" formControlName="grade">
        </mat-form-field>
        <span class="max-grade-display">/ {{ surveyMaxGrade }}</span>
      </div>
    </div>
    <!-- checkbox -->
    <div class="full-width-element flex-row" *ngIf="surveyQuestion?.question?.answerType?.key == 'MULTI_SELECT'">
      <div class="flex-column-container">
        <mat-checkbox class="padding-radios"  [disabled]="!isEditMode" (change)="toogleSelectionMultipleChoice($event, answer);"
          *ngFor="let answer of surveyQuestion?.question?.answers; " [checked]="checkIfSelected(answer.id)">
          {{answer.answer}}
        </mat-checkbox>
        <mat-checkbox class="padding-radios"  [disabled]="!isEditMode" (click)="$event.stopPropagation()" *ngIf="surveyQuestion?.question?.withOther" [checked]="checkIfHasOther(surveyQuestion?.question)" (change)="handleShowOtherValueChange($event)" [checked]="showOther">
          {{'siren-survey-translation.survey-form.fields.other' | translate}}
        </mat-checkbox>
      </div>
      <div  class="question-grade-div" *ngIf="surveyGraded && surveyQuestion?.graded && !surveyQuestion?.question?.gradeByAnswer">
        <mat-form-field class="block-div mat-input-without-padding mat-input-without-space-after">
          <input matInput type="number" autocomplete="off" min="0" max="{{surveyMaxGrade}}"  (keydown)="onKeyDownEvent($event)" class="" formControlName="grade">
        </mat-form-field>
        <span class="max-grade-display">/ {{ surveyMaxGrade }}</span>
      </div>
    </div>
    <!-- textarea -->
    <div class="full-width-element flex-row" *ngIf="surveyQuestion?.question.answerType.key == 'LONG_TEXT'">
      <mat-form-field class="full-width-element" >
        <mat-label class="">{{'siren-survey-translation.survey-form.fields.answer' | translate}}</mat-label>
        <textarea  matInput type="text-area" autocomplete="off" min="0" class="" formControlName="answerValue"></textarea>
      </mat-form-field>
      <div  class="question-grade-div" *ngIf="surveyQuestion?.graded">
        <mat-form-field class="block-div mat-input-without-padding mat-input-without-space-after">
          <input matInput type="number" autocomplete="off" min="0" max="{{surveyMaxGrade}}"  (keydown)="onKeyDownEvent($event)" class="" formControlName="grade">
        </mat-form-field>
        <span class="max-grade-display">/ {{ surveyMaxGrade }}</span>
      </div>
    </div>
      <!-- textbox -->
    <div class="full-width-element flex-row" *ngIf="surveyQuestion?.question.answerType.key == 'TEXT'">
      <mat-form-field  class="full-width-element">
        <input matInput placeholder="{{'siren-survey-translation.survey-form.fields.answer' | translate}}" autocomplete="off" formControlName="answerValue" >
      </mat-form-field>
      <div  class="question-grade-div" *ngIf="surveyQuestion?.graded">
        <mat-form-field class="block-div mat-input-without-padding mat-input-without-space-after">
          <input matInput type="number" autocomplete="off" min="0" max="{{surveyMaxGrade}}"  (keydown)="onKeyDownEvent($event)" class="" formControlName="grade">
        </mat-form-field>
        <span class="max-grade-display">/ {{ surveyMaxGrade }}</span>
      </div>
    </div>
    <!-- number -->
    <div class="full-width-element flex-row" *ngIf="surveyQuestion?.question?.answerType?.key == 'NUMBER'">
      <mat-form-field >
        <input matInput type="number" autocomplete="off" min="0" class="" (keydown)="onKeyDownEvent($event)" formControlName="answerValue">
      </mat-form-field>
      <div  class="question-grade-div" *ngIf="surveyQuestion?.graded">
        <mat-form-field class="block-div mat-input-without-padding mat-input-without-space-after">
          <input matInput type="number" autocomplete="off" min="0" max="{{surveyMaxGrade}}"  (keydown)="onKeyDownEvent($event)" class="" formControlName="grade">
        </mat-form-field>
        <span class="max-grade-display">/ {{ surveyMaxGrade }}</span>
      </div>
    </div>
    <!-- decimal -->
    <div class="full-width-element flex-row" *ngIf="surveyQuestion?.question?.answerType?.key == 'DECIMAL'">
      <mat-form-field >
        <input matInput type="number" step=".1" placeholder="0.00" autocomplete="off" min="0" (keydown)="onKeyDownEvent($event)" class="" formControlName="answerValue">
      </mat-form-field>
      <div  class="question-grade-div" *ngIf="surveyQuestion?.graded">
        <mat-form-field class="block-div mat-input-without-padding mat-input-without-space-after">
          <input matInput type="number" autocomplete="off" min="0" max="{{surveyMaxGrade}}" (keydown)="onKeyDownEvent($event)" class="" formControlName="grade">
        </mat-form-field>
        <span class="max-grade-display">/ {{ surveyMaxGrade }}</span>
      </div>
    </div>
    <!-- grade only -->
    <!-- <div class="full-width-element flex-row" *ngIf="surveyQuestion?.question?.answerType?.key == 'GRADE_ONLY'">
      <div  class="question-grade-div" *ngIf="surveyQuestion?.graded">
        <mat-form-field class="block-div mat-input-without-padding mat-input-without-space-after">
          <input matInput type="number" autocomplete="off" min="0" max="{{surveyMaxGrade}}"  class="" formControlName="grade">
        </mat-form-field>
        <span class="max-grade-display">/ {{ surveyMaxGrade }}</span>
      </div>
    </div> -->
    <!-- withOther -->
    <div class="full-width-element" *ngIf="surveyQuestion?.question?.withOther && showOther">
      <mat-form-field  class="full-width-element mat-input-without-space-after">
        <input matInput placeholder="{{'siren-survey-translation.survey-form.fields.other' | translate}}" formControlName="otherResponse" >
      </mat-form-field>
    </div>
    <!-- withExplanation -->
    <div class="full-width-element" *ngIf="surveyQuestion?.question?.withExplanation">
      <mat-form-field  class="full-width-element mat-input-without-space-after">
        <textarea matInput  placeholder="{{'siren-survey-translation.survey-form.fields.explanation' | translate}}" type="text-area" autocomplete="off" min="0" class="" formControlName="explanation"></textarea>
      </mat-form-field>
    </div>
    <div class="alert-box-action error-message error-message-style" *ngIf="error">
      {{error}}
    </div>
  </div>
</form>







<!-- <div class="question-label">
  <span class="question-display">
    {{ questionIndex + 1 }}- {{question?.question}}
  </span>
  <div  class="question-grade-div" *ngIf="question?.graded && !question?.gradeByAnswer">
    <mat-form-field class="block-div mat-input-without-padding">
      <input matInput type="number" autocomplete="off" min="0" max="{{surveyMaxGrade}}"  class="" formControlName="grade">
    </mat-form-field>
    <span class="max-grade-display">/ {{ surveyMaxGrade }}</span>
  </div>
</div>
  <div  class="full-width-element" *ngIf="question?.answerType?.key == 'SINGLE_SELECT'">
      <mat-radio-group class="flex-column-container">
        <mat-radio-button class="padding-radios" *ngFor="let answer of question?.answers;"
          (click)="setSelectedSingleChoice(answer); $event.stopPropagation()" [value]="answer.id" >
          {{ answer.answer}}
        </mat-radio-button>
        <mat-radio-button class="padding-radios" (click)="addShowOther(); $event.stopPropagation()" *ngIf="question?.withOther" [value]="-1">
          {{'survey-form.fields.other' | translate}}
        </mat-radio-button>
      </mat-radio-group>
  </div>
  <div class="full-width-element flex-column-container" *ngIf="question?.answerType?.key == 'MULTI_SELECT'">
    <mat-checkbox class="padding-radios" (change)="toogleSelectionMultipleChoice($event, answer); $event.stopPropagation()"
      *ngFor="let answer of question?.answers; " [checked]="selection.isSelected(answer)">
      {{answer.answer}}
    </mat-checkbox>
    <mat-checkbox class="padding-radios" (click)="$event.stopPropagation()" *ngIf="question?.withOther" (change)="handleShowOtherValueChange($event)" [checked]="showOther">
      {{'survey-form.fields.other' | translate}}
    </mat-checkbox>
  </div>
<div class="full-width-element" *ngIf="question.answerType.key == 'LONG_TEXT'">
  <mat-form-field class="full-width-element" >
    <mat-label class="">{{'survey-form.fields.answer' | translate}}</mat-label>
    <textarea  matInput type="text-area" autocomplete="off" min="0" class="" formControlName="answerValue"></textarea>
  </mat-form-field>
</div>
<div class="full-width-element" *ngIf="question.answerType.key == 'TEXT'">
  <mat-form-field  class="full-width-element">
    <input matInput placeholder="{{'survey-form.fields.answer' | translate}}" autocomplete="off" formControlName="answerValue" >
  </mat-form-field>
</div>
<div class="full-width-element" *ngIf="question?.answerType?.key == 'NUMBER'">
  <mat-form-field class="">
    <input matInput type="number" autocomplete="off" min="0" class="" formControlName="answerValue">
  </mat-form-field>
</div>
<div class="full-width-element" *ngIf="question?.withOther && showOther">
  <mat-form-field  class="full-width-element">
    <input matInput placeholder="{{'survey-form.fields.other' | translate}}" formControlName="otherResponse" >
  </mat-form-field>
</div>
<div class="full-width-element" *ngIf="question?.withExplanation">
  <mat-form-field  class="full-width-element">
    <input matInput placeholder="{{'survey-form.fields.explanation' | translate}}" formControlName="explanation" >
  </mat-form-field>
</div> -->
