import { Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
  innerWidth: number;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  isMobile() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      return true;
    }
    return false;
  }

  goToHr() {
    this.router.navigate(['home/hr']);
  }
}
