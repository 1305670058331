
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyList } from '../../../models/survey/surveyList'
import { DataTableModel } from '../../../models/shared/cell-data-model';
import { TableAction } from '../../../models/shared/table-action';
import { SurveyService } from '../../../services/survey.service';
import { FilterOperator, TableFilterElement, TableFilterOutput, TableFilterTypeEnum } from '../../../models/shared/table-filter.model';
import { Subject } from 'rxjs';
import { MenuActionObject, MenuActionEmittedObject } from '../../../models/shared/datatable-objects.model';
import { TranslateService } from '@ngx-translate/core';
import { LookupService } from '../../../services/lookups/lookup.service';
import { ISurveyAuthenticationService } from '../../../shared/services/interfaces/authentication.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OpenLinkedSurveyQuestionService } from '../../../services/shared/open-linked-survey-question.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../../services/shared/dialog.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-survey-table',
  templateUrl: './survey-table.component.html',
  styleUrls: ['./survey-table.component.sass']
})
export class SurveyTableComponent implements OnInit {
  tableTitleLabel: string;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;

  showLoadingSpinner = false;

  predefinedFilter: Map<string, any>;
  surveyTypeParam: number;
  consumerPermissionEnum: { [index: string]: UserModule } = {};
  canAdd: boolean = false;
  hasListAccess: boolean = false;
  showAssignUsers: boolean = false;
  assignUsersSurveyId: number = 0;
  tableTitle: string;

  _booleanIconColumnDisplayList: string[] = [
    "enabled","published"
  ]

  _tableColumns  : DataTableModel<SurveyList> []= [
    {  columnDef: 'title', headerTranslationPath: "siren-survey-translation.survey-form.fields.title", header: this._translateService.instant('siren-survey-translation.survey-form.fields.title'),  cell: (element: SurveyList) => `${element.title}`, width: undefined, contentClass: 'leftAlign'},
    {  columnDef: 'description', headerTranslationPath: "siren-survey-translation.survey-form.fields.description", header: this._translateService.instant('siren-survey-translation.survey-form.fields.description'),  cell: (element: SurveyList) => `${element.description}`, width: undefined, contentClass: 'leftAlign'},
    {  columnDef: 'surveyType', headerTranslationPath: "siren-survey-translation.survey-form.fields.survey-type", header: this._translateService.instant('siren-survey-translation.survey-form.fields.survey-type'),  cell: (element: SurveyList) => `${element.surveyType.value}`, width: '200px', contentClass: 'leftAlign'},
    {  columnDef: 'startDateTime', headerTranslationPath: "siren-survey-translation.survey-form.fields.start-date", header: this._translateService.instant('siren-survey-translation.survey-form.fields.start-date'),  cell: (element: SurveyList) => `${element.startDateTime ? this._datePipe.transform(element.startDateTime, 'dd / MM / yyyy')  : "-"}`, width: '150px'},
    {  columnDef: 'endDateTime', headerTranslationPath: "siren-survey-translation.survey-form.fields.end-date",  header: this._translateService.instant('siren-survey-translation.survey-form.fields.end-date'),  cell: (element: SurveyList) => `${element.endDateTime ? this._datePipe.transform(element.endDateTime, 'dd / MM / yyyy')  : "-"}`, width: '150px'},
    {  columnDef: 'enabled', headerTranslationPath: "siren-survey-translation.survey-form.fields.enabled", header: this._translateService.instant('siren-survey-translation.survey-form.fields.enabled'),  cell: (element: SurveyList) => `${element.enabled}`, width: '100px'},
    {  columnDef: 'published', headerTranslationPath: "siren-survey-translation.survey-form.fields.published", header: this._translateService.instant('siren-survey-translation.survey-form.fields.published'),  cell: (element: SurveyList) => `${element.published}`, width: '100px'},
    {  columnDef: 'responseNumber', headerTranslationPath: "siren-survey-translation.survey-form.fields.response-number", header: this._translateService.instant('siren-survey-translation.survey-form.fields.response-number'),  cell: (element: SurveyList) => `${element.responseNumber != null ? element.responseNumber : 0}`, width: '100px'},
    {  columnDef: '_menu_inline', headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "title", translationPath: "siren-survey-translation.survey-form.fields.title", displayName: "Title", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like , cssClass: 'col-xs-12 col-sm-6 col-md-4' },
    { name: "description", translationPath: "siren-survey-translation.survey-form.fields.description", displayName: "Description", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "surveyType.id", translationPath: "siren-survey-translation.survey-form.fields.survey-type", displayName: this._translateService.instant('siren-survey-translation.survey-form.fields.survey-type'), type: TableFilterTypeEnum.LookupObject, lookupName: "FormType", lookupsValueById: true, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal , cssClass: 'col-xs-12 col-sm-6 col-md-3'  },
    { name: "maxGrade", translationPath: "siren-survey-translation.survey-form.fields.max-grade", displayName: "Maximum Grade", type: TableFilterTypeEnum.Number, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "enabled", translationPath: "siren-survey-translation.survey-form.fields.enabled", displayName: "Enabled", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "published", translationPath: "siren-survey-translation.survey-form.fields.published", displayName: "Published", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "startDateTime", translationPath: "siren-survey-translation.survey-form.fields.start-date", displayName: "Start Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator['Greater Or Equal'], cssClass: 'col-xs-12 col-sm-6 col-md-4' },
    { name: "endDateTime", translationPath: "siren-survey-translation.survey-form.fields.end-date", displayName: "End Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator['Less Or Equal'], cssClass: 'col-xs-12 col-sm-6 col-md-4'  },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.responses'), actionName: "SURVEY_RESPONSES", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/survey_response_black.svg" },
    { name: this._translateService.instant('global.clone'), actionName: "CLONE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg" },
    { name: this._translateService.instant('global.linked-surveys'), actionName: "LINKED_SURVEYS", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/external-link-alt-solid.svg" },
    { name: this._translateService.instant('global.generated-reports'), actionName: "GENERATED_REPORTS", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/report.svg" },
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg" , allowedAction :  this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SURVEY_REPORT)},
    { name: this._translateService.instant('siren-survey-translation.survey-table.manage-access'), actionName: "MANAGE_ACCESS", iconStyle: "width: 10px; height: 10px; margin-right: 5px; margin-left: 5px;", iconUrl: "", fontAwesomeIcon:"fa fa-users" },
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string{
    return this._translateService.instant('siren-survey-translation.global.delete');
  }
  getCloneBtnLabel(): string{
    return this._translateService.instant('siren-survey-translation.global.clone');
  }

  constructor(public service : SurveyService,
    public dialog: MatDialog,
    public lookupService: LookupService,
    public authenticationService: ISurveyAuthenticationService,
    private router: Router,
    private snackbar: MatSnackBar,
    private dialogService: DialogService,
    private route : ActivatedRoute,
    private readonly _translateService: TranslateService,
    private openLinkedSurveyQuestionServicesService: OpenLinkedSurveyQuestionService,
    private _datePipe: DatePipe,
  ) {

    // this.addConsumerButtonsToTableMenuActions();

    this.tableTitle = this._translateService.instant('siren-survey-translation.survey-table.title');
    let titleParam = this.route.snapshot.queryParams['title'];
    if(titleParam != undefined && titleParam.trim() != '') this.tableTitle = titleParam;
    this.consumerPermissionEnum = this.authenticationService.getPermissionEnumeration();
    this._tableMenuAction.forEach(column=>{
      if(column.actionName=='DELETE_ACTION'){
        column.allowedAction = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.DELETE_SURVEY);
      }else if(column.actionName=='CLONE_ACTION'){
        column.allowedAction = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.CLONE_SURVEY);
      }else if(column.actionName=='MANAGE_ACCESS'){
        column.allowedAction = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.ASSIGN_USER_SURVEY);
      }
    });
    this.canAdd = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SAVE_SURVEY);
    this.hasListAccess = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SURVEY_LIST);


    this.predefinedFilter = new Map<string, any>();
    this.surveyTypeParam = this.route.snapshot.params['surveyType'];
    if(this.surveyTypeParam) {
      this.predefinedFilter.set("surveyType.id", this.surveyTypeParam);
      for(let filter of this.filters){
        if(filter.name == "surveyType.id"){
          filter.visible = false;
          break;
        }
      }
    }
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        case 'surveyType': return item[property]?.value;
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        if(key && key == 'surveyType'){
          return currentTerm + data[key].value;
       } else{
         return currentTerm + data[key];
       }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  ngOnInit(): void {
  }

  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    this.showLoadingSpinner = true;
    if(event.mode == TableAction.EDIT_MODE){
      this.router.navigate([event.id], {relativeTo: this.route});
    } else if(event.mode == TableAction.CREATE_MODE) {
      this.router.navigate(["create"], {relativeTo: this.route});
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }


  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION") {
        this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.survey-form.messages.delete-title'),
          "message": this._translateService.instant('siren-survey-translation.survey-form.messages.delete-message'),
          "confirmText": this._translateService.instant('siren-survey-translation.survey-form.actions.yes'),
          "cancelText": this._translateService.instant('siren-survey-translation.survey-form.actions.no') })
        .subscribe(response=>{
          if(response){
                this.service.deleteObject(menuAction.object.id).subscribe( response =>{

                  this.reloadEventSubject.next(true);
                }, error =>{
                  let message = error && error.error && error.error.message.includes("ConstraintViolationException")
                    ? this._translateService.instant('error.record-depending-error')
                    : this._translateService.instant('error.record-error');
                  this.snackbar.open(message, null, { duration: 3000 });
                });
              }
            });
        } else if (menuAction.actionName == "CLONE_ACTION"){
        this.service.cloneSurvey(menuAction.object.id).subscribe( response =>{
          let surveyResponse = response?.body;
          if(surveyResponse && surveyResponse.id > 0){
            this.reloadEventSubject.next(true);
          }
        }, error =>{
          console.error(error.error.message);
        });
      } else if (menuAction.actionName == "LINKED_SURVEYS"){
        this.openLinkedSurveyQuestionServicesService.openLinkedSurveysTable(menuAction.object.id,undefined)
      }
      else if(menuAction.actionName == "SURVEY_RESPONSES"){
        this.router.navigate([`/survey/response/${menuAction.object.id}/${menuAction.object.title}`] );
      }
      else if(menuAction.actionName == "GENERATED_REPORTS"){
        this.router.navigate([`/survey/survey-reports/${menuAction.object.id}/${menuAction.object.title}/${menuAction.object.uuid}`] );
      }
      else if(menuAction.actionName == "MANAGE_ACCESS"){
        this.assignUsersSurveyId = menuAction.object.id;
        this.showAssignUsers = true;
        this.reloadEventSubject.next(true);
      }
    }
  }

  hideAssignUsers(){
    this.showAssignUsers = false;
  }
}

export interface UserModule {
  module: string;
  key: string;
}
