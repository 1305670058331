import { FieldConfigModel } from "../../models/fields-config.model";

export class CustomFormModel {
    constructor(
        public fields: FieldConfigModel[],
        public lookups?: any,
        public data?: any,
        public isDisabled?: boolean
    ) { }
}

export class CustomFormActionModel {
    constructor(
        public label: string,
        public hide?: boolean,
        public disabled?: boolean
    ) { }
}
