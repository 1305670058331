
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { ResponsiveListInlineEditObject, TableAction } from '@siren-survey/app/models/shared/table-action';
import { FilterOperator, TableFilterElement, TableFilterOutput, TableFilterTypeEnum } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { OrganizationService } from '../../../../services/admin/organization.service';
import { DatePipe } from '@angular/common';
import { DatatableInlineEdit } from '@siren-survey/app/component/shared/datatable/datatable-inline-edit.service';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { Organization } from 'src/app/models/admin/organization/organization';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from 'src/app/services/shared/validators.service';

@Component({
  selector: 'app-organization-admin-table',
  templateUrl: './organization-admin-table.component.html',
  styleUrls: ['./organization-admin-table.component.sass']
})
export class OrganizationAdminTableComponent extends DatatableInlineEdit implements OnInit {

  tableTitleLabel: string;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  inlineEditEventSubject: Subject<ResponsiveListInlineEditObject> = new Subject<ResponsiveListInlineEditObject>();

  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;

  predefinedFilter: Map<string, any>;

  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  canAdd : boolean = false;
  hasListAccess : boolean = false;

  _booleanIconColumnDisplayList: string[] = [
    "enabled","published"
  ]

  _tableColumns  : DataTableModel<Organization> []= [
    {  columnDef: 'name', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "organization.fields.organization-type", header: this._translateService.instant('organization.fields.organization-type'),  cell: (element: Organization) => `${element.name}`, width: undefined},
    {  columnDef: 'prefix', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "organization.fields.prefix", header: this._translateService.instant('organization.fields.prefix'),  cell: (element: Organization) => `${element.prefix}`, width: undefined},
    {  columnDef: 'creationDate', type: TableFilterTypeEnum.Date, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "organization.fields.creation-date", header: this._translateService.instant('organization.fields.creation-date'),  cell: (element: Organization) => `${element.creationDate}`, width: undefined},
    {  columnDef: '_menu_inline', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "name", translationPath: "organization.fields.organization-name", displayName: "organization Name", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "creationDate", translationPath: "organization.fields.creation-date", displayName: "Creation Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal, cssClass: 'col-xs-12 col-sm-6 col-md-4'  },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ORGANIZATION_UPDATE) },
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg", accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ]), allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ORGANIZATION_DELETE)}
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string{
    return this._translateService.instant('global.delete');
  }

  constructor(public service : OrganizationService, private datePipe : DatePipe,private router: Router, private route : ActivatedRoute, private readonly _translateService: TranslateService,
    private dialogService: DialogService, public lookupService: LookupService, public snackBar: MatSnackBar, public authService: AuthenticationService) {
    super();
    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ORGANIZATION_CREATE);
    this.hasListAccess = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ORGANIZATION_LIST);
  }

  ngOnInit(): void {
  }

  lastCreatedId = 0;
  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.CREATE_MODE) {
      this.lastCreatedId--;
      let editFormGroup = new FormGroup({
        'id': new FormControl({value: this.lastCreatedId, disabled: true}),
        'name': new FormControl({value:'', disabled: false}, [Validators.required, CustomValidators.noWhitespaceValidator]),
        'prefix': new FormControl({value:'', disabled: false},[Validators.required, CustomValidators.noWhitespaceValidator]),
        'creationDate': new FormControl({value:'', disabled: true})
      })
      let inlineEditObject = new ResponsiveListInlineEditObject(this.lastCreatedId, this.lastCreatedId, editFormGroup,false);
      this.inlineEditEventSubject.next(inlineEditObject);
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }


  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
          this.dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-organization'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
        .subscribe(response=>{
          if(response){
            this.service.deleteObject(menuAction.object.id).subscribe( response =>{
              this.reloadEventSubject.next(true);
            }, error =>{
              let message = error && error.error && error.error.message.includes("ConstraintViolationException")
                ? this._translateService.instant('error.record-depending-error')
                : this._translateService.instant('error.record-error');
              this.snackBar.open(message, null, { duration: 3000 });
            });
          }
        });
      }  else {
          this.handleInlineEditingRequests(menuAction,this.snackBar,this._translateService);
      }
    }
  }

  isInlineEditable(): boolean {
    return true;
  }

  getTableFormGroupModel(): FormGroup<any> {
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'name': new FormControl({value: undefined, disabled: false}, [Validators.required, CustomValidators.noWhitespaceValidator]),
      'prefix': new FormControl({value: undefined, disabled: true}),
      'creationDate': new FormControl({value: undefined, disabled: true})
    });
  }

  triggerInlineEditEventSubject(inlineEditObject: ResponsiveListInlineEditObject): void {

    if(this.inlineEditEventSubject) this.inlineEditEventSubject.next(inlineEditObject);
  }

  getTableService() {
    return this.service;
  }

  getValidForm(menuAction:MenuActionEmittedObject): boolean {
    menuAction.form.markAllAsTouched();
    menuAction.form.updateValueAndValidity();
    return menuAction.form.valid;
  }

  withCheckUnicity(): boolean{
    return true;
  }

  objectClassName(): string{
    return "Organization";
  }
}

