import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardListComponent } from './card-list/card-list.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DatatableComponent } from './datatable/datatable.component';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { PopupMessageComponent } from './popup-message/popup-message.component';
import { ResponsiveListViewComponent } from './responsive-list-view/responsive-list-view.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createSurveyTranslateLoader } from '@siren-survey/app/survey-app.module';
import { HttpClient } from '@angular/common/http';
import { ExternalLibrariesModule } from './external-libraries.module';
import { SurveyDatepciker } from './survey-datepicker/survey-datepicker.component';
import { TitleComponent } from './title/title.component';
import { PopupComponent } from './popup/popup.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';




@NgModule({
  declarations: [
    CardListComponent,
    ConfirmDialogComponent,
    DatatableComponent,
    LoadingSpinnerComponent,
    PopupComponent,
    InfoTooltipComponent,
    PopupMessageComponent,
    ResponsiveListViewComponent,
    TableFilterComponent,
    TitleComponent,
  ],
  exports: [
    CardListComponent,
    ConfirmDialogComponent,
    DatatableComponent,
    LoadingSpinnerComponent,
    PopupComponent,
    InfoTooltipComponent,
    PopupMessageComponent,
    ResponsiveListViewComponent,
    TableFilterComponent,
    ExternalLibrariesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TitleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ExternalLibrariesModule,
    FontAwesomeModule,
    TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: createSurveyTranslateLoader,
				deps: [HttpClient]
			},
      extend: true
		}),
  ],
})
export class SharedModule { }
