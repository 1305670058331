import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchPermissionEnum, MarchModuleEnum } from 'src/app/shared/enum/user-role.enum';
import { ActivityExecutionService } from 'src/app/services/assessment/activity-execution.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivityAssesment } from '../../assessment/models/assessment.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity-result',
  templateUrl: './activity-result.component.html',
  styleUrls: ['./activity-result.component.sass']
})
export class ActivityResultComponent {

  public reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  public filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  public initiateSearchFromFilter: boolean = true;
  public hasListAccess: boolean = false;
  public predefinedFilter: Map<string, any>;
  public marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  public marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  public booleanIconColumnDisplayList: string[] = [
    "activityEnabled"
  ]

  public tableColumns  : DataTableModel<ActivityAssesment> []= [
    {  columnDef: 'activityName', headerTranslationPath: "activities.fields.name", header: 'Name',  cell: (element: ActivityAssesment) => `${element.activityName}`, width: undefined},
    {  columnDef: 'activityCategories', headerTranslationPath: "activities.fields.category", header: 'Category',  cell: (element: ActivityAssesment) => `${element.activityCategory}`, width: undefined},
    {  columnDef: 'program', headerTranslationPath: "activities.fields.program", header: 'Program',  cell: (element: ActivityAssesment) => `${element.program}`, width: undefined},
    {  columnDef: 'startDate', headerTranslationPath: "activities.fields.startDate", header: 'Start Date',  cell: (element: ActivityAssesment) => `${element.startDate ? this._datePipe.transform(element.startDate, 'dd / MM / yyyy') : "-"}`, width: undefined},
    {  columnDef: 'endDate', headerTranslationPath: "activities.fields.endDate", header: 'End Date',  cell: (element: ActivityAssesment) => `${element.endDate ? this._datePipe.transform(element.endDate, 'dd / MM / yyyy') : "-"}`, width: undefined},
    {  columnDef: 'occurrence', headerTranslationPath: "activities.fields.occurrence", header: 'Occurrence',  cell: (element: ActivityAssesment) => `${element.occurrence}`, width: undefined},
    {  columnDef: 'activityEnabled', headerTranslationPath: "activities.fields.enabled", header: 'Enabled',  cell: (element: ActivityAssesment) => `${element.activityEnabled}`, width: undefined},
    {  columnDef: 'creationDate', type: TableFilterTypeEnum.Date, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "achievement.fields.creation-date", header: 'Creation Date',  cell: (element: ActivityAssesment) => `${element.creationDate ? this._datePipe.transform(element.creationDate, 'dd / MM / yyyy') : "-"}`, width: undefined},
    {  columnDef: '_menu_inline', headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  public filters: TableFilterElement[] = [
    { name: "programId", translationPath: "activities.fields.program", displayName: "Program", type: TableFilterTypeEnum.LookupObject, lookupName: "Program", lookupsValueById: true, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "activityName", translationPath: "activities.fields.activityName", displayName: "Activity", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "creationDate", translationPath: "activities.fields.creation-date", displayName: "Creation Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal, cssClass: 'col-xs-12 col-sm-6 col-md-4'  },
  ];

  public tableMenuAction: MenuActionObject[] = [
    { name: `${this._translate.instant('activities.fields.view-assessment')}`, actionName: "EDIT_MODE", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/generate-report-icon.svg", allowedAction: this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.ASSESSMENTS_MODIFY) , accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ])},
    { name: `${this._translate.instant('activities.fields.create-assessment')}`, actionName: "ADD_MODE", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg", allowedAction: this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.ASSESSMENTS_MODIFY) , accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ])},
  ]

  public sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  public  filteringPredicate : (data: any, filter: string) => boolean;

  constructor(
    public service : ActivityExecutionService,
    private _router: Router,
    private _route : ActivatedRoute,
    private _authService: AuthenticationService,
    private _datePipe: DatePipe,
    private _translate: TranslateService) {
    this.predefinedFilter = new Map<string, any>();
    this.sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this.filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.hasListAccess = this._authService.isModuleAccessible(this.marchModuleEnum.ACTIVITY_ENTITIES_MGT.module);
  }

  public applyFilter(event: TableFilterOutput): void{
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  public tableMenuActionHandler(menuAction: MenuActionEmittedObject): void{
    if(menuAction && menuAction.actionName && menuAction.object && menuAction.actionName == "ADD_MODE"){
      this._router.navigate([`../../assessment/activity/${menuAction.object.id}/assessment-result/${menuAction.actionName}/${menuAction.object.activityName}`], {relativeTo: this._route});
    }
    else if(menuAction && menuAction.actionName && menuAction.object && menuAction.actionName == "EDIT_MODE"){
      this._router.navigate([`../../assessment/activity/${menuAction.object.id}/assessment-result/${menuAction.actionName}/${menuAction.object.activityName}`], {relativeTo: this._route});
    }
  }

}
