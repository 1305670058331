import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchPermissionEnum, MarchModuleEnum } from 'src/app/shared/enum/user-role.enum';
import { Location } from '@angular/common';
import { Incident, IncidentPostObject, IncidentSecurityForce } from 'src/app/models/incident/incident';
import { IncidentService } from 'src/app/services/incident/incident.service';
import { IncidentFormInput } from '../incident-table/incident-table.component';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-incident-form',
  templateUrl: './incident-form.component.html',
  styleUrls: ['./incident-form.component.sass']
})
export class IncidentFormComponent extends DropdownComparatorWithBrowserListener implements OnInit {
  @Input() isEditMode: boolean = true;

  form: FormGroup;
  public error : string;
  incident: Incident;
  id: number;
  index: number;
  disableSubmit: boolean = false;
  canGetIncident: boolean = false;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  incidentTypes: LookupModel[];
  regions: LookupModel[];
  securityForces: LookupModel[];

  selectedSecurityForces: number[];
  existingSelection: Map<number, number> = new Map();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(private incidentService: IncidentService,public dialogRef: MatDialogRef<IncidentFormComponent>,
    private route : ActivatedRoute, private router: Router, private _snackbar: MatSnackBar, public lookupService: LookupService,
    private dialogService: DialogService, public readonly _translateService: TranslateService, private http: MyHttpHandler,
    private location: Location, public authService: AuthenticationService,@Inject(MAT_DIALOG_DATA) public data: IncidentFormInput){
      super();
      this.id = data.incidentId;
      this.canGetIncident = this.authService.isModuleAccessible(this.marchModuleEnum.INCIDENTS_MGT.module);
  }

  ngOnInit(): void {
    this.getData();
    this.lookupService.getLookupsService(['Region', 'SecurityForce'], true, true).subscribe(response => {
      if(response && response.body){
        this.regions = response.body['Region'];
        this.securityForces = response.body['SecurityForce'];
      }
    });

    this.lookupService.getLookupObjectsService(['IncidentType'], true, true).subscribe(response => {
      if(response && response.body){
        this.incidentTypes = response.body['IncidentType'];
      }
    });
    this.createFormGroups();
  }

  createFormGroups(){
    this.form = new FormGroup({
      'id': new FormControl({value:this.incident?.id, disabled: true}),
      'title': new FormControl({value: this.incident?.title, disabled: !this.isEditMode}, [Validators.required, CustomValidators.noWhitespaceValidator]),
      'description': new FormControl({value: this.incident?.description, disabled: !this.isEditMode},  [Validators.required, CustomValidators.noWhitespaceValidator]),
      'dateTime': new FormControl({value: this.incident?.dateTime ? new Date(this.incident?.dateTime) : undefined, disabled: !this.isEditMode}, Validators.required),
      'incidentType': new FormControl({value: this.incident?.incidentType, disabled: !this.isEditMode}, Validators.required),
      'external': new FormControl({value: this.incident ? this.incident.external : true, disabled: !this.isEditMode}, Validators.required),
      'region': new FormControl({value: this.incident?.region, disabled: !this.isEditMode}, Validators.required),
      'creationDate': new FormControl({value: this.incident?.creationDate, disabled: true}),
    });
  }

  getData(){
    if(this.id && this.id > 0){
      this.incidentService.getObject(this.id).subscribe(response =>{
        if(response && response.body){
          this.incident = response.body;
          this.form.patchValue(this.incident);

          let dateTimeValue = this.incident?.dateTime ? new Date(this.incident?.dateTime) : undefined
          this.form?.get('dateTime')?.patchValue(dateTimeValue);

          this.isEditMode  ? this.form.enable() : this.form.disable();

          this.selectedSecurityForces = [];
          if(this.incident?.securityForces?.length > 0){
            for(let secForces of this.incident?.securityForces){
              this.selectedSecurityForces.push(secForces.securityForce.id);
              this.existingSelection.set(secForces.securityForce.id, secForces.id);
            }
          }
        }
      })
    }
  }

  isSecurityForceEnvolved(securityForceid): boolean{
    let isEnvolved = false;
    if(securityForceid && this.selectedSecurityForces){
      for(let currId of this.selectedSecurityForces){
        if(currId == securityForceid) isEnvolved = true;
      }
    }
    return isEnvolved;
  }

  setSecurityForceAsEnvolved(event: MatCheckboxChange, id: number){
    if(id){
      if(event.checked){
        if(!this.selectedSecurityForces) this.selectedSecurityForces = [];
        this.selectedSecurityForces.push(id);
      } else{
        if(this.selectedSecurityForces) {
          this.selectedSecurityForces.forEach((element,index)=>{
            if(element == id) this.selectedSecurityForces.splice(index,1);
         });
        }
      }
    }
  }

  editable() : boolean{
    return this.isEditMode;
  }

  validateFormForSave() : string{
    let error : string = undefined;
    if(this.form){
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.incident = this.form.getRawValue();

      if(!error) error = !this.form.valid ? this._translateService.instant('incident.error.form-not-valid') : undefined;
    }
    return error;
  }

  saveIncident(){
    let error = this.validateFormForSave();
    if(!error){
      this.executeSave();
    }
    else{
      this._snackbar.open(error, null, { duration: 3000 });
    }
  }

  constructEnvolvedSecurityForcesBody(): IncidentSecurityForce[]{
    let list : IncidentSecurityForce[] = [];
    if(this.selectedSecurityForces){
      for(let envolvedId of this.selectedSecurityForces){
        let incEnvolvedForceId = this.existingSelection ? this.existingSelection.get(envolvedId) : null;
        list.push({
          "id": incEnvolvedForceId,
          "securityForce": {
            "id": envolvedId,
            "name": ""
          }
        });
      }
    }
    return list;
  }

  executeSave(){
    let rawValue = this.form.getRawValue();
    this.incident = rawValue;
    let dateTimeValue = this.form.get('dateTime').getRawValue();
    dateTimeValue = this.toISOLocal(dateTimeValue).slice(0, 19).replace("T"," ");

    let incidentObject: IncidentPostObject = {
      creationDate : undefined,
      dateTime: dateTimeValue ,
      description: this.incident.description,
      id: this.incident.id,
      title: this.incident.title,
      incidentType: this.incident.incidentType,
      external: this.incident.external,
      region: this.incident.region,
      securityForces: this.constructEnvolvedSecurityForcesBody()
    }

    this.disableSubmit = true;
    this.incidentService.postObject(incidentObject).subscribe(response => {
      if(response){
        this.disableSubmit = false;
        this.incident = response.body;
        this.dialogRef?.close(this.incident);
        if(this.id > 0){
          this._snackbar.open(this._translateService.instant('incident.success.incident-updated'), null, { duration: 3000 });
        }
        else{
          this._snackbar.open(this._translateService.instant('incident.success.incident-saved'), null, { duration: 3000 });
        }
        this.id = this.incident.id;
    } else{
        if(this.incident.id){
          this.error = this._translateService.instant('incident.error.incident-update-fail');
        }
        else{
          this.error = this._translateService.instant('incident.error.incident-save-fail');
        }
        this.disableSubmit = false;
      }
    }, error =>{
      this.error = error.error.message;
      this.disableSubmit = false;
    });
  }

  goBack(){
    this.location.back();
  }

  toISOLocal(date) {
    var z  = n =>  ('0' + n).slice(-2);
    var zz = n => ('00' + n).slice(-3);
    var off = date.getTimezoneOffset();
    var sign = off > 0? '-' : '+';
    off = Math.abs(off);

    return date.getFullYear() + '-'
           + z(date.getMonth()+1) + '-' +
           z(date.getDate()) + 'T' +
           z(date.getHours()) + ':'  +
           z(date.getMinutes()) + ':' +
           z(date.getSeconds()) + '.' +
           zz(date.getMilliseconds()) +
           sign + z(off/60|0) + ':' + z(off%60);
  }
}

