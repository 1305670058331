import { Observable, Subject, tap } from "rxjs";
import { MyHttpHandler } from "./http-handler.service";
import { DataTableService } from "./interfaces/data-table-service";
import { Injector } from "@angular/core";
import { HttpResponse } from "@angular/common/http";

// @Injectable({ providedIn: 'root' })
export class BaseService<T,U> implements DataTableService {
  url: String;
  creationUrl: String;
  // protected tableListParams: Map<string, any> = new Map<string, any>();
  private _data$: Subject<T[]> = new Subject();
  data$: Observable<T[]> = this._data$.asObservable();

  constructor(protected serviceUrl: String, protected http: MyHttpHandler, protected injector: Injector){ //, protected constParams?: Map<string, string>) {
    this.url = serviceUrl;
    // this.params = constParams;
  }

  protected getTableListParams(): Map<string, any>{
    return undefined;
  }

  protected getObjectUrlPrefix(): string{
    return undefined;
  }

  public setServiceUrl(url:string){
    this.url = url;
  }

  public setServiceCreationUrl(url:string){
    this.creationUrl = url;
  }

  getTableDataWithPrarams(params : Map<string, any>): Observable<any> {

    let pagedUrl = this.url;
    let iteration = 0;
    if(params && params.size > 0){
      params.forEach(function(value, key){
        pagedUrl += iteration == 0 ? "?" : "&";
        pagedUrl += key +"=" + value;
        iteration++;
      });
    }
    if(this.getTableListParams() && this.getTableListParams().size > 0){
      this.getTableListParams().forEach(function(value, key){
        pagedUrl += iteration == 0 ? "?" : "&";
        pagedUrl += key +"=" + value;
        iteration++;
      });
    }
    return this.http.get<T[]>(pagedUrl).pipe(
      tap(result => {
        this._data$.next(result.body);
      })
    );
  }

  getList(): Observable<HttpResponse<T[]>>{
    let constructedUrl = this.url;
    let iteration = 0;
    if(this.getTableListParams() && this.getTableListParams().size > 0){
      this.getTableListParams().forEach(function(value, key){
        constructedUrl += iteration == 0 ? "?" : "&";
        constructedUrl += key +"=" + value;
        iteration++;
      });
    }
    return this.http.get<T[]>(constructedUrl).pipe(
      tap(result => {
        this._data$.next(result.body);
      })
    );
  }

  getConstructedObjectUrl(){
    return this.getObjectUrlPrefix() != undefined ? this.url + "/" + this.getObjectUrlPrefix() : this.url;
  }

  getConstructedCreationObjectUrl(){
    let creationObjectUrl = this.creationUrl != undefined ? this.creationUrl : this.url;
    return this.getObjectUrlPrefix() != undefined ? creationObjectUrl + "/" + this.getObjectUrlPrefix() : creationObjectUrl;
  }

  getObject(id: number): Observable<HttpResponse<U>>{
    return this.http.get<U>(this.getConstructedObjectUrl() + "/" + id);
  }

  getTableData(): Observable<HttpResponse<T[]>>{
    return this.getList();
  }


  protected formatCreationObject(object: U): any{
    return object;
  }

  postObject(object: U): Observable<HttpResponse<U>> {
    return this.http.post<U>(this.getConstructedCreationObjectUrl(), this.formatCreationObject(object));
  }

  updateObject(id: number, object: U): Observable<HttpResponse<U>> {
    let updateUrl = this.getConstructedCreationObjectUrl() + "/" + id;
    return this.http.post<U>(updateUrl,  this.formatCreationObject(object));
  }

  putObject(id: number, object: U): Observable<HttpResponse<U>> {

    let updateUrl = this.getConstructedCreationObjectUrl() + "/" + id;
    return this.http.put<U>(updateUrl, this.formatCreationObject(object))
  }

  deleteObject(id: number): Observable<HttpResponse<U>>{
    let deleteUrl = this.getConstructedCreationObjectUrl() + "/" + id;
    return this.http.delete<U>(deleteUrl);
  }

  // deleteObjects(ids: number[]): Observable<U>{
  //   let updateUrl = this.url + "/" + id;
  //   return this.http.post<U>(updateUrl, object);
  // }

  checkNameUnicity(type: string, id: number, name: string): Observable<HttpResponse<boolean>> {
    let url: string = "/v1/shared/unicity-check?type=" + type + "&name=" + name;
    if(id != undefined && id > 0) url += "&id=" + id;
    return this.http.get<boolean>(url);
  }
}
