import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { Achievement } from 'src/app/models/admin/program/achievement/achievement';
import { AchievementService } from 'src/app/services/admin/program/achievement.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchModuleEnum, MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { Location } from '@angular/common';
import { AchievementFormInput } from '../achievement-table/achievement-table.component';
import { Subject } from 'rxjs';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { BlobService } from '@siren-survey/app/services/blob/blob.service';

@Component({
  selector: 'app-achievement-form',
  templateUrl: './achievement-form.component.html',
  styleUrls: ['./achievement-form.component.sass']
})
export class AchievementFormComponent implements OnInit {
  @Input() isEditMode: boolean = true;

  form: FormGroup;
  public error : string;
  achievement: Achievement;
  id: number;
  index: number;

  public files: any[] = [];
  singleBlobObject: boolean = false;
  saveBlobOnTrigger: boolean = false;
  type: string;
  objectId: number;
  formDataPosted: FormData;
  saveBlobSubject: Subject<any> = new Subject<any>();
  fromComponent: string;

  canGetAchievement: boolean = false;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  hasUnsavedBeneficiariesCount : number = 0;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    private achievementService: AchievementService,
    public dialogRef: MatDialogRef<AchievementFormComponent>,
    private _snackbar: MatSnackBar,
    private _authService: AuthenticationService,
    public readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AchievementFormInput){
      this.id = data.achievementId;
      this.canGetAchievement = this._authService.isModuleAccessible(this.marchModuleEnum.LOOKUPS_MGT.module);
      this.type='Achievement';
      this.objectId = this.id;
      this.fromComponent = "AchievementFormComponent";
  }

  ngOnInit(): void {
    this.createFormGroups();
    if(this.id && this.id > 0 && this.canGetAchievement) this.getData();
    this.singleBlobObject = true;
    this.saveBlobOnTrigger = true;
    this.type = "Achievement" ;
    this.objectId = this.id;
  }

  createFormGroups(){
    this.form = new FormGroup({
      'id': new FormControl({value:this.achievement?.id, disabled: true}),
      'name': new FormControl({value: this.achievement?.name, disabled: !this.isEditMode}, [Validators.required, CustomValidators.noWhitespaceValidator]),
    });
  }

  getData(){
    if(this.id && this.id > 0){

      this.createFormGroups();
      this.achievementService.getObject(this.id).subscribe(response =>{
        this.achievement = response.body;
        this.isEditMode = !this.achievement.isDeleted;
        this.form.patchValue(this.achievement);
        this.isEditMode  ? this.form.enable() : this.form.disable();
      });
    }
  }

  editable() : boolean{
    return this.isEditMode;
  }

  validateFormForSave() : string{
    let error : string = undefined;
    if(this.form){
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      let rawValue = this.form.getRawValue();
      let nameValue = this.form.controls["name"].getRawValue();
      this.achievement = this.form.getRawValue();

      if(!nameValue || nameValue == undefined){
        error = this.translateService.instant('achievement.error.achievement-name-mandatory');
      }

      if(!error) error = !this.form.valid ? this.translateService.instant('achievement.error.form-not-valid') : undefined;
    }
    return error;
  }

  saveAchievement(){
    let error = this.validateFormForSave();
    if(!error){
      this.achievementService.checkAchievementUnicity(this.achievement).subscribe(response => {
        if(response){
          if(response.body){
            this.executeSave();
          } else{
            error =  this.translateService.instant('achievement.error.already-exist');
            this._snackbar.open(error, null, { duration: 3000 });
          }
        } else{
            error = this.translateService.instant('achievement.error.fail-unicity');
            this._snackbar.open(error, null, { duration: 3000 });
        }
      });
    } else{
      this._snackbar.open(error, null, { duration: 3000 });
    }
  }

  executeSave(){
    let rawValue = this.form.getRawValue();
    this.achievement = rawValue;
    if(this.achievement.id){
      this.achievementService.putObject(this.achievement.id,this.achievement).subscribe(response => {
        if(response){
          this.achievement = response.body;
          this.id = this.achievement.id;

          this.saveBlobSubject.next(this.achievement.id);

          this.dialogRef?.close(response.body);
          this._snackbar.open(this.translateService.instant('achievement.success.achievement-updated'), null, { duration: 3000 });
        } else{
          this.error = this.translateService.instant('achievement.error.achievement-update-fail');
        }
      }, error =>{
        this.error = error.error.message;
      });
    } else{
      this.achievementService.postObject(this.achievement).subscribe(response => {
        if(response){
          this.hasUnsavedBeneficiariesCount = 0;
          this.achievement = response.body;
          this.saveBlobSubject.next(this.achievement.id);

          this.dialogRef?.close(response.body);
          this._snackbar.open(this.translateService.instant('achievement.success.achievement-saved'), null, { duration: 3000 });
        } else{
          this.error = this.translateService.instant('achievement.error.achievement-save-fail');
        }
      }, error =>{
        this.error = error.error.message;
      });
    }
  }
}

