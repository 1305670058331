import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';


import { SurveyAppRoutingModule } from './survey-app-routing.module';
import { SurveyAppComponent } from './survey-app.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SurveyTableComponent } from './component/survey/survey-table/survey-table.component';
import { SurveyFormComponent } from './component/survey/survey-form/survey-form.component';
import { QuestionBankFormComponent } from './component/question-bank/question-bank-form/question-bank-form.component';
import { QuestionBankFormComponentEmbedded } from './component/question-bank/question-bank-form-embedded/question-bank-form-embedded.component';
import { SurveySectionComponent } from './component/survey/survey-section/survey-section.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuestionConditionsComponent } from './component/survey/conditions/question-conditions/question-conditions.component';
import { QuestionStatementComponent } from './component/survey/conditions/question-statement/question-statement.component';
import { QuestionBankListComponent } from './component/question-bank/question-bank-list/question-bank-list.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { QuestionBankSelectListComponent } from './component/question-bank/question-bank-select-list/question-bank-select-list.component';
import { SurveyResponseTableComponent } from './component/survey-response/survey-response-table/survey-response-table.component';
import { QuestionBankResponseFormEmbeddedComponent } from './component/survey-response/question-bank-response-form-embedded/question-bank-response-form-embedded.component';
import { MatRadioModule } from '@angular/material/radio';
import { ResponseSectionFormComponent } from './component/survey-response/response-section-form/response-section-form.component';
import { ResponseTableComponent } from './component/survey-response/response-table/response-table.component';
import { SurveyTypeTableComponent } from './component/survey-type/survey-type-table/survey-type-table.component';
import { SurveyQuestionComponent } from './component/survey/survey-question/survey-question.component';

import { CommonModule } from '@angular/common';
import { SettingsHttpService } from './shared/services/settings-http.service';
import { CanActivatePageGuard } from './shared/guards/can-activate-page.guard';
import { SurveySelectionTableComponent } from './component/survey/survey-selection-table/survey-selection-table.component';
import { SharedModule } from './component/shared/shared.module';
import { SurveyDatepciker } from './component/shared/survey-datepicker/survey-datepicker.component';
import { SurveyResponseFormComponent } from './component/survey-response/survey-response-form/survey-response-form.component';
import { SurveyUsersComponent } from './component/survey/survey-users/survey-users.component';

export function createSurveyTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function appInit(settingsHttpService: SettingsHttpService) {
	return (): Promise<any> => settingsHttpService.initializeApp();
}

@NgModule({
  declarations: [
    SurveyAppComponent,
    SurveyTableComponent,
    SurveyUsersComponent,
    SurveyFormComponent,
    QuestionBankFormComponent,
    QuestionBankFormComponentEmbedded,
    SurveySectionComponent,
    QuestionConditionsComponent,
    QuestionStatementComponent,
    QuestionBankListComponent,
    QuestionBankSelectListComponent,
    SurveyResponseTableComponent,
    QuestionBankResponseFormEmbeddedComponent,
    ResponseSectionFormComponent,
    ResponseTableComponent,
    SurveyTypeTableComponent,
    SurveyQuestionComponent,
    SurveySelectionTableComponent,
    SurveyResponseFormComponent,
  ],
  imports: [
    SharedModule,
    SurveyAppRoutingModule,
    MatSnackBarModule,
    MatMenuModule,
    SurveyDatepciker,
    TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: createSurveyTranslateLoader,
				deps: [HttpClient]
			},
      extend: true
		}),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    CommonModule,
    // BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatExpansionModule
    // ToastrModule.forRoot({
    //   timeOut: 10000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: true,
    //   newestOnTop: true,
    //   countDuplicates: true,
    //   resetTimeoutOnDuplicate: true
    // })
    // MatMomentDateModule
  ],
  exports: [
    SurveyAppComponent,
    SurveyTableComponent,
    SurveyFormComponent,
    QuestionBankFormComponent,
    QuestionBankFormComponentEmbedded,
    SurveySectionComponent,
    QuestionConditionsComponent,
    QuestionStatementComponent,
    QuestionBankListComponent,
    QuestionBankSelectListComponent,
    SurveyResponseTableComponent,
    QuestionBankResponseFormEmbeddedComponent,
    ResponseSectionFormComponent,
    ResponseTableComponent,
    SurveyTypeTableComponent,
    SurveyQuestionComponent,
    SurveySelectionTableComponent
  ],
  providers: [
    // SessionService,
    CanActivatePageGuard,
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [SettingsHttpService], multi: true },
    // { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    // { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } } ,
    // DatePipe,
    // AuthenticationService,
    // TranslateService
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true
    // }
  ],
  // bootstrap: [SurveyAppComponent]
})
export class SurveyAppModule {
  constructor(matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }

}
