import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrowserListener } from './services/utils/browser-listener.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LookupService } from './services/lookups/lookup.service';
import { LookupModel } from './models/shared/lookup.model';
import { Location } from '@angular/common';
import { ISessionService } from './services/shared/interfaces/session-service.interface';
import { SettingsService } from './shared/services/settings.service';

@Component({
    selector: 'survey-app-root',
    templateUrl: './survey-app.component.html',
    styleUrls: ['./survey-app.component.sass']
})
export class SurveyAppComponent extends BrowserListener implements OnInit{
  // isMenuPerSurveyType = this.settingsService.settings && this.settingsService.settings.menuPerSurveyType ? this.settingsService.settings.menuPerSurveyType : false;
  isMenuPerSurveyType = this.settingsService.settings && this.settingsService.settings.menuPerSurveyType ? this.settingsService.settings.menuPerSurveyType : false;
  navbarOpened = false;
  bottomNavbarOpened = false;

  title="Survey"

  surveyTypes: LookupModel[] = [];

  toogleNav(){
    if(this._isMobile){
      this.bottomNavbarOpened ? this.closeBottomNav() : this.openBottomNav();
    } else{
      this.navbarOpened ? this.closeNav() : this.openNav();
    }
  }

  executeOnResize(){
    this._isMobile ? this.closeNav() : this.closeBottomNav();
  }

  openNav() {
    this.navbarOpened = true;
    if(document.getElementById("mySidenav") != undefined) document.getElementById("mySidenav").style.width = "250px";
    if(document.getElementById("main-foreground") != undefined) document.getElementById("main-foreground").style.display = "block";
    this.setOpenNavButton();
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
  closeNav() {
    this.navbarOpened = false;
    if(document.getElementById("mySidenav") != undefined) document.getElementById("mySidenav").style.width = "0";
    if(document.getElementById("main-foreground") != undefined) document.getElementById("main-foreground").style.display = "none";
    this.setClosedNavButton();
  }

  setOpenNavButton(){
    if(this.isRtl()){
      if(document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.right = "245px";
      if(document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.rotate = "180deg";
    } else{
      if(document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.left = "228.5px";
      if(document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.rotate = "0deg";
    }
  }

  setClosedNavButton(){
    if(this.isRtl()){
      if(document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.right = "-16px";
      if(document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.rotate = "0deg"
    }else{
      if(document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.left = "0";
      if(document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.rotate = "180deg"
    }
  }

  adaptNavButtonStateAfterLanguageChange(){
    this.navbarOpened ? this.setOpenNavButton() : this.setClosedNavButton();
  }

  openBottomNav() {
    this.bottomNavbarOpened = true;
    if(document.getElementById("bottomSidenav") != undefined) document.getElementById("bottomSidenav").style.height = "50%";
    if(document.getElementById("main-mobile-foreground") != undefined) document.getElementById("main-mobile-foreground").style.display = "block";
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
  closeBottomNav() {
    this.bottomNavbarOpened = false;
    if(document.getElementById("bottomSidenav") != undefined) document.getElementById("bottomSidenav").style.height = "0";
    if(document.getElementById("main-mobile-foreground") != undefined) document.getElementById("main-mobile-foreground").style.display = "none";
  }

  activeLanguage: string = this.settingsService.settings.defaultLanguage; //, private route : ActivatedRoute, private router: Router

  constructor(private readonly _translateService: TranslateService,    private _snackBar: MatSnackBar, @Inject(DOCUMENT) private document: Document,
  public lookupService: LookupService, private session: ISessionService, private location: Location, private settingsService: SettingsService) {
    super();
    if(this.session && this.session.getActiveLanguage()) this.activeLanguage = this.session.getActiveLanguage();
    this._translateService.setDefaultLang(this.activeLanguage);
    // this.setGlobalLanguage();
    this.changeLanguage(this.activeLanguage);
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  isActiveRoute(routeToCheck: string): boolean{
    let isActive: boolean = false;
    if(this.isActiveRoute){
      let equal = this.location.isCurrentPathEqualTo(routeToCheck);
      let contains = this.location.path().includes(routeToCheck);
      isActive = equal || contains;
    }
    return isActive;
  }

  ngOnInit(): void {
    if(this.isMenuPerSurveyType){
      this.lookupService.getLookupsService(["SurveyType"], false, true).subscribe(response => {
        if(response?.body) this.surveyTypes = response.body["SurveyType"];
      });
    }
  }

  changeLanguage(languageChangeValue: string){
    this.session.setActiveLanguage(languageChangeValue);
    this.activeLanguage = languageChangeValue;
    this.setGlobalLanguage();
    this.adaptNavButtonStateAfterLanguageChange()
  }

  isRtl(): boolean{
    return this.activeLanguage === "ar";
  }

  setGlobalLanguage(){
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = this.isRtl() ? "rtl" : "ltr";
    this._translateService.use(this.activeLanguage);
    this.changeCssFile();
  }

  changeCssFile() {
    let headTag = this.document.getElementsByTagName("head")[0] as HTMLHeadElement;
    let existingLink = this.document.getElementById("langCss") as HTMLLinkElement;
    let bundleName = this.isRtl() ? "arabicStyle":"englishStyle";
    if (existingLink) {
       existingLink.href = bundleName;
    } else {
       let newLink = this.document.createElement("link");
       newLink.rel = "stylesheet";
       newLink.type = "text/css";
       newLink.id = "langCss";
       newLink.href = bundleName;
       headTag.appendChild(newLink);
    }
  }
}
