import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { ProgramBeneficiarySelectionListComponent, ProgramBeneficiarySelectionListInput } from 'src/app/component/beneficiary/program-beneficiary-selection-list/program-beneficiary-selection-list.component';
import { ClassAttendeeCreateDto, Program, ProgramBeneficiary, ProgramBeneficiarySelectionList, ProgramClass, ProgramClassAttendee, ProgramOrganization } from 'src/app/models/admin/program/program/program';
import { ProgramService } from 'src/app/services/admin/program/program.service';
import { BeneficiaryService } from 'src/app/services/beneficiary/beneficiary.service';
import { Subject } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SurveyLinkSelectionComponent } from '../survey-link-selection/survey-link-selection.component';

@Component({
  selector: 'app-program-organization-form',
  templateUrl: './program-organization-form.component.html',
  styleUrls: ['./program-organization-form.component.sass']
})
export class ProgramOrganizationFormComponent extends DropdownComparatorWithBrowserListener implements OnInit {
  @Input() programOrganization: ProgramOrganization;
  @Input() programOrganizationFormGroup: FormGroup;
  @Input() isEditable: boolean = true;
  @Input() hasAccessToOrg: boolean = false;
  @Input() hasUnsavedBeneficiariesCount = 0;
  @Input() hasUnsavedClassesCount = 0;
  @Input() classes: LookupModel[] = [];
  @Input() organizationId: number;
  @Input() program: Program;
  @Input() title: string = "";
  beneficiaries: LookupModel[] = [];
  isLoadingBeneciaries: boolean = true;
  classAccordionToogleEventSubjectMap: Map<number, Subject<boolean>> = new Map();

  @ViewChild('benefPanel') benefPanel!: MatExpansionPanel;
  @ViewChild('programOrganizationSurveyLink') programOrganizationSurveyLink!: SurveyLinkSelectionComponent;
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;


  constructor(private dialogService: DialogService, public readonly _translateService: TranslateService, public lookupService: LookupService,
    private matDialog: MatDialog, public beneficiaryService: BeneficiaryService, private programService: ProgramService, private _snackbar: MatSnackBar) {
    super();
  }

  openAll() {
    this.expansionPanels.forEach(panel => panel.open());
  }

  closeAll() {
    this.expansionPanels.forEach(panel => panel.close());
  }
  ngOnInit(): void {
    if (this.organizationId == undefined || this.organizationId == 0) this.organizationId = this.programOrganization?.organization?.id;
    this.createProgramOrganizationFormGroup();
    this.loadLinkedObjects();
  }


  loadLinkedObjects() {
    if (this.organizationId != undefined && this.organizationId > 0) {
      this.beneficiaryService.getOrganizationBeneficiariesLookupList(this.organizationId).subscribe(response => {
        if (response && response.body) this.beneficiaries = response.body;
        this.isLoadingBeneciaries = false;
      });
    }
  }

  get isEditableProgOrgData() {
    return this.isEditable && this.hasAccessToOrg;
  }

  createProgramOrganizationFormGroup() {
    if (this.programOrganizationFormGroup == undefined) {
      this.programOrganizationFormGroup = new FormGroup({
        'id': new FormControl({ value: this.programOrganization?.id, disabled: true }),
        'organization': new FormControl({ value: this.programOrganization?.organization, disabled: !this.isEditableProgOrgData }, Validators.required),
        'programClasses': new FormArray([]),
        "programBeneficiaries": new FormArray([])
      });
    }

    if (this.programOrganization?.programClasses) {
      let programClassFormArray: FormArray = this.programClasses as FormArray;
      if (programClassFormArray) {
        for (let progClass of this.programOrganization.programClasses) {
          programClassFormArray.push(this.createProgramClassFormGroup(progClass, this.isEditableProgOrgData));
        }
      }
    }
    if (this.programOrganization?.programBeneficiaries) {
      let programBeneficiaryFormArray: FormArray = this.programBeneficiaries as FormArray;
      if (programBeneficiaryFormArray) {
        for (let progBeneficiary of this.programOrganization.programBeneficiaries) {
          programBeneficiaryFormArray.push(this.createProgramBeneficiaryFormGroup(progBeneficiary, this.isEditableProgOrgData));
        }
      }
    }
  }

  // CLASSES MANAGEMENT

  get programClasses(): FormArray {
    return this.programOrganizationFormGroup.get('programClasses') as FormArray;
  }

  addProgramClass() {
    let programClassFormArray: FormArray = this.programClasses as FormArray;
    if (programClassFormArray) {
      programClassFormArray.push(this.createProgramClassFormGroup(undefined, true));
      this.hasUnsavedClassesCount++;
    }
  }

  removeProgramClass(index: number) {
    this.dialogService.confirmDialog({
      "title": this._translateService.instant('popup-module.titles.remove-class'),
      "message": this._translateService.instant('popup-module.messages.class-remove'),
      "confirmText": this._translateService.instant('popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('popup-module.buttons.no')
    })
      .subscribe(response => {
        if (response) {
          let programClassFormArray: FormArray = this.programClasses as FormArray;
          if (programClassFormArray) {
            programClassFormArray.removeAt(index);
            this.hasUnsavedClassesCount--;
            if (this.hasUnsavedClassesCount < 0) this.hasUnsavedClassesCount = 0;
          }
        }
      });
  }

  createProgramClassFormGroup(progClass: ProgramClass, editable: boolean): FormGroup {
    return new FormGroup({
      'id': new FormControl({ value: progClass?.id, disabled: true }),
      'classes': new FormControl({ value: progClass?.classes, disabled: !editable }, Validators.required),
      'deletable': new FormControl({ value: progClass?.deletable, disabled: true })
    });
  }

  getAvailableClasses(index: number): LookupModel[] {
    let response: LookupModel[] = [];
    let programClassFormArray: FormArray = this.programClasses as FormArray;
    if (programClassFormArray && programClassFormArray.length > 0) {
      let programClassFormArrayValue = programClassFormArray.getRawValue();
      let classesId: number[] = [];
      let currentClassSelection = this.programClasses?.at(index)?.getRawValue()?.classes?.id;
      for (let val of programClassFormArrayValue) if (val?.classes?.id) classesId.push(val.classes.id);
      for (let currClass of this.classes) {
        if (!classesId.includes(currClass.id) || currentClassSelection == currClass.id) response.push(currClass);
      }
    } else {
      response = this.classes;
    }
    return response;
  }

  hasAvailableClasses(): boolean {
    let programClassFormArray: FormArray = this.programClasses as FormArray;
    let selectedClassesCount = programClassFormArray ? programClassFormArray.length : 0;
    let classesCount = this.classes ? this.classes.length : 0;
    return selectedClassesCount < classesCount;
  }

  canAddClasses(): boolean {
    let canAdd: boolean = false;
    let programClassFormArray: FormArray = this.programClasses as FormArray;
    let selectedClassesCount = programClassFormArray ? programClassFormArray.length : 0;
    canAdd = this.classes && this.classes.length > 0 && this.classes.length > selectedClassesCount;
    return canAdd;
  }


  // CLASS ATTENDEES MANAGEMENT

  selectClassAttendees(classId: number) {
    if (classId && classId > 0) {
      this.programService.getClassAttendees(classId).subscribe(response => {
        if (response && response.body) {
          let classAttendeesList: ProgramBeneficiarySelectionList[] = [];
          for (let element of response.body) {
            classAttendeesList.push(element.programBeneficiary);
          }
          this.openClassAttendeesWindow(classId, classAttendeesList, response.body);
        }
      });
    }
  }

  openClassAttendeesWindow(classId: number, classAttendeesList: ProgramBeneficiarySelectionList[], classAttendees: ProgramClassAttendee[]) {
    if (classId != undefined && classId > 0) {
      const dialogRef = this.openBeneficiarySelectionWindow(this.program.id, this.programOrganization?.id, classAttendeesList);
      dialogRef.afterClosed().subscribe((selectedProgramBeneficiaries: ProgramBeneficiarySelectionList[]) => {
        if (selectedProgramBeneficiaries) {
          let postBody: ClassAttendeeCreateDto = new ClassAttendeeCreateDto();
          for (let currSelection of selectedProgramBeneficiaries) {
            let existingAttendee = this.findExistingClassAttendeeObject(currSelection?.beneficiary?.id, classAttendees);
            let postElementBody: ProgramClassAttendee = {
              id: existingAttendee ? existingAttendee.id : null,
              programBeneficiary: existingAttendee ? existingAttendee.programBeneficiary : currSelection
            }
            postBody.classAttendees.push(postElementBody);
          }
          this.programService.postClassAttendees(classId, postBody).subscribe(response => {
            if (response) {
              this._snackbar.open(this._translateService.instant('snackbar.class-attendees-update'), null, { duration: 3000 });
            } else {
              this._snackbar.open(this._translateService.instant('snackbar.something-wrong'), null, { duration: 3000 });
            }
          });
        }
      });
    }
  }

  findExistingClassAttendeeObject(beneficiaryId: number, classAttendees: ProgramClassAttendee[]): ProgramClassAttendee {
    let foundObject = undefined;
    if (beneficiaryId != undefined && beneficiaryId > 0) {
      for (let attendee of classAttendees) {
        if (foundObject != undefined) break;
        foundObject = foundObject == undefined && attendee?.programBeneficiary?.beneficiary?.id == beneficiaryId ? attendee : foundObject;
      }
    }
    return foundObject;
  }

  // PROGRAM BENEFICIARY SELECTION

  openBeneficiarySelectionWindow(programId: number, programOrganizationId: number, selectedElements: ProgramBeneficiarySelectionList[]): any {
    let input: ProgramBeneficiarySelectionListInput = new ProgramBeneficiarySelectionListInput(programId, programOrganizationId, selectedElements);
    const programBeneficiarySelectionDialog = this.matDialog.open(ProgramBeneficiarySelectionListComponent, {
      "width": '6000px',
      "maxWidth": '80vw',
      "maxHeight": '80vh',
      "autoFocus": false,
      data: input,
      direction: this._translateService.currentLang == 'ar' ? 'rtl' : 'ltr'
    });
    return programBeneficiarySelectionDialog;
  }

  // BENEFICIARIES MANAGEMENT

  get programBeneficiaries(): FormArray {
    return this.programOrganizationFormGroup.get('programBeneficiaries') as FormArray;
  }

  addProgramBeneficiary() {
    let programBeneficiaryFormArray: FormArray = this.programBeneficiaries as FormArray;
    if (programBeneficiaryFormArray) {
      // programBeneficiaryFormArray.push(this.createProgramBeneficiaryFormGroup(undefined, true));
      programBeneficiaryFormArray.insert(0, this.createProgramBeneficiaryFormGroup(undefined, true));
      this.hasUnsavedBeneficiariesCount++;
    }
    this.benefPanel.open();
  }

  removeProgramBeneficiary(index: number) {
    this.dialogService.confirmDialog({
      "title": this._translateService.instant('popup-module.titles.remove-beneficiary'),
      "message": this._translateService.instant('popup-module.messages.beneficiary-remove'),
      "confirmText": this._translateService.instant('popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('popup-module.buttons.no')
    })
      .subscribe(response => {
        if (response) {
          let programBeneficiaryFormArray: FormArray = this.programBeneficiaries as FormArray;
          if (programBeneficiaryFormArray) {
            programBeneficiaryFormArray.removeAt(index);
            this.hasUnsavedBeneficiariesCount--;
            if (this.hasUnsavedBeneficiariesCount < 0) this.hasUnsavedBeneficiariesCount = 0;
          }
        }
      });
  }

  createProgramBeneficiaryFormGroup(progBeneficiary: ProgramBeneficiary, editable: boolean): FormGroup {
    return new FormGroup({
      'id': new FormControl({ value: progBeneficiary?.id, disabled: true }),
      'beneficiary': new FormControl({ value: progBeneficiary?.beneficiary, disabled: !editable }, Validators.required),
      'comment': new FormControl({ value: progBeneficiary?.comment, disabled: !editable }),
      'enrollmentDate': new FormControl({ value: progBeneficiary?.enrollmentDate, disabled: !editable }, Validators.required),
      'exitDate': new FormControl({ value: progBeneficiary?.exitDate, disabled: !editable }),
      'deletable': new FormControl({ value: progBeneficiary == undefined ? true : progBeneficiary?.deletable, disabled: !editable }),
    });
  }

  getAvailableBeneficiaries(index: number): LookupModel[] {
    let response: LookupModel[] = [];
    let programBeneficiaryFormArray: FormArray = this.programBeneficiaries as FormArray;
    if (programBeneficiaryFormArray && programBeneficiaryFormArray.length > 0) {
      let programBeneficiaryFormArrayValue = programBeneficiaryFormArray.getRawValue();
      let beneficiariesId: number[] = [];
      let currentBeneficiarySelection = this.programBeneficiaries?.at(index)?.getRawValue()?.beneficiary?.id;
      for (let val of programBeneficiaryFormArrayValue) if (val?.beneficiary?.id) beneficiariesId.push(val.beneficiary.id);
      for (let currBenef of this.beneficiaries) {
        if (!beneficiariesId.includes(currBenef.id) || currentBeneficiarySelection == currBenef.id) response.push(currBenef);
      }
    } else {
      response = this.classes;
    }
    return response;
  }

  canAddBeneficiaries(): boolean {
    let canAdd: boolean = false;
    let programBeneficiaryFormArray: FormArray = this.programBeneficiaries as FormArray;
    let selectedBeneficiaries: number = programBeneficiaryFormArray ? programBeneficiaryFormArray?.length : 0;
    canAdd = this.beneficiaries && this.beneficiaries.length > 0 && this.beneficiaries.length > selectedBeneficiaries;
    return canAdd;
  }

  canAddSurveyLink() {
    //add permission in future
    return true;
  }

  toogleClassSurveys(progClassId: number) {
    if (progClassId != undefined) {
      let classAccordionToogleEventSubject: Subject<boolean> = this.getClassAccordionToogleEventSubject(progClassId);
      if (classAccordionToogleEventSubject) classAccordionToogleEventSubject.next(true);
    }
  }

  getClassAccordionToogleEventSubject(progClassId: number): Subject<boolean> {
    if (progClassId == undefined) return undefined;
    let classAccordionToogleEventSubject: Subject<boolean> = this.classAccordionToogleEventSubjectMap.get(progClassId);
    if (classAccordionToogleEventSubject == undefined) {
      classAccordionToogleEventSubject = new Subject<boolean>();
      this.classAccordionToogleEventSubjectMap.set(progClassId, classAccordionToogleEventSubject)
    }
    return classAccordionToogleEventSubject;
  }
}
