// export interface Profile {
//     adminLevel: string,
//     changePasswordRequired: boolean,
//     emailAddress: string
//     enabled: boolean,
//     firstName: string,
//     id: number,
//     lastName: string,
//     roles: any[]
//     username: string
// }
// export interface ProfileRequest {
//     fisrtName: string,
//     lastname: string,
//     emailAddress: string,
//     username: string,
// }

export interface PasswordRequest {
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string
}

export class PasswordRequestObj{
  oldPassword: string;
  newPassword: string;
}

export interface PasswordError {
    withTranslate: boolean;
    error: boolean,
    message: string
}
