import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { ProgramActivityExecution } from 'src/app/models/admin/program/activities/activity-execution';
import { ClassAttendeeCreateDto, Program, ProgramClassAttendee } from 'src/app/models/admin/program/program/program';
import { ProgramList } from 'src/app/models/admin/program/program/programList';

@Injectable({
  providedIn: 'root'
})
export class ProgramService extends BaseService<ProgramList, Program> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/program", http, injector);
  }

  // getProgramQuartersByProgramId(programId: number): Observable<HttpResponse<ProgramQuarterList>> {
  //   return this.http.get<ProgramQuarterList>("/v1/program/program-quarters/" + programId);
  // }

  getClassAttendees(classId: number): Observable<HttpResponse<ProgramClassAttendee[]>> {
    return this.http.get<ProgramClassAttendee[]>("/v1/program/class-attendees/" + classId);
  }

  postClassAttendees(classId: number, body: ClassAttendeeCreateDto): Observable<HttpResponse<ProgramClassAttendee[]>> {
    return this.http.post<ProgramClassAttendee[]>("/v1/program/class-attendees/" + classId, body);
  }

  getActivityExecutionByProgramId(surveyId: number): Observable<HttpResponse<ProgramActivityExecution[]>> {
    let url = "/v1/program-activity/activity-execution";
    if(surveyId != undefined && surveyId > 0) url += "?surveyId=" + surveyId;
    return this.http.get<ProgramActivityExecution[]>(url);
  }

  reactivateProgram(programId: number): Observable<HttpResponse<Program>>{
    return this.http.post<Program>("/v1/program/reactivate/" + programId, null);
  }

  canUncheckOrganization(organizationId: number, programId: number): Observable<HttpResponse<boolean>> {
    return  this.http.get<boolean>(`/v1/program/remove-organization?organizationId=${organizationId}&programId=${programId}`);
  }
}
