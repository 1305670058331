<header class="header_wrapper">
    <div class="first_row">
      <button class="my_profile" type="button" (click)="goToHr()">
        <p class="my_profile_title mx-auto">
          {{ "siren-admin-translation.admin_module.header.profile" | translate }}
        </p>
        <img src="assets/icons/profile.svg" alt="" />
      </button>

      <!-- <input
        *ngIf="isMobile()"
        matInput
        placeholder="{{ 'admin_module.header.search' | translate }}"
        class="header_searchbar"
      /> -->

      <div class="text-right">
        <img
          src="assets/images/moj-logo1-big.png"
          class="header_moj_logo"
          alt=""
        />
      </div>
    </div>
    <div class="second_row">
      <!-- <div *ngIf="!isMobile()">
        <input matInput placeholder="{{ 'admin_module.header.search' | translate }}" class="header_searchbar" />
      </div> -->
    </div>
  </header>
