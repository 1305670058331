import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-code-verification-dialog',
    templateUrl: './code-verification-dialog.component.html',
    styleUrls: ['./code-verification-dialog.component.sass']
})

export class CodeVerificationDialogComponent implements OnInit {
    numbersForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<CodeVerificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.numbersForm = new FormGroup({
            number: new FormControl('', Validators.required),
        });

        this.numbersForm.controls.number.valueChanges.subscribe(x => {
            const val = this.numbersForm.controls.number.value;
            if (val && val.indexOf(' ') >= 0) {
                this.numbersForm.controls.number.setValue(val.trim());
            }
        });
    }

    send(): void {
        if (this.numbersForm.valid) {
            const form = this.numbersForm.getRawValue();
            const code = form.number;
            this.dialogRef.close(code);
        }
    }

    close() {
        this.dialogRef.close();
    }
}
