<div class="toolbar_wrapper">
  <mat-button-toggle-group [value]="activeAction" aria-label="Font Style"
    class="toolbar_btn_group">

    <!-- <mat-button-toggle *ngFor="let m of menuItems" class="toolbar_btn" [value]="m.value" #toggle
      (click)="onValChange(m.value)"><img [src]="getImageSrc(toggle, m)" alt="" />
      <div>
        <p class="btn_title">{{ m.label | translate }}</p>
      </div>
    </mat-button-toggle> -->

  </mat-button-toggle-group>
</div>

<!-- <app-toolbar-drawer></app-toolbar-drawer> -->
