<div *ngIf="field">
    <div [hidden]="field.isHidden"
        *ngIf="field.type == fieldTypeEnum.string || field.type == fieldTypeEnum.uniquestring">
        <app-text-input [formControl]="ngControl.control" [placeholdertext]="field.label" [maxLength]="field.maxLength">
        </app-text-input>
    </div>
    <div [hidden]="field.isHidden" *ngIf="field.type == fieldTypeEnum.number">
        <app-text-input [formControl]="ngControl.control" type="number" [placeholdertext]="field.label"
            [maxLength]="field.maxLength">
        </app-text-input>
    </div>
    <div [hidden]="field.isHidden" *ngIf="field.type == fieldTypeEnum.text">
        <app-text-area [maxLength]="field.maxLength" [formControl]="ngControl.control" [placeholdertext]="field.label">
        </app-text-area>
    </div>
    <div [hidden]="field.isHidden" *ngIf="field.type == fieldTypeEnum.password">
        <app-text-input [formControl]="ngControl.control" [placeholdertext]="field.label" [maxLength]="field.maxLength"
            [isPassword]="true">
        </app-text-input>
    </div>
    <div [hidden]="field.isHidden"
        *ngIf="field.type == fieldTypeEnum.date || field.type == fieldTypeEnum.dateTime || field.type == fieldTypeEnum.month">
        <app-datepicker [formControl]="ngControl.control" [placeholdertext]="field.label" [minDate]="getMinDate(field)"
            [maxDate]="getMaxDate(field)" [withClear]="true" [onlyMonth]="field.type == fieldTypeEnum.month">
        </app-datepicker>
    </div>
    <div [hidden]="field.isHidden" *ngIf=" field.type==fieldTypeEnum.dropdown">
        <app-single-select [dropdownList]="lookups" [formControl]="ngControl.control" [placeholderLabel]="field.label"
            [withClearButton]="field.withClearButton" [withFilter]="field.withFilter">
        </app-single-select>
    </div>
    <div [hidden]="field.isHidden" *ngIf="field.type == fieldTypeEnum.checkbox" style="padding-top: 20px">
        <div class="checkbox_wrapper">
            <mat-checkbox color="primary" [formControl]="ngControl.control">
                {{ field.label | translate }}
            </mat-checkbox>
        </div>
    </div>
    <div [hidden]="field.isHidden" *ngIf="field.type == fieldTypeEnum.multiselectdropdown">
        <app-multiple-select [formControl]="ngControl.control" [placeholderLabel]="field.label"
            [dropdownList]="lookups">
        </app-multiple-select>
    </div>

    <div [hidden]="field.isHidden" *ngIf="field.type == fieldTypeEnum.toggle" class="single-lined-element button-toggle">

            <app-toggle-buttons [placeholdertext]="field.label" [formControl]="ngControl.control"
            [list]="field.listOfValues">
            </app-toggle-buttons>

     
    </div>

    
</div>