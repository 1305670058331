import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MyHttpHandler } from "@siren-survey/app/services/shared/http-handler.service";
import { ISessionService } from "@siren-survey/app/services/shared/interfaces/session-service.interface";
import { AuthenticationService } from "src/app/services/auth/authentication.service";

@Injectable()
export class MarchMyHttpHandler extends MyHttpHandler{

  constructor(protected http: HttpClient, public readonly _translateService: TranslateService, protected session : ISessionService,
    protected authService: AuthenticationService, protected injector: Injector) {
      super(http, _translateService, session, authService, injector);
  }

}
