import { Injectable } from '@angular/core';
import { MenuItemModel } from '../models/menu-item.model';

@Injectable()
export class ToolbarDrawerService {
  drawerConfig = { openDrawer: false, contentType: '', content: null }

  constructor() { }

  setContent(content: MenuItemModel) {
    if (this.drawerConfig.contentType != content.value) {
      this.drawerConfig.openDrawer = true;
      this.drawerConfig.contentType = content.value;
      this.drawerConfig.content = content;
    }
    else {
      this.onClose();
    }
  }

  onClose() {
    this.drawerConfig.openDrawer = false;
    this.drawerConfig.contentType = '';
    this.drawerConfig.content = null;
  }
}
