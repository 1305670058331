import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SurveyCustomValidators } from '../shared/validators/validators.service';
import { v4 as uuid } from 'uuid';

export class FormControllerGeneratorService {

  public static createSectionFormGroup(editMode: boolean) : FormGroup{
    const sectionUuid: string = uuid();
    return new FormGroup({
      'uuid': new FormControl(sectionUuid),
      'id': new FormControl({value:undefined, disabled: true}),
      'weight': new FormControl({value: 1, disabled: !editMode}),
      'passingGrade': new FormControl({value: undefined, disabled: !editMode}),
      'totalGrade': new FormControl({value: undefined, disabled: !editMode}),
      'title': new FormControl({value: '', disabled: !editMode}, [Validators.required, SurveyCustomValidators.noWhitespaceValidator]),
      'index': new FormControl({value: undefined, disabled: true}),
      'order': new FormControl({value: undefined, disabled: true}),
      'questions': new FormArray([ ])
    });
  }

  public static createQuestionBankFormGroup(editMode: boolean): FormGroup{
    return new FormGroup({
      'editMode': new FormControl({value: editMode, disabled: true}),
      'id': new FormControl({value: undefined, disabled: true}),
      'question': new FormControl({value:'', disabled: !editMode},  [Validators.required, SurveyCustomValidators.noWhitespaceValidator]),
      'withExplanation': new FormControl({value:false, disabled: !editMode}),
      'withOther': new FormControl({value:false, disabled: !editMode}),
      'gradeByAnswer': new FormControl({value:false, disabled: !editMode}),
      'answers': new FormArray([ ]),
      'editable': new FormControl({value:true, disabled: !editMode}),
      'answerType': new FormGroup({
        id : new FormControl({value:undefined, disabled: !editMode}),
        key: new FormControl({value:'', disabled: !editMode}, [Validators.required]),
        value: new FormControl({value:'', disabled: !editMode})
      }, [Validators.required])
    })
  }

  public static createAnswerFormGroup(editMode: boolean) : FormGroup{
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'answer': new FormControl({value:'', disabled: !editMode},  [Validators.required, SurveyCustomValidators.noWhitespaceValidator]),
      'grade': new FormControl({value: undefined, disabled: !editMode}),
      'order': new FormControl({value: undefined, disabled: true}),
    })
  }


  public static createQuestionFormGroup(editMode: boolean, gradedSurvey: boolean) : FormGroup{
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'graded': new FormControl({value: gradedSurvey, disabled: !editMode}),
      'index': new FormControl({value: undefined, disabled: true}),
      'optional': new FormControl({value: true, disabled: !editMode}),
      'order': new FormControl({value: undefined, disabled: !editMode}),
      'parentIds': new FormControl({value: undefined, disabled: !editMode}),
      'question': this.createQuestionBankFormGroup(editMode)
    })
  }

  public static createConditionFormGroup(editMode: boolean): FormGroup{
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'action': new FormControl({value:'', disabled: !editMode}, Validators.required),
      'blocks': new FormArray([ ], [Validators.required, Validators.minLength(1)])
    }, Validators.required)
  }


  public static createConditionBlockFormGroup(editMode: boolean): FormGroup{
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: !editMode}),
      'statements': new FormArray([ ]), //,[Validators.required, Validators.minLength(1)]),
      'customStatements': new FormArray([ ]), //,[Validators.required, Validators.minLength(1)])
    }, Validators.required)
  }

  public static createCustomStatementFormGroup(editMode: boolean): FormGroup{
    return new FormGroup({
      'id': new FormControl<number>({value:undefined, disabled: true}),
      'filterName': new FormControl({value:undefined, disabled: !editMode}, Validators.required),
      'filterId':  new FormControl({value:undefined, disabled: !editMode}, Validators.required),
      'filterType': new FormControl({value:undefined, disabled: false}),
    }, Validators.required)
  }

  public static createStatementFormGroup(editMode: boolean): FormGroup{
    return new FormGroup({
      'id': new FormControl({value:undefined, disabled: true}),
      'surveyQuestion': this.createQuestionStatmentFormGroup(editMode),
      'questionAnswer': this.createQuestionAnswerStatmentFormGroup(editMode),
      'filterType': new FormControl({value:undefined, disabled: false}),
    }, Validators.required)
  }

  public static createQuestionStatmentFormGroup(editMode: boolean): FormGroup{
    return new FormGroup({
      'id': new FormControl({value:undefined, disabled: !editMode}, Validators.required)
    }, Validators.required)
  }

  public static createQuestionAnswerStatmentFormGroup(editMode: boolean): FormGroup{
    return new FormGroup({
      'id': new FormControl({value:undefined, disabled: !editMode},Validators.required)
    }, Validators.required)
  }

  // SURVEY RESPONSE

  public static createSurveyResponseFormGroup(editable: boolean): FormGroup{
    let surveyResponseFormGroup: FormGroup = new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'survey': new FormGroup({
        'id': new FormControl({value: undefined, disabled: true}),
      }),
      'totalGrade': new FormControl({value: undefined, disabled: !editable}),
      'questionResponses': new FormArray([]),
      'submitDate':new FormControl({value: undefined, disabled: true})
    });
    return surveyResponseFormGroup;
  }

  public static createQuestionResponseFormGroup(editable: boolean, maxGrade: number): FormGroup{
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'surveyQuestion': new FormGroup({
        'id': new FormControl({value: undefined, disabled: true}),
      }),
      'answerValue': new FormControl({value: '', disabled: !editable}),
      'grade': new FormControl({value: undefined, disabled: !editable},[Validators.max(maxGrade)]),
      'otherResponse': new FormControl({value: '', disabled: !editable}),
      'explanation': new FormControl({value: '', disabled: !editable}),
      'answers': new FormArray([])
    });
  }

  public static createQuestionResponseSelectionFormGroup(editable: boolean): FormGroup{
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'questionAnswer': new FormGroup({
        'id': new FormControl({value: undefined, disabled: true}),
      })
    });
  }

}
