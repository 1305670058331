import { Component, OnInit, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../services/shared/dialog.service';
import { SurveyService } from '../../../services/survey.service';
import { DatePipe } from '@angular/common';
import { FormArray, FormGroup } from '@angular/forms';
import { SurveyResponseService } from '../../../services/survey-response.service';
import { Survey } from '../../../models/survey/survey';
import { QuestionResponse, SurveyResponse } from '../../../models/response/survey-response';
import { FormControllerGeneratorService } from '../../form-controller-generator.service';
import { SurveyQuestion } from '../../../models/question/survey-question';
import { ResponseSectionFormComponent } from '../response-section-form/response-section-form.component';
import { Location } from '@angular/common';
import { DropdownComparator } from '../../shared/component-base/dropdown-comparator';
import { SettingsService } from '../../../shared/services/settings.service';
import { ISurveyAuthenticationService } from '../../../shared/services/interfaces/authentication.interface';
import { ISurveyResponseClientService } from '../../../shared/services/interfaces/survey-response-client.interface';
import { ComponentRef } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-survey-response-form',
  templateUrl: './survey-response-form.component.html',
  styleUrls: ['./survey-response-form.component.sass']
})
export class SurveyResponseFormComponent extends DropdownComparator implements OnInit {
  @Input() isEditMode: boolean = true;
  @Input() isPreview: boolean = false;
  @Input() surveyId: number;

  surveyResponseForm: FormGroup = new FormGroup({});
  surveyResponseId: number;
  surveyResponse: SurveyResponse;
  disableSave: boolean = false;
  isFormLoaded: boolean = false;

  public hasGradedAnswers: boolean = false;

  survey: Survey;
  surveyGraded: boolean;
  sectionQuestionFormGroupMap: Map<number, Map<number, FormGroup>> = new Map<number, Map<number, FormGroup>>();
  questionSectionMap: Map<number, number> = new Map<number, number>();
  invalidQuestionResponseIds: number[] = [];

  hasListAccess: boolean = false;
  consumerPermissionEnum;
  isSubmitted: boolean = false;

  isAllowedToSubmitResponse: boolean = true;
  showResponseSections: boolean = false;

  isLoading: boolean = false;

  public error = "";

  // USED FOR THE INJECTABLE COMPONENTS
  @ViewChild('responseHeaderAdditionalFormContainer', { read: ViewContainerRef, static: true }) responseHeaderAdditionalFormContainer: ViewContainerRef;
  componentRefMap: Map<string, ComponentRef<any>> = new Map();

  constructor(private surveyService: SurveyService, private surveyResponseService: SurveyResponseService, private route: ActivatedRoute, private router: Router,
    private _snackbar: MatSnackBar, private dialogService: DialogService, public readonly _translateService: TranslateService,
    private datePipe: DatePipe, private location: Location, private settingsService: SettingsService, public authenticationService: ISurveyAuthenticationService,
    private surveyResponseClientService: ISurveyResponseClientService) {
    super();

    this.consumerPermissionEnum = this.authenticationService.getPermissionEnumeration();
    this.hasListAccess = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SURVEY_LIST);

  }

  getAssetsUrl(assetPath: string): string {
    let url = assetPath;
    if (this.settingsService && this.settingsService.settings) {
      url = this.settingsService.settings.surveyAssetsBasePath;
      if (!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  ngOnInit(): void {
    if (this.hasListAccess) this.getSurveyData(); // if no access dont call api
    this.surveyResponseForm = FormControllerGeneratorService.createSurveyResponseFormGroup(this.isEditMode);
    this.loadResponseHeaderAdditionalForm();
    // if beneficiaryId selected then show responseSections
    this.surveyResponseClientService.getDataEmitter().subscribe((result) => {
      if (this.surveyResponseClientService.getSelectedBeneficiaryId()) {
        this.showResponseSections = true;
      }
    })
  }

  getSurveyData() {
    if (!this.isPreview && this.surveyId == undefined) this.surveyId = this.route.snapshot.params['surveyId'];
    this.getSurveyById();
  }

  getSurveyResponseData() {
    this.surveyResponseId = this.route.snapshot.params['responseId'];
    this.surveyId = this.route.snapshot.params['surveyId'];
    this.getSurveyResponseById();
  }

  editable(): boolean {
    return this.isEditMode;
  }

  getSurveyById() {
    if (this.surveyId != undefined && this.surveyId > 0) {
      this.surveyService.getObject(this.surveyId).subscribe(response => {
        if (response) {
          this.survey = response.body;
          this.surveyGraded = this.survey.graded;
          this.isAllowedToSubmitResponse = this.survey?.enabled && this.survey?.published;
          this.createFormGroups();
          this.getSurveyResponseData();
        }
      }, error => {
        this.error = error.error.message;
      });
    } else {
      this.isEditMode = false;
    }
  }

  getSurveyResponseById() {
    if (this.surveyResponseId != undefined && this.surveyResponseId > 0) {
      this.showResponseSections = true;
      this.surveyResponseService.getObject(this.surveyResponseId).subscribe(response => {
        if (response) {
          this.surveyResponse = response.body;
          this.isSubmitted = this.surveyResponse != undefined && this.surveyResponse.submitDate != undefined;
          this.surveyResponseForm?.get('id')?.patchValue(this.surveyResponse.id);
          this.surveyResponseForm?.get('survey')?.patchValue(this.surveyResponse.survey);
          this.surveyResponseForm?.get('totalGrade')?.patchValue(this.surveyResponse.totalGrade);
          this.surveyResponseForm?.get('submitDate')?.patchValue(this.surveyResponse.submitDate);
          // this.surveyResponseForm.patchValue(this.surveyResponse);
          if (this.surveyResponse?.questionResponses) {
            for (let questionResponses of this.surveyResponse?.questionResponses) {
              let questionId = questionResponses.surveyQuestion.id;
              let sectionId = this.questionSectionMap.get(questionId);
              let questionFormGroup: FormGroup = this.sectionQuestionFormGroupMap.get(sectionId).get(questionId);

              questionFormGroup.patchValue(questionResponses);
              if (questionResponses.answers) {
                for (let selection of questionResponses.answers) {
                  let selectionFormGroup = FormControllerGeneratorService.createQuestionResponseSelectionFormGroup(this.isEditMode);
                  selectionFormGroup.patchValue(selection);
                  (questionFormGroup.get("answers") as FormArray).push(selectionFormGroup);
                }
              }
            }
          }
          this.isEditMode = this.surveyResponse.isEditable;
          this.isEditMode ? this.surveyResponseForm.enable() : this.surveyResponseForm.disable();
          if (this.hasGradedAnswers) this.surveyResponseForm?.get('totalGrade').disable();

          this.isFormLoaded = true;
        }
      }, error => {
        this.error = error.error.message;
        this.isFormLoaded = true;
      });
    } else {
      this.isEditMode = true;
      this.isFormLoaded = true;
    }
  }

  createFormGroups() {
    if (this.survey && this.survey.sections && this.survey.sections.length > 0) {
      this.survey.sections.forEach(section => {
        let currentSectionMap: Map<number, FormGroup> = new Map<number, FormGroup>();
        if (section && section.questions && section.questions.length > 0) {
          section.questions.forEach(sectionQuestion => {
            if (section?.id && section?.id > 0 && sectionQuestion?.id && sectionQuestion?.id > 0) this.questionSectionMap.set(sectionQuestion.id, section.id);
            let currentQuestionFormGroup = FormControllerGeneratorService.createQuestionResponseFormGroup(this.isEditMode, this.survey?.maxGrade);
            //TODO We need to add here the required validation on the explanation and others
            if (sectionQuestion && sectionQuestion.question) {
              if (sectionQuestion && sectionQuestion.id != undefined && sectionQuestion.id > 0) {
                if (!this.hasGradedAnswers) this.hasGradedAnswers = sectionQuestion.graded;
                currentQuestionFormGroup.get('surveyQuestion').patchValue(sectionQuestion);
                currentSectionMap.set(sectionQuestion.id, currentQuestionFormGroup);
                (this.surveyResponseForm.get('questionResponses') as FormArray).push(currentQuestionFormGroup);
              }
            }
          });
        }
        if (section.id != undefined && section.id > 0 && currentSectionMap && currentSectionMap.size > 0) this.sectionQuestionFormGroupMap.set(section.id, currentSectionMap);
      });
    }
    if (this.hasGradedAnswers) this.surveyResponseForm?.get('totalGrade').disable();
  }

  validateResponse(surveyQuestion: SurveyQuestion, questionResponse: QuestionResponse): boolean {
    let valid = false;
    if (questionResponse && surveyQuestion && surveyQuestion.id == questionResponse?.surveyQuestion?.id) {
      valid = this.hasQuestionResponse(surveyQuestion.optional, questionResponse, surveyQuestion.question.withOther, surveyQuestion?.question?.answerType.key); // (surveyQuestion?.question?.answerType.key == "GRADE_ONLY"));
      if (valid) valid = this.explanationQuestionValidation(surveyQuestion.question.withExplanation, questionResponse.explanation);
      if (valid && this.survey.graded && surveyQuestion.graded && !surveyQuestion.optional) {
        valid = surveyQuestion.question.gradeByAnswer || questionResponse.grade != undefined;
        if (valid && !surveyQuestion.question.gradeByAnswer) valid = this.gradedQuestionValidation(this.survey.maxGrade, questionResponse.grade);
      }
    }
    if (!valid) this.invalidQuestionResponseIds.push(surveyQuestion.id);
    return valid;
  }

  gradedQuestionValidation(grade: number, responseGrade: number): boolean {
    return responseGrade >= 0 && responseGrade <= grade;
  }

  explanationQuestionValidation(explanation: boolean, explanationResponse: string): boolean {
    return !explanation || (explanation && explanationResponse.trim() != "");
  }

  hasQuestionResponse(optional: boolean, questionResponse: QuestionResponse, withOther: boolean, answerType: string): boolean {
    return questionResponse != null
      && (optional
        || (answerType == "GRADE_ONLY" && questionResponse.grade != undefined && questionResponse.grade >= 0)
        || (!optional
          && (((answerType == "SINGLE_SELECT" || answerType == "MULTI_SELECT") && questionResponse?.answers?.length > 0)
            || ((answerType == "TEXT" || answerType == "LONG_TEXT" || answerType == "NUMBER" || answerType == "DECIMAL") && questionResponse.answerValue != undefined && questionResponse.answerValue.trim() != "")
            || ((answerType == "SINGLE_SELECT" || answerType == "MULTI_SELECT") && withOther && questionResponse.otherResponse != undefined && questionResponse.otherResponse.trim() != ""))));
  }

  withOtherValidation(withOther: boolean, otherResponse: string): boolean {
    return !withOther || (withOther && otherResponse?.trim() != "");
  }

  hasOther(withOther: boolean, otherResponse: string): boolean {
    return withOther && otherResponse?.trim() != "";
  }

  getQuestionResponsesMap(response: SurveyResponse): Map<number, QuestionResponse> {
    let responseMap: Map<number, QuestionResponse> = new Map<number, QuestionResponse>();
    if (response && response?.questionResponses) {
      response?.questionResponses?.forEach(questionResponse => {
        if (questionResponse && questionResponse.surveyQuestion && questionResponse.surveyQuestion.id)
          responseMap.set(questionResponse.surveyQuestion.id, questionResponse);
      });
    }
    return responseMap;
  }

  validateResponseAnswers(responseForm: SurveyResponse): ResponseValidationOutput {
    let valid = true;
    this.invalidQuestionResponseIds = [];
    if (responseForm) {
      if (this.survey && this.survey.sections) {
        let responseMap: Map<number, QuestionResponse> = this.getQuestionResponsesMap(responseForm);
        for (let i = 0; i < this.survey.sections.length; i++) {
          let section = this.survey.sections[i];
          for (let j = 0; j < section.questions.length; j++) {
            let surveyQuestion = section.questions[j];
            if (surveyQuestion && surveyQuestion.id) {
              let questionResponse = responseMap.get(surveyQuestion.id);
              let isVisibleQuestion = ResponseSectionFormComponent.visibleQuestion(this.questionSectionMap, this.sectionQuestionFormGroupMap, surveyQuestion, this.surveyResponseClientService)
              if (isVisibleQuestion) {
                let questionValid = this.validateResponse(surveyQuestion, questionResponse);
                if (valid) valid = questionValid;
              } else {
                responseForm = this.removeResponseFromPostBody(responseForm, surveyQuestion.id);
              }
            }
          }
        }
      }
    } else {
      valid = false;
    }
    return new ResponseValidationOutput(responseForm, valid);
  }

  removeResponseFromPostBody(responseForm: SurveyResponse, questionId: number): SurveyResponse {
    if (responseForm != undefined && responseForm?.questionResponses != undefined && questionId != undefined) {
      for (let i = 0; i < responseForm?.questionResponses.length; i++) {
        let questionResponse = responseForm?.questionResponses[i];
        if (questionResponse && questionResponse.surveyQuestion && questionResponse.surveyQuestion.id && questionResponse.surveyQuestion.id == questionId) {
          responseForm?.questionResponses.splice(i, 1);
          break;
        }
      }
    }
    return responseForm;
  }

  submit() {
    this.disableSave = true;
    if (this.editable) {
      let responseForm: SurveyResponse = this.surveyResponseForm.getRawValue();
      this.surveyResponseForm.markAllAsTouched();
      this.surveyResponseForm.updateValueAndValidity();
      if (responseForm && this.surveyResponseForm && this.surveyResponseForm.valid && this.validateComponentRefMapElements()) {
        let responseValidationOutput: ResponseValidationOutput = this.validateResponseAnswers(responseForm);
        let valid = responseValidationOutput.isValid;
        responseForm = responseValidationOutput.response;
        if (valid && this.invalidQuestionResponseIds.length == 0) { // condition show or no condition for this quesiton
          this.surveyResponseForm.controls['survey'].get('id').setValue(this.surveyId);
          let submitDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
          this.surveyResponseForm.get('submitDate').setValue(submitDate);
          responseForm.survey.id = this.surveyId;

          this.surveyResponseForm.markAllAsTouched();
          this.surveyResponseForm.updateValueAndValidity();

          if (this.surveyResponseForm && this.surveyResponseForm.valid) {
            this.executeSubmit(responseForm);
          }
          else {
            this.disableSave = false;
            this._snackbar.open(this._translateService.instant('siren-survey-translation.error.form_invalid'), null, { duration: 3000 });
          }
        }
        else {
          this.disableSave = false;
          this._snackbar.open(this._translateService.instant('siren-survey-translation.error.form_invalid'), null, { duration: 3000 });
        }
      }
      else {
        this.disableSave = false;
        this._snackbar.open(this._translateService.instant('siren-survey-translation.error.form_invalid'), null, { duration: 3000 });
      }
    }
  }

  save() {
    this.disableSave = true;
    this.surveyResponseForm.controls['survey'].get('id').setValue(this.surveyId);
    let responseForm = this.surveyResponseForm.getRawValue();
    this.surveyResponseForm.markAllAsTouched();
    this.surveyResponseForm.updateValueAndValidity();
    if (this.surveyResponseForm.valid && this.validateComponentRefMapElements()) {
      if (this.surveyResponseForm) {
        this.executeSave(responseForm);
      }
      else {
        this._snackbar.open(this._translateService.instant('siren-survey-translation.error.form_invalid'), null, { duration: 3000 });
        this.disableSave = false;
      }
    } else {
      this._snackbar.open(this._translateService.instant('siren-survey-translation.error.form_invalid'), null, { duration: 3000 });
      this.disableSave = false;
    }
  }

  executeSave(responseForm): void {
    this.error = "";
    this.surveyResponseService.postObject(responseForm).subscribe(response => {
      if (response && response.body) {
        this.saveComponentRefMapElements(response.body.id).subscribe(result => {
          if (result) {
            this.executeClose();
          }
        });
      } else {
        this.disableSave = false;
        this.error = "Could not create object!";
      }
    }, error => {
      if (error.error) {
        this.error = error.error.message;
      } else if (error.message) {
        this.error = error.message;
      } else {
        this.error = "An error has occurred";
      }
      this.disableSave = false;
    });
  }

  executeSubmit(responseForm): void {
    this.error = "";
    let componentRef: ComponentRef<any> = this.componentRefMap.get("responseHeaderAdditionalForm");
    let submitParams = this.surveyResponseClientService.getSubmitSurveyQueryParams(componentRef);
    this.surveyResponseService.submitSurveyResponse(responseForm, submitParams).subscribe(response => {
      if (response && response.body) {
        this.saveComponentRefMapElements(response.body.id).subscribe(result => {
          if (result) {
            this.executeClose();
          }
        });
      } else {
        this.error = "Could not create object!";
        this.disableSave = false;
      }
    }, error => {
      if (error.error) {
        this.error = error.error.message;
      } else if (error.message) {
        this.error = error.message;
      } else {
        this.error = "An error has occurred";
      }
      this.disableSave = false;
    });
  }

  executeClose(): void {
    this.disableSave = false;
    this.goBack();
  }

  //==================================================================================
  //=================== QUESTION FORM GROUP MANAGEMENT ===============================
  //==================================================================================

  getQuestionFormGroupMapForSection(sectionId: number): Map<number, FormGroup> {
    let sectionQuestionGroupMap: Map<number, FormGroup>;
    if (this.sectionQuestionFormGroupMap && sectionId != undefined && this.sectionQuestionFormGroupMap.has(sectionId)) sectionQuestionGroupMap = this.sectionQuestionFormGroupMap.get(sectionId);
    return sectionQuestionGroupMap;
  }

  addQuestionFormGroup(sectionId: number, questionId: number, questionFormGroup: FormGroup) {
    if (this.sectionQuestionFormGroupMap && sectionId != undefined && questionId != undefined && questionFormGroup != undefined) {
      let sectionQuestionGroupMap: Map<number, FormGroup>;
      if (this.sectionQuestionFormGroupMap.has(sectionId)) {
        sectionQuestionGroupMap = this.sectionQuestionFormGroupMap.get(sectionId);
      } else {
        sectionQuestionGroupMap = new Map<number, FormGroup>();
      }
      if (sectionQuestionGroupMap && !sectionQuestionGroupMap.has(questionId)) {
        sectionQuestionGroupMap.set(questionId, questionFormGroup);
        this.sectionQuestionFormGroupMap.set(sectionId, sectionQuestionGroupMap);
      }
    }
    return questionFormGroup;
  }

  goBack() {
    this.isLoading = true;
    this.location.back();
  }

  //==================================================================================
  //===================== INJECTABLE COMPONENT MANAGEMENT ============================
  //==================================================================================


  loadResponseHeaderAdditionalForm() {
    let responseId = this.route.snapshot.params['responseId'];
    responseId = responseId != "create-response" ? responseId : undefined;
    let surveyId = this.route.snapshot.params['surveyId'];
    surveyId = surveyId != undefined ? surveyId : this.route.snapshot.params['id'];
    const componentRef = this.surveyResponseClientService.getAdditionalResponseHeaderTemplate(responseId, surveyId, this.editable());
    this.componentRefMap.set("responseHeaderAdditionalForm", componentRef);
    this.responseHeaderAdditionalFormContainer.insert(componentRef.hostView);
  }

  saveComponentRefMapElements(responseId: number): Observable<boolean> {
    if (this.componentRefMap != null && this.componentRefMap.size > 0) {
      // this.componentRefMap.forEach((value, key) => {
      //   if (value != undefined)
          return this.componentRefMap.get("responseHeaderAdditionalForm").instance.save(responseId);
      // });
    }
    return of(false);
    // this.componentRefMap.get("responseHeaderAdditionalForm").instance.save(response.body.id);

  }

  validateComponentRefMapElements(): boolean {
    let isValid: boolean = true;
    if (this.componentRefMap != null && this.componentRefMap.size > 0) {
      this.componentRefMap.forEach((value, key) => {
        if (value != undefined && isValid) isValid = value.instance.isValidForm();
      });
    }
    return isValid;
  }

  onKeyDownEvent(event: any) {
    if (event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }
}


export class ResponseValidationOutput {
  response: SurveyResponse;
  isValid: boolean;

  constructor(response: SurveyResponse, isValid: boolean) {
    this.response = response;
    this.isValid = isValid;
  }
}
