<div class="col-lg-4 col-sm-6 col-12 element-list-div">
  <div class="cell-list-div">
        <div class="padding-cell">{{'report.fields.cell-count' | translate}} {{ customReportElements?.length ? customReportElements?.length : 0 }}</div>
        <div class="add-update-buttons">
          <button type="button" *ngIf="ordersChanged" title="Move Section Down" class="button-flat" (click)="saveOrder()" class="btn btn-submit">
            <span style="white-space: nowrap;">{{'report.fields.update-order' | translate}}</span>
          </button>
          <button type="button" (click)="addCellElement(); $event.stopPropagation();" class="btn btn-submit">
            <span style="white-space: nowrap;">+</span>
          </button>
        </div>
  </div>
  <div class="border-list" *ngIf="customReportElements && customReportElements.length > 0">
      <div class="cell-delete-list"  *ngFor="let cell of customReportElements; let elementIndex = index">
        <div class="cell-list-order">
          {{ cell.order }}
        </div>
        <div class="cell-list" (click)="editCellElement(cell);">
          <div class="cell-title">
            {{cell.title}}
          </div>
          <div>
            <button type="button" title="Move Section Up" *ngIf="elementIndex > 0" class="button-flat" (click)="moveUp(elementIndex)">
              <img [src]="getAssetsUrl('assets/icons/arrow-down-icon.svg')" class="iconWidth rotateIcon">
            </button>
            <button
              type="button"
              title="Move Section Down"
              *ngIf="this.customReportElements
                && this.customReportElements.length
                && elementIndex < this.customReportElements.length - 1"
              class="button-flat" (click)="moveDown(elementIndex)"
            >
              <img [src]="getAssetsUrl('assets/icons/arrow-down-icon.svg')" class="iconWidth">
            </button>
          </div>
        </div>
        <i (click)="deleteCell(cell.id); $event.stopPropagation();"
          aria-hidden="true"
          title="Delete Link"
          class="fa fa-trash red-trash"
          style="font-size: 18px;"
        ></i>
      </div>
    </div>
</div>
<div class="col-lg-8 col-sm-6 col-12" >
    <app-report-element *ngIf="cellSelected"
      [customReportElementsLength]="customReportElements?.length ? customReportElements?.length : 0"
      [customReportElement]="customReportElement"
      [reportId]="this.customReportId"
      [isEditMode]="isEditMode"
      (cellEventEmitter)="handleCellEmit($event)"
    ></app-report-element>
</div>
