import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from './confirm-dialog.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.sass']
})
export class ConfirmDialogComponent implements OnInit {

  public fName: string;
  public fIndex: any;
  public hideCancelButton: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    this.hideCancelButton = data?.hideCancelButton;
  }

  ngOnInit(): void {
  }

}
