export interface LookupModel {
  id: number;
  key: string;
  value: string;
  order: number;
  deprecated?: boolean;
}


export class LookupModelDto {
  id: number;
  key: string;
  value: string;
  deprecated?: boolean;
  order: number;

  constructor(
    id: number,
    key: string,
    value: string,
    deprecated: boolean,
    order: number
  ) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.deprecated = deprecated;
    this.order = order;
  }
}


export class KeyValueObject {
  key: string;
  value: string;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}

export class IdValueObject {
  id: number;
  value: string;

  constructor(id: number, value: string) {
    this.id = id;
    this.value = value;
  }
}
export class IdObject {
  id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class IdValueOrderObject {
  id: number;
  value: string;
  order: number;

  constructor(id: number, value: string, order: number) {
    this.id = id;
    this.value = value;
    this.order = order;
  }
}

export class QuestionAnswerTypes{
  questionId: number;
  answerTypes: LookupModel[];

  constructor(id: number, lookup: LookupModel[]) {
    this.questionId = id;
    this.answerTypes = lookup;
  }
}
