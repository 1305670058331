import { DropdownOptionModel } from "./dropdown-option.model";

export class DialogDataModel<T> {
    constructor(
        public Data?: T,
        public Lookups?: {[key:string]: DropdownOptionModel[]},
        public LookupsAny?: any
    ) {
    }

}
