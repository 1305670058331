import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetailsDialogModel } from '../details-dialog/details-dialog.model';


@Component({
  selector: 'confirm',
  templateUrl: 'confirm-component.html',
})
export class ConfirmComponent {

  confirmationMessage: String = this.data.confirmationMessage;
  icon: String = this.data.icon;

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DetailsDialogModel
  ) { }

  onResponse(isAccept: boolean) {
    this.dialogRef.close(isAccept);
  }

}