import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Settings } from '../bases/settings.class';
// import { SettingsService } from 'src/app/shared/services/settings.service';

@Injectable({ providedIn: 'root' })
export class SettingsHttpService {

    constructor(private settingsService: SettingsService,
                private httpClient: HttpClient) {
    }

    public initializeApp(): Promise<any> {

        return this.httpClient.get('assets/configs/settings.json').pipe(tap((settings: Settings) => {

            this.settingsService.settings = settings;
        })).toPromise();
    }
}
