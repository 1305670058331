import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DropdownComparatorWithBrowserListener } from "@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser";
import { TableFilterOutput } from "@siren-survey/app/models/shared/table-filter.model";
import { LookupService } from "@siren-survey/app/services/lookups/lookup.service";
import { Observable, Subscription, forkJoin, interval, map, takeWhile } from "rxjs";
import { BlobObject, UploadStatus } from "@siren-survey/app/models/blob/blob";
import { CustomReportService } from "src/app/services/custom-report/custom-report.service";
import { BlobService } from "@siren-survey/app/services/blob/blob.service";
import { CustomReportFormInput } from "../report-list/report-list.component";
import { CustomReportGenerateComponent } from "../custom-report-generate/custom-report-generate.component";
import { MatDialog } from "@angular/material/dialog";
import { LookupModel } from "@siren-survey/app/models/shared/lookup.model";
import { AssessmentService } from "src/app/services/assessment/assessment.service";
import { DashboardService } from "../../dashboard/services/dashboard.service";
import { FilterLookupService } from "../services/filter-lookup.service";

@Component({
  selector: 'app-custom-report-generated-list',
  templateUrl: './custom-report-generated-list.component.html',
  styleUrls: ['./custom-report-generated-list.component.sass']
})
export class CustomReportGeneratedListComponent extends DropdownComparatorWithBrowserListener implements OnInit, OnDestroy {

  public reportId: number;
  public includeProgramAttendance: boolean;
  customReportResponseList: CustomReportResponseList[] = [];
  currentPage: number = 0;
  maxPage: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  initiateSearchFromFilter = true;
  predefinedFilter: Map<string, any>;

  from: Date;
  to: Date;

  private _subscription = new Subscription();

  public filterForm: FormGroup;

  public programs: LookupModel[] = [];
  public genders: LookupModel[] = [];
  public tags: LookupModel[] = [];
  public activities: LookupModel[] = [];
  public beneficiaryStatuses: LookupModel[] = [];
  public filterLookupsRetrieved: boolean = false;
  hasInProgressReports: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private customReportService: CustomReportService,
    protected http: HttpClient,
    private blobService: BlobService,
    private _route : ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
    private _snackbar: MatSnackBar,
    private _lookupService: LookupService,
    private readonly _translateService: TranslateService,
    private _dialog: MatDialog,
  ){
    super();
  }

  ngOnInit(): void {
    this.goBack = this.goBack.bind(this);
    this.constructFormGroup();
    this._subscription.add(
      this._route.params.subscribe((params) => {
        this.reportId = params['id'];
        this.includeProgramAttendance = JSON.parse(params['includeProgramAttendance']);
      })
    );

    if (this.reportId != undefined && this.reportId > 0) {
      this.getPageData();
    }

    this._subscription.add(
      this.startScheduler().subscribe(() => {
        if (this.hasInProgressReports)
          this.getPageData();
      }));
  }

  constructFormGroup() {
    this.filterForm = this.formBuilder.group({
      'id': '',
      'fileName': '',
      'fileSize': '',
      'fileType': '',
      'objectType': '',
      'objectId': '',
      'status': '',
    });
  }

  applyFilters() {
    this.getPageData();
  }


  clearFilters() {
    this.constructFormGroup();
    this.applyFilters();
  }

  onKeyDownEvent(event: any) {
    if (event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }

  getPageData() {
    if (this.reportId != undefined && this.reportId > 0) {
      let params = 'page=' + this.currentPage + '&size=' + this.pageSize + "&customReportId=" + this.reportId;
      this._subscription.add(
        this.customReportService.getGenerateReports(params).subscribe(response => {
          if (response && response.body) {
            this.customReportResponseList = response.body.content;
            this.customReportResponseList.forEach(customReport => {
              if (!this.hasInProgressReports) {
                this.hasInProgressReports = (customReport.status == UploadStatus.IN_PROGRESS) ? true : false;
              }
            });
            this.maxPage = response.body.totalPages;
            this.totalElements = response.body.totalElements;
          }
        }));

        this._subscription.add(
          this._lookupService.getLookupObjectsService(["Program","Activity","BeneficiaryStatus"]).subscribe(result=>{
          this.programs = result.body.Program;
          this.beneficiaryStatuses = result.body.BeneficiaryStatus;
          this.activities = result.body.Activity;
          })
        );

    }
  }

  changePageSize(event: any) {
    this.resetPagination();
    if (event && event.value != undefined) this.pageSize = event.value;
    this.getPageData();
  }

  resetPagination() {
    this.currentPage = 0;
    this.maxPage = 0;
    this.pageSize = 10;
  }

  getNextPage() {
    if (this.currentPage < this.maxPage - 1) {
      this.currentPage++;
      this.getPageData();
    }
  }

  getPreviousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.getPageData();
    }
  }

  applyFilter(event: TableFilterOutput) {
    if (event) {
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
    }
  }

  downloadFile(file: BlobObject) {
    if (file.status == UploadStatus.DONE) {
      this._subscription.add(
        this.blobService.downloadBlobObjects(file.id).subscribe((response) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(response.body);
          link.download = file.fileName + "." + file.fileType;
          link.click();
          window.URL.revokeObjectURL(link.href);
          this._snackbar.open(this._translateService.instant('snackbar.successful-download'), 'Close', {
            duration: 2000,
          });
        }, error => {
          this._snackbar.open(this._translateService.instant('snackbar.error-download'), 'Close', {
            duration: 2000,
          });
        }));
    }
  }

  goBack() {
    this.location.back();
  }

  private startScheduler(): Observable<number> {
    const intervalMinutes = 1; // Change this value to the desired interval in minutes
    const intervalMilliseconds = intervalMinutes * 60 * 1000; // Convert minutes to milliseconds

    return interval(intervalMilliseconds)
      .pipe(
        takeWhile(() => true)
      );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  generateReport(){
    let serveyGenerateReportForm = this.openChangeStatusFormWindow(this.reportId, this.includeProgramAttendance);
      serveyGenerateReportForm.afterClosed().subscribe((response: any) => {
        this.getPageData();
      });

  }

  openChangeStatusFormWindow(reportId: number, includeProgramAttendance: boolean): any{
    let input: CustomReportFormInput = new CustomReportFormInput(reportId,includeProgramAttendance,false,this.programs,this.genders,this.tags,this.activities,this.beneficiaryStatuses);
    const activityExecutionFormDialog = this._dialog.open(CustomReportGenerateComponent, {
      "width": '6000px',
      "maxWidth": '80vw',
      "maxHeight": '80vh',
      "autoFocus": false,
      data: input,
      direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });
    return activityExecutionFormDialog;
  }
}


export interface CustomReportResponseList {
  id: string;
  fileName: string;
  fileSize: string;
  fileType: string;
  objectType: string;
  objectId: number;
  status: string;
}

