import { Component, Input } from '@angular/core';
import { BlobComponent } from '../blob.component';
import { BlobObject } from '@siren-survey/app/models/blob/blob';

@Component({
  selector: 'app-beneficiary-profile-image',
  templateUrl: './beneficiary-profile-image.component.html',
  styleUrls: ['./beneficiary-profile-image.component.sass']
})
export class BeneficiaryProfileImageComponent extends BlobComponent {
  public files: any[] = [];
  profileImageUrl: string;
  isRound = true;
  selectedFile: File | null = null;
  uuid: string = '';
  changeProfileCounter: number = 0;
  maxFileSizeInMb: number;
  hasAccess: boolean = false;

  ngOnInit(): void {
    this.hasAccess = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.BENEFICIARY_MODIFY);
    if (this.objectId) {
      let params = 'page=' + this.currentPage + '&size=' + this.pageSize + '&type=' + this.type + '&id=' + Number(this.objectId);
      this.blobService.getBlobObjects(params).subscribe(response => {
        if (response?.body) {
          this.files = (response.body as any).content;
          if (this.files?.length > 0) {
            this.uuid = this.files.at(0).id;
            this.profileImageUrl = this.settingsService.settings.backendBaseUrl + "/v1/blob/" + this.files.at(0).id
          }
        }
      }, error => {
      });
    }
    this.maxFileSizeInMb = this.settingsService?.settings?.uploadedFileMaxSizeInMb;
  }

  getAllowedExtensions() {
    let extensions = this.settingsService?.settings?.allowedImageExtensions;
    if (extensions) {
      extensions = "." + extensions.replaceAll(",", ",.");
    } else {
      extensions = "*";
    }
    return extensions;
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    event.target.value = '';
    this.uploadProfilePicture(this.selectedFile);
  }

  uploadProfilePicture(file?) {
    if (this.selectedFile) {
      let error = this.isFileValid(file);
      if (error != undefined) {
        this._snackbar.open(error, this._translateService.instant('snackbar.unable-upload'), { duration: 2000 });
      } else {
        this.fileCounter++;
        const formData = new FormData();
        formData.append("uploadedFile", file);
        formData.append("type", this.type);
        formData.append("id", this.objectId);
        formData.append("uuid", this.uuid);
        formData.append("formId", this.fileCounter.toString());
        formData.append("isUploaded", "false");

        this.uploadBlobFile(formData);
      }
    }
  }

  isFileValid(file: File): string {
    let error = undefined;
    if (file != undefined) {
      if (this.settingsService?.settings != undefined) {
        const fileExtension = file?.name?.split('.').pop();
        let fileSizeInMb = this.getFileSizeForComparaisonInMB(file.size);
        if (fileExtension && this.settingsService?.settings.allowedImageExtensions) {
          const allowedImageExtensions = this.settingsService?.settings.allowedImageExtensions.split(',');
          if (!allowedImageExtensions.includes(fileExtension))
            error = this._translateService.instant('snackbar.invalid-file')
              + this.settingsService?.settings.allowedImageExtensions
              + this._translateService.instant('snackbar.are-allowed');
        }
        if (!error && this.maxFileSizeInMb != undefined && fileSizeInMb > this.maxFileSizeInMb)
          error = this._translateService.instant('snackbar.file-too-large')
            + this.maxFileSizeInMb
            + this._translateService.instant('snackbar.mb');
      }
    } else {
      error = this._translateService.instant('snackbar.unable-load-file');
    }
    return error;
  }

  getFileSizeForComparaisonInKB(fileSize: number) {
    return fileSize / 1024;
  }

  getFileSizeForComparaisonInMB(fileSize: number) {
    return fileSize / 1024 / 1024;
  }

  uploadBlobFile(formData) {
    this.blobService.postUploadBlob(formData).subscribe(response => {
      let blobObj: BlobObject = response.body;
      let fileObj: any = {
        id: blobObj.id,
        fileName: blobObj.fileName,
        fileSize: blobObj.fileSize,
        fileType: blobObj.fileType,
        isUploaded: "true"
      };
      this.changeProfileCounter++;
      this.profileImageUrl = this.settingsService.settings.backendBaseUrl + "/v1/blob/" + fileObj.id + "?reloadCounter=" + this.changeProfileCounter;
      this._snackbar.open(this._translateService.instant('snackbar.successful-upload'), 'Close', {
        duration: 2000,
      });
    }, error => {
      this._snackbar.open(this._translateService.instant('snackbar.error-upload'), 'Close', {
        duration: 2000,
      });
    });
  }

  preventDefault(event: Event) {
    event.preventDefault();
  }
}
