<div style="margin: 10px 0px;" [formGroup]="programOrganizationFormGroup" >
  <mat-accordion class="profile-headers-align" multi>
      <!-- PROGRAM BENEFICIARIES LIST -->
      <mat-expansion-panel [expanded]="false" (opened)="true" #benefPanel class="remove-mat-expansion-panel-padding">
        <mat-expansion-panel-header class="expansion-panel-header locate-color-header">
          <mat-panel-title class="panel-title">
            <div class="expansion-header-container">
              <div style="width: 100%;">
                <span>{{ title }}  : {{programBeneficiaries.length}}</span>
                <span *ngIf="hasUnsavedBeneficiariesCount > 0" class="unsaved-benef-label">{{ 'program.message.unsaved-beneficiaries' | translate}}</span>
              </div>
              <button *ngIf="isEditableProgOrgData && canAddBeneficiaries()" title="{{ 'program.buttons.add-beneficiary' | translate }}"
                  class="btn flat-button" type="button" mat-button (click)="addProgramBeneficiary(); $event.stopPropagation();">
                <span class="fa fa-plus"></span>
              </button>
              <button *ngIf="isLoadingBeneciaries" class="btn flat-button" type="button" mat-button>
                <span class="fa fa-spinner fa-spin"></span>
              </button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div formArrayName="programBeneficiaries" class="mat-expansion-container scrollable-container">
          <div *ngFor="let progBenef of programBeneficiaries?.controls; let i = index;" [formGroupName]="i" class="flex-row-container class-selector-container">
            <div class="flex-row-container full-width-element">
              <div class="row prog-act-row">
                <div class="col-lg-3 col-sm-6 col-12 elem-no-padding tooltip-input-container "  (click)="$event.stopPropagation();">
                  <app-multiple-searchable-selection
                    class="full-width-element"
                    [placeHolder]="'program.fields.beneficiary' | translate"
                    formControlName="beneficiary"
                    [lookups]="getAvailableBeneficiaries(i)"
                    [formControl]="progBenef.get('beneficiary')"
                    [isMultiSelect]="false"
                  ></app-multiple-searchable-selection>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <march-datepicker
                    [max]="progBenef?.get('exitDate')?.getRawValue()"
                    [title]="'program.fields.enrollment-date' | translate"
                    [dateFormControl]="progBenef?.get('enrollmentDate')"
                  ></march-datepicker>
                </div>

                <div class="col-lg-3 col-sm-6 col-12 remove-padding">
                  <march-datepicker
                    [min]="progBenef?.get('enrollmentDate')?.getRawValue()"
                    [title]="'program.fields.exit-date' | translate"
                    [dateFormControl]="progBenef?.get('exitDate')"
                  ></march-datepicker>
                </div>
                <div class="col-lg-3 col-sm-6 col-12 activity-field tooltip-input-container elem-no-padding" (click)="$event.stopPropagation();">
                  <mat-form-field class="full-width-element mat-input-with-no-space " >
                    <mat-label class="">{{'program.fields.beneficiary-comment' | translate}}</mat-label>
                    <textarea  matInput type="text-area" autocomplete="off" min="0" class="" formControlName="comment"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <button *ngIf="progBenef.get('deletable').value  && isEditableProgOrgData" title="{{ 'program.buttons.remove-beneficiary' | translate }}"  type="button"
                     class="flat-button" (click)="removeProgramBeneficiary(i); $event.stopPropagation();">
                <img src="assets/icons/delete-icon.svg" style="width: 20px;">
              </button>
            </div>
          </div>
          <div *ngIf="this.programBeneficiaries == undefined || this.programBeneficiaries?.length == 0">
            <span class="empty-list-label">{{ 'beneficiary-form.fields.no-enrolled-beneficiaries' | translate }}</span>
          </div>
        </div>
      </mat-expansion-panel>

      <!-- <app-survey-link-selection #programOrganizationSurveyLink *ngIf="program.id" [isEditable]="isEditableProgOrgData" [program]="this.program" [organizationId]="organizationId" ></app-survey-link-selection> -->
  </mat-accordion>
</div>
