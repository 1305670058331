<app-responsive-list-view
  [tableColumns]="_tableColumns"
  [sortingDataAccessor]="_sortingDataAccessor"
  [filteringPredicate]="_filteringPredicate"
  [service]="service"
  [withAdd]="true"
  [hasListAccess]="true"
  [maxCellStringLength]="80"
  [maxStringLength]="20"
  (mode)="updateViewMode($event)"
  [inputFilterEventSubject]="filterEventSubject.asObservable()"
  [_tableMenuAction]="_tableMenuAction"
  [booleanIconColumnDisplayList]="[]"
  (menuActionEventEmitter)="tableMenuActionHandler($event)"
  addButtonLabel="{{ 'siren-survey-translation.global.add' | translate }}"
  tableTitleLabel="{{
    'siren-survey-translation.survey-table.manage-access' | translate
  }}"  
  [inputInlineEditEventSubject]="inlineEditEventSubject.asObservable()"
  [inlineEditable]="true"
  [inputReloadEventSubject]="reloadEventSubject.asObservable()"
  [showFilterButton]="false"
  [showBackButton]="false"
  [initiateSearchFromFilter]="initiateSearchFromFilter"
  initialPageSize="10"
>
</app-responsive-list-view>
