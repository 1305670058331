import { ValidatorFn } from "@angular/forms";

export interface FieldConfigModel {
    prop: string;
    type: FieldType;
    label: string;
    defaultValue: any;
    isDisabled?: boolean;
    isRequired?: boolean;
    validators?: ValidatorFn[];
    maxLength?: number;
    size?: string;
    fixedSize?: boolean,
    lookupKey?: string;
    isHidden?: boolean;
    visibleIfField?: string;
    visibleIfFieldValue?: any;
    relatedField?: string;
    dependancyFieldName?: string;
    minDate?: Date | string;
    maxDate?: Date | string;
    withFilter?: boolean;
    withClearButton?: boolean,
    children?: FieldConfigModel[],
    listOfValues?: string[]
}


export enum FieldType {
    string,
    number,
    date,
    dateTime,
    dropdown,
    checkbox,
    space,
    text,
    password,
    multiselectdropdown,
    month,
    toggle,
    array,
    upload
}
