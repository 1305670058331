import { Subject } from "rxjs";
import { FieldConfigModel } from "../../models/fields-config.model";

export class DetailsDialogModel {
    constructor(
        public data: any,
        public fields: FieldConfigModel[],
        public lookups: any,
        public detailsTitle: string,
        public isDisabled: boolean = false,
        public modalTitle: string,
        public confirmationMessage: string,
        public icon: string,
        public isReport?: boolean,
        public externalComponentComunicationSubject? : Subject<any>
    ) { }
}
