export const environment = {
  production: true,
  // defaultLanguage: "en",
  // menuPerSurveyType: true,
  // backendBaseUrl: "http://localhost:8081",
  // surveyUrlPath: "/v1/survey",
  // lookupUrlPath: "/v1/lookups",
  // lookupObjectUrlPath: "/v1/object-lookups",
  // adminLookupUrlPath: "/v1/admin/lookups",
  // questionBankUrlPath: "/v1/survey/question",
  // surveyResponseUrlPath: "/v1/survey/response",
  // loggingUrlPath: "",
  // surveyAssetsBasePath: "/march_web/app/siren_survey_web/src"
};
