<div class="fullwidth survey-container" >
  <app-responsive-list-view
       [tableColumns]="_tableColumns"
       [sortingDataAccessor]="_sortingDataAccessor"
       [filteringPredicate]="_filteringPredicate"
       [service]="service"
       [withAdd]="false"
       [hasListAccess]="hasListAccess"
       [selectionMode]="tableSelectionMode.SINGLE_SELECT_MODE"
       [deleteMode]="tableDeleteMode.NONE_MODE"
       [showBackButton]="false"
       [maxCellStringLength]="80"
       [maxStringLength]="20"
       tableTitleLabel="{{'program.survey-selection.survey-selection-list' | translate}}"
       [inputFilterEventSubject]="filterEventSubject.asObservable()"
       (selectionEventEmitter)="handleSelectionSubmission($event)"
       [showFilterButton]="true"
       [additionalParams]="additionalParams"
       initialPageSize="10">
       <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
       <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
   </app-responsive-list-view>
 </div>
