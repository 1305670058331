import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomReportTable } from 'src/app/models/custom-report/customReport';
import { CustomReportService } from 'src/app/services/custom-report/custom-report.service';
import { FilterLookupService } from '../services/filter-lookup.service';

@Component({
  selector: 'app-custom-report-table',
  templateUrl: './custom-report-table.component.html',
  styleUrls: ['./custom-report-table.component.sass']
})
export class CustomReportTableComponent implements OnInit, OnDestroy {

  private _subscription = new Subscription();
  private _reportId: number;
  private _includeProgramAttendance: string = "";
  private _programIds: string = "";
  private _genderId: string = "";
  private _beneficiaryStatusId: string = "";
  private _tagOperator: string = "";
  private _activitiesIds: string = "";
  private _tagsIds: string = "";
  private _fromDate: string = "";
  private _toDate: string = "";
  private _programStartDate: string = "";
  private _programEndDate: string = "";
  private _params: string = "";
  public title: string = "";
  public customReportTable: CustomReportTable;

  constructor(
    private customReportService: CustomReportService,
    private _route : ActivatedRoute,
    private location: Location,
    public translateService: TranslateService,
    private _filterLookupService: FilterLookupService,
  ){  }

  ngOnInit(): void {
    this.goBack = this.goBack.bind(this);

    this._subscription.add(
      this._route.params.subscribe((params) => {
        this._reportId = params['id'];
        this.title = params['title'];

        this._includeProgramAttendance = this._filterLookupService.getObject('includeProgramAttendance');
        this._programIds = this._filterLookupService.getObject('programIds');
        this._activitiesIds = this._filterLookupService.getObject('activitiesIds');
        this._tagsIds = this._filterLookupService.getObject('tagsIds');
        this._fromDate = this._filterLookupService.getObject('fromDate');
        this._toDate = this._filterLookupService.getObject('toDate');
        this._programStartDate =  this._filterLookupService.getObject('programStartDate');
        this._programEndDate = this._filterLookupService.getObject('programEndDate');
        this._genderId = this._filterLookupService.getObject('genderId');
        this._beneficiaryStatusId = this._filterLookupService.getObject('beneficiaryStatusId');
        this._tagOperator = this._filterLookupService.getObject('tagOperator');
        this._filterLookupService.removeAllObjects();

        const addParam = (param, value) => typeof value !== 'undefined' ? `&${param}=${value}` : '';
        this._params = "customReportId=" + this._reportId +
        addParam("includeProgramAttendance", this._includeProgramAttendance) +
        addParam("programIds", this._programIds) +
        addParam("activitiesIds", this._activitiesIds) +
        addParam("tagsIds", this._tagsIds) +
        addParam("fromDate", this._fromDate) +
        addParam("toDate", this._toDate) +
        addParam("programStartDate", this._programStartDate) +
        addParam("programEndDate", this._programEndDate) +
        addParam("genderId", this._genderId) +
        addParam("beneficiaryStatusId", this._beneficiaryStatusId) +
        addParam("tagOperator", this._tagOperator);
        })
    );

    if (this._reportId > 0) {
      this.getPageData();
    }
  }

  getPageData(){
    this.customReportService.getCustomReportTable(this._reportId, this._params).subscribe(response =>{
      this.customReportTable = response.body;
    });
  }

  prepareData() {
    if (this.customReportTable) {
      const preparedData = [];
      this.customReportTable.values.forEach((row) => {
        const rowData = {
          beneficiaryName: row.beneficiaryName,
        };
        this.customReportTable.headers.forEach((header) => {
          const foundValue = row.values.find((value) => value.order === header.order);
          rowData[header.value] = foundValue ? foundValue.value : '';
        });
        preparedData.push(rowData);
      });
      return preparedData;
    }
    return [];
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
