import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.sass']
})
export class TitleComponent {
  @Input() title: string;
  @Input() goBackCallback: () => void; // Callback function

  goBack() {
    if (this.goBackCallback) {
      this.goBackCallback(); // Invoke the callback function
    }
  }
}
