import { ISessionModel, IUserProfile } from "@siren-survey/app/models/session.interface";
import { UserProfile } from "./user-profile.model";
import { Authority } from "../auth/login-response.model";
import { UserRole } from "@siren-auth/app/admin/user-roles/user-roles.model";
import { LookupModel } from "@siren-survey/app/models/shared/lookup.model";

export class SessionModel implements ISessionModel{
    login_time: Date;
    user_profile: UserProfile;
    user_id: number;
    activeOrganizationRoles: UserRole[];
    token_type: string = "Bearer";
    access_token: string;
    access_token_expires_in: string = "600";
    refresh_token: string;
    refresh_token_expires_in: string = "1800";

    authorities: Authority<String>[];

    loginSession(): void{
        this.login_time = new Date(Date.now());
    }
    getUserId(): number{
      return this.user_id;
    }
    getUserProfile(): UserProfile {
      return this.user_profile;
    }
    getTokenType(): string {
      return this.token_type;
    }
    getAccessToken(): string {
      return this.access_token;
    }
    getAccessTokenExpiresIn(): string {
      return this.access_token_expires_in;
    }
    getRefreshToken(): string {
      return this.refresh_token;
    }
    getRefreshTokenExpiresIn(): string {
      return this.refresh_token_expires_in;
    }
    getAuthorities(): Authority<String>[]{
      return this.authorities;
    }

    getAuthoritiesStringList(): String[]{
      let userAuthorities: String[] = [];
      if(this.getAuthorities() && this.getAuthorities().length > 0){
        // for(let currentAuthority of this.getAuthorities()){
        //   if(currentAuthority){
        //     userAuthorities.add currentAuthority.key
        //   }
        // }
        userAuthorities = this.addAuthorityToList(userAuthorities, this.getAuthorities());
      }
      return userAuthorities;
    }

    addAuthorityToList(userAuthorities: String[], currentAuthorityList: Authority<String>[]): String[]{
      if(userAuthorities == undefined) userAuthorities = [];
      if(currentAuthorityList){
        for(let currentAuthority of currentAuthorityList){
          userAuthorities.push(currentAuthority.key);
          if(currentAuthority.authorities != undefined && currentAuthority.authorities.length > 0) {
            this.addAuthorityToList(userAuthorities, currentAuthority.authorities);
          }
        }
      }
      return userAuthorities;
    }

    getActiveOrganizationIds(): number[]{
      let organizationIds: number[] = []
      if(this.activeOrganizationRoles){
        for(let currUserRole of this.activeOrganizationRoles){
          if(currUserRole && currUserRole.organization && currUserRole.organization.id != undefined && currUserRole.organization.id > 0)
            organizationIds.push(currUserRole.organization.id);
        }
      }
      return organizationIds;
    }


    getActiveOrganizations(): LookupModel[]{
      let organizations: LookupModel[] = []
      if(this.activeOrganizationRoles){
        let order: number =0;
        for(let currUserRole of this.activeOrganizationRoles){
          if(currUserRole && currUserRole.organization && currUserRole.organization.id != undefined && currUserRole.organization.id > 0){
            order++;
            let model:LookupModel = {
              id: currUserRole.organization.id,
              key: currUserRole.organization.id + '',
              order: order,
              value: currUserRole.organization.name,
              deprecated: false
            }
            organizations.push(model);
          }
        }
      }
      return organizations;
    }
}


export interface IdNameObject{
  id: number;
  name: string;
}
