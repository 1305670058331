import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ISurveyAuthenticationService } from '../services/interfaces/authentication.interface';

@Injectable()
export class CanActivatePageGuard implements CanLoad, CanActivate {

  constructor(public authenticationService: ISurveyAuthenticationService, private router: Router) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      const canAccess = this.authenticationService.isModuleAccessible(route?.data?.pageAccess);
      if (canAccess) {
        return true;
      } else {
        //TODO check what to do with this and uncomment
        this.router.navigate(['/']);
        return false;
      }
  }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      let canAccess: boolean = false;
      if(route?.data?.pageAccess){
        canAccess = this.authenticationService.isModuleAccessible(route?.data?.pageAccess);
      } else if(route?.data?.moduleName){
        let consumerPermissionEnum = this.authenticationService.getPermissionEnumeration();
        if(route?.data?.moduleName == "MODULE_SURVEY"){
          canAccess = this.authenticationService.isModuleAccessible(consumerPermissionEnum.SURVEY_LIST.module);
        } else if(route?.data?.moduleName == "MODULE_QUESTION"){
          canAccess = this.authenticationService.isModuleAccessible(consumerPermissionEnum.QUESTION_LIST.module);
        } else if(route?.data?.moduleName == "MODULE_RESPONSE"){
          canAccess = this.authenticationService.isModuleAccessible(consumerPermissionEnum.SURVEY_RESPONSE_LIST.module);
        }
      }
      if (canAccess) {
          return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
   }
}
