import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { FormType } from 'src/app/models/admin/program/formType/formType';

@Injectable({
  providedIn: 'root'
})
export class FormTypeService  extends BaseService<FormType, FormType> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/survey/surveyType", http, injector);
  }

  checkCustomNameUnicity(surveyTypeId: number,value: string): Observable<HttpResponse<boolean>>{
    return this.http.get<boolean>(`/v1/survey/surveyType/name-valid?${surveyTypeId > 0 ? `surveyTypeId=${surveyTypeId}&` : ''}value=${value}`);
  }

}
