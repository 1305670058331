
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxImageCompressService } from 'ngx-image-compress';
import { DocumentModel } from '../../models/document.model';
import { WarningDialogComponent } from '../dialogs/warning-dialog/warning-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-field',
  templateUrl: './upload-field.component.html',
  styleUrls: ['./upload-field.component.scss']
})
export class UploadFieldComponent {
  @ViewChild('uploadPhoto', { static: false }) uploadPhoto: ElementRef;

  @Input() placeholdertext: string;
  @Input() section: string;
  @Input() singleFile: boolean = true;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() inputType: string = "upload";
  @Input() validatorErrorDisplayed: boolean = false;
  @Input() validateFilesExtension: boolean = true;
  @Input() canUpload;
  @Input() canDownload;
  @Input() canDeleteBlob;
  @Output() changes: EventEmitter<any> = new EventEmitter<any>();
  @Output() hasFileChangedEvent = new EventEmitter<boolean>();
  @Output() downloadFile = new EventEmitter<any>();

  public file: any[] = [];
  public documentsList: DocumentModel[] = [];
  mustUploadFileError = false;
  maxFileSizeInBytes = 10 * 1024 * 1024;
  imageExtensions = ['.jpg', '.jpeg', '.gif', '.png', '.bmp', '.tif'];
  // From BE
  allowedFilesExtensions = '.xls, .xlsx, .bmp, .doc, .docx, .pdf, .jpeg, .png, .tif, .jpg, .csv';

  constructor(public dialog: MatDialog,
    private imageCompressService: NgxImageCompressService,
    private _translateService: TranslateService) {
  }

  public fileSelected(event) {
    if (event?.target?.files[0]) {
      if (event?.target?.files[0].size > this.maxFileSizeInBytes) {
        this.dialog.open(WarningDialogComponent, {
          width: '400px',
          data: { arabic: 'errors.large_file', english: 'errors.large_file_en', canClose: true },
          direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
        });
      }
      else {
        this.hasFileChangedEvent.emit(true);
        this.file.push(event.target.files[0]);
        this.validatorErrorDisplayed = false;
        if (event.target.files && event.target.files[0]) {

          let filename = event?.target?.files[0].name;
          let fileExt = ".";
          fileExt += filename.split('.').pop();
          if (this.imageExtensions.includes(fileExt) && event?.target?.files[0].size > 1000000) {
            let reader = new FileReader();
            reader.onload = (eve: any) => {
              this.compressFile(eve.target.result, filename);
            }
            reader.readAsDataURL(event.target.files[0]);

          }
          else {
            this.addFileToDoc({ file: event.target.files[0], fileName: event.target.files[0].name, key: '' });
          }
        }
      }
    }
  }

  public addFile(event) {
    event.preventDefault();
    this.uploadPhoto.nativeElement.click();
  }


  public isRequiredAndNotFilled() {

    return this.required && this.documentsList.length == 0
  }

  private compressFile(image, fileName) {
    var orientation = -1;
    this.imageCompressService.compressFile(image, orientation, 50, 50).then(
      result => {
        const imageFile = this.dataURItoFile(result, fileName);
        this.addFileToDoc({ file: imageFile, fileName: fileName, key: '' });
      });
  }

  dataURItoFile(dataURI, fileName) {
    var byteString = window.atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    let imageBlob = new Blob([ab], { type: mimeString });
    return new File([imageBlob], fileName, { type: mimeString });
  }

  private addFileToDoc(doc: DocumentModel) {
    this.documentsList.forEach((doc, index) => this.deleteFile(doc.key, index));
    if (this.singleFile) {
      if (this.documentsList && this.documentsList.length) {
        doc.toUpdate = true;
      }
      this.documentsList = [doc];
    }
    else {
      this.documentsList.push(doc);
    }
    this.uploadPhoto.nativeElement.value = '';
    this.mustUploadFileError = false;
    this.changes.emit(this.documentsList)
  }

  public getDocuments() {
    return this.documentsList.map(m => {
      m.section = this.section;
      return m;
    });
  }

  public resetDocuments() {
    return this.documentsList = [];
  }

  public setDocuments(list: DocumentModel[]) {
    this.documentsList = list.filter(f => f.section === this.section);
    this.changes.emit(this.documentsList)
  }

  public isUploadDocValid(): boolean {

    const valid = !this.required || (this.required && this.documentsList && this.documentsList.length > 0);
    if (!valid)
      this.validatorErrorDisplayed = true;
    return valid
  }

  public deleteFile(urlKey: string, index: number) {
    if (urlKey === '') {
      this.documentsList.splice(index, 1);
    } else {
      this.documentsList[index].toDelete = true;
    }
    this.file.splice(index, 1);
    this.changes.emit(this.documentsList);
    this.hasFileChangedEvent.emit(true);
  }

  public clickDownloadFile(doc) {
    if(doc.key && this.canDownload) {
      this.downloadFile.emit(doc.key);
    }
  }

  public isANewDoc() {

    return this.documentsList.find((d) => d && !d.key);
  }
}
