import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatatableInlineEdit } from '@siren-survey/app/component/shared/datatable/datatable-inline-edit.service';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { ResponsiveListInlineEditObject, TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { Subject } from 'rxjs';
import { ActivityList } from 'src/app/models/admin/program/activities/activityList';
import { ActivitiesService } from 'src/app/services/admin/program/activities.service';
import { ActivityCategoriesService } from 'src/app/services/admin/program/activity-categories.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';

@Component({
  selector: 'app-activity-categories-admin-table',
  templateUrl: './activity-categories-admin-table.component.html',
  styleUrls: ['./activity-categories-admin-table.component.sass']
})
export class ActivityCategoriesAdminTableComponent  extends DatatableInlineEdit implements OnInit {
  tableTitleLabel: string;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  inlineEditEventSubject: Subject<ResponsiveListInlineEditObject> = new Subject<ResponsiveListInlineEditObject>();

  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;
  canAdd: boolean = false;
  hasListAccess: boolean = false;
  predefinedFilter: Map<string, any>;

  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  _booleanIconColumnDisplayList: string[] = [
    "enabled","published"
  ]

  _tableColumns  : DataTableModel<ActivityList> []= [
    {  columnDef: 'name', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "activity-categories.fields.category-name", header: this._translateService.instant('activity-categories.fields.category-name'),  cell: (element: ActivityList) => `${element?.name}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'enabled', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "activity-categories.fields.category-enabled", header: this._translateService.instant('activity-categories.fields.category-enabled'),  cell: (element: ActivityList) => `${element?.enabled}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'creationDate', type: TableFilterTypeEnum.Date, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "activity-categories.fields.creation-date", header: this._translateService.instant('activity-categories.fields.creation-date'),  cell: (element: ActivityList) => `${element.creationDate ? this.datePipe.transform(element.creationDate, 'dd / MM / yyyy') : "-"}`, width: undefined},
    {  columnDef: '_menu_inline', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "name", translationPath: "activity-categories.fields.category-name", displayName: this._translateService.instant('activity-categories.fields.category-name'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "creationDate", translationPath: "activity-categories.fields.creation-date", displayName: this._translateService.instant('activity-categories.fields.creation-date'), type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal, cssClass: 'col-xs-12 col-sm-6 col-md-4'  },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ACTIVITY_CATEGORY_UPDATE) , accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ])},
    { name: this._translateService.instant('global.enable'), actionName: "ENABLE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg", accessRightExpressions: new Map<string, string>([ ["enabled", "enabled == undefined || enabled == false"], ["id", "id > 0"] ]), allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ENABLE_ACTIVITY_CATEGORY) },
    { name: this._translateService.instant('global.disable'), actionName: "DISABLE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg", accessRightExpressions: new Map<string, string>([ ["enabled", "enabled == true"], ["id", "id > 0"] ]), allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ENABLE_ACTIVITY_CATEGORY) },
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string{
    return this._translateService.instant('global.delete');
  }

  constructor(public service : ActivityCategoriesService, private router: Router, private route : ActivatedRoute, private readonly _translateService: TranslateService,
    private dialogService: DialogService, public lookupService: LookupService, public snackBar: MatSnackBar, public authService: AuthenticationService, private datePipe: DatePipe) {
    super();
    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ACTIVITY_CATEGORY_CREATE);
    this.hasListAccess = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ACTIVITY_CATEGORY_LIST);

  }

  ngOnInit(): void {
  }

  lastCreatedId = 0;
  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.CREATE_MODE) {
      this.lastCreatedId--;
      let editFormGroup = new FormGroup({
        'id': new FormControl({value: this.lastCreatedId, disabled: true}),
        'name': new FormControl({value:'', disabled: false}, [Validators.required, CustomValidators.noWhitespaceValidator]),
        // 'organization': new FormControl({value:'', disabled: false},[Validators.required]),
        'enabled': new FormControl({value: true, disabled: false}),
        'creationDate': new FormControl({value:'', disabled: true})
      })
      let inlineEditObject = new ResponsiveListInlineEditObject(this.lastCreatedId, this.lastCreatedId, editFormGroup,false);
      this.inlineEditEventSubject.next(inlineEditObject);
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }


  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if (menuAction.actionName == "ENABLE_ACTION"){
        this.service.enableDisableActivity(menuAction.object.id, true).subscribe( response =>{
          let responseBody = response.body;
          let editFormGroup = new FormGroup({
            'id': new FormControl({value: responseBody?.id, disabled: true}),
            'name': new FormControl({value: responseBody?.name, disabled: false},[Validators.required, CustomValidators.noWhitespaceValidator]),
            // 'organization': new FormControl({value: responseBody?.organization, disabled: false},[Validators.required]),
            'enabled': new FormControl({value: responseBody?.enabled, disabled: false}),
            'creationDate': new FormControl({value: responseBody?.creationDate, disabled: true})
          })
          let inlineEditObject = new ResponsiveListInlineEditObject(menuAction.object.id, menuAction.object.id, editFormGroup,true);
          this.inlineEditEventSubject.next(inlineEditObject);
        }, error =>{
        });
      }
      else if (menuAction.actionName == "DISABLE_ACTION"){
        this.service.enableDisableActivity(menuAction.object.id, false).subscribe( response =>{
          let responseBody = response.body;
          let editFormGroup = new FormGroup({
            'id': new FormControl({value: responseBody?.id, disabled: true}),
            'name': new FormControl({value: responseBody?.name, disabled: false}, [Validators.required, CustomValidators.noWhitespaceValidator]),
            // 'organization': new FormControl({value: responseBody?.organization, disabled: false},[Validators.required]),
            'enabled': new FormControl({value: responseBody?.enabled, disabled: false}),
            'creationDate': new FormControl({value: responseBody?.creationDate, disabled: true})
          })
          let inlineEditObject = new ResponsiveListInlineEditObject(menuAction.object.id, menuAction.object.id, editFormGroup,true);
          this.inlineEditEventSubject.next(inlineEditObject);
        }, error =>{
          console.error(error.error.message);
        });
      } else{
        this.handleInlineEditingRequests(menuAction,this.snackBar,this._translateService);
      }
    }
  }

  isInlineEditable(): boolean {
    return true;
  }

  getTableFormGroupModel(): FormGroup<any> {
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'name': new FormControl({value: undefined, disabled: false}, [Validators.required, CustomValidators.noWhitespaceValidator]),
      // 'organization': new FormControl({value: undefined, disabled: false},[Validators.required]),
      'enabled': new FormControl({value: true, disabled: false}),
      'creationDate': new FormControl({value: undefined, disabled: true})
    });
  }

  triggerInlineEditEventSubject(inlineEditObject: ResponsiveListInlineEditObject): void {

    if(this.inlineEditEventSubject) this.inlineEditEventSubject.next(inlineEditObject);
  }

  getTableService() {
    return this.service;
  }

  getValidForm(menuAction:MenuActionEmittedObject): boolean {
    menuAction.form.markAllAsTouched();
    menuAction.form.updateValueAndValidity();
    return menuAction.form.valid;
  }



  withCheckUnicity(): boolean{
    return true;
  }

  objectClassName(): string{
    return "ActivityCategories";
  }
}
