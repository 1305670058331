import { SimpleChanges } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { LanguageHelper } from './lang.helper';
import { FormArray, FormControl } from '@angular/forms';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HttpRequestOptions } from '../models/http-request-options.model';
import { FieldType } from '../models/fields-config.model';
import { formatDate } from '@angular/common';

export class FrameworkHelper extends LanguageHelper {

    static clearFormConfig(formConfig: any) {
        const formConfigIsDefined = this.isDefined(formConfig);
        if (formConfigIsDefined) {
            Object.keys(formConfig).forEach(prop => {
                const obj = formConfig[prop];
                this.clearFormConfigObjectProperty(obj);
            });
        }
        return formConfig;
    }

    static clearFormConfigObjectProperty(obj: any) {
        const objIsDefined = this.isDefined(obj);
        if (objIsDefined) {
            if (obj instanceof FormControl) {
                // (obj as FormControl).reset();
            }
        }
    }

    static inputValueChanged(changes: SimpleChanges, inputName: string) {
        const isChangesDefined = this.isDefined(changes);
        const isInputNameDefined = this.isDefined(inputName);
        if (isChangesDefined && isInputNameDefined) {
            const inputChanges = changes[inputName];
            const isInputChangesDefined = this.isDefined(inputChanges);
            if (isInputChangesDefined) {
                return inputChanges.previousValue !== inputChanges.currentValue;
            }
        }
        return false;
    }

    static areEntitiesStrictlyDifferent(firstEntity: any, secondEntity: any) {
        return firstEntity !== secondEntity;
    }

    static areArraysDifferent(firstArray: any[], secondArray: any[]) {
        const areEligibileArrays = this.areDefined([firstArray, secondArray]) &&
            this.isArray(firstArray) &&
            this.isArray(secondArray);
        return areEligibileArrays ? firstArray.length !== secondArray.length : true;
    }

    static unsubscribe(subscription: Subscription) {
        if (this.isDefined(subscription)) {
            subscription.unsubscribe();
        }
    }

    static unsubscribeAll(subscriptions: Subscription[]) {
        if (this.isDefined(subscriptions)) {
            subscriptions.forEach(
                subscription => this.unsubscribe(subscription)
            );
        }
    }

    static getBody<BodyModel>(response: HttpResponse<BodyModel>): BodyModel {
        return this.getPropValueIfObjIsDefined(response, 'body');
    }

    static getHeaders(response: HttpResponse<any>): HttpHeaders {
        return this.getPropValueIfObjIsDefined(response, 'headers');
    }

    static getFromBody(response: HttpResponse<any>, prop: string) {
        return this.getPropValueIfObjIsDefined(this.getBody(response), prop);
    }

    static getFromHeader(response: HttpResponse<any>, prop: string) {
        return this.getHeaders(response).get(prop);
    }

    static getFromHeaderAndParse(response: HttpResponse<any>, dotSeperatedProps: string) {
        let value;
        const headers = this.getHeaders(response);
        if (this.isObjAndPropDefined(headers, dotSeperatedProps)) {
            const props = dotSeperatedProps.split('.');
            const header = this.parse(this.getFromHeader(response, props.shift()));
            value = props.reduce((prev, currProp) => {
                return this.isPropPartOfThisObj(prev, currProp) ? prev[currProp] : undefined;
            }, header);
        }
        return value;
    }

    static convertFromBoolToStringEquivalent(boolValue: boolean) {
        let result = '';
        const isBoolValueDefined = this.isDefined(boolValue);
        if (isBoolValueDefined) {
            result = boolValue ? 'true' : 'false';
        }
        return result;
    }

    static removeTimeFromDatesInObjectProperties(body: any) {
        if (this.isDefinedAndNotEmptyOrWhiteSpace(body)) {
            this.loopOverProps(body, (prop) => {
                if (this.dateParser(null, body[prop]) instanceof Date) {
                    body[prop] = this.removeTimeFromDate(body[prop]);
                }
            });
        }
    }

    static getParamFromRouteParents(route: ActivatedRouteSnapshot, paramName: string): any {
        let result = null;
        while (!this.isDefined(result = route.params[paramName]) && this.isDefined(route.parent)) {
            route = route.parent;
        }
        return result;
    }

    static getParamFromRouteChildren(route: ActivatedRouteSnapshot, paramName: string): any {
        let result = null;
        while (!this.isDefined(result = route.params[paramName]) && this.isDefined(route.firstChild)) {
            route = route.firstChild;
        }
        return result;
    }

    static exportToFile(data: any, fileName: string, fileType?: string) {
        let text = atob(data.body);
        let file = new Blob([text], { type: (fileType || 'text/csv') });
        var fileURL = URL.createObjectURL(file);
        //Uglier hack to download the file as CSV. Stupid JS.
        let a = document.createElement('a');
        a.href = fileURL;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    static openPdf(data: any, fileName: string) {
        let pdfWindow = window.open("", "_blank");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(data.body) + "'></iframe>");
        pdfWindow.document.title = fileName;
    }

    static addRecaptchaToHeader(options: HttpRequestOptions, actionName: string, token: string) {
        if (!options.headers)
            options.headers = new HttpHeaders();
        options.headers = options.headers.set('X-RECAPTCHA-ACTION', actionName);
        options.headers = options.headers.set('X-RECAPTCHA-TOKEN', token);
        return options;
    }

    static patchForm(form, data, formGroups?: { formGroup: Function, name: string }[]) {
        if (form && data) {
            
            Object.keys(form.controls).forEach(name => {
                if (form.controls[name] && data[name] != null) {

                    // console.log(data[name]);
                    // console.log(typeof (data[name]));
                    // console.log(data[name] instanceof Array);

                    // if (data[name] instanceof Array){

                    // }
                    if (form.controls[name] instanceof FormArray) {
                        if (data[name] && data[name].length > 0) {
                            for (let f = 0; f < data[name].length; f++) {
                                if (!form.controls[name]['controls'][f]) {
                                    let groupIndex = formGroups.findIndex(x => x.name === name);
                                    formGroups[groupIndex].formGroup(f);
                                }
                            }
                            data[name].forEach((element, ind) => {
                                Object.keys(form.controls[name]['controls'][ind].controls).forEach(listItem => {
                                    if (name.toLocaleLowerCase().includes('date')) {
                                        const ds = element[listItem].split('/');
                                        const switchedDate = `${ds[1]}/${ds[0]}/${ds[2]}`;
                                        form.controls[name]['controls'][ind].controls[listItem].patchValue(new Date(switchedDate));
                                    } else {
                                        const val = typeof (element[listItem]) === 'object' ? element[listItem]?.id : element[listItem];
                                        form.controls[name]['controls'][ind].controls[listItem].patchValue(val);
                                    }
                                })
                            });
                        }
                    }
                    else if (name.toLocaleLowerCase().includes('date')) {
                        const ds = data[name].split('/');
                        const switchedDate = `${ds[1]}/${ds[0]}/${ds[2]}`;
                        form.controls[name].patchValue(new Date(switchedDate));
                    } else {
                        let val = typeof (data[name]) === 'object' ? data[name]?.id : data[name];
                        if( typeof (data[name]) === 'object' && data[name] instanceof Array){
                            val=data[name][0].id;
                        }
                        form.controls[name].patchValue(val);
                    }
                }
            });
        }
    }

    static getFilterString(conditions, ...booleanVars) {
        let filters = [];
        if (conditions) {
            Object.keys(conditions).forEach((key) => {
                if (conditions[key].value != null && conditions[key].value != undefined && conditions[key].value != '') {
                    if (booleanVars.includes(key)) {
                        switch (conditions[key].value) {
                            case 1:
                                filters.push(`${key}:false`);
                                break;
                            case 2:
                                filters.push(`${key}:true`);
                                break;
                        }

                    }
                    //if string
                    else if (conditions[key].type == FieldType.string) {
                        const val = conditions[key]?.value?.trim();
                        if (val) {
                            filters.push(`${key}~*${val}*`);
                        }
                    }
                    // else if (conditions[key].type == FieldType.uniquestring) {
                    //     const val = conditions[key]?.value?.trim();
                    //     if (val) {
                    //         filters.push(`${key}:${val}`);
                    //     }
                    // }
                    //if date
                    else if (conditions[key].type == FieldType.date) {
                        filters.push(`${key}:${formatDate(conditions[key].value, 'd/M/yyyy', 'en')}`)
                    } else {
                        filters.push(`${key}:${conditions[key].value}`)
                    }
                }
            });
        }
        return filters && filters.length ? filters.join(',') : null;
    }

    static getFilter2String(conditions, ...booleanVars) {
        let filters = [];
        if (conditions) {
            Object.keys(conditions).forEach((key) => {
                if (conditions[key].value != null && conditions[key].value != undefined && conditions[key].value != '') {
                    if (booleanVars.includes(key)) {
                        switch (conditions[key].value) {
                            case 1:
                                filters.push(`${key}=false`);
                                break;
                            case 2:
                                filters.push(`${key}=true`);
                                break;
                        }

                    }
                    // //if string
                    // else if (conditions[key].type == ( FieldType.string || FieldType.uniquestring)) {
                    //     const val = conditions[key]?.value?.trim();
                    //     if (val) {
                    //         filters.push(`${key}=${conditions[key].value}`);
                    //     }
                    // }
                 
                    //if date
                    else if (conditions[key].type == FieldType.date) {
                        filters.push(`${key}=${formatDate(conditions[key].value, 'd/M/yyyy', 'en')}`)
                    } else {
                        filters.push(`${key}=${conditions[key].value}`)
                    }
                }
            });
        }
        return filters && filters.length ? filters.join(',') : null;
    }

    static getFilter2Object(conditions, ...booleanVars) {
        let filters = {};
        if (conditions) {
            Object.keys(conditions).forEach((key) => {
                if (conditions[key].value != null && conditions[key].value != undefined && conditions[key].value != '') {
                    if (booleanVars.includes(key)) {
                        switch (conditions[key].value) {
                            case 1:
                                filters[key] = false
                                break;
                            case 2:
                                filters[key] = true
                                break;
                        }

                    }
                 
                    //if date
                    else if (conditions[key].type == FieldType.date) {
                        filters[key] = formatDate(conditions[key].value, 'd/M/yyyy', 'en')
                    } else {
                        filters[key] = conditions[key].value
                    }
                }
            });
        }
        return filters ? filters : null;
    }
}
