import { IUserProfile, IUserRole } from "@siren-survey/app/models/session.interface";

export class UserProfile implements IUserProfile{
    id: Number;
    username: string;

    getId(): Number {
      return this.id;
    }
    getUsername(): string {
      return this.username;
    }
}
