import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchPermissionEnum, MarchModuleEnum } from 'src/app/shared/enum/user-role.enum';
import { Location } from '@angular/common';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { CustomReportFormInput } from '../report-list/report-list.component';
import { GenerateCustomReportObject } from 'src/app/models/custom-report/customReport';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IdNameObject } from 'src/app/models/shared/session.model';
import { CustomReportService } from 'src/app/services/custom-report/custom-report.service';
import { Router } from '@angular/router';
import { CustomReportGeneratedListComponent } from '../custom-report-generated-list/custom-report-generated-list.component';
import { Observable, Subscription } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FilterLookupService } from '../services/filter-lookup.service';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';

@Component({
  selector: 'app-custom-report-generate',
  templateUrl: './custom-report-generate.component.html',
  styleUrls: ['./custom-report-generate.component.sass']
})
export class CustomReportGenerateComponent  extends DropdownComparatorWithBrowserListener implements OnInit, OnDestroy {
  @Input() isEditMode: boolean = true;

  form: FormGroup;
  private _id: number;
  showProgramAttendance: boolean = false;
  generateCustomReportObject: GenerateCustomReportObject;
  canGetProgramList: boolean = false;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  public programs: LookupModel[] = [];
  public genders: LookupModel[] = [];
  public tags: LookupModel[] = [];
  public activities: LookupModel[] = [];
  public beneficiaryStatuses: LookupModel[] = [];
  public organizations: LookupModel[] = [];

  selectedElements: ProgramSelectionList[] = [];
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(CustomReportGeneratedListComponent) customReportGeneratedListComponent!: CustomReportGeneratedListComponent;

  programStartDate: string;
  programEndDate: string;

  programsRetrieved: boolean = false;

  public viewReport: boolean = false;
  private _title: string = "";

  public formSubmitted: boolean = false;

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public programCtrl = new FormControl('');
  public filteredPrograms: Observable<LookupModel[]>;
  public selectedPrograms: LookupModel[] = [];
  private _subscription = new Subscription();
  @ViewChild('programInput') programInput: ElementRef<HTMLInputElement>;

  constructor(private _customReportService: CustomReportService,
              public dialogRef: MatDialogRef<CustomReportFormInput>,
              private _snackbar: MatSnackBar,
              private _router: Router,
              public readonly _translateService: TranslateService,
              private _filterLookupService: FilterLookupService,
              private _lookupService: LookupService,
              private _location: Location,
              public authService: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public data: CustomReportFormInput){
      super();
      this._id = data.reportId;
      this.showProgramAttendance = data.includeProgramAttendance;
      this.tags = data.tags;
      this.genders = data.genders;
      this._title = data?.title;
      this.viewReport = data.viewReport;
      this.canGetProgramList = this.authService.isModuleAccessible(this.marchModuleEnum.REPORTS_MGT.module);
      this.organizations = this.authService.getSessionService().getActiveSession().getActiveOrganizations();

  }

  ngOnInit(): void {
    this.createFormGroups();
    this._subscription.add(
      this._lookupService.getLookupObjectsService(["Program","Activity","BeneficiaryStatus"]).subscribe(result=>{
        this.programs = result.body['Program'];
        this.activities = result.body['Activity'];
        this.beneficiaryStatuses = result.body['BeneficiaryStatus'];
    })
    )
    this._subscription.add(
      this._lookupService.getLookupsService(["Gender","Tag"]).subscribe(result=>{
        this.tags = result.body['Tag'];
        this.genders = result.body['Gender'];
        this.form.controls['genderId'].enable();
        this.form.controls['tagsIds'].enable();
      })
    )
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  createFormGroups(){
    this.form = new FormGroup({
      'id': new FormControl({value: this.generateCustomReportObject?.id, disabled: true}),
      'customReportId':  new FormControl({value: this.generateCustomReportObject?.customReportId, disabled: true}, Validators.required),
      'programId': new FormControl({value: this.generateCustomReportObject?.programId, disabled: false}),
      'programIds': new FormControl({value: this.generateCustomReportObject?.programIds, disabled: !this.isEditMode}),
      'includeProgramAttendance': new FormControl({value: this.generateCustomReportObject?.includeProgramAttendance, disabled: !this.isEditMode}),
      'programStartDate': new FormControl({value: this.generateCustomReportObject?.programStartDate ? new Date(this.generateCustomReportObject?.programStartDate) : undefined, disabled: !this.isEditMode}),
      'programEndDate': new FormControl({value: this.generateCustomReportObject?.programEndDate ? new Date(this.generateCustomReportObject?.programEndDate) : undefined, disabled: !this.isEditMode}),
      'fromDate': new FormControl({value: this.generateCustomReportObject?.fromDate, disabled: false}),
      'toDate': new FormControl({value: this.generateCustomReportObject?.toDate , disabled: false}),
      'organizationId': new FormControl({ value: this.generateCustomReportObject?.organizationId, disabled: false }),
      'genderId': new FormControl({ value: this.generateCustomReportObject?.organizationId, disabled: this.genders.length == 0 }),
      'beneficiaryStatusId': new FormControl({ value: this.generateCustomReportObject?.beneficiaryStatusId, disabled: false }),
      'tagsIds': new FormControl({ value: this.generateCustomReportObject?.tagsIds, disabled: this.tags.length == 0 }),
      'tagOperator': new FormControl({ value: this.generateCustomReportObject?.tagOperator ?? 'OR', disabled: false }),
      'activitiesIds': new FormControl({value: this.generateCustomReportObject?.activitiesIds, disabled: false}),});
  }
  validateFormForSave() : string{
    let error : string = undefined;
    this.formSubmitted = true;
    if(this.form){
      this.formSubmitted = false;
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      error = !this.form.valid ? this._translateService.instant('report.error.form-not-valid') : undefined;
    }
    return error;
  }

  generateReport(){
    this.form.get('customReportId').setValue(this._id);
    let error = this.validateFormForSave();
    if(!error){
      this.executeSave();
    } else{
      this._snackbar.open(error, null, { duration: 3000 });
    }
  }

  executeSave(){
    let rawValue = this.form.getRawValue();
    let startDate;
    if(rawValue.programStartDate){
      let startDateLength = rawValue.programStartDate.length;
      if(startDateLength == undefined){
        startDate = rawValue.programStartDate && !isNaN(rawValue.programStartDate) ? rawValue.programStartDate.toISOString().slice(0, 10) : undefined;
      } else{
        startDate = rawValue.programStartDate;
      }
    }

    let endDate;
    if(rawValue.programEndDate){
      let endDateLength = rawValue.programEndDate.length;
      if(endDateLength == undefined){
        endDate = endDateLength == undefined && rawValue.programEndDate && !isNaN(rawValue.programEndDate) ? rawValue.programEndDate.toISOString().slice(0, 10) : undefined;
      } else{
        endDate = rawValue.programEndDate;
      }
    }
    let param = 'includeProgramAttendance='+ (rawValue.includeProgramAttendance ? rawValue.includeProgramAttendance: false);

    param += this.mapAndConcat('programIds', rawValue.programIds);
    param += this.mapAndConcat('activitiesIds', rawValue.activitiesIds);

    if(rawValue.organizationId){
      param += '&organizationId='+rawValue.organizationId;
    }

    if(rawValue.beneficiaryStatusId){
      param += '&beneficiaryStatusId='+rawValue.beneficiaryStatusId;
    }

    if(rawValue.fromDate && rawValue.toDate){
      param += '&fromDate='+this.formatDateOnly(rawValue.fromDate);
      param += '&toDate='+this.formatDateOnly(rawValue.toDate);
    }

    param += this.mapAndConcat('tagsIds', rawValue.tagsIds);
    if(rawValue.tagOperator){
      param += '&tagOperator='+rawValue.tagOperator;
    }

    if(rawValue.genderId){
       param += '&genderId='+rawValue.genderId;
    }

    if(startDate) param += '&programStartDate='+startDate;
    if(endDate) param += '&programEndDate='+endDate;

    this._customReportService.generateReport(this._id,param).subscribe( response =>{
      this.dialogRef?.close();
    }, error =>{
        let message = this._translateService.instant('report.error.unable-to-generate-report');
        this._snackbar.open(message, null, { duration: 3000 });
    });
  }

  formatDateOnly(date: Date): any{
    if(date){
      date = new Date(date);
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let year = date.getFullYear();
      return year + "-" + month + "-" + day;
    } else undefined;
  }

  mapAndConcat(field: string, value: any): string {
    if (value) {
      const mappedValue = value.map(item => item.id).join(',');
      return `&${field}=${mappedValue}`;
    }
    return '';
  }

  goBack(){
    this._location.back();
  }

  close(){
    this.dialogRef.close();
  }

  handleSelection(event: MatCheckboxChange, program: ProgramSelectionList){
    if(event.checked && program){
      this.selectedElements.push(program);
    } else{
      let index = this.getElementIndexInSelectionList(program);
      if(index != undefined && index >= 0) this.selectedElements.splice(index, 1);
    }
  }

  getElementIndexInSelectionList(program: ProgramSelectionList){
    let index : number;
    if(program && this.selectedElements){
      let countIndex = 0;
      for(let element of this.selectedElements){
        if(index) break;
        if(element.id == program.id) index = countIndex;
        countIndex++;
      }
    }
    return index;
  }

  isSelected(program: ProgramSelectionList){
    let isSelected = false;
    if(program){
      let index = this.getElementIndexInSelectionList(program);
      isSelected = index != undefined && index >= 0;
    }
    return isSelected;
  }

  handleSelectionAll(event: MatCheckboxChange){
    if(event.checked){
      this.selectedElements = [];
      this.programs.forEach(program => {
        let programIdVal : ProgramSelectionList= {
          id: program.id,
          program: {
            id: program.id,
            name: program.value
          }
        }
        this.selectedElements.push(programIdVal);
      });
    } else{
      this.selectedElements = [];
    }
  }

  includeProgramAtttendance(event: any){
    event.checked ? this.form.get('includeProgramAttendance').setValue(true) : this.form.get('includeProgramAttendance').setValue(false);
  }

  buildReport(){
    this.form.controls['customReportId'].setValue(this._id);
    let error = this.validateFormForSave();
    if(!error){
      let rawValue = this.form.getRawValue();
      let startDate;
      if(rawValue.programStartDate){
        let startDateLength = rawValue.programStartDate.length;
        if(startDateLength == undefined){
          startDate = rawValue.programStartDate && !isNaN(rawValue.programStartDate) ? rawValue.programStartDate.toISOString().slice(0, 10) : undefined;
        } else{
          startDate = rawValue.programStartDate;
        }
      }

      let endDate;
      if(rawValue.programEndDate){
        let endDateLength = rawValue.programEndDate.length;
        if(endDateLength == undefined){
          endDate = endDateLength == undefined && rawValue.programEndDate && !isNaN(rawValue.programEndDate) ? rawValue.programEndDate.toISOString().slice(0, 10) : undefined;
        } else{
          endDate = rawValue.programEndDate;
        }
      }

      const valuesToSet = {
        includeProgramAttendance: rawValue.includeProgramAttendance ? rawValue.includeProgramAttendance: false,
        programIds: rawValue.programIds?.map(program => program.id).join(','),
        activitiesIds: rawValue.activitiesIds?.map(activity => activity.id).join(','),
        tagsIds: rawValue.tagsIds?.map(tag => tag.id).join(','),
        tagOperator: rawValue.tagOperator,
        genderId: rawValue.genderId,
        organizationId: rawValue.organizationId,
        beneficiaryStatusId: rawValue.beneficiaryStatusId,
        fromDate:this.formatDateOnly(rawValue.fromDate),
        toDate:this.formatDateOnly(rawValue.toDate),
        programStartDate: startDate,
        programEndDate: endDate
      };
      this._filterLookupService.removeAllObjects();
      this.setValuesInLookupService(valuesToSet);

      if (!rawValue.programIds || rawValue.programIds.length === 0) {
        rawValue.programIds = undefined;
      } else {
        rawValue.programIds = rawValue.programIds.map(program => program.id).join(',');
      }

      this._router.navigateByUrl(`report/report-list/view-report/${this._id}/${rawValue.programIds}/${this._title}`);
      this.dialogRef?.close();
    } else{
      this._snackbar.open(error, null, { duration: 3000 });
    }
  }

  setValuesInLookupService(values: { [key: string]: any }) {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        this._filterLookupService.setObject(key, values[key]);
      }
    }
  }
}

export interface ProgramSelectionList{
  id: number;
  program: IdNameObject;
}
