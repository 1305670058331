import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProgramActivityService } from 'src/app/services/admin/program/activity-execution.service';
import { ActivityExecutionFormComponent } from '../activity-execution-form/activity-execution-form.component';
import { MatDialog } from '@angular/material/dialog';
import { ProgramActivityExecutionDisplay } from 'src/app/models/admin/program/activities/activity-execution';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { ProgramOrganization } from 'src/app/models/admin/program/program/program';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TagLookupModel } from 'src/app/models/shared/tag.model';

@Component({
  selector: 'app-activity-execution-list',
  templateUrl: './activity-execution-list.component.html',
  styleUrls: ['./activity-execution-list.component.sass']
})
export class ActivityExecutionListComponent implements OnInit {
  @Input() isEditMode: boolean = true;
  @Input() programActivityId: number;
  @Input() programId: number;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() programOrganization: ProgramOrganization[] = [];
  @Input() activityOccurrence: number = 0;
  @Input() tags: TagLookupModel[] = [];

  activityExecutionDisplay: ProgramActivityExecutionDisplay[];
  public attendeesNumbers: number = 0;
  @Output() activityAttendees = new EventEmitter<ProgramActivityExecutionDisplay[]>();

  constructor(private _programActivityService: ProgramActivityService,
             private _matDialog: MatDialog,
             private _translateService: TranslateService,
             private _dialogService: DialogService,
             private _snackbar: MatSnackBar){
  }

  ngOnInit(): void {
    this.getActivityExecutionList();
  }

  getActivityExecutionList(){
    if(this.programActivityId != undefined && this.programActivityId > 0){
      this._programActivityService.getActivityExecutionDisplayList(this.programActivityId).subscribe(response => {
        if(response && response.body){
          this.activityExecutionDisplay = response?.body?.executions ? response?.body?.executions : [];
          this.attendeesNumbers = this.activityExecutionDisplay.flatMap(activityExecution =>
              Array.isArray(activityExecution.attendees) ?
                  activityExecution.attendees.reduce((total, attendee) =>
                      total + (attendee.attendee.split(',').length || 0), 0
                  ) : []
          ).reduce((total, count) => total + count, 0);
          this.activityAttendees.emit(this.activityExecutionDisplay);
        }
      });
    }
  }

  editActivityExecution(executionId: number){
    this.openActivityExecutionWindow(executionId);
  }

  deleteActivityExecution(executionId: number){
    this._dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-activity-execution'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
          .subscribe(response=>{
            if(response){
              this._programActivityService.deleteActivityExecution(executionId).subscribe( response =>{
                this.getActivityExecutionList();
              }, error =>{
                let message = error.error.message
                this._snackbar.open(message, null, { duration: 3000 });
              });
            }
          });
  }

  canDeleteExecution(execution: ProgramActivityExecutionDisplay){
    return this.editable() && execution != undefined && execution?.deletable != undefined && execution?.deletable;
  }

  addActivityExecution(){
    this.openActivityExecutionWindow(null);
  }

  openActivityExecutionWindow(executionId: number){
    if(this.programActivityId != undefined && this.programActivityId > 0){
      const dialogRef = this.openActivityExecutionFormWindow(executionId);
      dialogRef.afterClosed().subscribe(() => {
        this.getActivityExecutionList();
      });
    }
  }

  openActivityExecutionFormWindow(executionId: number): any{
    let input: ActivityExecutionFormInput = new ActivityExecutionFormInput(this.programId,
                                                    this.programActivityId,
                                                    executionId,
                                                    this.editable(),
                                                    this.minDate,
                                                    this.maxDate,
                                                    this.programOrganization,
                                                    this.tags);
    const activityExecutionFormDialog = this._matDialog.open(ActivityExecutionFormComponent, {
      "width": '6000px',
      "maxWidth": '80vw',
      "maxHeight": '80vh',
      "autoFocus": false,
      data: input,
      direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });
    return activityExecutionFormDialog;
  }

  editable() : boolean{
    return this.isEditMode;
  }

  canAddNewExecution(): boolean{
    return  this.activityOccurrence != undefined && this.activityExecutionDisplay != undefined
            && this.activityOccurrence > this.activityExecutionDisplay.length && this.editable();
  }
}

export class ActivityExecutionFormInput{

  constructor(
    public programId: number,
    public programActivityId: number,
    public executionId: number,
    public isEditMode: boolean,
    public minDate: Date,
    public maxDate: Date,
    public programOrganizations: ProgramOrganization[] = [],
    public tags: TagLookupModel[] = []
    ) {
  }

 }
