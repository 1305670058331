<div class="fullwidth locateForm">
  <!-- <app-responsive-list-view
       [tableColumns]="_tableColumns"
       [sortingDataAccessor]="_sortingDataAccessor"
       [filteringPredicate]="_filteringPredicate"
       [service]="service"
       [withAdd]="true"
       [maxCellStringLength]="80"
       [maxStringLength]="20"
       [inputFilterEventSubject]="filterEventSubject.asObservable()"
       (mode)="updateViewMode($event)"
       [_tableMenuAction]="_tableMenuAction"
       [booleanIconColumnDisplayList]="_booleanIconColumnDisplayList"
       (menuActionEventEmitter)="tableMenuActionHandler($event)"
       addButtonLabel="New Type"
       tableTitleLabel="Survey Type List"
       [inputReloadEventSubject]="reloadEventSubject.asObservable()"
       [showFilterButton]="true">
       <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
       <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
  </app-responsive-list-view> -->
</div>
