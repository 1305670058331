import { IdNameObject } from "src/app/models/shared/session.model";
import { Organization } from "../../organization/organization";
import { Activity } from "../activities/activity";
import { Class } from "../class/class";
import { Beneficiary, BeneficiaryTag } from "src/app/models/beneficiary/beneficiary";
import { Tag } from "src/app/models/shared/tag.model";

export interface Program {
  id: number;
  startDate: Date;
  endDate: Date;
  creationDate: Date;
  name: string;
  goal: string;
  isDeleted: boolean;
  programActivities: ProgramActivity[];
  programOrganizations: ProgramOrganization[];
  programTags: ProgramTag[];

}

export interface ProgramOrganization{
  id: number;
  organization : Organization;
  programClasses: ProgramClass[];
  programBeneficiaries: ProgramBeneficiary[];
}

export interface ProgramBeneficiary{
  id: number;
  beneficiary: Beneficiary;
  comment: string;
  enrollmentDate: Date,
  exitDate: Date;
  deletable: boolean
}

export interface ProgramClass{
  id: number;
  classes: Class;
  deletable: boolean;
}

export interface ProgramActivity{
  id: number;
  activity: Activity;
  occurrence: number;
  toDate: Date;
  fromDate: Date;
  deletable: boolean;
}

export class ProgramTag{
  id: number;
  tag: Tag;
}

export interface ProgramBeneficiarySelectionList{
  id: number;
  beneficiary: IdNameObject;
}

export interface ReferencedBeneficiarySelection{
  id: number;
  programBeneficiary: ProgramBeneficiarySelectionList;
}

export interface ProgramClassAttendee{
  id: number;
  programBeneficiary: ProgramBeneficiarySelectionList;
}

export class ClassAttendeeCreateDto{
  classAttendees: ProgramClassAttendee[] = [];
}
