<div class="fullwidth locateForm">
  <form [formGroup]="form">
    <div class="row" style="padding-top: 10px">
      <div class="col-12 march-page-title">
        <p>{{'user-activity.title' | translate}}</p>
      </div>
      <p>{{ 'user-activity.select-user' | translate }}</p>

        <mat-form-field class="full-width-element">
          <mat-label class="" >{{ 'user-activity.username' | translate }}</mat-label>
          <mat-select class="" formControlName="loouserkup" (selectionChange)="lookupChanged($event)" >
            <mat-option *ngFor="let user of userList" [value]="user">
                {{ user.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
  </form>
  <div *ngIf="selectedUserId != undefined && selectedUserId > 0 && activitiesList != undefined && activitiesList.length > 0">
    <p>
      <span class="grey-label">{{ 'user-activity.expand-row' | translate }}</span>
    </p>

    <div class="table-header">
      <div class="full-width-element row">
        <div class="col-lg-3 col-sm-6 col-12">
          <span>{{ 'user-activity.uuid' | translate }}</span>
        </div>

        <div class="col-lg-2 col-sm-6 col-12">
          <span>{{ 'user-activity.date' | translate }}</span>
        </div>

        <div class="col-lg-1 col-sm-6 col-12">
          <span>{{ 'user-activity.method' | translate }}</span>
        </div>

        <div class="col-lg-5 col-sm-6 col-12">
          <span>{{ 'user-activity.url' | translate }}</span>
        </div>

        <div class="col-lg-1 col-sm-6 col-12">
          <span>{{ 'user-activity.http-code' | translate }}</span>
        </div>

      </div>
    </div>
    <ng-container *ngFor="let activity of activitiesList">
      <mat-accordion class="row table-record full-width-element">
        <mat-expansion-panel (opened)="headerOpenState = true" (closed)="headerOpenState = false" style="border: none; box-shadow: none; padding: 0px;">
          <mat-expansion-panel-header style="height: auto !important; padding: 20px;">
            <div class="full-width-element row-header">
              <div class="col-lg-3 col-sm-6 col-12">
                <span>{{ activity.uuid }}</span>
              </div>

              <div class="col-lg-2 col-sm-6 col-12">
                <span>{{ activity.requestCreationDate ? (activity.requestCreationDate | date: 'dd / MM / yyyy hh:mm:ss') : "N/A" }} </span>
              </div>

              <div class="col-lg-1 col-sm-6 col-12">
                <span>{{ activity.requestMethod }}</span>
              </div>

              <div class="col-lg-5 col-sm-6 col-12">
                <span>{{ activity.requestPath }}</span>
              </div>

              <div class="col-lg-1 col-sm-6 col-12">
                {{ activity.responseStatusCode }}
              </div>
            </div>
          </mat-expansion-panel-header>
          <mat-expansion-panel-body>
            <div class="full-width-element" *ngIf="activity.responsesBody && activity.responsesBody.length > 0">
              {{ activity.responsesBody }}
            </div>
            <div class="full-width-element" *ngIf="activity.responsesBody == undefined || activity.responsesBody.length == 0">
              <span><b>{{ 'user-activity.no-body' | translate }}</b></span>
            </div>
          </mat-expansion-panel-body>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <div class="page-footer">
      <mat-form-field style="width: 100px; margin-inline-end: 10px;">
        <mat-label class="" >{{ 'global.page-size' | translate }}</mat-label>
        <mat-select class="" [(value)]="pageSize" (selectionChange)="changePageSize($event)">
          <mat-option [value]="5">5</mat-option>
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="20">20</mat-option>
          <mat-option [value]="50">50</mat-option>
          <mat-option [value]="100">100</mat-option>
          <mat-option [value]="150">150</mat-option>
          <mat-option [value]="200">200</mat-option>
        </mat-select>
      </mat-form-field>
      <button *ngIf="currentPage > 0" type="button" (click)="getPreviousPage()" class="btn btn-previous-page">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </button>
      <span>{{ 'global.page' | translate }} {{ currentPage + 1 }} {{ 'global.out-of' | translate }} {{ maxPage }}</span>
      <button *ngIf="currentPage < maxPage -1" type="button" (click)="getNextPage()" class="btn btn-next-page">
        <i class="fa fa-arrow-right" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div *ngIf="selectedUserId != undefined && selectedUserId > 0 && activitiesList != undefined && activitiesList.length == 0">
    <span><b>{{ 'user-activity.user-no-activity' | translate }}</b></span>
  </div>
</div>
