/**
 * Node for to-do item
 */
export class ItemNode {
  children: ItemNode[];
  item: string;
  key: string;
}

/** Flat to-do item node with expandable and level information */
export class ItemFlatNode {
  item: string;
  key: string;
  level: number;
  expandable: boolean;
}
