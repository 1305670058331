<div class="fullwidth locateForm">
  <app-responsive-list-view
       [tableColumns]="tableColumns"
       [sortingDataAccessor]="sortingDataAccessor"
       [filteringPredicate]="filteringPredicate"
       [service]="service"
       [withAdd]="false"
       [hasListAccess]="hasListAccess"
       [maxCellStringLength]="80"
       [maxStringLength]="20"
       [inputFilterEventSubject]="filterEventSubject.asObservable()"
       [_tableMenuAction]="tableMenuAction"
       [booleanIconColumnDisplayList]="booleanIconColumnDisplayList"
       (menuActionEventEmitter)="tableMenuActionHandler($event)"
       tableTitleLabel="{{ 'activities.instances' | translate}}"
       [inputReloadEventSubject]="reloadEventSubject.asObservable()"
       [showFilterButton]="true"
       [showBackButton]="false"
       [initiateSearchFromFilter]="initiateSearchFromFilter"
       initialPageSize="10">
    <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter>
    <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter>
  </app-responsive-list-view>
</div>
