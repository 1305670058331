import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItemModel } from '../../models/menu-item.model';
import { ToolbarDrawerService } from '../../services/toolbar-drawer.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass']
})
export class ToolbarComponent implements OnInit {
  @Input() menuItems: MenuItemModel[] = [];
  @Input() activeAction: string = '';

  constructor(private router: Router, private toolbarDrawerService: ToolbarDrawerService) { }

  ngOnInit(): void {
  }

  onValChange(action) {
    let item = this.menuItems.find(f => f.value == action);
    if (item) {
      if (item.children && item.children.length > 0) {
        this.toolbarDrawerService.setContent(item);
      }
      else if (item.link) {
        this.router.navigate([item.link]);
        this.toolbarDrawerService.onClose();
      }
    }
  }

  public getImageSrc(toggle, item) {
    if (this.router.url.includes(item.link)) {
      toggle.checked = true;
    }
    return toggle.checked ? `${item.icon}-blue.svg` : `${item.icon}-grey.svg`;
  }
}
