import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FrameworkHelper } from '../../helpers/framework.helper';
import { DropdownOptionModel } from '../../models/dropdown-option.model';
import { FieldConfigModel, FieldType } from '../../models/fields-config.model';
import { CustomFormActionModel, CustomFormModel } from './custom-form.model';

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.sass'],
})
export class CustomFormComponent implements OnChanges {
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @Input() data: CustomFormModel;
  @Input() listenToChanges: boolean = false;
  @Input() submitAction: CustomFormActionModel = new CustomFormActionModel('siren-admin-translation.admin_module.saveAndContinue');
  @Input() reverseActions: boolean = false;
  form: FormGroup;
  fieldTypeEnum = FieldType;
  frameworkHelper = FrameworkHelper;
  @Output() actionClicked = new EventEmitter<any>();
  @Output() fieldChange = new EventEmitter<any>();
  @Output() childCreation = new EventEmitter<any>();
  @Output() childDeletion = new EventEmitter<any>();
  private filterLookups: any = {};
  private destroy$ = new Subject();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.form) {
      this.initForm();
      this.handleFieldsVisibility();
    }
    const dataChange = changes['data'];
    if (dataChange && this.data && this.data.data) {
      this.patchForm();
    }
  }

  /**
 * Creates a form group an initiaze the form arrays
 * @param aFormFields : Field Config Model
 * @param isMainPerson : boolean if is main person
 * @param createArray : arrays to be created
 * @returns a form group
 */
  initForm() {
    this.form = new FormGroup({});
    this.data.fields.forEach(control => {
      if (control.prop) {
        if (control.type === FieldType.array) {
          let defaultValue = [];
          if (control.isRequired) {
            defaultValue = [this.createArray(control, 0)];
          }
          let newFormControl = new FormArray(defaultValue);
          if (control.isDisabled) {
            newFormControl.disable();
          }
          this.form.addControl(control.prop, newFormControl);
          this.emitChildFieldChanges(this.form, control.prop, 0);
        }
        else if (control.type !== FieldType.space) {
          this.initFieldControl(this.form, control);
          if (this.listenToChanges) {
            this.form.get(control.prop).valueChanges.subscribe(s => {
              this.fieldChange.emit({ form: this.form, field: control.prop, value: s });
            })
          }
        }
      }
    });
    this.configureLookupsConditionality();
  }

  private emitChildFieldChanges(form, controlName, index) {
    if (this.listenToChanges) {
      this.childCreation.emit({ array: this.form.get(controlName), index: index, name: controlName });
      Object.keys(form.get(controlName)['controls'][index].controls).forEach((c) => {
        form.get(controlName)['controls'][index].get(c).valueChanges.subscribe(s => {
          this.fieldChange.emit({ form: this.form, field: controlName, value: s, childField: c, index: index });
          // handle is hidden
          // handle nestedlookups
          let lookupsWithCondition = this.data.fields.find(f => f.prop === controlName).children.filter(f => f.type === FieldType.dropdown && f.relatedField == c);

          if (lookupsWithCondition?.length) {
            lookupsWithCondition.forEach(childElement => {
              this.filterLookups[`${childElement.lookupKey}.${index}`] = this.data.lookups[childElement.lookupKey].filter((m) => m[childElement.dependancyFieldName]?.id === s || m[childElement.dependancyFieldName] === s);
            });
          }

        })
      });
    }
  }

  private createArray(field: FieldConfigModel, index?: number) {
    let fg = new FormGroup({});
    field.children.forEach(control => {
      if (control.type !== FieldType.space) {
        this.initFieldControl(fg, control);
      }
    });
    return fg;
  }

  private initFieldControl(form: FormGroup, control: FieldConfigModel) {
    let validators = control.validators;
    if (control.isRequired) {
      if (!validators) { validators = [] }
      validators.push(Validators.required);
    }
    let newFormControl = new FormControl({ value: control.defaultValue, disabled: control.isDisabled }, validators);
    if (control.isDisabled) {
      newFormControl.disable();
      control.withClearButton = false;
    }
    form.addControl(control.prop, newFormControl);
  }

  private configureLookupsConditionality() {
    const lookupsWithCondition = this.data.fields.filter(f => f.type == FieldType.dropdown && f.relatedField);
    if (lookupsWithCondition && lookupsWithCondition.length) {
      lookupsWithCondition.forEach(l => {
        this.filterLookups[l.lookupKey] = this.getLookups(l);

        // l => field should be filtered with condition
        //{ prop: 'town', type: FieldType.dropdown, lookupKey: LookupsEnum.Town, relatedField: 'kadaa', dependancyFieldName: 'kadaa' },

        this.form.get(l.relatedField).valueChanges.subscribe(s => {

          const relatedFieldLookup = this.data.fields.find(f => f.prop === l.relatedField);

          if (this.data.lookups[relatedFieldLookup.lookupKey] && this.data.lookups[l.lookupKey]) {
            if (s) {

              this.filterLookups[l.lookupKey] = this.data.lookups[l.lookupKey].filter((m) => m[l.dependancyFieldName]?.id === s);

              const parentRelatedField = this.data.fields.find(t => t.prop === l.relatedField);
              if (parentRelatedField && parentRelatedField.relatedField) {
                let fieldChosen = this.getLookups(parentRelatedField).find(f => f.id === s) as any;
                if (!fieldChosen) {
                  const isSelectedInFullList = this.data.lookups[parentRelatedField.lookupKey].find(k => k.id === s) as any
                  if (isSelectedInFullList) {
                    this.filterLookups[parentRelatedField.lookupKey] = this.data.lookups[parentRelatedField.lookupKey];
                    fieldChosen = isSelectedInFullList;
                  }
                }
                if (fieldChosen[parentRelatedField.dependancyFieldName].id !== this.form.get(parentRelatedField.relatedField).value) {
                  this.form.get(parentRelatedField.relatedField).setValue(fieldChosen[parentRelatedField.dependancyFieldName]?.id);
                }
              }

            } else {
              this.filterLookups[l.lookupKey] = this.data.lookups[l.lookupKey];
            }

            if (this.form.get(l.prop).value) {
              const ext = this.filterLookups[l.lookupKey]?.findIndex((c) => c.id === this.form.get(l.prop).value);
              if (ext === -1) {
                this.form.get(l.prop).setValue(null);
              }
            }
          }

        });

        const dependantField = this.data.fields.find(d => d.relatedField === l.prop);
        if (!dependantField) {
          this.form.get(l.prop).valueChanges.subscribe(x => {
            if (this.getLookups(l)) {
              if (x) {
                const selectedValue = this.getLookups(l).find(f => f.id === x) as any;
                if (selectedValue && selectedValue[l.dependancyFieldName].id !== this.form.get(l.relatedField).value) {
                  this.form.get(l.relatedField).setValue(selectedValue[l.dependancyFieldName]?.id);
                }
              }

            }
          })
        }
      })
    }
  }

  public patchForm() {
    const formArrays = this.getFormArrays();
    this.frameworkHelper.patchForm(this.form, this.data.data, formArrays);

    this.addOldLookup();
    if (this.data.isDisabled) {
      this.disableForm();
    }
  }

  private disableForm() {
    this.form.disable();
    this.data.fields.forEach(control => {
      if (control.type === FieldType.dropdown) {
        control.withClearButton = false
      }
    });
  }

  private getFormArrays() {
    return this.data.fields.filter(f => f.type === FieldType.array).map(m => {
      return {
        name: m.prop,
        formGroup: (i) => this.addRecord(m)
      }
    });
  }

  private addOldLookup() {
    this.data.fields.filter(f => f.type === FieldType.dropdown)?.forEach(el => {

      if (this.data.data[el.prop] && this.data.lookups[el.lookupKey] &&
        !this.data.lookups[el.lookupKey].find(x => x.id === this.data.data[el.prop].id)) {

        ////solves null issue when dialog is closed
        if (el.relatedField) {
          let pushItem = { ...this.data.data[el.prop], [el.relatedField]: this.data.data[el.relatedField] }
          this.data.lookups[el.lookupKey].push(pushItem);
        } else {
          this.data.lookups[el.lookupKey].push(this.data.data[el.prop]);
        }
        ////

        if (this.filterLookups[el.lookupKey]) {
          this.filterLookups[el.lookupKey].push(this.data.data[el.prop]);
        }
      }
    });
  }

  public buttonClicked(add) {
    let formVal;

    if (add) {
      //Invalid form
      if (this.form.invalid || this.submitAction.disabled) {

        //Display error messages
        (<any>Object).values(this.form.controls).forEach(control => {
          control.markAsTouched();
        });

        //exit function
        return;
      }
      formVal = Object.assign(this.data.data || {}, this.form.getRawValue());
      this.actionClicked.emit({ formVal: formVal, apply: true });
    } else {
      this.actionClicked.emit({ formVal: formVal, apply: false });
    }
  }

  public getLookups(field, index?): DropdownOptionModel[] {
    return this.filterLookups[`${field.lookupKey}.${index}`] ?
      this.filterLookups[`${field.lookupKey}.${index}`] :
      this.filterLookups[field.lookupKey] ?
        this.filterLookups[field.lookupKey] :
        this.data.lookups ?
          this.data.lookups[field.lookupKey] :
          null;
  }

  public resetForm() {
    this.formGroupDirective.resetForm();
    this.form.reset();
  }

  public getMinDate(field) {
    if (field.minDate instanceof Date) {
      return field.minDate;
    }
    else if (!field.minDate) {
      return null;
    }
    else {
      return this.form.controls[field.minDate].value;
    }
  }

  public getMaxDate(field) {
    if (field.maxDate instanceof Date) {
      return field.maxDate;
    }
    else if (!field.maxDate) {
      return null;
    }
    else {
      return this.form.controls[field.maxDate].value;
    }
  }

  handleFieldsVisibility(): void {

    const groupedFields: any = {};
    const flatFields: any = {};

    this.data.fields.forEach((field) => {

      if (!field.prop) {
        return;
      }

      flatFields[field.prop] = field;


      //if (field.type !== FieldType.array) {

      //
      if (!field.visibleIfField) {
        return;
      }

      // only fields with visibleIfField
      if (!groupedFields[field.visibleIfField]) {
        groupedFields[field.visibleIfField] = [field];
      }
      else {
        groupedFields[field.visibleIfField].push(field);
      }

      //}

    });

    Object.keys(groupedFields).forEach((parentField) => {

      const theParentField = this.form.get(parentField);

      setTimeout(() => {
        this.showHideRelatedBasedOnType(flatFields, parentField, groupedFields[parentField], theParentField.value);
      });

      theParentField
        .valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((val) => {

          this.showHideRelatedBasedOnType(flatFields, parentField, groupedFields[parentField], val);
        });
    });
  }

  showHideRelatedBasedOnType(flatFields, parentField, childrenFields, selectedVal): void {

    switch (flatFields[parentField]?.type) {

      case FieldType.dropdown:

        const valOption = flatFields[parentField].relatedTypeProperties.listOptions?.find((o) => o.id === selectedVal);

        Object.keys(childrenFields).forEach((index) => {

          childrenFields[index].isHidden = childrenFields[index].visibleIfFieldValue !== valOption?.type;
          this.updateValidators(childrenFields[index].prop);
        });
        break;

      case FieldType.checkbox:

        Object.keys(childrenFields).forEach((index) => {

          childrenFields[index].isHidden = childrenFields[index].visibleIfFieldValue !== selectedVal;
          this.updateValidators(childrenFields[index].prop);
        });
        break;
    }
  }

  updateValidators(f: string) {
    if (f) {
      const config = this.data.fields.find(y => y.prop == f);
      if (config != null) {
        if (config.type === FieldType.array) {
          if (config.isHidden)
            this.form.get(f).disable();
          else this.form.get(f).enable();
        }
        else {
          if (!config.isHidden) {
            if (config.validators == null) config.validators = [];
            if (config.isRequired) config.validators.push(Validators.required);
            this.form.get(f).setValidators(config.validators);
          } else {
            this.form.get(f).setValue(null);
            this.form.get(f).setValidators(null);
          }
          this.form.get(f).updateValueAndValidity();
        }
      }
    }
  }


  removeRecord(field, index) {
    let list = this.form.get(field.prop) as FormArray;
    let oldDetails = list.controls[index]
    list.removeAt(index);
    this.childDeletion.emit({ oldDetails: oldDetails, index: index, name: field.prop });
  }

  addRecord(field) {
    let list = this.form.get(field.prop) as FormArray;
    const currentIndex = list.length;
    list.push(this.createArray(field, currentIndex));
    this.emitChildFieldChanges(this.form, field.prop, currentIndex);
  }
}
