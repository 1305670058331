<div id="app_menu" (swipeleft)="close()" class="sidenav">
  <div class="nav_grid">
    <div [ngClass]="{ hidden: !sideNavOpen }" class="menu">
      <div class="empty-space"></div>
      <div class="content-wrapper">
        <div class="menu_item">
          <button mat-button class="menu-button" (click)="close()">
            <mat-icon>menu</mat-icon>
            <span>{{"siren-admin-translation.admin_module.header.home" | translate}}</span>
          </button>
        </div>
        <div class="menu_item">
          <button mat-button class="menu-button" (click)="navigateTo('admin')">
            <mat-icon>assignment_ind</mat-icon>
            <span>{{"siren-admin-translation.admin_module.header.account_management" | translate}}</span>
          </button>
        </div>
        <div class="menu_item">
          <button mat-button class="menu-button" (click)="navigateTo('admin/profile')">
            <mat-icon>home</mat-icon>
            <span>{{ "siren-admin-translation.admin_module.profile.account_info" | translate }}</span>
          </button>
        </div>
        <div class="menu_item">
          <button mat-button class="menu-button" (click)="navigateTo('admin/lookups')">
            <mat-icon>search</mat-icon>
            <span>{{ "siren-admin-translation.admin_module.lookups" | translate }}</span>
          </button>
        </div>
        <div class="menu_item">
          <button mat-button class="menu-button" (click)="navigateTo('login')">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ "siren-admin-translation.admin_module.toolbar.logout" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="click_overlay" (click)="close()"></div>
  </div>
</div>
