<div *ngIf="programActivityId != undefined && programActivityId != 0" class="activity-execution-container">
  <div class="activity-execution-action-container">
    <span class="act-execution-title">{{ 'program.fields.activity-executions' | translate }} - {{ 'program.fields.total' | translate }} {{ activityExecutionDisplay != undefined ? activityExecutionDisplay.length : 0 }}</span>
    <button *ngIf="canAddNewExecution()" title="{{ 'program.buttons.execute-activity' | translate }}" type="button" class="btn btn-md" (click)="addActivityExecution()"> <!--progActivity?.get('id')?.getRawValue()-->
      <span class="fa fa-plus"></span>
    </button>
  </div>
  <div *ngIf="activityExecutionDisplay" class="execution-log-container">
    <div *ngFor="let execution of activityExecutionDisplay" class="flex-row-container">
      <div>
        <span class="execution-date-label">{{ execution?.fromDate | date:'dd/MM/yyyy' }} - {{ execution?.toDate | date:'dd/MM/yyyy' }} : {{attendeesNumbers}} {{ 'activity-execution.messages.attendees' | translate }}</span>
        <span *ngIf="execution?.attendees == undefined || execution?.attendees.length == 0">{{ 'activity-execution.messages.no-attendees' | translate }}</span>
        <span *ngIf="execution?.attendees != undefined && execution?.attendees.length > 0">
          <ul>
            <li *ngFor="let currrentAttendee of execution?.attendees">
              {{ currrentAttendee.organization }} - {{ currrentAttendee.attendee }}
            </li>
          </ul>
        </span>
      </div>
      <div>
        <button *ngIf="editable()" title="{{ 'program.buttons.edit-execution' | translate }}" type="button" class="btn btn-md" (click)="editActivityExecution(execution?.id)"> <!--progActivity?.get('id')?.getRawValue()-->
          <span class="fa fa-pencil"></span>
        </button>
        <button *ngIf="canDeleteExecution(execution)" title="{{ 'program.buttons.delete-execution' | translate }}"  type="button" class="flat-button" (click)="deleteActivityExecution(execution?.id); $event.stopPropagation();">
          <img class="delete-icon" src="assets/icons/delete-icon.svg">
        </button>
      </div>
    </div>
  </div>
</div>

<span *ngIf="programActivityId == undefined || programActivityId == 0" class="alert-span">
  {{ 'Program activity should be saved before entering an execution' | translate }}
</span>
