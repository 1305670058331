import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { NotificationObject } from 'src/app/models/notification/NotificationObject';

@Injectable({
  providedIn: 'root'
})
export class NotificationService  extends BaseService<NotificationObject, NotificationObject> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/notification", http, injector);
  }

}
