import { Injectable, Injector } from '@angular/core';
import { BaseService } from './shared/base-service';
import { MyHttpHandler } from './shared/http-handler.service';
import { SettingsService } from '../shared/services/settings.service';
import { SurveyUser } from '../models/survey/surveyUser';
import { Observable, map } from 'rxjs';
import { LookupModel } from '../models/shared/lookup.model';

@Injectable({ providedIn: 'root' })
export class SurveyUsersService extends BaseService<SurveyUser, SurveyUser> {
  private _surveyId: number;

  constructor(http: MyHttpHandler, protected injector: Injector, private settingsService: SettingsService){
    super(settingsService.settings.surveyUrlPath + "/user", http, injector);
  }
  
  changeSurvey(surveyId:number){
    this._surveyId = surveyId;
    this.url = this.settingsService.settings.surveyUrlPath + "/user/" + surveyId;
  }
  
  removeUserSurveyLink(id: number): Observable<any> {
    return this.http.delete(this.settingsService.settings.surveyUrlPath + "/user/" + id);
  }
  
  addUserSurveyLink(surveyId: number, userId: number): Observable<any> {
    return this.http.post(this.settingsService.settings.surveyUrlPath + "/user", {
      surveyId: surveyId,
      userId: userId
    });
  }

  getUsersLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(this.settingsService.settings.surveyUrlPath + "/user-list/" + this._surveyId).pipe(
      map((data) => {
        return data.body;
      })
    );
  }
}
