import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Beneficiary, BeneficiaryCodeUnicityCheck, BeneficiaryComment, BeneficiaryCommentCreationModel, BeneficiaryResponse, BeneficiaryStatusChangeModel, SuccessStory, SuccessStoryCreationModel } from '../../models/beneficiary/beneficiary';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryResponseService extends BaseService<BeneficiaryResponse, BeneficiaryResponse> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/beneficiary", http, injector);
  }

  getBeneficiaryResponseBySurveyResponseId(surveyResponseId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>('/v1/beneficiary/beneficiary-response/' + surveyResponseId);
  }

  saveBeneficiaryResponse(surveyResponse: BeneficiaryResponse): Observable<HttpResponse<any>> {
    return this.http.post<any>('/v1/beneficiary/beneficiary-response', surveyResponse);
  }

  setResponseAsBaseLine(surveyResponseId: number): Observable<HttpResponse<any>> {
    return this.http.put<any>('/v1/beneficiary/baseline/' + surveyResponseId, null);
  }

  checkIfResponseIsBaseLine(surveyResponseId: number): Observable<HttpResponse<boolean>> {
    return this.http.get<any>('/v1/beneficiary/is-baseline/' + surveyResponseId);
  }

  getBeneficiaryList(surveyId: number,organizationSurveyId?: number): Observable<HttpResponse<LookupModel[]>> {
    let url=`/v1/beneficiary/survey/${surveyId}`;
    if(organizationSurveyId){
      url +="?organizationSurveyId="+organizationSurveyId;
    }
    return this.http.get<LookupModel[]>(url);
  }
}
