import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-multiple-searchable-selection',
  templateUrl: './multiple-searchable-selection.component.html',
  styleUrls: ['./multiple-searchable-selection.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleSearchableSelectionComponent),
      multi: true,
    },
  ],
})
export class MultipleSearchableSelectionComponent
  implements OnInit, ControlValueAccessor
{
  value: LookupModel[] = [];

  @Input() lookups: LookupModel[] = [];
  @Input() placeHolder: string;
  @Input() formSubmitted: boolean;
  @Input() formControl: FormControl;
  @Input() isMultiSelect: boolean = true;

  @Output() selectionChange = new EventEmitter<LookupModel[]>();

  public lookupMultiFilterCtrl: FormControl = new FormControl();

  public filteredLookupsMulti: ReplaySubject<LookupModel[]> = new ReplaySubject<
    LookupModel[]
  >(1);

  @ViewChild(MatSelect) multiSelect: MatSelect;

  private _onDestroy = new Subject<void>();

  constructor(public readonly _translateService: TranslateService) {}

  ngOnInit() {
    this.lookupMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterLookupsMulti();
      });

    this.lookupMultiFilterCtrl.setValue('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formSubmitted) {
    }
    if (changes.formControl && changes.formControl.currentValue) {
    }
    if (changes.lookups && !changes.lookups.firstChange) {
      this.filterLookupsMulti();
    }
  }

  ngAfterViewInit() {
    this.setInitialValue();
    this.filterLookupsMulti();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredLookupsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.multiSelect) {
          this.multiSelect.compareWith = (a: LookupModel, b: LookupModel) =>
            a && b && a.id === b.id;
        }
      });
  }

  protected filterLookupsMulti() {
    if (!this.lookups) {
      return;
    }
    let search = this.lookupMultiFilterCtrl.value;
    if (!search) {
      this.filteredLookupsMulti.next(this.lookups?.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    const filteredLookups = this.lookups.filter(
      (lookup) => lookup.value.toLowerCase().indexOf(search) > -1
    );

    this.filteredLookupsMulti.next(filteredLookups);
  }

  writeValue(value: any): void {
    if (this.isMultiSelect) {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          this.value = value;
        } else {
          this.value = [value];
        }
        this.filteredLookupsMulti.next(this.value?.slice());
      } else {
        this.value = [];
        if (this.multiSelect) {
          this.multiSelect.value = this.value;
        }
      }
    }
    else{
      this.value = value;
    }
    this.selectionChange.emit(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = (selectedValues: LookupModel[]) => {
      this.value = selectedValues;
      fn(this.value);
      this.selectionChange.emit(this.value);
    };
  }

  isSelected(lookup: LookupModel): boolean {
    if (this.isMultiSelect)
      return this.value?.some(
        (selectedLookup) => selectedLookup?.id === lookup?.id
      );
    //todo fix for single select
    else return true;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange: (selectedValues: LookupModel[]) => void = () => {};
  public onTouched: () => void = () => {};
}
