export interface UserModule {
    module: string;
    key: string;
}

export const MarchPermissionEnum: { [index: string]: UserModule } = {
    USERS: { module: 'USERS_MGT', key: 'USER' }
}

export enum UserRolesEnum {
    SYSTEM_ADMIN = 'SYSTEM_USER'
}

export enum UserRolesActionsEnum {
    LIST = 'LIST',
    GET = 'GET',
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    COMPUTE = 'PI_COMPUTE'
}
