<div class="fullwidth locateForm">
  <form [formGroup]="form">
    <div class="row form-title">
      <div class="col-12 march-page-title">
        <p>{{'lookup-type.title' | translate}}</p>
      </div>
      <div class="col-lg-3 col-sm-6 col-12 locate-lookup">
        <p>{{'lookup-type.fields.select-lookup' | translate}}</p>
      </div>
      <div class="col-lg-3 col-sm-6 col-12">
        <mat-form-field class="full-width-element">
          <mat-label class="" >{{'lookup-type.fields.lookup' | translate}}</mat-label>
          <mat-select class="" formControlName="lookup" (selectionChange)="lookupChanged($event)" >
            <mat-option *ngFor="let lookup of lookupList | keyvalue" [value]="lookup">
                {{ lookup.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
    <app-responsive-list-view *ngIf="lookupSelected"
       [tableColumns]="_tableColumns"
       [sortingDataAccessor]="_sortingDataAccessor"
       [filteringPredicate]="_filteringPredicate"
       [service]="service"
       [withAdd]="canAdd"
       [hasListAccess]="hasListAccess"
       [maxCellStringLength]="80"
       [maxStringLength]="100"
       [inputFilterEventSubject]="filterEventSubject.asObservable()"
       (mode)="updateViewMode($event)"
       [_tableMenuAction]="_tableMenuAction"
       [booleanIconColumnDisplayList]="_booleanIconColumnDisplayList"
       (menuActionEventEmitter)="tableMenuActionHandler($event)"
       [addButtonLabel]="addButtonLabel"
       [tableTitleLabel]="tableTitleLabel"
       [inputReloadEventSubject]="reloadEventSubject.asObservable()"
       [inputInlineEditEventSubject]="inlineEditEventSubject.asObservable()"
       [showFilterButton]="false"
       [inlineEditable]="true"
       [showBackButton]="false"
       [initiateSearchFromFilter]="false">
    <!-- <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter> -->
    <!-- <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter> -->
  </app-responsive-list-view>
</div>
