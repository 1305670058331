import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { QuestionBank, QuestionBankList } from '../models/question/question-bank';
import { BaseService } from './shared/base-service';
import { MyHttpHandler } from './shared/http-handler.service';
import { HttpResponse } from '@angular/common/http';
import { SettingsService } from '../shared/services/settings.service';

@Injectable({ providedIn: 'root' })
export class QuestionBankService extends BaseService<QuestionBankList, QuestionBank> {

  constructor(http: MyHttpHandler, protected injector: Injector, private settingsService: SettingsService){
    super(settingsService.settings.questionBankUrlPath, http, injector);
  }

  saveQuestionBank(questionBank: QuestionBank):  Observable<HttpResponse<QuestionBank>>{
    if(questionBank){
      if(questionBank.id == undefined || questionBank.id == 0){
        return this.postObject(questionBank);
      } else{
        return this.putObject( questionBank.id, questionBank);
      }
    }else{
      return undefined;
    }
  }

}
