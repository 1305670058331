<div class="login-container">
  <div class="login-bkg-image"></div>
  <div class="login-bkg-dimmer"></div>
  <div class="login-container-col">
    <img src="assets/icons/MARCH_LOGO_TRANSPARENT.svg" class="login-march-icon"/>
    <h2 style="color: #FFFFFF; margin-bottom: 20px;">{{ "siren-admin-translation.admin_module.reset_password_confirmation.title" | translate }}</h2>
    <div *ngIf="!validOtp">
      <h4 style="color: #FFFFFF; margin-bottom: 20px;">{{ "siren-admin-translation.admin_module.reset_password_confirmation.validating_access" | translate }}</h4>
    </div>
    <div *ngIf="validOtp">
      <form [formGroup]="form" (ngSubmit)="submit()" class="login-form" *ngIf="!success">
        <div class="login-form-container">
          <div class="alert alert-danger custom_alert" role="alert" *ngIf="error">
            <p>{{error}}</p>
          </div>
          <h5 style="color: #FFFFFF; margin-bottom: 20px;">{{ "siren-admin-translation.admin_module.reset_password_confirmation.info" | translate }}</h5>
          <span class="white-font">{{passwordComplexityMessage}}</span>
          <div class="reset-pass-container">
            <div class="input_styling username_field mt-2">
              <mat-form-field appearance="outline" class="login-input-field">
                <mat-label>{{ "siren-admin-translation.admin_module.reset_password_confirmation.username" | translate }}</mat-label>
                <input matInput autocomplete="off" formControlName="username" class="font-weight-bold" />
              </mat-form-field>
            </div>
            <!-- <div class="input_styling username_field mt-2">
              <mat-form-field appearance="outline" class="login-input-field">
                <mat-label>{{ "reset_password_confirmation.email" | translate }}</mat-label>
                <input matInput formControlName="email" class="font-weight-bold" />
              </mat-form-field>
            </div> -->
            <div class="input_styling mt-2">
              <mat-form-field appearance="outline" class="login-input-field">
                <mat-label>{{ "siren-admin-translation.admin_module.reset_password_confirmation.password" | translate }}</mat-label>
                <input matInput autocomplete="off" formControlName="password" [type]="showPassword ? 'text' : 'password'" class="font-weight-bold" />
                <mat-icon *ngIf="showPassword" matSuffix (click)="tooglePassword()"><i style="font-size: 24px;" class="fa fa-eye"></i></mat-icon>
                <mat-icon *ngIf="!showPassword" matSuffix (click)="tooglePassword()"><i style="font-size: 24px;" class="fa fa-eye-slash"></i></mat-icon>
              </mat-form-field>
            </div>
            <div class="input_styling mt-2">
              <mat-form-field appearance="outline" class="login-input-field">
                <mat-label>{{ "siren-admin-translation.admin_module.reset_password_confirmation.password_confirmation" | translate }}</mat-label>
                <input matInput autocomplete="off" formControlName="passwordConfirmation" [type]="showPasswordConfirmation ? 'text' : 'password'" class="font-weight-bold" />
                <mat-icon *ngIf="showPasswordConfirmation" matSuffix (click)="tooglePasswordConfirmation()"><i style="font-size: 24px;" class="fa fa-eye"></i></mat-icon>
                <mat-icon *ngIf="!showPasswordConfirmation" matSuffix (click)="tooglePasswordConfirmation()"><i style="font-size: 24px;" class="fa fa-eye-slash"></i></mat-icon>
              </mat-form-field>
            </div>
            <div >
              <div *ngIf="this.errorPassComplexity != undefined && this.errorPassComplexity.length > 0" class="alert alert-danger">
                {{ 'siren-admin-translation.generic.complexity_not_respected' | translate }}
                <div *ngFor="let error of errorPassComplexity">
                  <span style="padding-inline-start: 8px;">- {{ error }}</span>
                </div>
              </div>
              <div *ngIf="errorPassNoMatch" class="alert alert-danger">{{
                'siren-admin-translation.generic.confirmation_do_not_match' | translate }}</div>
            </div>
            <button type="submit" class="login-button">
              <img src="assets/icons/lock_white.svg" class="login-button-icon"/>
              <span class="login-button-text">{{"siren-admin-translation.admin_module.reset_password_confirmation.send_reset_password" | translate}}</span>
            </button>
          </div>
          <div class="forgot_password_wrapper pointer">
            <a class="forgot_password_button" (click)="navigateTo('login')">{{"reset_password_confirmation.login" | translate}}</a>
          </div>
        </div>
      </form>

      <div *ngIf="success">
        <div class="alert alert-success custom_alert" role="alert" *ngIf="success">
          <p>{{"siren-admin-translation.admin_module.reset_password_confirmation.success_message" | translate}}</p>
        </div>
        <div class="forgot_password_wrapper pointer">
          <a class="forgot_password_button" (click)="navigateTo('login')">{{"siren-admin-translation.admin_module.reset_password_confirmation.login" | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
