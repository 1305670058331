import { FormArray, FormGroup } from "@angular/forms";

export class OrderHandler{

  static adaptOrderField(objectArray: FormArray) : FormArray{
    if(objectArray){
      objectArray.controls.forEach((control, index) =>{
        (control as FormGroup).controls['order'].setValue(index + 1);
      });
    }
    return objectArray;
  }

  static moveUp(index: number, formArray: FormArray) {
    if (index > 0 && formArray) {
        const currentObject = formArray.at(index) as FormGroup;
        formArray.insert(index - 1, currentObject);
        formArray.removeAt(index + 1);
    }
  }

  static moveDown(index: number, formArray: FormArray) {
    if (formArray && index != undefined && index < formArray.length - 1) {
      const currentObject = formArray.at(index) as FormGroup;
      formArray.insert(index + 2, currentObject);
      formArray.removeAt(index);
    }
  }
}
