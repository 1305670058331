import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { SurveyResponse } from '../models/response/survey-response';
import { SurveyResponseList } from '../models/response/survey-response-list';
import { BaseService } from './shared/base-service';
import { MyHttpHandler } from './shared/http-handler.service';
import { HttpResponse } from '@angular/common/http';
import { SettingsService } from '../shared/services/settings.service';

@Injectable({ providedIn: 'root' })
export class SurveyResponseService extends BaseService<SurveyResponseList, SurveyResponse> {

  fatherSurveyId: number;

  constructor(http: MyHttpHandler, protected injector: Injector, private settingsService: SettingsService){
    super(settingsService.settings.surveyResponseUrlPath, http, injector);
  }

  public getFatherSurveyId() : number{
    return this.fatherSurveyId;
  }

  public setFatherSurveyId(value : number){
    this.fatherSurveyId = value;
    // this.tableListParams.set('surveyId', this.getFatherSurveyId());
  }

  protected getTableListParams(): Map<string, any> {
    let tableListParams: Map<string, any> = new Map<string, any>();
    tableListParams.set('surveyId', this.getFatherSurveyId());
    return tableListParams;
  }

  submitSurveyResponse(object: SurveyResponse, extraParams?: string): Observable<HttpResponse<SurveyResponse>> {
    let newUrl = this.getConstructedObjectUrl() +"?isSubmit=true";
    if(extraParams != undefined && extraParams.trim() != "") newUrl = newUrl + "&param=" + extraParams;
    return this.http.post<SurveyResponse>(newUrl, object);
  }
}
