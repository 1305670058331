import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { ISessionService } from "./interfaces/session-service.interface";
import { ISurveyAuthenticationService } from "../../shared/services/interfaces/authentication.interface";
import { switchMap } from 'rxjs/operators';
import { defaultHTTPRequestOptionsConfig } from "../../shared/config/http-requests-header.config";
import { HttpManagerClass } from "../../shared/base/http-manager.class";

@Injectable({ providedIn: 'root' })
export class MyHttpHandler extends HttpManagerClass {
  constructor(protected http: HttpClient, public readonly _translateService: TranslateService, protected session : ISessionService,
    protected authService: ISurveyAuthenticationService, protected injector: Injector) {
      super(injector);
  }

  // private getDefaultHeaders() : HttpHeaders {
  //   let token = this.session && this.session.getActiveSession() && this.session.getActiveSession().getAccessToken() ? this.session.getActiveSession().getAccessToken() : "";
  //   let lang = this._translateService && this._translateService.currentLang ? this._translateService.currentLang : this._translateService.defaultLang;

  //   if(token){
  //     return new HttpHeaders()
  //     .set('Accept-Language', lang)
  //     .set('Authorization', 'Bearer ' + token)
  //     .set('Content-Type', 'application/json')
  //     .set('Access-Control-Allow-Origin', '*');
  //   } else{
  //     return new HttpHeaders()
  //     .set('Accept-Language',lang)
  //     .set('Content-Type', 'application/json')
  //     .set('Access-Control-Allow-Origin', '*');
  //   }
  // }

  delete<T>(apiEndPoint: String, body?: any, options = defaultHTTPRequestOptionsConfig, showLoader = true): Observable<HttpResponse<T>> {
		if (!this.authService.checkIfUserIsAvailable()) {
			return this.authService.refreshToken().pipe(switchMap(response => this.http.delete<HttpResponse<T>>(this.getRequestUrl(apiEndPoint), this.overwriteDefaultOptions(options, showLoader, body))));
		} else {
			return this.http.delete<HttpResponse<T>>(this.getRequestUrl(apiEndPoint), this.overwriteDefaultOptions(options, showLoader, body));
		}
	}

  put<T>(apiEndPoint: String, body : any, options = defaultHTTPRequestOptionsConfig, showLoader = true) :Observable<HttpResponse<T>>{
    // return this.http.put<T>(this.getRequestUrl(urlPath), body, {'headers': this.getDefaultHeaders()});
    if (!this.authService.checkIfUserIsAvailable()) {
			return this.authService.refreshToken().pipe(switchMap(response => this.http.put<HttpResponse<T>>(this.getRequestUrl(apiEndPoint), body, this.overwriteDefaultOptions(options, showLoader))));
		} else {
			return this.http.put<HttpResponse<T>>(this.getRequestUrl(apiEndPoint), body, this.overwriteDefaultOptions(options, showLoader));
		}
  }

  post<T>(apiEndPoint: String, body : any, options = defaultHTTPRequestOptionsConfig, showLoader = true) :Observable<HttpResponse<T>>{
    // return this.http.post<T>(this.getRequestUrl(urlPath), body, {'headers': this.getDefaultHeaders()});
    if (!this.authService.checkIfUserIsAvailable()) {
			return this.authService.refreshToken().pipe(switchMap(response => this.http.post<HttpResponse<T>>(this.getRequestUrl(apiEndPoint), body, this.overwriteDefaultOptions(options, showLoader))));
		} else {
			return this.http.post<HttpResponse<T>>(this.getRequestUrl(apiEndPoint), body, this.overwriteDefaultOptions(options, showLoader));
		}
  }

  get<T>(apiEndPoint: String, options = defaultHTTPRequestOptionsConfig, showLoader = true) :Observable<HttpResponse<T>>{
    // return this.http.get<T>(this.getRequestUrl(urlPath), {'headers': this.getDefaultHeaders()});
    if (!this.authService.checkIfUserIsAvailable()) {
			return this.authService.refreshToken().pipe(switchMap(response => this.http.get<HttpResponse<T>>(this.getRequestUrl(apiEndPoint), this.overwriteDefaultOptions(options, showLoader))));
		} else {
			return this.http.get<HttpResponse<T>>(this.getRequestUrl(apiEndPoint), this.overwriteDefaultOptions(options, showLoader));
		}
  }

  // getBlobObject(urlPath: String, options = defaultHTTPRequestOptionsConfig, showLoader = true) :Observable<Blob>{
    // return this.http.get(this.getRequestUrl(urlPath), {'headers': this.getDefaultHeaders(), responseType: 'blob'});
  // }

  // get_withParam<T>(urlPath: String, params: Map<String, String>) :Observable<T>{
  //   if(params && params.size > 0){
  //     let paramsStr = "";
  //     let count =0;
  //     for (let [key, value] of params) {
  //       if(count > 0) paramsStr += "&";
  //       paramsStr += key + "=" + value;
  //       count++;
  //     }
  //     urlPath += "?" + paramsStr;
  //   }
  //   return this.http.get<T>(this.getRequestUrl(urlPath), {'headers': this.getDefaultHeaders() });
  // }

  // get_withParamList<T>(urlPath: String, params: String[]) :Observable<T>{
  //   if(params && params.length > 0){
  //     let paramsStr = "";
  //     let count =0;
  //     for (let value of params) {
  //       if(count > 0) paramsStr += "&";
  //       paramsStr += value;
  //       count++;
  //     }
  //     urlPath += "?" + paramsStr;
  //   }
  //   return this.http.get<T>(this.getRequestUrl(urlPath), {'headers': this.getDefaultHeaders() });
  // }


  protected getRequestUrl(urlPath: String): string{
    return this.settingsService.settings.backendBaseUrl + urlPath;
  }
}
