import { BreakpointObserver } from '@angular/cdk/layout';
import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.sass']
})

export class SidenavComponent implements OnInit {
  // @Output() newsClicked = new EventEmitter<boolean>();
  sideNavOpen = false;
  constructor(private router: Router, private observer: BreakpointObserver) { }

  ngOnInit(): void {
  }

  // _newsClicked() {
  //   this.close();
  //   this.newsClicked.emit(true);
  // }

  open() {
    document.querySelector('#app_menu').classList.add('open');
    this.sideNavOpen = true;
}

close() {
    document.querySelector('#app_menu').classList.remove('open');
    this.sideNavOpen = false;
}


  public navigateTo(url: string) {
    this.close();
    this.router.navigate([url]);
  }

}
