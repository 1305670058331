
<div class="input_styling">
    <div [ngClass]="{
          invalid: fieldInvalid()
        }">
      <mat-form-field color="primary" class="sheet-elements" appearance="outline">
        <mat-label>{{ placeholdertext | translate }} {{ fieldRequired() ? "*" : "" }}
          <span *ngIf="input.value.length>0">{{input.value?.length || 0}}/2000</span>
        </mat-label>
        <textarea #input cdkTextareaAutosize #autosize="cdkTextareaAutosize" [formControl]="ngControl.control" matInput
          maxlength=maxLength class="textarea" [placeholder]="'max_2000_chars' | translate" cdkAutosizeMinRows=4></textarea>
      </mat-form-field>
    </div>
    <app-error-display [control]="ngControl.control">
    </app-error-display>
  </div>