import { FormControl, ValidationErrors } from "@angular/forms";


export class CustomValidators{

  public static noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length? null : { 'whitespace': true };
  }

  public static validateLebaneseMobileNumber(control: FormControl): ValidationErrors | null {
    if (control.value === null || control.value === '' || control.value === undefined) {
      return null;
    }

    const value = control.value.toString(); // Ensure the value is a string

    if (value.trim() === '') {
      return { incorrectFormat: 'siren-admin-translation.form_errors.incorrect_format' };
    }

    if (!value.match(/^([\u0660-\u0669]|[0-9]){8}$/)) {
      return { phone_number_formatting: 'siren-admin-translation.form_errors.phone_number_formatting' };
    }

    const numList: string[] = ["03", "76", "81", "79", "78", "71", "70", "٠٣", "٧٦", "٨١", "٧٩", "٧٨", "٧١", "٧٠"];

    if (!numList.some(x => value.startsWith(x))) {
      return { lebanese_number_error: 'siren-admin-translation.form_errors.lebanese_number_error' };
    }

    return null; // No validation errors
  }
}
