import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterLookupService {

  private objects: { [key: string]: any } = {};

  setObject(key: string, data: any) {
    this.objects[key] = data;
  }

  getObject(key: string) {
    return this.objects[key];
  }

  removeObject(key: string) {
    delete this.objects[key];
  }

  removeAllObjects() {
    this.objects = {};
  }
}
