import { Component, Input, OnInit, Output, EventEmitter, ViewChild, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { LookupModel } from '../../../models/shared/lookup.model';
import { FilterOperator, TableFilter, TableFilterElement, TableFilterOutput, TableFilterOutputElement, TableFilterTypeEnum } from '../../../models/shared/table-filter.model';
import { MyHttpHandler } from '../../../services/shared/http-handler.service';
import { DropdownComparator } from '../component-base/dropdown-comparator';
import { LookupService } from '../../../services/lookups/lookup.service';
import { SettingsService } from '../../../shared/services/settings.service';
import { formHelper } from '../../../shared/helpers/form-helper';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.sass']
})
export class TableFilterComponent extends DropdownComparator implements OnInit {
  @Input() filters: TableFilterElement[];
  @Input() showFilterButton: boolean = true;
  @Input() predefinedFilter: Map<string, any>;
  @Input() initiateSearchFromFilter: boolean = false;

  @Output() filterOutput = new EventEmitter<TableFilterOutput>();

  @ViewChild('filterbutton') filterbutton: any;

  tableFilterTypeEnum: typeof TableFilterTypeEnum = TableFilterTypeEnum;

  isInitCall = true;

  public filterForm: FormGroup;
  public lookups: Map<string, LookupModel[]> = new Map();

  public OperatorEnum: FilterOperator;

  constructor(private http: MyHttpHandler, private datePipe : DatePipe, private lookupService: LookupService, private settingsService: SettingsService) {
    super();
  }

  ngOnInit(): void {
    this.constructFormGroupFromMap();
    this.getLookups();
    if(this.initiateSearchFromFilter) this.applyFilters();
  }

  getLookups(){
    this.lookups = new Map();
    if(this.filters){
      let lookupNames: string[] = [];
      let lookupObjectNames: string[] = [];
      this.filters.forEach(filter =>{
        if(filter.lookupName && filter.type){
          if(filter.type == TableFilterTypeEnum.Lookup && filter.lookupName != undefined && !lookupNames.includes(filter.lookupName)) lookupNames.push(filter.lookupName);
          if(filter.type == TableFilterTypeEnum.LookupObject) lookupObjectNames.push(filter.lookupName);
          if(filter.type == TableFilterTypeEnum.LookupObjectEnumeration) lookupObjectNames.push(filter.lookupName);
        }
      });

      if(lookupNames && lookupNames.length > 0){
        this.lookupService.getLookupsService(lookupNames, false, true).subscribe(response => {
          if(response?.body){
            lookupNames.forEach(name => {
              let currLookup: LookupModel[] = response.body[name];
              if(currLookup) this.lookups.set(name, currLookup);
            });
          }
        });
      }

      if(lookupObjectNames && lookupObjectNames.length > 0){
        this.lookupService.getLookupObjectsService(lookupObjectNames, false, true).subscribe(response => {
          if(response?.body){
            lookupObjectNames.forEach(name => {
              let currLookup: LookupModel[] = response.body[name];
              if(currLookup) this.lookups.set(name, currLookup);
            });
          }
        });
      }
    }
  }

  getLookupByName(filterIndex: number): LookupModel[]{
    let response: LookupModel[] = [];
    if(this.lookups && this.filters && filterIndex != undefined && this.lookups.size > 0 && filterIndex < this.filters.length){
      let filter = this.filters[filterIndex];
      if(filter) response = this.lookups.get(filter.lookupName);
    }
    return response;
  }

  getStaticValues(filterIndex: number): { [key: string]: string } {
    return this.filters[filterIndex].staticValue;
  }
  constructFormGroupFromMap(){
    this.filterForm = new FormGroup({
      'filterComponents': new FormArray([])
    });

    let filterComponentsFormArray = this.filterComponents as FormArray;
    if(this.filters && filterComponentsFormArray){
      this.filters.forEach(filter =>{
        let currFormGroup = new FormGroup({
          'name': new FormControl({value: filter?.name, disabled: true}),
          'type': new FormControl({value: filter?.type.toString(), disabled: true}),
          'lookupName': new FormControl({value: filter?.lookupName, disabled: true},),
          'lookupsValueById': new FormControl({value: filter?.lookupsValueById, disabled: true},),
          'operator': new FormControl(filter?.operator),
          'displayName': new FormControl({value: filter?.displayName, disabled: true},),
          'translationPath': new FormControl({value: filter?.translationPath, disabled: true},),
          'value': new FormControl(filter?.value),
          'valueTo': new FormControl(filter?.valueTo),
          'valueFrom': new FormControl(filter?.valueFrom),
          'values': new FormControl(filter?.values),
          'visible': new FormControl(filter?.visible != undefined ? filter?.visible : true),
          'cssClass': new FormControl(filter?.cssClass),
          'isMultiSelect': new FormControl(filter?.isMultiSelect != undefined ? filter?.isMultiSelect : false),
          'staticValues': new FormControl(filter?.staticValue)
        });
        filterComponentsFormArray.push(currFormGroup);
      });
    }
  }

  applyFilters(){
    let emittedFilters: TableFilterOutput = this.getTableFilterOutput();
    this.filterOutput.emit(emittedFilters);
    this.filterbutton?.nativeElement?.click();
    this.isInitCall = false;
  }

  getTableFilterOutput(): TableFilterOutput{
    formHelper.trimAllFormValues(this.filterForm);
    let formFilterValues: TableFilter = this.filterForm.getRawValue();
    let searchConcatenatedValue: string = "";
    formFilterValues.filterComponents.forEach(filter => {
      let currentFilterFormatedValue = this.getOutputElement(filter);
      if(currentFilterFormatedValue && currentFilterFormatedValue.trim() !== ""){
        if(searchConcatenatedValue && searchConcatenatedValue.trim() !== "") searchConcatenatedValue += ",";
        if(filter.type == TableFilterTypeEnum.String){
          currentFilterFormatedValue = currentFilterFormatedValue.replaceAll(',','~c~');
        }
        searchConcatenatedValue += currentFilterFormatedValue;
      }
    });
    if(this.predefinedFilter) {
      this.predefinedFilter.forEach(function(value, key){
        let currentFilterFormatedValue = key + ":" + value;
        if(searchConcatenatedValue && searchConcatenatedValue.trim() !== "") searchConcatenatedValue += ",";
        searchConcatenatedValue += currentFilterFormatedValue;
      });
    }
    let searchFilter: TableFilterOutput = new TableFilterOutput();
    searchFilter.filterComponents.push(this.createNewTableFilterOutputElement("isInitCall", this.isInitCall));
    searchConcatenatedValue = searchConcatenatedValue.trim();
    if(searchConcatenatedValue && searchConcatenatedValue.trim() !== "") searchFilter.filterComponents.push(this.createNewTableFilterOutputElement("search", searchConcatenatedValue));
    return searchFilter;
  }

  getOutputElement(filter : TableFilterElement): string{
    let response: string = "";
    if(filter && filter.name && filter.operator){
      let name = filter.name.trim();
      if(filter.values){
        // let value : any;
        let count = 0;
        filter.values.forEach(valueElement=>{
          // if(count < 0) value += ',';
          response += name + filter.operator.trim() + ( filter.operator.trim() == FilterOperator.Like ? "'" + valueElement + "'" : valueElement);
        });
      } else{
        if(filter.type==TableFilterTypeEnum.Boolean && filter.value != undefined && (filter.value == false || filter.value == true)){
          response += name + filter.operator.trim() + filter.value;
        } else if(filter.type==TableFilterTypeEnum.DateRange && ( filter.valueFrom || filter.valueTo) ){
          if(filter.valueFrom) response += name + ">:" + this.datePipe.transform(filter.valueFrom, 'dd/MM/yyyy hh:mm:ss');
          if(filter.valueTo){
            if(filter.valueFrom)response +=",";
            response += name + "<:" + this.datePipe.transform(filter.valueTo, 'dd/MM/yyyy hh:mm:ss');
          }
        }
        else if((filter.type==TableFilterTypeEnum.LookupObject || filter.type==TableFilterTypeEnum.Lookup) && filter.isMultiSelect){
          if(filter.value && filter.value.length > 0){
            let values: string = "";
            filter.value.forEach(value => {
              if(values && values.trim() != "") {
                values += ";";
              }
              values += value;
            });
            response += name + filter.operator.trim() + values;
          }
        }
        else if(filter.value != undefined && filter.value != ""){
            response += name + filter.operator.trim() +  ( filter.operator.trim() == FilterOperator.Like ? "'" + filter.value + "'" : filter.value);
        }
      }
    }
    return response;
  }

  createNewTableFilterOutputElement(name: string, value: any): TableFilterOutputElement{
    let outputElement : TableFilterOutputElement = new TableFilterOutputElement();
    outputElement.name = name;
    outputElement.value = value;
    return outputElement;
  }

  getFormatedValue(value : any, type: TableFilterTypeEnum){
    let formatedValue: any = value;
    if(value != undefined && type && (type == TableFilterTypeEnum.Date || type == TableFilterTypeEnum.DateRange))
      formatedValue = this.datePipe.transform(value, 'yyyy-MM-dd hh:mm:ss');
    return formatedValue;
  }

  get filterComponents(): FormArray {
    return this.filterForm.get('filterComponents') as FormArray;
  }

  clearFilters(){
    this.constructFormGroupFromMap();
    this.applyFilters();
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  onKeyDownEvent(event: any){
    if(event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }
}
