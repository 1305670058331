export class MenuItemModel {
    constructor(
        public value: string,
        public icon: string,
        public label: string,
        public link?: string,
        public children?: ChildMenuItemModel[]
    ) {
    }

}

export class ChildMenuItemModel {
    constructor(
        public label: string,
        public link: string,
        public disabled = false
    ) {
    }
}