import { Component, HostListener, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  template: ''
})
export class BrowserListener implements OnDestroy {

  static MAX_MOBILE_SIZE: number = 991; // 767

  _isMobileListener : Subject<boolean> = new Subject<boolean>();
  _isMobile = false;

  constructor( ) {
    this.evaluateIsMobile(window.innerWidth);
  }

  ngOnDestroy(): void {
    if(this._isMobileListener){
      this._isMobileListener.complete();
      this._isMobileListener = null;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    this.evaluateIsMobile(innerWidth);
    this.executeOnResize();
  }

  executeOnResize(){

  }

  protected evaluateIsMobile(innerWidth: number){
    // let isMobile = window.innerWidth <= this.getMobileMaxWidth() ? true : false;
    let isMobile = innerWidth <= BrowserListener.getMobileMaxWidth() ? true : false;
    if(isMobile != this._isMobile) {
      this._isMobile = isMobile;
      this._isMobileListener.next(this._isMobile);
    }
  }

  public static getMobileMaxWidth(){
    return BrowserListener.MAX_MOBILE_SIZE;
  }
}
