import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatatableInlineEdit } from '@siren-survey/app/component/shared/datatable/datatable-inline-edit.service';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { ResponsiveListInlineEditObject, TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { Subject } from 'rxjs';
import { LegalCaseStatus } from 'src/app/models/legal-case/legalcase';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { LegalCaseService } from 'src/app/services/legal-case/legal-case.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { MatDialog } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/services/shared/validators.service';

@Component({
  selector: 'app-legal-case-status-table',
  templateUrl: './legal-case-status-table.component.html',
  styleUrls: ['./legal-case-status-table.component.sass']
})
export class LegalCaseStatusTableComponent extends DatatableInlineEdit implements OnInit {

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  inlineEditEventSubject: Subject<ResponsiveListInlineEditObject> = new Subject<ResponsiveListInlineEditObject>();

  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;
  canAdd: boolean = false;
  hasListAccess: boolean = false;
  predefinedFilter: Map<string, any>;

  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  _booleanIconColumnDisplayList: string[] = [
    "isInitialStatus","isEndStatus"
  ]

  _tableColumns  : DataTableModel<LegalCaseStatus> []= [
   {  columnDef: 'name', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "legal-case.fields.name", header: this._translateService.instant('legal-case.fields.name'),  cell: (element: LegalCaseStatus) => `${element?.name}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'isInitialStatus', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "legal-case.fields.initial-status", header: this._translateService.instant('legal-case.fields.initial-status'),  cell: (element: LegalCaseStatus) => `${element?.isInitialStatus}`, width: '150px'},
    {  columnDef: 'isEndStatus', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "legal-case.fields.end-status", header: this._translateService.instant('legal-case.fields.end-status'),  cell: (element: LegalCaseStatus) => `${element?.isEndStatus}`, width: '150px'},
    {  columnDef: '_menu_inline', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "name", translationPath: "legal-case.fields.name", displayName: this._translateService.instant('legal-case.fields.name'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "isInitialStatus", translationPath: "legal-case.fields.initial-status", displayName: this._translateService.instant('legal-case.fields.initial-status'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "isEndStatus", translationPath: "legal-case.fields.end-status", displayName: this._translateService.instant('legal-case.fields.end-status'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_LEGAL_CASES), accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ]) },
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_LEGAL_CASES), accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ]) },
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  constructor(public service : LegalCaseService, private router: Router, private route : ActivatedRoute, private readonly _translateService: TranslateService,
    private dialogService: DialogService, public dialog: MatDialog,public authService: AuthenticationService, private _snackbar: MatSnackBar) {
    super();
    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_LOOKUPS);
    this.hasListAccess = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_LOOKUPS);
  }

  ngOnInit(): void {
  }

  lastCreatedId = 0;
  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.CREATE_MODE) {
      this.lastCreatedId--;
      let editFormGroup = new FormGroup({
        'id': new FormControl({value: this.lastCreatedId, disabled: true}),
        'name': new FormControl({value:'', disabled: false},  [Validators.required, CustomValidators.noWhitespaceValidator]),
        'isInitialStatus': new FormControl({value: false, disabled: false}),
        'isEndStatus': new FormControl({value: false, disabled: false}),
      })
      let inlineEditObject = new ResponsiveListInlineEditObject(this.lastCreatedId, this.lastCreatedId, editFormGroup,false);
      this.inlineEditEventSubject.next(inlineEditObject);
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
        this.dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-legal-case'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
        .subscribe(response=>{
          if(response){
            this.service.deleteLegalStatus(menuAction.object.id).subscribe( response =>{
              this.reloadEventSubject.next(true);
            }, error =>{
                this._snackbar.open(this._translateService.instant('legal-case.error.cannot-delete-status'), null, { duration: 3000 });
              });
          }
        });
      } else{
        this.handleInlineEditingRequests(menuAction,this._snackbar,this._translateService);

      }
    }
  }

  isInlineEditable(): boolean {
    return true;
  }

  getTableFormGroupModel(): FormGroup<any> {
    return  new FormGroup({
      'id': new FormControl({value: this.lastCreatedId, disabled: true}),
      'name': new FormControl({value:'', disabled: false},  [Validators.required, CustomValidators.noWhitespaceValidator]),
      'isInitialStatus': new FormControl({value: '', disabled: false}),
      'isEndStatus': new FormControl({value: '', disabled: false}),
    });
  }

  triggerInlineEditEventSubject(inlineEditObject: ResponsiveListInlineEditObject): void {
    if(this.inlineEditEventSubject) this.inlineEditEventSubject.next(inlineEditObject);
  }

  getTableService() {
    return this.service;
  }

  getValidForm(menuAction:MenuActionEmittedObject): boolean {
    menuAction.form.markAllAsTouched();
    menuAction.form.updateValueAndValidity();
    return menuAction.form.valid;
  }

  usePostForUpdate(){
    return true;
  }

  handleOnSubmitError(message? : string){
     return message != undefined && message.trim() != '' ? message : this._translateService.instant('snackbar.unable-save');
  }

  formatObject(object: any): any{
    object.isInitialStatus = object.isInitialStatus != "" ? object.isInitialStatus : false;
    object.isEndStatus = object.isEndStatus != "" ? object.isEndStatus : false;
    return object;
  }

  withCheckUnicity(): boolean{
    return true;
  }

  objectClassName(): string{
    return "LegalCaseStatus";
  }

}

export class DeleteLegalCaseStatusFormInput{
  constructor(public statusId: number) {}
}
