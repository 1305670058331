import { HttpBaseClass } from '../shared/bases/http-base.class';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DefaultJudgePaginationParametersModel, PaginationParametersModel, SpringJudgeDefaultPaginationParametersModel, SpringPaginationParametersModel } from '../shared/models/pagination-parameters.model';
import { Observable } from 'rxjs';

@Injectable()
export class AdminServiceService extends HttpBaseClass {

  constructor(public http: HttpClient,
    public injector: Injector) {
    super(http, injector);
  }

  pagination: PaginationParametersModel = Object.assign({}, DefaultJudgePaginationParametersModel);
  springPagination: SpringPaginationParametersModel = Object.assign({}, SpringJudgeDefaultPaginationParametersModel);

  getUsers(filter: any, onPageLoad?: boolean, shouldLoadMore = false): Observable<HttpResponse<any>> {
    let params: SpringPaginationParametersModel;
    if (shouldLoadMore) {
      this.springPagination.page++
    }
    if (onPageLoad) {
      this.springPagination = Object.assign({}, SpringJudgeDefaultPaginationParametersModel);
      params = params = Object.assign({}, SpringJudgeDefaultPaginationParametersModel);
    }
    let qparams;
    if (filter) {
      qparams = Object.assign({}, params ? params : this.springPagination, this.setAndGetParams(filter).params);
    } else {
      qparams = params ? params : this.springPagination;
    }
    return this.get('admin/user', this.setAndGetParams(qparams), true, this.settingsService.settings.baseUrl)
  }

  getRoles(filter: any, onPageLoad?: boolean, shouldLoadMore = false): Observable<HttpResponse<any>> {
    let params: SpringPaginationParametersModel;
    if (shouldLoadMore) {
      this.springPagination.page++
    }
    if (onPageLoad) {
      this.springPagination = Object.assign({}, SpringJudgeDefaultPaginationParametersModel);
      params = params = Object.assign({}, SpringJudgeDefaultPaginationParametersModel);
    }
    let qparams;
    if (filter) {
      qparams = Object.assign({}, params ? params : this.springPagination, this.setAndGetParams(filter).params);
    } else {
      qparams = params ? params : this.springPagination;
    }
    return this.get('admin/role', this.setAndGetParams(qparams), true, this.settingsService.settings.baseUrl)
  }

  setRole(userId: number, roles: string) {
    return this.put(`admin/user/${userId}/roles/${roles}`,null,undefined, true, this.settingsService.settings.baseUrl)
  }

  createUser(user: any) {
    return this.post('admin/user',user, undefined, true, this.settingsService.settings.baseUrl);
  }


}
