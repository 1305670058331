<form [formGroup]="currentFormGroup">
  <div class="statement-container">
     <!--DP Custom / standard filter-->
    <div class="full-width-element">
      <mat-form-field  class="full-width-element mat-input-without-space-after">
        <mat-label class="" >{{'siren-survey-translation.question-bank-form.fields.type' | translate}}</mat-label>
        <mat-select class="" formControlName="filterType" (selectionChange)="changeFilterType($event)">
            <mat-option *ngFor="let filterType of this.filterTypes" [value]="filterType?.id" >
              {{'siren-survey-translation.question-bank-form.fields.filter-type.'+filterType?.value.toLowerCase().replace(" ","-") | translate}}
            </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="statement-selection-container" *ngIf="isStandardStatement && filterTypeSelected">
      <div formGroupName="surveyQuestion">
          <mat-form-field  class="full-width-element mat-input-without-space-after">
          <mat-label class="" >{{'siren-survey-translation.question-bank-form.fields.question' | translate}}</mat-label>
          <mat-select class="" formControlName="id" (selectionChange)="checkOrder($event)">
              <mat-option *ngFor="let question of getQuestionSelectionList(false)" [value]="question?.id" >
                {{ question?.value }}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div formGroupName="questionAnswer">
        <mat-form-field  class="full-width-element mat-input-without-space-after" *ngIf="getAnswerListForQuestion(false) && getAnswerListForQuestion(false).length > 0">
          <mat-label class="" >{{'siren-survey-translation.question-bank-form.fields.answer' | translate}}</mat-label>
          <mat-select class="" formControlName="id" >
              <mat-option *ngFor="let answer of getAnswerListForQuestion()" [value]="answer?.id" >
                {{ answer?.value }}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- custom filter-->
    <div class="statement-selection-container" *ngIf="!isStandardStatement && filterTypeSelected && filterNames">
      <div>
          <mat-form-field  class="full-width-element mat-input-without-space-after">
          <mat-label class="" >{{'siren-survey-translation.question-bank-form.fields.condition-type' | translate}}</mat-label>
          <mat-select class="" formControlName="filterName"  (selectionChange)="changeFilterName($event)" >
              <mat-option *ngFor="let filterName of filterNames" [value]="filterName?.value" >
                {{ filterName?.value }}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field  class="full-width-element mat-input-without-space-after" *ngIf="filterNameSelected && filterIds">
          <mat-label class="" >{{'siren-survey-translation.question-bank-form.fields.condition-value' | translate}}</mat-label>
          <mat-select class="" formControlName="filterId" >
              <mat-option *ngFor="let filterId of filterIds" [value]="filterId?.id" >
                {{ filterId?.value }}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="isStandardStatement && filterTypeSelected">
      <button class="col-sm-6 col-12" title="Delete Statement" class="button-flat delete-statement-btn" type="button" *ngIf='canDeleteStatement()'  (click)="deleteStatement()">
        <img [src]="getAssetsUrl('assets/icons/x-icon.svg')" class="iconWidth">
      </button>
    </div>
    <div *ngIf="!isStandardStatement && filterTypeSelected">
      <button class="col-sm-6 col-12" title="Delete Statement" class="button-flat delete-statement-btn" type="button" *ngIf='canDeleteCustomStatement()'  (click)="deleteCustomStatement()">
        <img [src]="getAssetsUrl('assets/icons/x-icon.svg')" class="iconWidth">
      </button>
    </div>
  </div>
</form>
