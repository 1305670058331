import { ComponentFactoryResolver, ComponentRef, EventEmitter, Injectable, Injector, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { LookupModel } from "@siren-survey/app/models/shared/lookup.model";
import { SurveyFormQuestionHandler } from "@siren-survey/app/services/survey-form/survey-form-question-handler.service";
import { ISurveyResponseClientService } from "@siren-survey/app/shared/services/interfaces/survey-response-client.interface";
import { MarchSurveyResponseHeaderComponent } from "src/app/component/survey/march-survey-response/march-survey-response-header/march-survey-response-header.component";
import { Beneficiary } from "src/app/models/beneficiary/beneficiary";
import { BeneficiaryService } from "../../beneficiary/beneficiary.service";
import { DataTableModel } from "@siren-survey/app/models/shared/cell-data-model";
import { SurveyResponseList } from "@siren-survey/app/models/response/survey-response-list";


@Injectable({
  providedIn: 'root'
})
export class MarchSurveyResponseService implements ISurveyResponseClientService{

  beneficiaryId: number;
  beneficiary: Beneficiary;
  isBenefLoaded: boolean;

  dataEmitter  = new EventEmitter<any>();

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector,private beneficiaryService: BeneficiaryService) {}

  public getAdditionalResponseHeaderTemplate(responseId: number, surveyId: number, isEditable: boolean ) : ComponentRef<MarchSurveyResponseHeaderComponent> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(MarchSurveyResponseHeaderComponent);
    const componentRef = factory.create(this.injector);
    componentRef.instance.responseId = responseId;
    componentRef.instance.isEditable = isEditable;
    componentRef.instance.surveyId = surveyId;
    componentRef.instance.marchSurveyResponseService = this;
    componentRef.changeDetectorRef.detectChanges();
    return componentRef;
  }

  public getSubmitSurveyQueryParams(componentRef: ComponentRef<any>): string {
    if(componentRef != null){
      let responseHeaderFormGroup: FormGroup = componentRef?.instance?.responseHeaderFormGroup;
      if(responseHeaderFormGroup != undefined){
        let beneficiary: LookupModel = responseHeaderFormGroup.get('beneficiary').getRawValue();
        if(beneficiary != undefined && beneficiary.id != undefined && beneficiary.id > 0)
          return "beneficiary:" + beneficiary.id;
      }
    }
    return undefined;
  }

  public evaluateCustomConditionForQuestionVisibility(object: any): boolean {
    let visible : boolean = false;
    if(object != null && this.beneficiary){
      // object is the customStatement
      let filterName = object?.filterName;
      let filterId = object?.filterId;
      //checking for showHide
      if(filterName == SurveyFormQuestionHandler.REGION_FILTER_NAME.value){
        visible = filterId == this.beneficiary?.region?.id;
      }else if(filterName == SurveyFormQuestionHandler.GENDER_FILTER_NAME.value){
        visible = filterId == this.beneficiary?.contact?.gender?.id;
      }else if(filterName == SurveyFormQuestionHandler.ACHIEVEMENT_FILTER_NAME.value){
        this.beneficiary.achievements.forEach(achievement => {
          visible = filterId == achievement?.id;
        });
      }
    }
    return visible;
  }

  public handleSelectBeneficiaryId(benefId: number){
      this.beneficiaryId = benefId;
      // once setted call get beneficiary from BE
      this.beneficiaryService.getObject(benefId).subscribe(response =>{
        if(response && response.body){
          this.beneficiary = response.body;
          this.dataEmitter.emit(benefId);
        }
      });
  }

  public getSelectedBeneficiaryId(): number{
    return this.beneficiaryId;
  }

  public getDataEmitter(): EventEmitter<any>{
    return this.dataEmitter;
  }

  public getResponseTableAdditionalColumns(): DataTableModel<SurveyResponseList>[]{
    return [
      {  columnDef: 'beneficiary', headerTranslationPath: "", header: 'Beneficiary', cell: (element: any) => `${element?.beneficiary}`, width: undefined,contentClass: 'leftAlign'}
    ] ;
  }
}
