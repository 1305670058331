<form [formGroup]="condition">
  <div class="full-width-element action-selection-container" *ngIf="!onlyShowActionAllowed">
    <mat-form-field class="full-width-element mat-input-without-space-after action-selection">
      <mat-label>{{'siren-survey-translation.question-conditions-form.fields.action' | translate}}</mat-label>
      <mat-select class=""  matInput formControlName="action" >
        <mat-option *ngFor="let action of actions | keyvalue" [value]="action.key" >
          {{ action.value | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-info-tooltip class="marginBottom" message="{{'siren-survey-translation.question-conditions-form.messages.condition-information' | translate}}"></app-info-tooltip>
  </div>

  <div class="full-width-element action-selection-container" *ngIf="onlyShowActionAllowed">
    <span style="text-decoration: underline;">{{'siren-survey-translation.question-conditions-form.title' | translate}}</span>
    <app-info-tooltip class="marginBottom" message="{{'siren-survey-translation.question-conditions-form.messages.condition-information' | translate}}"></app-info-tooltip>
  </div>
  <div style="margin-top: 10px;" formArrayName="blocks">
    <div class="error-message" *ngIf="getBlocksFormArray()?.length == 0">{{'siren-survey-translation.question-conditions-form.error.missing-blocks' | translate}}</div>
    <div *ngFor="let block of getBlocksFormArray().controls; let blockIndex = index;" [formGroupName]="blockIndex" >
      <div *ngIf="block" class="block-container">
        <div class="full-width-element statement-list-container">
          <span class="error-message" *ngIf="hasUnsavedQuestionChanges">{{'siren-survey-translation.error.save_before_adding_condition' | translate}}</span>
          <div class="error-message" *ngIf="!hasUnsavedQuestionChanges && (block?.get('statements')?.length == 0 && block?.get('customStatements')?.length == 0)">{{'siren-survey-translation.question-conditions-form.error.missing-statements' | translate}}</div>

          <div *ngIf="block.get('statements')" formArrayName="statements" class="statements-list-container">
            <div *ngFor="let statement of getStatementFormArray(blockIndex).controls; let statementIndex = index;" [formGroupName]="statementIndex" >
              <app-question-statement
                [statements]="getStatementFormArray(blockIndex)"
                [customStatements]="getCustomStatementFormArray(blockIndex)"
                [statementIndex]="statementIndex"
                [isEditMode]="editable()"
                [isStandardStatement]="true"
                [parentQuestionControl]="parentQuestionControl"
                [statement]="statement"
                [surveyFormQuestionHandler]="surveyFormQuestionHandler"
                (questionStatementChanged)="onQuestionStatementChange($event)">
              </app-question-statement>
              <hr class="divider" style="margin: 13px 0px;" *ngIf="statementIndex != (getStatementFormArray(blockIndex).controls.length - 1)">
            </div>
          </div>
          <hr class="divider" style="margin: 13px 0px;" *ngIf="block?.get('customStatements')?.length > 0 && block?.get('statements')?.length > 0">
          <div *ngIf="block.get('customStatements')" formArrayName="customStatements" class="statements-list-container">
           <div *ngFor="let customStatement of getCustomStatementFormArray(blockIndex).controls; let customStatementIndex = index;" [formGroupName]="customStatementIndex" >
              <app-question-statement
                [customStatements]="getCustomStatementFormArray(blockIndex)"
                [statements]="getStatementFormArray(blockIndex)"
                [customStatementIndex]="customStatementIndex"
                [isEditMode]="editable()"
                [parentQuestionControl]="parentQuestionControl"
                [customStatement]="customStatement"
                [isStandardStatement]="false"
                [surveyFormQuestionHandler]="surveyFormQuestionHandler">
              </app-question-statement>
              <hr class="divider" style="margin: 13px 0px;" *ngIf="customStatementIndex != (getCustomStatementFormArray(blockIndex).controls.length - 1)">
            </div>
          </div>
        </div>
        <div *ngIf="editable()" [ngClass]="_translateService?.currentLang == 'ar' ? 'div-action-button-bar-container-rtl' : 'div-action-button-bar-container' ">
          <button title="{{'siren-survey-translation.question-conditions-form.button.delete-block' | translate}}" class="button-flat action-embedded-btn" type="button" *ngIf='canDeleteBlock()'  (click)="deleteBlock(blockIndex)">
            <img [src]="getAssetsUrl('assets/icons/delete-icon-white.svg')" class="iconWidth">
          </button>
          <button type="button" title="{{'siren-survey-translation.question-conditions-form.fields.addStatement' | translate}}" class="btn btn-md action-embedded-btn table-action-button" *ngIf="editable()" (click)="addStatement(blockIndex, block)">
            <span class="fa fa-plus" style="color: #FFFFFF"></span>
            <!-- {{'siren-survey-translation.question-conditions-form.fields.addStatement' | translate}} -->
          </button>
          <app-info-tooltip class="marginBottom" iconColor="#FFFFFF" message="{{'siren-survey-translation.question-conditions-form.messages.block-information' | translate}}"></app-info-tooltip>
        </div>
      </div>
    </div>
    <div class="div-action-button-container">
      <!-- <h4 class="survey-section-title">{{'siren-survey-translation.question-conditions-form.fields.blocks' | translate}}</h4> -->
      <button type="button" class="btn btn-md no-padding" *ngIf='editable()' (click)="addBlock()">
        <span class="fa fa-plus"></span>
        <span style="padding-inline-start: 8px;">{{'siren-survey-translation.question-conditions-form.fields.addBlock' | translate}}</span>
      </button>
    </div>
  </div>
</form>
