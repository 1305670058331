import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormClassification } from '../models/assessment-classification.model';
import { ClassificationService } from '../services/assessment-classification.service';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { formHelper } from 'src/app/shared/helper/form-helper';
import { Subscription } from 'rxjs';
import { LookupModel } from 'src/app/models/shared/lookup.model';

@Component({
  selector: 'app-classification-form',
  templateUrl: './classification-form.component.html',
  styleUrls: ['./classification-form.component.sass']
})
export class ClassificationFormComponent implements OnInit, OnDestroy {
  @Input() isEditMode: boolean = true;

  public form: FormGroup;
  private _error: string;
  private _classification: FormClassification;
  private _id: number;
  public chosenColor: string;
  public currentLang: string;
  public classificationTypes: LookupModel[] = [];
  

  private _subscription = new Subscription();
  constructor(
    private _assessmentClassificationService: ClassificationService,
    private _route: ActivatedRoute,
    private _snackbar: MatSnackBar,
    public readonly _translateService: TranslateService,
    private _router: Router) {
    this.currentLang = _translateService.currentLang;
  }


  public ngOnInit(): void {
    // needed to make sure that the context when calling back is the current component context 
    this.goBack = this.goBack.bind(this);

    this.createFormGroups();

    this._subscription.add(
      this._route.params.subscribe((params) => {
        this._id = params['id'];
        if (this._id && this._id > 0) {
          this.getData();
        }
      })
    );

    this._subscription.add(
      this._route.data.subscribe((params) => {
        this.classificationTypes = params.data.ClassificationType;
      })
    );
  }

  private createFormGroups(): void {
    this.form = new FormGroup({
      'id': new FormControl({ value: this._classification?.id, disabled: true }),
      'classificationType': new FormControl({ value: this._classification?.type, disabled: false }, Validators.required),
      'riskLevel': new FormControl({ value: this._classification?.riskLevel, disabled: false }, [Validators.required, CustomValidators.noWhitespaceValidator]),
      'minValue': new FormControl({ value: this._classification?.minValue, disabled: false }, Validators.required),
      'maxValue': new FormControl({ value: this._classification?.maxValue, disabled: false }, Validators.required),
      'riskColor': new FormControl({ value: this._classification?.riskColor, disabled: false }, Validators.required),
    });
  }

  private getData(): void {
    if (this._id && this._id > 0 && this.form) {
      this._assessmentClassificationService.getObject(this._id).subscribe({
        next: (responses) => {
          if (responses && responses.body) {
            this._classification = responses.body;
            this.chosenColor = this._classification.riskColor;
            this.form.patchValue(this._classification);
          } else {
            this._snackbar.open(this._translateService.instant('classification.error.could-not-load'), null, { duration: 3000 });
            this._id = undefined;
          }
        }
        ,
        error: (_) => {
          this._snackbar.open(this._translateService.instant('classification.error.could-not-load'), null, { duration: 3000 });
          this._id = undefined;
        }
      });
    }
  }

  private validateFormForSave(): string {
    let error: string = undefined;
    formHelper.trimAllFormValues(this.form);
    if (this.form && this.form.valid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      let rawValue = this.form.getRawValue();
    } else {
      error = this._translateService.instant('global.form-invalid');
    }
    return error;
  }

  public saveClassification(): void {
    let error = this.validateFormForSave();
    if (!error) {
      this.executeSave();
    }
    if (error != undefined) this._snackbar.open(this._translateService.instant(error), null, { duration: 3000 });
  }

  private executeSave(): void {
    let rawValue = this.form.getRawValue();
    this._classification = rawValue;
    this._assessmentClassificationService.postObject(this._classification).subscribe(response => {
      if (response) {
        this._classification = response.body;
        this.form.patchValue(this._classification);
        this._id = this._classification.id;
        this._snackbar.open(this._translateService.instant('classification.success.registered'), null, { duration: 3000 });
      }
    }, error => {
      this._snackbar.open(this._translateService.instant('classification.error.risk-level-existing'), null, { duration: 3000 });
    });
  }

  public goBack(): void {
    this._router.navigate([`/org-settings/classification`]);
  }

  public updateColor(event: any): void {
    this.form.get('riskColor').setValue(this.chosenColor);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}

