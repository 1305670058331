import { Inject, Injectable, Injector } from '@angular/core';
import { ISurveyAuthenticationService } from '../../siren_survey_web/src/app/shared/services/interfaces/authentication.interface';
import { MyHttpHandler } from '../../siren_survey_web/src/app/services/shared/http-handler.service';
import { SettingsService } from '../../siren_survey_web/src/app/shared/services/settings.service';
import { LookupService } from '../../siren_survey_web/src/app/services/lookups/lookup.service';

@Injectable({
    providedIn: 'root'
  })
export class MarchLookupService extends LookupService{

	constructor(injector: Injector, public settingsService: SettingsService,private lookUpService: LookupService,
      protected authService: ISurveyAuthenticationService, http: MyHttpHandler) {
		super(injector,settingsService, authService, http);
  }

  public async getOrganizationList(): Promise<any> {
    const response = await this.lookUpService.getLookupObjectsService(["MarchOrganization"], false, true).toPromise();
    const organizationList = response && response.body ? response.body["MarchOrganization"] : undefined;
    return organizationList;
  }
}
