
<div class="file_upload_wrapper" [ngClass]="{'file_upload_wrapper_error field-invalid' : validatorErrorDisplayed }">
  <input class="hidden" #uploadPhoto (change)="fileSelected($event)" type="file" [accept]="validateFilesExtension ? allowedFilesExtensions : '*/*'">
  <div class="upload_content">
    <div class="upload_content_files">
      <h4 class="text-placeholder">{{placeholdertext}}</h4>
      <button *ngIf="!disabled && (canUpload || !documentsList || !documentsList.length || isANewDoc())" class="upload_btn" (click)="addFile($event)" mat-raised-button type="input">
        <span> {{ 'siren-admin-translation.generic.upload' | translate}}</span>
        <mat-icon class="material-icons-outlined">upload</mat-icon>
      </button>
    </div>
    <div *ngFor="let d of documentsList,let i=index">
      <div *ngIf="!d.toDelete" class="files file-titles files-container">
        <mat-icon *ngIf="!disabled && (canDeleteBlob || isANewDoc())" (click)="deleteFile(d.key,i)" class="pointer">clear</mat-icon>
        <div [ngClass]="{'new-file': !d.key, 'link' : d.key && canDownload}" class="files" href="javascript:;" (click)="clickDownloadFile(d)">{{d.fileName}}</div>

        <ng-container *ngIf="d.recentlyUploaded">
          <div *ngIf="d.uploadFail" class="hasError error">{{ 'siren-admin-translation.form_errors.upload_error' | translate}}</div>
          <div *ngIf="!d.uploadFail" class="success">{{ 'siren-admin-translation.generic.upload_success' | translate}}</div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div *ngIf="validatorErrorDisplayed" class="error">
  {{ 'form_errors.field_required' | translate }}
</div>
