
<div class="container">
  <div class="profile-action-buttons">
      <button class="btn btn-expand" type="button" mat-button (click)="accordion.openAll(); $event.stopPropagation();">{{ 'global.expand-all' | translate }}</button>
      <button class="btn btn-collapse" type="button" mat-button (click)="accordion.closeAll(); $event.stopPropagation();">{{ 'global.collapse-all' | translate }}</button>
  </div>
  <mat-accordion class="profile-headers-align" multi >
    <mat-expansion-panel [expanded]="true" (opened)="true" >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">{{'siren-admin-translation.admin_module.profile.change_password' | translate}} <app-info-tooltip class="tooltipMargin" message="{{passwordComplexityMessage}}"></app-info-tooltip></mat-panel-title>
      </mat-expansion-panel-header>
      <app-change-password></app-change-password>
    </mat-expansion-panel>

    <!-- <mat-expansion-panel [expanded]="true" (opened)="true" >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">{{'siren-admin-translation.admin_module.profile.change_organization' | translate}}</mat-panel-title>
      </mat-expansion-panel-header>
      <app-change-organization></app-change-organization>
    </mat-expansion-panel> -->

  </mat-accordion>

</div>

