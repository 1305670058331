import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginFormComponent } from './component/login-form/login-form.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { PublicResetPasswordComponent } from './component/public-reset-password/public-reset-password.component';
import { PublicResetPasswordSubmissionComponent } from './component/public-reset-password-submission/public-reset-password-submission.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateResolver } from '@siren-survey/app/shared/services/translation/translate-resolver.service';

const routes: Routes = [
	{ path: 'login', component: LoginFormComponent, pathMatch: 'full' },
	{ path: 'reset-password', component: PublicResetPasswordComponent,  resolve: { translations: TranslateResolver } , pathMatch: 'full' },
	{ path: 'reset-password-submission/:otp', component: PublicResetPasswordSubmissionComponent, pathMatch: 'full' ,  resolve: { translations: TranslateResolver }},
	{ path: '', loadChildren: () => import('./component/landing-page/landing.module').then(m => m.LandingModule), canLoad: [AuthGuard]},
	{ path: '**', redirectTo: '/login' }
];


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [RouterModule.forRoot(routes),
    TranslateModule.forChild({
      defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			},
			extend: true
		}),], //, { onSameUrlNavigation: 'reload' } )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
