import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { ActivityCategory } from 'src/app/models/admin/program/activityCategories/activityCategory';
import { ActivityCategoryList } from 'src/app/models/admin/program/activityCategories/activityCategoryList';

@Injectable({
  providedIn: 'root'
})
export class ActivityCategoriesService extends BaseService<ActivityCategoryList, ActivityCategory> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/activity/activity-categories", http, injector);
  }

  enableDisableActivity(activityId: number,enabled : boolean): Observable<HttpResponse<ActivityCategory>> {
    return this.http.put<ActivityCategory>("/v1/activity/activity-categories/enable/" + activityId+"?enable="+enabled, null);
  }
}

