import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBank } from '../../../models/question/question-bank';
import { LookupModel } from '../../../models/shared/lookup.model';
import { LookupService } from '../../../services/lookups/lookup.service';
import { QuestionBankService } from '../../../services/question-bank.service';
import { DialogService } from '../../../services/shared/dialog.service';
import { OrderHandler } from '../../../utils/order-handler.utilities';
import { FormControllerGeneratorService } from '../../form-controller-generator.service';
import { DropdownComparatorWithBrowserListener } from '../../shared/component-base/dropdown-comparator-with-browser';
import { SettingsService } from '../../../shared/services/settings.service';
import { EditQuestionNotification } from '../../../models/question/survey-question';
import { OpenLinkedSurveyQuestionService } from '../../../services/shared/open-linked-survey-question.service';

@Component({
  selector: 'app-question-bank-form-embedded',
  templateUrl: './question-bank-form-embedded.component.html',
  styleUrls: ['./question-bank-form-embedded.component.sass']
})
export class QuestionBankFormComponentEmbedded extends DropdownComparatorWithBrowserListener implements OnInit {
  @Input() questionBankForm: FormGroup;
  @Input() isEditMode: boolean = true;
  @Output() questionChangeEventEmitter = new EventEmitter<boolean>();
  @Input() canEditQuestion: boolean = true;
  @Output() editQuestionNotificationEmitter = new EventEmitter<EditQuestionNotification>();
  @Input() surveyId: number;

  @Input() answerTypes: LookupModel[];
  public editable: boolean = false;
  public id: number;
  conditionsArr = new FormArray([]);
  private questionBank: QuestionBank;
  public error:string;

  private beforeSaveQuestionBank: QuestionBank;
  // saveQuestionsOnFocusOut: boolean = false;

  constructor(private questionBankService: QuestionBankService, private route : ActivatedRoute, private openLinkedSurveyQuestionServicesService: OpenLinkedSurveyQuestionService, private settingsService: SettingsService,
              public lookupService: LookupService, private dialogService: DialogService, public readonly _translateService: TranslateService) {
              // private elementRef: ElementRef) {
    super();
    // this.saveQuestionsOnFocusOut = settingsService && settingsService.settings && settingsService.settings.saveQuestionsOnFocusOut != undefined ? settingsService.settings.saveQuestionsOnFocusOut : false;
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  initializeFormGroup(){
    if(!this.questionBankForm){
      this.questionBankForm = FormControllerGeneratorService.createQuestionBankFormGroup(this.isEditMode);
      if(this.id != undefined){
        this.questionBankService.getObject(this.id).subscribe(question =>{
          this.questionBank = question.body;
          this.beforeSaveQuestionBank = this.questionBank;
          this.editable = this.questionBank?.editable ? this.isEditMode : false;
          this.questionBankForm = FormControllerGeneratorService.createQuestionBankFormGroup(this.editable);
          this.fillFormGroup();
        });
      }
    } else{
      this.questionBank = this.questionBankForm.getRawValue();
      this.beforeSaveQuestionBank = this.questionBank;
      this.editable = this.questionBank?.editable;
      this.id = this.questionBankForm.get('id').getRawValue();
      if(this.id != undefined){
        this.disableEditMode(); //this.editable = true once selected while onload editable = false
      } else{
        this.enableEditMode();
      }
    }
    this.canEditQuestion = this.editable;
  }

  fillFormGroup(){
    this.questionBankForm.patchValue(this.questionBank);
    (this.getAnswersFormArray() as FormArray)?.reset();
    (this.getAnswersFormArray() as FormArray)?.clear();
    this.questionBank.answers.forEach(currAnswer =>{
      let currAnsFormGroup = FormControllerGeneratorService.createAnswerFormGroup(this.editable);
      currAnsFormGroup.patchValue(currAnswer);
      (this.getAnswersFormArray() as FormArray)?.push(currAnsFormGroup);
    });
  }

  getAnswersFormArray(): FormArray{
    return this.questionBankForm ? this.questionBankForm.controls['answers'] as FormArray : undefined;
  }

  addAnswer(){
    let answersFormArray = this.getAnswersFormArray() as FormArray;
    if(answersFormArray){
      let answerFormGroup = FormControllerGeneratorService.createAnswerFormGroup(this.isEditMode);
      if(answerFormGroup) answersFormArray.push(answerFormGroup);
    }
  }

  validateQuestionBankForm(): string{
    this.error = undefined;
    let hasError = false;

    if(!this.questionBank?.question || this.questionBank?.question?.trim() == "") {
      this.error= this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-question');
      hasError = true;
    }
    if(!this.error && (!this.questionBank?.answerType || this.questionBank?.answerType?.key == undefined || this.questionBank?.answerType?.key?.trim() == "")) {
      this.error= this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-answerType');
      hasError = true;
    }
    if(!this.error){
      if(this.questionBank?.answerType?.key != 'MULTI_SELECT' && this.questionBank?.answerType?.key != 'SINGLE_SELECT'){
        this.questionBank.answers = [];
      } else if(this.questionBank?.answers?.length <= 0){
        this.error = this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-answers');
        hasError = true;
      }
    }
    if(!this.error && this.questionBank.answerType.key != 'MULTI_SELECT' && this.questionBank.answerType.key != 'SINGLE_SELECT') this.questionBank.gradeByAnswer = false;
    if(!this.error && this.questionBank?.answerType?.key == 'GRADE_ONLY'){
      this.questionBank.gradeByAnswer = false;
      this.questionBank.withOther = false;
    }
    if(!this.error){
      for(let answer of this.questionBank.answers){
        if(answer && this.questionBank.gradeByAnswer && answer?.grade == undefined){
          this.error = this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-grade');
          hasError = true;
          break;
        } else if(answer && (answer.answer == undefined || answer.answer.trim() == "")){
          this.error = this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-answer-value');
          hasError = true;
          break;
        }
      }
    }
    if(hasError && !this.error) this.error = "Invalid Form";
    return this.error;
  }

  save(){
    this.error = "";
    this.questionBankForm.markAllAsTouched();
    this.questionBankForm.updateValueAndValidity();
    if(this.questionBankForm && this.questionBankForm.valid){
      this.executeSave();
    }
  }

  cancel(){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.cancel-question'),
      "message": this._translateService.instant('siren-survey-translation.popup-module.messages.question-bank-cancel'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response) {
        this.questionBank = this.beforeSaveQuestionBank;
        this.error = "";
        this.fillFormGroup();
        this.executeClose(-1);
      }
    });
  }

  executeSave(): void{
    OrderHandler.adaptOrderField(this.questionBankForm.controls['answers'] as FormArray);
    this.beforeSaveQuestionBank = this.questionBank;
    this.questionBank  = this.questionBankForm.getRawValue();
    this.questionBank.question = this.questionBank.question != null ?  this.questionBank.question.trim() : "";
    if(this.questionBank){
      this.error = "";
      this.validateQuestionBankForm();
      if(!this.error){
        if(this.questionBankForm && this.questionBankForm.valid){
          if(!this.error){
            this.questionBank = this.clearAnswerGradesIfNeeded(this.questionBank);
            let isCreation = this.questionBank.id == undefined || this.questionBank.id == 0;
            this.questionBankService.saveQuestionBank(this.questionBank).subscribe( response =>{
              if(response){
                this.questionBank = response.body;
                // if(isCreation && this.surveyId != undefined) this.suveyIntegrationService.bindQuestionToTopic(this.questionBank.id, this.surveyId);
                this.beforeSaveQuestionBank = this.questionBank;
                // this.questionBankForm.controls["id"].patchValue(response.id);
                this.questionBankForm.patchValue(this.questionBank);
                this.questionChangeEventEmitter.emit(true);
                this.executeClose(this.questionBank?.id);
              } else{
                this.error = "Could not create object!";
              }
            }, error =>{
              this.error = error.error.message;
            });
          }
        } else{
          this.error = "Unable to create question. Please refresh the page. If the problem persists contact your system administrator.";
        }
      } else{

      }
    } else{
      this.error = "Unable to create question. Please refresh the page. If the problem persists contact your system administrator.";
    }
  }

  clearAnswerGradesIfNeeded(questionBank: QuestionBank): QuestionBank{
    if(questionBank && !questionBank.gradeByAnswer && questionBank.answers && questionBank.answers.length > 0){
      questionBank.answers.forEach(answer =>{
        answer.grade = null;
      });
    }
    return questionBank;
  }

  enableEditMode(){
    if(this.editable){
      this.isEditMode = true;
      this.questionBankForm.enable();
    }
  }

  disableEditMode(){
    this.isEditMode = false;
    this.questionBankForm.disable();
  }

  executeClose(event: number): void{
    let currId = this.id != undefined && this.id > 0 ? this.id : (event != undefined && event > 0 ? event : undefined);
    this.editQuestionNotificationEmitter.emit({
      questionId: currId,
      sectionUuid: null,
      decreaseEditCount: this.isEditMode,
      encreaseEditCount: false,
      addToUnsavedQuestionList: event != undefined && event > 0,
      removeFromUnsavedQuestionList: false
    });
    this.questionChangeEventEmitter.emit(true);
    this.isEditMode = false;
    this.questionBankForm.disable();
  }

  canDeleteAnswer() : boolean{
    return this.isEditMode;
  }

  deleteAnswer(index: number){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-answer'),
      "message": this._translateService.instant('siren-survey-translation.popup-module.messages.question-bank-delete'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response) (this.getAnswersFormArray() as FormArray).removeAt(index);
    });
  }

  moveUpAnswer(index: number) {
    OrderHandler.moveUp(index, this.getAnswersFormArray() as FormArray);
  }

  moveDownAnswer(index: number) {
    OrderHandler.moveDown(index, this.getAnswersFormArray() as FormArray);
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  openLinkedSurveysTable(id: number){
    this.openLinkedSurveyQuestionServicesService.openLinkedSurveysTable(undefined,this.id);
  }

  onKeyDownEvent(event: any){
    if(event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }

 /* private isMouseInside = false;
  onMouseEnter(){
    this.isMouseInside = true;
    console.log('Enter');
  }
  onMouseLeave(){
    this.isMouseInside = false;
    console.log('Leave');
  }
  @HostListener('document:click', ['$event.target'])
  onClickAndEnter(targetElement: HTMLElement) {
    if (!this.isMouseInside && !this.elementRef.nativeElement.contains(targetElement)) {
      console.log('OUTSIDE');
      if(this.saveQuestionsOnFocusOut) this.save();
    } else{
      console.log('INSIDE');
    }
  }
  onQuestionFormFocusOut(){
    if(this.saveQuestionsOnFocusOut) this.save();
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement) {
    let isSelectionClick = targetElement.className == 'mat-option-text';
    const clickedInside = isSelectionClick || this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      console.log('OUTSIDE');
      if(this.saveQuestionsOnFocusOut) this.save();
    } else{
      console.log('INSIDE');
    }
  }*/
}
