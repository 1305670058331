import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { SessionService } from '../shared/session.service';
import { MarchModuleEnum, MarchPermissionEnum, UserModule } from '../../shared/enum/user-role.enum';
import { HttpRequestOptions } from '@siren-survey/app/shared/models/http-request-options.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { MarchMyHttpHandler } from 'src/app/shared/services/march-http-handler.service';
import { LoginResponse } from 'src/app/models/auth/login-response.model';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ISurveyAuthenticationService } from '@siren-survey/app/shared/services/interfaces/authentication.interface';
import { IAdminAuthenticationService } from '@siren-auth/app/shared/services/adminAuthentication.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements ISurveyAuthenticationService, IAdminAuthenticationService{
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  constructor(private sessionService: SessionService, private settingsService: SettingsService, private router: Router,
    private http: HttpClient) {
  }
  // public getUserAccessRights(): string[] {
  //   throw new Error('Method not implemented.');
  // }
  // public isAdminUser(): boolean {
  //   throw new Error('Method not implemented.');
  // }
  // public accessRightValidation(module: string): boolean {
  //   throw new Error('Method not implemented.');
  // }
  // public getUserRole(): string {
  //   throw new Error('Method not implemented.');
  // }

  public getSessionService(): SessionService{
    return this.sessionService;
  }

  public clearSession(){
    if(this.sessionService != undefined) this.sessionService.clearSession();
  }

  public logout(){
    if(this.sessionService != undefined) this.sessionService.logout();
  }

  public getAccessToken() : string {
    return this.sessionService && this.sessionService.isActiveSession() ? this.sessionService.getActiveSession().getAccessToken() : undefined;
  }

	public checkIfUserIsAvailable(): boolean {
    return this.sessionService && this.sessionService.isActiveSession();
	}

  public getUserAccessRightStringList(): String[]{
    return this.sessionService?.getActiveSession()?.getAuthoritiesStringList();
  }

  // NOT USED YET
  public validateRightCombination(combinationsList: string[][], applyAndOperatorOnCombination: boolean): boolean{
    let isValid = false;
    if(combinationsList != undefined && combinationsList.length > 0){
      let userRights = this.getUserAccessRightStringList();
      if(userRights && userRights.length > 0){
        let combinationsAreValid = true;
        for(let currCombination of combinationsList){
          if(!isValid){
            if(combinationsAreValid){
              let statementsAreValid: boolean = true;
              for(let currStatement of currCombination){
                if(statementsAreValid) statementsAreValid = userRights.includes(currStatement);
                if(!statementsAreValid) break;
              }
              if(applyAndOperatorOnCombination) {
                combinationsAreValid = statementsAreValid;
              } else if(statementsAreValid){
                isValid = true;
              }
            }
            if(combinationsAreValid) break;
          }
        }
      }
    } else{
      isValid = true;
    }
    return isValid;
  }

  // public accessRightValidation(module: string): boolean {

  //   let hasAccess = true;
  //   if (module) {
  //     let authorities = this.sessionService.getAuthorities();
  //     let splitAuthority = [];
  //     if (module.includes('.')) {
  //       splitAuthority = module.split('.');
  //     } else {
  //       splitAuthority = [module];
  //     }
  //     for(let authority of splitAuthority){
  //       const ind = authorities?.findIndex(i => i.key === authority);
  //       if (ind >= 0) {
  //           authorities = authorities[ind].authorities;
  //       } else {
  //         hasAccess = false;
  //           break;
  //       }
  //     }
  //   } else {
  //     hasAccess = false;
  //   }
  //   return hasAccess;
  // }

  public userModuleAccessRightValidation(module: UserModule): boolean {
    let hasAccess = false;
    if (module) {
      let authorities = this.sessionService.getAuthorities();
      const ind = authorities?.find(i => i.key === module.module)?.authorities?.findIndex( b => b.key == module.key);
      hasAccess = ind >= 0;
    }
    return hasAccess;
  }

  public isModuleAccessible(module: string): boolean {
    let hasAccess = false;
    if (module) {
      let authorities = this.sessionService.getAuthorities();
      const ind = authorities?.findIndex(i => i.key === module);
      hasAccess = ind >= 0;
    }
    return hasAccess;
  }

  public getPermissionEnumeration(){
    return this.marchPermissionEnum;
  }

  public refreshToken(): Observable<LoginResponse> { // LoginBodyResponseModel
    if(this.sessionService && this.sessionService.getActiveSession()){
      const refreshToken: string = this.sessionService.getActiveSession().getRefreshToken();
      const userId: number = this.sessionService.getActiveSession().getUserId();
      if(refreshToken !== '' && userId != undefined) {
        const requestOptions = new HttpRequestOptions();
        requestOptions.headers = new HttpHeaders();
        requestOptions.headers = requestOptions.headers.set('Content-Type', 'application/json; charset=utf8').set('replace-header', 'true');
        const body = { token: refreshToken, userId: userId };
        return this.http.post<LoginResponse>(this.settingsService.settings.backendBaseUrl + "/v1/auth/refresh-token ", body, requestOptions).pipe(tap(data => {
            this.sessionService.refreshSession(data,this.sessionService?.getActiveSession()?.user_profile);
        }, error => {
          // refresh token expired and user has switched to another screen => should hide spinner
          // if (this.spinnerService.getNumberOfPendingScreenLoading() > 0) {
          //   this.spinnerService.popScreenLoading();
          // }
          return throwError(error);
        }));
      } else {
        this.router.navigate(['/login']);
        return of();
      }
    }
	}

  public requestResetPassword(username: String): Observable<String>{
    let requestBody = { "username": username };
    return this.http.post<String>(this.settingsService.settings.backendBaseUrl + "/v1/auth/reset_password", requestBody);
  }

  public hasDataAdminRight(): boolean{
    return this.isModuleAccessible(this.marchModuleEnum.DATA_ADMIN.module);
  }
}
