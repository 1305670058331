import { ChildMenuItemModel, MenuItemModel } from "../shared/models/menu-item.model";
import { MarchPermissionEnum, UserRolesEnum } from "../shared/models/user-role.enum";
import { AdminModule, AdminRoleModule } from "./admin.models";

export const menuItems: MenuItemModel[] = [
    new MenuItemModel("lookups", "assets/icons/toolbar-search", "admin_module.lookups", "/admin/lookups"),
    new MenuItemModel("users", "assets/icons/toolbar-users", "admin_module.user_module.title", "/admin/users", [
        new ChildMenuItemModel("admin_module.user_module.roles.title", "admin/users/roles"),
        new ChildMenuItemModel("admin_module.user_module.users.title", "admin/users/users")
    ]),
    new MenuItemModel("login", "assets/icons/toolbar-logout", "generic.logout", "login")
];

export const AdminModules: AdminModule[] = [
    {
        name: MarchPermissionEnum.USERS.module,
        path: "/admin/users"
    }
];

export const AdminRoleModules: AdminRoleModule[] = [
    {
        role: UserRolesEnum.SYSTEM_ADMIN,
        modules: [
            MarchPermissionEnum.USERS.module
        ]
    }
];
