import { Injector } from "@angular/core";
import { ItemNode } from "../models/tree-checklist.model";

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
export class ChecklistBaseClass {

    constructor(
        protected injector: Injector
    ) {
    }

    // Build the tree nodes from Json object. The result is a list of `ItemNode` with nested
    //     file node as children.
    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `ItemNode`.
     */
    buildFileTree(obj: { [key: string]: any }, level: number, childrenKey: string, displayKey: string): ItemNode[] {
        return Object.keys(obj).reduce<ItemNode[]>((accumulator, key) => {
            const value = obj[key][childrenKey];
            const node = new ItemNode();
            node.item = obj[key][displayKey];
            node.key = obj[key]['key']

            if (value != null) {
                if (typeof value === 'object') {
                    node.children = this.buildFileTree(value, level + 1, childrenKey, displayKey);
                } else {
                    node.item = value;
                }
            }
            return accumulator.concat(node);
        }, []);
    }
}