import { Injectable, Injector } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../../services/shared/base-service';
import { MyHttpHandler } from '../../services/shared/http-handler.service';
import { BlobObject } from '../../models/blob/blob';

@Injectable({
  providedIn: 'root'
})
export class BlobService extends BaseService<BlobObject, BlobObject> {

  constructor(
    http: MyHttpHandler,
    protected injector: Injector,
    ){
    super("/v1/blob", http, injector);
  }

  postUploadBlob(formData: FormData): Observable<HttpResponse<BlobObject>> {
    return this.http.post("/v1/blob/upload", formData)
  }

  getBlobObjects(params: string, providedUrl?: string): Observable<HttpResponse<BlobObject>> {
    let url = providedUrl != undefined && providedUrl.trim() != '' ? providedUrl : '/v1/blob';
    return this.http.get<any>(url + '?' + params);
  }

  deleteBlobObject(uuid: string): Observable<HttpResponse<String>> {
    return this.http.delete<String>("/v1/blob/" + uuid);
  }

  downloadBlobObjects(uuid: string): Observable<HttpResponse<any>> {
    return this.http.get("/v1/blob/"+uuid, { responseType: 'blob' })
  }
}
