import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableAction } from '../../../models/shared/table-action';
import { TableFilterOutput } from '../../../models/shared/table-filter.model';
import { Subject } from 'rxjs';
import { MenuActionEmittedObject } from '../../../models/shared/datatable-objects.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from '../../../services/shared/base-service';

@Component({
  templateUrl: 'responsive-list-consumer-base.html'
})
export abstract class ResponsiveListConsumerBaseComponent<T,U> implements OnInit {

  protected reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  protected filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  protected _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  protected _filteringPredicate : (data: any, filter: string) => boolean;


  getDeleteBtnLabel(): string{
    return this._translateService.instant('siren-survey-translation.global.delete');
  }
  getCloneBtnLabel(): string{
    return this._translateService.instant('siren-survey-translation.global.clone');
  }

  constructor(public service : BaseService<T,U>, protected router: Router, protected route : ActivatedRoute, protected readonly _translateService: TranslateService) {
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        // case 'productStatus': return item.productStatus ? item.productStatus.name : '';
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  ngOnInit(): void {
  }

  protected abstract getFormRelativeUrlPath();

  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.EDIT_MODE){
      this.router.navigate([event.id], {relativeTo: this.route});
    } else if(event.mode == TableAction.CREATE_MODE) {
      this.router.navigate([this.getFormRelativeUrlPath()], {relativeTo: this.route});
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
        this.service.deleteObject(menuAction.object.id).subscribe( response =>{
          this.reloadEventSubject.next(true);
        }, error =>{
          console.error(error.error.message);
        });
      }
    }
  }
}
