<form [formGroup]="form" (ngSubmit)="saveLegalCaseStatus()">
  <div class="row container">
    <div class="col-12">
      <mat-form-field class="full-width-element">
        <mat-label class="" >{{'legal-case.fields.legal-case-status' | translate}}</mat-label>
        <mat-select class="" formControlName="legalCaseStatus" [compareWith]="objectComparisonFunction">
          <mat-option *ngFor="let status of legalCaseStatuses" [value]="status" >
            {{ status.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-sm-6 col-12">
      <button type="submit" [ngClass]="_translateService.currentLang == 'ar' ? 'btn-submit-rtl' : '' " class="btn btn-submit">
        <span style="white-space: nowrap;">{{'global.replace' | translate}}</span>
      </button>
    </div>
  </div>
</form>
