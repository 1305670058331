import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { Subject } from 'rxjs';
import { Achievement } from 'src/app/models/admin/program/achievement/achievement';
import { AchievementService } from 'src/app/services/admin/program/achievement.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchModuleEnum, MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { AchievementFormComponent } from '../achievement-form/achievement-form.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-achievement-table',
  templateUrl: './achievement-table.component.html',
  styleUrls: ['./achievement-table.component.sass']
})
export class AchievementTableComponent implements OnInit {
  tableTitleLabel: string;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;
  canAdd: boolean = false;
  hasListAccess: boolean = false;
  predefinedFilter: Map<string, any>;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  _booleanIconColumnDisplayList: string[] = [
    "enabled","published"
  ]

  _tableColumns  : DataTableModel<Achievement> []= [
    {  columnDef: 'name', headerTranslationPath: "achievement.fields.name", header: this._translateService.instant('achievement.fields.name'),  cell: (element: Achievement) => `${element.name}`, width: undefined},
    {  columnDef: 'creationDate', type: TableFilterTypeEnum.Date, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "achievement.fields.creation-date", header: this._translateService.instant('achievement.fields.creation-date'),  cell: (element: Achievement) => `${element.creationDate ? this.datePipe.transform(element.creationDate, 'dd / MM / yyyy') : "-"}`, width: undefined},
    {  columnDef: '_menu_inline', headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "name", translationPath: "achievement.fields.name", displayName: "Name", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "../../../../assets/icons/delete-icon.svg", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_LOOKUP) , accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ])},
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string{
    return this._translateService.instant('global.delete');
  }

  constructor(public service : AchievementService, private router: Router, private route : ActivatedRoute, private readonly _translateService: TranslateService,
    public lookupService: LookupService,private dialog: MatDialog, public authService: AuthenticationService, private datePipe: DatePipe,
    public dialogService: DialogService, private snackbar: MatSnackBar) {
    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_LOOKUPS);
    this.hasListAccess = this.authService.isModuleAccessible(this.marchModuleEnum.LOOKUPS_MGT.module);

  }

  ngOnInit(): void {
  }

  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.EDIT_MODE){
      const dialogRef = this.openChangeStatusFormWindow(event.id);
      dialogRef.afterClosed().subscribe((achievement: Achievement) => {
        this.reloadEventSubject.next(true);
      });
    } else if(event.mode == TableAction.CREATE_MODE) {
      const dialogRef = this.openChangeStatusFormWindow();
      dialogRef.afterClosed().subscribe((achievement: Achievement) => {
        this.reloadEventSubject.next(true);
      });
      // this.router.navigate(["create"], {relativeTo: this.route});
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  openChangeStatusFormWindow(id?: number): any{
    let input: AchievementFormInput = new AchievementFormInput(id);
    const activityExecutionFormDialog = this.dialog.open(AchievementFormComponent, {
      "width": '6000px',
      "maxWidth": '80vw',
      "maxHeight": '80vh',
      "autoFocus": false,
      data: input,
      direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });
    return activityExecutionFormDialog;
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
        if(this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_LOOKUP)){
          this.dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-achievement'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
          .subscribe(response=>{
            if(response){
              this.service.deleteObject(menuAction.object.id).subscribe( response =>{

                this.reloadEventSubject.next(true);
              }, error =>{
                let message = error && error.error && error.error.message.includes("ConstraintViolationException")
                  ? this._translateService.instant('error.record-depending-error')
                  : this._translateService.instant('error.record-error');
                this.snackbar.open(message, null, { duration: 3000 });
              });
            }
          });
        }
      }
    }
  }

}

export class AchievementFormInput{
  constructor(public achievementId: number) {}
}
