import { Component, Input } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.sass']
})
export class InfoTooltipComponent {
  faInfo = faInfoCircle;
  @Input() message: String;
  @Input() iconColor: String;
  constructor(public translateService: TranslateService) { }

}
