import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Organization } from '../../models/admin/organization/organization';
import { SettingsService } from 'src/app/shared/services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class SurveySelectionService  extends BaseService<Organization, Organization> {

  constructor(http: MyHttpHandler, protected injector: Injector, private _settingsService: SettingsService){
    super(_settingsService.settings.surveyUrlPath, http, injector);
  }

}
