import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { ResponsiveListInlineEditObject, TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { ClassList } from '../../../../models/admin/program/class/classList';
import { ClassesService } from '../../../../services/admin/program/classes.service';
import { DatatableInlineEdit } from '@siren-survey/app/component/shared/datatable/datatable-inline-edit.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-classes-admin-table',
  templateUrl: './classes-admin-table.component.html',
  styleUrls: ['./classes-admin-table.component.sass']
})
export class ClassesAdminTableComponent extends DatatableInlineEdit implements OnInit {
  tableTitleLabel: string;

  lastCreatedId = 0;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  inlineEditEventSubject: Subject<ResponsiveListInlineEditObject> = new Subject<ResponsiveListInlineEditObject>();

  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;
  canAdd: boolean = false;
  hasListAccess: boolean = false;
  predefinedFilter: Map<string, any>;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  _booleanIconColumnDisplayList: string[] = [
    "enabled","published"
  ]

  _tableColumns  : DataTableModel<ClassList> []= [
    {  columnDef: 'name', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "class-type.fields.class-name", header: this._translateService.instant('class-type.fields.class-name'),  cell: (element: ClassList) => `${element?.name}`, width: undefined},
    {  columnDef: 'classType', type: TableFilterTypeEnum.LookupObject, lookupName: "ClassType", lookupsValueById: true, headerTranslationPath: "class-type.fields.class-type", header: this._translateService.instant('class-type.fields.class-type'),  cell: (element: ClassList) => `${element?.classType?.name}`, width: undefined},
    {  columnDef: 'creationDate', type: TableFilterTypeEnum.Date, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "class.fields.creation-date", header: this._translateService.instant('class-type.fields.creation-date'),  cell: (element: ClassList) => `${element.creationDate ? this.datePipe.transform(element.creationDate, 'dd / MM / yyyy') : "-"}`, width: undefined},
    {  columnDef: '_menu_inline', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "name", translationPath: "class.fields.class-name", displayName: this._translateService.instant('class-type.fields.class-name'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "classType.id", translationPath: "class-type.fields.class-type", displayName: this._translateService.instant('class-type.fields.class-type'), type: TableFilterTypeEnum.LookupObject, lookupName: "ClassType", lookupsValueById: true, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "creationDate", translationPath: "class.fields.creation-date", displayName: this._translateService.instant('class-type.fields.creation-date'), type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal , cssClass: 'col-xs-12 col-sm-6 col-md-4' },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.CLASS_UPDATE), accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ]) },
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg", accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ]), allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_CLASS) },
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  constructor(
    public service : ClassesService,
    private router: Router,
    private route : ActivatedRoute,
    private readonly _translateService: TranslateService,
    public lookupService: LookupService,
    public snackBar: MatSnackBar,
    private dialogService: DialogService,
    public authService: AuthenticationService,
    private datePipe: DatePipe) {
      super();
    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        case "classType": return item[property]?.name;
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        if(key && key == 'classType'){
           return currentTerm + data[key]?.name;
        } else{
          return currentTerm + data[key];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.CLASS_CREATE);
    this.hasListAccess = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.CLASS_LIST);

  }

  ngOnInit(): void {
  }

  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.CREATE_MODE) {
      this.lastCreatedId--;
      let editFormGroup = new FormGroup({
        'id': new FormControl({value: this.lastCreatedId, disabled: true}),
        'name': new FormControl({value:'', disabled: false}, [Validators.required, CustomValidators.noWhitespaceValidator]),
        // 'organization': new FormControl({value:'', disabled: false},[Validators.required]),
        'classType': new FormControl({value:'', disabled: false},[Validators.required]),
        'creationDate': new FormControl({value:'', disabled: true})
      })
      let inlineEditObject = new ResponsiveListInlineEditObject(this.lastCreatedId, this.lastCreatedId, editFormGroup,false);
      this.inlineEditEventSubject.next(inlineEditObject);
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }


  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
        this.dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-class'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
        .subscribe(response=>{
          if(response){
            this.service.deleteObject(menuAction.object.id).subscribe( response =>{
              this.reloadEventSubject.next(true);
            }, error =>{
              let message = error && error.error && error.error.message.includes("ConstraintViolationException")
                ? this._translateService.instant('error.record-depending-error')
                : this._translateService.instant('error.record-error');

              this.snackBar.open(message, null, { duration: 3000 });
            });
          }
        });
      } else{
        this.handleInlineEditingRequests(menuAction,this.snackBar,this._translateService);
      }
    }
  }

  isInlineEditable(): boolean {
    return true;
  }

  getTableFormGroupModel(): FormGroup<any> {
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'name': new FormControl({value: undefined, disabled: false}, [Validators.required, CustomValidators.noWhitespaceValidator]),
      // 'organization': new FormControl({value: undefined, disabled: false},[Validators.required]),
      'classType': new FormControl({value: undefined, disabled: false},[Validators.required]),
      'creationDate': new FormControl({value: undefined, disabled: true})
    });
  }

  triggerInlineEditEventSubject(inlineEditObject: ResponsiveListInlineEditObject): void {
    if(this.inlineEditEventSubject) this.inlineEditEventSubject.next(inlineEditObject);
  }

  getTableService() {
    return this.service;
  }

  getValidForm(menuAction:MenuActionEmittedObject): boolean {
    menuAction.form.markAllAsTouched();
    menuAction.form.updateValueAndValidity();
    return menuAction.form.valid;
  }

  withCheckUnicity(): boolean{
    return true;
  }

  objectClassName(): string{
    return "Classes";
  }
}

