import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@siren-survey/app/shared/services/settings.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CustomValidators } from 'src/app/services/shared/validators.service';

@Component({
  selector: 'app-public-reset-password',
  templateUrl: './public-reset-password.component.html',
  styleUrls: ['./public-reset-password.component.sass']
})
export class PublicResetPasswordComponent implements OnInit {
  form: FormGroup;
  error: string;
  success: string;
  // loginSubscription: Subscription;
  invalidLogin = false;
  public showPassword = false;

  constructor(private authService: AuthenticationService, private router: Router, private readonly _translateService: TranslateService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.form = new FormGroup({
      username: new FormControl(null,  [Validators.required, CustomValidators.noWhitespaceValidator])
    });
  }

  submit() {
    this.error = "";
    this.success = "";
    if (this.form && this.form.valid) {
      let username: String = this.form.get("username").getRawValue();
      if(this.isStringEmpty(username)) {
        this.error = this._translateService.instant('siren-admin-translation.admin_module.reset_password.missing_values');
      } else {
          this.authService.requestResetPassword(username).subscribe(response => {
            this.initForm();
            this.success = this._translateService.instant('siren-admin-translation.admin_module.reset_password.success');
          }, error => {
            // this.error = "Something went wrong";
            this.error = this._translateService.instant('siren-admin-translation.admin_module.reset_password.something_wrong');
          });
      }
    }
  }

  isStringEmpty(value: String): boolean{
    return value == undefined || value.trim() == "";
  }

  public navigateTo(path: String) {
    this.router.navigate([path]);
  }
}
