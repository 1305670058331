import { Component, Input, OnChanges, QueryList, Self, SimpleChanges, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FrameworkHelper } from '../../helpers/framework.helper';
import { DocumentModel } from '../../models/document.model';
import { FieldConfigModel, FieldType } from '../../models/fields-config.model';
import { UploadFieldComponent } from '../upload-field/upload-field.component';

@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.sass'],
})
export class CustomFieldComponent implements ControlValueAccessor {
  fieldTypeEnum = FieldType;
  canDownload = true;
  canUpload = true;
 



  @Input() field: FieldConfigModel;
  @Input() lookups: any[];

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  writeValue(obj: any): void {
  }
  registerOnChange(fn: any): void {

  }
  registerOnTouched(fn: any): void {

  }

}