
<div class="main-container">

  <!-- FLOATING SIDE NAV CONTENT -->
  <div id="mySidenav" class="sidenav" [ngClass]="activeLanguage == 'ar' ? 'sidenav-ar' : '' "   *ngIf="!_isMobile">
    <div class="sidenav-content" [ngClass]="activeLanguage == 'ar' ? 'sidenav-content-ar' : '' ">
      <div>
        <ul class="navbar-nav mr-auto">
          <div *ngIf="isMenuPerSurveyType">
            <li class="sidenav-item" *ngFor="let surveyType of surveyTypes" [ngClass]="isActiveRoute('/' + surveyType.id) ? 'active-route' : '' ">
              <img [src]="getAssetsUrl('assets/icons/menu/survey-icon-black.svg')" *ngIf="isActiveRoute('/' + surveyType.id)" class="menu-icon">
              <img [src]="getAssetsUrl('assets/icons/menu/survey-icon-white.svg')" *ngIf="!isActiveRoute('/' + surveyType.id)" class="menu-icon">
              <a class="navbar-brand" [routerLink]="'/' + surveyType.id"  >{{surveyType.value}}</a>
            </li>
          </div>

          <div *ngIf="!isMenuPerSurveyType">
            <li class="sidenav-item" [ngClass]="isActiveRoute('/list') ? 'active-route' : '' ">
              <img [src]="getAssetsUrl('assets/icons/menu/survey-icon-black.svg')" *ngIf="isActiveRoute('/list')" class="menu-icon">
              <img [src]="getAssetsUrl('assets/icons/menu/survey-icon-white.svg')" *ngIf="!isActiveRoute('/list')" class="menu-icon">
              <a class="navbar-brand" routerLink="/list">Surveys</a>
            </li>
          </div>

          <li class="sidenav-item" [ngClass]="isActiveRoute('/response') ? 'active-route' : '' ">
            <img  class="menu-icon">
            <a class="navbar-brand" routerLink="/response">Responses</a>
          </li>
          <li class="sidenav-item" [ngClass]="isActiveRoute('/question') ? 'active-route' : '' ">
            <img [src]="getAssetsUrl('assets/icons/menu/question-icon-black.svg')" *ngIf="isActiveRoute('/question')" class="menu-icon">
            <img [src]="getAssetsUrl('assets/icons/menu/question-icon-white.svg')" *ngIf="!isActiveRoute('/question')" class="menu-icon">
            <a class="navbar-brand" routerLink="/question">Question Bank</a>
          </li>
       </ul>
      </div>
      <div class="language-button-container">
        <button mat-raised-button class="language-button language-button-left" [ngClass]="activeLanguage == 'ar' ? 'active-button' : '' " (click)="changeLanguage('ar')">Ar</button>
        <button mat-raised-button class="language-button language-button-right" [ngClass]="activeLanguage == 'en' ? 'active-button' : '' " (click)="changeLanguage('en')">En</button>
      </div>
    </div>
  </div>

  <button class="sidenav-toogle-btn" id="sidenav-toogle-btn" [ngClass]="activeLanguage == 'ar' ? 'sidenav-toogle-btn-ar' : '' " type="button" (click)="toogleNav()" *ngIf="!_isMobile">
    <img [src]="getAssetsUrl('assets/icons/slider_btn.svg')">
  </button>

  <!-- FLOATING BOTTOM NAV CONTENT FOR MOBILE -->
  <div id="bottomSidenav" class="sidenav bottomSideNavClass" *ngIf="_isMobile">
    <div class="sidenav-content bottomSideNavContent">
      <div class="bottom-nav-collaps-btn-container">
        <button class="bottom-toogle-btn" id="bottom-sidenav-toogle-btn" type="button" (click)="toogleNav()">
          <span id="bottom-sidenav-toogle-btn-border" class="span-border"></span>
        </button>
      </div>
      <div class="bottom-nav-menu-container">
        <ul class="navbar-nav mr-auto">
          <div *ngIf="isMenuPerSurveyType">
            <li class="mobile-sidenav-item" *ngFor="let surveyType of surveyTypes" [ngClass]="isActiveRoute('/' + surveyType.id) ? 'mobile-active-route' : '' ">
              <img [src]="getAssetsUrl('assets/icons/menu/survey-icon-yellow.svg')" *ngIf="isActiveRoute('/' + surveyType.id)" class="menu-icon">
              <img [src]="getAssetsUrl('assets/icons/menu/survey-icon-black.svg')" *ngIf="!isActiveRoute('/' + surveyType.id)" class="menu-icon">
              <a class="navbar-brand mobile-navbar-brand" [routerLink]="'/survey/' + surveyType.id"  >{{surveyType.value}}</a>
            </li>
          </div>

          <div *ngIf="!isMenuPerSurveyType">
            <li class="mobile-sidenav-item" [ngClass]="isActiveRoute('/list') ? 'mobile-active-route' : '' ">
              <img [src]="getAssetsUrl('assets/icons/menu/survey-icon-yellow.svg')" *ngIf="isActiveRoute('/list')" class="menu-icon">
              <img [src]="getAssetsUrl('assets/icons/menu/survey-icon-black.svg')" *ngIf="!isActiveRoute('/list')" class="menu-icon">
              <a class="navbar-brand mobile-navbar-brand" routerLink="/list">Surveys</a>
            </li>
          </div>

          <li class="mobile-sidenav-item" [ngClass]="isActiveRoute('/response') ? 'mobile-active-route' : '' ">
            <img  class="menu-icon">
            <a class="navbar-brand mobile-navbar-brand" routerLink="/response">Responses</a>
          </li>
          <li class="mobile-sidenav-item" [ngClass]="isActiveRoute('/question') ? 'mobile-active-route' : '' ">
            <img [src]="getAssetsUrl('assets/icons/menu/question-icon-yellow.svg')" *ngIf="isActiveRoute('/question')" class="menu-icon">
            <img [src]="getAssetsUrl('assets/icons/menu/question-icon-black.svg')" *ngIf="!isActiveRoute('/question')" class="menu-icon">
            <a class="navbar-brand mobile-navbar-brand" routerLink="/question">Question Bank</a>
          </li>
        </ul>
      </div>
      <div class="language-button-container">
        <button mat-raised-button class="language-button language-button-left" [ngClass]="activeLanguage == 'ar' ? 'active-button' : '' " (click)="changeLanguage('ar')">Ar</button>
        <button mat-raised-button class="language-button language-button-right" [ngClass]="activeLanguage == 'en' ? 'active-button' : '' " (click)="changeLanguage('en')">En</button>
      </div>
    </div>
  </div>

  <!-- FIXED NAVBAR -->
  <nav class="navbar navbar-expand-lg navbar-dark survey-nav-bar">
    <div class="navbar-flex">
      <div  class="navbar-menu-btn-container">
        <button class="navbar-toggler always-displayed" type="button" (click)="toogleNav()">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <!-- <div >
        <img [src]="getAssetsUrl('assets/icons/LOGO.png')" /> <span class="logo-title">SIREN</span>
      </div> -->
    </div>
    <div>

    </div>
  </nav>

  <!-- Add all page content inside this div if you want the side nav to push page content to the right (not used if you only want the sidenav to sit on top of the page -->
  <div id="main">
    <div *ngIf="!_isMobile" class="main-foreground" id="main-foreground" (click)="toogleNav()"></div>
    <div *ngIf="_isMobile" class="main-foreground" id="main-mobile-foreground" (click)="toogleNav()"></div>
    <!-- <router-outlet></router-outlet> -->
  </div>
</div>
