import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { DropdownComparatorWithBrowserListener } from '../component-base/dropdown-comparator-with-browser';
import { TableFilterOutput } from '../../../models/shared/table-filter.model';


@Component({ template: '' })
export abstract class BasePanelListComponent extends DropdownComparatorWithBrowserListener implements OnInit{

  currentPage: number = 0;
  maxPage: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  initiateSearchFromFilter = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(protected datePipe: DatePipe){
    super();
  }

  ngOnInit(): void {
    this.constructFilterFormGroup();
    this.getPageData();
  }

  /**
   * {
   *    this.filterForm = this.formBuilder.group({
   *      'title': '',
   *      'maxGrade': '',
   *      'isBaseLine': '',
   *      'valueFrom': '',
   *      'valueTo': '',
   *    });
   * }
   */
  abstract constructFilterFormGroup(): void;

  /**
   {
      if(this.getId() != undefined && this.getId() > 0){
        let params = this.getQueryParamsWithFilter();
        this.getService().getBeneficiaryResponses(this.getId(), params).subscribe(response =>{
          if(response && response.body){
            this.surveyResponses = response.body.content;
            this.maxPage = response.body.totalPages;
            this.totalElements = response.body.totalElements;
          }
        });
      }
    }
  **/
  abstract getPageData();

  /**
   {
    let params = '?page=' + this.currentPage + '&size=' + this.pageSize;
    if(this.filterForm){
      const formValue = this.filterForm?.value;
      const paramObject = Object.entries(formValue)
        .map(([key, value]) => {
            if (key === 'valueFrom' && value != undefined && value != null && value != '') {
              return `submitDateFrom=${this.datePipe.transform(value as string, 'yyyy-MM-dd')}`;
            } else if (key === 'valueTo' && value != undefined && value != null && value != '') {
              return `submitDateTo=${this.datePipe.transform(value as string, 'yyyy-MM-dd')}`;
            }else if(key === 'maxGrade' && value != undefined && value != null && value != ''){
              return `maxGrade=${value}`;
            }else if(key === 'isBaseLine' && value != undefined && value != null && value != ''){
              return `isBaseLine=${value}`;
            }else if(key === 'title' && value != undefined && value != null && value != ''){
              return `title=${value}`;
            }
          return '';
        })
        .filter(Boolean)
        .join('&');
      if(paramObject != undefined && paramObject.trim() != '') params += '&' + paramObject.trim();
    }
    return params;
  }
   */
  abstract getQueryParamsWithFilter(): string;


  // Default list functions


  clearFilters(){
    this.constructFilterFormGroup();
    this.applyFilters();
  }

  applyFilters(){
    this.getPageData();
  }

  onKeyDownEvent(event: any){
    if(event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }

  openFormInNewTab(url: string){
    if(url && url != undefined && url.trim() != '') window.open(url, '_blank');
  }

  changePageSize(event: any){
    this.resetPagination();
    if(event && event.value != undefined) this.pageSize = event.value;
    this.getPageData();
  }

  resetPagination(){
    this.currentPage = 0;
    this.maxPage = 0;
    this.pageSize = 10;
  }

  getNextPage(){
    if(this.currentPage < this.maxPage -1){
      this.currentPage++;
      this.getPageData();
    }
  }

  getPreviousPage(){
    if(this.currentPage > 0){
      this.currentPage--;
      this.getPageData();
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
    }
  }

  // filter utils functions

  validateKeyAndValue(key: string, value: any){
    return key != undefined && key.trim() != '' && value != undefined && value.trim() != '';
  }

  getFilterParamForDate(key: string, value: any){
    return this.validateKeyAndValue(key, value) ? key + `=${this.datePipe.transform(value as string, 'yyyy-MM-dd')}` : undefined;
  }

  getFilterParamForString(key: string, value: any){
    return this.validateKeyAndValue(key, value) ? key + `=${value}` : undefined;
  }

  getFilterParamForBoolean(key: string, value: any){
    return this.validateKeyAndValue(key, value) ? key + `=${value}` : undefined;
  }

  getFilterParamForNumber(key: string, value: any){
    return this.validateKeyAndValue(key, value) ? key + `=${value}` : undefined;
  }
}
