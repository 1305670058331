import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlobComponent } from './blob.component';
import { BlobFileComponent } from './blob-file/blob-file.component';
import { BeneficiaryProfileImageComponent } from './beneficiary-profile-image/beneficiary-profile-image.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from 'src/app/app.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ImageFileTypeModule } from 'src/app/services/blob/image-file-type/image-file-type.module';
import { BytePipe } from './service/byte.pipe';
import { MediaBlobServicePipe } from './service/media-blob-service.pipe';

@NgModule({
  declarations: [
    BlobComponent,
    BlobFileComponent,
    BeneficiaryProfileImageComponent,
    BytePipe,
    MediaBlobServicePipe,
  ],
  exports: [
    BlobComponent,
    BlobFileComponent,
    BeneficiaryProfileImageComponent,
    MediaBlobServicePipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ImageFileTypeModule,
    TranslateModule.forChild({
      defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			},
			extend: true
		}),
    MatSnackBarModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
  ]
})
export class BlobModule { }
