import { Pipe, PipeTransform } from '@angular/core';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';

@Pipe({
  name: 'isOrganizationSelected'
})
export class IsOrganizationSelectedPipe implements PipeTransform {

  concernedOrganization: LookupModel[] = [];

  transform(organization: LookupModel, concernedOrganization: LookupModel[]): boolean {
    if(concernedOrganization.length>0){
      this.concernedOrganization = concernedOrganization;
      let index = this.getOrganizationIndexFromConcernedListById(organization?.id);
      return index != undefined && index >= 0;
    }
  }

  getOrganizationIndexFromConcernedListById(organizationId: number): number {
    let index: number;
    if (organizationId && this.concernedOrganization) {
      let countIndex = 0;
      for (let element of this.concernedOrganization) {
        if (index) break;
        if (element?.id == organizationId) index = countIndex;
        countIndex++;
      }
    }
    return index;
  }
}
