import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@siren-survey/app/component/shared/shared.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MarchDatepciker } from '../components/march-datepicker/march-datepicker.component';
import { SurveyAppModule, createSurveyTranslateLoader } from '@siren-survey/app/survey-app.module';
import { AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MultipleSearchableSelectionComponent } from '../components/multiple-searchable-selection/multiple-searchable-selection.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [
    MultipleSearchableSelectionComponent,
  ],
  imports: [
    MarchDatepciker,
    MatChipsModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: createSurveyTranslateLoader,
				deps: [HttpClient]
			},
      extend: true
		}),
  ],
  exports :[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SurveyAppModule,
    MarchDatepciker,
    MatSnackBarModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatCheckboxModule,
    FontAwesomeModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    MatChipsModule,
    MatAutocompleteModule,
    AsyncPipe,
    MultipleSearchableSelectionComponent,
    NgxMatSelectSearchModule,
  ]
})
export class ExternalModule { }
