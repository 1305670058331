import { Component, Output, EventEmitter, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalContainerComponent } from 'angular-bootstrap-md';
import { QuestionBank } from '../../../models/question/question-bank';
import { LookupModel, QuestionAnswerTypes } from '../../../models/shared/lookup.model';
import { LookupService } from '../../../services/lookups/lookup.service';
import { QuestionBankService } from '../../../services/question-bank.service';
import { DialogService } from '../../../services/shared/dialog.service';
import { OrderHandler } from '../../../utils/order-handler.utilities';
import { FormControllerGeneratorService } from '../../form-controller-generator.service';
import { DropdownComparator } from '../../shared/component-base/dropdown-comparator';
import { SettingsService } from '../../../shared/services/settings.service';
import { SurveyCustomValidators } from '../../../shared/validators/validators.service';
import { OpenLinkedSurveyQuestionService } from '../../../services/shared/open-linked-survey-question.service';

@Component({
  selector: 'app-question-bank-form',
  templateUrl: './question-bank-form.component.html',
  styleUrls: ['./question-bank-form.component.sass']
})
export class QuestionBankFormComponent extends DropdownComparator implements OnInit, OnDestroy {
  @Output() closeEventEmitter = new EventEmitter<string>();
  questionBankForm: FormGroup;
  isEditMode: boolean = true;
  disableSubmit: boolean = false;
  conditionsArr = new FormArray([]);

  private questionBank: QuestionBank;
  public id: number;
  public error:string;

  @ViewChild('questionBankModal', { static: true }) basicModal: ModalContainerComponent;

  public answerTypes: LookupModel[];

  constructor(private questionBankService: QuestionBankService, private route : ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: QuestionAnswerTypes,
    public lookupService: LookupService, private dialogService: DialogService, public readonly _translateService: TranslateService, private settingsService: SettingsService,
    private _openLinkedSurveyQuestionService: OpenLinkedSurveyQuestionService) {
    super();

    this.answerTypes = data.answerTypes;
    this.id = data.questionId;

  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  getQuestionBankFormGroup(editMode: boolean){
    return new FormGroup({
      'id': new FormControl({value:undefined, disabled: true}),
      'question': new FormControl({value:'', disabled: !editMode},  [Validators.required, SurveyCustomValidators.noWhitespaceValidator]),
      'withExplanation': new FormControl({value:false, disabled: !editMode}),
      'withOther': new FormControl({value:false, disabled: !editMode}),
      'gradeByAnswer': new FormControl({value:false, disabled: !editMode}),
      'answers': new FormArray([ ]),
      'answerType': new FormGroup({
        id : new FormControl({value:undefined, disabled: !editMode}),
        key: new FormControl({value:'', disabled: !editMode}, [Validators.required]),
        value: new FormControl({value:'', disabled: !editMode})
      }, [Validators.required])
    })
  }

  initializeFormGroup(){

    if(!this.questionBankForm){
      this.questionBankForm = this.getQuestionBankFormGroup(this.editable());
      if(this.id != undefined){
        this.questionBankService.getObject(this.id).subscribe(response =>{
          this.questionBank = response.body;
          this.isEditMode = this.questionBank.editable ? this.questionBank.editable : false;
          this.questionBankForm = this.getQuestionBankFormGroup(this.editable());
          this.questionBankForm.patchValue(this.questionBank);
          this.questionBank.answers.forEach(currAnswer =>{
           let currAnsFormGroup = FormControllerGeneratorService.createAnswerFormGroup(this.isEditMode);
           currAnsFormGroup.patchValue(currAnswer);
           (this.getAnswersFormArray() as FormArray)?.push(currAnsFormGroup);
          });
        });
      }
    }
  }

  ngOnDestroy() {
  }

  getAnswersFormArray(): FormArray{
    return this.questionBankForm ? this.questionBankForm.controls['answers'] as FormArray : undefined;
  }

  addAnswer(){
    let answersFormArray = this.getAnswersFormArray() as FormArray;
    if(answersFormArray){
      let answerFormGroup = FormControllerGeneratorService.createAnswerFormGroup(this.editable());
      if(answerFormGroup) answersFormArray.push(answerFormGroup);
    }
  }

  editable() : boolean{
    return this.isEditMode;
  }

  validateQuestionBankForm(): string{
    this.error = undefined;
    let hasError = false;

    if(!this.questionBank?.question || this.questionBank?.question?.trim() == "") {
      this.error= this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-question');
      hasError = true;
    }
    if(!this.error && (!this.questionBank?.answerType || this.questionBank?.answerType?.key == undefined || this.questionBank?.answerType?.key?.trim() == "")) {
      this.error= this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-answerType');
      hasError = true;
    }
    if(!this.error){
      if(this.questionBank?.answerType?.key != 'MULTI_SELECT' && this.questionBank?.answerType?.key != 'SINGLE_SELECT'){
        this.questionBank.answers = [];
      } else if(this.questionBank?.answers?.length <= 0){
        this.error = this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-answers');
        hasError = true;
      }
    }
    if(!this.error && this.questionBank.answerType.key != 'MULTI_SELECT' && this.questionBank.answerType.key != 'SINGLE_SELECT') this.questionBank.gradeByAnswer = false;
    if(!this.error){
      for(let answer of this.questionBank.answers){
        if(answer && this.questionBank.gradeByAnswer && answer?.grade == undefined){
          this.error = this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-grade');
          hasError = true;
          break;
        } else if(answer && (answer.answer == undefined || answer.answer.trim() == "")){
          this.error = this._translateService.instant('siren-survey-translation.question-bank-form.error.missing-answer-value');
          hasError = true;
          break;
        }
      }
    }
    if(hasError && !this.error) this.error = "Invalid Form";
    return this.error;
  }

  save(){
    this.error = "";
    this.questionBankForm.markAllAsTouched();
    this.questionBankForm.updateValueAndValidity();
    if(this.questionBankForm && this.questionBankForm.valid){
      this.executeSave();
    }
  }

  cancel(){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.cancel-question'),
      "message":  this._translateService.instant('siren-survey-translation.popup-module.messages.question-bank-cancel'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response) this.executeClose("CANCEL");
    });
  }

  executeSave(): void{
    OrderHandler.adaptOrderField(this.questionBankForm.controls['answers'] as FormArray);
    this.questionBank  = this.questionBankForm.getRawValue();
    this.questionBank.question = this.questionBank.question != null ? this.questionBank.question.trim(): "";
    this.questionBank.answers.forEach(answer => answer.answer = (answer.answer != null ? answer.answer.trim(): ""));
    this.disableSubmit = true;
    if(this.questionBank){
      this.error = "";
      this.validateQuestionBankForm();
      if(!this.error){
        this.questionBank = this.clearAnswerGradesIfNeeded(this.questionBank);
        if(this.questionBankForm && this.questionBankForm.valid){
          if(!this.error){
            this.questionBankService.postObject(this.questionBank).subscribe( response =>{
              if(response){
                this.executeClose("SAVE");
              } else{
                this.disableSubmit = false;
                this.error = "Could not create object!";
              }
            }, error =>{
              this.disableSubmit = false;
              this.error = error.error.message;
            });
          }
        } else{
          this.disableSubmit = false;
          this.error = "Unable to create question. Please refresh the page. If the problem persists contact your system administrator.";
        }
      } else{
        this.disableSubmit = false;
      }
    } else{
      this.disableSubmit = false;
      this.error = "Unable to create question. Please refresh the page. If the problem persists contact your system administrator.";
    }
  }

  clearAnswerGradesIfNeeded(questionBank: QuestionBank): QuestionBank{
    if(questionBank && !questionBank.gradeByAnswer && questionBank.answers && questionBank.answers.length > 0){
      questionBank.answers.forEach(answer =>{
        answer.grade = null;
      });
    }
    return questionBank;
  }

  executeClose(event: string): void{
    this.disableSubmit = false;
    this.closeEventEmitter.emit(event);
  }

  canDeleteAnswer() : boolean{
    return this.isEditMode;
  }

  deleteAnswer(index: number){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-answer'),
      "message": this._translateService.instant('siren-survey-translation.popup-module.messages.question-bank-delete'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response) (this.getAnswersFormArray() as FormArray).removeAt(index);
    });
  }

  moveUpAnswer(index: number) {
    OrderHandler.moveUp(index, this.getAnswersFormArray() as FormArray);
  }

  moveDownAnswer(index: number) {
    OrderHandler.moveDown(index, this.getAnswersFormArray() as FormArray);
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  openLinkedSurveysTable(id: number){
    this._openLinkedSurveyQuestionService.openLinkedSurveysTable(undefined,this.id);
  }

  onKeyDownEvent(event: any){
    if(event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }
}
