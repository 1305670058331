<div >
    <div>{{ placeholdertext | translate}} </div>
    <mat-button-toggle-group *ngIf="list?.length===0" [formControl]="ngControl.control" class="button-toggle-covid">
        <mat-button-toggle
            [value]="true"
            >
            {{firstButtonLabel}}
        </mat-button-toggle>
        <mat-button-toggle
            [value]="false">
            {{secondButtonLabel}}
        </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group *ngIf="list?.length> 0" [formControl]="ngControl.control" class="button-toggle">
        <mat-button-toggle
          *ngFor="let option of list"
          [value]="option">
          {{ option }}
         </mat-button-toggle>
    </mat-button-toggle-group>

    


</div>


