import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FrameworkHelper } from '../../helpers/framework.helper';
import { FieldType } from '../../models/fields-config.model';
import { CustomFormComponent } from '../custom-form/custom-form.component';
import { CustomFormActionModel } from '../custom-form/custom-form.model';
import { DetailsDialogModel } from './details-dialog.model';

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.sass'],
})
export class DetailsDialogComponent implements OnInit {
  @ViewChild(CustomFormComponent) customFormComponent?: CustomFormComponent;
  close = false;
  error: string;
  fieldTypeEnum = FieldType;
  frameworkHelper = FrameworkHelper;
  @Output() result = new EventEmitter<any>();
  @Output() fieldChange = new EventEmitter<any>();
  @Output() validationRequest = new EventEmitter<any>();
  submitAction: CustomFormActionModel = new CustomFormActionModel('siren-admin-translation.admin_module.saveAndContinue', false, false);
  closedFromCaller: boolean = false;

  constructor(public dialogRef: MatDialogRef<DetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DetailsDialogModel) {

  }

  ngOnInit(): void {
    if(this.data?.externalComponentComunicationSubject != undefined){
      this.closedFromCaller = true;
      this.data.externalComponentComunicationSubject.subscribe(externalComm => {
        if(externalComm) {
          if(externalComm.msg == "EVENT_CLOSE"){
            let formVal = Object.assign(this.data.data || {}, externalComm.data);
            this.dialogRef.close(formVal);
          } else{
            this.error = externalComm.msg ? externalComm.msg : "Error";
          }
        } else{
          this.dialogRef.close(false);
        }
      });
    }
  }

  public apply(event) {
    if (event) {
      this.buttonClicked(event.formVal);
    } else {
      this.closeDialog(true);
    }
  }

  public buttonClicked(formValToSave) {
    let formVal;
    if (formValToSave) {
      formVal = Object.assign(this.data.data || {}, formValToSave);
      //this.result.emit(formVal);
    }
    // this.dialogRef.close(formVal);
    if(this.closedFromCaller && formVal) this.validationRequest.emit(formVal);
    if(!this.closedFromCaller || !formVal) this.dialogRef.close(formVal);
  }

  public closeDialog(val: boolean) {
    if (this.customFormComponent.form.pristine) {
      this.buttonClicked(false);
    } else this.close = val;
  }

  changeField(event) {
    this.fieldChange.emit(event);
  }

}
