import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterElement, TableFilterTypeEnum, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { Subject, Subscription } from 'rxjs';
import { CustomReportList } from 'src/app/models/custom-report/customReportList';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CustomReportService } from 'src/app/services/custom-report/custom-report.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { CustomReportGenerateComponent } from '../custom-report-generate/custom-report-generate.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.sass']
})
export class ReportListComponent  implements OnInit , OnDestroy {
  tableTitleLabel: string;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;
  canAdd: boolean = false;
  hasListAccess: boolean = false;
  predefinedFilter: Map<string, any>;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  private _programs : LookupModel[] = [];
  private _genders: LookupModel[] = [];
  private _tags: LookupModel[] = [];
  private _activities: LookupModel[] = [];
  private _beneficiaryStatuses: LookupModel[] = [];

  private _subscription = new Subscription();

  _booleanIconColumnDisplayList: string[] = [
    "enabled","published"
  ]

  _tableColumns  : DataTableModel<CustomReportList> []= [
    {  columnDef: 'title', headerTranslationPath: "report.fields.title", header: this._translateService.instant('report.fields.title'),  cell: (element: CustomReportList) => `${element.title}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'disabled', headerTranslationPath: "report.fields.disabled", header: this._translateService.instant('report.fields.disabled'),  cell: (element: CustomReportList) => `${element.disabled ? "Yes":"No"}`, width: '150px'},
    {  columnDef: 'creationDate', type: TableFilterTypeEnum.Date, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "achievement.fields.creation-date", header: this._translateService.instant('report.fields.creation-date'),  cell: (element: CustomReportList) => `${element.creationDate ? this.datePipe.transform(element.creationDate, 'dd / MM / yyyy')  : "-"}`, width: '150px'},
    {  columnDef: 'programAttendance', headerTranslationPath: "report.fields.program-attendance", header: this._translateService.instant('report.fields.program-attendance'),  cell: (element: CustomReportList) => `${element.includeProgramAttendance ? "Yes":"No"}`, width: '150px'},
    {  columnDef: '_menu_inline', headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;


  filters: TableFilterElement[] = [
    { name: "title", translationPath: "report.fields.title", displayName:  this._translateService.instant('report.fields.title'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "disabled", translationPath: "report.fields.disabled", displayName:  this._translateService.instant('report.fields.disabled'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "programAttendance", translationPath: "report.fields.program-attendance", displayName: this._translateService.instant('report.fields.program-attendance'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
   ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('report.fields.view-report'), actionName: "VIEW_REPORTS_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/file-icon.svg" },
	  { name: this._translateService.instant('global.generated-reports'), actionName: "REPORTS_LIST_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/file-icon.svg" },
    { name: this._translateService.instant('global.clone'), actionName: "CLONE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg" },
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "../../../../assets/icons/delete-icon.svg", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.CUSTOM_REPORT_DELETE) , accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ])},
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string{
    return this._translateService.instant('global.delete');
  }

  constructor(public service : CustomReportService,
              private _router: Router,
              private _route : ActivatedRoute,
              private readonly _translateService: TranslateService,
              public lookupService: LookupService,
              public authService: AuthenticationService,
              public dialogService: DialogService,
              public dialog: MatDialog,
              private datePipe : DatePipe,
              private snackbar: MatSnackBar,) {
    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.CUSTOM_REPORT_MODIFY);
    this.hasListAccess = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.CUSTOM_REPORT_LIST);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){

    if(event.mode == TableAction.EDIT_MODE && this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.CUSTOM_REPORT_MODIFY)){
      this._router.navigate([event.id], {relativeTo: this._route});
    } else if(event.mode == TableAction.CREATE_MODE && this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.CUSTOM_REPORT_MODIFY)) {
      this._router.navigate(["create"], {relativeTo: this._route});
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }


  tableMenuActionHandler(menuAction: MenuActionEmittedObject){

    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
        this.dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-report'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
        .subscribe(response=>{
          if(response){
            this.service.deleteObject(menuAction.object.id).subscribe( response =>{

              this.reloadEventSubject.next(true);
            }, error =>{
              let message = error && error.error && error.error.message.includes("ConstraintViolationException")
                ? this._translateService.instant('error.record-depending-error')
                : this._translateService.instant('error.record-error');
              this.snackbar.open(message, null, { duration: 3000 });
            });
          }
        });
      }
      else if(menuAction.actionName == "CLONE_ACTION"){
        this.service.cloneCustomReport(menuAction.object.id).subscribe( response =>{
          let surveyResponse = response?.body;
          if(surveyResponse && surveyResponse.id > 0){
            this.reloadEventSubject.next(true);
          }
        }, error =>{
          console.error(error.error.message);
        });
      }
      else if(menuAction.actionName == "REPORTS_LIST_ACTION"){
        this._router.navigate([`generated-reports/${menuAction.object.id}/${menuAction.object.includeProgramAttendance}`], { relativeTo: this._route });
      }
      else if(menuAction.actionName == "VIEW_REPORTS_ACTION"){
        let serveyGenerateReportForm = this.openChangeStatusFormWindow(menuAction.object.id, menuAction.object.includeProgramAttendance, menuAction.object.title);
      }
    }
  }


  openChangeStatusFormWindow(reportId: number, includeProgramAttendance: boolean, title: string): any{
    let input: CustomReportFormInput = new CustomReportFormInput(reportId,includeProgramAttendance, true,this._programs,this._genders ,this._tags,this._activities,this._beneficiaryStatuses,title);
    const activityExecutionFormDialog = this.dialog.open(CustomReportGenerateComponent, {
      "width": '6000px',
      "maxWidth": '80vw',
      "maxHeight": '80vh',
      "autoFocus": false,
      data: input,
      direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });
    return activityExecutionFormDialog;
  }
}

export class CustomReportFormInput{
  constructor(public reportId: number,
              public includeProgramAttendance: boolean,
              public viewReport: boolean,
              public programs: LookupModel[],
              public genders: LookupModel[],
              public tags: LookupModel[],
              public activities: LookupModel[],
              public beneficiaryStatuses: LookupModel[],
              public title?: string) {}
}
