<form [formGroup]="responseHeaderFormGroup">
  <div class="row" style="align-items: baseline; margin-top: 10px;">
    <div class="col-lg-4 col-sm-6 col-12">
      <app-multiple-searchable-selection
        [placeHolder]="'program.fields.beneficiary' | translate"
        formControlName="beneficiary"
        [lookups]="beneficiaries"
        [formControl]="responseHeaderFormGroup.get('beneficiary')"
        [isMultiSelect]="false"
        (selectionChange)="setBeneficiaryId($event)"
      ></app-multiple-searchable-selection>
    </div>
    <div class="col-lg-6 col-sm-6 col-12">
      <mat-form-field class="full-width-element">
        <mat-label class="" >{{'program.fields.organization-survey' | translate}}</mat-label>
        <mat-select class="" formControlName="organizationSurvey" [compareWith]="objectComparisonFunction" (selectionChange)="displayActivityExecution($event)">
          <mat-option *ngFor="let organizationSurvey of organizationSurveys" [value]="organizationSurvey" >
            {{ organizationSurvey.value }}
          </mat-option>
        </mat-select>
        <button matSuffix mat-icon-button *ngIf="responseHeaderFormGroup?.controls?.organizationSurvey?.getRawValue()?.id && this.responseId == undefined" type="button" aria-label="Clear"
            (click)="clearOrganizationSurvey();$event.stopPropagation()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-sm-6 col-12" *ngIf="showActivityExecution">
      <mat-form-field class="full-width-element">
        <mat-label class="" >{{'program.fields.activity-executions' | translate}}</mat-label>
        <mat-select class="" formControlName="activityExecution" [compareWith]="objectComparisonFunction">
          <mat-option *ngFor="let activityExecution of activityExecutions" [value]="activityExecution" >
            {{ activityExecution.value }}
          </mat-option>
        </mat-select>
        <button matSuffix mat-icon-button *ngIf="responseHeaderFormGroup?.controls?.activityExecution?.value && this.responseId == undefined" type="button" aria-label="Clear"
            (click)="responseHeaderFormGroup?.controls?.activityExecution?.setValue(null); $event.stopPropagation()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-sm-6 col-12" *ngIf="responseId != undefined && responseId > 0 && !isBaseLine && canSetBaseline">
      <button type="button" class="btn btn-submit" (click)="setResponseAsBaseline()">
        <span style="white-space: nowrap;">{{ 'march-survey-response-header.set-baseline' | translate }}</span>
      </button>
    </div>
  </div>
  <!-- <app-program-topic-selection [surveyId]="surveyId" [isEditable]="isEditable" [topicSelectionFormGroup]="responseHeaderFormGroup?.get('topicSelectionFormGroup')"
    [useActivityExecution]="true" [programActivityExecutionMap]="programActivityExecutionMap">
  </app-program-topic-selection> -->
</form>
