import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { AssessmentActivityLink, AssessmentBeneficiary, AssessmentBeneficiaryObject, AssessmentLookup, BeneficiaryAssessment, BeneficiaryAssessmentObject } from '../../component/assessment/models/assessment.model';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService  extends BaseService<AssessmentLookup, AssessmentLookup> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/assessment", http, injector);
  }

  getAssessmentListByType(typeId: number): Observable<HttpResponse<AssessmentLookup[]>>{
    return this.http.get<AssessmentLookup[]>(`/v1/assessment/Lookup/${typeId}`);
  }
  getAssessmentList(): Observable<HttpResponse<AssessmentLookup[]>>{
    return this.http.get<AssessmentLookup[]>(`/v1/assessment/Lookup?assessmentType=all`);
  }

  getRiskAssessmentList(): Observable<HttpResponse<AssessmentLookup[]>>{
    return this.http.get<AssessmentLookup[]>(`/v1/assessment/Lookup?assessmentType=risk`);
  }

  linkAssessmentToActivity(body : AssessmentActivityLink): Observable<HttpResponse<AssessmentActivityLink>> {
    return this.http.post<AssessmentActivityLink>("/v1/assessment/activity-link" , body);
  }

  getLinkedAssessmentList(id: number,type: string): Observable<HttpResponse<AssessmentLookup[]>>{
    return this.http.get<AssessmentLookup[]>(`/v1/assessment/activity-assessment/Lookup?${type}Id=${id}`);
  }

  saveAssessmentResponse(body : BeneficiaryAssessmentObject): Observable<HttpResponse<AssessmentBeneficiaryObject>> {
    return this.http.post<AssessmentBeneficiaryObject>("/v1/assessment/new-response" , body);
  }

}
