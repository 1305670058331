import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { QuestionBankList } from '../../../models/question/question-bank';
import { DataTableModel } from '../../../models/shared/cell-data-model';
import { TableDeleteMode, TableSelectionMode } from '../../../models/shared/table-action';
import { QuestionBankService } from '../../../services/question-bank.service';
import { FilterOperator, TableFilterElement, TableFilterOutput, TableFilterTypeEnum } from '../../../models/shared/table-filter.model';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISurveyAuthenticationService } from '../../../shared/services/interfaces/authentication.interface';
import { MenuActionEmittedObject, MenuActionObject } from '../../../models/shared/datatable-objects.model';
import { OpenLinkedSurveyQuestionService } from '../../../services/shared/open-linked-survey-question.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-question-bank-select-list',
  templateUrl: './question-bank-select-list.component.html',
  styleUrls: ['./question-bank-select-list.component.sass']
})
export class QuestionBankSelectListComponent implements OnInit {
  @Output()  selectionEventEmitter = new EventEmitter<number>();
  public tableDeleteMode: typeof TableDeleteMode = TableDeleteMode;
  public tableSelectionMode: typeof TableSelectionMode = TableSelectionMode;
  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  additionalParams : Map<string,any>;

  consumerPermissionEnum;
  hasListAccess: boolean = false;

  _tableColumns  : DataTableModel<QuestionBankList> []= [
   {  columnDef: 'answerType', headerTranslationPath: "siren-survey-translation.question-bank-list.answerType", header: this._translateService.instant('siren-survey-translation.question-bank-list.answerType'),  cell: (element: QuestionBankList) => `${element.answerType.value}`, width:  '150px',contentClass: 'leftAlign'},
    {  columnDef: 'question',  headerTranslationPath: "siren-survey-translation.question-bank-list.question", header: this._translateService.instant('siren-survey-translation.question-bank-list.question'),  cell: (element: QuestionBankList) => `${element.question}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'gradeByAnswer', headerTranslationPath: "siren-survey-translation.question-bank-list.grade-by-answer", header: this._translateService.instant('siren-survey-translation.question-bank-list.grade-by-answer'),  cell: (element: QuestionBankList) => `${element.gradeByAnswer}`, width: '150px'},
    {  columnDef: 'withExplanation', headerTranslationPath: "siren-survey-translation.question-bank-list.with-explanation",  header: this._translateService.instant('siren-survey-translation.question-bank-list.with-explanation'),  cell: (element: QuestionBankList) => `${element.withExplanation}`, width: '150px'},
    {  columnDef: 'withOther', headerTranslationPath: "siren-survey-translation.question-bank-list.with-other", header: this._translateService.instant('siren-survey-translation.question-bank-list.with-other'),  cell: (element: QuestionBankList) => `${element.withOther}`, width: '150px'},
    {  columnDef: '_menu_inline', headerTranslationPath: "", header: '',  cell: undefined, width: '25px'},
    {  columnDef: '_delete_inline', headerTranslationPath: "", header: '',  cell: (element: QuestionBankList) => `${element.id}`, width: undefined}
  ] ;

  filters: TableFilterElement[] = [
    { name: "answerType", translationPath: "siren-survey-translation.question-bank-list.answerType", displayName: "Answer Type", type: TableFilterTypeEnum.Lookup, lookupName: "AnswerType", lookupsValueById: false, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "question", translationPath: "siren-survey-translation.question-bank-list.question", displayName: "Question", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "gradeByAnswer", translationPath: "siren-survey-translation.question-bank-list.grade-by-answer", displayName: "Grade By Answer", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "withExplanation", translationPath: "siren-survey-translation.question-bank-list.with-explanation", displayName: "With Explanation", type: TableFilterTypeEnum.Boolean, lookupName: null, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "withOther", translationPath: "siren-survey-translation.question-bank-list.with-other", displayName: "With Other", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal }
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.linked-surveys'), actionName: "LINKED_SURVEYS", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/external-link-alt-solid.svg"},
  ]
  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  constructor(public service : QuestionBankService,
              @Inject(MAT_DIALOG_DATA) public data: Map<string,any>,
              private openLinkedSurveyQuestionServicesService: OpenLinkedSurveyQuestionService,
              public authenticationService: ISurveyAuthenticationService,
              private _translateService: TranslateService) {
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        case 'answerType': return item[property]?.value;
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        if(key && key == 'answerType'){
          return currentTerm + data[key]?.value;
       } else{
         return currentTerm + data[key];
       }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.consumerPermissionEnum = this.authenticationService.getPermissionEnumeration();
    this.hasListAccess = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.QUESTION_LIST);
    this.additionalParams = data;
  }

  ngOnInit(): void {
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  handleSelectionSubmission(objects: any){
    this.selectionEventEmitter.emit(objects?.at(0)?.id);
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "LINKED_SURVEYS"){
        this.openLinkedSurveyQuestionServicesService.openLinkedSurveysTable(undefined, menuAction.object.id)
      }
    }
  }

}
