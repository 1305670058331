<div class="container mat-focus-indicator" [ngClass]="isPreview ? 'preview-container' : '' ">
  <div class="response-form-header-container" *ngIf="!isPreview" >
    <h3 class="response-title">{{'siren-survey-translation.survey-response-form.title' | translate}}</h3>
    <div class="bottom-border">
      <button type="button" (click)="goBack(); $event.stopPropagation();" class="btn button-flat button-flex"
       [disabled]="false">
       <i class="fa fa-angle-left" aria-hidden="true"></i>
       <span  class="locate-text ">{{'siren-survey-translation.global.back' | translate}}</span>
     </button>
   </div>
  </div>
  <form [formGroup]="surveyResponseForm" class="white-background">
    <div class="survey-title-container ">
      <div style="display: flex; flex-direction: column;">
        <span class=" survey-title-input">{{ survey?.title }}</span>
        <span style="color: red; font-size: 14px; font-weight: 600;" *ngIf="editable() && isSubmitted">{{ 'siren-survey-translation.section-response-form.response-submited-caution' | translate }}</span>
      </div>
      <div class="survey-form-action-container align-right padding0" >
        <div class="survey-form-action-btn-container align-right padding0" >
          <div class="total-grade-container" *ngIf="surveyGraded">
            <span class="inline-padding total-grade-label">{{'siren-survey-translation.survey-response-form.total' | translate}}  </span>
            <div style="white-space: nowrap;">
              <!-- <mat-form-field class="total-grade-input">
                <input matInput type="number" autocomplete="off" min="0" max="{{survey?.maxGrade}}" class="" formControlName="totalGrade">
                <span matTextSuffix>/ {{ survey?.maxGrade }}</span>
              </mat-form-field> -->
              <mat-form-field class="total-grade-input">
                <input matInput type="number" autocomplete="off" min="0" max="{{survey?.maxGrade}}" class="" formControlName="totalGrade" (keydown)="onKeyDownEvent($event)">
              </mat-form-field>
              <span class="max-grade-display">/ {{ survey?.maxGrade }}</span>
            </div>
          </div>
          <div class="placeButtons" *ngIf="isAllowedToSubmitResponse && isFormLoaded">
            <button *ngIf='editable() && !isPreview && !isSubmitted' [disabled]="disableSave" type="submit" (click)="save()" class="btn btn-submit">
              <span style="white-space: nowrap;">{{'siren-survey-translation.global.save' | translate}}</span>
            </button>
            <button *ngIf='editable() && !isPreview && !isSubmitted' [disabled]="disableSave" type="submit" (click)="submit()" class="btn btn-publish">
              <span style="white-space: nowrap;">{{'siren-survey-translation.survey-form.button.submit' | translate}} </span>
              <img [src]="getAssetsUrl('assets/icons/arrow-down-icon.svg')" class="widthIcon" [ngClass]="_translateService.currentLang == 'ar' ? 'rotateIcon-rtl' : 'rotateIcon-ltr' ">
            </button>
            <button *ngIf='editable() && !isPreview && isSubmitted' [disabled]="disableSave" type="submit" (click)="submit()" class="btn btn-submit">
              <span style="white-space: nowrap;">{{'siren-survey-translation.survey-response-form.button.data-admin-save' | translate}}</span>
            </button>
          </div>
          <div class="disabled-survey-submission" *ngIf="!isAllowedToSubmitResponse">
            <span>{{ 'siren-survey-translation.section-response-form.no-longer-accessible' | translate }}</span>
          </div>
        </div>
        <div>
          <span class="message-label" *ngIf="hasGradedAnswers">{{'siren-survey-translation.survey-response-form.has-graded-questions' | translate}}</span>
        </div>
      </div>
    </div>
    <hr class="divider" style="margin: 0px;">
    <div class="container form-element-division">
      <div class="form-element-label">
        {{'siren-survey-translation.survey-response-form.description' | translate}}
      </div>
      <div class="form-element-area-content">
        {{ survey?.description }}
      </div>
      <div>
        <ng-container #responseHeaderAdditionalFormContainer></ng-container>
      </div>
      <div *ngIf="error != undefined && error.trim() != ''">
        <span style="color: red">{{ 'siren-survey-translation.section-response-form.invalid-response' | translate }}</span>
      </div>
    </div>
    <ng-container *ngIf="showResponseSections">
      <div *ngFor="let section of survey?.sections; let sectionIndex = index;">
        <app-response-section-form [section]="section" [isEditMode]="isEditMode" [surveyGraded]="surveyGraded" [invalidQuestionResponseIds]="invalidQuestionResponseIds" [surveyMaxGrade]="survey?.maxGrade"  [sectionIndex]="sectionIndex"
          [surveyResponseClientService]="surveyResponseClientService" [questionFormGroupMap]="sectionQuestionFormGroupMap" [sectionQuestionFormGroupMap]="getQuestionFormGroupMapForSection(section.id)" [questionSectionMap]="questionSectionMap" [isSubmitted]="surveyResponse && surveyResponse.submitDate != undefined"></app-response-section-form>
      </div>
    </ng-container>
  </form>
</div>
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>