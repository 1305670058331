import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable, map } from 'rxjs';
import { ActivityAssesment, ActivityBeneficiaryAssessment, Assessment, AssessmentBeneficiaryObject } from 'src/app/component/assessment/models/assessment.model';

@Injectable({
	providedIn: 'root'
})
export class ActivityExecutionService extends BaseService<ActivityAssesment, ActivityAssesment> {

	constructor(http: MyHttpHandler, protected injector: Injector) {
		super("/v1/program-activity/activity-execution-info/program", http, injector);
	}

	public getBeneficiaryListBasedOnActivityOrProgram(id: number, type: string): Observable<HttpResponse<ActivityBeneficiaryAssessment[]>> {
		return this.http.get<ActivityBeneficiaryAssessment[]>(`/v1/beneficiary/activity-execution?${type}Id=${id}`);
	}

	public getAssessmentCriteria(assessmentId: number): Observable<HttpResponse<Assessment>> {
		return this.http.get<Assessment>(`/v1/assessment/${assessmentId}`);
	}

	public getResponseAssessment(activityAssessmentId: number, id: number, type: string, page: number, size: number): Observable<AssessmentBeneficiaryObject> {
		return this.http.get<AssessmentBeneficiaryObject>(`/v1/assessment/response-List/activity?activityAssessmentId=${activityAssessmentId}&${type}Id=${id}&page=${page}&size=${size}`).pipe(map((result: any) => { 
			return { submitDate: result.body.submitDate, responses: result.body.page.content, totalPages: result.body.page.totalPages };
		}));
	}

}
