<div class="fullwidth locateForm" >

  <app-responsive-list-view
       [tableColumns]="_tableColumns"
       [sortingDataAccessor]="_sortingDataAccessor"
       [filteringPredicate]="_filteringPredicate"
       [service]="service"
       [withAdd]="canAddQuestion"
       [hasListAccess]="hasListAccess"
       [selectionMode]="tableSelectionMode.NONE_MODE"
       [maxCellStringLength]="80"
       [maxStringLength]="20"
       addButtonLabel="{{'siren-survey-translation.question-bank-list.create-question' | translate}}"
       tableTitleLabel="{{'siren-survey-translation.question-bank-list.title' | translate}}"
       [inputFilterEventSubject]="filterEventSubject.asObservable()"
       (mode)="updateViewMode($event)"
       [_tableMenuAction]="_tableMenuAction"
       [booleanIconColumnDisplayList]="_booleanIconColumnDisplayList"
       (menuActionEventEmitter)="tableMenuActionHandler($event)"
       [inputReloadEventSubject]="reloadEventSubject.asObservable()"
       [showFilterButton]="true"
       [showBackButton]="false"
       initialPageSize="10">
       <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
       <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
   </app-responsive-list-view>
 </div>

       <!-- [deleteMode]="tableDeleteMode.INLINE_MODE" -->
