<div class="main-container">

  <!-- FLOATING SIDE NAV CONTENT -->
  <app-side-menu *ngIf="!_isMobile" [activeLanguage]="activeLanguage" (languageChanged)="onLanguageChange($event)"
    (logoutClicked)="onLogoutClick()" (itemClicked)="closeNav()"></app-side-menu>

  <button class="sidenav-toogle-btn" id="sidenav-toogle-btn"
    [ngClass]="activeLanguage == 'ar' ? 'sidenav-toogle-btn-ar' : '' " type="button" (click)="toogleNav()"
    *ngIf="!_isMobile">
    <img [src]="getSurveyAssetsUrl('assets/icons/slider_btn.svg')">
  </button>

  <!-- FLOATING BOTTOM NAV CONTENT FOR MOBILE -->
  <div id="bottomSidenav" class="sidenav bottomSideNavClass" *ngIf="_isMobile">
    <div class="sidenav-content bottomSideNavContent">
      <div class="bottom-nav-collaps-btn-container">
        <button class="bottom-toogle-btn" id="bottom-sidenav-toogle-btn" type="button"
          (click)="mobileSideNavBtnClicked()">
          <span id="bottom-sidenav-toogle-btn-border" class="span-border"></span>
        </button>
      </div>
      <div class="bottom-nav-menu-container">
        <ul class="navbar-nav mr-auto">
          <li (click)="collapseAllItems()" class="sidenav-item-rev" >
            <span class="fa fa-caret-square-o-up" style="font-size: 20px;"></span>
            <a class="navbar-brand">{{ "landing-page.collapse-all" | translate }}</a>
          </li>
          <li routerLink="/" class="mobile-sidenav-item"
            [ngClass]="(isActiveRoutePath('/', true) || isActiveRoutePath('', true)) ? 'mobile-active-route' : '' ">
            <span class="fa fa-home menu-icon" style="font-size: 20px;"></span>
            <a class="navbar-brand mobile-navbar-brand">{{ "landing-page.home" | translate }}</a>
          </li>

          <ng-container *ngFor="let mainMenuItem of menuService.menuItems">
            <div *menuPermissions="mainMenuItem.permissions">
              <li class="mobile-sidenav-item"
                [ngClass]="isActiveRoute(mainMenuItem.routerLink)? 'mobile-active-route' : '' "
                (click)="toogleMenuExpansion(mainMenuItem)">
                <ng-container
                  *ngIf="mainMenuItem.activeIconUrlMobile|| mainMenuItem.inactiveIconUrlMobile else menuIconMobile">
                  <img [src]="mainMenuItem.activeIconUrlMobile" *ngIf="isActiveRoute(mainMenuItem.routerLink)"
                    class="menu-icon">
                  <img [src]="mainMenuItem.inactiveIconUrlMobile" *ngIf="!isActiveRoute(mainMenuItem.routerLink)"
                    class="menu-icon">
                </ng-container>
                <ng-template #menuIconMobile>
                  <i class={{mainMenuItem.iconClass}}
                    [ngClass]="{ 'active-menu': isActiveRoute(mainMenuItem.routerLink), 'light-active-label': !isActiveRoute(mainMenuItem.routerLink) }"></i>
                </ng-template>
                <a class="navbar-brand mobile-navbar-brand">{{ mainMenuItem.label | translate }}</a>
                <i class="fa"
                  [ngClass]="{ 'fa-chevron-up': mainMenuItem.toggled, 'fa-chevron-down': !mainMenuItem.toggled }"></i>
              </li>
            </div>
            <div [ngClass]="mainMenuItem.toggled && 'expand-collapsable-menu-element'" class="collapsable-menu-element">
              <ng-container *ngFor="let item of mainMenuItem.items">
                <div *menuPermissions="item.permissions">
                  <li routerLink="{{item.routerLink}}" class="mobile-sidenav-item mobile-menu-sub-element"
                    [ngClass]="isActiveRoute(item.routerLink) ? 'mobile-active-route' : '' ">
                    <ng-container
                      *ngIf="item.activeIconUrlMobile || item.inactiveIconUrlMobile; else iconContainerMobile">
                      <img [src]="item.activeIconUrlMobile" *ngIf="isActiveRoute(item.routerLink)" class="menu-icon">
                      <img [src]="item.inactiveIconUrlMobile" *ngIf="!isActiveRoute(item.routerLink)" class="menu-icon">
                    </ng-container>
                    <ng-template #iconContainerMobile>
                      <i class={{item.iconClass}}
                        [ngClass]="{ 'active-menu': isActiveRoute(item.routerLink), 'light-active-label': !isActiveRoute(item.routerLink) }"></i>
                    </ng-template>
                    <a class="navbar-brand mobile-navbar-brand">{{ item.label | translate }}</a>
                  </li>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <!-- USER PROFILE -->
          <div>
            <li routerLink="/profile" class="mobile-sidenav-item"
              [ngClass]="isActiveRoute('/profile') ? 'mobile-active-route' : '' ">
              <i class="fa fa-user profile-picture-menu-icon active-menu" *ngIf="isActiveRoute('/profile')"></i>
              <i class="fa fa-user profile-picture-menu-icon active-label" *ngIf="!isActiveRoute('/profile')"></i>
              <a class="navbar-brand mobile-navbar-brand">{{ "landing-page.profile" | translate }}</a>
            </li>
          </div>
          <!-- KEEP THIS AS THE LAST MENU -->
          <li class="mobile-sidenav-item">
            <span class="fa fa-sign-out menu-icon" style="font-size: 18px;"></span>
            <a class="navbar-brand mobile-navbar-brand" (click)="logout()">{{ "landing-page.logout" | translate }}</a>
            <!-- routerLink="/login" -->
          </li>
        </ul>
      </div>
      <div class="language-button-container">
        <button mat-raised-button class="language-button language-button-left"
          [ngClass]="activeLanguage == 'ar' ? 'active-button' : '' " (click)="changeLanguage('ar')">{{ "landing-page.ar"
          | translate }}</button>
        <button mat-raised-button class="language-button language-button-right"
          [ngClass]="activeLanguage == 'en' ? 'active-button' : '' " (click)="changeLanguage('en')">{{ "landing-page.en"
          | translate }}</button>
      </div>
    </div>
  </div>

  <!-- FIXED NAVBAR -->
  <nav class="navbar navbar-expand-lg navbar-dark march-nav-bar navbar-flex-container">
    <div class="navbar-flex">
      <div class="navbar-menu-btn-container">
        <button class="navbar-toggler always-displayed" type="button" (click)="toogleNav()">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div routerLink="/">
        <img src="assets/icons/MARCH_LOGO_REVERTED.png" class="march-logo" /> <span class="logo-title">{{
          "landing-page.march" | translate }}</span>
      </div>
    </div>
    <div >
      <div class="navbar-userdata"> <!-- class="profile-picture-container" -->
        <button mat-icon-button (click)="navigateToSivis()" aria-label="User Account"
          class="dropdown-notifications-btn">
          <div class="mat-button-wrapper bell-icon">
            <i class="fa fa-bar-chart  bell-color-size"></i>
          </div>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="notificationMenu" aria-label="User Account"
          class="dropdown-notifications-btn">
          <div class="mat-button-wrapper bell-icon">
            <i class="fa fa-bell bell-color-size"></i>
            <span class="counter">{{notificationCounter}}</span>
          </div>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="incidentsMenu" aria-label="User Account"
          class="dropdown-notifications-btn">
          <div class="mat-button-wrapper bell-icon">
            <i class="fa fa-exclamation-triangle bell-color-size"></i>
            <span class="counter">{{incidentsCounter}}</span>
          </div>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User Account" class="dropdown-profile-btn">
          <!-- <i class="fa fa-user profile-picture-icon"></i> -->
          <div style="display: flex;
          align-items: center">
            <mat-icon class="profile-navbar-icon">account_circle</mat-icon>
            <span *ngIf="!_isMobile" class="profile-username-label">{{username}}</span>
          </div>
        </button>
      </div>
      <mat-menu #notificationMenu="matMenu" class="notification-height">
        <p class="notification-title">{{ "landing-page.notifications" | translate }}</p>
        <hr class="divider">
        <div *ngFor="let item of list" class="list-notifications notification-container" (click)="openObject(item)">
          <div class="title-bold">{{item.title}}</div>
          <div>{{item.message}}</div>
          <hr class="divider">
        </div>
        <div class="list-notifications" *ngIf="list.length == 0">
          <div class="title-bold">{{ "landing-page.you-have-no-notification" | translate }}</div>
          <hr class="divider">
        </div>
      </mat-menu>
      <mat-menu #incidentsMenu="matMenu" class="notification-height">
        <p class="notification-title">{{ "homepage.incidents" | translate }}</p>
        <hr class="divider">
        <div *ngFor="let incident of listIncident" class="list-notifications notification-container" (click)="$event.stopPropagation();">
          <app-incident-feed [incident]="incident"></app-incident-feed>
        </div>
        <div class="list-notifications" *ngIf="listIncident.length == 0">
          <div class="title-bold">{{ "homepage.no-incidents" | translate }}</div>
          <hr class="divider">
        </div>
      </mat-menu>
      <mat-menu #menu="matMenu">
        <div style="min-width: 160px; text-align-last: start;">
          <div class="profile-menu-title">
            <span class="menu-profile-username-label">{{username}}</span>
          </div>
          <div *ngIf="userOrganizationRoles && userOrganizationRoles.length > 0"
            style="border-bottom: 1px solid #E3E9EF">
            <button mat-menu-item class="dropdown-org-role-title">
              <span><u>{{ "landing-page.organizations" | translate }}</u></span>
            </button>
            <button mat-menu-item *ngFor="let userOrganizationRole of userOrganizationRoles"
              (click)="setAsActiveOrganization(userOrganizationRole.id)"
              [ngClass]="isRollActive(userOrganizationRole) && !isAllRollActive() ? 'dropdown-active-org-role' : 'dropdown-org-role' ">
              <span>{{ userOrganizationRole?.organization?.name }}</span>
            </button>
            <button mat-menu-item *ngIf="canActivateAllRoles()" (click)="setAllAsActiveOrganization()"
              [ngClass]="isAllRollActive() ? 'dropdown-active-org-role' : 'dropdown-org-role' ">
              <span>{{ "landing-page.all" | translate }}</span>
            </button>
          </div>

          <button mat-menu-item routerLink="/profile">
            <i class="fa fa-user dropdown-menu-icon active-label" style="font-size: 20px;"></i>
            <span>{{ "landing-page.profile" | translate }}</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <i class="fa fa-sign-out dropdown-menu-icon" style="font-size: 20px;"></i>
            <span>{{ "landing-page.logout" | translate }}</span>
          </button>
          <div class="dropdown-language-button-container">
            <button mat-raised-button class="language-button dropdown-language-button-left dropdown-language-button"
              [ngClass]="activeLanguage == 'ar' ? 'active-button' : '' " (click)="changeLanguage('ar')">{{
              "landing-page.ar" | translate }}</button>
            <button mat-raised-button class="language-button dropdown-language-button"
              [ngClass]="activeLanguage == 'en' ? 'active-button' : '' " (click)="changeLanguage('en')">{{
              "landing-page.en" | translate }}</button>
          </div>
        </div>
      </mat-menu>
    </div>
  </nav>

  <!-- Add all page content inside this div if you want the side nav to push page content to the right (not used if you only want the sidenav to sit on top of the page -->
  <div id="main">
    <div *ngIf="!_isMobile" class="main-foreground" id="main-foreground" (click)="toogleNav()"></div>
    <div *ngIf="_isMobile" class="main-foreground" id="main-mobile-foreground" (click)="toogleNav()"></div>
    <router-outlet></router-outlet>
  </div>
</div>
