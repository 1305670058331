import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionBankList } from '../../../models/question/question-bank';
import { DataTableModel } from '../../../models/shared/cell-data-model';
import { TableAction, TableDeleteMode, TableSelectionMode } from '../../../models/shared/table-action';
import { QuestionBankService } from '../../../services/question-bank.service';
import { FilterOperator, TableFilterElement, TableFilterOutput, TableFilterTypeEnum } from '../../../models/shared/table-filter.model';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { QuestionBankFormComponent } from '../question-bank-form/question-bank-form.component';
import { MenuActionEmittedObject, MenuActionObject } from '../../../models/shared/datatable-objects.model';
import { ISurveyAuthenticationService } from '../../../shared/services/interfaces/authentication.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../services/shared/dialog.service';
import { LookupModel, QuestionAnswerTypes } from '@siren-survey/app/models/shared/lookup.model';

@Component({
  selector: 'app-question-bank-list',
  templateUrl: './question-bank-list.component.html',
  styleUrls: ['./question-bank-list.component.sass']
})
export class QuestionBankListComponent implements OnInit {
  public tableDeleteMode: typeof TableDeleteMode = TableDeleteMode;
  public tableSelectionMode: typeof TableSelectionMode = TableSelectionMode;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  consumerPermissionEnum;
  hasListAccess : boolean = false;
  canAddQuestion : boolean = false;

  public answerTypes:  LookupModel[];
  private _subscription = new Subscription();

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg", accessRightExpressions: new Map<string, string>([ ["deletable", "deletable == true"] ])}
  ]
  _booleanIconColumnDisplayList: string[] = [ "gradeByAnswer", "withExplanation", "withOther" ]

  _tableColumns  : DataTableModel<QuestionBankList> []= [
    {  columnDef: 'answerType', headerTranslationPath: "siren-survey-translation.question-bank-list.answerType", header: this._translateService.instant('siren-survey-translation.question-bank-list.answerType'),  cell: (element: QuestionBankList) => `${element.answerType.value}`, width: '150px',contentClass: 'leftAlign'},
    {  columnDef: 'question',  headerTranslationPath: "siren-survey-translation.question-bank-list.question", header: this._translateService.instant('siren-survey-translation.question-bank-list.question'),  cell: (element: QuestionBankList) => `${element.question}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'gradeByAnswer', headerTranslationPath: "siren-survey-translation.question-bank-list.grade-by-answer", header: this._translateService.instant('siren-survey-translation.question-bank-list.grade-by-answer'),  cell: (element: QuestionBankList) => `${element.gradeByAnswer}`, width: '150px'},
    {  columnDef: 'withExplanation', headerTranslationPath: "siren-survey-translation.question-bank-list.with-explanation",  header: this._translateService.instant('siren-survey-translation.question-bank-list.with-explanation'),  cell: (element: QuestionBankList) => `${element.withExplanation}`, width: '150px'},
    {  columnDef: 'withOther', headerTranslationPath: "siren-survey-translation.question-bank-list.with-other", header: this._translateService.instant('siren-survey-translation.question-bank-list.with-other'),  cell: (element: QuestionBankList) => `${element.withOther}`, width: '150px'},
    {  columnDef: '_menu_inline', headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "answerType", translationPath: "siren-survey-translation.question-bank-list.answerType", displayName: "Answer Type", type: TableFilterTypeEnum.Lookup, lookupName: "AnswerType", lookupsValueById: false, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "question", translationPath: "siren-survey-translation.question-bank-list.question", displayName: "Question", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "gradeByAnswer", translationPath: "siren-survey-translation.question-bank-list.grade-by-answer", displayName: "Grade By Answer", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "withExplanation", translationPath: "siren-survey-translation.question-bank-list.with-explanation", displayName: "With Explanation", type: TableFilterTypeEnum.Boolean, lookupName: null, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "withOther", translationPath: "siren-survey-translation.question-bank-list.with-other", displayName: "With Other", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal }
  ];

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  constructor(
    public service: QuestionBankService,
    private _router: Router,
    private _route : ActivatedRoute,
    private matDialog: MatDialog,
    public authenticationService: ISurveyAuthenticationService,
    private snackbar: MatSnackBar,
    private _translateService: TranslateService,
    private dialogService: DialogService
  ) {
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        case 'answerType': return item[property]?.value;
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        if(key && key == 'answerType'){
          return currentTerm + data[key]?.value;
       } else{
         return currentTerm + data[key];
       }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.consumerPermissionEnum = this.authenticationService.getPermissionEnumeration();
    this.hasListAccess = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.QUESTION_LIST);
    this.canAddQuestion =  this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SAVE_QUESTION);

    this._tableMenuAction.forEach(column=>{
      if(column.actionName=='DELETE_ACTION')
        column.allowedAction = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.DELETE_QUESTION);
    });
  }

  ngOnInit(): void {
    this._subscription.add(
      this._route.data.subscribe((params) => {
        this.answerTypes = params.answerTypes.AnswerType;
      })
    );
  }

  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    const questionAnswerTypes: QuestionAnswerTypes = {
      questionId: event.id,
      answerTypes: this.answerTypes
    };

    const questionBankFormDialog = this.matDialog.open(QuestionBankFormComponent, {
      "width": '6000px',
      "maxWidth": '80vw',
      "maxHeight": '80vh',
      "data": questionAnswerTypes,
      "autoFocus": false,
      "direction": this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });

    const subscribeDialog = questionBankFormDialog.componentInstance.closeEventEmitter.subscribe((data) => {
      questionBankFormDialog.close();
      this.reloadEventSubject.next(true);
    });
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
        this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-question'),
        "message": this._translateService.instant('siren-survey-translation.popup-module.messages.delete-question'),
        "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
        "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
      .subscribe(response=>{
        if(response) {
          this.service.deleteObject(menuAction.object.id).subscribe( response =>{

            this.reloadEventSubject.next(true);
          }, error =>{
            let message = error && error.error && error.error.message.includes("ConstraintViolationException")
              ? this._translateService.instant('error.record-depending-error')
              : this._translateService.instant('error.record-error');
            this.snackbar.open(message, null, { duration: 3000 });
          });
        }
      });
      }
    }
  }
}
