import { Observable } from "rxjs";

export abstract class ISurveyAuthenticationService {

  public abstract getAccessToken(): string;

	public abstract checkIfUserIsAvailable(): boolean;

  public abstract refreshToken(): Observable<any>;

  // public abstract accessRightValidation(module: string): boolean;

  public abstract userModuleAccessRightValidation(userModule: any): boolean;

  public abstract getPermissionEnumeration();

  public abstract isModuleAccessible(module: string): boolean;

}
