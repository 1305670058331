<div id="mySidenav" class="sidenav" [ngClass]="activeLanguage == 'ar' ? 'sidenav-ar' : '' ">
  <div class="sidenav-content" [ngClass]="activeLanguage == 'ar' ? 'sidenav-content-ar' : '' ">
    <div>
      <ul class="navbar-nav mr-auto no-padding-navbar-nav">
        <li (click)="collapseAllItems()"  class="sidenav-item-rev" >
          <span class="fa fa-caret-square-o-up" style="font-size: 20px;"></span>
          <a class="navbar-brand">{{ "landing-page.collapse-all" | translate }}</a>
        </li>
        <li routerLink="/" class="sidenav-item"
          [ngClass]="(isActiveRoutePath('/', true) || isActiveRoutePath('', true)) ? 'active-route' : '' ">
          <span class="fa fa-home menu-icon" style="font-size: 20px;"></span>
          <a class="navbar-brand">{{ "landing-page.home" | translate }}</a>
        </li>
        <ng-container *ngFor="let mainMenuItem of menuService.menuItems">
          <div *menuPermissions="mainMenuItem.permissions">
            <li class="sidenav-item" [ngClass]="{ 'active-route': isActiveRoute(mainMenuItem.routerLink) }"
              (click)="toogleMenuExpansion(mainMenuItem)">
              <ng-container *ngIf="mainMenuItem.activeIconUrl || mainMenuItem.inactiveIconUrl; else menuIconContainer">
                <img
                  [src]="isActiveRoute(mainMenuItem.routerLink) ? mainMenuItem.activeIconUrl : mainMenuItem.inactiveIconUrl"
                  class="menu-icon">
              </ng-container>
              <ng-template #menuIconContainer>
                <i [class]="mainMenuItem.iconClass" aria-hidden="true"
                [ngClass]="{'active-label': isActiveRoute(mainMenuItem.routerLink),'inactive-label': !isActiveRoute(mainMenuItem.routerLink)}"></i>
              </ng-template>
              <a class="navbar-brand">{{ mainMenuItem.label | translate }}</a>
              <i class="fa  inactive-label"
                [ngClass]="{ 'fa-chevron-up': mainMenuItem.toggled, 'fa-chevron-down': !mainMenuItem.toggled }"></i>
            </li>
          </div>
          <div [ngClass]="mainMenuItem.toggled && 'expand-collapsable-menu-element'" class="collapsable-menu-element">
            <ng-container *ngFor="let item of mainMenuItem.items">
              <div *menuPermissions="item.permissions">
                <li routerLink={{item.routerLink}} class="sidenav-item menu-sub-element"
                  [ngClass]="{ 'diluted-active-route': isActiveRoute(item.routerLink) }" (click)="onItemClick(item)">
                  <ng-container *ngIf="item.activeIconUrl || item.inactiveIconUrl; else iconContainer">
                    <img [src]="isActiveRoute(item.routerLink) ? item.activeIconUrl : item.inactiveIconUrl"
                      class="menu-icon">
                  </ng-container>
                  <ng-template #iconContainer>
                    <i class={{item.iconClass}} aria-hidden="true"></i>
                  </ng-template>
                  <a class="navbar-brand">{{ item.label | translate }}</a>
                </li>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- USER PROFILE  TODO-->
        <div>
          <li routerLink="/profile" class="sidenav-item" [ngClass]="isActiveRoute('/profile') ? 'active-route' : '' ">
            <i class="fa fa-user profile-picture-menu-icon active-label" *ngIf="isActiveRoute('/profile')"></i>
            <i class="fa fa-user profile-picture-menu-icon inactive-label" *ngIf="!isActiveRoute('/profile')"></i>
            <a class="navbar-brand">{{ "landing-page.profile" | translate }}</a>
          </li>
        </div>

        <!-- KEEP THIS AS THE LAST MENU -->
        <li class="sidenav-item">
          <span class="fa fa-sign-out menu-icon" style="font-size: 18px;"></span>
          <a class="navbar-brand" (click)="logout()">{{ "landing-page.logout" | translate }}</a>
          <!-- routerLink="/login" -->
        </li>
      </ul>
    </div>
    <div class="language-button-container">
      <button mat-raised-button class="language-button language-button-left"
        [ngClass]="activeLanguage == 'ar' ? 'active-button' : '' " (click)="changeLanguage('ar')">{{ "landing-page.ar"
        | translate }}</button>
      <button mat-raised-button class="language-button language-button-right"
        [ngClass]="activeLanguage == 'en' ? 'active-button' : '' " (click)="changeLanguage('en')">{{ "landing-page.en"
        | translate }}</button>
    </div>
  </div>
</div>
