import { LookupModelDto } from "../../models/shared/lookup.model";

export class MonthService {

  public static getMonthsLookupList(): LookupModelDto[]{
    let lookupDTOS: LookupModelDto[] = [];
    lookupDTOS.push(MonthService.createMonthLookupDto(1, "January"));
    lookupDTOS.push(MonthService.createMonthLookupDto(2, "February"));
    lookupDTOS.push(MonthService.createMonthLookupDto(3, "March"));
    lookupDTOS.push(MonthService.createMonthLookupDto(4, "April"));
    lookupDTOS.push(MonthService.createMonthLookupDto(5, "May"));
    lookupDTOS.push(MonthService.createMonthLookupDto(6, "June"));
    lookupDTOS.push(MonthService.createMonthLookupDto(7, "July"));
    lookupDTOS.push(MonthService.createMonthLookupDto(8, "August"));
    lookupDTOS.push(MonthService.createMonthLookupDto(9, "September"));
    lookupDTOS.push(MonthService.createMonthLookupDto(10, "October"));
    lookupDTOS.push(MonthService.createMonthLookupDto(11, "November"));
    lookupDTOS.push(MonthService.createMonthLookupDto(12, "December"));
    return lookupDTOS;
  }

  private static createMonthLookupDto(id: number, value: string): LookupModelDto{
    return new LookupModelDto(id, id+"", value, false, id);
  }
}
