<div class="linked-class-survey">
  <mat-accordion class="profile-headers-align" multi>
    <mat-expansion-panel #mainpanel (opened)="handleSurveyLinkPanelOpenEvent()" class="benef-remove-mat-expansion-panel-padding">
      <mat-expansion-panel-header class="expansion-panel-header" *ngIf="!toogleMainPanelFromOutside" [ngClass]="{'expansion-panel-header-default': useDefaultStyling,'customClass': !!customClass}">
        <mat-panel-title class="panel-title">
          <div class="expansion-header-container">
            <div class="expansion-title">
              <span>{{'program.survey-selection.linked-survey-list' | translate}}</span>
            </div>
            <button *ngIf="canLinkSurvey" title="{{ 'program.buttons.add-linked-survey' | translate }}"
          class="btn flat-button" type="button" mat-button (click)="linkSurvey(); $event.stopPropagation();">
          <span class="fa fa-plus"></span>
          </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <div class="response-container">
          <p>
            <span class="grey-label">{{ 'program.survey-selection.view-survey-details' | translate }}</span>
          </p>
          <div>
            <div class="flex-row table-header">
              <div class="row" style="width: 100%;">
                <div class="col-lg-2 col-sm-6 col-12">
                  <span>{{'siren-survey-translation.survey-form.fields.title' | translate}}</span>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <span>{{'siren-survey-translation.survey-form.fields.organization' | translate}}</span>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <span>{{'siren-survey-translation.survey-form.fields.creation-date' | translate}}</span>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <span>{{'siren-survey-translation.survey-form.fields.start-date' | translate}}</span>
                </div>
                <div class="col-lg-2 col-sm-6 col-12">
                  <span>{{'siren-survey-translation.survey-form.fields.end-date' | translate}}</span>
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                  <span>{{'program.survey-selection.actions' | translate}}</span>
                </div>
              </div>
              <div class="flex-row" style="min-width: 35px;">

              </div>
            </div>
            <ng-container *ngIf="this.surveyTopicList != undefined && this.surveyTopicList?.length > 0 ">
              <mat-accordion class="row table-record full-width-element" *ngFor="let surveyTopic of this.surveyTopicList" >
                <mat-expansion-panel (opened)="headerOpenState = true" (closed)="headerOpenState = false" style="border: none; box-shadow: none; padding: 0px;" >
                  <mat-expansion-panel-header style="height: auto !important; padding: 20px 15px;">
                    <div class="row row-header" style="width: 100%;">
                      <div class="col-lg-2 col-sm-6 col-12">
                        <span>{{ surveyTopic?.survey?.title ? surveyTopic?.survey?.title : "-" }}</span>
                      </div>
                      <div class="col-lg-2 col-sm-6 col-12">
                        <span>{{ surveyTopic?.organization?.name ? surveyTopic?.organization?.name : "-" }}</span>
                      </div>
                      <div class="col-lg-2 col-sm-6 col-12">
                        <span>{{ surveyTopic?.survey?.creationDateTime ? (surveyTopic?.survey?.creationDateTime | date: 'dd / MM / yyyy') : "N/A" }} </span>
                      </div>
                      <div class="col-lg-2 col-sm-6 col-12">
                        <span>{{ surveyTopic?.survey?.startDateTime ? surveyTopic?.survey?.startDateTime : "-" }}</span>
                      </div>
                      <div class="col-lg-2 col-sm-6 col-12">
                        <span>{{ surveyTopic?.survey?.endDateTime ? surveyTopic?.survey?.endDateTime : "-" }}</span>
                      </div>
                      <div class="col-lg-1 col-sm-6 col-12" style="display: flex; align-items: center;">
                        <ng-container *ngIf="surveyTopic?.id != undefined && hasAccessToOrganization(surveyTopic?.organization?.id)">
                          <i (click)="editProgramTopic(surveyTopic?.id); $event.stopPropagation();" aria-hidden="true" title="View Survey"
                            class="fa fa-pencil" style="font-size: 17px; margin-inline-end: 5px;"></i>
                          <i (click)="createResponse(surveyTopic?.survey?.id, surveyTopic?.id); $event.stopPropagation();" aria-hidden="true" title="Create Response"
                            *ngIf="showCreateResponseButton(surveyTopic)" class="fa fa-plus-circle" style="font-size: 18px; margin-inline-end: 5px;"></i>
                          <i (click)="deleteTopic(surveyTopic?.id); $event.stopPropagation();" aria-hidden="true" title="Delete Link"
                            class="fa fa-trash" style="font-size: 18px;"></i>
                        </ng-container>
                      </div>

                    </div>
                  </mat-expansion-panel-header>
                  <mat-expansion-panel-body>
                    <div class="description-panel" style="width: 100%; margin-bottom: 15px;">
                      <div style="text-align: left;">
                        <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.description' | translate}}:</span>
                        <span>{{ surveyTopic?.survey?.description ? surveyTopic?.survey?.description : "-" }}</span>
                      </div>
                      <i *ngIf="surveyTopic?.survey?.id != undefined && surveyTopic?.survey?.id > 0 && surveyTopic?.survey?.surveyType != undefined && surveyTopic?.survey?.surveyType.id != undefined && surveyTopic?.survey?.surveyType.id > 0"
                        class="fa fa-eye" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true" title="View Survey"
                        (click)="openSurveyPreviewForm(surveyTopic?.survey?.id); $event.stopPropagation();"></i>
                    </div>
                    <div class="flex-row-pannel" style="width: 100%;">
                      <div style="margin-inline-end: 30px;">
                        <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.survey-type' | translate}}:</span>
                        <span>{{ surveyTopic?.survey?.surveyType && surveyTopic?.survey?.surveyType.value ? surveyTopic?.survey?.surveyType.value : "-" }}</span>
                      </div>
                      <div style="margin-inline-end: 30px;">
                        <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.enabled' | translate}}:</span>
                        <span>
                          {{
                            surveyTopic?.survey?.enabled != undefined && surveyTopic?.survey?.enabled == true
                              ? ('siren-survey-translation.global.yes' | translate)
                              : ('siren-survey-translation.global.no' | translate)
                          }}
                        </span>
                      </div>
                      <div style="margin-inline-end: 30px;">
                        <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.graded' | translate}}:</span>
                        <span>
                          {{
                            surveyTopic?.survey?.graded != undefined && surveyTopic?.survey?.graded == true
                              ? ('siren-survey-translation.global.yes' | translate)
                              : ('siren-survey-translation.global.no' | translate)
                          }}
                        </span>
                      </div >
                      <div style="margin-inline-end: 30px;">
                        <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.max-grade' | translate}}:</span>
                        <span>{{ surveyTopic?.survey?.maxGrade ? surveyTopic?.survey?.maxGrade : "-" }}</span>
                      </div>
                      <div>
                        <span class="mat-body-label">{{'siren-survey-translation.survey-form.fields.published' | translate}}:</span>
                        <span>
                          {{
                            surveyTopic?.survey?.published != undefined && surveyTopic?.survey?.published == true
                              ? ('siren-survey-translation.global.yes' | translate)
                              : ('siren-survey-translation.global.no' | translate)
                          }}
                        </span>
                      </div>
                    </div>
                  </mat-expansion-panel-body>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
            <div *ngIf="this.surveyTopicList == undefined || this.surveyTopicList?.length == 0">
              <span class="empty-list-label">{{ 'siren-survey-translation.survey-form.no-linked-surveys' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- </div> -->
</div>
<app-loading-spinner *ngIf="loading"></app-loading-spinner>

