import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { CustomReportElement } from 'src/app/models/custom-report/customReport';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CustomReportService } from 'src/app/services/custom-report/custom-report.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { SettingsService } from '@siren-survey/app/shared/services/settings.service';

@Component({
  selector: 'app-report-element-list',
  templateUrl: './report-element-list.component.html',
  styleUrls: ['./report-element-list.component.sass']
})
export class ReportElementListComponent implements OnInit {

  @Input() customReportElements : []; // mainly we have to create object customReportElements that includes array of customReportElement
  @Input() customReportId: number;
  @Input() isEditMode: boolean = false;

  @Output()  cellListEventEmitter = new EventEmitter<boolean>();
  // @Output()  orderChangeEventEmitter = new EventEmitter<{}>();

  idOrderArray: {[key: number]: number} = {};

  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  consumerPermissionEnum;
  hasListAccess: boolean = false;
  ordersChanged: boolean = false;
  cellCount: number = 0 ;
  cellSelected : boolean = false;
  customReportElement : CustomReportElement;
  orderingTriggered: boolean = false;
  upperBoundIndex: number;
  constructor(public authenticationService: AuthenticationService,
    private settingsService: SettingsService,
    private readonly _translateService: TranslateService,private cdr: ChangeDetectorRef,
    private customReportService: CustomReportService, private dialogService: DialogService){

    this.hasListAccess = this.authenticationService.userModuleAccessRightValidation(this.marchPermissionEnum.CUSTOM_REPORT_MODIFY);

 }

  ngOnInit(): void {
    if(this.hasListAccess) this.getCustomReportElementListData();

  }

  getCustomReportElementListData(){
    this.cellCount = this.customReportElements ? this.customReportElements?.length : 0 ;
    if(this.customReportElements?.length > 0){
      this.upperBoundIndex = this.customReportElements.length > 0 ? this.customReportElements.length - 1 : 0
    }
  }

  addCellElement(){
    this.cellSelected = false;

    this.customReportElement = {
      id: undefined,
      order: undefined,
      title: undefined,
      type: undefined
    }
    this.cdr.detectChanges();

    this.cellSelected = true;
  }

  editCellElement(cell: CustomReportElement){
    this.cellSelected = false;
    this.customReportElement = cell;

    this.cdr.detectChanges();

    this.cellSelected = true;
  }

  deleteCell(cellId: number){

    this.dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-cell'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
        .subscribe(response=>{
          if(response){
            this.customReportService.deleteCellElement(this.customReportId,cellId).subscribe(response =>{
              if(response){
                this.handleCellEmit();
                const elements = (this.customReportElements  as any[]).find((element) => element.id === cellId);
                const index = (this.customReportElements  as any[]).findIndex((element) => element.id === cellId) - 1;
                if (index >= 0 && this.customReportElements.length > 0) {
                  this.editCellElement(this.customReportElements[index]);
                }
                else{
                  this.addCellElement();
                }
              }
            });
          }
        });

  }

  handleCellEmit(){
    this.cellListEventEmitter.emit();
  }

  moveUp(index: number){
    this.ordersChanged = true;
    if (index > 0 && this.customReportElements) {
      const oldOrder = (this.customReportElements[index] as any ).order;
      const tempObj : any = this.customReportElements[index];
      let currIbj : any = this.customReportElements[index];
      let preObj : any = this.customReportElements[index - 1];
      currIbj.order = preObj.order;
      preObj.order = oldOrder;
      const itemToMove = this.customReportElements[index];
      this.customReportElements[index] = this.customReportElements[index - 1];
      this.customReportElements[index - 1] = itemToMove;
      this.idOrderArray = {};
      this.customReportElements.forEach(customElement => {
          this.idOrderArray[(customElement as any).id] = (customElement as any).order;
      });
    }
  }

  moveDown(index: number){
    this.ordersChanged = true;
    if (index < this.customReportElements.length - 1) {
      const itemToMove = this.customReportElements[index];
      this.customReportElements[index] = this.customReportElements[index + 1];
      this.customReportElements[index + 1] = itemToMove;
      const oldOrder = (this.customReportElements[index] as any ).order;
      const tempObj : any = this.customReportElements[index];
      let currObj : any = this.customReportElements[index];
      let postObj : any = this.customReportElements[index + 1];

      currObj.order = postObj.order;
      postObj.order = oldOrder;

      this.idOrderArray = {};
      this.customReportElements.forEach(customElement => {
        this.idOrderArray[(customElement as any).id] = (customElement as any).order;
      });
    }
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }


  saveOrder(){
    this.ordersChanged = false;
    this.customReportService.rearrangeElements(this.customReportId,<any>this.idOrderArray).subscribe(response =>{
      if(response){
        this.handleCellEmit();
      }
    });
  }
}
