<div *ngIf="secondForm == undefined">
    <button
    type="submit"
    class="submit_btn"
    *ngIf="form.enabled"
    [disabled]="form.invalid || form.pristine"
    [ngClass]="{'submit_btn_disabled': form.invalid || form.pristine}"
    >{{ (placeholdertext? placeholdertext : 'siren-admin-translation.admin_module.profile.send') | translate }}
</button>
</div>
<div *ngIf="secondForm != undefined">
    <button
    type="submit"
    class="submit_btn"
    *ngIf="form.enabled && secondForm.enabled"
    [disabled]="(secondForm.invalid || secondForm.pristine && (form.invalid || form.pristine))"
    [ngClass]="{'submit_btn_disabled': (secondForm.invalid || secondForm.pristine && (form.invalid || form.pristine))}"
    >{{ (placeholdertext? placeholdertext : 'siren-admin-translation.admin_module.profile.send') | translate }}
</button>
</div>
