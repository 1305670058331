<div class="fullwidth locateForm" >

  <app-responsive-list-view
       [tableColumns]="_tableColumns"
       [sortingDataAccessor]="_sortingDataAccessor"
       [filteringPredicate]="_filteringPredicate"
       [service]="service"
       [withAdd]="false"
       [hasListAccess]="hasListAccess"
       [_tableMenuAction]="_tableMenuAction"
       [selectionMode]="tableSelectionMode.SINGLE_SELECT_MODE"
       [deleteMode]="tableDeleteMode.NONE_MODE"
       [maxCellStringLength]="80"
       [maxStringLength]="20"
       tableTitleLabel="{{'siren-survey-translation.question-bank-list.selection-title' | translate}}"
       [inputFilterEventSubject]="filterEventSubject.asObservable()"
       (selectionEventEmitter)="handleSelectionSubmission($event)"
       (menuActionEventEmitter)="tableMenuActionHandler($event)"
       [showFilterButton]="true"
       [showBackButton]="false"
       [additionalParams]="additionalParams"
       initialPageSize="10">
       <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
       <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
   </app-responsive-list-view>
 </div>
