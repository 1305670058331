import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule, Provider } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
// import { RecaptchaV3Module, RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
// import { NgxImageCompressService } from 'ngx-image-compress';
import { environment } from 'src/environments/environment';
import { MaterialModule } from './materials.module';

@NgModule({
    imports: [],
    exports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        FormsModule,
        ScrollingModule,
        // RecaptchaV3Module
    ],
    providers: []
})
export class ThirdPartiesModule {}
