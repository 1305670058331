import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// import { AuthService } from '../services/auth.service';
import { IAdminAuthenticationService } from '../services/adminAuthentication.interface';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public authService: IAdminAuthenticationService,
    public router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getAccessToken()) {
      req = this.addToken(req, this.authService.getAccessToken())
    }

    return next.handle(req).pipe(tap(
      event => { },
      error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 || error.status === 403) {
            this.router.navigateByUrl('/login');
          }
        }
      }
    ));
  }

  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
