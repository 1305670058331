<div class="table-wrapper" *ngIf="data && data.currentlyVisibleRows; else noData">
  <mat-form-field appearance="outline" *ngIf="filterConfig.enabled" class="filter_field">
    <mat-label>{{'siren-admin-translation.generic.filter.search' | translate}} + </mat-label>
    <input matInput [formControl]="filterControl">
    <div matSuffix class="filter_actions">
      <mat-icon class="filter_action" (click)="executeFilter()" (keydown.enter)="executeFilter()">search</mat-icon>
      <mat-icon class="filter_action" (click)="clearFilter()">clear</mat-icon>
    </div>
  </mat-form-field>
  <div class="record-header-row record-row">
    <div class="record-header record-cell" *ngFor="let col of columnsDefinition" [ngStyle]="{'width': col.width}">
      {{col.label | translate}}
    </div>
  </div>

  <div class="record-data-row" *ngFor="let row of data.currentlyVisibleRows">

    <div class="record-row">
      <div class="record-cell record-data-cell" *ngFor="let col of columnsDefinition" [ngStyle]="{'width': col.width}">
        <ng-container *ngIf="col?.format === columnFormat.Action">
          <div [ngClass]="getCellClass(col)" *ngIf="col.visible && (row.editable == undefined || (row.editable != undefined && row.editable))">
            <mat-icon *ngIf="col.icon" (click)="actionClicked(col, row)" class="actionicon">{{ col.icon }}
            </mat-icon>
          </div>
        </ng-container>
        <ng-container *ngIf="col?.format === columnFormat.Toggle">
          <div [ngClass]="getCellClass(col)">
            <mat-slide-toggle #componentRef color="primary" [checked]="getCheckedVal(col, row)"
              (click)="toggleClicked(col, row, $event, componentRef)" [disabled]="col.disabled">
            </mat-slide-toggle>
          </div>
        </ng-container>
        <ng-container *ngIf="col?.format === columnFormat.Boolean">
          <div [ngClass]="getCellClass(col)">
            <mat-checkbox color="primary" [checked]="row[col.property]" #componentRef
              (click)="col.disabled ? null : toggleClicked(col, row, $event, componentRef)" [disabled]="col.disabled">
            </mat-checkbox>
          </div>
        </ng-container>
        <ng-container
          *ngIf="col?.format !== columnFormat.Action && col?.format !== columnFormat.Toggle && col?.format !== columnFormat.Boolean">
          <div [ngClass]="getCellClass(col)">
            {{formatCellValue(col, row)}}
          </div>
        </ng-container>

      </div>
    </div>
  </div>

  <div class="paginator"
    *ngIf="paginatorConfig.enabled !== configTypeEnum.NONE  && data.currentlyVisibleRows && data.currentlyVisibleRows.length > 0">
    <mat-icon class="material-icons-outlined navigation_icon" [ngClass]="{'isDisabled': pageChangeEvent.page == 0}"
      color="primary" (click)="pageChangeEvent.page > 0 ? clickPage(false, true) : null">
      last_page
    </mat-icon>
    <mat-icon class="material-icons-outlined navigation_icon" [ngClass]="{'isDisabled': pageChangeEvent.page == 0}"
      color="primary" (click)="pageChangeEvent.page > 0 ? clickPage(false) : null">
      chevron_right
    </mat-icon>
    <div class="navigation_nb">
      {{ pageChangeEvent.page + 1 }} / {{ getPageNb() }}
    </div>
    <mat-icon class="material-icons-outlined navigation_icon" color="primary"
      (click)="pageChangeEvent.page + 1 < getPageNb() ? clickPage(true) : null"
      [ngClass]="{'isDisabled': pageChangeEvent.page + 1 == getPageNb()}">
      chevron_left
    </mat-icon>
    <mat-icon class="material-icons-outlined navigation_icon" color="primary"
      (click)="pageChangeEvent.page + 1 < getPageNb() ? clickPage(true, true) : null"
      [ngClass]="{'isDisabled': pageChangeEvent.page + 1 == getPageNb()}">
      first_page
    </mat-icon>
  </div>

</div>

<ng-template #noData>
  <div>
    {{ 'siren-admin-translation.generic.table.no_data' | translate }}

  </div>
</ng-template>
