<div class="login-container">
  <div class="login-bkg-image"></div>
  <div class="login-bkg-dimmer"></div>
  <div class="login-container-col">
    <img src="assets/icons/MARCH_LOGO_TRANSPARENT.svg" class="login-march-icon"/>
    <h2 style="color: #FFFFFF; margin-bottom: 20px;">{{ 'siren-admin-translation.admin_module.reset_password.reset_password' | translate }}</h2>
    <form [formGroup]="form" (ngSubmit)="submit()" class="login-form">
      <div class="login-form-container">
        <div class="alert alert-success custom_alert" role="alert" *ngIf="success">
          <p>{{success}}</p>
        </div>
        <div class="alert alert-danger custom_alert" role="alert" *ngIf="error">
          <p>{{error}}</p>
        </div>
        <h5 style="margin-bottom: 20px;">{{ 'siren-admin-translation.admin_module.reset_password.enter_username' | translate }}</h5>
        <div class="input_styling username_field mt-2">
          <mat-form-field appearance="outline" class="login-input-field">
            <mat-label>{{ 'siren-admin-translation.admin_module.reset_password.username' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="username" class="font-weight-bold" />
          </mat-form-field>
        </div>
        <button type="submit" class="login-button">
          <img src="assets/icons/lock_white.svg" class="login-button-icon"/>
          <span class="login-button-text">{{ 'siren-admin-translation.admin_module.reset_password.reset_password' | translate }}</span>
        </button>
      </div>
      <div class="forgot_password_wrapper pointer">
        <a class="forgot_password_button" (click)="navigateTo('login')">{{ 'siren-admin-translation.admin_module.reset_password.login' | translate }}</a>
      </div>
    </form>
  </div>
</div>
