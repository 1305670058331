import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ActivityExecutionService } from 'src/app/services/assessment/activity-execution.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { AssessmentService } from 'src/app/services/assessment/assessment.service';
import { Subscription, concatMap, of } from 'rxjs';
import { ActivityBeneficiaryAssessment, Assessment, AssessmentBeneficiary, AssessmentBeneficiaryObject, AssessmentCriterion, AssessmentLookup, AssessmentSection, AssessmentSectionDetails, BeneficiaryAssessmentObject, BeneficiaryResult, Section } from '../models/assessment.model';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { AssessmentBenefCriterion, AssessmentBenefSection } from '../../beneficiary/models/benficiary-assessment.model';
import { formHelper } from 'src/app/shared/helper/form-helper';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-assessment-result',
  templateUrl: './assessment-result.component.html',
  styleUrls: ['./assessment-result.component.sass']
})
export class AssessmentResultComponent implements OnInit, OnDestroy {
  public allBeneficiaryList: ActivityBeneficiaryAssessment[];
  public beneficiaryList: ActivityBeneficiaryAssessment[];
  public id: number;
  public activityAssessmentId: number;
  public actionMode: string;
  public marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  public assessmentId: number;
  public assessments: AssessmentLookup[];
  public assessmentTypes: LookupModel[];
  public assessment: Assessment;
  public beneficiaryResultArray: FormArray = new FormArray([]);
  public sectionResultArray: FormArray = new FormArray([]);
  public benficiaryResults: BeneficiaryResult[] = [];
  public assessmentDetails: AssessmentSectionDetails[] = [];
  public gridTemplateColumns: string;
  public subscription: Subscription = new Subscription();
  public allResponses: AssessmentBeneficiaryObject;
  public response: AssessmentBeneficiaryObject;
  public currentLang: string;
  public action = 'ADD_MODE';
  public form: FormGroup;
  public activityName: string = "";
  public showSubmitDate: boolean = false;
  private _type: string = "";
  rowsRetrieved: number = 0;
  message: string = '';

  // Variables for the pagination of the assessments reponses
  page = 0;
  pageSize = 10;
  totalPages = 0;

  constructor(private _activityExecutionService: ActivityExecutionService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _snackbar: MatSnackBar,
    public readonly _translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private _assessmentService: AssessmentService,
    private _datePipe: DatePipe,
  ) {
    this.subscription.add(this._route.data.subscribe(data => {
      this.assessments = data?.data?.assessments?.body;
      this.assessmentTypes = data?.data?.lookups?.AssessmentType;
    })
    );
    this.currentLang = _translateService.currentLang;

    this.form = this._formBuilder.group({
      assessment: new FormControl({ value: undefined, disabled: false }, Validators.required),
      submitDate: new FormControl({ value: undefined, disabled: false }, Validators.required),
      benficiaries: this._formBuilder.array([])
    });

    const segments = this._route.snapshot.url.map(segment => segment.path);
    this._type = segments.indexOf('activity') >= 0 ? 'activityExecution' : 'program';
  }

  ngOnInit(): void {
    this.subscription.add(this._route.params.subscribe(param => {
      this.id = param.id;
      this.actionMode = param.action;
      this.activityName = param.name;
      this.getBeneficiaryList(this.id);
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getBeneficiaryList(id: number): void {
    if (id && id > 0) {
      this.subscription.add(this._activityExecutionService.getBeneficiaryListBasedOnActivityOrProgram(id, this._type).subscribe(response => {
        if (response && response.body) {
          if (this.rowsRetrieved == 0) {
            if (response.body.length > 5) {
              this.beneficiaryList = response.body.slice(0, 5);
              this.rowsRetrieved = this.beneficiaryList.length;
            }
            else {
              this.beneficiaryList = response.body;
              this.rowsRetrieved = this.beneficiaryList.length;
            }
            this.allBeneficiaryList = response.body;
          }
        } else {
          this._snackbar.open(this._translateService.instant('assessment-module.assessment.error.could-not-load-benef'), null, { duration: 3000 });
          this._type == 'activityExecution' ? this._router.navigate([`/enrollment/activities`]) : this._router.navigate([`/enrollment/program`]);
          this.id = undefined;
        }
      }, err => {
        this._snackbar.open(this._translateService.instant('assessment-module.assessment.error.could-not-load-benef'), null, { duration: 3000 });
        this._type == 'activityExecution' ? this._router.navigate([`/enrollment/activities`]) : this._router.navigate([`/enrollment/program`]);
        this.id = undefined;
      }));
    }
  }

  public getMoreCreateDate() {
    this.rowsRetrieved;
    let countOfAddedBenefs = 0;
    for (var i = this.rowsRetrieved; i < Math.min(this.rowsRetrieved + 5, this.allBeneficiaryList.length); i++) {
      this.beneficiaryList.push(this.allBeneficiaryList[i]);
      countOfAddedBenefs++;
    }

    this.createEmptyBenefAssessmentFormAfterScroll(this.rowsRetrieved, countOfAddedBenefs);
  }

  private createEmptyBenefAssessmentFormAfterScroll(rowsRetrieved: number, countOfAddedBenefs: number): FormGroup {

    const beneficiariesArray = this.beneficiariesFormArray as FormArray;

    let newList = this.beneficiaryList.slice(rowsRetrieved, rowsRetrieved + countOfAddedBenefs);
    newList.forEach(beneficiary => {
      beneficiariesArray.push(this.createEmptyBenef(beneficiary.name, beneficiary.id));
    });

    this.rowsRetrieved = beneficiariesArray.length;
    return this.form;
  }

  public getMoreViewDate() {
    this.rowsRetrieved;
    let countOfAddedResponses = 0;
    for (var i = this.rowsRetrieved; i < Math.min(this.rowsRetrieved + 5, this.allResponses?.responses.length); i++) {
      this.response.responses.push(this.allResponses?.responses[i]);
      countOfAddedResponses++;
    }
    this.getBenefAssessmentFromWithDataAfterScroll(this.rowsRetrieved, countOfAddedResponses);
  }

  private getBenefAssessmentFromWithDataAfterScroll(rowsRetrieved: number, countOfAddedResponses: number): FormGroup {
    const responses = this.response.responses;

    let newList = responses.slice(rowsRetrieved, rowsRetrieved + countOfAddedResponses);

    newList.forEach((assessment: AssessmentBeneficiary) => {
      if (assessment.sections && assessment.sections.length > 0) {
        this.beneficiariesFormArray.push(this.createAssessmentWithData(assessment));
      } else {
        this.beneficiariesFormArray.push(this.createEmptyBenef(assessment.beneficiaryName, assessment.beneficiaryId));
      }
    });
    this.rowsRetrieved = this.beneficiariesFormArray.length;

    return this.form;
  }

  private createEmptyBenefAssessmentForm(): FormGroup {
    const benefForm: FormGroup = this._formBuilder.group({
      assessment: new FormControl({ value: this.form?.controls['assessment']?.getRawValue(), disabled: false }, Validators.required),
      submitDate: new FormControl({ value: undefined, disabled: false }, Validators.required),
      benficiaries: this._formBuilder.array([]),
    });

    const beneficiariesArray = benefForm.get('benficiaries') as FormArray;

    this.beneficiaryList.forEach(beneficiary => {
      beneficiariesArray.push(this.createEmptyBenef(beneficiary.name, beneficiary.id));
    })

    return benefForm;
  }

  private createEmptyBenef(beneficiaryName: string, beneficiaryId: number): FormGroup {
    const benefFormGroup = this._formBuilder.group({
      id: new FormControl(null),
      assessmentId: new FormControl(this.assessmentId),
      beneficiaryId: new FormControl(beneficiaryId),
      beneficiaryName: new FormControl(beneficiaryName),
      totalGrade: new FormControl(null),
      title: new FormControl(null),
      sections: this._formBuilder.array([]),
    });

    const sectionsArray = benefFormGroup.get('sections') as FormArray;
    const columnWidths = this.assessment.sections.map(section => this.calculateSectionWidth(section));
    this.gridTemplateColumns = `1fr ${columnWidths.join(' ')}`;


    if (this.assessment.sections.length) {
      this.assessment.sections.forEach((sectionData: Section) => {
        sectionsArray.push(this.createEmptySection(sectionData));
      });
    }
    return benefFormGroup;
  }

  calculateSectionWidth(section: any): string {
    const criterionColumnWidth = 750;
    const totalCriterionWidth = section.assessmentCriterion.length * criterionColumnWidth;
    return `minmax(${totalCriterionWidth}px, 1fr)`;
  }

  private createEmptySection(section): FormGroup {
    const emptySectionForm = this._formBuilder.group({
      id: new FormControl(section ? section.id : null),
      commentId: new FormControl(null),
      title: new FormControl(
        { value: section ? section.title : '', disabled: false },
      ),
      comment: new FormControl(
        { value: '', disabled: false },
      ),
      assessmentCriterion: this._formBuilder.array([]),
    });

    const assessmentCriterionArray = emptySectionForm.get('assessmentCriterion') as FormArray;

    section.assessmentCriterion.forEach((criterionData: AssessmentCriterion) => {
      assessmentCriterionArray.push(this.createEmptyCriteria(criterionData, emptySectionForm));
    });

    return emptySectionForm;
  }

  private createEmptyCriteria(criterionData: AssessmentCriterion, sectionForm: FormGroup): FormGroup {
    const valueControl = new FormControl(
      { value: null, disabled: false },
    );
    const commentControl = new FormControl(
      { value: '', disabled: false },
    );

    this.subscription.add(valueControl.valueChanges.subscribe((value) => {
      this.checkCriteriaValues(sectionForm, value);
      if (value) {
        commentControl.setValidators([Validators.required, CustomValidators.noWhitespaceValidator]);
      } else {
        commentControl.removeValidators(Validators.required);
        commentControl.removeValidators(CustomValidators.noWhitespaceValidator);
      }
      commentControl.updateValueAndValidity();
    }));

    return this._formBuilder.group({
      id: new FormControl(criterionData ? criterionData.id : null),
      title: new FormControl(
        { value: criterionData ? criterionData.title : '', disabled: false },
      ),
      responseId: new FormControl(
        { value: null, disabled: false },
      ),
      minValue: new FormControl(
        { value: criterionData ? criterionData.minValue : null, disabled: false },
      ),
      maxValue: new FormControl(
        { value: criterionData ? criterionData.maxValue : null, disabled: false },
      ),
      value: valueControl,
      comment: commentControl,
    });
  }

  private checkCriteriaValues(sectionForm: FormGroup, value?: any): void {
    const assessmentCriterionArray = sectionForm.get('assessmentCriterion') as FormArray;

    const isCriterionFilled = (control: FormGroup) => {
      const controlValue = control.get('value').value;
      return controlValue !== null && controlValue !== '' && controlValue !== undefined;
    };

    const filledCriteriaCount = assessmentCriterionArray.controls.filter(isCriterionFilled).length;

    const sectionCommentControl = sectionForm.get('comment');
    const hasFilledCriteria = filledCriteriaCount > 0;

    if (hasFilledCriteria) {
      sectionCommentControl.setValidators([Validators.required]);
    } else {
      sectionCommentControl.clearValidators();
    }

    sectionCommentControl.updateValueAndValidity();
  }

  private createFormGroups(): void {
    this.form = this.actionMode == 'EDIT_MODE'
      ? this.createBenefAssessmentFromWithData(this.response)
      : this.createEmptyBenefAssessmentForm()
  }

  private createAssessmentDetails(): void {
    this.assessmentDetails = [];
    if (this.assessment && this.assessment?.sections?.length > 0) {
      this.assessment.sections.forEach(section => {
        let sectionInstance = section;
        let assessmentSectionDetails: AssessmentSectionDetails = {
          section: sectionInstance.title,
          criteria: [],
          width: `calc((100% / ${section.assessmentCriterion.length}) - 8px)`
        }
        section.assessmentCriterion.forEach(criterion => {
          assessmentSectionDetails.criteria.push(criterion.title);
        });
        this.assessmentDetails.push(assessmentSectionDetails);
      })
    }
  }

  get beneficiariesFormArray(): FormArray {
    return this.form.get('benficiaries') as FormArray;
  }

  private createBenefAssessmentFromWithData(assessmentData: AssessmentBeneficiaryObject): FormGroup {
    const assessmentForm = this._formBuilder.group({
      assessment: new FormControl({ value: this.form?.controls['assessment']?.getRawValue(), disabled: false }, Validators.required),
      submitDate: new FormControl({ value: assessmentData.submitDate, disabled: false }, Validators.required),
      benficiaries: this._formBuilder.array([]),
    });

    const beneficiariesArray = assessmentForm.get('benficiaries') as FormArray;

    assessmentData.responses.forEach((assessment: AssessmentBeneficiary) => {
      if (assessment.sections && assessment.sections.length > 0) {
        beneficiariesArray.push(this.createAssessmentWithData(assessment));
      } else {
        beneficiariesArray.push(this.createEmptyBenef(assessment.beneficiaryName, assessment.beneficiaryId))
      }
    });

    return assessmentForm;
  }

  private createAssessmentWithData(assessmentData: AssessmentBeneficiary): FormGroup {
    this.assessmentId = assessmentData.assessmentId;
    const assessmentForm = this._formBuilder.group({
      id: new FormControl(assessmentData.id),
      assessmentId: new FormControl(assessmentData.assessmentId),
      activityAssessmentId: new FormControl(assessmentData.activityAssessmentId),
      beneficiaryId: new FormControl(assessmentData.beneficiaryId),
      beneficiaryName: new FormControl(assessmentData.beneficiaryName),
      totalGrade: new FormControl(assessmentData.totalGrade),
      title: new FormControl(assessmentData.title),
      sections: this._formBuilder.array([]),
    });

    const sectionsArray = assessmentForm.get('sections') as FormArray;
    const columnWidths = assessmentData.sections.map(section => this.calculateSectionWidth(section));
    this.gridTemplateColumns = `1fr ${columnWidths.join(' ')}`;

    if (assessmentData.sections.length) {
      assessmentData.sections.forEach((sectionData: Section) => {
        sectionsArray.push(this.createSectionWithData(sectionData));
      });
    } else {
      if (this.assessment.sections.length) {
        this.assessment.sections.forEach((sectionData: Section) => {
          sectionsArray.push(this.createEmptySection(sectionData));
        });
      }
    }
    return assessmentForm;
  }

  private createSectionWithData(sectionData: Section | AssessmentSection = null): FormGroup {
    const sectionFormGroup = this._formBuilder.group({
      id: new FormControl(sectionData ? sectionData.id : null),
      commentId: new FormControl(sectionData ? sectionData.commentId : null),
      title: new FormControl(
        { value: sectionData ? sectionData.title : '', disabled: false },
      ),
      comment: new FormControl(
        { value: sectionData ? sectionData.comment : '', disabled: false },
      ),
      assessmentCriterion: this._formBuilder.array([]),
    });

    if (sectionData && sectionData.assessmentCriterion) {
      const assessmentCriterionArray = sectionFormGroup.get('assessmentCriterion') as FormArray;

      sectionData.assessmentCriterion.forEach((criterionData: AssessmentCriterion) => {
        assessmentCriterionArray.push(this.createCriteriaWithData(criterionData, sectionFormGroup));
      });
    }
    this.checkCriteriaValues(sectionFormGroup);


    return sectionFormGroup;
  }

  private createCriteriaWithData(criterionData: AssessmentCriterion = null, sectionForm: FormGroup): FormGroup {
    const valueControl = new FormControl(
      { value: criterionData ? criterionData.value : null, disabled: false },
    );
    const commentControl = new FormControl(
      { value: criterionData ? criterionData.comment : '', disabled: false },
    );

    if (criterionData.value) {
      commentControl.setValidators([Validators.required, CustomValidators.noWhitespaceValidator]);
      commentControl.updateValueAndValidity();
    }

    this.subscription.add(valueControl.valueChanges.subscribe((value) => {
      this.checkCriteriaValues(sectionForm, value);
      if (value) {
        commentControl.setValidators([Validators.required, CustomValidators.noWhitespaceValidator]);
      } else {
        commentControl.removeValidators(Validators.required);
        commentControl.removeValidators(CustomValidators.noWhitespaceValidator);
      }
      commentControl.updateValueAndValidity();
    }));

    return this._formBuilder.group({
      id: new FormControl(criterionData ? criterionData.id : null),
      title: new FormControl(
        { value: criterionData ? criterionData.title : '', disabled: false },
      ),
      responseId: new FormControl(
        { value: criterionData ? criterionData.responseId : null, disabled: false },
      ),
      minValue: new FormControl(
        { value: criterionData ? criterionData.minValue : null, disabled: false },
      ),
      maxValue: new FormControl(
        { value: criterionData ? criterionData.maxValue : null, disabled: false },
      ),
      value: valueControl,
      comment: commentControl,
    });
  }

  public goBack(): void {
    this._type == 'activityExecution' ? this._router.navigate([`/enrollment/activities`]) : this._router.navigate([`/enrollment/program`]);
  }

  private validateFormForSave(): string {
    let error: string = undefined;
    if (this.form) {
      formHelper.trimAllFormValues(this.form);
      formHelper.markAllControlsAsTouched(this.form)
      if (!error) error = !this.form.valid ? this._translateService.instant('assessment-module.assessment.error.form-not-valid') : undefined;
    }
    return error;
  }

  public fillAssessment(): void {
    let error = this.validateFormForSave();
    if (!error) {
      let beneficiaryAssessmentObject: BeneficiaryAssessmentObject = {
        assessmentId: this.assessmentId,
        activityId: this._type == 'activityExecution' ? this.id : null,
        programId: this._type == 'program' ? this.id : null,
        activityAssessmentId: this.activityAssessmentId ? this.activityAssessmentId : null,
        beneficiaryAssessments: null,
        submitDate: this._datePipe.transform(this.form.controls['submitDate'].getRawValue(), 'yyyy-MM-dd')
      }

      if (this.actionMode === 'EDIT_MODE') {
        let data = [];
        if (this.response.responses.length <= this.allResponses.responses.length) {
          this.beneficiariesFormArray.controls.forEach(beneficiary => {
            let responses = [];
            let sectionComments = [];
            beneficiary.get('sections').getRawValue().map((section: AssessmentBenefSection) => {
              sectionComments.push({
                id: section.commentId,
                assessmentId: this.assessmentId,
                assessmentSectionId: section.id ? section.id : null,
                comment: section.comment,
              })
              section.assessmentCriterion.map((criteria: AssessmentBenefCriterion) => {
                responses.push(
                  {
                    id: criteria.responseId ? criteria.responseId : null,
                    assessmentId: this.assessmentId,
                    assessmentCriterionId: criteria.id,
                    value: criteria.value,
                    comment: criteria.comment,
                  }
                )
              });
            });

            data.push({
              id: beneficiary?.value.id ? beneficiary?.value.id : null,
              activityAssessmentId: beneficiary.value.activityAssessmentId ? beneficiary.value.activityAssessmentId : null,
              assessmentId: beneficiary.value.assessmentId,
              beneficiaryId: beneficiary.value.beneficiaryId,
              responses: responses,
              sectionComments: sectionComments,
            });
          });

          //=================================== add missing responses ===========================
          const notAddedResponsesForm = new FormArray<FormGroup>(
            this.allResponses.responses.filter(response =>
              !this.response.responses.some(r => r.beneficiaryId === response.beneficiaryId))
              .map(response => {
                const sections = response.sections.map(section => {
                  const assessmentCriterionArray = section.assessmentCriterion.map(criterion => {
                    return this._formBuilder.group({
                      id: new FormControl({ value: criterion.id, disabled: false }),
                      title: new FormControl({ value: criterion.title, disabled: false }),
                      responseId: new FormControl({ value: criterion.responseId, disabled: false }),
                      minValue: new FormControl({ value: criterion.minValue, disabled: false }),
                      maxValue: new FormControl({ value: criterion.maxValue, disabled: false }),
                      value: new FormControl({ value: criterion.value, disabled: false }),
                      comment: new FormControl({ value: criterion.comment, disabled: false }),
                    });
                  });

                  const formGroup = this._formBuilder.group({
                    id: new FormControl({ value: section.id, disabled: false }),
                    title: new FormControl({ value: section.title, disabled: false }),
                    commentId: new FormControl({ value: section.commentId, disabled: false }),
                    assessmentCriterion: this._formBuilder.array(assessmentCriterionArray),
                  });
                  return formGroup;
                });

                const formGroup = this._formBuilder.group({
                  id: new FormControl({ value: response.id ? response.id : null, disabled: false }),
                  assessmentId: new FormControl({ value: response.assessmentId ? response.assessmentId : null, disabled: false }),
                  beneficiaryId: new FormControl({ value: response.beneficiaryId ? response.beneficiaryId : null, disabled: false }),
                  beneficiaryName: new FormControl({ value: response.beneficiaryName ? response.beneficiaryName : null, disabled: false }),
                  activityAssessmentId: new FormControl({ value: response.activityAssessmentId ? response.activityAssessmentId : null, disabled: false }),
                  totalGrade: new FormControl({ value: response.totalGrade ? response.totalGrade : null, disabled: false }),
                  title: new FormControl({ value: response.title ? response.title : null, disabled: false }),
                  submitDate: new FormControl({ value: response.submitDate ? response.submitDate : null, disabled: false }),
                  sections: this._formBuilder.array(sections),
                });

                return formGroup;
              })
          );

          notAddedResponsesForm.controls.forEach(addedResponses => {
            const notAddedresponses = [];
            const notAddedsectionComments = [];
            addedResponses.get('sections').getRawValue().map((section: AssessmentBenefSection) => {
              notAddedsectionComments.push({
                id: section.commentId,
                assessmentId: this.assessmentId,
                assessmentSectionId: section.id ? section.id : null,
                comment: section.comment,
              })
              section.assessmentCriterion.forEach(criterion => {
                const criterea = criterion;
                notAddedresponses.push({
                  id: criterea.responseId ? criterea.responseId : null,
                  assessmentId: this.assessmentId,
                  assessmentCriterionId: criterea.id,
                  value: criterea.value,
                  comment: criterea.comment,
                });
              });
            });

            data.push({
              id: addedResponses?.value.id ? addedResponses?.value.id : null,
              activityAssessmentId: addedResponses.value.activityAssessmentId ? addedResponses.value.activityAssessmentId : null,
              assessmentId: addedResponses.value.assessmentId,
              beneficiaryId: addedResponses.value.beneficiaryId,
              responses: notAddedresponses,
              sectionComments: notAddedsectionComments,
            });
          })
        }

        beneficiaryAssessmentObject.beneficiaryAssessments = data;
      }
      else {
        let data = [];
        if (this.beneficiariesFormArray.length < this.allBeneficiaryList.length) {
          const beneficiariesArray = this.beneficiariesFormArray as FormArray;
          this.allBeneficiaryList.forEach(allBeneficiary => {
            if (!this.beneficiaryList.some(beneficiary => beneficiary.id === allBeneficiary.id)) {
              this.beneficiaryList.push(allBeneficiary);
              beneficiariesArray.push(this.createEmptyBenef(allBeneficiary.name, allBeneficiary.id));
            }
          });
        }

        this.beneficiariesFormArray.controls.forEach(beneficiary => {
          const responses = [];
          const sectionComments = [];
          beneficiary.get('sections').getRawValue().map((section: AssessmentBenefSection) => {
            sectionComments.push({
              id: section.commentId,
              assessmentId: this.assessmentId,
              assessmentSectionId: section.id ? section.id : null,
              comment: section.comment,
            })
            section.assessmentCriterion.map((criteria: AssessmentBenefCriterion) => {
              responses.push(
                {
                  id: criteria.responseId ? criteria.responseId : null,
                  assessmentId: this.assessmentId,
                  assessmentCriterionId: criteria.id,
                  value: criteria.value,
                  comment: criteria.comment,
                }
              )
            });
          });

          data.push({
            id: beneficiary?.value.id ? beneficiary?.value.id : null,
            activityAssessmentId: beneficiary.value.activityAssessmentId ? beneficiary.value.activityAssessmentId : null,
            assessmentId: beneficiary.value.assessmentId,
            beneficiaryId: beneficiary.value.beneficiaryId,
            responses: responses,
            sectionComments: sectionComments,
          });
        });

        beneficiaryAssessmentObject.beneficiaryAssessments = data;
      }

      this._assessmentService.saveAssessmentResponse(beneficiaryAssessmentObject).subscribe(response => {
        if (response) {
          //Commented out to prevent perfomance issue and reddirected to the main screen.
          // this.response = response.body;
          // this.createFormGroups();
          this._snackbar.open(this._translateService.instant('assessment-module.assessment.success.assessment-saved'), null, { duration: 3000 });
          this.goBack();
        } else {
          this._snackbar.open(this._translateService.instant('assessment-module.assessment.error.assessment-failed'), null, { duration: 3000 });
        }
      }, error => {
        this._snackbar.open(this._translateService.instant('assessment-module.assessment.error.assessment-failed'), null, { duration: 3000 });
      });
    }
    else {
      this._snackbar.open(error, null, { duration: 3000 });
    }
  }

  public assessmentTypeChanged(event): void {
    this._assessmentService.getAssessmentListByType(event.value.id).subscribe(assessments => {
      this.form.controls['assessment'].reset();
      this.assessments = assessments.body;
    })
  }

  public assessmentChanged(event): void {
    this.assessmentId = null;
    if (event.value != undefined) {
      this.showSubmitDate = true;
      //using value.key since in both cases add and edit key has the assessment value
      this._activityExecutionService.getAssessmentCriteria(event.value.key).pipe(concatMap(assessments => {
        this.assessment = assessments.body;
        this.page = 0;
        this.totalPages = 0;
        this.createAssessmentDetails();
        this.assessmentId = event.value.key;
        if (this.actionMode === 'ADD_MODE') {
          const assessmentBeneficiary: AssessmentBeneficiaryObject = {
            responses: [],
            submitDate: undefined
          };
          return of(assessmentBeneficiary);
        }
        else {
          this.activityAssessmentId = event.value.id;
        }
        return this._activityExecutionService.getResponseAssessment(event.value.id, this.id, this._type, this.page, this.pageSize);
      })).subscribe({
        next: (data) => {
          this.allResponses = data;
          this.totalPages = data.totalPages;
          this.response = {
            submitDate: this.allResponses.submitDate,
            responses: this.allResponses.responses
          };

          if (data.responses.length > 0)
            this.rowsRetrieved = this.response?.responses?.length ?? 0;

          this.createFormGroups();
        }
        ,
        error: (_) => {
          this._snackbar.open(this._translateService.instant('assessment-module.assessment.error.could-not-load-assessments'), null, { duration: 3000 });
        }
      });
    }
  }


  onScroll(event: Event) {
    this.actionMode == 'ADD_MODE' ? this.getMoreCreateDate() : this.loadMoreAssessments();
    this.message = 'End reached';
  }

  /**
   * Function used to load more assessments using pagination
   */
  private loadMoreAssessments(): void {
    if (this.page < this.totalPages - 1) {
      this._activityExecutionService.getResponseAssessment(this.activityAssessmentId, this.id, this._type, ++this.page, this.pageSize).subscribe(data => {
        this.response.responses.concat(data.responses);
        this.rowsRetrieved = this.response.responses.length;
        data.responses.forEach((assessment: AssessmentBeneficiary) => {
          if (assessment.sections && assessment.sections.length > 0) {
            this.beneficiariesFormArray.push(this.createAssessmentWithData(assessment));
          } else {
            this.beneficiariesFormArray.push(this.createEmptyBenef(assessment.beneficiaryName, assessment.beneficiaryId));
          }
        });
      });
    }
  }
}
