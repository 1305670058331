import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageFileType'
})
export class ImageFileTypePipe implements PipeTransform {

  transform(fileType: string): boolean {
    return (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png');
  }

}
