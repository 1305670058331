<div>
  <!-- MOBILE VIEW MODE -->
  <div *ngIf="_isMobile">
    <!-- <h1>Mobile view for responsive view</h1> -->
    <app-card-list
      [selection]="selection"
      (selectionEventEmitter)="handleSelection($event)"
      [tableColumns]="tableColumns"
      [withAdd]="withAdd"
      [deleteMode]="deleteMode"
      [selectionMode]="selectionMode"
      [maxStringLength]="maxStringLength"
      (mode)="updateViewMode($event)"
      (deleteEventEmitter)="deleteObject($event)"
      [tableDataSource]="tableDataSource"
      [isLoading]="isLoading"
      [databaseChangeEventSubject]="databaseChangeEventSubject"
      [withInlineMenu]="withInlineMenu"
      [_tableMenuAction]="_tableMenuAction"
      [addButtonLabel]="addButtonLabel"
      [tableTitleLabel]="tableTitleLabel"
      (menuActionEventEmitter)="tableMenuActionHandler($event)"
      [showFilterButton]="showFilterButton"
      [inlineEditable]="inlineEditable"
      [showBackButton]="showBackButton"
      [activeInlineEditingRows]="activeInlineEditingRows"
      [inlineEditable]="inlineEditable">
      <ng-content select="[filter-content-mobile]"></ng-content>
    </app-card-list>
  </div>

  <!-- DESKTOP VIEW MODE -->
  <div *ngIf="!_isMobile">
    <!-- <h1>Desktop view for responsive view</h1> -->
    <app-datatable
      [selection]="selection"
      (selectionEventEmitter)="handleSelection($event)"
      [tableColumns]="tableColumns"
      [withAdd]="withAdd"
      [deleteMode]="deleteMode"
      [selectionMode]="selectionMode"
      [maxCellStringLength]="maxCellStringLength"
      (mode)="updateViewMode($event)"
      (deleteEventEmitter)="deleteObject($event)"
      [tableDataSource]="tableDataSource"
      [isLoading]="isLoading"
      [databaseChangeEventSubject]="databaseChangeEventSubject"
      [_tableMenuAction]="_tableMenuAction"
      [withInlineMenu]="withInlineMenu"
      [booleanIconColumnDisplayList]="booleanIconColumnDisplayList"
      [addButtonLabel]="addButtonLabel"
      [tableTitleLabel]="tableTitleLabel"
      (menuActionEventEmitter)="tableMenuActionHandler($event)"
      [showFilterButton]="showFilterButton"
      [inlineEditable]="inlineEditable"
      [showBackButton]="showBackButton"
      [activeInlineEditingRows]="activeInlineEditingRows"
      [inlineEditable]="inlineEditable"
      (sortingEventEmitter)="sortingHandler($event)"
      [isServerSideSorting]="isServerSideSorting"
      >
      <ng-content select="[filter-content]"></ng-content>
    </app-datatable>
  </div>

  <mat-paginator class="responsive-paginator"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [length]="currentPage?.totalElements"
    aria-label="Select page"
    [pageSize]="initialPageSize"
    [pageIndex]="initialPageIndex"
    showFirstLastButtons="true" (page)="getServerData(reloadLookup)" >
  </mat-paginator>

  <button class="btn" mat-flat-button color="accent" *ngIf="this.selection?.selected?.length > 0" (click)="submitSelection()">Select</button>
</div>
