import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class beneficiaryStatusService extends BaseService<any, any> {

  constructor(
    http: MyHttpHandler,
    private lookupService: LookupService,
    protected injector: Injector
  ){
    super("/v1/status/beneficiary-status", http, injector);
  }

  deleteBeneficiaryStatus(beneficiaryStatusId: number): Observable<HttpResponse<String>> {
    return this.http.delete<String>("/v1/status/beneficiary-status/"+beneficiaryStatusId);
  }
}
