<form [formGroup]="form" class="container" (ngSubmit)="saveClassification()">

  <app-title [title]="'classification.title' | translate" [goBackCallback]="goBack"></app-title>

  <div class="classification">
    <!-- TYPE -->
    <div class="col-lg-4 col-sm-6 col-12">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{ 'classification.fields.type' | translate }}</mat-label>
        <mat-select class="" formControlName="classificationType">
          <mat-option *ngFor="let type of classificationTypes" [value]="type.id">
            {{ type?.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Name -->
    <div class="col-lg-4 col-sm-6 col-12">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'classification.fields.name' |
          translate}}</mat-label>
        <input matInput class="" formControlName="riskLevel">
      </mat-form-field>
    </div>
    <!-- Min Value -->
    <div class="col-lg-4 col-sm-6 col-12">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'classification.fields.min-value' |
          translate}}</mat-label>
        <input matInput class="" type="number" formControlName="minValue" min="0"
          [max]="this.form?.get('maxValue') ? this.form?.get('maxValue').value : 9999">
      </mat-form-field>
    </div>
    <!-- Max value -->
    <div class="col-lg-4 col-sm-6 col-12">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'classification.fields.max-value' |
          translate}}</mat-label>
        <input matInput class="" type="number" formControlName="maxValue"
          [min]="this.form?.get('minValue') ? this.form?.get('minValue').value : 0" max="9999">
      </mat-form-field>
    </div>
    <!--Color-->
    <div class="col-lg-4 col-sm-6 col-12">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'classification.fields.risk-color' |
          translate}}</mat-label>
        <input matInput [style.background]="chosenColor" [style.color]="chosenColor" [cpOKButton]="true"
          [cpSaveClickOutside]="true" [cpOKButtonClass]="'btn btn-primary btn-xs'" [(colorPicker)]="chosenColor"
          formControlName="riskColor" [cpOutputFormat]="'hex'" (colorPickerChange)="updateColor($event)" />
      </mat-form-field>
    </div>
    <!--Save button-->
    <div class="col-lg-4 col-sm-6 col-12 save-button-place">
      <button type="submit" [ngClass]="currentLang == 'ar' ? 'btn-submit-rtl' : '' " class="btn btn-submit">
        <span class="btn save-button">{{'global.save' | translate}}</span>
      </button>
    </div>
  </div>


</form>