<form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
  <div>
    <mat-form-field appearance="outline" class="input-change-password login-input-field">
      <mat-label>{{ "siren-admin-translation.admin_module.profile.old_password" | translate }}</mat-label>
      <input matInput autocomplete="off" formControlName="oldPassword" [type]="showPassword ? 'text' : 'password'"
        class="font-weight-bold" />
      <mat-icon *ngIf="showPassword" matSuffix (click)="tooglePassword()"><i style="font-size: 24px;"
          class="fa fa-eye"></i></mat-icon>
      <mat-icon *ngIf="!showPassword" matSuffix (click)="tooglePassword()"><i style="font-size: 24px;"
          class="fa fa-eye-slash"></i></mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline" class="input-change-password login-input-field">
      <mat-label>{{ "siren-admin-translation.admin_module.profile.new_password" | translate }}</mat-label>
      <input matInput autocomplete="off" formControlName="newPassword" [type]="showNewPassword ? 'text' : 'password'"
        class="font-weight-bold" />
      <mat-icon *ngIf="showNewPassword" matSuffix (click)="toogleNewPassword()"><i style="font-size: 24px;"
          class="fa fa-eye"></i></mat-icon>
      <mat-icon *ngIf="!showNewPassword" matSuffix (click)="toogleNewPassword()"><i style="font-size: 24px;"
          class="fa fa-eye-slash"></i></mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline" class="input-change-password login-input-field">
      <mat-label>{{ "siren-admin-translation.admin_module.profile.confirm_password" | translate }}</mat-label>
      <input matInput autocomplete="off" formControlName="confirmNewPassword"
        [type]="showConfirmPassword ? 'text' : 'password'" class="font-weight-bold" />
      <mat-icon *ngIf="showConfirmPassword" matSuffix (click)="toogleConfirmPassword()"><i style="font-size: 24px;"
          class="fa fa-eye"></i></mat-icon>
      <mat-icon *ngIf="!showConfirmPassword" matSuffix (click)="toogleConfirmPassword()"><i style="font-size: 24px;"
          class="fa fa-eye-slash"></i></mat-icon>
    </mat-form-field>
  </div>

  <div>
    <div
      *ngIf="this.errorPassComplexity != undefined && this.errorPassComplexity.length > 0"
      class="alert alert-danger"
    >
      {{ 'siren-admin-translation.generic.complexity_not_respected' | translate }}
      <div *ngFor="let error of errorPassComplexity">
        <span style="padding-inline-start: 8px;">- {{ error }}</span>
      </div>
    </div>
    <div *ngIf="errorPassNoMatch" class="alert alert-danger">{{
      'siren-admin-translation.generic.confirmation_do_not_match' | translate }}</div>
  </div>

  <button type="submit"
    class=" btn btn-submit full-width-element">{{'siren-admin-translation.admin_module.profile.submit' |
    translate}}</button>
</form>