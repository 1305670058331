<div class="input_styling">
  <div class="wrapping_square" [ngClass]="{invalid: fieldInvalid()}">
    <mat-form-field [ngClass]="{'with-clear': withClear}" color="primary" class="calendar" appearance="outline">
      <mat-label>{{placeholdertext | translate}}{{fieldRequired() ? "*" : ""}}
      </mat-label>
      <input class="pointer" [formControl]="ngControl.control" matInput [matDatepicker]="picker" [min]="minDate"
        [max]="maxDate" (click)="picker.open()" (keydown)="false">
        <mat-icon [ngClass]="{'disabled-icon': clearBtnDisabled}" *ngIf="withClear && ngControl.control.value"
         (click)="clear()">clear</mat-icon>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker touchUi="true" #picker [startView]="startView"></mat-datepicker>
    </mat-form-field>
  </div>
  <app-error-display [control]="ngControl.control">
  </app-error-display>
</div>