import { NgModule, Provider } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
// import { AuthService } from '../services/auth.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SpinnerService } from '../services/spinner.service';
import { SnackbarService } from '../services/snackbar.service';
import { UtilsService } from '../services/utils.service';
import { ToolbarDrawerService } from '../services/toolbar-drawer.service';
import { IAdminAuthenticationService } from '../services/adminAuthentication.interface';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', `.json?v=${new Date().getTime()}`);
}

@NgModule({})
export class ServicesModule {
  static forShared(): Provider[] {
    return [
      LocalStorageService,
      // AuthService,
      // TODO interface cant be set in provider
      // ISurveyAuthenticationService,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }).providers,
      TranslateService,
      SpinnerService,
      SnackbarService,
      UtilsService,
      ToolbarDrawerService
    ];
  }
}
