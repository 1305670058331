import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, forkJoin, map, of } from 'rxjs';
import { LookupService } from './lookup.service';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { SurveyService } from '../survey.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SurveyLookupsResolver implements  Resolve<{ lookups: LookupModel[],surveyTypes: LookupModel[] }> {
  constructor(
    private _lookupService: LookupService,
    private _surveyService: SurveyService,
    private _translateService: TranslateService ) {}

  resolve(): Observable<{ lookups: LookupModel[], surveyTypes: LookupModel[] }> {
    return forkJoin(
      [this._lookupService.getLookupsService(["AnswerType", "Gender", "Region"]),
      this._surveyService.getSurveyTypes(this._translateService.currentLang)]).pipe(
      map((allResponses: any) => {
        return {
          lookups: allResponses[0]?.body,
          surveyTypes: allResponses[1]?.body
      };
      })
    );
  }


}
