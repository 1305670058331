import { HttpResponse } from "@angular/common/http";
import { ComponentFactoryResolver, ComponentRef, Injectable, Injector } from "@angular/core";
import { LookupModel } from "@siren-survey/app/models/shared/lookup.model";
import { MyHttpHandler } from "@siren-survey/app/services/shared/http-handler.service";
import { ISurveyTopicService } from "@siren-survey/app/shared/services/interfaces/survey-topic.interface";

import { Observable } from "rxjs";
import { OrganizationSurvey, SurveyTopic } from "src/app/models/survey/survey-topic.models";

@Injectable({
  providedIn: 'root'
})
export class SurveyTopicService implements ISurveyTopicService{

  constructor(private myHttp: MyHttpHandler, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector){
  }

  public saveOrganizationSurvey(body : OrganizationSurvey): Observable<HttpResponse<OrganizationSurvey>>{
    return this.myHttp.post<OrganizationSurvey>("/v1/survey-topic", body);
  }


  public getProgramSurveyTopicList(surveyId: number): Observable<HttpResponse<SurveyTopic>>{
    if(surveyId != null && surveyId > 0 ) return this.myHttp.get<SurveyTopic>("/v1/survey-topic/" + surveyId);
  }

  public getOrganizationProgramTopic(organizationId: number): Observable<HttpResponse<LookupModel[]>> {
    return this.myHttp.get<LookupModel[]>("/v1/survey-topic/program/" + organizationId);
  }

  public getProgramSurveyTopicForSurvey(surveyId: number): Observable<HttpResponse<LookupModel[]>> {
    return this.myHttp.get<LookupModel[]>("/v1/survey-topic/" + surveyId);
  }

  public getOrganizationProgramClassesTopic(organizationId: number, programId: number): Observable<HttpResponse<LookupModel[]>> {
    return this.myHttp.get<LookupModel[]>("/v1/survey-topic/class/" + organizationId + "/" + programId);
  }

  public getProgramActivitiesTopic(programId: number): Observable<HttpResponse<LookupModel[]>> {
    return this.myHttp.get<LookupModel[]>("/v1/survey-topic/activity/" + programId);
  }

  public deleteSurveyTopic(surveyTopicId: number): Observable<HttpResponse<String>> {
    return this.myHttp.delete<String>("/v1/survey-topic/" + surveyTopicId);
  }

  public getOrganizationSurveyLookup(surveyId: number): Observable<HttpResponse<LookupModel[]>>{
    if(surveyId != undefined && surveyId > 0){
      return this.myHttp.get<LookupModel[]>("/v1/survey-topic/topic-lookup/" + surveyId);
    }
  }

  public getActivityExecutionListLookup(organizationSurveyId: number): Observable<HttpResponse<LookupModel[]>>{
    if(organizationSurveyId != undefined && organizationSurveyId > 0){
      return this.myHttp.get<LookupModel[]>("/v1/survey-topic/activity-execution/" + organizationSurveyId);
      }
  }

}
