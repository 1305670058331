import { BrowserListener } from "../../../services/utils/browser-listener.service";

export class DropdownComparatorWithBrowserListener extends BrowserListener{
    public objectComparisonFunction = function( option: any, value :any ) : boolean {

      return option && value ? option.id == value.id : false;
    }

    public enumerationComparisonFunction = function( option: any, value :any ) : boolean {
      return option && value ? option == value : false;
    }

    public objectKeyComparisonFunction(object1: any, object2: any): boolean {

      return object1 && object2 ? object1.key == object2.key : object1 === object2;
    }
}
