<div class="card-style">
  <mat-accordion class="profile-headers-align" multi >
    <mat-expansion-panel [expanded]="false" (opened)="false" class="incident-panel">
      <mat-expansion-panel-header style="height: 100%;">
        <i *ngIf="incident && !incident.external" class="fa fa-exclamation-triangle incident-danger-marker"></i>
        <mat-panel-title class="panel-title incident-col">
          <div class="incident-title-container">
            <span style="width: 100%;">{{incident?.title}}</span>
          </div>
          <div class="incident-panel-subtitle">
            <span>
              <span class="grey-out">{{ getIncidentSubTitle() }}</span>
            </span>
            <span class="grey-out incident-date">
              {{this.myDateString}}
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div style="padding-top: 10px; display: flex; flex-direction: column; align-items: center;">
        <span *ngIf="incident?.securityForces && incident?.securityForces != ''">
          <b>{{ 'incidents-feed.involved-security' | translate }} {{ incident?.securityForces }}</b>
        </span>
        <span style="padding-top: 5px">
          {{incident?.description}}
        </span>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
