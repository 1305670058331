import { FormGroup } from "@angular/forms";



export class MenuActionEmittedObject{
  object: any;
  actionName: string;
  form: FormGroup;

  constructor(object: any, actionName: string, form : FormGroup){
    this.object = object;
    this.actionName = actionName;
    this.form = form;
  }
}

export interface MenuActionObject{
  name: string;
  actionName: string;
  iconUrl: string;
  iconStyle: string;
  // accessRightVariablePath: string;
  // accessRightVariableExpression?: string;

  accessRightExpressions?: Map<string, string>;
  allowedAction?: boolean;
  fontAwesomeIcon?: string;
}
