<div class="input_styling">
  <div [ngClass]="{invalid: fieldInvalid()}">
    <mat-form-field color="primary" appearance="outline">
      <mat-label>{{ placeholdertext | translate }}
      </mat-label>
      <input [autocomplete]="isPassword? 'on' : 'off'" [type]="type ? type : (isPassword? 'password' : 'text')" matInput
        [maxlength]=maxLength [formControl]="ngControl.control" />
    </mat-form-field>
    <app-error-display [control]="ngControl.control">
    </app-error-display>
  </div>
</div>
