<form [formGroup]="changeOrganizationForm" (ngSubmit)="onChangeOrganization()" *ngIf="organizations">
  <p style="font-weight: 500; font-family: var(--app-font-family); color: #979595;">
    {{ 'siren-admin-translation.admin_module.profile.active-organization' | translate }}
  </p>
  <div>
    <mat-form-field class="full-width-element">
      <mat-label class="" >{{'siren-admin-translation.admin_module.profile.organizations' | translate}}</mat-label>
      <mat-select class="" formControlName="organization" [compareWith]="objectComparisonFunction">
        <mat-option *ngFor="let organization of organizations" [value]="organization" >
          {{ organization.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <button type="submit" class="full-width-element btn btn-submit" [disabled]="!enableChangeOrganizationButton()">{{'siren-admin-translation.admin_module.profile.submit' | translate}}
  </button>
</form>
