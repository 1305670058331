import { Injectable } from '@angular/core';
import {
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Resolve
} from '@angular/router';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { Observable, map, of } from 'rxjs';
import { LookupModel } from 'src/app/models/shared/lookup.model';

@Injectable({
  providedIn: 'root'
})

export class ClassificationResolver implements Resolve<LookupModel[]> {

  constructor(private _lookupService: LookupService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LookupModel[]> {
    return this._lookupService.getLookupsService(["ClassificationType"]).pipe(
      map(result => {
        return result.body;
      })
    );
  }
}
