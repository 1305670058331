import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Class } from '../../../models/admin/program/class/class';
import { ClassList } from '../../../models/admin/program/class/classList';

@Injectable({
  providedIn: 'root'
})
export class ClassesService  extends BaseService<ClassList, Class> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/class/class", http, injector);
  }

}
