import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ISessionService } from '../../../services/shared/interfaces/session-service.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'any'
})
export class TranslateResolver implements Resolve<Observable<any>> {
	constructor(public translate: TranslateService, private session: ISessionService) {

	}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<Observable<any>> | Promise<Observable<any>> {
		this.translate.currentLang = '';
		const browserLang = this.session && this.session.getActiveLanguage() !== '' ? this.session.getActiveLanguage() : 'en';
		return this.translate.use(browserLang).pipe(map( response => response ));
	}
}
