import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LookupModel } from '../../siren_survey_web/src/app/models/shared/lookup.model';
import { LookupService } from '../../siren_survey_web/src/app/services/lookups/lookup.service';
import { SessionService } from '../../services/shared/session.service';
import { BrowserListener } from '../../siren_survey_web/src/app/services/utils/browser-listener.service';
import { Location } from '@angular/common';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchModuleEnum, MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { LoginResponse } from 'src/app/models/auth/login-response.model';
import { UserRole } from '@siren-auth/app/admin/user-roles/user-roles.model';
import { SnackbarService } from '@siren-auth/app/shared/services/snackbar.service';
import { Observable, Subscription, interval, map, takeWhile } from 'rxjs';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { NotificationObject } from 'src/app/models/notification/NotificationObject';
import { MenuService } from './side-menu/menu.service';
import { Menu } from './side-menu/menu.model';
import { Incident, IncidentFeed } from 'src/app/models/incident/incident';
import { IncidentService } from 'src/app/services/incident/incident.service';
import { SivisService } from 'src/app/services/sivis/sivis.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.sass']
})
export class LandingPageComponent extends BrowserListener implements OnInit {
  isMenuPerSurveyType = this.settingsService && this.settingsService.settings && this.settingsService.settings.menuPerSurveyType ? this.settingsService.settings.menuPerSurveyType : false;
  navbarOpened = false;
  bottomNavbarOpened = false;

  systemAdminOpened = false;
  mobileSystemAdminOpened = false;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  surveyMobileModuleOpen = false;
  programMobileModuleOpen = false;
  adminMobileModuleOpen = false;
  profileMobileModuleOpen = false;
  incidentMobileModuleOpen = false;
  reportMobileModuleOpen = false;
  riskAssessementModuleOpen = false;

  username: string;

  userActiveOrganizationRoles: UserRole[] = [];
  userOrganizationRoles: UserRole[] = [];

  title = "MARCH Lebanon"
  surveyTypes: LookupModel[] = [];

  readonly halfOpenedMenu: string = "50%";
  readonly fullyOpenedMenu: string = "90%";

  activeLanguage: string = this.settingsService.settings.defaultLanguage; //, private route : ActivatedRoute, private router: Router
  panelOpenState = false;

  list: NotificationObject[] = [];
  listIncident: IncidentFeed[] = [];
  notificationCounter: number = 0;
  public incidentsCounter: number = 0;
  private notificationSubscription: Subscription = new Subscription();

  constructor(private readonly _translateService: TranslateService, private notificationService: NotificationService, private _snackBar: MatSnackBar, @Inject(DOCUMENT) private document: Document,
    public lookupService: LookupService, private session: SessionService, private location: Location, private settingsService: SettingsService,
    public authService: AuthenticationService, private router: Router, private route: ActivatedRoute, public myHttpHandler: MyHttpHandler, private snacks: SnackbarService,
    public readonly menuService: MenuService, private incidentService: IncidentService, private _sivisService: SivisService) {
    super();
    if (this.session) {
      this.activeLanguage = this.session.getActiveLanguage() ? this.session.getActiveLanguage() : this.settingsService.settings.defaultLanguage;
      this.userActiveOrganizationRoles = this.session?.getActiveSession()?.activeOrganizationRoles;
      this.username = this.session.getActiveUserProfile()?.username;
      this.getUserRoles();
    }
    this._translateService.setDefaultLang(this.activeLanguage);
    this.changeLanguage(this.activeLanguage);
  }

  // toogleSystemAdminMenu(){
  //   if(this._isMobile){
  //     this.mobileSystemAdminOpened ? this.closeModileSystemAdmin() : this.openModileSystemAdmin();
  //   } else{
  //     this.systemAdminOpened ? this.closeSystemAdmin() : this.openSystemAdmin();
  //   }
  // }

  getUserRoles() {
    this.myHttpHandler.get<any>('/v1/security_mgt/user-role').subscribe(response => {
      if (response && response.body) this.userOrganizationRoles = response.body;
    });
  }

  canActivateAllRoles(): boolean {
    let canActivateAllRoles: boolean = false;
    if (this.userOrganizationRoles && this.userOrganizationRoles.length > 1) {
      let roleId: number;
      canActivateAllRoles = true;
      for (let currUserRole of this.userOrganizationRoles) {
        if (canActivateAllRoles && currUserRole && currUserRole.role != undefined && currUserRole.role.id != undefined && currUserRole.role.id > 0) {
          if (roleId == undefined) roleId = currUserRole.role.id;
          canActivateAllRoles = (roleId == currUserRole.role.id);
        }
      }
    }
    return canActivateAllRoles;
  }

  isRollActive(userRole: UserRole): boolean {
    let isActive = false;
    if (userRole != null && this.userActiveOrganizationRoles) {
      for (let currUserRole of this.userActiveOrganizationRoles) {
        if (!isActive && currUserRole && currUserRole.role != undefined && currUserRole.role.id != undefined
          && currUserRole.role.id > 0 && currUserRole.active)
          isActive = userRole.id == currUserRole.id;
      }
    }
    return isActive;
  }

  isAllRollActive() {
    return this.userActiveOrganizationRoles && this.userOrganizationRoles && this.userOrganizationRoles?.length > 1
      && this.userActiveOrganizationRoles?.length == this.userOrganizationRoles?.length;
  }

  setAsActiveOrganization(selectedUserRoleId: number) {
    let body = { "userRoleIds": [selectedUserRoleId] };
    this.changeActiveOrganization(body);
  }

  setAllAsActiveOrganization() {
    if (this.canActivateAllRoles()) {
      let userRoleIds: number[] = [];
      for (let userRole of this.userOrganizationRoles) userRoleIds.push(userRole.id);
      let body = { "userRoleIds": userRoleIds };
      this.changeActiveOrganization(body);
    }
  }

  changeActiveOrganization(body: any) {
    this.myHttpHandler.post("/v1/auth/change_organization", body).subscribe(response => {
      if (response && response.body) {
        this.session.startSession(response.body as LoginResponse, null, true);
        window.location.reload();
        this.snacks.openSnackBar("organization.messages.organization-changed");
      } else this.snacks.openWarningSnackBar("organization.messages.error");
    }, err => {
      this.snacks.openWarningSnackBar("organization.messages.error");
    });
  }

  toogleMenuExpansion(menu: Menu) {
    menu.toggled = !menu.toggled;
  }

  collapseAllItems() {
    this.menuService.menuItems.forEach(mainMenuItem => {
      mainMenuItem.toggled = false;
    });
  }

  closeAllMobileModuleExpansions() {
    this.surveyMobileModuleOpen = false;
    this.programMobileModuleOpen = false;
    this.adminMobileModuleOpen = false;
    this.profileMobileModuleOpen = false;
    this.reportMobileModuleOpen = false;
  }


  toogleNav() {
    if (this._isMobile) {
      this.bottomNavbarOpened ? this.closeBottomNav() : this.openBottomNav();
    } else {
      this.navbarOpened ? this.closeNav() : this.openNav();
    }
  }


  executeOnResize() {
    this._isMobile ? this.closeNav() : this.closeBottomNav();
  }

  openNav() {
    this.navbarOpened = true;
    if (document.getElementById("mySidenav") != undefined) document.getElementById("mySidenav").style.width = "250px";
    if (document.getElementById("main-foreground") != undefined) document.getElementById("main-foreground").style.display = "block";
    this.setOpenNavButton();
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
  closeNav() {
    this.navbarOpened = false;
    if (document.getElementById("mySidenav") != undefined) document.getElementById("mySidenav").style.width = "0";
    if (document.getElementById("main-foreground") != undefined) document.getElementById("main-foreground").style.display = "none";
    this.setClosedNavButton();
    //this.closeAllModuleExpansions();
  }

  setOpenNavButton() {
    if (this.isRtl()) {
      if (document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.right = "245px";
      if (document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.rotate = "180deg";
    } else {
      if (document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.left = "228.5px";
      if (document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.rotate = "0deg";
    }
  }

  setClosedNavButton() {
    if (this.isRtl()) {
      if (document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.right = "-16px";
      if (document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.rotate = "0deg"
    } else {
      if (document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.left = "0";
      if (document.getElementById("sidenav-toogle-btn") != undefined) document.getElementById("sidenav-toogle-btn").style.rotate = "180deg"
    }
  }

  adaptNavButtonStateAfterLanguageChange() {
    this.navbarOpened ? this.setOpenNavButton() : this.setClosedNavButton();
  }

  openBottomNav() {
    this.bottomNavbarOpened = true;
    if (document.getElementById("bottomSidenav") != undefined) document.getElementById("bottomSidenav").style.height = "50%";
    if (document.getElementById("main-mobile-foreground") != undefined) document.getElementById("main-mobile-foreground").style.display = "block";
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
  closeBottomNav() {
    this.bottomNavbarOpened = false;
    if (document.getElementById("bottomSidenav") != undefined) document.getElementById("bottomSidenav").style.height = "0";
    if (document.getElementById("main-mobile-foreground") != undefined) document.getElementById("main-mobile-foreground").style.display = "none";

    this.closeAllMobileModuleExpansions();
  }

  /* isActiveRoute(routeToCheck: string): boolean{
     let isActive: boolean = false;
     if(this.isActiveRoute){
       let equal = this.location.isCurrentPathEqualTo(routeToCheck);
       let contains = this.location.path().includes(routeToCheck);
       isActive = equal || contains;
     }
     return isActive;
   }*/

  isActiveRoute(routeToCheck: string): boolean {
    return this.isActiveRoutePath(routeToCheck, false);
  }

  isActiveRoutePath(routeToCheck: string, equalOnly: boolean): boolean {
    let isActive: boolean = false;

    if (routeToCheck != undefined) {
      let equal = this.location.isCurrentPathEqualTo(routeToCheck);
      let contains = !equalOnly ? this.location.path().includes(routeToCheck) : false;
      isActive = equal || contains;
    }
    return isActive;
  }

  ngOnInit(): void {
    this.fillList();
    this.notificationSubscription = this.startScheduler().subscribe(() => {
      this.fillList();
    });
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
  }

  private startScheduler(): Observable<number> {
    const intervalMilliseconds = 5 * 60 * 1000; // 5 min in milliseconds
    return interval(intervalMilliseconds) // Emits 5 min
      .pipe(
        takeWhile(() => true) // Continues indefinitely
      );
  }

  fillList() {
    this.notificationService.getList().subscribe(response => {
      if (response && response.body) {
        this.list = [];
        this.notificationCounter = 0;
        response.body.forEach(notification => {
          this.list.push(notification);
          this.notificationCounter++;
        });
      }
    });

    this.incidentService.getLastDaysIncidents().subscribe(response => {
      let listIncidentResponse = response?.body;
      this.listIncident = listIncidentResponse?.content as IncidentFeed[];
      this.incidentsCounter = this.listIncident.length;
    });
  }

  openObject(notification: NotificationObject) {
    if (notification && notification.objectType == 'Program') {
      this.router.navigateByUrl('enrollment/program/' + notification.objectId);
    }
  }

  changeLanguage(languageChangeValue: string) {
    this.session.setActiveLanguage(languageChangeValue);
    if (this.activeLanguage != languageChangeValue) {
      window.location.reload();
    }
    this.activeLanguage = languageChangeValue;
    this.setGlobalLanguage();
    this.adaptNavButtonStateAfterLanguageChange();
  }

  public onLanguageChange(lang: string): void {
    this.changeLanguage(lang);
  }

  public onLogoutClick(): void {
    this.logout();
  }

  isRtl(): boolean {
    return this.activeLanguage === "ar";
  }

  setGlobalLanguage() {
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = this.isRtl() ? "rtl" : "ltr";
    this._translateService.use(this.activeLanguage);
    this.changeCssFile();
  }

  changeCssFile() {
    let headTag = this.document.getElementsByTagName("head")[0] as HTMLHeadElement;
    let existingLink = this.document.getElementById("langCss") as HTMLLinkElement;
    let bundleName = this.isRtl() ? "arabicStyle" : "englishStyle";
    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      let newLink = this.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.type = "text/css";
      newLink.id = "langCss";
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }

  getSurveyAssetsUrl(assetPath: string): string {
    let url = assetPath;
    if (this.settingsService && this.settingsService.settings) {
      url = this.settingsService.settings.surveyAssetsBasePath;
      if (!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  mobileSideNavBtnClicked(): void {
    if (this.bottomNavbarOpened && document.getElementById("bottomSidenav") != undefined) {
      if (document.getElementById("bottomSidenav").style.height == this.halfOpenedMenu) {
        document.getElementById("bottomSidenav").style.height = this.fullyOpenedMenu;
      } else {
        document.getElementById("bottomSidenav").style.height = this.halfOpenedMenu;
      }
    }
  }

  logout(): void {
    this.myHttpHandler.post<LoginResponse>("/v1/auth/logout-all", null).subscribe(response => {
      this.afterLogout();
    }, err => {
      this.afterLogout();
    });
  }

  afterLogout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  navigateToSivis() {
    this._sivisService
      .getOTP()
      .pipe(
        map((otp) => {
          let url = `${this.settingsService.settings.sivisHostUrl}/app/dashboards/galleries/view?otp=${otp}`;
          window.open(url, "_blank");
        }),
      )
      .subscribe();
  }

}
