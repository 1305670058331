import { FormArray, FormControl, FormGroup } from "@angular/forms";

export const formHelper = {
  /**
  * This is a sample function that adds two numbers.
  *
  * @param {FormGroup | FormControl} group - The formGroup or FromControl to be marked as Touched.
  */
  markAllControlsAsTouched: (group: FormGroup | FormControl | FormArray): void => {
    group.markAsTouched();
    // If the control is a FormGroup, recursively mark its controls as "touched"
    if (group instanceof FormGroup || group instanceof FormArray) {
      Object.values(group.controls).forEach((control: any) => {
        formHelper.markAllControlsAsTouched(control);
      });
    }
  },

  /**
  * This is a sample function that adds two numbers.
  *
  * @param {FormGroup | FormControl} group - The formGroup or FromControl to be marked as UnTOuched.
  */
  markAllControlsAsUntouched: (group: FormGroup | FormControl): void => {
    group.markAsUntouched();

    // If the control is a FormGroup, recursively mark its controls as "untouched"
    if (group instanceof FormGroup) {
      Object.values(group.controls).forEach((control: any) => {
        formHelper.markAllControlsAsUntouched(control);
      });
    }
  },

  trimAllFormValues(formGroup: FormGroup | FormArray) {
    if (formGroup instanceof FormGroup) {
      for (const controlName in formGroup.controls) {
        if (formGroup.controls.hasOwnProperty(controlName)) {
          const control = formGroup.controls[controlName];
          formHelper.trimChecks(control);
        }
      }
    } else if (formGroup instanceof FormArray) {
      for (let i = 0; i < formGroup.length; i++) {
        const control = formGroup.at(i);
        formHelper.trimChecks(control);
      }
    }
  },

  trimChecks(control) {
    if (control instanceof FormGroup || control instanceof FormArray) {
      formHelper.trimAllFormValues(control);
    } else {
      if(typeof(control.value) == 'string') {
        control.setValue(control.value.trim());
      }
    }
  },
}