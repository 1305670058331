<mat-form-field class="full-width-element" *ngIf="formControl">
  <mat-label>{{ placeHolder }}</mat-label>
  <mat-select [(ngModel)]="value" (ngModelChange)="onChange(value)" (blur)="onTouched()" [multiple]="isMultiSelect" #multiSelect [formControl]="formControl">
    <mat-option>
      <ngx-mat-select-search class="search-input" [formControl]="lookupMultiFilterCtrl" [placeholderLabel]="'dashboard.titles.search' | translate"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let lookup of filteredLookupsMulti | async" [value]="lookup" [selected]="isSelected(lookup)">
      {{ lookup?.value }}
    </mat-option>
    <mat-option *ngIf="(filteredLookupsMulti | async)?.length === 0" [disabled]="true">
      {{ 'dashboard.titles.no-options' | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
