import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableModel } from '../../../models/shared/cell-data-model';
import { LookupModel } from '../../../models/shared/lookup.model';
import { FilterOperator, TableFilterElement, TableFilterTypeEnum } from '../../../models/shared/table-filter.model';
import { SurveyTypeService } from '../../../services/survey-type.service';
import { MenuActionObject } from '../../../models/shared/datatable-objects.model';
import { ResponsiveListConsumerBaseComponent } from '../../shared/responsive-list-consumer-base/responsive-list-consumer-base';

@Component({
  selector: 'app-survey-type-table',
  templateUrl: './survey-type-table.component.html',
  styleUrls: ['./survey-type-table.component.sass']
})
export class SurveyTypeTableComponent extends ResponsiveListConsumerBaseComponent<LookupModel, LookupModel> implements OnInit {

  constructor(public service : SurveyTypeService, protected router: Router, protected route : ActivatedRoute, protected readonly _translateService: TranslateService) {
    super(service, router, route, _translateService);
   }

  ngOnInit(): void {
  }

  protected getFormRelativeUrlPath() {
    throw "create-survey-type";
  }

  _booleanIconColumnDisplayList: string[] = [
    "deprecated"
  ]

  _tableColumns  : DataTableModel<LookupModel> []= [
    {  columnDef: 'key', headerTranslationPath: "siren-survey-translation.lookup-model.fields.key", header: 'Key',  cell: (element: LookupModel) => `${element.key}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'value', headerTranslationPath: "siren-survey-translation.lookup-model.fields.value", header: 'Value',  cell: (element: LookupModel) => `${element.value}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'order', headerTranslationPath: "siren-survey-translation.lookup-model.fields.order", header: 'Order',  cell: (element: LookupModel) => `${element.order}`, width: '150px'},
    {  columnDef: 'deprecated', headerTranslationPath: "siren-survey-translation.lookup-model.fields.deprecated",  header: 'Deprecated',  cell: (element: LookupModel) => `${element.deprecated}`, width: '150px'},
    {  columnDef: '_menu_inline', headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "key", translationPath: "siren-survey-translation.lookup-model.fields.key", displayName: "Title", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "value", translationPath: "siren-survey-translation.lookup-model.fields.value", displayName: "Value", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "deprecated", translationPath: "siren-survey-translation.lookup-model.fields.deprecated", displayName: "Deprecated", type: TableFilterTypeEnum.Number, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal }
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg" }
  ]
}
