<div class="body">
    <form [formGroup]="form" (ngSubmit)="buttonClicked(true)">

        <div class="fields_wrapper">
            <ng-container *ngFor="let field of data.fields">
                <div *ngIf="!field.isHidden" [ngStyle]="{'width':field.size}"
                    [ngClass]="field.fixedSize ? 'field_wrapper_fixed_size' : 'field_wrapper'">
                    <div *ngIf="(field.type == fieldTypeEnum.date || field.type == fieldTypeEnum.dateTime || field.type == fieldTypeEnum.month); else arrayField">
                        <app-datepicker [formControlName]="field.prop" [placeholdertext]="field.label"
                        [minDate]="getMinDate(field)" [maxDate]="getMaxDate(field)" [withClear]="true"
                        [onlyMonth]="field.type == fieldTypeEnum.month">
                        </app-datepicker>
                    </div>
                    <ng-template #arrayField>
                        <div [hidden]="field.isHidden" *ngIf="field.type == fieldTypeEnum.array; else normalField">
                            <div class="array_title">
                                <h5>{{field.label | translate}}</h5>

                                <button (click)="addRecord(field)" class="add_new_btn"
                                    mat-raised-button type="button">{{'siren-admin-translation.admin_module.sms_module.campaigns.details.add' | translate
                                    }}</button>
                            </div>
                            <div [formArrayName]="field.prop" class="array_wrapper"
                                *ngFor="let child of form.get(field.prop)['controls']; let i = index;">

                                <div class="delete_icon_wrapper">
                                    <mat-icon (click)="removeRecord(field, i)" class="delete_icon">delete</mat-icon>
                                </div>

                                <div [formGroupName]="i" class="array_fields_wrapper">

                                    <ng-container *ngFor="let childField of field.children">
                                        <div *ngIf="!childField.isHidden" [ngStyle]="{'width': childField.size}"
                                            [ngClass]="childField.fixedSize ? 'field_wrapper_fixed_size' : 'field_wrapper'">

                                            <div *ngIf="childField.type == fieldTypeEnum.space">
                                            </div>
                                            <app-custom-field *ngIf="childField.prop" [formControlName]="childField.prop"
                                                [field]="childField" [lookups]="getLookups(childField, i)">
                                            </app-custom-field>
                                        </div>
                                    </ng-container>

                                </div>
                            </div>

                        </div>
                    </ng-template>

                    <ng-template #normalField>
                        <div *ngIf="field.type == fieldTypeEnum.space">
                        </div>
                        <app-custom-field *ngIf="field.prop" [formControlName]="field.prop" [field]="field"
                            [lookups]="getLookups(field)">
                        </app-custom-field>

                    </ng-template>


                </div>
            </ng-container>
        </div>
        <div class="footer" *ngIf="!data.isDisabled">
            <button type="submit" class="submit_btn" [disabled]="!submitAction.hide && form.invalid"
                [ngClass]="{'submit_btn_disabled': form.invalid}">{{ submitAction.label | translate }}
            </button>
        </div>
    </form>
</div>
