import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { SnackbarService } from '@siren-survey/app/shared/services/snackbar.service';
import { LoginResponse } from 'src/app/models/auth/login-response.model';
import { IdNameObject } from 'src/app/models/shared/session.model';
import { SessionService } from 'src/app/services/shared/session.service';

@Component({
  selector: 'app-change-organization',
  templateUrl: './change-organization.component.html',
  styleUrls: ['./change-organization.component.sass']
})
export class ChangeOrganizationComponent extends DropdownComparatorWithBrowserListener implements OnInit {

  organizations: LookupModel[] = [];
  changeOrganizationForm: FormGroup;

  constructor(public lookupService: LookupService, private sessionService: SessionService, private http: MyHttpHandler, private snacks: SnackbarService){
    super();
  }

  ngOnInit(): void {
    this.http.get("/v1/auth/organizations").subscribe(response =>{
      if(response?.body){
        let userOrgList : IdNameObject[] = response?.body as IdNameObject[];
        for(let userOrg of userOrgList){
          let currLookup: LookupModel = {
            id: userOrg.id,
            key: '',
            value: userOrg.name,
            order: 0
          };
          this.organizations.push(currLookup);
        }
      }
    });

    // let activeOrganization: LookupModel = this.sessionService?.getActiveSession()?.activeOrganizationId ? {
    //   id: this.sessionService?.getActiveSession()?.activeOrganizationId,
    //   key: '',
    //   value: '',
    //   order: 0
    // } : undefined;

    // this.changeOrganizationForm = new FormGroup({
    //   organization: new FormControl(activeOrganization, Validators.required)
    // });
  }

  onChangeOrganization(){
    if(this.changeOrganizationForm.valid){
      let organization : LookupModel = this.changeOrganizationForm?.get("organization")?.getRawValue();
      if(this.enableChangeOrganizationButton() && organization != undefined){
        this.http.post("/v1/auth/change_organization", { "organizationId": organization.id }).subscribe(response =>{
          if(response && response.body) {
            this.sessionService.startSession(response.body as LoginResponse);
            this.snacks.openSnackBar("organization.messages.organization-changed");
          } else this.snacks.openWarningSnackBar("organization.messages.error");
        }, err => {
          this.snacks.openWarningSnackBar("organization.messages.error");
        });
      }
    }
    // const passwordToSubmit: PasswordRequest = this.passwordForm.getRawValue();
    // if (passwordToSubmit.newPassword !== passwordToSubmit.confirmNewPassword) {
    //   this.error = { error: true, message: 'generic.do_not_match', withTranslate: true }
    //   this.passwordForm.controls["confirmNewPassword"].setErrors({ 'incorrect': true });
    // } else {
    //   this.profileService.changePassword(passwordToSubmit).subscribe(res => {
    //     this.error.error = false;
    //     this.snacks.openSnackBar("snackbar.edit_success");
    //   }, err => {
    //     this.snacks.openWarningSnackBar("snackbar.edit_error");

    //     if (err.message === "Data Conflict") {
    //       this.error = { error: true, message: "generic.incorrect_old_password", withTranslate: true }
    //     } else {
    //       this.error = { error: true, message: err.message, withTranslate: false }
    //     }
    //   })
    // }
  }

  enableChangeOrganizationButton(): boolean{
    return true;
    // let organization = this.changeOrganizationForm?.get("organization")?.getRawValue();
    // return organization != undefined && (this.sessionService?.getActiveSession()?.activeOrganizationId == undefined
    // || organization.id != this.sessionService?.getActiveSession()?.activeOrganizationId);
  }
}
