<form [formGroup]="form" (ngSubmit)="saveAchievement()">
    <div class="dialog-header">
      <span>{{ 'achievement.fields.add-new-achievement' | translate }}</span>
    </div>
    <div class="dialog-container">
      <mat-form-field class="full-width-element" >
        <mat-label class="">{{'achievement.fields.name' | translate}}</mat-label>
        <input  matInput  class="" formControlName="name">
      </mat-form-field>
      <div class="col-12">
        <app-blob [singleBlobObject]="singleBlobObject" [saveBlobSubject]="saveBlobSubject" [saveBlobOnTrigger]="saveBlobOnTrigger" [type]="type" [objectId]="objectId" [displayMode]="'LIST'" [showPreviewPanel]="true"></app-blob>
      </div>
      <div class="save">
        <button type="submit" [ngClass]="translateService.currentLang == 'ar' ? 'btn-submit-rtl' : '' " class="btn btn-submit">
          <span class=" button-label">{{'global.save' | translate}}</span>
        </button>
      </div>
    </div>
</form>
