<form [formGroup]="form" class="custom-report-form" (ngSubmit)="saveReportElement()">
  <div class="form-elements">
    <div class="col-12 form-element">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.title' | translate}}</mat-label>
        <textarea matInput type="text-area" autocomplete="off" min="0" class="" formControlName="title"></textarea>
      </mat-form-field>
    </div>
    <div class="col-12 form-element">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.type' | translate}}</mat-label>
        <mat-select class="" formControlName="type" (selectionChange)="typeChanged($event)">
          <mat-option *ngFor="let type of cellTypes" [value]="type.id">{{ type?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- survey object -->
    <div class="col-12 form-element" *ngIf="showSurveyList" [formGroup]="form.get('customReportSurveyElement')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.survey' | translate}}</mat-label>
        <mat-select class="" formControlName="surveyUuid" (selectionChange)="surveySelected($event)">
          <mat-option *ngFor="let survey of surveys" [value]="survey.key">{{ survey?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 form-element" *ngIf="showSurveyQuestions" [formGroup]="form.get('customReportSurveyElement').get('question')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.question' | translate}}</mat-label>
        <mat-select class="" formControlName="id" (selectionChange)="questionSelected($event)">
          <mat-option *ngFor="let question of surveyQuestions" [value]="question.id">{{ question?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 form-element" *ngIf="showSurveyList"  [formGroup]="form.get('customReportSurveyElement')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.baseline' | translate}}</mat-label>
        <mat-select class="" formControlName="baselineValue">
          <mat-option [value]=""></mat-option>
          <mat-option [value]="false">{{ 'global.false' | translate }}</mat-option>
          <mat-option [value]="true">{{ 'global.true' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- object option -->
    <div class="col-12 form-element" *ngIf="showObjectTypes" [formGroup]="form.get('customReportObjectElement')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.objectType' | translate}}</mat-label>
        <mat-select class="" formControlName="objectType" (selectionChange)="objectTypeSelected($event)">
          <mat-option *ngFor="let objectType of objectTypes" [value]="objectType.id">{{ objectType?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 form-element" *ngIf="showObjectAttributes" [formGroup]="form.get('customReportObjectElement')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.objectAttributes' | translate}}</mat-label>
        <mat-select class="" formControlName="objectAttribute" (selectionChange)="attributeSelected($event)">
          <mat-option *ngFor="let attribute of objectAttributes" [value]="attribute.key">{{ attribute?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- flag option -->
    <div class="col-12 form-element" *ngIf="showFlagTypes" [formGroup]="form.get('customReportFlagElement')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.flagType' | translate}}</mat-label>
        <mat-select class="" formControlName="objectType" (selectionChange)="objectFlagTypeSelected($event)">
          <mat-option *ngFor="let flagType of flagTypes" [value]="flagType.value">{{ flagType?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 form-element" *ngIf="showTags" [formGroup]="form.get('customReportFlagElement')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.tags' | translate}}</mat-label>
        <mat-select class="" formControlName="objectId"  (selectionChange)="tagSelected($event)">
          <mat-option *ngFor="let tag of tags" [value]="tag.id">{{ tag?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 form-element" *ngIf="showAchievements" [formGroup]="form.get('customReportFlagElement')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.achivements' | translate}}</mat-label>
        <mat-select class="" formControlName="objectId"  (selectionChange)="achivementSelected($event)">
          <mat-option *ngFor="let achievement of achievements" [value]="achievement.id">{{ achievement?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- assessment option -->
    <div class="col-12 form-element" *ngIf="showAssessments" [formGroup]="form.get('customReportAssessmentElement')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.assessment' | translate}}</mat-label>
        <mat-select class="" formControlName="assessmentId" (selectionChange)="assessmentSelected($event)">
          <mat-option *ngFor="let assessment of assessments" [value]="assessment.id">{{ assessment?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- evaluation option -->
    <div class="col-12 form-element" *ngIf="showEvaluations" [formGroup]="form.get('customReportAssessmentElement')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.evaluation' | translate}}</mat-label>
        <mat-select class="" formControlName="assessmentId"  (selectionChange)="assessmentSelected($event)">
          <mat-option *ngFor="let evaluation of evaluations" [value]="evaluation.id">{{ evaluation?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 form-element" *ngIf="showAssessmentCriterea" [formGroup]="form.get('customReportAssessmentElement').get('assessmentCriterion')">
      <mat-form-field class="full-width-element">
        <mat-label class="">{{'report.fields.criterion' | translate}}</mat-label>
        <mat-select class="" formControlName="id" (selectionChange)="critereonSelected($event)">
          <mat-option *ngFor="let critereon of criterea" [value]="critereon.id">{{ critereon?.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="dialog-button-container">
      <button type="submit" mat-flat-button color="accent">
        <span style="white-space: nowrap;">{{'global.save' | translate}}</span>
      </button>
    </div>
  </div>
</form>
