import { DialogDataModel } from './../../../models/dialog-data.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-authentication-dialog',
  templateUrl: './authentication-dialog.component.html',
  styleUrls: ['./authentication-dialog.component.sass']
})
export class AuthenticationDialogComponent implements OnInit {
  message: string
  errorContent: AuthenticationError;
  constructor(public dialogRef: MatDialogRef<AuthenticationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataModel<any>) { }

  ngOnInit(): void {
    this.errorContent = (this.data as any)?.error;
    if(this.errorContent){
      switch(this.errorContent.error_description){
        case "Invalid refresh token":
          this.message = "siren-admin-translation.authentication_dialog.invalid_refresh_token";
          break;
        case "Refresh limit reached.  Please log in again.":
          this.message = "siren-admin-translation.authentication_dialog.refresh_token_expired";
          break;
        case "Account Locked.  Please contact an administrator":
          this.message = "siren-admin-translation.authentication_dialog.account_locked"
          break;
      }
    }



  }

}

export interface AuthenticationError {
  error_description: string,
  error: string
}
