import { Injectable, Injector } from '@angular/core';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { PasswordRequest, PasswordRequestObj } from '../../models/profile/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService  {

  constructor(private http: MyHttpHandler, protected injector: Injector){
  }

  changePassword(passwordRequest : PasswordRequest): Observable<HttpResponse<boolean>> {
    let passwordRequestObj: PasswordRequestObj = new PasswordRequestObj();
    passwordRequestObj.newPassword = passwordRequest.newPassword;
    passwordRequestObj.oldPassword = passwordRequest.oldPassword;

    return this.http.put<boolean>('/v1/auth/change_password', passwordRequestObj);
  }
}
