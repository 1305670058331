import { NgModule } from '@angular/core';
import { ArabicNumberPipe } from '../pipes/arabic-number-pipe.pipe';
import { EllipsisPipe } from '../pipes/ellipsis.pipe';
import { FilterPipePipe } from '../pipes/filter-pipe.pipe';

@NgModule({
    declarations: [
        ArabicNumberPipe,
        FilterPipePipe,
        EllipsisPipe
    ],
    exports: [
        ArabicNumberPipe,
        FilterPipePipe,
        EllipsisPipe
    ]
})
export class PipesModule { }
