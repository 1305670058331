import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { LegalCaseStatus } from 'src/app/models/legal-case/legalcase';

@Injectable({
  providedIn: 'root'
})
export class LegalCaseService extends BaseService<LegalCaseStatus, LegalCaseStatus> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/status/legal-case-status", http, injector);
  }

  deleteLegalStatus(legalStatusId: number): Observable<HttpResponse<boolean>> {

    return this.http.delete<boolean>('/v1/status/legal-case-status/'  + legalStatusId);
  }
}
