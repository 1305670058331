import { Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { IdValueObject, IdValueOrderObject } from '../../models/shared/lookup.model';
import { Survey } from '../../models/survey/survey';
import { EditQuestionNotification } from '../../models/question/survey-question';

@Injectable({ providedIn: 'root' })
export class SurveyFormQuestionHandler{

  hasUnsavedQuestionChanges: boolean;
  questionInEditModeCountMap: Map<string, number>;
  questionAnswerMap: Map<IdValueOrderObject, IdValueObject[]>;
  newlyAddedSurveyQuestions: number[];
  survey: Survey;
  lookupMap: Map<string, any[]> = new Map();
  idValueObjectLookupMap: Map<string, IdValueObject[]> = new Map();

  reloadLookupSubject: Subject<String> = new Subject();

  // THIS IS USED TO NOTIFY ANY COMPONENT USING THIS SERVICE OF THE CHANGE IN THE QUESTION/ANSWER MAP
  @Output() public questionChangeEventSubject: Subject<Map<IdValueOrderObject, IdValueObject[]>> = new Subject<Map<IdValueOrderObject, IdValueObject[]>>();
  constructor(public readonly _translateService: TranslateService){
    this.questionAnswerMap = new Map<IdValueOrderObject, IdValueObject[]>();
    this.questionInEditModeCountMap = new Map<string, number>();
    this.newlyAddedSurveyQuestions = [];
    this.hasUnsavedQuestionChanges = false;
  }

  //Filter names and types for statements

  static readonly STANDARD_FILTER_TYPE: IdValueObject = {
    id: 1,
    value: "Standard Condition",
  }
  static readonly CUSTOM_FILTER_TYPE: IdValueObject = {
    id: 2,
    value: "Custom Condition",
  }

  static readonly REGION_FILTER_NAME: IdValueObject = {
    id: 1,
    value: "Region",
  }
  static readonly GENDER_FILTER_NAME: IdValueObject = {
    id: 2,
    value: "Gender",
  }
  static readonly ACHIEVEMENT_FILTER_NAME: IdValueObject = {
    id: 3,
    value: "Achievement",
  }

  public questionsInEditModeCount(): number{
    let count: number = 0;

    if(this.questionInEditModeCountMap != undefined){
      this.questionInEditModeCountMap.forEach(function(value, key){
        if(value != null) count += value;
      });
    }

    return count;
  }

  public hasQuestionsInEditMode(): boolean{
    return this.questionsInEditModeCount() > 0;
  }

  public validateFormForSave() : string{
    return this.hasQuestionsInEditMode() ? this._translateService.instant('siren-survey-translation.error.unsaved-question-bank') : undefined;
  }

  public triggerSurveySaveEvent(){
    this.hasUnsavedQuestionChanges = false;
    this.questionInEditModeCountMap = new Map<string, number>();
    this.newlyAddedSurveyQuestions = [];
  }

  public setSurvey(survey: Survey){
    this.survey = survey;
    this.calculateQuestionAnswerMap();
  }

  public resetQuestions() {
    this.questionInEditModeCountMap = undefined;
  }

  public calculateQuestionAnswerMap(): Map<IdValueObject, IdValueObject[]>{
    if(this.survey && this.survey.sections){
      this.questionAnswerMap = new Map<IdValueOrderObject, IdValueObject[]>();
      this.survey.sections.forEach(section =>{
        if(section && section.questions){
          section.questions.forEach(sectionQuestion =>{
            if(sectionQuestion && sectionQuestion.question){
              let questionObject = new IdValueOrderObject(sectionQuestion.id, sectionQuestion.question.question, sectionQuestion.order);
              let questionAnswersList: IdValueObject[] = [];
              sectionQuestion.question.answers.forEach(answer =>{
                let answerObject = new IdValueObject(answer.id, answer.answer);
                questionAnswersList.push(answerObject);
              });
              if(questionAnswersList != undefined && questionAnswersList.length > 0) this.questionAnswerMap.set(questionObject, questionAnswersList);
            }
          })
        }
      });
      this.questionChangeEventSubject.next(this.questionAnswerMap);
    }
    return this.questionAnswerMap;
  }

  private removeOneFromEditMode(sectionUuid: string): void{
    if(sectionUuid != undefined && this.questionInEditModeCountMap != undefined){
      let currentCount: number = this.questionInEditModeCountMap.get(sectionUuid);
      if(currentCount != undefined){
        currentCount--;
        this.questionInEditModeCountMap.set(sectionUuid, currentCount);
      }
    }
  }

  private addOneToEditMode(sectionUuid: string): void{
    if(this.questionInEditModeCountMap == undefined) this.questionInEditModeCountMap = new Map<string, number>();
    if(sectionUuid != undefined) {
      let currentCount: number = this.questionInEditModeCountMap.get(sectionUuid);
      if(currentCount == undefined || currentCount < 0) currentCount = 0;
      currentCount++;
      this.questionInEditModeCountMap.set(sectionUuid, currentCount);
    }
  }

  public handleEditQuestionNotification(editQuestionNotification: EditQuestionNotification){
    if(editQuestionNotification != null){
      if(editQuestionNotification.addToUnsavedQuestionList && editQuestionNotification.questionId != undefined && editQuestionNotification.questionId > 0)
        this.addQuestionIdToNewlyAddedSurveyQuestions(editQuestionNotification.questionId);
      if(editQuestionNotification.decreaseEditCount && editQuestionNotification.sectionUuid != undefined)
        this.removeOneFromEditMode(editQuestionNotification.sectionUuid);
      if(editQuestionNotification.encreaseEditCount && editQuestionNotification.sectionUuid != undefined)
        this.addOneToEditMode(editQuestionNotification.sectionUuid);
      if(editQuestionNotification.removeFromUnsavedQuestionList && editQuestionNotification.questionId != undefined && editQuestionNotification.questionId > 0)
        this.removeQuestionIdFromNewlyAddedSurveyQuestions(editQuestionNotification.questionId);
    }
  }

  public addQuestionIdToNewlyAddedSurveyQuestions(id: number) {
    if(this.newlyAddedSurveyQuestions == undefined) this.newlyAddedSurveyQuestions = [];
    if(!this.newlyAddedSurveyQuestions.includes(id)) this.newlyAddedSurveyQuestions.push(id);
  }

  public removeQuestionIdFromNewlyAddedSurveyQuestions(id: number) {
    if(this.newlyAddedSurveyQuestions != undefined && id != undefined && this.newlyAddedSurveyQuestions.includes(id)){
      this.newlyAddedSurveyQuestions  = this.newlyAddedSurveyQuestions.filter(value => value !== id);
    }
  }

  public deleteSection(sectionUuid: string): void{
    if(sectionUuid != undefined && this.questionInEditModeCountMap != undefined){
      this.questionInEditModeCountMap.delete(sectionUuid);
      this.calculateQuestionAnswerMap();
    }
  }

  // common lookup management

  public addLookupByName(name: string, list: any[]){
    if(this.lookupMap == undefined) this.lookupMap = new Map();
    this.lookupMap.set(name, list);
    this.reloadLookupSubject.next(name);
  }

  public getLookupByName(lookupName: string) : any[]{
    return this.lookupMap ? this.lookupMap.get(lookupName) : undefined;
  }

  public getIdValueObjectLookupMap(lookupName: string) : any[]{
    if(this.idValueObjectLookupMap == undefined) this.idValueObjectLookupMap = new Map();
    let idValueLookupList: IdValueObject[] = this.idValueObjectLookupMap.get(lookupName);
    if(idValueLookupList == undefined || idValueLookupList.length == 0){
      idValueLookupList = [];
      let lookupList: any[] = this.getLookupByName(lookupName);
      if(lookupList != undefined){
        lookupList?.forEach(object=>{
          let filterId:IdValueObject = {
            id: object.id,
            value: object.value
          }
          idValueLookupList.push(filterId);
        })
      }
      this.idValueObjectLookupMap.set(lookupName, idValueLookupList);
    }
    return idValueLookupList;
  }
}
