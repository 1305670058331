import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { MenuActionEmittedObject, MenuActionObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchPermissionEnum, MarchModuleEnum } from 'src/app/shared/enum/user-role.enum';
import { Classification, TableClassification } from '../models/assessment-classification.model';
import { ClassificationService } from '../services/assessment-classification.service';

@Component({
  selector: 'app-classification-table',
  templateUrl: './classification-table.component.html',
  styleUrls: ['./classification-table.component.sass']
})
export class ClassificationTableComponent implements OnInit {
  public reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  public filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  public canAdd: boolean = false;
  public hasListAccess: boolean = false;
  public predefinedFilter: Map<string, any>;
  public marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  public marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  public tableColumns  : DataTableModel<TableClassification> []= [
    {  columnDef: 'classificationTypeName', headerTranslationPath: "classification.fields.type", header: 'Type',  cell: (element: TableClassification) => `${element.classificationTypeName}`, width: undefined},
    {  columnDef: 'riskLevel', headerTranslationPath: "classification.fields.name", header: 'Name',  cell: (element: TableClassification) => `${element.riskLevel}`, width: undefined},
    {  columnDef: 'minValue', headerTranslationPath: "classification.fields.min-value", header: 'Min Value',  cell: (element: TableClassification) => `${element.minValue}`, width: undefined},
    {  columnDef: 'maxValue', headerTranslationPath: "classification.fields.max-value", header: 'Max Value',  cell: (element: TableClassification) => `${element.maxValue}`, width: undefined},
    {  columnDef: 'riskColor', headerTranslationPath: "classification.fields.risk-color", header: 'Color',  cell: (element: TableClassification) => `${element.riskColor}`, width: undefined, style: (element: Classification) => `background-color: ${element.riskColor}; color: ${element.riskColor}`},
    {  columnDef: '_menu_inline', headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  public filters: TableFilterElement[] = [
    { name: "riskLevel", translationPath: "classification.fields.name", displayName: "Name", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
  ];

  public tableMenuAction: MenuActionObject[] = [
    { name: "Delete", actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "../../../../assets/icons/delete-icon.svg", allowedAction: this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_CLASSIFICATION) , accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ])},
  ]

  public sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  public filteringPredicate : (data: any, filter: string) => boolean;

  constructor(
    public classificationService : ClassificationService,
    private _router: Router,
    private _route : ActivatedRoute,
    private _translateService: TranslateService,
    private _authService: AuthenticationService,
    private _dialogService: DialogService,
    private _snackbar: MatSnackBar) {
    this.predefinedFilter = new Map<string, any>();
    this.sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this.filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.CLASSIFICATION_MODIFY);
    this.hasListAccess = this._authService.isModuleAccessible(this.marchModuleEnum.ASSESSMENT_MGMT.module);

  }

  public ngOnInit(): void {
  }

  // this is used to route the table row onto its form page with the id attached
  public updateViewMode(event: TableAction): void{
    if(event.mode == TableAction.EDIT_MODE && this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.BENEFICIARY_MODIFY)){
      this._router.navigate([event.id], {relativeTo: this._route});
    } else if(event.mode == TableAction.CREATE_MODE) {
      this._router.navigate(["create"], {relativeTo: this._route});
    }
  }

  public applyFilter(event: TableFilterOutput): void{
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  public tableMenuActionHandler(menuAction: MenuActionEmittedObject): void{
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
        if(this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_LOOKUP)){
          this._dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-achievement'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
          .subscribe(response=>{
            if(response){
              this.classificationService.deleteObject(menuAction.object.id).subscribe({
                next : (response) =>{
                  this.reloadEventSubject.next(true);
                }
                ,
                error : (error)=>{
                  this._snackbar.open(this._translateService.instant('classification.error.could-not-delete-interval'), null, { duration: 3000 });
                }
              });
            }
          });
        }
      }
    }
  }

}
