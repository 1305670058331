<ng-container>
  <div class="fullwidth locateForm">
    <app-title [title]="'report.reports-instance-title' | translate" [goBackCallback]="goBack"></app-title>
    <div class="generate-report">
      <button type="button" mat-flat-button color="accent" (click)="generateReport()">{{'report.fields.generate-report' | translate}}</button>
    </div>
    <div>
      <div class="flex-row table-header ">
        <div class="row" style="width: 100%;">
          <div class="col-lg-4 col-sm-6 col-12 ">
            <span>{{'report.fields.file-name' | translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-6 col-12">
            <span>{{'report.fields.file-size' | translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-6 col-12">
            <span>{{'report.fields.creation-date' | translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-6 col-12">
            <span>{{'report.fields.status' | translate}}</span>
          </div>
          <div class="col-lg-2 col-sm-6 col-12">
            <span>{{'report.fields.action' | translate}}</span>
          </div>
        </div>
      </div>

      <div *ngFor="let response of this.customReportResponseList; let i = index;" class="table-record flex-row">
          <div class="row" style="width: 100%;">
            <div class="col-lg-4 col-sm-6 col-12">
              <span>{{ response?.fileName ? response?.fileName : "-" }}.{{ response?.fileType}}</span>
            </div>
            <div class="col-lg-2 col-sm-6 col-12">
              <span>{{ response?.fileSize ? response?.fileSize : "N/A" }}</span>
            </div>
            <div class="col-lg-2 col-sm-6 col-12">
              <span>{{ response.creationDate ? (response.creationDate | date: 'dd / MM / yyyy') : "N/A" }} </span>
            </div>
            <div class="col-lg-2 col-sm-6 col-12">
              <span>{{ response?.status ? response?.status : "-"}}</span>
            </div>
            <div class="col-lg-2 col-sm-6 col-12"><!-- download button-->
              <span>
                <i class="fa fa-download download-class" [ngClass]=" response?.status == 'IN_PROGRESS' ? 'greyed-out': ''" aria-hidden="true"  (click)="downloadFile(response)"></i>
              </span>
            </div>
          </div>
      </div>
    </div>
  </div>
</ng-container>
<div *ngIf="this.customReportResponseList == undefined || this.customReportResponseList?.length == 0">
  <span class="empty-list-label">{{'report.fields.no-generated-reports' | translate}}</span>
</div>
<div class="page-footer">
  <span style="margin-inline-end: 15px;" >{{'global.total-responses' | translate}} {{ totalElements }}</span>
  <mat-form-field style="width: 100px; margin-inline-end: 10px;">
    <mat-label class="" >{{'global.page-size' | translate}}</mat-label>
    <mat-select class="" [(value)]="pageSize" (selectionChange)="changePageSize($event)">
      <mat-option [value]="5">5</mat-option>
      <mat-option [value]="10">10</mat-option>
      <mat-option [value]="20">20</mat-option>
      <mat-option [value]="50">50</mat-option>
      <mat-option [value]="100">100</mat-option>
      <mat-option [value]="150">150</mat-option>
      <mat-option [value]="200">200</mat-option>
    </mat-select>
  </mat-form-field>
  <button *ngIf="currentPage > 0" type="button" (click)="getPreviousPage()" class="btn btn-previous-page">
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
  </button>
  <span>{{ 'global.page' | translate}} {{ currentPage + 1 }} {{ 'global.out-of' | translate}} {{ maxPage }}</span>
  <button *ngIf="currentPage < maxPage -1" type="button" (click)="getNextPage()" class="btn btn-next-page">
    <i class="fa fa-arrow-right" aria-hidden="true"></i>
  </button>
</div>

