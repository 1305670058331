import { Injectable, Injector } from '@angular/core';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { FormClassification } from '../models/assessment-classification.model';
import { BaseService } from '@siren-survey/app/services/shared/base-service';

@Injectable({
  providedIn: 'root'
})
export class ClassificationService extends BaseService<FormClassification, FormClassification> {
    constructor(http: MyHttpHandler, protected injector: Injector){
      super("/v1/assessment/risk-interval", http, injector);
    }
}
