import { FormGroup } from "@angular/forms";

export class TableAction {
    public static readonly VIEW_MODE: number = 0;
    public static readonly CREATE_MODE: number = 1;
    public static readonly EDIT_MODE: number = 2;
    public static readonly SAVE_MODE: number = 3;
    public static readonly CANCEL_MODE: number = 4;

    mode: number = 0;
    id?: number;
}

export class TableDeleteMode {
  public static readonly NONE_MODE: number = 0;
  public static readonly SELECTION_MODE: number = 1;
  public static readonly INLINE_MODE: number = 2;

  mode: number = 0;
  id?: number;
}

export class TableSelectionMode {
  public static readonly NONE_MODE: number = 0;
  public static readonly SINGLE_SELECT_MODE: number = 1;
  public static readonly MULTI_SELECT_MODE: number = 2;

  mode: number = 0;
  id?: number;
}

export class ResponsiveListInlineEditObject{
  id: number;
  idBeforeSave: number;
  form : FormGroup;
  afterSave : boolean = false;
  reloadLookup: boolean = false;

  constructor(id: number, idBeforeSave: number, form: FormGroup, afterSave: boolean, reloadLookup?: boolean){
    this.id = id;
    this.form = form;
    this.afterSave = afterSave;
    this.idBeforeSave = idBeforeSave;
    this.reloadLookup = reloadLookup != undefined ? reloadLookup : false;
  }
}
