import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { LookUpList } from 'src/app/models/admin/program/lookupType/lookupList';
import { CustomReport, CustomReportElement, CustomReportTable, GenerateCustomReportObject } from 'src/app/models/custom-report/customReport';
import { CustomReportList } from 'src/app/models/custom-report/customReportList';
import { LookupModel } from 'src/app/models/shared/lookup.model';

@Injectable({
  providedIn: 'root'
})
export class CustomReportService extends BaseService<CustomReportList, CustomReport> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/reports/custom-report", http, injector);
  }

  submitCustomReport(body: CustomReport): Observable<HttpResponse<CustomReport>> {
    return this.http.post<CustomReport>('/v1/reports/custom-report', body);
  }

  modifyElementCustomReport(elementId: number, body: CustomReportElement):Observable<HttpResponse<CustomReportElement>> {
      return this.http.put<any>('/v1/reports/custom-report/'+elementId+'/element', body);
  }

  postElementCustomReport(reportId: number,body: CustomReportElement):Observable<HttpResponse<CustomReportElement>> {
    return this.http.post<any>('/v1/reports/custom-report/'+reportId+'/element', body);
  }


  getUniqueSurveys(): Observable<HttpResponse<LookUpList>> {
    return this.http.get<LookUpList>("/v1/reports/custom-report/unique-surveys");
  }

  getUniqueQuestions(uuid: string){
    return this.http.get<LookUpList>("/v1/reports/custom-report/unique-questions/"+uuid);
  }

  getCustomReportObjectElementAttribute() : Observable<HttpResponse<LookupModel[]>> {
    return this.http.get<LookupModel[]>("/v1/lookups?types=CustomReportObjectElementAttribute");
  }

  deleteCellElement(reportId: number,elementId: number): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>('/v1/reports/custom-report/'+reportId+'/elements/' + elementId);
  }

  rearrangeElements(reportId: number,idOrderArray: {[key: number]: number }): Observable<HttpResponse<CustomReport>> {
    const keyValueListDTO = Object.entries(idOrderArray).map(([key, value]) => ({
      key: parseInt(key),
      value
    }));

    const data = {
      keyValueListDTO : keyValueListDTO
    }

    return this.http.post<CustomReport>('/v1/reports/custom-report/'+reportId+'/elements-arrangement/', data);
  }

  cloneCustomReport(reportId: number): Observable<HttpResponse<CustomReport>> {
    return this.http.post<CustomReport>('/v1/reports/custom-report/clone/'+reportId,null);
  }

  programFilteredList(programStartDate,programEndDate) : Observable<HttpResponse<LookUpList>>{
    return this.http.get<LookUpList>("/v1/program/date-range?programStartDate="+programStartDate+"&programEndDate="+programEndDate);
  }

  generateReport(reportId: number,customReportParams: string) : Observable<HttpResponse<CustomReport>>{
    return this.http.get<CustomReport>('/v1/reports/custom-report/'+reportId+'/generate?'+customReportParams);
  }

  getGenerateReports(params: string){
    return this.http.get<any>('/v1/reports/custom-report-list?'+params);
  }

  getCustomReportTable(customReportId: number, params: string) : Observable<HttpResponse<CustomReportTable>>{
    return this.http.get<CustomReportTable>('/v1/reports/custom-report/'+customReportId+'/table?'+params);
  }

  getAssessmentsList() : Observable<HttpResponse<LookUpList>>{
    return this.http.get<LookUpList>(`/v1/assessment/Lookup?assessmentType=all`);
  }

  getAssessmentCriteria(assessmentId: number) : Observable<HttpResponse<LookUpList>>{
    return this.http.get<LookUpList>(`/v1/assessment/criteria/Lookup?assessmentId=${assessmentId}`);
  }
}
