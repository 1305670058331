import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivityExecutionFormInput } from '../activity-execution-list/activity-execution-list.component';
import { ProgramBeneficiarySelectionList, ProgramOrganization } from 'src/app/models/admin/program/program/program';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivityAttendee, ActivityAttendeeCreation, ActivityExecution, ActivityExecutionCreation, ActivityTag, ProgramActivityExecutionDisplay } from 'src/app/models/admin/program/activities/activity-execution';
import { ProgramActivityService } from 'src/app/services/admin/program/activity-execution.service';
import { ProgramBeneficiarySelectionListComponent } from 'src/app/component/beneficiary/program-beneficiary-selection-list/program-beneficiary-selection-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TagLookupModel } from 'src/app/models/shared/tag.model';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { LookupModelDto } from '@siren-survey/app/models/shared/lookup.model';
import { Observable, Subscription, startWith, map, of } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-activity-execution-form',
  templateUrl: './activity-execution-form.component.html',
  styleUrls: ['./activity-execution-form.component.sass']
})
export class ActivityExecutionFormComponent implements OnInit{

  executionId: number;
  programActivityId: number;
  programId: number;
  programOrganizations: ProgramOrganization[] = [];
  selectedElements: ProgramBeneficiarySelectionList[] = [];
  programBeneficiaries: ProgramBeneficiarySelectionList[] = [];
  public tags: TagLookupModel[] = [];
  public form: FormGroup;
  activityExecution: ActivityExecution;
  isEditMode: boolean = true;
  minDate: Date;
  maxDate: Date;
  activityAttendeeList: ActivityAttendee[] = [];

  @ViewChild(ProgramBeneficiarySelectionListComponent) progBeneficiarySelectionComponent: ProgramBeneficiarySelectionListComponent;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public tagCtrl = new FormControl('');
  public filteredTags: Observable<TagLookupModel[]>;
  public selectedTags: TagLookupModel[] = [];
  public noSelections: boolean = false;
  private _subscription: Subscription = new Subscription();

  constructor(public dialogRef: MatDialogRef<ActivityExecutionFormComponent>, @Inject(MAT_DIALOG_DATA) public data: ActivityExecutionFormInput,
      public readonly _translateService: TranslateService, private programActivityService: ProgramActivityService,private _snackbar: MatSnackBar,private formBuilder: FormBuilder) {
    this.executionId = data.executionId;
    this.programActivityId = data.programActivityId;
    this.programId = data.programId;
    this.isEditMode = data.isEditMode;
    this.minDate = data.minDate;
    this.maxDate = data.maxDate;
    this.programOrganizations = data.programOrganizations;
    this.tags = data.tags;

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((filteredTag: string | TagLookupModel | null) => {
        if (typeof filteredTag === 'string') {
          const tagLookupModel = new TagLookupModel(0, new LookupModelDto(0, "", filteredTag, false, 0));
          return this.tagFilter(tagLookupModel);
        } else if (filteredTag instanceof TagLookupModel) {
          return [filteredTag];
        } else {
          return this.tags.slice();
        }
      })
    );
    this._subscription.add(
      this.tagCtrl.valueChanges.subscribe(newValue => {
        if (typeof newValue === 'string') {
          const tagLookupModel = new TagLookupModel(0, new LookupModelDto(0, "", newValue, false, 0));
          this.tagFilter(tagLookupModel);
        }  else if (this.isTagLookupModel(newValue)) {
          this.tagFilter(newValue);
        }
      })
    )
  }

  private isTagLookupModel(value: TagLookupModel) : boolean {
    return value instanceof TagLookupModel;
  }

  public removeTag(tag: TagLookupModel): void {
    const tagsFormArray = this.form.get('activityTags') as FormArray;
    const index = tagsFormArray.controls.findIndex((control) => control.value?.tag?.id === tag?.tag?.id);

    const selectedIndex = this.selectedTags.indexOf(tag);

    if (selectedIndex >= 0) {
      this.selectedTags.splice(selectedIndex, 1);
      tagsFormArray.removeAt(index);

      this.filteredTags = of(this.tags.filter(tag => !this.selectedTags.some(selectedTag => selectedTag.tag.id === tag.tag.id)));
    }
  }

  public selectTag(event: MatAutocompleteSelectedEvent): void {
      this.selectedTags.push(event.option.value);
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue(null);
      this.filteredTags = of(this.tags.filter(tag => !this.selectedTags.some(selectedTag => selectedTag.tag.id === tag.tag.id)));
      const tagsFormArray = this.form.get('activityTags') as FormArray;

      if (event.option.value) {
        const newTag = this.createActivityTagFormGroup(event.option.value);
        tagsFormArray.push(newTag);
      }
    }

  private tagFilter(filterValue: TagLookupModel): TagLookupModel[] {
    this.filteredTags = of(this.tags.filter(tag =>
      !this.selectedTags.some(selectedTag => selectedTag.tag.id === tag.tag.id) &&
      tag.tag.value.toLowerCase().includes(filterValue?.tag?.value?.toLowerCase())
    ));

   return this.tags.filter(tag => tag.tag.value.toLowerCase().includes(filterValue?.tag?.value?.toLowerCase()));
  }


  optionClicked(event: Event, tag: TagLookupModel) {
    event.stopPropagation();
    this.toggleSelection(tag);
  }

  toggleSelection(tag: TagLookupModel) {
    tag.selected = !tag.selected;
    if (tag.selected) {
      this.selectedTags.push(tag);
    } else {
      const i = this.selectedTags.findIndex(value => value.tag.id === tag.tag.id);
      this.selectedTags.splice(i, 1);
    }
  }


  editable() : boolean{
    return this.isEditMode;
  }

  ngOnInit(): void {
    this.createFormGroups();
    this.getActivityExecution();
  }

  getActivityExecution(){
    if(this.executionId != undefined && this.executionId > 0){
      this.programActivityService.getActivityExecutionById(this.executionId).subscribe(response =>{
        if(response && response.body){
          this.activityExecution = response.body;
          this.form.patchValue(this.activityExecution);
          this.patchActivityTags();
          if(this.activityExecution.activityAttendees){
            this.activityAttendeeList = this.activityExecution.activityAttendees;
            for(let activityAttendee of this.activityExecution.activityAttendees){
              let curr: ProgramBeneficiarySelectionList = {
                id: activityAttendee.id,
                beneficiary: activityAttendee?.programBeneficiary?.beneficiary
              };
              this.selectedElements.push(curr);
            }
          }
        }
      });
    }
  }

  createFormGroups(){
    this.form = new FormGroup({
      'id': new FormControl({value: this.activityExecution?.id, disabled: true}),
      'fromDate': new FormControl({value: this.activityExecution?.fromDate, disabled: !this.isEditMode}, Validators.required),
      'toDate': new FormControl({value: this.activityExecution?.toDate, disabled: !this.isEditMode}, Validators.required),
      'activityAttendees': new FormArray([], Validators.required),
      'activityTags': new FormArray([])
    });
  }

  createActivityAttendeeFormGroup(activityAttendee: ActivityAttendee): FormGroup{
    return new FormGroup({
      'id': new FormControl({value: activityAttendee?.id, disabled: true}),
      'programBeneficiary': new FormControl({value: activityAttendee?.programBeneficiary, disabled: !this.isEditMode}, Validators.required)
    });
  }

  private patchActivityTags() : void {
    if (this.form && this.activityExecution) {
      let tagsArray: FormArray = this.form.get('activityTags') as FormArray;
      tagsArray.clear();
      if (this.activityExecution.activityTags) {
        for (let activityTag of this.activityExecution.activityTags) {
          let tag = this.tags.find(tag => tag.tag.id === activityTag.tag.id);
          tagsArray.push(this.createActivityTagFormGroup(tag));
        }
      }
      this.selectedTags = tagsArray.getRawValue();
      this.filteredTags = of(this.tags.filter(tag => !this.selectedTags.some(selectedTag => selectedTag.tag.id === tag.tag.id)));
    }
  }

  private createActivityTagFormGroup(activityTag: ActivityTag) : FormGroup {
    return new FormGroup({
      'id': new FormControl({ value: activityTag?.id, disabled: true }),
      'tag': new FormGroup({
        'id': new FormControl({ value: activityTag?.tag?.id, disabled: true }),
        'key': new FormControl({ value: activityTag?.tag?.key, disabled: true }),
        'value': new FormControl({ value: activityTag?.tag?.value, disabled: true }),
      })
    });
  }


  get activityAttendees(): FormArray {
    return this.form.get('activityAttendees') as FormArray;
  }

  private validateFormForSave(): string {
    let error: string = undefined;
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if(this.progBeneficiarySelectionComponent.getCurrentSelection().length > 0){
      this.progBeneficiarySelectionComponent.getCurrentSelection().forEach(benef => {
        let attendee: FormGroup =  new FormGroup({
          'id': new FormControl({value: benef?.id, disabled: true}),
          'programBeneficiary': new FormControl({value: benef?.beneficiary, disabled: !this.isEditMode}, Validators.required)
        });
        this.activityAttendees.push(attendee);
      });
    }
    this.noSelections = this.activityAttendees.length == 0 ? true : false;

    if (this.form && (!this.form.valid || this.noSelections)) {
      error = this._translateService.instant('global.form-invalid');
    }
    return error;
  }

  public saveActivityExecution() : void{
    let error = this.validateFormForSave();
    if (!error) {
      this.executeSave();
    }
    if (error != undefined) this._snackbar.open(this._translateService.instant(error), null, { duration: 3000 });
  }

  executeSave(){
    if(this.form?.valid){
      let activityExecution: ActivityExecutionCreation = this.constructRequestData();
      if(activityExecution){
        this.programActivityService.saveActivityExecution(activityExecution).subscribe(response =>{
          if(response && response.body) this.dialogRef.close(response.body);
        });
      }
    }
  }

  constructRequestData(): ActivityExecutionCreation{
    let activityExecution: ActivityExecutionCreation;
    if(this.form?.valid){
      activityExecution = this.form.getRawValue();
      activityExecution.id = this.executionId;
      activityExecution.fromDate = this.formatDateOnly(activityExecution.fromDate);
      activityExecution.toDate = this.formatDateOnly(activityExecution.toDate);
      activityExecution.programActivity = {
        id : this.programActivityId
      };
      let selectedAttendees: ActivityAttendeeCreation[] = [];
      let beneficiarySelection: ProgramBeneficiarySelectionList[] = this.progBeneficiarySelectionComponent.getCurrentSelection();
      if(beneficiarySelection && beneficiarySelection.length > 0){
        for(let currSelection of beneficiarySelection){
          if(currSelection?.beneficiary != null){
            let existingAttendee = this.findExistingActivityAttendeeObject(currSelection?.beneficiary?.id);
            let attendeePostBody : ActivityAttendeeCreation = {
              id: existingAttendee ? existingAttendee.id : null,
              programBeneficiary: existingAttendee ? existingAttendee.programBeneficiary : currSelection,
            }

            selectedAttendees.push(attendeePostBody);
          }
        }
      }
      activityExecution.activityAttendees = selectedAttendees;
    }
    return activityExecution;
  }

  findExistingActivityAttendeeObject(beneficiaryId: number): ActivityAttendee{
    let foundObject = undefined;
    if(beneficiaryId != undefined && beneficiaryId > 0){
      for(let attendee of this.activityAttendeeList){
        if(foundObject != undefined) break;
        foundObject = foundObject == undefined && attendee?.programBeneficiary?.beneficiary?.id == beneficiaryId ? attendee : foundObject;
      }
    }
    return foundObject;
  }


  handleSelectedBeneficiaries(selectedBeneficiaries: ProgramActivityExecutionDisplay[]){
    this.activityAttendees.clear();
    selectedBeneficiaries.forEach(benef=>{
      this.activityAttendees.push(this.formBuilder.control(benef));
    });
    this.noSelections = this.activityAttendees.length == 0 ? true : false;
  }

  formatDateOnly(date: Date): any{
    if(date){
      date = new Date(date);
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let year = date.getFullYear();
      return year + "-" + month + "-" + day;
    } else undefined;
  }
}
