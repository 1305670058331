<mat-form-field class="full-width-element" >
    <mat-label>{{title}}</mat-label>
    <input
        matInput
        [matDatepicker]="datepicker"
        [formControl]="dateFormControl"
        [min]="min"
        [max]="max"
        class="{{customClass}}"
    />
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix  [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
</mat-form-field>
