import { Inject, Injectable, Injector } from '@angular/core';
import { LookupModel } from '../models/shared/lookup.model';
import { BaseService } from './shared/base-service';
import { MyHttpHandler } from './shared/http-handler.service';
import { ISurveyConfig } from '../config/survey-config.interface';
import { SettingsService } from '../shared/services/settings.service';

@Injectable({ providedIn: 'root' })
export class SurveyTypeService extends BaseService<LookupModel, LookupModel> {

  constructor(http: MyHttpHandler, protected injector: Injector, private settingsService: SettingsService){
    super(settingsService.settings.lookupUrlPath, http, injector);
  }

  protected getTableListParams(): Map<string, any> {
    let tableListParams: Map<string, any> = new Map<string, any>();
    tableListParams.set('types', 'SurveyType');
    return tableListParams;
  }

  protected getObjectUrlPrefix(): string{
    return "SurveyType";
  }

}
