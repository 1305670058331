<form [formGroup]="form" (ngSubmit)="saveSurvey()">
  <div class="survey-form container" [ngClass]="isPreview ? 'preview-container' : '' ">
    <div class="survey-form-header">
      <div class="container survey-form-title-header">
        <div class="page-title-container">
          <span class="page-title">{{'siren-survey-translation.survey-form.title' | translate}}</span>
        </div>
        <div class="page-title-buttons" *ngIf="isPreview">
          <div>
            <button *ngIf="id != undefined && id > 0" type="button"
              (click)="openLinkedSurveysTable();  $event.stopPropagation();" class="btn button-flat mobile-icon-padding"
              [disabled]="false">
              <i title="Linked Surveys" class="fa fa-external-link"
                style="font-size: 17px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
              <span *ngIf="!_isMobile">{{ 'siren-survey-translation.survey-form.fields.linked-surveys' | translate
                }}</span>
            </button>
          </div>
          <div class="bottom-border locate-text">
            <button *ngIf="id != undefined && id > 0" type="button" (click)="closePreviewPage()"
              class="btn button-flat mobile-icon-padding" [disabled]="false">
              <img *ngIf="!_isMobile" [src]="getAssetsUrl('assets/icons/questions-not-selected-simple-icon.svg')"
                title="Show Questions">
                <span *ngIf="!_isMobile" class="locate-text">{{'siren-survey-translation.global.questions' | translate}}</span>
            </button>
          </div>
          <div class="bottom-border locate-text selected-icon">
            <button *ngIf="id != undefined && id > 0" type="button" (click)="openPreviewPage()"
              class="btn button-flat mobile-icon-padding" [disabled]="false">
              <img [src]="getAssetsUrl('assets/icons/preview-selected-simple-icon.svg')"
                title="Show Preview">
                <span *ngIf="!_isMobile" class="locate-text selected-icon">{{'siren-survey-translation.global.preview' | translate}}</span>
            </button>
          </div>
          <div class="bottom-border">
             <button type="button" (click)="goBack(); $event.stopPropagation();" class="btn button-flat"
              [disabled]="false">
              <i class="fa fa-angle-left" aria-hidden="true"></i>
              <span *ngIf="!_isMobile" class="locate-text ">{{'siren-survey-translation.global.back' | translate}}</span>
            </button>
          </div>
        </div>
        <div class="page-title-buttons" *ngIf="!isPreview">
          <div>
            <button *ngIf="id != undefined && id > 0" type="button"
              (click)="openLinkedSurveysTable();  $event.stopPropagation();" class="btn button-flat mobile-icon-padding"
              [disabled]="false">
              <i title="Linked Surveys" class="fa fa-external-link"
                style="font-size: 17px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
              <span *ngIf="!_isMobile">{{ 'siren-survey-translation.survey-form.fields.linked-surveys' | translate
                }}</span>
            </button>
          </div>
          <div class="bottom-border locate-text selected-icon">
            <button *ngIf="id != undefined && id > 0" type="button" (click)="closePreviewPage()"
              class="btn button-flat mobile-icon-padding" [disabled]="false">
              <img [src]="getAssetsUrl('assets/icons/questions-selected-simple-icon.svg')"
                title="Show Questions">
                <span *ngIf="!_isMobile" class="locate-text selected-icon">{{'siren-survey-translation.global.questions' | translate}}</span>
            </button>
          </div>
          <div class="bottom-border locate-text">
            <button *ngIf="id != undefined && id > 0" type="button" (click)="openPreviewPage()"
              class="btn button-flat mobile-icon-padding" [disabled]="false">
              <img [src]="getAssetsUrl('assets/icons/preview-not-selected-simple-icon.svg')"
                title="Show Preview">
                <span *ngIf="!_isMobile" class="locate-text">{{'siren-survey-translation.global.preview' | translate}}</span>
            </button>
          </div>
          <div class="bottom-border">
            <button type="button" (click)="goBack(); $event.stopPropagation();" class="btn button-flat"
              [disabled]="false">
              <i class="fa fa-angle-left" aria-hidden="true"></i>
              <span *ngIf="!_isMobile" class="locate-text ">{{'siren-survey-translation.global.back' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="container survey-form-container-header survey-title-container" *ngIf="!_isMobile && !isPreview">
        <div class="full-width-element title-box">
          <mat-form-field class="full-width-element ">
            <mat-label class="">{{'siren-survey-translation.survey-form.fields.title' |
              translate}}</mat-label>
            <input matInput class="" formControlName="title">
          </mat-form-field>
        </div>
        <div class="survey-form-action-btn-container align-right">
          <mat-slide-toggle class=" color-slide-toogle" formControlName="graded">
            <mat-label *ngIf="form.get('graded').value"
              class=" slide-toogle-label">{{'siren-survey-translation.survey-form.fields.graded'
              | translate}}</mat-label>
            <mat-label *ngIf="!form.get('graded').value"
              class=" slide-toogle-label-danger">{{'siren-survey-translation.survey-form.fields.not-graded'
              | translate}}</mat-label>
          </mat-slide-toggle>
          <button *ngIf='this.isEditMode && this.saveAccess' [disabled]="disableSubmit" type="submit" class="btn btn-submit">
            <span style="white-space: nowrap;">{{'siren-survey-translation.global.save' | translate}}</span>
          </button>
          <button *ngIf='(this.isEditMode && this.saveAccess) && !survey?.published' type="button" (click)="publishSurvey()" class="btn btn-publish">
            <span style="white-space: nowrap;">{{'siren-survey-translation.survey-form.button.publish' |
              translate}}</span>
            <img [src]="getAssetsUrl('assets/icons/arrow-down-icon.svg')" class="widthIcon"
              [ngClass]="_translateService.currentLang == 'ar' ? 'rotateIcon-rtl' : 'rotateIcon-ltr' ">
          </button>
          <button *ngIf='survey?.id > 0 && survey?.published && survey?.enabled' type="button" (click)="disableSurvey()" class="btn btn-publish">
            <span style="white-space: nowrap;">{{'siren-survey-translation.survey-form.button.disable' |
              translate}}</span>
          </button>
          <button *ngIf='survey?.id > 0 && survey?.published && !survey?.enabled' type="button" (click)="enableSurvey()" class="btn btn-publish">
            <span style="white-space: nowrap;">{{'siren-survey-translation.survey-form.button.enable' |
              translate}}</span>
          </button>
          <button *ngIf="this.id != undefined && this.id > 0 && this.cloneAccess"
            title="{{'siren-survey-translation.survey-form.actions.clone-survey' | translate}}"
            class="button-flat btn-large-width button-start-padding" type="button" (click)="cloneSurvey()">
            <img [src]="getAssetsUrl('assets/icons/duplicate-icon-grey.svg')" class="clone-button-icon">
          </button>
          <button *ngIf='(this.isEditMode && this.saveAccess) &&  (this.id != undefined && this.id > 0 && this.deleteAccess)'
            title="{{'siren-survey-translation.survey-form.actions.delete-survey' | translate}}"
            class="button-flat btn-large-width button-start-padding" type="button" (click)="deleteSurvey()">
            <img [src]="getAssetsUrl('assets/icons/delete-icon-grey.svg')" class="delete-button-icon">
          </button>
          <button *ngIf='this.id != undefined && this.id > 0 && this.cloneAccess'
            title="{{'siren-survey-translation.survey-form.actions.delete-survey' | translate}}"
            class="btn btn-small-width button-start-padding" type="button" (click)="cloneSurvey()">
            {{'siren-survey-translation.survey-form.actions.clone-survey' | translate}}
          </button>
          <button *ngIf='(this.isEditMode && this.saveAccess) && (this.id != undefined && this.id > 0 && this.deleteAccess)'
            title="{{'siren-survey-translation.survey-form.actions.delete-survey' | translate}}"
            class="btn btn-small-width button-start-padding" type="button" (click)="deleteSurvey()">
            {{'siren-survey-translation.survey-form.actions.delete-survey' | translate}}
          </button>
        </div>
      </div>
      <div class="warning-container" *ngIf="((survey?.id > 0 ? survey?.hasResponse : false) && survey?.editable)">
        <mat-icon class="warning-icon">warning</mat-icon>
        <span class="warning-text">
          {{'siren-survey-translation.survey-form.fields.caution-submitted-responses' | translate}}</span>
      </div>
      <div class="container survey-form-container-header survey-title-container" *ngIf="_isMobile && !isPreview">
        <mat-accordion style="width: 100%;">
          <mat-expansion-panel (opened)="headerOpenState = true" (closed)="headerOpenState = false"
            style="border: none; box-shadow: none; padding: 5px">
            <mat-expansion-panel-header style="padding: 0px;">
              <div class="full-width-element">
                <mat-form-field class="full-width-element flat-input" appearance="none">
                  <input matInput class=" survey-title-input"
                    placeholder="{{'siren-survey-translation.survey-form.fields.title' | translate}}"
                    formControlName="title">
                </mat-form-field>
              </div>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body style="padding: 0px;">
              <div class="survey-form-action-btn-container align-right">
                <mat-slide-toggle class=" color-slide-toogle" formControlName="graded">
                  <mat-label *ngIf="form.get('graded').value"
                    class=" slide-toogle-label">{{'siren-survey-translation.survey-form.fields.graded'
                    | translate}}</mat-label>
                  <mat-label *ngIf="!form.get('graded').value"
                    class=" slide-toogle-label-danger">{{'siren-survey-translation.survey-form.fields.not-graded'
                    | translate}}</mat-label>
                </mat-slide-toggle>
                <button *ngIf='this.isEditMode && this.saveAccess' type="submit" class="btn btn-submit">
                  <span style="white-space: nowrap;">{{'siren-survey-translation.global.save' | translate}}</span>
                </button>
                <ng-container *ngIf="isFormLoaded">
                  <button *ngIf='(this.isEditMode && this.saveAccess) && !survey?.published' type="button" (click)="publishSurvey()"
                    class="btn btn-publish">
                    <span style="white-space: nowrap;">{{'siren-survey-translation.survey-form.button.publish' |
                      translate}}</span>
                    <img [src]="getAssetsUrl('assets/icons/arrow-down-icon.svg')" class="widthIcon"
                      [ngClass]="_translateService.currentLang == 'ar' ? 'rotateIcon-rtl' : 'rotateIcon-ltr' ">
                  </button>
                  <button *ngIf='survey?.id > 0 && survey?.published && survey?.enabled' type="button" (click)="disableSurvey()" class="btn btn-publish">
                    <span style="white-space: nowrap;">{{'siren-survey-translation.survey-form.button.disable' |
                      translate}}</span>
                  </button>
                  <button *ngIf='survey?.id > 0 && survey?.published && !survey?.enabled' type="button" (click)="enableSurvey()" class="btn btn-publish">
                    <span style="white-space: nowrap;">{{'siren-survey-translation.survey-form.button.enable' |
                      translate}}</span>
                  </button>
                  <button *ngIf="this.id != undefined && this.id > 0 && this.cloneAccess"
                    title="{{'siren-survey-translation.survey-form.actions.clone-survey' | translate}}"
                    class="button-flat btn-large-width  button-start-padding" type="button" (click)="cloneSurvey()">
                    <img [src]="getAssetsUrl('assets/icons/duplicate-icon-grey.svg')" class="clone-button-icon">
                  </button>
                  <button *ngIf='this.id != undefined && this.id > 0 && this.cloneAccess'
                    title="{{'siren-survey-translation.survey-form.actions.delete-survey' | translate}}"
                    class="btn btn-small-width button-small-margin  button-start-padding" type="button"
                    (click)="cloneSurvey()">
                    {{'siren-survey-translation.survey-form.actions.clone-survey' | translate}}
                  </button>
                  <button *ngIf='(this.isEditMode && this.saveAccess) &&  (this.id != undefined && this.id > 0 && this.deleteAccess)'
                    title="{{'siren-survey-translation.survey-form.actions.delete-survey' | translate}}"
                    class="button-flat btn-large-width button-start-padding" type="button" (click)="deleteSurvey()">
                    <img [src]="getAssetsUrl('assets/icons/delete-icon-grey.svg')" class="delete-button-icon">
                  </button>
                  <button *ngIf='(this.isEditMode && this.saveAccess) &&  (this.id != undefined && this.id > 0 && this.deleteAccess)'
                    title="{{'siren-survey-translation.survey-form.actions.delete-survey' | translate}}"
                    class="btn btn-small-width button-small-margin btn-delete-survey button-start-padding" type="button"
                    (click)="deleteSurvey()">
                    {{'siren-survey-translation.survey-form.actions.delete-survey' | translate}}
                  </button>
                </ng-container>
              </div>
            </mat-expansion-panel-body>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div class="container mat-focus-indicator survey-form-container" *ngIf="!isPreview">
      <div class="survey-form-content">
        <div class="form-title">
          <h3 class="survey-title">{{'siren-survey-translation.survey-form.information' | translate}}</h3>
        </div>
        <div class="row">
          <div class="col-lg-3 col-sm-6 col-12" *ngIf="this.survey?.creationDate">
            <mat-form-field class="full-width-element">
              <mat-label
                class="">{{'siren-survey-translation.survey-form.fields.creation-date' |
                translate}}</mat-label>
              <input matInput class=""
                placeholder="{{'siren-survey-translation.survey-form.fields.creation-date' | translate}}"
                formControlName="creationDate">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-6 col-12">
            <mat-form-field class="full-width-element">
              <mat-label class="">{{'siren-survey-translation.survey-form.fields.survey-type'
                | translate}}</mat-label>
              <mat-select class="" formControlName="surveyType"
                [compareWith]="objectComparisonFunction">
                <mat-option *ngFor="let surveyType of surveyTypes" [value]="surveyType">
                  {{ surveyType.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-sm-6 col-12">
            <survey-datepicker [max]="this.form?.get('endDateTime')?.getRawValue()"
              [title]="'siren-survey-translation.survey-form.fields.start-date' | translate"
              [dateFormControl]="this.form?.get('startDateTime')"></survey-datepicker>
          </div>
          <div class="col-lg-3 col-sm-6 col-12">
            <survey-datepicker [min]="this.form?.get('startDateTime')?.getRawValue()"
              [title]="'siren-survey-translation.survey-form.fields.end-date' | translate"
              [dateFormControl]="this.form?.get('endDateTime')"></survey-datepicker>
          </div>
          <div *ngIf="form?.get('graded')?.getRawValue()" class="col-lg-3 col-sm-6 col-12 tooltip-input-container">
            <mat-form-field class="full-width-element">
              <mat-label class="">{{'siren-survey-translation.survey-form.fields.max-grade' |
                translate}}</mat-label>
              <input matInput type="number" autocomplete="off" min="0" (keydown)="onKeyDownEvent($event)"
                class=""
                placeholder="{{'siren-survey-translation.survey-form.fields.max-grade' | translate}}"
                formControlName="maxGrade">
            </mat-form-field>
            <app-info-tooltip class="tooltipMargin"
              message="{{'siren-survey-translation.survey-form.fields-tooltip.max-grade' | translate}}"></app-info-tooltip>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="full-width-element">
              <mat-label class="">{{'siren-survey-translation.survey-form.fields.description'
                | translate}}</mat-label>
              <textarea matInput type="text-area" autocomplete="off" min="0" class=""
                placeholder="{{'siren-survey-translation.survey-form.fields.description' | translate}}"
                formControlName="description"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div formArrayName="sections" class="section-list-container" *ngIf="id && id > 0">
          <div class="survey-section-container" *ngFor="let section of getSectionsFormArray().controls; let i = index;"
            [formGroupName]="i">
            <app-survey-section *ngIf="section" [isEditMode]="isEditMode" [surveyId]="id" [formRefSection]="form"
              [elementIndex]="i" [gradedSurvey]="form?.get('graded')?.getRawValue()" [hasResponse]="hasResponse()"
              [upperBoundIndex]="getSectionsFormArray().length > 0 ? getSectionsFormArray().length - 1 : 0"
              [surveySectionForm]="getSurveySectionFormControlElement(section,i)"
              [hasUnsavedQuestionChanges]="this.surveyFormQuestionHandler?.hasUnsavedQuestionChanges"
              [questionInEditModeCount]="this.surveyFormQuestionHandler?.questionInEditModeCount"
              [questionPendingForSave]="this.surveyFormQuestionHandler?.newlyAddedSurveyQuestions"
              [questionChangeEventSubject]="this.surveyFormQuestionHandler?.questionChangeEventSubject"
              (editQuestionNotificationEmitter)="this.surveyFormQuestionHandler?.handleEditQuestionNotification($event)"
              (questionChangeEventEmitter)="questionChangeEventHandler($event)"
              [questionAnswerMap]="this.surveyFormQuestionHandler?.questionAnswerMap"
              [surveyFormQuestionHandler]="surveyFormQuestionHandler"
              [answerTypes]="answerTypes">
            </app-survey-section>
          </div>
          <div class="form-section-title" *ngIf='this.isEditMode && this.saveAccess'>
            <h3 class="survey-title">{{'siren-survey-translation.section-form.button.add' | translate}}</h3>
            <button *ngIf='this.isEditMode && this.saveAccess' class="btn btn-md table-action-button" type="button"
              (click)="addSection();$event.stopPropagation();"><span class="fa fa-plus"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-survey-response-form *ngIf="isPreview" isEditMode="true" [surveyId]="id"
    isPreview="true"></app-survey-response-form>
</form>
