import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginResponse } from 'src/app/models/auth/login-response.model';
import { SessionService } from 'src/app/services/shared/session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthModel } from 'src/app/models/shared/auth.model';
import { SettingsService } from '@siren-survey/app/shared/services/settings.service';
import { MarchMyHttpHandler } from 'src/app/shared/services/march-http-handler.service';
import { UserProfile } from 'src/app/models/shared/user-profile.model';
import { CustomValidators } from 'src/app/services/shared/validators.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.sass']
})
export class LoginFormComponent implements OnInit {
  form: FormGroup;
  error: string;
  // loginSubscription: Subscription;
  invalidLogin = false;
  public showPassword = false;

  constructor(private authService: AuthenticationService, private router: Router, private _translateService: TranslateService,
    private sessionService: SessionService, protected http: HttpClient, private settingsService: SettingsService, private marchHttp: MarchMyHttpHandler) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl(null,  [Validators.required, CustomValidators.noWhitespaceValidator]),
      password: new FormControl(null,  [Validators.required, CustomValidators.noWhitespaceValidator])
    });
    this.authService.clearSession();
  }

  tooglePassword(){
    this.showPassword = !this.showPassword;
  }

  submit() {
    this.error = "";
    this.showPassword = false;
    if (this.form.valid) {
      const user = this.form.getRawValue();
      let username: string;
      if (user.username !== undefined && user.username !== null) {
        username = user.username.trim();
      } else {
        username = '';
      }
      const password = user.password !== undefined && user.password !== null ? user.password.trim() : '';

      this.executeLogin(username, password);
    }
  }

  public executeLogin(username: string, password: string): void{
    let authRequest: AuthModel = new AuthModel();
    authRequest.password = password;
    authRequest.username = username;
    this.invalidLogin = false;
    this.showPassword = false;
    this.http.post<LoginResponse>(this.settingsService.settings.backendBaseUrl + "/v1/auth/login", authRequest).subscribe(loginBody => {
      if (loginBody) {
        this.sessionService.startSession(loginBody);
        let token = this.sessionService.getActiveSession().getAccessToken();
        let headers = new HttpHeaders()
          .set('Authorization', 'Bearer ' + token)
          .set('replace-header', 'true');
        this.http.get<UserProfile>(this.settingsService.settings.backendBaseUrl + "/v1/profile/user-profile", { 'headers': headers }).subscribe(userProfileBody => {
          if(userProfileBody) this.sessionService.startSession(loginBody, userProfileBody);
          this.router.navigate(['home']);
        }, error => {
          this.showPassword = false;
          // this.handleError(error);
          this.router.navigate(['home']);
        });
      }
    }, error => {
      this.handleError(error);
    });
  }

  handleError(e?): void {
    this.showPassword = false;
    this.invalidLogin = true;
    this.error = "Invalid request"; // this._translateService.instant('login.not_authorized');
    this.onLoginError(e);
  }

  onLoginError(error?: any) {
    this.showPassword = false;
    this.error = 'خطأ';
    this.form.controls.password.reset();
  }

  // ngOnDestroy() {
  //   if (this.loginSubscription) { this.loginSubscription.unsubscribe() }
  // }

  /**
  * Navigate to a another page
  * @param path string of the path we want to navigate to
  */
  public navigateTo(path: String) {
    this.router.navigate([path]);
  }
}





