import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { MenuActionEmittedObject, MenuActionObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { ResponsiveListInlineEditObject, TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { DatatableInlineEdit } from '@siren-survey/app/component/shared/datatable/datatable-inline-edit.service';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { beneficiaryStatusService } from 'src/app/services/admin/program/beneficiary-status.serive';
import { MatDialog } from '@angular/material/dialog';
import { BeneficiaryStatusChangeFormComponent } from '../beneficiary-status-change-form/beneficiary-status-change-form.component';
import { ChangeStatusFormInput } from '../beneficiary-form/beneficiary-form.component';

@Component({
  selector: 'app-beneficiary-status-table',
  templateUrl: './beneficiary-status-table.component.html',
  styleUrls: ['./beneficiary-status-table.component.sass']
})
export class BeneficiaryStatusTableComponent extends DatatableInlineEdit implements OnInit {

 tableTitleLabel: string;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  inlineEditEventSubject: Subject<ResponsiveListInlineEditObject> = new Subject<ResponsiveListInlineEditObject>();

  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;

  predefinedFilter: Map<string, any>;
  canAdd: boolean = false;
  hasListAccess: boolean = false;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  _booleanIconColumnDisplayList: string[] = [
    "isDefaultExitStatus", "isDefaultProgramEntryStatus", "isInitialStatus", "allowIncompleteProfile"
  ]

  // _tableColumns  : DataTableModel<ClassTypeList> []= [
    _tableColumns  : DataTableModel<any> []= [
    {  columnDef: 'name', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: this._translateService.instant('beneficiary-status.fields.status-name'),  cell: (element: any) => `${element?.name}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'allowIncompleteProfile', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: this._translateService.instant('beneficiary-status.fields.allow-incomplete-profile'),  cell: (element: any) => `${element?.allowIncompleteProfile}`, width: '150px'},
    {  columnDef: 'isDefaultExitStatus', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: this._translateService.instant('beneficiary-status.fields.default-exit-status'),  cell: (element: any) => `${element?.isDefaultExitStatus}`, width: '150px'},
    {  columnDef: 'isDefaultProgramEntryStatus', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: this._translateService.instant('beneficiary-status.fields.default-entry-status'),  cell: (element: any) => `${element?.isDefaultProgramEntryStatus}`, width: '150px'},
    {  columnDef: 'isInitialStatus', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: this._translateService.instant('beneficiary-status.fields.initial-status'),  cell: (element: any) => `${element?.isInitialStatus}`, width: '150px'},
    {  columnDef: '_menu_inline', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "name", translationPath: "beneficiary-status.fields.status-name", displayName: this._translateService.instant('beneficiary-status.fields.status-name'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "allowIncompleteProfile", translationPath: "beneficiary-status.fields.allow-incomplete-profile", displayName: this._translateService.instant('beneficiary-status.fields.allow-incomplete-profile'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal  , cssClass: 'col-xs-12 col-sm-6 col-md-3' },
    { name: "isDefaultExitStatus", translationPath: "beneficiary-status.fields.default-exit-status", displayName: this._translateService.instant('beneficiary-status.fields.default-exit-status'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal  , cssClass: 'col-xs-12 col-sm-6 col-md-3' },
    { name: "isDefaultProgramEntryStatus", translationPath: "beneficiary-status.fields.default-entry-status", displayName: this._translateService.instant('beneficiary-status.fields.default-entry-status'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal  , cssClass: 'col-xs-12 col-sm-6 col-md-3' },
    { name: "isInitialStatus", translationPath: "beneficiary-status.fields.initial-status", displayName: this._translateService.instant('beneficiary-status.fields.initial-status'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal  , cssClass: 'col-xs-12 col-sm-6 col-md-3' },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.BENEFICIARY_STATUS_UPDATE) , accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ])},
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg", accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ]), allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.BENEFICIARY_STATUS_UPDATE) }
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string{
    return this._translateService.instant('global.delete');
  }

  constructor(public service : beneficiaryStatusService, public dialog: MatDialog, private router: Router, private route : ActivatedRoute, private readonly _translateService: TranslateService,
    private dialogService: DialogService, public snackBar: MatSnackBar,public lookupService: LookupService, public authService: AuthenticationService) {
    super();
    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.BENEFICIARY_STATUS_UPDATE);
    this.hasListAccess = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.BENEFICIARY_STATUS_UPDATE);
  }

  ngOnInit(): void {
  }

  lastCreatedId = 0;
  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.CREATE_MODE) {
      this.lastCreatedId--;
      let editFormGroup = new FormGroup({
        'id': new FormControl({value: this.lastCreatedId, disabled: true}),
        'name': new FormControl({value:'', disabled: false},  [Validators.required, CustomValidators.noWhitespaceValidator]),
        'allowIncompleteProfile': new FormControl({value:'', disabled: false},  [Validators.required]),
        'isDefaultExitStatus': new FormControl({value:'', disabled: false},  [Validators.required]),
        'isDefaultProgramEntryStatus': new FormControl({value:'', disabled: false}, [Validators.required]),
        'isInitialStatus': new FormControl({value:'', disabled: false}, [Validators.required])
      })
      let inlineEditObject = new ResponsiveListInlineEditObject(this.lastCreatedId, this.lastCreatedId, editFormGroup,false);
      this.inlineEditEventSubject.next(inlineEditObject);
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
        this.dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-beneficiary-status'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
        .subscribe(response=>{
          if(response){
            this.service.deleteBeneficiaryStatus(menuAction.object.id).subscribe(response =>{
              if(response && response.body){
                this.reloadEventSubject.next(true);
              }
            },error=>{
              error = this._translateService.instant('beneficiary.message.benef-status-being-used');
              this.snackBar.open(error, null, { duration: 3000 });
            });
          }
        });
      } else{
        this.handleInlineEditingRequests(menuAction,this.snackBar,this._translateService);
      }
    }
  }

  isInlineEditable(): boolean {
    return true;
  }

  getTableFormGroupModel(): FormGroup<any> {
    return new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'name': new FormControl({value:'', disabled: false},  [Validators.required, CustomValidators.noWhitespaceValidator]),
      'allowIncompleteProfile': new FormControl({value:'', disabled: false},  [Validators.required]),
      'isDefaultExitStatus': new FormControl({value:'', disabled: false},  [Validators.required]),
      'isDefaultProgramEntryStatus': new FormControl({value:'', disabled: false}, [Validators.required]),
      'isInitialStatus': new FormControl({value:'', disabled: false}, [Validators.required])
    });
  }

  triggerInlineEditEventSubject(inlineEditObject: ResponsiveListInlineEditObject): void {

    if(this.inlineEditEventSubject) this.inlineEditEventSubject.next(inlineEditObject);
  }

  getTableService() {
    return this.service;
  }

  getValidForm(menuAction:MenuActionEmittedObject): boolean {
    menuAction.form.markAllAsTouched();
    menuAction.form.updateValueAndValidity();
    return menuAction.form.valid;
  }

  withCheckUnicity(): boolean{
    return true;
  }

  usePostForUpdate(): boolean{
    return true;
  }

  objectClassName(): string{
    return "BeneficiaryStatus";
  }
}

