import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { SurveyService } from '@siren-survey/app/services/survey.service';
import { CustomReportElement, ReportEnum, ReportFlagEnum, ReportObjectAttributesEnum, ReportObjectEnum, StringValueObject } from 'src/app/models/custom-report/customReport';
import { CustomValidators } from './../../..//services/shared/validators.service';
import { CustomReportService } from './../../../services/custom-report/custom-report.service';
import { formHelper } from '../../../shared/helper/form-helper';
@Component({
  selector: 'app-report-element',
  templateUrl: './report-element.component.html',
  styleUrls: ['./report-element.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ReportElementComponent extends DropdownComparatorWithBrowserListener implements OnInit {
  @Input() customReportElement: CustomReportElement;
  @Input() reportId: number;
  @Input() isEditMode: boolean;
  @Input() customReportElementsLength: number;
  @Output() cellEventEmitter = new EventEmitter<boolean>();

  form: FormGroup;
  cellTypes: StringValueObject[] = [
    {
      id: ReportEnum.SURVEY,
      value: ReportEnum.SURVEY
    },
    {
      id: ReportEnum.FLAGS,
      value: ReportEnum.FLAGS
    },
    {
      id: ReportEnum.BENEFICIARY,
      value: ReportEnum.BENEFICIARY
    },
    {
      id: ReportEnum.ASSESSMENT,
      value: "ASSESSMENT"
    }
  ];

  objectTypes: StringValueObject[] = [
    {
      id: ReportObjectEnum.BENEFICIARY,
      value: ReportObjectEnum.BENEFICIARY
    }
  ];

  flagTypes: StringValueObject[] = [
    {
      id: ReportFlagEnum.ACHIEVEMENT,
      value: ReportFlagEnum.ACHIEVEMENT
    },
    {
      id: ReportFlagEnum.TAG,
      value: ReportFlagEnum.TAG
    }
  ];


  error: string;

  surveys: LookupModel[] = [];
  surveyQuestions: LookupModel[] = [];
  beneficiaryList: LookupModel[] = [];
  achievements: LookupModel[] = [];
  tags: LookupModel[] = [];
  assessments: LookupModel[] = [];
  evaluations: LookupModel[] = [];
  criterea: LookupModel[] = [];
  objectAttributes: LookupModel[] = [];

  showSurveyList: boolean = false;
  showObjectTypes: boolean = false;
  showObjectAttributes: boolean = false;
  showSurveyQuestions: boolean = false;
  showFlagTypes: boolean = false;
  showAchievements: boolean = false;
  showTags: boolean = false;
  showAssessments: boolean = false;
  showAssessmentCriterea: boolean = false;
  showEvaluations: boolean = false;

  surveyUuid: string;
  questionId: number;
  baselineValue: boolean;
  objectType: string;
  objectAttribute: string;
  flagType: string;
  flagObjectId: number;

  public assessmentId: number;
  public critereonId: number;

  constructor(public _translateService: TranslateService, public service: SurveyService,
    public lookupService: LookupService, public customReportService: CustomReportService) {
    super();
  }

  ngOnInit(): void {
    if(this.customReportElement){
     this.createCustomReportFormFroup();

      switch (this.customReportElement.type) {
        case ReportEnum.SURVEY:
          this.getSurveyList();
          this.showSurveyList = true;
          this.surveyUuid = this.customReportElement.customReportSurveyElement.surveyUuid;
          this.customReportService.getUniqueQuestions(this.surveyUuid).subscribe(response => {
            if (response) {
              this.surveyQuestions = (response.body as any);
            }
          })
          this.showSurveyQuestions = true;
          this.questionId = this.customReportElement.customReportSurveyElement.question.id;
          this.baselineValue = this.customReportElement.customReportSurveyElement.baselineValue;
          break;
        case ReportEnum.OBJECT:
          this.objectType = this.customReportElement.customReportObjectElement.objectType;
          this.form.get('type').setValue(this.objectType);

          this.customReportService.getCustomReportObjectElementAttribute().subscribe(response => {
            if (response) {
              this.showObjectAttributes = true;
              this.objectAttributes = (response.body as any).CustomReportObjectElementAttribute;
              this.objectAttribute = this.customReportElement.customReportObjectElement.objectAttribute;
            }
          });
          break;
        case ReportEnum.ASSESSMENT:
          this.getAssessmentList();
          this.showAssessments = true;
          this.assessmentId = this.customReportElement.customReportAssessmentElement.assessmentId;
          this.customReportService.getAssessmentCriteria(this.assessmentId).subscribe(response => {
            if (response) {
              this.criterea = (response.body as any);
            }
          });
          this.showAssessmentCriterea = true;
          this.critereonId = this.customReportElement.customReportAssessmentElement.assessmentCriterion.id;
          break;
        // case ReportEnum.EVALUATION:
        //   this.getEvaluationList();
        //   this.showEvaluations = true;
        //   this.assessmentId = this.customReportElement.customReportAssessmentElement.assessmentId;
        //   this.customReportService.getAssessmentCriteria(this.assessmentId).subscribe(response => {
        //     if (response) {
        //       this.criterea = (response.body as any);
        //     }
        //   });
        //   this.showAssessmentCriterea = true;
        //   this.critereonId = this.customReportElement.customReportAssessmentElement.assessmentCriterion.id;
        //   break;
        case ReportEnum.FLAGS:
          this.showFlagTypes = true;
          this.flagType = this.customReportElement.customReportFlagElement.objectType;
          if(this.flagType == ReportFlagEnum.ACHIEVEMENT){
            this.lookupService.getLookupObjectsService(["Achievement"], false, true).subscribe(response => {
              if (response && response?.body) {
                this.achievements = response.body["Achievement"];
                this.showAchievements = true;
              }
            });
          }
          else if(this.flagType == ReportFlagEnum.TAG){
            this.lookupService.getLookupsService(["Tag"]).subscribe(response => {
              if (response && response?.body) {
                this.tags = response.body["Tag"];
                this.showTags = true;
              }
            });
          }
          this.flagObjectId = this.customReportElement.customReportFlagElement.objectId;
          break;
        default:
          break;
      }
    }
  }

  createCustomReportFormFroup(): void {
    this.form = new FormGroup({
      'id': new FormControl({value: this.customReportElement?.id, disabled: true}),
      'title': new FormControl({value: this.customReportElement?.title, disabled: !this.isEditMode},[Validators.required, CustomValidators.noWhitespaceValidator]),
      'type': new FormControl({value: this.customReportElement?.type, disabled: !this.isEditMode},Validators.required),
      'customReportSurveyElement': this.customReportSurveyElementFormGroup(),
      'customReportObjectElement': this.customReportObjectElementFormGroup(),
      'customReportFlagElement': this.customReportFlagElementFormGroup(),
      'customReportAssessmentElement': this.customReportAssessmentElementFormGroup(),
    });
  }

  customReportSurveyElementFormGroup(): FormGroup {
    return new FormGroup({
      'id': new FormControl({ value: this.customReportElement?.customReportSurveyElement?.id, disabled: true }),
      'surveyUuid': new FormControl({ value: this.customReportElement?.customReportSurveyElement?.surveyUuid, disabled: !this.isEditMode }),
      'baselineValue': new FormControl({ value: this.customReportElement?.customReportSurveyElement?.baselineValue, disabled: !this.isEditMode }),
      'question': new FormGroup({
        'id': new FormControl({ value: this.customReportElement?.customReportSurveyElement?.question?.id, disabled: !this.isEditMode },Validators.required)
      }),
    })
  }

  customReportObjectElementFormGroup(): FormGroup {
    return new FormGroup({
      'id': new FormControl({ value: this.customReportElement?.customReportObjectElement?.id, disabled: true }),
      'objectType': new FormControl({ value: this.customReportElement?.customReportObjectElement?.objectType, disabled: !this.isEditMode },Validators.required),
      'objectAttribute': new FormControl({ value: this.customReportElement?.customReportObjectElement?.objectAttribute, disabled: !this.isEditMode },Validators.required),
    })
  }

  customReportFlagElementFormGroup(): FormGroup {
    return new FormGroup({
      'id': new FormControl({ value: this.customReportElement?.customReportFlagElement?.id, disabled: true }),
      'objectType': new FormControl({ value: this.customReportElement?.customReportFlagElement?.objectType, disabled: !this.isEditMode },Validators.required),
      'objectId': new FormControl({ value: this.customReportElement?.customReportFlagElement?.objectId, disabled: !this.isEditMode },Validators.required),
    })
  }

  customReportAssessmentElementFormGroup(): FormGroup {
    return new FormGroup({
      'id': new FormControl({ value: this.customReportElement?.customReportAssessmentElement?.id, disabled: true }),
      'customReportElement': new FormControl({ value: this.customReportElement?.customReportAssessmentElement?.customReportElement, disabled: !this.isEditMode }),
      'assessmentCriterion':  new FormGroup({
        'id': new FormControl({ value: this.customReportElement?.customReportAssessmentElement?.assessmentCriterion?.id, disabled: !this.isEditMode },Validators.required)
      }),
      'assessmentId': new FormControl({ value: this.customReportElement?.customReportAssessmentElement?.assessmentId, disabled: !this.isEditMode },Validators.required),
    })
  }

  typeChanged(event: any) {
    formHelper.markAllControlsAsUntouched(this.form);
    this.showSurveyList = false;
    this.showSurveyQuestions = false;
    this.showObjectAttributes = false;
    this.showFlagTypes = false;
    this.showAchievements = false;
    this.showAssessments = false;
    this.showEvaluations = false;
    this.showAssessmentCriterea = false;
    this.showTags = false;

    const surveyGroup = this.form.get('customReportSurveyElement') as FormGroup;
    const objectGroup = this.form.get('customReportObjectElement') as FormGroup;
    const flagGroup = this.form.get('customReportFlagElement') as FormGroup;
    const assessmentsGroup = this.form.get('customReportAssessmentElement') as FormGroup;

    const surveyQuestionGroup = surveyGroup.get('question') as FormGroup;
    const assessmentCriterionGroup = assessmentsGroup.get('assessmentCriterion') as FormGroup;

    objectGroup.reset();
    flagGroup.reset();
    assessmentsGroup.reset();
    assessmentCriterionGroup.reset();
    surveyGroup.reset();

    switch (event.value) {
      case ReportEnum.SURVEY:
        this.getSurveyList();
        this.showSurveyList = true;
        surveyGroup.get('surveyUuid').setValidators([Validators.required]);
        surveyQuestionGroup.get('id').setValidators(Validators.required);
        surveyGroup.updateValueAndValidity();
        objectGroup.clearValidators();
        objectGroup.updateValueAndValidity();
        flagGroup.clearValidators();
        flagGroup.updateValueAndValidity();
        assessmentsGroup.clearValidators();
        assessmentsGroup.updateValueAndValidity();
        assessmentCriterionGroup.clearValidators();
        assessmentCriterionGroup.updateValueAndValidity();
        if (surveyGroup.get('surveyUuid').getRawValue() !== undefined) {
          surveyGroup.updateValueAndValidity();
          this.showSurveyQuestions = true;
        }
        break;
      case ReportEnum.BENEFICIARY:
        this.showObjectAttributes = true;
        this.getObjectElementAttribute();
        objectGroup.get('id').setValue(this.customReportElement?.customReportObjectElement?.id);

        // objectGroup.get('objectType').setValidators(Validators.required);
        objectGroup.get('objectAttribute').setValidators(Validators.required);
        objectGroup.updateValueAndValidity();
        surveyGroup.clearValidators();
        surveyGroup.updateValueAndValidity();
        surveyQuestionGroup.clearValidators();
        surveyQuestionGroup.updateValueAndValidity();
        flagGroup.clearValidators();
        flagGroup.updateValueAndValidity();
        assessmentsGroup.clearValidators();
        assessmentsGroup.updateValueAndValidity();
        assessmentCriterionGroup.clearValidators();
        assessmentCriterionGroup.updateValueAndValidity();
        // if (objectGroup.get('objectType').getRawValue() !== undefined) {
        objectGroup.updateValueAndValidity();
        this.showObjectAttributes = true;
        // }
        break;
      case ReportEnum.FLAGS:
        this.showFlagTypes = true;
        flagGroup.get('objectType').setValidators(Validators.required);
        flagGroup.get('objectId').setValidators(Validators.required);
        flagGroup.updateValueAndValidity();
        surveyGroup.clearValidators();
        surveyGroup.updateValueAndValidity();
        surveyQuestionGroup.clearValidators();
        surveyQuestionGroup.updateValueAndValidity();
        objectGroup.clearValidators();
        objectGroup.updateValueAndValidity();
        assessmentsGroup.clearValidators();
        assessmentsGroup.updateValueAndValidity();
        assessmentCriterionGroup.clearValidators();
        assessmentCriterionGroup.updateValueAndValidity();
        if (flagGroup.get('objectType').getRawValue() !== undefined) {
          flagGroup.updateValueAndValidity();
        }
        break;
      case ReportEnum.ASSESSMENT:
        this.getAssessmentList();
        this.showAssessments = true;
        if (assessmentsGroup.get('assessmentId').getRawValue() !== undefined) {
          assessmentsGroup.updateValueAndValidity();
          this.showAssessmentCriterea = true;
        }
        assessmentsGroup.get('id').setValue(this.customReportElement?.customReportAssessmentElement?.id)
        assessmentsGroup.get('assessmentId').setValidators([Validators.required]);
        assessmentCriterionGroup.get('id').setValidators(Validators.required);
        assessmentsGroup.updateValueAndValidity();
        surveyGroup.clearValidators();
        surveyGroup.updateValueAndValidity();
        surveyQuestionGroup.clearValidators();
        surveyQuestionGroup.updateValueAndValidity();
        objectGroup.clearValidators();
        objectGroup.updateValueAndValidity();
        flagGroup.clearValidators();
        flagGroup.updateValueAndValidity();
        break;
      // case ReportEnum.EVALUATION:
      //   this.getEvaluationList();
      //   this.showEvaluations = true;
      //   if (assessmentsGroup.get('assessmentId').getRawValue() !== undefined) {
      //     assessmentsGroup.updateValueAndValidity();
      //     this.showAssessmentCriterea = true;
      //   }
      //   assessmentsGroup.get('id').setValue(this.customReportElement?.customReportAssessmentElement?.id)
      //   assessmentsGroup.get('assessmentId').setValidators([Validators.required]);
      //   assessmentCriterionGroup.get('id').setValidators(Validators.required);
      //   assessmentsGroup.updateValueAndValidity();
      //   surveyGroup.clearValidators();
      //   surveyGroup.updateValueAndValidity();
      //   surveyQuestionGroup.clearValidators();
      //   surveyQuestionGroup.updateValueAndValidity();
      //   objectGroup.clearValidators();
      //   objectGroup.updateValueAndValidity();
      //   flagGroup.clearValidators();
      //   flagGroup.updateValueAndValidity();
      //   break;
      default:
        break;
    }
  }

  getAssessmentList() {
    this.customReportService.getAssessmentsList().subscribe(response => {
      if (response) {
        this.assessments = (response.body as any)
      }
    })
  }

  // getEvaluationList() {
  //   this.customReportService.getAssessmentsList().subscribe(response => {
  //     if (response) {
  //       this.evaluations = (response.body as any)
  //     }
  //   })
  // }

  assessmentSelected(event: any) {
    //call api to get assessmentCriterea based on assessment id
    this.assessmentId = event.value;
    this.customReportService.getAssessmentCriteria(this.assessmentId).subscribe(response => {
      if (response) {
        this.criterea = (response.body as any);
      }
    })
    const assessmentCriterionGroup = this.form.get('customReportAssessmentElement').get('assessmentCriterion') as FormGroup;
    assessmentCriterionGroup.reset();

    this.showAssessmentCriterea = true;
  }

  critereonSelected(event: any) {
    const title = this.criterea.find((element) => element.id === event.value);
    this.critereonId = event.value;
    this.form.get('title').setValue(title.value);
  }


  getObjectElementAttribute() {
    this.customReportService.getCustomReportObjectElementAttribute().subscribe(response => {
      if (response) {
        this.objectAttributes = (response.body as any).CustomReportObjectElementAttribute;
      }
    })
  }

  getSurveyList() {
    this.customReportService.getUniqueSurveys().subscribe(response => {
      if (response) {
        this.surveys = (response.body as any);
      }
    })
  }

  surveySelected(event: any) {
    //call api to get surveyQuestiond based on survey id
    this.surveyUuid = event.value;
    this.customReportService.getUniqueQuestions(this.surveyUuid).subscribe(response => {
      if (response) {
        this.surveyQuestions = (response.body as any);
      }
    })
    const surveyQuestionGroup = this.form.get('customReportSurveyElement').get('question') as FormGroup;
    surveyQuestionGroup.reset();
    this.showSurveyQuestions = true;
  }

  questionSelected(event: any) {
    const title = this.surveyQuestions.find((element) => element.id === event.value);
    this.questionId = event.value;
    this.form.get('title').setValue(title.value);
  }


  objectTypeSelected(event: any) {
    this.objectType = event.value;
    this.showObjectAttributes = true;
  }

  attributeSelected(event: any) {
    const title = this.objectAttributes.find((element) => element.key === event.value);
    this.objectAttribute = event.value;
    this.form.get('title').setValue(title.value);
  }


  objectFlagTypeSelected(event: any) {
    this.flagType = event.value;

    const flagGroup = this.form.get('customReportFlagElement') as FormGroup;
    flagGroup.get('objectId').setValue("");
    flagGroup.updateValueAndValidity();

    this.showFlagTypes = true;
    if(this.flagType == ReportFlagEnum.TAG){
      this.showAchievements = false;
      if(this.tags.length == 0){
        this.lookupService.getLookupsService(["Tag"]).subscribe(response => {
          if (response && response?.body) {
            this.tags = response.body["Tag"];
          }
          this.showTags = true;
        });
      } else{
        this.showTags = true;
      }
    }
    else{
      this.showTags = false;
      this.lookupService.getLookupObjectsService(["Achievement"], false, true).subscribe(response => {
        if (response && response?.body) {
          this.achievements = response.body["Achievement"];
        }
        this.showAchievements = true;
      });
    }
  }

  achivementSelected(event: any) {
    this.flagObjectId = event.value;
    const title = this.achievements.find((element) => element.id === event.value);
    this.form.get('title').setValue(title.value);
  }

  tagSelected(event: any) {
    this.flagObjectId = event.value;
    const title = this.tags.find((element) => element.id === event.value);
    this.form.get('title').setValue(title.value);
  }

  saveReportElement() {
    formHelper.markAllControlsAsTouched(this.form);
    let rawValue = this.form.getRawValue();
    this.form.get('title').setValue(this.form.get('title').getRawValue().trim());
    this.customReportElement = rawValue;

    const surveyGroup = this.form.get('customReportSurveyElement') as FormGroup;
    const objectGroup = this.form.get('customReportObjectElement') as FormGroup;
    const flagGroup = this.form.get('customReportFlagElement') as FormGroup;
    const assessmentGroup = this.form.get('customReportAssessmentElement') as FormGroup;
    const surveyQuestionGroup = surveyGroup.get('question') as FormGroup;
    const critereonGroup = assessmentGroup.get('assessmentCriterion') as FormGroup;

    switch (this.customReportElement.type) {
      case ReportEnum.SURVEY:
        this.customReportElement.customReportSurveyElement.question.id = this.questionId;
        this.customReportElement.customReportSurveyElement.surveyUuid = this.surveyUuid;
        this.customReportElement.customReportObjectElement = undefined;
        this.customReportElement.customReportFlagElement = undefined;
        this.customReportElement.customReportAssessmentElement = undefined;

        objectGroup.get('objectType').clearValidators();
        objectGroup.get('objectType').updateValueAndValidity();
        objectGroup.get('objectAttribute').clearValidators();
        objectGroup.get('objectAttribute').updateValueAndValidity();

        flagGroup.get('objectType').clearValidators();
        flagGroup.get('objectType').updateValueAndValidity();
        flagGroup.get('objectId').clearValidators();
        flagGroup.get('objectId').updateValueAndValidity();

        assessmentGroup.get('assessmentId').clearValidators();
        assessmentGroup.get('assessmentId').updateValueAndValidity();
        critereonGroup.get('id').clearValidators();
        critereonGroup.get('id').updateValueAndValidity();
        break;
      case ReportEnum.BENEFICIARY:
        this.customReportElement.customReportFlagElement = undefined;
        this.customReportElement.customReportSurveyElement = undefined;
        this.customReportElement.customReportAssessmentElement = undefined;
        this.customReportElement.type = ReportEnum.OBJECT;
        this.customReportElement.customReportObjectElement.objectType = ReportEnum.BENEFICIARY;
        this.customReportElement.customReportObjectElement.objectAttribute = this.objectAttribute;
        this.form.get('customReportObjectElement').get('objectType').setValue(ReportEnum.BENEFICIARY);
        this.form.get('customReportObjectElement').get('objectAttribute').setValue(this.objectAttribute);

        surveyGroup.get('surveyUuid').clearValidators();
        surveyGroup.get('surveyUuid').updateValueAndValidity();
        surveyQuestionGroup.get('id').clearValidators();
        surveyQuestionGroup.get('id').updateValueAndValidity();

        flagGroup.get('objectType').clearValidators();
        flagGroup.get('objectType').updateValueAndValidity();
        flagGroup.get('objectId').clearValidators();
        flagGroup.get('objectId').updateValueAndValidity();

        assessmentGroup.get('assessmentId').clearValidators();
        assessmentGroup.get('assessmentId').updateValueAndValidity();
        critereonGroup.get('id').clearValidators();
        critereonGroup.get('id').updateValueAndValidity();
        break;
      case ReportEnum.FLAGS:
        this.customReportElement.customReportObjectElement = undefined;
        this.customReportElement.customReportSurveyElement = undefined;
        this.customReportElement.customReportAssessmentElement = undefined;

        this.customReportElement.customReportFlagElement.objectId = this.flagObjectId;
        this.customReportElement.customReportFlagElement.objectType = this.flagType;

        surveyGroup.get('surveyUuid').clearValidators();
        surveyGroup.get('surveyUuid').updateValueAndValidity();
        surveyQuestionGroup.get('id').clearValidators();
        surveyQuestionGroup.get('id').updateValueAndValidity();

        objectGroup.get('objectType').clearValidators();
        objectGroup.get('objectType').updateValueAndValidity();
        objectGroup.get('objectAttribute').clearValidators();
        objectGroup.get('objectAttribute').updateValueAndValidity();

        assessmentGroup.get('assessmentId').clearValidators();
        assessmentGroup.get('assessmentId').updateValueAndValidity();
        critereonGroup.get('id').clearValidators();
        critereonGroup.get('id').updateValueAndValidity();

        break;
      case ReportEnum.ASSESSMENT:
        this.customReportElement.customReportObjectElement = undefined;
        this.customReportElement.customReportSurveyElement = undefined;
        this.customReportElement.customReportFlagElement = undefined;

        this.customReportElement.customReportAssessmentElement.assessmentId = this.assessmentId;
        this.customReportElement.customReportAssessmentElement.assessmentCriterion.id = this.critereonId;

        surveyGroup.get('surveyUuid').clearValidators();
        surveyGroup.get('surveyUuid').updateValueAndValidity();
        surveyQuestionGroup.get('id').clearValidators();
        surveyQuestionGroup.get('id').updateValueAndValidity();

        objectGroup.get('objectType').clearValidators();
        objectGroup.get('objectType').updateValueAndValidity();
        objectGroup.get('objectAttribute').clearValidators();
        objectGroup.get('objectAttribute').updateValueAndValidity();

        flagGroup.get('objectType').clearValidators();
        flagGroup.get('objectType').updateValueAndValidity();
        flagGroup.get('objectId').clearValidators();
        flagGroup.get('objectId').updateValueAndValidity();

        break;
      default:
        break;
    }

    this.customReportElement.order = this.customReportElement.id == undefined ? this.customReportElementsLength + 1 : this.customReportElementsLength;

    if (this.form.valid) {
      this.customReportService.postElementCustomReport(this.reportId, this.customReportElement).subscribe(response => {
        if (response) {
          this.customReportElement = response.body;
          this.customReportElement.type = this.customReportElement.customReportObjectElement != null ? ReportEnum.BENEFICIARY : this.customReportElement.type;
          this.createCustomReportFormFroup();
          this.reloadElementList();
        } else {
          this.error = this._translateService.instant('report.error.element-save-fail');
        }
      }, error => {
        this.error = error.error.message;
      });
    }
  }


  reloadElementList() {
    this.cellEventEmitter.emit();
  }
}
