import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { DataTableModel } from "../../../models/shared/cell-data-model";
import { TableAction } from "../../../models/shared/table-action";
import { TableFilterElement, TableFilterTypeEnum, FilterOperator, TableFilterOutput } from "../../../models/shared/table-filter.model";
import { SurveyList } from "../../../models/survey/surveyList";
import { SurveyService } from "../../../services/survey.service";
import { MenuActionObject, MenuActionEmittedObject } from "../../../models/shared/datatable-objects.model";
import { ISurveyAuthenticationService } from '../../../shared/services/interfaces/authentication.interface';
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-survey-response-table',
  templateUrl: './survey-response-table.component.html',
  styleUrls: ['./survey-response-table.component.sass']
})
export class SurveyResponseTableComponent implements OnInit {

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();

  hasListAccess: boolean = false;
  consumerPermissionEnum;

  _booleanIconColumnDisplayList: string[] = [
    "enabled","published"
  ]

  _tableColumns  : DataTableModel<SurveyList> []= [
    {  columnDef: 'title', headerTranslationPath: "siren-survey-translation.survey-form.fields.title", header: 'Title',  cell: (element: SurveyList) => `${element.title}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'description', headerTranslationPath: "siren-survey-translation.survey-form.fields.description", header: 'Description',  cell: (element: SurveyList) => `${element.description}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'startDateTime', headerTranslationPath: "siren-survey-translation.survey-form.fields.start-date", header: 'Start Date',  cell: (element: SurveyList) => `${element.startDateTime ? this._datePipe.transform(element.startDateTime, 'dd / MM / yyyy') : "-"}`, width: '150px'},
    {  columnDef: 'endDateTime', headerTranslationPath: "siren-survey-translation.survey-form.fields.end-date",  header: 'End Date',  cell: (element: SurveyList) => `${element.endDateTime ? this._datePipe.transform(element.endDateTime, 'dd / MM / yyyy') : "-"}`, width: '150px'},
    {  columnDef: 'enabled', headerTranslationPath: "siren-survey-translation.survey-form.fields.enabled", header: 'Enabled',  cell: (element: SurveyList) => `${element.enabled}`, width: '120px'},
    {  columnDef: 'published', headerTranslationPath: "siren-survey-translation.survey-form.fields.published", header: 'Published',  cell: (element: SurveyList) => `${element.published}`, width: '120px'},
  ] ;

  filters: TableFilterElement[] = [
    { name: "title", translationPath: "siren-survey-translation.survey-form.fields.title", displayName: "Title", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like, cssClass: 'col-xs-12 col-sm-6 col-md-3'  },
    { name: "description", translationPath: "siren-survey-translation.survey-form.fields.description", displayName: "Description", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "maxGrade", translationPath: "siren-survey-translation.survey-form.fields.max-grade", displayName: "Maximum Grade", type: TableFilterTypeEnum.Number, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "surveyType", translationPath: "siren-survey-translation.survey-form.fields.survey-type", displayName: "Survey Type", type: TableFilterTypeEnum.Lookup, lookupName: "SurveyType", lookupsValueById: true, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "enabled", translationPath: "siren-survey-translation.survey-form.fields.enabled", displayName: "Enabled", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "published", translationPath: "siren-survey-translation.survey-form.fields.published", displayName: "Published", type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "startDate", translationPath: "siren-survey-translation.survey-form.fields.start-date", displayName: "Start Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator['Greater Or Equal'], cssClass: 'col-xs-12 col-sm-6 col-md-4'  },
    { name: "endDate", translationPath: "siren-survey-translation.survey-form.fields.end-date", displayName: "End Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator['Less Or Equal'] , cssClass: 'col-xs-12 col-sm-6 col-md-4' },
    { name: "creationDate", translationPath: "siren-survey-translation.survey-form.fields.creation-date", displayName: "Creation Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal, cssClass: 'col-xs-12 col-sm-6 col-md-4'  },
  ];

  _tableMenuAction: MenuActionObject[] = [
  ]

  public surveyId: number;
  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  constructor(
    public surveyService: SurveyService,
    private router: Router,
    private route : ActivatedRoute,
    public authenticationService: ISurveyAuthenticationService,
    private snackbar: MatSnackBar,
    private _translateService: TranslateService,
    private _datePipe: DatePipe
  ) {
    this.consumerPermissionEnum = this.authenticationService.getPermissionEnumeration();
    this.hasListAccess = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SURVEY_LIST);

    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  ngOnInit(): void {
    this.surveyId = this.route.snapshot.params['surveyId'];

  }

  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.EDIT_MODE){
      this.router.navigate([event.id], {relativeTo: this.route});
    } else if(event.mode == TableAction.CREATE_MODE) {
      this.router.navigate(["create-response"], {relativeTo: this.route});
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }

  }


  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(menuAction.actionName == "DELETE_ACTION"){
        this.surveyService.deleteObject(menuAction.object.id).subscribe( response =>{
          this.reloadEventSubject.next(true);
        }, error =>{
          let message = error && error.error && error.error.message.includes("ConstraintViolationException")
            ? this._translateService.instant('error.record-depending-error')
            : this._translateService.instant('error.record-error');
          this.snackbar.open(message, null, { duration: 3000 });
        });
      }
    }
  }
}
