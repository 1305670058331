<div class="title_with_action">
    <div class="generic_title">
        <div>
        <a (click)="backClicked()" class="back-link">
            <mat-icon *ngIf="showBackButton" aria-hidden="false" aria-label="Example home icon">arrow_forward_ios</mat-icon>
        </a>

        <h5>
            {{ titleKey | translate }}
        </h5>
    </div>
    <div class="filter_btn" (click)="togglePanel()" *ngIf="withFilter">
        <img src="assets/icons/filter-icon.svg"> {{'siren-admin-translation.generic.filter.search' | translate}}
    </div>
    <div class="container" *ngIf="withFilter">
        <mat-expansion-panel class="container2" hideToggle #matExpansionPanel>
            <!-- Panel content -->
            <ng-content></ng-content>
            <!--End of panel content -->
        </mat-expansion-panel>
    </div>
    </div>

    <div class="button_container">
        <button *ngIf="showAddButton" class="new_user" type="button" (click)="newClicked()">
            <img src="assets/images/new_element.png" alt="" />
            <p class="new_user_title mx-auto">
              {{ addLabel | translate }}
            </p>
        </button>
    </div>
