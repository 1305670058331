<div class="fullwidth locateForm">
  <app-responsive-list-view
       [tableColumns]="_tableColumns"
       [sortingDataAccessor]="_sortingDataAccessor"
       [filteringPredicate]="_filteringPredicate"
       [service]="responseService"
       [withAdd]="canCreateResponse"
       [hasListAccess]="hasListAccess"
       [maxCellStringLength]="80"
       [maxStringLength]="20"
       [inputFilterEventSubject]="filterEventSubject.asObservable()"
       (mode)="updateViewMode($event)"
       [_tableMenuAction]="_tableMenuAction"
       [booleanIconColumnDisplayList]="_booleanIconColumnDisplayList"
       (menuActionEventEmitter)="tableMenuActionHandler($event)"
       addButtonLabel="{{'siren-survey-translation.response-table.add-response' | translate}}"
       [tableTitleLabel]="pageTitle"
       [inputReloadEventSubject]="reloadEventSubject.asObservable()"
       [showFilterButton]="true"
       initialPageSize="10">
       <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
       <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false"></app-table-filter>
    </app-responsive-list-view>
 </div>
