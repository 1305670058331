import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationAdminTableComponent } from '../../component/admin/organization/orgaization-admin-table/organization-admin-table.component';
import { ActivitiesAdminTableComponent } from '../../component/admin/program/activities-admin-table/activities-admin-table.component';
import { ClassTypeAdminTableComponent } from '../../component/admin/program/class-type-admin-table/class-type-admin-table.component';
import { ClassesAdminTableComponent } from '../../component/admin/program/classes-admin-table/classes-admin-table.component';
import { ProgramTableComponent } from '../../component/admin/program/program-table/program-table.component';
import { ProgramFormComponent } from '../../component/admin/program/program-form/program-form.component';
import { TranslateResolver } from '@siren-survey/app/shared/services/translation/translate-resolver.service';
import { LandingPageComponent } from '../../component/landing-page/landing-page.component';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ActivityCategoriesAdminTableComponent } from '../admin/program/activity-categories-admin-table/activity-categories-admin-table.component';
import { AdminGuard } from 'src/app/shared/guards/admin.guard';
import { MarchModuleEnum, MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { UserProfileComponent } from '../profile/user-profile/user-profile.component';
import { LookupsAdminTableComponent } from '../admin/organization/lookups-admin-table/lookups-admin-table.component';
import { AchievementTableComponent } from '../admin/achievement/achievement-table/achievement-table.component';
import { UserActivityComponent } from '../admin/user-activity/user-activity.component';
import { HomepageComponent } from '../homepage/homepage.component';
import { IncidentTableComponent } from '../incident/incident-table/incident-table.component';
import { LegalCaseStatusTableComponent } from '../legal-case/legal-case-status-table/legal-case-status-table.component';
import { IncidentTypeTableComponent } from '../incident/incident-type-table/incident-type-table.component';
import { BeneficiaryStatusTableComponent } from '../beneficiary/beneficiary-status-table/beneficiary-status-table.component';
import { ReportListComponent } from '../report/report-list/report-list.component';
import { ReportDataComponent } from '../report/report-data/report-data.component';
import { CustomReportGeneratedListComponent } from '../report/custom-report-generated-list/custom-report-generated-list.component';
import { ActivityResultComponent } from '../enrollment/activity-result/activity-result.component';
import { CanActivatePageGuard } from 'src/app/shared/guards/can-activate-page.guard';
import { ClassificationTableComponent } from '../admin/classification/classification-table/classification-table.component';
import { ClassificationFormComponent } from '../admin/classification/classification-form/classification-form.component';
import { ClassificationResolver } from '../admin/classification/services/classification.resolver';
import { LookupResolver } from '../admin/classification/services/lookup.resolver';
import { CustomReportTableComponent } from '../report/custom-report-table/custom-report-table.component';
import { DashboardResolver } from '../dashboard/services/dashboard.resolver';
import { FormTypeComponent } from '../admin/form-type/form-type.component';
import { ProgramResolver } from '../admin/program/service/program.resolver';
import { AssessmentResultResolver } from '../assessment/services/assessment-result.resolver';
import { AssessmentResultComponent } from '../assessment/assessment-result/assessment-result.component';


const routes: Routes = [
  {
    path: '', component: LandingPageComponent, resolve: { translations: TranslateResolver }, canLoad: [AuthGuard],
    children: [
      {
        path: 'home', component: HomepageComponent, pathMatch: 'full', canLoad: [AuthGuard]
      },
      {
        path: '', component: HomepageComponent, pathMatch: 'full', canLoad: [AuthGuard]
      },
      { path: 'dashboard', canLoad: [AuthGuard], loadChildren: () => import('./../dashboard/dashboard.module').then(m => m.DashboardModule) },
      {
        path: "incident",
        children: [
          { path: 'incidents', component: IncidentTableComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.INCIDENT_LIST } },
          { path: 'incident-type', component: IncidentTypeTableComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.INCIDENT_LIST } },
        ]
      },
      {
        path: "report",
        children: [
          {
            path: 'report-list', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CUSTOM_REPORT_LIST.module },
            children: [
              { path: 'create', component: ReportDataComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CUSTOM_REPORT_MODIFY } },
              { path: ':id', component: ReportDataComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CUSTOM_REPORT_MODIFY } },
              { path: '', component: ReportListComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CUSTOM_REPORT_LIST } },
              { path: "generated-reports/:id/:includeProgramAttendance", component: CustomReportGeneratedListComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CUSTOM_REPORT_LIST } },
              { path: "view-report/:id/:programIds/:title", component: CustomReportTableComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CUSTOM_REPORT_LIST } }
            ]
          }
        ]
      },
      { path: 'assessment', canLoad: [AuthGuard], loadChildren: () => import('./../assessment/assessment.module').then(m => m.AssessmentModule) },
      {
        path: 'enrollment', canLoad: [AuthGuard],
        children: [
          { path: 'beneficiary', canLoad: [AuthGuard], loadChildren: () => import('./../beneficiary/beneficiary.module').then(m => m.BeneficiaryModule) },
          { path: 'program', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.PROGRAM_LIST.module },
            children: [
              { path: 'create', component: ProgramFormComponent,  resolve: { data: ProgramResolver }, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.PROGRAM_GET }},
              { path: ':id', component: ProgramFormComponent, resolve: { data: ProgramResolver }, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.PROGRAM_GET }},
              { path: '', component: ProgramTableComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.PROGRAM_LIST }},
              { path:':id/assessment-result/:action/:name', component: AssessmentResultComponent, resolve: { translations: TranslateResolver, data: AssessmentResultResolver }, canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.ACTIVITY_LIST.module }},
            ],
          },
          { path:'activities', component: ActivityResultComponent, resolve: { translations: TranslateResolver }, canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.ACTIVITY_LIST.module }},
        ],
      },
      {
        path: "org-settings",
        children: [
          {
            path: 'beneficiaries-status', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.BENEFICIARY_STATUS_LIST.module },
            children: [
              { path: 'create', component: BeneficiaryStatusTableComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.BENEFICIARY_STATUS_UPDATE  }},
              { path: ':id', component: BeneficiaryStatusTableComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.BENEFICIARY_STATUS_LIST  } },
              { path: '', component: BeneficiaryStatusTableComponent, pathMatch: 'full' }
            ]
          },
          {
            path: 'achievement', component: AchievementTableComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.LOOKUPS_MGT.module },
          },
          {
            path: 'legalCaseStatuses', component: LegalCaseStatusTableComponent, canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.LOOKUPS_MGT.module },
          },
          {
            path: 'classType', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CLASS_TYPE_LIST.module },
            children: [
              { path: '', component: ClassTypeAdminTableComponent, pathMatch: 'full' }
            ],
          },
          {
            path: 'classes', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CLASS_LIST.module },
            children: [
              { path: '', component: ClassesAdminTableComponent, pathMatch: 'full' }
            ],
          },
          {
            path: 'activity-category', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.ACTIVITY_CATEGORY_LIST.module },
            children: [
              { path: '', component: ActivityCategoriesAdminTableComponent, pathMatch: 'full' }
            ],
          },
          { path:'activities', component: ActivitiesAdminTableComponent, resolve: { translations: TranslateResolver }, canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.ACTIVITY_LIST.module }},
          {
            path: 'classification', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CLASSIFICATION_LIST.module },
            children: [
              { path: '', component: ClassificationTableComponent, pathMatch: 'full' },
              { path: 'create', component: ClassificationFormComponent, pathMatch: 'full',  resolve: { data: ClassificationResolver }, canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CLASSIFICATION_MODIFY }},
              { path: ':id', component: ClassificationFormComponent, pathMatch: 'full', resolve: { data: ClassificationResolver }, canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.CLASSIFICATION_MODIFY }},
            ],
          },
          { path: 'organization', component: OrganizationAdminTableComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.ORGANIZATION_LIST.module } },
          { path: 'lookups', component: LookupsAdminTableComponent,  resolve: { data: LookupResolver }, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchModuleEnum.LOOKUPS_MGT.module } },
          //Permission same as beneficiary status
          { path: 'formType', component: FormTypeComponent,  resolve: { translations: TranslateResolver }, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchPermissionEnum.BENEFICIARY_STATUS_LIST.module } },
        ]
      },
      {
        path: 'survey', canLoad: [AuthGuard],
        loadChildren: () => import('../../siren_survey_web/src/app/survey-app.module').then(m => m.SurveyAppModule)
      },
      {
        path: 'admin', canLoad: [AuthGuard],
        children: [

          { path: '', loadChildren: () => import('../../siren_admin_web/src/app/admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard] },

        ],
      },
      {
        path: 'usermanagement', canLoad: [AuthGuard],
        children: [
          { path: 'activity', component: UserActivityComponent, pathMatch: 'full', canActivate: [CanActivatePageGuard], data: { pageAccess: MarchModuleEnum.LOGGING_ACTIVITIES_MGT.module } },
          { path: '', loadChildren: () => import('../../siren_admin_web/src/app/admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard] },
        ],
      },
      {
        path: 'profile', component: UserProfileComponent, pathMatch: 'full', canLoad: [AuthGuard]
      },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingRoutingModule { }
