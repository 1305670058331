import { Observable } from "rxjs";

export abstract class IAdminAuthenticationService {
  public abstract getAccessToken(): string;

	public abstract checkIfUserIsAvailable(): boolean;

  public abstract refreshToken(): Observable<any>;

  // public abstract getUserAccessRights(): string[];

  // public abstract isAdminUser(): boolean;

  public abstract validateRightCombination(combinationsList: string[][], applyAndOperatorOnCombination: boolean): boolean;

  public abstract isModuleAccessible(module: string): boolean;

  // public abstract accessRightValidation(module: string): boolean;
  public abstract userModuleAccessRightValidation(userModule: any): boolean;

  // public abstract getUserRole(): string;

  public abstract getPermissionEnumeration();


}
