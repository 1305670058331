import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Beneficiary, BeneficiaryAchievement, BeneficiaryAchievementPostModel, BeneficiaryCodeUnicityCheck, BeneficiaryComment, BeneficiaryCommentCreationModel, BeneficiaryLegalCase, BeneficiaryLegalCasePost, BeneficiaryStatusChangeModel, SuccessStory, SuccessStoryCreationModel } from '../../models/beneficiary/beneficiary';

import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { BeneficiaryList } from 'src/app/models/beneficiary/beneficiaryList';
import { ProgramClassActivityList, ProgramList } from 'src/app/models/admin/program/program/programList';
import { Assessment } from 'src/app/component/assessment/models/assessment.model';
import { AssessmentBenefLink } from 'src/app/component/beneficiary/models/benficiary-assessment.model';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService extends BaseService<BeneficiaryList, Beneficiary> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/beneficiary", http, injector);
  }

  async validateCodeUnicity(object: BeneficiaryCodeUnicityCheck): Promise<HttpResponse<boolean>>{
    return this.http.post<boolean>("/v1/beneficiary/code-valid", object).toPromise();
  }

  // checkCodeUnicity(object: BeneficiaryCodeUnicityCheck): Observable<HttpResponse<boolean>>{
  //   return this.http.post<boolean>("/v1/beneficiary/code-exists", object);
  // }

  getNewestInitCode(organizationId: number, genderPrefix: string): Observable<HttpResponse<any>> {
    // Make the API call to get a new code and return the code as a Promise
    return this.http.get<any>('/v1/beneficiary/generate-init-code/'+organizationId + "?genderPrefix=" + genderPrefix);
  }

  getNewestCode(organizationId: number, genderPrefix: string): Observable<HttpResponse<any>> {
    // Make the API call to get a new code and return the code as a Promise
    return this.http.get<any>('/v1/beneficiary/generate-code/'+organizationId + "?genderPrefix=" + genderPrefix);
  }

  updateBeneficiaryStatus(beneficiaryId: number, body: BeneficiaryStatusChangeModel): Observable<HttpResponse<Beneficiary>> {
    return this.http.put<any>('/v1/beneficiary/status/' + beneficiaryId, body);
  }

  updateAchievement(beneficiaryId: number, body: BeneficiaryAchievementPostModel): Observable<HttpResponse<BeneficiaryAchievement>> {
    return this.http.post<any>('/v1/beneficiary/achievement', body);
  }

  deleteAchievement(beneficiaryId: number): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>('/v1/beneficiary/achievement/' + beneficiaryId);
  }

  postBeneficiaryComment(beneficiaryId: number, body: BeneficiaryCommentCreationModel): Observable<HttpResponse<BeneficiaryComment>> {
    return this.http.post<BeneficiaryComment>('/v1/beneficiary/comment/' + beneficiaryId, body);
  }

  deleteBeneficiaryComment(commentId: number): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>('/v1/beneficiary/comment/' + commentId);
  }

  flagAsSuccessStory(beneficiaryId: number, body: SuccessStoryCreationModel): Observable<HttpResponse<SuccessStory>> {
    return this.http.post<SuccessStory>('/v1/beneficiary/success-story/' + beneficiaryId, body);
  }

  removeSuccessStory(beneficiaryId: number): Observable<HttpResponse<boolean>> {
    return this.http.delete<boolean>('/v1/beneficiary/success-story/' + beneficiaryId);
  }

  getBeneficiaryResponses(beneficiaryId: number, params: string): Observable<HttpResponse<any>> {
    let url = '/v1/beneficiary/response-list/' + beneficiaryId;
    if(params) url += params;
    return this.http.get<any>(url);
  }

  postBeneficiaryLegalCase(beneficiaryId: number, body: BeneficiaryLegalCasePost): Observable<HttpResponse<BeneficiaryLegalCase>> {
    return this.http.post<BeneficiaryLegalCase>('/v1/beneficiary/legal-case/' + beneficiaryId, body);
  }

  getOrganizationBeneficiariesLookupList(organizationId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>('/v1/beneficiary/organization/' + organizationId);
  }

  getBeneficiaryPrograms(beneficiaryId: number): Observable<HttpResponse<ProgramList[]>> {
    return this.http.get<ProgramList[]>('/v1/beneficiary/' + beneficiaryId + "/program");
  }

  linkRiskAssessment(body: AssessmentBenefLink): Observable<HttpResponse<AssessmentBenefLink>> {
    return this.http.post<any>(`/v1/assessment/beneficiary-link`, body);
  }

  getProgramActivitiesAndClassesByBeneficiaryId(beneficiaryId: number, programId: number): Observable<HttpResponse<ProgramClassActivityList[]>> {
    return this.http.get<ProgramClassActivityList[]>('/v1/beneficiary/' + beneficiaryId + "/program/" + programId);
  }

  // clonebeneficiary(beneficiaryId: number): Observable<Beneficiary> {
  //   return this.http.post<Beneficiary>("/v1/beneficiary/clone/" + beneficiaryId, null);
  // }

  // isbeneficiaryActive(beneficiaryId: number): Observable<boolean> {
  //   return this.http.get<boolean>("/v1/beneficiary/active/" + beneficiaryId);
  // }

  // publishbeneficiary(beneficiaryId: number): Observable<Beneficiary> {
  //   return this.http.post<Beneficiary>("/v1/beneficiary/publish-beneficiary/" + beneficiaryId, null);
  // }

  // unPublishbeneficiary(beneficiaryId: number): Observable<Beneficiary> {
  //   return this.http.post<Beneficiary>("/v1/beneficiary/unpublish-beneficiary/" + beneficiaryId+"?deleteDraftResponse=false", null);
  // }

  // enablebeneficiary(beneficiaryId: number): Observable<Beneficiary> {
  //   return this.http.post<Beneficiary>("/v1/beneficiary/enable-beneficiary/" + beneficiaryId+"?enable=true", null);
  // }

  // disablebeneficiary(beneficiaryId: number): Observable<Beneficiary> {
  //   return this.http.post<Beneficiary>("/v1/beneficiary/enable-beneficiary/" + beneficiaryId+"?enable=false", null);
  // }
}
