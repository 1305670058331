<div [hidden]="close">
  <div class="dialog-header">
    <img src="assets/images/pencil.png" alt="" />
    <p>
      {{
        (data.modalTitle ? data.modalTitle : "siren-admin-translation.admin_module.edit_lookups")
          | translate
      }}
    </p>
    <mat-icon (click)="apply(false)">clear</mat-icon>
  </div>
  <div class="column_title_1" *ngIf="data?.detailsTitle">
    {{ data?.detailsTitle | translate }}
  </div>
  <app-custom-form
    *ngIf="!data.isReport"
    [data]="data"
    [submitAction]="submitAction"
    (actionClicked)="apply($event)"
    (fieldChange)="changeField($event)"
    listenToChanges="true"
  >
  </app-custom-form>
  <div style="width: 100%; text-align: center; margin-bottom: 10px;"><span class="error-msg">{{error}}</span></div>
</div>

<div *ngIf="close" class="form_container dialog_form no_padding_bottom">
  <p>{{ "siren-admin-translation.confirmation_dialog.text" | translate }}</p>
  <div class="dialog_form_buttons">
    <button class="submit_btn" (click)="closeDialog(false)">
      {{ "siren-admin-translation.confirmation_dialog.reject" | translate }}
    </button>
    <button mat-button class="action_btn" (click)="buttonClicked(false)">
      {{ "siren-admin-translation.confirmation_dialog.confirm" | translate }}
    </button>
  </div>
</div>
