import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart, ChartConfiguration, ChartOptions } from 'chart.js';
import { Subscription } from 'rxjs';
import { IncidentFeed } from 'src/app/models/incident/incident';
import { LookupModel } from 'src/app/models/shared/lookup.model';
import { IncidentService } from 'src/app/services/incident/incident.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.sass']
})
export class HomepageComponent implements OnInit {

  listIncident:IncidentFeed[] = [] ;

  private _dashboardAccess: boolean = true;

  constructor(
    public incidentService: IncidentService,
    private _route: ActivatedRoute,
    private _router: Router,
    ){}

  ngOnInit(): void {
    this.incidentService.getLastDaysIncidents().subscribe(response =>{
      let listIncidentResponse = response?.body;
      this.listIncident = listIncidentResponse?.content as IncidentFeed[];
    });

    if(this._dashboardAccess){
      this._router.navigate([`/dashboard`] );
    }
  }

}
