import { FormArray, FormGroup } from "@angular/forms";
import { MenuActionObject } from "../models/shared/datatable-objects.model";

export class Utils{
  static deepClone(obj : any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  static adaptOrderField(objectArray: FormArray) : FormArray{
        if(objectArray){
            objectArray.controls.forEach((control, index) =>{ 
               (control as FormGroup).controls['order'].setValue(index + 1);     
        });   
      }   
      return objectArray; 
  }

  static isAllowedAction(element: any, menuAction: MenuActionObject): boolean{
    let allowedAction = true;
    if(element && menuAction && menuAction.accessRightExpressions != undefined && menuAction.accessRightExpressions.size > 0){
      menuAction.accessRightExpressions.forEach(
        function(value, key){
          if(allowedAction) allowedAction = Utils.validateFunction(element, key, value);
        }
      );
    }

    if(allowedAction && menuAction.allowedAction !=undefined){
      allowedAction = menuAction.allowedAction;
    }

    return allowedAction;
  }

  static validateFunction(object: any, accessRightVariablePath: string, accessRightVariableExpression: string): boolean {
    let isValid = false;
    const variable = object[accessRightVariablePath];
    try {
      const validateExpression = new Function(accessRightVariablePath, `return ${accessRightVariableExpression}`);
      isValid = validateExpression(variable);
    } catch (error) {
      console.error(error);
    }
    return isValid;
  }
}
