import { CommonModule, DatePipe } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SessionService } from './services/shared/session.service';
import { AuthenticationService } from './services/auth/authentication.service';
import { ISessionService } from '@siren-survey/app/services/shared/interfaces/session-service.interface';
import { ISurveyAuthenticationService } from '@siren-survey/app/shared/services/interfaces/authentication.interface';
import { SpinnerInterceptor } from './shared/interceptors/spinner.interceptor';
import { RequestInterceptor } from './shared/interceptors/request.interceptor';
import { ResponseInterceptor } from './shared/interceptors/response.interceptor';
import { IAdminAuthenticationService } from '@siren-auth/app/shared/services/adminAuthentication.interface';
import { SettingsHttpService } from './shared/services/settings-http.service';
import { LoginFormComponent } from './component/login-form/login-form.component';
import { LandingModule } from './component/landing-page/landing.module';
import { AuthGuard } from './shared/guards/auth.guard';
import { MarchMyHttpHandler } from './shared/services/march-http-handler.service';
import { AdminGuard } from './shared/guards/admin.guard';
import { PublicResetPasswordComponent } from './component/public-reset-password/public-reset-password.component';
import { PublicResetPasswordSubmissionComponent } from './component/public-reset-password-submission/public-reset-password-submission.component';
import { ILookupService } from '@siren-auth/app/shared/services/lookupService.interface';
import { IAdminSessionService } from '@siren-auth/app/shared/services/session.interface';
import { MarchLookupService } from './services/lookups/march-lookup.service';
import { ISurveyTopicService } from '@siren-survey/app/shared/services/interfaces/survey-topic.interface';
import { SurveyTopicService } from './services/shared/survey-topic.service';
import { ISurveyResponseClientService } from '@siren-survey/app/shared/services/interfaces/survey-response-client.interface';
import { MarchSurveyResponseService } from './services/survey/march-survey-response/march-survey-response.service';
import { CUSTOM_DATE_FORMATS, CustomDateAdapter } from './shared/base/date-adapter';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function appInit(settingsHttpService: SettingsHttpService) {
	return (): Promise<any> => settingsHttpService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    PublicResetPasswordComponent,
    PublicResetPasswordSubmissionComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LandingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			},
			extend: true
		}),
    MatSnackBarModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatExpansionModule,
  ],
  providers: [
    MarchMyHttpHandler,
    AuthGuard,
    AdminGuard,
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: ISurveyAuthenticationService, useExisting: AuthenticationService },
    { provide: IAdminAuthenticationService, useExisting: AuthenticationService },
    { provide: ILookupService, useExisting: MarchLookupService },
    { provide: ISessionService, useExisting: SessionService },
    { provide: IAdminSessionService, useExisting: SessionService },
    { provide: ISurveyTopicService, useExisting: SurveyTopicService},
    { provide: ISurveyResponseClientService, useExisting: MarchSurveyResponseService },
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [SettingsHttpService], multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } } ,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
