import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { SettingsService } from '../services/settings.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(public auth: AuthenticationService, private session: SessionService, private settingsService: SettingsService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if(!request.headers.has("replace-header") || request.headers.get("replace-header") == "false"){
        let token = this.auth && this.auth.getAccessToken();
        let lang = this.settingsService && this.settingsService.settings ? this.settingsService.settings.defaultLanguage : "en";
        if(this.session && this.session.getActiveLanguage()) lang = this.session.getActiveLanguage();
        let headers = token ?
            new HttpHeaders()
            .set('Accept-Language', lang)
            .set('Authorization', 'Bearer ' + token)
            // .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*')
          :
            new HttpHeaders()
            .set('Accept-Language',lang)
            // .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*');
        request = request.clone({ headers });
      }
      return next.handle(request);
    }
}
