export enum DATA_TABLE_COLUMN_FORMAT {
  Date,
  Link,
  Amount,
  String,
  DateTime,
  Boolean,
  Combination,
  Action,
  Toggle
}  
