import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { Activity } from '../../../models/admin/program/activities/activity';
import { ActivityList } from '../../../models/admin/program/activities/activityList';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService extends BaseService<ActivityList, Activity> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/activity", http, injector);
  }

  enableDisableActivity(activityId: number,enabled : boolean): Observable<HttpResponse<Activity>> {
    return this.http.put<Activity>("/v1/activity/enable/" + activityId+"?enable="+enabled, null);
  }
}
