import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { BasePanelListComponent } from '../../shared/panel-list/base-panel-list.component';
import { SurveySelectionModel } from '../../../models/survey/survey-selection.model';
import { SurveyService } from '../../../services/survey.service';
import { LookupService } from '../../../services/lookups/lookup.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenLinkedSurveyQuestionService } from '../../../services/shared/open-linked-survey-question.service';

@Component({
  selector: 'app-survey-selection-table',
  templateUrl: './survey-selection-table.component.html',
  styleUrls: ['./survey-selection-table.component.sass']
})
export class SurveySelectionTableComponent  extends BasePanelListComponent implements OnInit{
  @Input() baseSurveyId: number; // allowing to get the list of surveys linked to this survey
  @Input() baseQuestionId: number; // allowing to get the list of surveys that uses this question

  surveyList: SurveySelectionModel[] = [];
  predefinedFilter: Map<string, any> = new Map<string, any>();

  public filterForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<SurveySelectionTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SurveySelectionTableInput,
    private surveyService: SurveyService,
    public lookupService: LookupService,
    private _openLinkedSurveyQuestionService: OpenLinkedSurveyQuestionService,
    protected http: HttpClient,
    protected datePipe: DatePipe,
    private route : ActivatedRoute,
    private router: Router,
  ){
    super(datePipe);
    this.baseQuestionId = data.questionId;
    this.baseSurveyId = data.surveyId;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  isLinkedToSurvey(): boolean{
    return this.baseSurveyId != undefined && this.baseSurveyId > 0;
  }

  isLinkedToQuestion(): boolean{
    return this.baseQuestionId != undefined && this.baseQuestionId > 0;
  }

  // base object abstract methods implementation
  constructFilterFormGroup(){
    // this.filterForm = this.formBuilder.group({
    //   'title': '',
    //   'maxGrade': '',
    //   'isBaseLine': '',
    //   'valueFrom': '',
    //   'valueTo': '',
    // });
  }

  getQueryParamsWithFilter(): string{
    let params = '?page=' + this.currentPage + '&size=' + this.pageSize;

    if(this.isLinkedToSurvey()){
      params += "&linkedSurveyId=" + this.baseSurveyId;
    } else if(this.isLinkedToQuestion()){
      params += "&questionId=" + this.baseQuestionId;
    }
    if(this.filterForm){
      const formValue = this.filterForm?.value;
      const paramObject = Object.entries(formValue)
        .map(([key, value]) => {
          if(key != undefined && value != undefined){
            if (key === 'valueFrom') {
              return this.getFilterParamForDate('submitDateFrom', value);
            } else if (key === 'valueTo') {
              return this.getFilterParamForDate('submitDateTo', value);
            } else if(key === 'maxGrade'){
              return this.getFilterParamForNumber('maxGrade', value);
            } else if(key === 'isBaseLine'){
              return this.getFilterParamForBoolean('isBaseLine', value);
            } else if(key === 'title'){
              return this.getFilterParamForString('isBasetitleLine', value);
            }
          }
          return '';
        }).filter(Boolean).join('&');
      if(paramObject != undefined && paramObject.trim() != '') params += '&' + paramObject.trim();
    }
    return params;
  }

  getPageData(){
    if(this.isLinkedToSurvey() || this.isLinkedToQuestion()){
      let params = this.getQueryParamsWithFilter();
      this.surveyService.getLinkedSurveyForObject(params).subscribe(response =>{
        if(response && response.body){
          this.surveyList = response.body.content;
          this.maxPage = response.body.totalPages;
          this.totalElements = response.body.totalElements;
        }
      });
    }
  }

  openSurveyPreviewForm(surveyId: number){
    this.router.navigateByUrl(`/survey/list/${surveyId}`);
    this.dialogRef.close();
    this._openLinkedSurveyQuestionService.closeLinkedSurveysTable();
  }
}


export interface SurveySelectionTableInput{
  surveyId: number;
  questionId: number;
}
