export const byteHelper = {

  /**
  * This is a sample function that convert Bytes to Size.
  *
  */
  convertBytesToSize(bytes: number): string {
    if (bytes === 0) {
      return '0 B';
    }

    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizeInUnits = (bytes / Math.pow(1024, i)).toFixed(2);
    const sizeUnit = sizes[i];

    return `${sizeInUnits} ${sizeUnit}`;
  }
}
