import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'lookups', pathMatch: 'full' },
      { path: 'users', loadChildren: () => import('./user-roles/user-roles.module').then(m => m.UserRolesModule)},
      // { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
      // { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
      // { path: 'lookups', loadChildren: () => import('./lookups/lookups.module').then(m => m.LookupsModule) },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }
