import { FormGroup } from "@angular/forms";
import { MenuActionEmittedObject } from "../../../models/shared/datatable-objects.model";
import { ResponsiveListInlineEditObject } from "../../../models/shared/table-action";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

export abstract class DatatableInlineEdit{

  abstract isInlineEditable(): boolean;
  abstract getTableFormGroupModel(): FormGroup;
  abstract triggerInlineEditEventSubject(inlineEditObject: ResponsiveListInlineEditObject): void;
  abstract getTableService();
  abstract getValidForm(menuAction:MenuActionEmittedObject): boolean;

  withCheckUnicity(): boolean{
    return false;
  }

  customUnicity(): boolean{
    return false;
  }

  objectClassName(): string{
    return undefined;
  }

  isUnicityCheckConfigured(){
    return this.withCheckUnicity() && this.objectClassName() != undefined && this.objectClassName().trim() != "";
  }

  usePostForUpdate(): boolean{
    return false;
  }

  handleOnSubmitError(error? : string){
    return  error != undefined && error.trim() != "" ? error : "Unable to save record";
  }

  handleInlineEditingRequests(menuAction: MenuActionEmittedObject, snackBar: MatSnackBar, _translateService: TranslateService,reloadLookup?: boolean){
    if(menuAction && menuAction.actionName && menuAction.object){
      if(this.isInlineEditable() && this.getTableFormGroupModel()){
        if (menuAction.actionName == "EDIT_ACTION"){
          this.initTableElementFormGroup(menuAction.object, menuAction.object.id,false);
        } else if (menuAction.actionName == "SAVE_ACTION"){
          if(this.isUnicityCheckConfigured() && this.getValidForm(menuAction)){
            if(this.customUnicity()){
              this.getTableService().checkCustomNameUnicity(menuAction.object.id, menuAction.object.key).subscribe(response =>{
                if(response && response.body){
                  this.executeRecordCreateUpdateCall(menuAction, snackBar,reloadLookup);
                } else{
                  snackBar.open("Name already exists!", null, { duration: 5000 });
                }
              });
            }
            else{
              this.getTableService().checkNameUnicity(this.objectClassName(), menuAction.object.id, menuAction.object.name).subscribe(response =>{
                if(response && response.body){
                  this.executeRecordCreateUpdateCall(menuAction, snackBar,reloadLookup);
                } else{
                  snackBar.open("Name already exists!", null, { duration: 5000 });
                }
              });
            }
          } else{
            this.executeRecordCreateUpdateCall(menuAction, snackBar);
          }
        }
      }
    }
  }

  executeRecordCreateUpdateCall(menuAction: MenuActionEmittedObject, snackBar: MatSnackBar,reloadLookup?: boolean){
    if(this.getTableService() != undefined && this.getValidForm(menuAction)){
      menuAction.object = this.formatObject(menuAction.object);
      if(menuAction?.object?.id != undefined && menuAction?.object?.id > 0 && !this.usePostForUpdate()){
        this.getTableService().putObject(menuAction.object.id,menuAction.object).subscribe( response =>{
          this.initTableElementFormGroup(response.body, menuAction.object.id, true,reloadLookup);
        }, error =>{
          if(snackBar != undefined) snackBar.open(this.handleOnSubmitError(error?.error?.message), null, { duration: 3000 });
        });
      } else {
        this.getTableService().postObject(menuAction.object).subscribe( response =>{
          this.initTableElementFormGroup(response.body, menuAction.object.id,true,reloadLookup);
        }, error =>{
          if(snackBar != undefined) snackBar.open(this.handleOnSubmitError(error?.error?.message), null, { duration: 3000 });
        });
      }
    }
  }

  formatObject(object: any): any{
    return object;
  }

  formatEditObject(object: any, isAfterSave: boolean): any{
    return object;
  }

  initTableElementFormGroup(object: any, idBeforeSave: number, isAfterSave: boolean, reloadLookup?: boolean): FormGroup{

    let tableFormGroup: FormGroup = this.getTableFormGroupModel();
    if(idBeforeSave == undefined) idBeforeSave = object.id;
    if(tableFormGroup != undefined && object != undefined){

      object = this.formatEditObject(object,isAfterSave);
      tableFormGroup.patchValue(object);
      let inlineEditObject = new ResponsiveListInlineEditObject(object.id, idBeforeSave, tableFormGroup, isAfterSave,reloadLookup);
      this.triggerInlineEditEventSubject(inlineEditObject);
    }
    return tableFormGroup;
  }
}
