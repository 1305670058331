import { DatePipe, Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { SettingsService } from '@siren-survey/app/shared/services/settings.service';
import { CustomReport, CustomReportElement } from 'src/app/models/custom-report/customReport';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CustomReportService } from 'src/app/services/custom-report/custom-report.service';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';

@Component({
  selector: 'app-report-data',
  templateUrl: './report-data.component.html',
  styleUrls: ['./report-data.component.sass']
})
export class ReportDataComponent extends DropdownComparatorWithBrowserListener implements OnInit {
  @Input() isEditMode: boolean = true;
  @Input() customReportId: number;

  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  customReportForm: FormGroup = new FormGroup({});
  customReport: CustomReport;

  isFormDataChanged: boolean = false;
  idOrderArray: {[key: number]: number} = {};
  isFormLoaded: boolean = false;
  isSubmitted: boolean = false;
  hasListAccess: boolean = false;
  showReportDetails: boolean = false;
  customReportElements: CustomReportElement[];
  public error = "";

  headerOpenState = false;
  ordersChanged: boolean =  false;

  constructor(private customReportService: CustomReportService, private route : ActivatedRoute, private router: Router,
              private _snackbar: MatSnackBar, private dialogService: DialogService, public readonly _translateService: TranslateService,
              private datePipe : DatePipe, private location: Location, private settingsService: SettingsService,public authenticationService: AuthenticationService){
    super();
    this.hasListAccess = this.authenticationService.userModuleAccessRightValidation(this.marchPermissionEnum.CUSTOM_REPORT_LIST);
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  ngOnInit(): void {
     
    this.goBack = this.goBack.bind(this);
    if(this.hasListAccess) this.getCustomReportData(); // if no access dont call api
     this.customReportForm = new FormGroup({
      'id': new FormControl({value: undefined, disabled: true}),
      'title': new FormControl({value: undefined, disabled: !this.isEditMode}, [Validators.required, CustomValidators.noWhitespaceValidator]),
      'disabled': new FormControl({value: false, disabled: !this.isEditMode}),
      'includeProgramAttendance': new FormControl({value: false, disabled: !this.isEditMode})
    });
    this.isFormDataChanged = true;
  }

  getCustomReportData(){
    if(this.customReportId == undefined) this.customReportId = this.route.snapshot.params['id'];
    this.getCustomReportDataById();
  }

  editable() : boolean{
    return this.isEditMode;
  }

  getCustomReportDataById(){
    if(this.customReportId != undefined && this.customReportId > 0){
      this.customReportService.getObject(this.customReportId).subscribe(response => {
        if(response) {
          this.customReport = response.body;
          // this.createFormGroups();
          this.customReportForm.patchValue(this.customReport);
          this.customReportForm.valueChanges.subscribe(() => {
            this.isFormDataChanged = true;
          });
          this.customReportElements = this.customReport?.customReportElements;
          this.isSubmitted = true;
        }
      }, error =>{
        this.error = error.error.message;
      });
    } else {
      // this.isEditMode = false;
    }
  }


  submit(){
    if(this.editable){
      this.customReportForm.markAllAsTouched();
      this.customReportForm.updateValueAndValidity();
      if(this.customReportForm && this.customReportForm.valid){
        this.customReportForm.get('title').setValue(this.customReportForm.get('title').getRawValue().trim());
        this.executeSubmit(this.customReportForm.getRawValue());
      }
      else {
        if(!this.customReportForm.get('title').valid){
          this.customReportForm.get('title').setValue(undefined);
        }
        this._snackbar.open(this._translateService.instant('siren-survey-translation.error.form_invalid'), null, { duration: 3000 });
      }
    }
  }

  get disabled() {
    return this.customReportForm.get('disabled');
  }

  onToggleSlideToggle(event: any) {
    this.disabled.setValue(!this.disabled.value);
  }


  executeSubmit(responseForm : CustomReport): void{
    this.error = "";
    responseForm.id = this.customReportId ? this.customReportId : undefined;
    this.isFormDataChanged = false;
    this.customReportService.submitCustomReport(responseForm).subscribe( response =>{
      this.isFormDataChanged = true;
      if(response && response.body){
        this.customReportElements = response.body?.customReportElements;
        this.isSubmitted = true;
        this.customReportId =  response.body?.id;
        this.customReportForm.patchValue(response.body);
      } else{
        this.error = "Could not create object!";
      }
    }, error =>{
      this.isFormDataChanged = true;
      if(error.error){
        this.error = error.error.message;
      } else if(error.message){
        this.error = error.message;
      } else{
        this.error = "An error has occurred";
      }
    });
  }

  handleCellList(event){
    this.getCustomReportDataById();
  }

  goBack(){
    this.location.back();
  }

  onKeyDownEvent(event: any){
    if(event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }

}

