import { ComponentRef } from "@angular/core";
import { DataTableModel } from "../../../models/shared/cell-data-model";
import { SurveyResponseList } from "../../../models/response/survey-response-list";

export abstract class ISurveyResponseClientService {

  public abstract getAdditionalResponseHeaderTemplate(responseId: number, surveyId: number, isEditable: boolean): ComponentRef<any>;

  public abstract getSubmitSurveyQueryParams(componentRef: ComponentRef<any>): string;

  public abstract evaluateCustomConditionForQuestionVisibility(object: any): boolean;

  public abstract handleSelectBeneficiaryId(benefId: number);

  public abstract getSelectedBeneficiaryId();

  public abstract getDataEmitter();

  public abstract getResponseTableAdditionalColumns(): DataTableModel<SurveyResponseList>[];
}


