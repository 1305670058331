import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { IdObject, LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { Observable, Subject, map, of } from 'rxjs';
import { ProgramActivityExecution } from 'src/app/models/admin/program/activities/activity-execution';
import { BeneficiaryResponse } from 'src/app/models/beneficiary/beneficiary';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { BeneficiaryResponseService } from 'src/app/services/beneficiary/beneficiary-response.service';
import { SurveyTopicService } from 'src/app/services/shared/survey-topic.service';
import { MarchSurveyResponseService } from 'src/app/services/survey/march-survey-response/march-survey-response.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';

@Component({
  selector: 'app-march-survey-response-header',
  templateUrl: './march-survey-response-header.component.html',
  styleUrls: ['./march-survey-response-header.component.sass']
})
export class MarchSurveyResponseHeaderComponent extends DropdownComparatorWithBrowserListener implements OnInit {

  @Input() responseId: number;
  @Input() surveyId: number;
  @Input() isEditable: boolean;
  @Input() marchSurveyResponseService: MarchSurveyResponseService;

  canSetBaseline: boolean = false;

  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  responseHeaderFormGroup: FormGroup;
  beneficiaries: LookupModel[];
  organizationSurveys: LookupModel[];
  activityExecutions: LookupModel[];
  isBaseLine: boolean = false;
  programActivityExecutions: ProgramActivityExecution[] = [];
  programActivityExecutionMap: Map<number, LookupModel[]> = new Map();

  beneficiaryResponse: BeneficiaryResponse;
  selectedOrganizationSurvey: number;

  showActivityExecution: boolean = false;

  constructor(private lookupService: LookupService, private beneficiaryResponseService: BeneficiaryResponseService, private route: ActivatedRoute,
    private authService: AuthenticationService, public surveyTopic: SurveyTopicService) {
    super();
    this.selectedOrganizationSurvey = this.route.snapshot.queryParams['topic'];

  }

  ngOnInit(): void {
    this.createFormGroups();
    this.beneficiaryResponseService.getBeneficiaryList(this.surveyId).subscribe(response => {
      if (response?.body) this.beneficiaries = response.body;
    });
    if (this?.surveyId > 0) {
      this.surveyTopic.getOrganizationSurveyLookup(this.surveyId).subscribe(response => {
        if (response?.body) {
          this.organizationSurveys = response.body;
          if (this.organizationSurveys != undefined && this.selectedOrganizationSurvey != undefined && this.selectedOrganizationSurvey > 0) {
            let found: boolean = false;
            this.organizationSurveys.forEach(orgSurv => {
              if (!found && orgSurv != undefined && orgSurv.id == this.selectedOrganizationSurvey) {
                this.responseHeaderFormGroup.get('organizationSurvey').patchValue(orgSurv);
                found = true;

                this.surveyTopic.getActivityExecutionListLookup(this.selectedOrganizationSurvey).subscribe(response => {
                  this.activityExecutions = response.body;
                  if (this.activityExecutions && this.activityExecutions.length > 0) {
                    this.showActivityExecution = true;
                    this.patchComponentValue('activityExecution', this.beneficiaryResponse?.activityExecution?.id, false);
                  }
                })
              }
            });
          }
        }
      })
    }

    this.getData();

    this.canSetBaseline = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.BASELINE_SUBMIT);

  }

  createFormGroups() {
    this.responseHeaderFormGroup = new FormGroup({
      'id': new FormControl({ value: undefined, disabled: true }, Validators.required),
      'beneficiary': new FormControl({ value: this.beneficiaryResponse?.beneficiary, disabled: !this.isEditable }, Validators.required),
      'organizationSurvey': new FormControl({ value: this.beneficiaryResponse?.organizationSurvey, disabled: !this.isEditable }),
      'surveyResponse': new FormControl({ value: this.beneficiaryResponse?.surveyResponse, disabled: !this.isEditable }),
      'activityExecution': new FormControl({ value: this.beneficiaryResponse?.activityExecution, disabled: !this.isEditable }),
    });
  }

  getData() {
    if (this.responseId != null && this.responseId > 0) {
      this.beneficiaryResponseService.checkIfResponseIsBaseLine(this.responseId).subscribe(response => {
        if (response && response.body) this.isBaseLine = response.body;
      });
      this.beneficiaryResponseService.getBeneficiaryResponseBySurveyResponseId(this.responseId).subscribe(response => {
        if (response && response.body) {
          this.beneficiaryResponse = response.body;
          this.patchComponentValue('id', this.beneficiaryResponse?.id);
          this.marchSurveyResponseService.handleSelectBeneficiaryId(this.beneficiaryResponse?.beneficiary?.id);
          this.patchComponentValue('beneficiary', this.beneficiaryResponse?.beneficiary?.id);
          this.patchComponentValue('surveyResponse', this.beneficiaryResponse?.surveyResponse?.id);
          this.patchComponentValue('organizationSurvey', this.beneficiaryResponse?.organizationSurvey?.id);
          if (this.beneficiaryResponse?.activityExecution?.id && this.beneficiaryResponse?.organizationSurvey?.id > 0) {
            this.surveyTopic.getActivityExecutionListLookup(this.beneficiaryResponse?.organizationSurvey?.id).subscribe(response => {
              this.activityExecutions = response.body;
              this.showActivityExecution = true;
              this.patchComponentValue('activityExecution', this.beneficiaryResponse?.activityExecution?.id);
            })
          }
        }
      });
    }
  }

  patchComponentValue(name: string, objectId: number, disable?: boolean) {
    if (objectId) {
      let value: LookupModel = {
        id: objectId,
        key: objectId + '',
        order: 1,
        value: '',
        deprecated: false
      }
      this.responseHeaderFormGroup?.get(name)?.patchValue(value);
    }
    if (disable == undefined || disable == true) this.responseHeaderFormGroup?.get(name)?.disable();
  }

  isValidForm(): boolean {
    if (this.responseId != undefined && this.responseId > 0) {
      return true;
    } else {
      this.responseHeaderFormGroup.markAllAsTouched();
      this.responseHeaderFormGroup.updateValueAndValidity();
      return this.responseHeaderFormGroup && this.responseHeaderFormGroup.valid;
    }
  }

  setResponseAsBaseline() {
    if (!this.isBaseLine && this.responseId != undefined && this.responseId > 0) {
      this.beneficiaryResponseService.setResponseAsBaseLine(this.responseId).subscribe(response => {
        if (response && response.body) this.isBaseLine = true;
      });
    }
  }

  displayActivityExecution(event: any) {
    this.beneficiaryResponseService.getBeneficiaryList(this.surveyId, event?.value?.id).subscribe(response => {
      if (response?.body) {
        this.beneficiaries = response.body;
        this.responseHeaderFormGroup?.controls?.beneficiary?.setValue(null);
      }
    });

    if (event?.value?.value.includes('Activity') && event?.value?.id > 0) {
      this.surveyTopic.getActivityExecutionListLookup(event.value.id).subscribe(response => {
        this.activityExecutions = response.body;
        this.showActivityExecution = true;
      })
    }
    else {
      this.showActivityExecution = false;
    }
  }

  clearOrganizationSurvey() {
    this.beneficiaryResponseService.getBeneficiaryList(this.surveyId).subscribe(response => {
      if (response?.body) this.beneficiaries = response.body;
    });
    this.responseHeaderFormGroup?.controls?.organizationSurvey?.setValue(null);
    this.responseHeaderFormGroup?.controls?.activityExecution?.setValue(null);
  }

  save(responseId: number): Observable<boolean> {
    //let result: boolean = false;
    this.responseId = responseId;
    if (this.responseId != undefined && this.responseId > 0 && this.isValidForm() &&
      (this.beneficiaryResponse == null || this.beneficiaryResponse.id == undefined || this.beneficiaryResponse.id == 0)) {
      let body: BeneficiaryResponse = this.responseHeaderFormGroup.getRawValue();
      body.surveyResponse = new IdObject(this.responseId);
      this.formatBodyId(body);

      return this.beneficiaryResponseService.saveBeneficiaryResponse(body).pipe(
        map((response) => {
          if (response != undefined && response.body != undefined) {
            this.beneficiaryResponse = response.body;
          }
          return true;
        })
      );

    }
    return of(true);
  }

  formatBodyId(body: BeneficiaryResponse) {
    if (this.responseId && this.responseId > 0) body.surveyResponse = new IdObject(this.responseId);
    if (body?.organizationSurvey?.id && body?.organizationSurvey?.id > 0) body.organizationSurvey = new IdObject(body?.organizationSurvey?.id);
    if (body?.beneficiary?.id && body?.beneficiary?.id > 0) body.beneficiary = new IdObject(body?.beneficiary?.id);
    if (body?.activityExecution?.id && body?.activityExecution?.id > 0) body.activityExecution = new IdObject(body?.activityExecution?.id);
  }

  setBeneficiaryId(event: any) {
    if (event?.id) this.marchSurveyResponseService.handleSelectBeneficiaryId(event.id);
  }
}
