<div class="fullwidth locateForm">
    <!-- <app-table-filter [filters]="filters" (filterOutput)="applyFilter($event)"></app-table-filter> -->
    <app-responsive-list-view
         [tableColumns]="_tableColumns"
         [sortingDataAccessor]="_sortingDataAccessor"
         [filteringPredicate]="_filteringPredicate"
         [service]="service"
         [withAdd]="canAdd"
         [hasListAccess]="hasListAccess"
         [maxCellStringLength]="80"
         [maxStringLength]="100"
         [inputFilterEventSubject]="filterEventSubject.asObservable()"
         (mode)="updateViewMode($event)"
         [_tableMenuAction]="_tableMenuAction"
         [booleanIconColumnDisplayList]="_booleanIconColumnDisplayList"
         (menuActionEventEmitter)="tableMenuActionHandler($event)"
         addButtonLabel="{{ 'beneficiary-status.fields.new-beneficiary-status' | translate}}"
         tableTitleLabel="{{ 'beneficiary-status.fields.beneficiary-status-list' | translate}}"
         [inputReloadEventSubject]="reloadEventSubject.asObservable()"
         [inputInlineEditEventSubject]="inlineEditEventSubject.asObservable()"
         [showFilterButton]="true"
         [inlineEditable]="true"
         [showBackButton]="false"
         [initiateSearchFromFilter]="initiateSearchFromFilter">
      <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter>
      <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter>
    </app-responsive-list-view>
  </div>
  