import { LookupModel } from "./lookup.model";

export class Tag{
  id: number;
  key: string;
  value: string;
}

export class TagLookupModel {
  id: number;
  tag: LookupModel;
  selected: boolean;
  constructor(id: number, tag: LookupModel) {
    this.id = id;
    this.tag = tag;
  }
}
