import { Component, Input, OnInit, OnDestroy, Inject, EventEmitter, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IdValueObject, IdValueOrderObject, LookupModel } from '../../../../models/shared/lookup.model';
import { DialogService } from '../../../../services/shared/dialog.service';
import { SettingsService } from '../../../../shared/services/settings.service';
import { SurveyFormQuestionHandler } from '../../../../services/survey-form/survey-form-question-handler.service';
import { FormControllerGeneratorService } from '../../../../component/form-controller-generator.service';
import { LookupService } from '../../../../services/lookups/lookup.service';
import { QuestionStatement } from '../../../../models/survey/survey';

@Component({
  selector: 'app-question-statement',
  templateUrl: './question-statement.component.html',
  styleUrls: ['./question-statement.component.sass']
})
export class QuestionStatementComponent implements OnInit, OnDestroy {
  @Input() statement: FormGroup;
  @Input() customStatement: FormGroup;
  @Input() statementIndex: any;
  @Input() customStatementIndex: any;
  @Input() statements: FormArray;
  @Input() customStatements: FormArray ;
  @Input() isEditMode: boolean = true;
  @Input() parentQuestionControl: FormGroup;
  @Input() surveyFormQuestionHandler: SurveyFormQuestionHandler;
  @Input() isStandardStatement = true;

  @Output()  questionStatementChanged = new EventEmitter<QuestionStatement>();

  questionAnswerMap: Map<IdValueOrderObject, IdValueObject[]> = new Map<IdValueOrderObject, IdValueObject[]>();

  private questionChangeSubscription: Subscription;
  private reloadLookupSubscription: Subscription;

  answerTypeValue : any;

  questions: IdValueObject[];
  answers: IdValueObject[];
  selectedQuestionId: number;

  filterTypes: IdValueObject[] = [];
  filterTypeSelected: boolean = false;
  filterNameSelected: boolean = false;

  currentFormGroup: FormGroup;
  currentStatements: FormArray;

  genders: LookupModel[];
  regions: LookupModel[];
  achivements: IdValueObject[] = [];
  filterNames: IdValueObject[] = [];
  filterIds: IdValueObject[] = [];
  constructor(private dialogService: DialogService, public readonly _translateService: TranslateService,public lookupService: LookupService, private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    if(this.isStandardStatement){
      this.currentFormGroup = this.statement;
      this.currentStatements = this.statements;
    } else{
      this.currentFormGroup = this.customStatement;
      this.currentStatements = this.customStatements;
    }

    this.fillFilterTypes();
    this.fillFilterNames();
    this.genders = this.surveyFormQuestionHandler.getLookupByName("Gender");
    this.regions = this.surveyFormQuestionHandler.getLookupByName("Region");
    this.achivements = this.surveyFormQuestionHandler.getLookupByName("Achievement");

    if(this.surveyFormQuestionHandler != undefined){
      if(this.surveyFormQuestionHandler.questionAnswerMap != undefined) this.questionAnswerMap = this.surveyFormQuestionHandler.questionAnswerMap;
      this.questionChangeSubscription = this.surveyFormQuestionHandler.questionChangeEventSubject.subscribe(newMap => {
        this.questionAnswerMap = newMap;
        this.recalculateDropdownValues();
      });

      if(this.surveyFormQuestionHandler.reloadLookupSubject != undefined){
        this.reloadLookupSubscription = this.surveyFormQuestionHandler.reloadLookupSubject.subscribe(lookupname =>{
          if(lookupname != undefined){
            switch (lookupname) {
              case "Gender":
                this.genders = this.surveyFormQuestionHandler.getLookupByName("Gender");
                break;
              case "Region":
                this.regions = this.surveyFormQuestionHandler.getLookupByName("Region");
                break;
              case "Achievement":
                this.achivements = this.surveyFormQuestionHandler.getLookupByName("Achievement");
                break;
              default:
                break;
            }
            if(this.currentFormGroup?.get('filterName')?.getRawValue()){
              let filterNameIdValue = {
                value: this.currentFormGroup.get('filterName').getRawValue()
              }
              this.changeFilterName(filterNameIdValue);
            }
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    if(this.questionChangeSubscription) this.questionChangeSubscription.unsubscribe();
  }

  getQuestionSelectionList(force: boolean): IdValueObject[]{
    if(!this.questions || force) this.questions = this.constructQuestionListForSelector();
    return this.questions;
  }

  constructQuestionListForSelector(): IdValueObject[]{
    let newQuestionList: IdValueObject[] = [];
    if(this.questionAnswerMap) {
      let currQuestionValueId = this.getParentQuestionId();
      for (let element of this.questionAnswerMap.keys()){
        if(currQuestionValueId == undefined || currQuestionValueId != element.id) newQuestionList.push(element);
      }
    }
    return newQuestionList;
  }

  getAnswerListForQuestion(force: boolean): IdValueObject[]{
    let currSelectedQuestionValueId = this.getCurrentSelectedQuestionId();
    if(this.selectedQuestionId == undefined || this.selectedQuestionId != currSelectedQuestionValueId || force){
      this.selectedQuestionId = currSelectedQuestionValueId;
      this.answers = this.constructAnswerListForSelector(this.selectedQuestionId);
    }
    return this.answers;
  }

  constructAnswerListForSelector(currSelectedQuestionValueId: number): IdValueObject[]{
    let newAnswerList: IdValueObject[] = [];
    if(this.questionAnswerMap && currSelectedQuestionValueId) {
      this.questionAnswerMap.forEach((value: IdValueObject[], key: IdValueObject) => {
        if(newAnswerList.length == 0 && key.id == currSelectedQuestionValueId) newAnswerList = value;
      });
    }
    return newAnswerList;
  }

  getParentQuestionId(): number{
    let parentQuestionId: number;
    if(this.parentQuestionControl && this.parentQuestionControl?.get('id')) parentQuestionId = this.parentQuestionControl?.get('id').getRawValue();
    return parentQuestionId;
  }

  getCurrentSelectedQuestionId(): number{
    let currentQuestionId: number;
    if(this.statement && this.statement?.get('surveyQuestion')){
      let question = this.statement ? this.statement?.get('surveyQuestion')?.getRawValue() : undefined;
      currentQuestionId = question ? question.id : undefined;
    }
    return currentQuestionId;
  }

  getCurrentSelectedAnswerId(): number{
    let currentAnswerId: number;
    if(this.statement && this.statement?.get('questionAnswer')){
      let answer = this.statement ? this.statement?.get('questionAnswer')?.getRawValue() : undefined;
      currentAnswerId = answer ? answer.id : undefined;
    }
    return currentAnswerId;
  }

  recalculateDropdownValues(){
    let currentSelectedQuestion = this.getCurrentSelectedQuestionId();
    let currentSelectedAnswer = this.getCurrentSelectedAnswerId();

    this.getQuestionSelectionList(true);
    this.getAnswerListForQuestion(true);
    if(!this.listContainsId(this.getQuestionSelectionList(false), currentSelectedQuestion)){
      this.clearQuestionFormGroup();
      this.clearAnswerFormGroup();
    }

    if(!this.listContainsId(this.getAnswerListForQuestion(false), currentSelectedAnswer)){
      this.clearAnswerFormGroup();
    }
  }

  clearQuestionFormGroup(){
    this.statement?.get('surveyQuestion')?.reset();
  }

  clearAnswerFormGroup(){
    this.statement?.get('questionAnswer')?.reset();
  }

  listContainsId(list: IdValueObject[], id : number) : boolean{
    let found : boolean = false;
    if(list != undefined && id != undefined){
    for(let object of list){
        if(!found) found = object.id == id;
        if(found) break;
      }
    }
    return found;
  }

  canDeleteStatement(){
    return this.statements && this.statementIndex != undefined && this.isEditMode;
  }

  deleteStatement(){
    if(this.canDeleteStatement()){
      this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-statement'),
        "message": this._translateService.instant('siren-survey-translation.popup-module.messages.statement-delete'),
        "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
        "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
      .subscribe(response=>{
        if(response) this.statements.removeAt(this.statementIndex);
      });
    }
  }

  canDeleteCustomStatement(){
    return this.customStatements && this.customStatementIndex != undefined && this.isEditMode;
  }

  deleteCustomStatement(){
    if(this.canDeleteCustomStatement()){
      this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-custom-statement'),
        "message": this._translateService.instant('siren-survey-translation.popup-module.messages.statement-delete'),
        "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
        "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
      .subscribe(response=>{
        if(response) this.customStatements.removeAt(this.customStatementIndex);
      });
    }
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }


  fillFilterTypes(){
    this.filterTypes.push(SurveyFormQuestionHandler.CUSTOM_FILTER_TYPE);
    this.filterTypes.push(SurveyFormQuestionHandler.STANDARD_FILTER_TYPE);
    this.currentFormGroup.get('filterType').setValue(this.isStandardStatement ? SurveyFormQuestionHandler.STANDARD_FILTER_TYPE.id : SurveyFormQuestionHandler.CUSTOM_FILTER_TYPE.id);
    this.filterTypeSelected = true;
  }

  fillFilterNames(){
    this.filterNames.push(SurveyFormQuestionHandler.REGION_FILTER_NAME);
    this.filterNames.push(SurveyFormQuestionHandler.GENDER_FILTER_NAME);
    this.filterNames.push(SurveyFormQuestionHandler.ACHIEVEMENT_FILTER_NAME);
  }

  changeFilterType(event: any){
    this.filterTypeSelected = true;
    if(event.value){
      if(event.value == SurveyFormQuestionHandler.STANDARD_FILTER_TYPE.id){
        let statementsFormGroup = FormControllerGeneratorService.createStatementFormGroup(this.isEditMode);
        if(this.statements == undefined){
          this.statements = new FormArray([ ]);
        }
        this.statements.push(statementsFormGroup);
        this.customStatements?.removeAt(this.customStatementIndex);
      }
      else{
        let customStatementFormGroup = FormControllerGeneratorService.createCustomStatementFormGroup(this.isEditMode);
        if(this.customStatements == undefined){
          this.customStatements = new FormArray([ ]);
        }
        this.customStatements.push(customStatementFormGroup);
        this.statements?.removeAt(this.statementIndex);
      }
    }
  }

  changeFilterName(event: any){ //filtername value is being used based on BE
    if(event.value){
      this.filterIds = [];
        this.filterIds = this.surveyFormQuestionHandler.getIdValueObjectLookupMap(event.value);
        this.filterNameSelected = true;
    }
  }

  checkOrder(event: any){
    let questions = Array.from(this.questionAnswerMap.entries()).sort((a, b) => a[0].order - b[0].order);

    let selectedQuestion = questions.map((entry) => entry[0])
    .find((idValueOrderObject) => idValueOrderObject.id === event.value);

    let parentQuestion = this.parentQuestionControl.getRawValue();
    if(parentQuestion.order < selectedQuestion.order){
      this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.reorder-question'),
        "message": this._translateService.instant('siren-survey-translation.popup-module.messages.reorder-question'),
        "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
        "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
      .subscribe(response=>{
        if(response) {
          //apply ordering of the questions
          let questionStatement: QuestionStatement = {
            parentQuestionControl : this.parentQuestionControl,
            selectedQuestion : selectedQuestion
          }
          this.questionStatementChanged.emit(questionStatement);
        }
        else{
          this.statement?.get('surveyQuestion')?.reset();
        }
      });
    }
  }

}
