import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBankService } from '../../../services/question-bank.service';
import { DialogService } from '../../../services/shared/dialog.service';
import { OrderHandler } from '../../../utils/order-handler.utilities';
import { FormControllerGeneratorService } from '../../form-controller-generator.service';
import { QuestionBankSelectListComponent } from '../../question-bank/question-bank-select-list/question-bank-select-list.component';
import { SettingsService } from '../../../shared/services/settings.service';
import { SurveyFormQuestionHandler } from '../../../services/survey-form/survey-form-question-handler.service';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';

@Component({
  selector: 'app-survey-section',
  templateUrl: './survey-section.component.html',
  styleUrls: ['./survey-section.component.sass']
})
export class SurveySectionComponent implements OnInit {
  @Input() surveySectionForm: FormGroup;
  @Input() isEditMode: boolean = true;
  @Input() formRefSection: any;
  @Input() gradedSurvey: boolean;
  @Input() elementIndex: number;
  @Input() upperBoundIndex: number;
  @Input() surveyId: number;
  @Input() hasResponse: boolean = false;
  @Input() answerTypes: LookupModel[];
  @Input() surveyFormQuestionHandler: SurveyFormQuestionHandler;

  surveyFormGroup: FormGroup;
  contentExpanded: boolean = true;

  sectionUuid: string;

  constructor(private parentFormGroup: FormGroupDirective, private dialogService: DialogService,private questionBankService: QuestionBankService,
    private matDialog: MatDialog, public readonly _translateService: TranslateService, private settingsService: SettingsService) {
    this.surveyFormGroup = parentFormGroup.control;
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  ngOnInit(): void {
    this.sectionUuid = this.surveySectionForm?.get('uuid')?.getRawValue();
  }

  ngAfterViewInit(): void {
  }

  toogleContentExpansion(){
    if(this.contentExpanded){
      this.collapseContent()
    } else{
      this.expandContent()
    }
  }

  expandContent(){
    this.contentExpanded = true;
    let elementid : string = 'section-content-' + this.elementIndex;
    document.getElementById(elementid).style.height = "100%";
    document.getElementById(elementid).style.display = "block";
  }

  collapseContent(){
    this.contentExpanded = false;
    let elementid : string = 'section-content-' + this.elementIndex;
    document.getElementById(elementid).style.height = "0px";
    document.getElementById(elementid).style.display = "none";
  }

  canDeleteSection(): boolean{
    return this.editable();
  }

  deleteQuestion(sectionIndex: number, questionIndex: number){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-question'),
      "message": this._translateService.instant('siren-survey-translation.popup-module.messages.survey-question-delete'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response){
          let sectionsArray = this.surveyFormGroup?.controls['sections'] as FormArray;
          let questionGroup = sectionsArray?.at(sectionIndex) as FormGroup;
          let questionArray = questionGroup?.controls['questions'] as FormArray;
          let questionId = questionArray?.at(questionIndex)?.get('id')?.getRawValue();
          let inEditMode = questionArray?.at(questionIndex).enabled;
          questionArray?.removeAt(questionIndex);
          this.surveyFormQuestionHandler.handleEditQuestionNotification({
            questionId: questionId,
            sectionUuid: this.sectionUuid,
            decreaseEditCount: inEditMode,
            encreaseEditCount: false,
            addToUnsavedQuestionList:false ,
            removeFromUnsavedQuestionList: true
          });
      }
    });
  }

  canDeleteQuestion(): boolean{
    return this.editable();
  }

  deleteSection(index: number){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-section'),
      "message": this._translateService.instant('siren-survey-translation.popup-module.messages.section-delete'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response){
        this.surveyFormQuestionHandler.deleteSection(this.sectionUuid);

        let sectionsArray = this.surveyFormGroup?.controls['sections'] as FormArray;
        let questionGroup = sectionsArray?.at(index) as FormGroup;
        let questionArray = questionGroup?.controls['questions'] as FormArray;
        for(let control of questionArray?.controls){
          let currentQuestionId = control?.get('question')?.get('id')?.getRawValue();
          if(currentQuestionId != undefined && currentQuestionId > 0) this.surveyFormQuestionHandler.removeQuestionIdFromNewlyAddedSurveyQuestions(currentQuestionId);
        }
        sectionsArray.removeAt(index);
      }
    });
  }

  moveUp(index: number) {
    OrderHandler.moveUp(index, this.surveyFormGroup.get('sections') as FormArray);
  }

  moveDown(index: number) {
    OrderHandler.moveDown(index, this.surveyFormGroup.get('sections') as FormArray);
  }

  getQuestionBankFormControlElement(parentCntrol: FormGroup) : FormGroup{
    return parentCntrol?.get('question') as FormGroup;
  }

  getQuestionsFormSectionArray(): FormArray{
    let questions = this.surveySectionForm.controls['questions'] as FormArray;
    return questions;
  }

  addSectionQuestion(): FormGroup{
    let newSectionQuestionForm = FormControllerGeneratorService.createQuestionFormGroup(this.editable(), this.gradedSurvey);
    let questions = this.getQuestionsFormSectionArray();
    questions.push(newSectionQuestionForm);
    this.surveyFormQuestionHandler.handleEditQuestionNotification({
      questionId: undefined,
      sectionUuid: this.sectionUuid,
      decreaseEditCount: false,
      encreaseEditCount: true,
      addToUnsavedQuestionList:false ,
      removeFromUnsavedQuestionList: false
    });
    return newSectionQuestionForm;
  }

  moveUpQuestion(index: number, questionIndex: number) {
    OrderHandler.moveUp(questionIndex, ((this.surveyFormGroup.get('sections') as FormArray).at(index) as FormGroup).get('questions') as FormArray);
  }

  moveDownQuestion(index: number, questionIndex: number) {
    OrderHandler.moveDown(questionIndex, ((this.surveyFormGroup.get('sections') as FormArray).at(index) as FormGroup).get('questions') as FormArray);
  }

  editable() : boolean{
    return this.isEditMode;
  }

  canEditQuestion(): boolean{
    return this.editable();
  }


  selectQuestion(){
    let additionalParams : Map<string, any> = new Map<string, any>();
    additionalParams.set('excludeSurveyId', this.surveyId);
    additionalParams.set('excludeQuestionId', this.surveyFormQuestionHandler.newlyAddedSurveyQuestions.join(','));
    const questionBankSelectListFormDialog = this.matDialog.open(QuestionBankSelectListComponent, {
      "width": '6000px',
      "maxWidth": '80vw',
      "maxHeight": '80vh',
      "autoFocus": false,
      data: additionalParams,
      direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });

    const subscribeDialog = questionBankSelectListFormDialog.componentInstance.selectionEventEmitter.subscribe((elementId) => {
      if(this.surveySectionForm){
        this.questionBankService.getObject(elementId).subscribe(response =>{
          let questionBankObject = response.body;
          let newQuestionFormGroup = FormControllerGeneratorService.createQuestionFormGroup(this.editable(), this.gradedSurvey);
          newQuestionFormGroup.get('question').patchValue(questionBankObject)
          newQuestionFormGroup.get('question').patchValue({ id: elementId });
          if(questionBankObject?.answers){
            for(let answer of questionBankObject?.answers){
              let answerFormGroup = FormControllerGeneratorService.createAnswerFormGroup(this.isEditMode);
              answerFormGroup.patchValue(answer);
              let answersFormArray = newQuestionFormGroup.get('question').get("answers") as FormArray;
              answersFormArray.push(answerFormGroup);
            }
          }
          let questionsFormArray = this.getQuestionsFormSectionArray();
          questionsFormArray.push(newQuestionFormGroup);

          this.surveyFormQuestionHandler.handleEditQuestionNotification({
            questionId: elementId,
            sectionUuid: this.sectionUuid,
            decreaseEditCount: false,
            encreaseEditCount: false,
            addToUnsavedQuestionList:true ,
            removeFromUnsavedQuestionList: false
          });
       });
      }
      questionBankSelectListFormDialog.close();
    });
  }

  onKeyDownEvent(event: any){
    if(event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }
}
