import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuestionBankListComponent } from './component/question-bank/question-bank-list/question-bank-list.component';
import { QuestionBankFormComponent } from './component/question-bank/question-bank-form/question-bank-form.component';
import { SurveyFormComponent } from './component/survey/survey-form/survey-form.component';
import { SurveyTableComponent } from './component/survey/survey-table/survey-table.component';
import { SurveyResponseFormComponent } from './component/survey-response/survey-response-form/survey-response-form.component';
import { ResponseTableComponent } from './component/survey-response/response-table/response-table.component';
import { TranslateResolver } from './shared/services/translation/translate-resolver.service';
import { CanActivatePageGuard } from './shared/guards/can-activate-page.guard';
import { AnswerTypesResolver } from './services/lookups/answer-types.resolver';
import { SurveyLookupsResolver } from './services/lookups/survey-lookups.resolver';

const routes: Routes = [
  {
    path: 'question', canActivate: [CanActivatePageGuard], data: { moduleName: 'MODULE_QUESTION' },
    resolve: { translations: TranslateResolver },
    children: [
      { path: '', component: QuestionBankListComponent, resolve: { answerTypes : AnswerTypesResolver}, pathMatch: 'full' },
      { path: ':id', component: QuestionBankFormComponent, pathMatch: 'full' },
      { path: 'create-question', component: QuestionBankFormComponent, pathMatch: 'full' }
    ],
  },
  {
    path: 'response', canActivate: [CanActivatePageGuard], data: { moduleName: 'MODULE_RESPONSE' },
    resolve: { translations: TranslateResolver },
    children: [
      { path: ':surveyId/:surveyName', component: ResponseTableComponent },
      { path: ':surveyId/:surveyName/:responseId', component: SurveyResponseFormComponent },
      { path: ':surveyId/:surveyName/create-response', component: SurveyResponseFormComponent, },
    ],
  },
  {
    path: '', canActivate: [CanActivatePageGuard], data: { moduleName: 'MODULE_SURVEY' },
    children: [
      { path: 'survey-reports/:surveyId/:surveyName/:surveyUuid', pathMatch: 'full', loadChildren: () => import('./component/survey/survey-reports/survey-report.module').then(m => m.SurveyReportModule) },
      { path: 'list', component: SurveyTableComponent, pathMatch: 'full' },
      { path: 'list/:id', component: SurveyFormComponent,  resolve: { surveyLookups : SurveyLookupsResolver}, pathMatch: 'full' },
      { path: 'list/create', component: SurveyFormComponent,  resolve: { surveyLookups : SurveyLookupsResolver}, pathMatch: 'full' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveyAppRoutingModule { }
