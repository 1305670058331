import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuService } from './menu.service';
import { Menu, MenuItem } from './menu.model';
import { Location } from '@angular/common';
import { SessionService } from 'src/app/services/shared/session.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.sass']
})
export class SideMenuComponent {

  @Input('activeLanguage') activeLanguage: string;
  @Output() languageChanged: EventEmitter<string> = new EventEmitter();
  @Output() logoutClicked: EventEmitter<void> = new EventEmitter();
  @Output() itemClicked: EventEmitter<MenuItem> = new EventEmitter();

  constructor(protected readonly menuService: MenuService, private _location: Location) {
  }

  public changeLanguage(lang: string) {
    this.languageChanged.emit(lang);
    window.location.reload();
  }

  public logout() : void{
    this.logoutClicked.emit();
  }

  public isActiveRoutePath(routeToCheck: string, equalOnly: boolean): boolean {
    let isActive: boolean = false;

    if (routeToCheck != undefined) {
      let equal = this._location.isCurrentPathEqualTo(routeToCheck);
      let contains = !equalOnly ? this._location.path().includes(routeToCheck) : false;
      isActive = equal || contains;
    }
    return isActive;
  }


  isActiveRoute(routeToCheck: string): boolean {
    return this.isActiveRoutePath(routeToCheck, false);

  }

  public toogleMenuExpansion(menu: Menu) : void {
    menu.toggled = !menu.toggled;
  }

  public onItemClick(item: MenuItem){
     this.itemClicked.emit(item);

  }

  public collapseAllItems() : void {
    this.menuService.menuItems.forEach(mainMenuItem => {
      mainMenuItem.toggled = false;
    });
  }
}
