<div class="surveyFilterContainer" *ngIf="filters && filters.length > 0">
  <div class="surveyFilterButtonContainer" *ngIf="showFilterButton">
    <button type="button" #filterbutton data-bs-toggle="collapse" data-bs-target="#filterElementContainer" title="Filter"
      aria-controls="filterElementContainer" aria-expanded="false" aria-label="Toggle navigation" class="button-flat">
      <img [src]="getAssetsUrl('assets/icons/filter-yellow-icon.svg')"  class="iconWidth"> <span class="table-filter-label">{{"siren-survey-translation.global.filter" | translate}}</span>
    </button>
  </div>
  <div class="row collapse filterElementContainer" id="filterElementContainer">
      <div class="col-12 flexDisplay" >
        <h4 class="text-muted">
          {{ ( "siren-survey-translation.global.table-filter.title" | translate) != undefined ? ( "siren-survey-translation.global.table-filter.title" | translate) : "Add values to the filter that you want to activate:" }}
        </h4>
        <app-info-tooltip class="marginBottom" message="{{'siren-survey-translation.global.table-filter.tooltip' | translate}}"></app-info-tooltip>
      </div>
      <form class="col-12" [formGroup]="filterForm" (ngSubmit)="applyFilters()">
        <div class="row container" style="min-width: 100%;" formArrayName="filterComponents" >
          <ng-container  *ngFor="let filterFormControl of filterComponents.controls; let i = index;" [formGroupName]="i">
            <!-- {{filterFormControl?.get('visible')?.getRawValue()}} col-xl-2  -->
            <!-- <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 marginBottom" *ngIf="filterFormControl?.get('visible')?.getRawValue() == true"> col-xl-2 -->
              <div class="marginBottom" *ngIf="filterFormControl?.get('visible')?.getRawValue() == true && filterFormControl.get('type').getRawValue() == tableFilterTypeEnum.Boolean"
                [ngClass]="filterFormControl.get('cssClass').getRawValue() != undefined ? filterFormControl.get('cssClass').getRawValue() : 'col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' ">
                <mat-form-field class="full-width-element" class="full-width-element">
                  <mat-label class="" >
                    {{ filterFormControl?.get("translationPath")
                    && (filterFormControl?.get("translationPath")?.value | translate) != undefined
                    && (filterFormControl?.get("translationPath")?.value | translate) != filterFormControl?.get("translationPath")?.value
                    ? (filterFormControl?.get("translationPath")?.value | translate) : filterFormControl?.get("displayName")?.value }}
                  </mat-label>
                  <mat-select class="" formControlName="value">
                    <!-- <mat-option [value]=""></mat-option> -->
                    <mat-option [value]="false">False</mat-option>
                    <mat-option [value]="true">True</mat-option>
                  </mat-select>
                  <button matSuffix mat-icon-button *ngIf="filterFormControl?.controls?.value?.value != undefined" type="button" aria-label="Clear"
                      (click)="filterFormControl?.controls?.value?.setValue(null); $event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="marginBottom" *ngIf="filterFormControl?.get('visible')?.getRawValue() == true && filterFormControl.get('type').getRawValue() == tableFilterTypeEnum.Number"
                [ngClass]="filterFormControl.get('cssClass').getRawValue() != undefined ? filterFormControl.get('cssClass').getRawValue() : 'col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' ">
                <mat-form-field class="full-width-element">
                  <mat-label class="" >
                    {{ filterFormControl?.get("translationPath")
                    && (filterFormControl?.get("translationPath")?.value | translate) != undefined
                    && (filterFormControl?.get("translationPath")?.value | translate) != filterFormControl?.get("translationPath")?.value
                    ? (filterFormControl?.get("translationPath")?.value | translate) : filterFormControl?.get("displayName")?.value }}
                  </mat-label>
                  <input matInput  type="number" class="" (keydown)="onKeyDownEvent($event)" autocomplete="off" formControlName="value">
                </mat-form-field>
              </div>
              <div class="marginBottom" *ngIf="filterFormControl?.get('visible')?.getRawValue() == true && filterFormControl.get('type').getRawValue() == tableFilterTypeEnum.String"
                [ngClass]="filterFormControl.get('cssClass').getRawValue() != undefined ? filterFormControl.get('cssClass').getRawValue() : 'col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' ">
                <mat-form-field class="full-width-element">
                  <mat-label class="" >
                    {{ filterFormControl?.get("translationPath")
                    && (filterFormControl?.get("translationPath")?.value | translate) != undefined
                    && (filterFormControl?.get("translationPath")?.value | translate) != filterFormControl?.get("translationPath")?.value
                    ? (filterFormControl?.get("translationPath")?.value | translate) : filterFormControl?.get("displayName")?.value }}
                  </mat-label>
                  <input matInput class="" autocomplete="off" formControlName="value">
                </mat-form-field>
              </div>
              <div class="marginBottom" *ngIf="filterFormControl?.get('visible')?.getRawValue() == true && filterFormControl.get('type').getRawValue() == tableFilterTypeEnum.Date"
                [ngClass]="filterFormControl.get('cssClass').getRawValue() != undefined ? filterFormControl.get('cssClass').getRawValue() : 'col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' ">
                <survey-datepicker
                  [title]="filterFormControl?.get('translationPath')
                    && (filterFormControl?.get('translationPath')?.value | translate) != undefined
                    && (filterFormControl?.get('translationPath')?.value | translate) != filterFormControl?.get('translationPath')?.value
                    ? (filterFormControl?.get('translationPath')?.value | translate)
                    : filterFormControl?.get('displayName')?.value"
                  [dateFormControl]="this.currFormGroup?.get('value')"
                ></survey-datepicker>
              </div>
              <div class="marginBottom" *ngIf="filterFormControl?.get('visible')?.getRawValue() == true && filterFormControl.get('type').getRawValue() == tableFilterTypeEnum.DateRange"
                [ngClass]="filterFormControl.get('cssClass').getRawValue() != undefined ? filterFormControl.get('cssClass').getRawValue() : 'col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' ">
                <mat-form-field class="full-width-element">
                  <mat-label class="" >
                    {{ filterFormControl?.get("translationPath")
                    && (filterFormControl?.get("translationPath")?.value | translate) != undefined
                    && (filterFormControl?.get("translationPath")?.value | translate) != filterFormControl?.get("translationPath")?.value
                    ? (filterFormControl?.get("translationPath")?.value | translate) : filterFormControl?.get("displayName")?.value}}  {{ 'global.range' | translate }}
                  </mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="valueFrom">
                    <input matEndDate formControlName="valueTo">
                  </mat-date-range-input>
                  <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                  <mat-datepicker-toggle matSuffix  [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="marginBottom" *ngIf="filterFormControl?.get('visible')?.getRawValue() == true && filterFormControl.get('type').getRawValue() == tableFilterTypeEnum.Lookup"
                [ngClass]="filterFormControl.get('cssClass').getRawValue() != undefined ? filterFormControl.get('cssClass').getRawValue() : 'col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' ">
                <mat-form-field  required class="full-width-element">
                  <mat-label class="" >
                    {{ filterFormControl?.get("translationPath")
                    && (filterFormControl?.get("translationPath")?.value | translate) != undefined
                    && (filterFormControl?.get("translationPath")?.value | translate) != filterFormControl?.get("translationPath")?.value
                    ? (filterFormControl?.get("translationPath")?.value | translate) : filterFormControl?.get("displayName")?.value }}
                  </mat-label>
                  <mat-select *ngIf="filterFormControl?.get('lookupsValueById')?.value" [multiple]="filterFormControl?.get('isMultiSelect')?.getRawValue()" class="" formControlName="value">
                    <mat-option *ngFor="let lookupElement of getLookupByName(i)" [value]="lookupElement.id" >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                  <button matSuffix mat-icon-button *ngIf="filterFormControl?.get('lookupsValueById')?.value && filterFormControl?.controls?.value?.value != undefined" type="button" aria-label="Clear"
                      (click)="filterFormControl?.controls?.value?.setValue(null); $event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                  </button>

                  <mat-select *ngIf="!filterFormControl?.get('lookupsValueById')?.value" class="" formControlName="value">
                    <mat-option *ngFor="let lookupElement of getLookupByName(i)" [value]="lookupElement.key" >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                  <button matSuffix mat-icon-button *ngIf="!filterFormControl?.get('lookupsValueById')?.value && filterFormControl?.controls?.value?.value != undefined" type="button" aria-label="Clear"
                      (click)="filterFormControl?.controls?.value?.setValue(null); $event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="marginBottom" *ngIf="filterFormControl?.get('visible')?.getRawValue() == true && filterFormControl.get('type').getRawValue() == tableFilterTypeEnum.Static"
                [ngClass]="filterFormControl.get('cssClass').getRawValue() != undefined ? filterFormControl.get('cssClass').getRawValue() : 'col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' ">
                <mat-form-field  required class="full-width-element">
                  <mat-label class="" >
                    {{ filterFormControl?.get("translationPath")
                    && (filterFormControl?.get("translationPath")?.value | translate) != undefined
                    && (filterFormControl?.get("translationPath")?.value | translate) != filterFormControl?.get("translationPath")?.value
                    ? (filterFormControl?.get("translationPath")?.value | translate) : filterFormControl?.get("displayName")?.value }}
                  </mat-label>
                  <mat-select *ngIf="filterFormControl?.get('staticValues')?.value" class="" formControlName="value">
                    <mat-option *ngFor="let pair of getStaticValues(i) | keyvalue" [value]="pair.key">
                      {{ pair.value }}
                    </mat-option>
                  </mat-select>
                  <button matSuffix mat-icon-button *ngIf="filterFormControl?.get('staticValues')?.value && filterFormControl?.controls?.value?.value != undefined" type="button" aria-label="Clear"
                      (click)="filterFormControl?.controls?.value?.setValue(null); $event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="marginBottom" *ngIf="filterFormControl?.get('visible')?.getRawValue() == true && filterFormControl.get('type').getRawValue() == tableFilterTypeEnum.LookupObject"
                [ngClass]="filterFormControl.get('cssClass').getRawValue() != undefined ? filterFormControl.get('cssClass').getRawValue() : 'col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2' ">
                <mat-form-field  required class="full-width-element">
                  <mat-label class="" >
                    {{ filterFormControl?.get("translationPath")
                    && (filterFormControl?.get("translationPath")?.value | translate) != undefined
                    && (filterFormControl?.get("translationPath")?.value | translate) != filterFormControl?.get("translationPath")?.value
                    ? (filterFormControl?.get("translationPath")?.value | translate) : filterFormControl?.get("displayName")?.value }}
                  </mat-label>
                  <mat-select *ngIf="filterFormControl?.get('lookupsValueById')?.value" [multiple]="filterFormControl?.get('isMultiSelect')?.getRawValue()" class="" formControlName="value">
                    <mat-option *ngFor="let lookupElement of getLookupByName(i)" [value]="lookupElement.id" >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                  <button matSuffix mat-icon-button *ngIf="filterFormControl?.get('lookupsValueById')?.value && filterFormControl?.controls?.value?.value != undefined" type="button" aria-label="Clear"
                      (click)="filterFormControl?.controls?.value?.setValue(null); $event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                  </button>

                  <mat-select *ngIf="!filterFormControl?.get('lookupsValueById')?.value" class="" formControlName="value">
                    <mat-option *ngFor="let lookupElement of getLookupByName(i)" [value]="lookupElement.key" >
                      {{ lookupElement.value }}
                    </mat-option>
                  </mat-select>
                  <button matSuffix mat-icon-button *ngIf="!filterFormControl?.get('lookupsValueById')?.value && filterFormControl?.controls?.value?.value != undefined" type="button" aria-label="Clear"
                      (click)="filterFormControl?.controls?.value?.setValue(null); $event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            <!-- </div> -->
          </ng-container>
        </div>
        <div class="col-12 surveyFilterButtonContainer">
          <button type="submit" class="btn btn-primary survey-filter-button marginRight">
            {{ ( "siren-survey-translation.global.search" | translate) != undefined ? ( "siren-survey-translation.global.search" | translate) : "Search" }}
          </button>
          <button type="button" (click)="clearFilters()" class="btn btn-primary survey-filter-cancel-button marginRight">
            {{ ( "siren-survey-translation.global.clear" | translate) != undefined ? ( "siren-survey-translation.global.clear" | translate) : "Clear" }}
          </button>
        </div>
      </form>
  </div>
</div>
