<ng-container>
  <div class="fullwidth locateForm">
    <app-title [title]="title" [goBackCallback]="goBack"></app-title>
    <div>
      <div class="table grid-container" [dir]="translateService.currentLang == 'ar' ? 'rtl' : 'ltr'">
        <!-- Header -->
        <div class="row header">
          <div class="cell fixed-cell">{{'report.table.beneficiaries' | translate}}</div>
          <div *ngFor="let header of customReportTable?.headers" class="cell">{{ header?.value }}</div>
        </div>

        <!-- Table Body (Dynamically generated rows) -->
        <div class="row" *ngFor="let row of prepareData(); let i = index">
          <div class="cell fixed-cell">{{ row.beneficiaryName }}</div>
          <div class="cell" *ngFor="let header of customReportTable.headers">
            <span>{{ row[header.value] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
