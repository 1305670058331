import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { IAdminConfig } from '../config/admin-config.interface';
import { CachingService } from '../shared/services/caching.service';
import { SettingsHttpService } from '../shared/services/settings-http.service';
import { SnackbarService } from '../shared/services/snackbar.service';
import { ToolbarDrawerService } from '../shared/services/toolbar-drawer.service';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminServiceService } from './admin-service.service';
import { AdminComponent } from './admin.component';
import { CanActivatePageGuard } from '../shared/guards/can-activate-page.guard';

export function appInit(settingsHttpService: SettingsHttpService) {
    return (): Promise<any> => settingsHttpService.initializeApp();
}

@NgModule({
  declarations: [
    AdminComponent
  ],
  imports: [
    AdminRoutingModule,
    SharedModule,
  ],
  providers: [AdminServiceService,
    ToolbarDrawerService,
    CachingService,
    CanActivatePageGuard,
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [SettingsHttpService], multi: true },
    SnackbarService]
})

export class AdminModule {
  constructor(matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }

  public static forRoot(environment: IAdminConfig): ModuleWithProviders<AdminModule> {
    return {
      ngModule: AdminModule,
      providers: [
        { provide: 'environment', useValue: environment },
      ]
    };
  }
}
