import { Directive, OnInit, Input } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';


@Directive({ selector: '[menuPermissions]' })
export class MenuPermissionsDirective implements OnInit {
  @Input()
  set menuPermissions(permissions: string[]) {
    this.permissions = permissions;
  }

  @Input()
  partial = false;
  permissions: string[];

  constructor(
    private _authService: AuthenticationService,
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef
  ) {}
  

  ngOnInit() {
    let hasAccess = false;
    if (!this.permissions || !this.permissions.length) {
      hasAccess = true;
    }
    else {
      hasAccess = this.isMenuAccessibleList(this.permissions, true);
    }
    if (hasAccess) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }


  isMenuAccessibleList(permissions: string[], orOperator: boolean) {
    let hasRight = true;
    if (permissions != undefined) {
      hasRight = false;
      let foundRights = 0;
      for (let permission of permissions) {
        if (this._authService.isModuleAccessible(permission)) {
          foundRights++;
        }
      }
      hasRight = orOperator ? foundRights > 0 : foundRights >= permissions.length;
    }
    return hasRight;
  }
}
