import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BasePanelListComponent } from '@siren-survey/app/component/shared/panel-list/base-panel-list.component';
import { SurveySelectionModel } from '@siren-survey/app/models/survey/survey-selection.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { SurveyService } from '@siren-survey/app/services/survey.service';
import { SurveySelectionComponent } from '../survey-selection/survey-selection.component';
import { OrganizationSurvey } from 'src/app/models/survey/survey-topic.models';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { SurveyTopicService } from 'src/app/services/shared/survey-topic.service';
import { Program } from 'src/app/models/admin/program/program/program';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable, Subscription } from 'rxjs';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-survey-link-selection',
  templateUrl: './survey-link-selection.component.html',
  styleUrls: ['./survey-link-selection.component.sass']
})
export class SurveyLinkSelectionComponent extends BasePanelListComponent implements OnInit, OnDestroy {

  @Input() programActivityId: number; // allowing to get the list of surveys linked to this activity
  @Input() programClassId: number; // allowing to get the list of surveys linked to this class
  @Input() program: Program;
  @Input() organizationId: number;
  @Input() hasAccessToOrg: boolean = false;
  @Input() toogleMainPanelFromOutside: boolean = false;
  @Input() useDefaultStyling: boolean = false;
  @Input() isEditable: boolean = true;
  @Input() linkTo: string = "";
  @Input() accordionToogleEventSubject: Observable<boolean>;
  @Input() customClass: string | undefined;

  private accordionToogleEventsSubscription: Subscription;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  hasFetchedData: boolean = false;
  openedPanel: boolean = false;
  canLinkSurvey: boolean = false;

  surveyTopicList: SurveySelectionModel[] = [];
  predefinedFilter: Map<string, any> = new Map<string, any>();
  userActiveOrganizations: LookupModel[] = [];

  loading = false;

  public filterForm: FormGroup;

  @ViewChild('mainpanel') mainPanel: MatExpansionPanel;
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;


  constructor(
    private surveyService: SurveyService,
    private _snackbar: MatSnackBar,
    protected datePipe: DatePipe,
    protected http: HttpClient,
    public surveyTopic: SurveyTopicService,
    private dialogService: DialogService,
    public authService: AuthenticationService,
    public readonly _translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public lookupService: LookupService,
    private matDialog: MatDialog) {
    super(datePipe);
    this.userActiveOrganizations = this.authService.getSessionService().getActiveSession().getActiveOrganizations();
    console.log("editable ? " + this.isEditable);
  }

  openAll() {
    this.expansionPanels.forEach(panel => panel.open());
  }

  closeAll() {
    this.expansionPanels.forEach(panel => panel.close());
  }
  ngOnInit(): void {
    this.canLinkSurvey = this.isEditable && this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_SURVEY);

    super.ngOnInit();
    if (this.accordionToogleEventSubject) {
      this.accordionToogleEventsSubscription = this.accordionToogleEventSubject.subscribe(value => {
        if (this.mainPanel != undefined) this.mainPanel.toggle();
      })
    }
  }

  ngOnDestroy(): void {
    if (this.accordionToogleEventsSubscription) this.accordionToogleEventsSubscription.unsubscribe();
  }

  isLinkedToProgram(): boolean {
    return this.program != undefined && this.program.id != undefined && this.program.id > 0;
  }

  isLinkedToProgramActivity(): boolean {
    return this.programActivityId != undefined && this.programActivityId > 0;
  }

  isLinkedToProgramClass(): boolean {
    return this.programClassId != undefined && this.programClassId > 0;
  }


  // base object abstract methods implementation
  constructFilterFormGroup() {
  }

  getQueryParamsWithFilter(): string {
    let params = '/program-filter?page=' + this.currentPage + '&size=' + this.pageSize + (this.organizationId ? "&organizationId=" + this.organizationId : "");

    if (this.isLinkedToProgramActivity()) {
      params += "&programActivityId=" + this.programActivityId;
    } else if (this.isLinkedToProgramClass()) {
      params += "&programClassId=" + this.programClassId;
    } else if (this.isLinkedToProgram()) {
      params += "&programId=" + this.program.id;
    }
    if (this.filterForm) {
      const formValue = this.filterForm?.value;
      const paramObject = Object.entries(formValue)
        .map(([key, value]) => {
          if (key != undefined && value != undefined) {
            if (key === 'valueFrom') {
              return this.getFilterParamForDate('submitDateFrom', value);
            } else if (key === 'valueTo') {
              return this.getFilterParamForDate('submitDateTo', value);
            } else if (key === 'maxGrade') {
              return this.getFilterParamForNumber('maxGrade', value);
            } else if (key === 'isBaseLine') {
              return this.getFilterParamForBoolean('isBaseLine', value);
            } else if (key === 'title') {
              return this.getFilterParamForString('isBasetitleLine', value);
            }
          }
          return '';
        }).filter(Boolean).join('&');
      if (paramObject != undefined && paramObject.trim() != '') params += '&' + paramObject.trim();
    }
    return params;
  }

  getPageData(reloadData?: boolean, surveyTopicInput?: SurveyTopicInput) {
    if (surveyTopicInput && surveyTopicInput.fromComponent == "Activity") {
      this.programActivityId = surveyTopicInput.id;
    }
    else if (surveyTopicInput && surveyTopicInput.fromComponent == "Class") {
      this.programClassId = surveyTopicInput.id;
    }
    if ((this.isLinkedToProgram() || this.isLinkedToProgramActivity() || this.isLinkedToProgramClass()) && this.openedPanel && (!this.hasFetchedData || reloadData)) {
      let params = this.getQueryParamsWithFilter();
      this.surveyService.getLinkedSurveyForObject(params).subscribe(response => {
        if (response && response.body) {
          this.surveyTopicList = response.body;
          this.hasFetchedData = true;
        }
      });
    }
  }

  openSurveyPreviewForm(surveyId: number) {
    this.loading = true;
    this.router.navigateByUrl(`/survey/list/${surveyId}`);
  }

  editProgramTopic(surveyTopic: number) {
    if (surveyTopic != undefined && surveyTopic > 0) {
      let surveyProgramTopicInput: SurveyTopicInput = {
        fromComponent: "Program",
        id: surveyTopic,
        organizationId: undefined,
        linkTo: this.linkTo
      }
      const surveySelectionTableDialog = this.matDialog.open(SurveySelectionComponent, {
        "width": '6000px',
        "maxWidth": '80vw',
        "maxHeight": '80vh',
        "autoFocus": false,
        data: surveyProgramTopicInput,
        direction: this._translateService.currentLang == 'ar' ? 'rtl' : 'ltr'
      });

      const subscribeDialog = surveySelectionTableDialog.componentInstance.selectionEventEmitter.subscribe((element) => {
        if (element !== undefined) {
          let surveyTopicBody: OrganizationSurvey = {
            id: surveyTopic,
            organizationId: element.organizationId ? element.organizationId : undefined,//concernedOrganization.id,
            surveyId: element.id,
            programId: this.program.id,
            programActivityId: this.programActivityId ? this.programActivityId : undefined,
            programClassId: this.programClassId ? this.programClassId : undefined,
          };
          this.surveyTopic.saveOrganizationSurvey(surveyTopicBody).subscribe(response => {
            if (response && response.body) this.getPageData(true);
          });
        }
        surveySelectionTableDialog.close();
      });
    }
  }

  showCreateResponseButton(surveyTopic: SurveySelectionModel): boolean {
    if (surveyTopic?.survey?.startDateTime != undefined || surveyTopic?.survey?.endDateTime != undefined) {
      let currDate: Date = new Date();
      let startDate = surveyTopic?.survey?.startDateTime != undefined ? new Date(surveyTopic?.survey?.startDateTime) : undefined;
      let endDate = surveyTopic?.survey?.endDateTime != undefined ? new Date(surveyTopic?.survey?.endDateTime) : undefined;
      return surveyTopic != undefined && surveyTopic?.survey?.published && surveyTopic?.survey?.enabled && this.canCreateResponse()
        && (startDate == undefined || startDate < currDate) && (endDate == undefined || endDate > currDate);
    } else {
      return surveyTopic != undefined && surveyTopic?.survey?.published && surveyTopic?.survey?.enabled && this.canCreateResponse();
    }
  }

  canCreateResponse(): boolean {
    return this.authService?.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_SURVEY_RESPONSE);
  }

  createResponse(surveyId: number, topicId: number) {
    if (this.canCreateResponse() && topicId != undefined && surveyId != undefined) {
      this.router.navigate([`/survey/response/${surveyId}/create-response?topic=${topicId}`], { relativeTo: this.route });
    }
  }

  deleteTopic(topicId: number) {
    this.dialogService.confirmDialog({
      "title": this._translateService.instant('popup-module.titles.delete-topic'),
      "message": this._translateService.instant('popup-module.messages.topic-delete'),
      "confirmText": this._translateService.instant('popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('popup-module.buttons.no')
    })
      .subscribe(response => {
        if (response) {
          this.surveyTopic.deleteSurveyTopic(topicId).subscribe(response => {
            this.getPageData(true);
          }, error => {
            error = this._translateService.instant('snackbar.link-already-deleted');
            this._snackbar.open(error, null, { duration: 3000 });
          });
        }
      })
  }

  canEditSurveyLink() {
    //add permission in future
    return true;
  }

  canDeleteSurveyLink() {
    //add permission in future
    return true;
  }

  hasAccessToOrganization(organizationId: number) {
    let hasAccess: boolean = false;
    if (organizationId == undefined || organizationId == null) {
      hasAccess = true;
    }
    else if (this.userActiveOrganizations && organizationId != null && organizationId > 0) {
      for (let model of this.userActiveOrganizations) {
        if (model && model.id != undefined && !hasAccess) hasAccess = model.id == organizationId;
      }
    }
    return hasAccess;
  }

  linkSurvey() {
    if (this.program != undefined && this.program.id != undefined && this.program.id > 0) {
      const surveySelectionTableDialog = this.matDialog.open(SurveySelectionComponent, {
        "width": '6000px',
        "maxWidth": '80vw',
        "maxHeight": '80vh',
        "autoFocus": false,
        data: { linkTo: this.linkTo },
        direction: this._translateService.currentLang == 'ar' ? 'rtl' : 'ltr'
      });

      const subscribeDialog = surveySelectionTableDialog.componentInstance.selectionEventEmitter.subscribe((element) => {
        if (element !== undefined) {
          const surveyTopicBody: OrganizationSurvey = {
            id: undefined,
            organizationId: this.organizationId ? this.organizationId : undefined,
            surveyId: element.id,
            programId: this.program.id ? this.program.id : undefined,
            programActivityId: this.programActivityId ? this.programActivityId : undefined,
            programClassId: this.programClassId ? this.programClassId : undefined,
          };

          this.surveyTopic.saveOrganizationSurvey(surveyTopicBody).subscribe(response => {
            if (response && response.body) this.getPageData(true);
          });
        }
        surveySelectionTableDialog.close();
      });
      this.mainPanel.open();
    }
  }

  handleSurveyLinkPanelOpenEvent() {
    this.openedPanel = true;
    if (!this.hasFetchedData) {
      this.getPageData(true);
    }
  }

}

export interface SurveySelectionTableInput {
  surveyId: number;
  questionId: number;
}

export interface SurveyTopicInput {
  id: number;
  fromComponent: string;
  organizationId: number;
  linkTo: string;
}
