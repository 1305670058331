import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized, ActivationStart, NavigationStart, NavigationEnd, ChildActivationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';

@Component({
	template: ''
})
export abstract class PageBaseClass implements OnInit, OnDestroy {
	protected router: Router;
	route: ActivatedRoute;
	routeParams: any;
	routeData: any;
	destinationURL = '';
	paramsSubscription: Subscription;
	dataSubscription: Subscription;
	eventSubscription: Subscription;
	routerUrlSubscription: Subscription;
	previousUrl = '';
	currentUrl = '';
	constructor(protected injector: Injector) {
		this.injectDependencies();
	}

	/**
	 * setParentRouteInfo must come before interceptRouteChanges
	 * because we might use parentRouteParams on route params change
	 */
	ngOnInit(): void {
		this.interceptRouteChanges();
		this.previousUrl = this.router.url;
	}

	injectDependencies(): void {
		this.router = this.injector.get(Router);
		this.route = this.injector.get(ActivatedRoute);
	}

	interceptRouteChanges(): void {
		this.eventSubscription = this.router.events.subscribe(routerEvents => {
			if (routerEvents instanceof ActivationStart) {
				this.onRouterEventsStart(routerEvents);
			} else if (routerEvents instanceof NavigationStart) {
				this.currentUrl = routerEvents.url;
			} else if (routerEvents instanceof NavigationEnd) {
				this.onRouterEventsChange(routerEvents);
			} else if (routerEvents instanceof ChildActivationStart) {
				this.onRouterParentPathChange(routerEvents);
			}
		});
		this.routerUrlSubscription = this.router.events
			.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
			.subscribe((events: RoutesRecognized[]) => {
				this.previousUrl = events[0].urlAfterRedirects;
				this.currentUrl = events[1].urlAfterRedirects;
			});
		this.paramsSubscription = this.route.params.subscribe(routeParams => {
			this.routeParams = routeParams;
			this.onRouteParamsChange(routeParams);
		});
		this.dataSubscription = this.route.data.subscribe(routeData => this.onRouteDataChange(routeData));
	}

	public onRouterEventsStart(routerEvent: any): void { }

	public onRouterEventsChange(routerEvent: any): void { }

	public onRouterParentPathChange(routerEvent: any): void { }

	public onRouteParamsChange(params: any): void {}

	public onRouteChange(data: any): void { }

	private onRouteDataChange(data: any): void {
		this.routeData = data;
		this.onRouteChange(data);
	}

	ngOnDestroy(): void {
		if (this.paramsSubscription) { this.paramsSubscription.unsubscribe(); }
		if (this.dataSubscription) { this.dataSubscription.unsubscribe(); }
		if (this.eventSubscription) { this.eventSubscription.unsubscribe(); }
		if (this.routerUrlSubscription) { this.routerUrlSubscription.unsubscribe(); }
	}
}
