import { Component, OnInit, Input, } from '@angular/core';
import { SurveySection } from '../../../models/survey/survey-section';
import { FormArray, FormGroup } from '@angular/forms';
import { SurveyQuestion } from '../../../models/question/survey-question';
import { ConditionBlock } from '../../../models/condition/condition-block';
import { QuestionConditionsComponent } from '../../survey/conditions/question-conditions/question-conditions.component';
import { ISurveyResponseClientService } from '../../../shared/services/interfaces/survey-response-client.interface';

@Component({
  selector: 'app-response-section-form',
  templateUrl: './response-section-form.component.html',
  styleUrls: ['./response-section-form.component.sass']
})
export class ResponseSectionFormComponent implements OnInit {
  @Input() section: SurveySection;
  @Input() surveyMaxGrade: number;
  @Input() surveyGraded: boolean;
  @Input() sectionIndex: number;
  @Input() sectionQuestionFormGroupMap: Map<number, FormGroup>; // map of the questions in this section and the FG
  @Input() questionFormGroupMap: Map<number, Map<number, FormGroup>>; // map of all the sections and question's FG
  @Input() questionSectionMap: Map<number, number> = new Map<number, number>(); // link between question and their section
  @Input() isEditMode: boolean = true;
  @Input() invalidQuestionResponseIds : number[] = [];
  @Input() isSubmitted: boolean = false;
  @Input() surveyResponseClientService: ISurveyResponseClientService;

  contentExpanded = true;

  sectionTotalScore: number = undefined;
  sectionTotalScoreCalculated: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // if(this.sectionIndex != 0) this.collapseContent();
  }

  toogleContentExpansion(){
    if(this.contentExpanded){
      this.collapseContent()
    } else{
      this.expandContent()
    }
  }

  getResponseTotalScore(): number{
    if(this.isSubmitted && !this.sectionTotalScoreCalculated && this.surveyGraded && this.section != undefined && this.section.questions != undefined){
      this.sectionTotalScoreCalculated = true;
      let questionCount = 0;
      for(let question of this.section.questions){
        if(question && question.graded){
          let fgroup: FormGroup = this.getQuestionFormGroup(question.id);
          if(fgroup){
            let questionGrade: number = fgroup.get("grade").getRawValue();
            if(questionGrade != undefined){
              questionCount++;
              if(this.sectionTotalScore == undefined){
                this.sectionTotalScore = questionGrade;
              } else{
                this.sectionTotalScore += questionGrade;
              }
            }
          }
        }
      }
      if(questionCount > 0) this.sectionTotalScore = this.sectionTotalScore / questionCount;
      if(this.sectionTotalScore != undefined) this.sectionTotalScore = Math.round((this.sectionTotalScore + Number.EPSILON) * 100) / 100;
    }
    return this.sectionTotalScore; // totalGrade ? parseFloat(totalGrade.toFixed(2)) : 0; //this.sectionIndex % this.surveyMaxGrade;
  }

  expandContent(){
    this.contentExpanded = true;
    let elementid : string = 'section-content-' + this.sectionIndex;
    document.getElementById(elementid).style.height = "100%";
    // document.getElementById(elementid).style.visibility = "visible";
    document.getElementById(elementid).style.display = "block";
  }

  collapseContent(){
    this.contentExpanded = false;
    let elementid : string = 'section-content-' + this.sectionIndex;
    document.getElementById(elementid).style.height = "0px";
    // document.getElementById(elementid).style.visibility = "hidden";
    document.getElementById(elementid).style.display = "none";
  }

  getQuestionFormGroup(questionId: number): FormGroup{
    let questionFormGroup;
    if(questionId != undefined && this.sectionQuestionFormGroupMap && this.sectionQuestionFormGroupMap.has(questionId))
      questionFormGroup = this.sectionQuestionFormGroupMap.get(questionId);
    return questionFormGroup;
  }

  isVisibleQuestion(surveyQuestion: SurveyQuestion): boolean{
    return ResponseSectionFormComponent.visibleQuestion(this.questionSectionMap, this.questionFormGroupMap, surveyQuestion,this.surveyResponseClientService);
  }

  public static visibleQuestion(questionSectionMap: Map<number, number>, questionFormGroupMap: Map<number, Map<number, FormGroup>>,surveyQuestion: SurveyQuestion,surveyResponseClientService: ISurveyResponseClientService): boolean{
    let show = true;
    if(surveyQuestion && surveyQuestion.condition && surveyQuestion.condition != undefined){
      let visibiltyFactor = false;
      let condition = surveyQuestion.condition;
      if(show && condition){
        if(condition.blocks){
          for(let block of condition.blocks){
            if(!visibiltyFactor && ((block.statements && block.statements.length > 0) || (block.customStatements && block.customStatements.length > 0) )) visibiltyFactor = this.calculateBlockVisibilityFactor(questionSectionMap, questionFormGroupMap, block,surveyResponseClientService);
          }
        }
        if(condition.action == QuestionConditionsComponent.CONDITION_ACTION_SHOW){
          show = visibiltyFactor;
        } else{
          show = !visibiltyFactor;
        }
      }
    }
    return show;
  }

  public static calculateBlockVisibilityFactor(questionSectionMap: Map<number, number>, questionFormGroupMap: Map<number, Map<number, FormGroup>>, block: ConditionBlock,surveyResponseClientService : ISurveyResponseClientService): boolean{
    let blockVisibilityFactor = true;
    if(block && block.statements && block.statements.length > 0){

      for(let statement of block.statements){
        if(blockVisibilityFactor && statement){
          let surveyAnswerId = statement?.questionAnswer?.id;
          let surveyQuestionId = statement?.surveyQuestion?.id;
          if(surveyQuestionId && surveyQuestionId > 0 && surveyAnswerId && surveyAnswerId > 0)
            blockVisibilityFactor = this.calculateStatementVisibilityFactor(questionSectionMap, questionFormGroupMap, surveyQuestionId, surveyAnswerId);
        }
      }
    }
    //add checking on custom filter
    if(block && block.customStatements && block.customStatements.length > 0){
      for(let customStatement of block.customStatements){
        if(blockVisibilityFactor && customStatement && customStatement.filterName  && customStatement.filterId){
          let filterName = customStatement?.filterName;
          let filterId = customStatement?.filterId;
          if(filterName && filterId && surveyResponseClientService){
            blockVisibilityFactor = surveyResponseClientService.evaluateCustomConditionForQuestionVisibility(customStatement);
          }
        }
      }
    }
    return blockVisibilityFactor;
  }

  public static calculateStatementVisibilityFactor(questionSectionMap: Map<number, number>, questionFormGroupMap: Map<number, Map<number, FormGroup>>,
     surveyQuestionId: number, surveyAnswerId: number): boolean{
    let statementVisibilityFactor = false;
    if(questionSectionMap){
      let sectionId = questionSectionMap?.get(surveyQuestionId);
      if(surveyQuestionId && surveyQuestionId > 0 && surveyAnswerId && surveyAnswerId > 0 && sectionId && sectionId > 0){
        let questionsMap : Map<number, FormGroup> = questionFormGroupMap?.get(sectionId);
        if(questionsMap){
          let answerFormGroup: FormGroup = questionsMap?.get(surveyQuestionId);
          if(answerFormGroup){
            for(let control of (answerFormGroup?.get('answers') as FormArray)?.controls){
              if(!statementVisibilityFactor) statementVisibilityFactor =  control?.get('questionAnswer')?.get('id')?.getRawValue() == surveyAnswerId;
            }
          }
        }
      }
    }
    return statementVisibilityFactor;
  }
}
