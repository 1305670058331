<div class="fullwidth locateForm">
  <!-- <app-table-filter [filters]="filters" (filterOutput)="applyFilter($event)"></app-table-filter> -->
  <app-responsive-list-view
       [tableColumns]="_tableColumns"
       [sortingDataAccessor]="_sortingDataAccessor"
       [filteringPredicate]="_filteringPredicate"
       [service]="service"
       [withAdd]="canAdd"
       [hasListAccess]="hasListAccess"
       [maxCellStringLength]="80"
       [maxStringLength]="20"
       [inputFilterEventSubject]="filterEventSubject.asObservable()"
       (mode)="updateViewMode($event)"
       [_tableMenuAction]="_tableMenuAction"
       [booleanIconColumnDisplayList]="_booleanIconColumnDisplayList"
       (menuActionEventEmitter)="tableMenuActionHandler($event)"
       addButtonLabel="{{ 'program.fields.create-program' | translate}}"
       tableTitleLabel="{{ 'program.title' | translate}}"
       [inputReloadEventSubject]="reloadEventSubject.asObservable()"
       [showFilterButton]="true"
       [showBackButton]="false"
       [initiateSearchFromFilter]="initiateSearchFromFilter"
       initialPageSize="10"
       [isServerSideSorting]="true">
    <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter>
    <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter>
  </app-responsive-list-view>
</div>
