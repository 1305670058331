import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ChecklistBaseClass } from '../../bases/tree-checklist.class';
import { ItemFlatNode, ItemNode } from '../../models/tree-checklist.model';

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-tree-checklist',
  templateUrl: './tree-checklist.component.html',
  styleUrls: ['./tree-checklist.component.sass'],
})
export class TreeChecklistComponent extends ChecklistBaseClass implements OnInit {
  @Input('data') data?: any;
  @Input('childrenKey') childrenKey?: any;
  @Input('displayKey') displayKey?: any;
  @Input('checkedData') checkedData?: any;
  @Output('clickCheckBox') clickCheckBox = new EventEmitter<any>();
  result: any;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  treeControl: FlatTreeControl<ItemFlatNode>;

  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);

  constructor(
    public injector: Injector
  ) {
    super(injector)
  }

  ngOnChanges(changes: SimpleChanges) {

    this.treeControl = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    const dataChange = changes['data'];
    if (dataChange && this.data) {
      this.data = dataChange.currentValue
      this.dataSource.data = this.buildFileTree(this.data, 0, this.childrenKey, this.displayKey);
    }

    const checkedDatachange = changes['checkedData'];
    if (checkedDatachange && this.checkedData) {
      this.checkedData = checkedDatachange.currentValue;
    }

    //initialize and format result
    this.result = this.checkedData.map(e => {
      return { key: e }
    });
  }

  ngOnInit(): void {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
  }

  checkBoxClicked(node, componentRef) {
    let action = {
      key: node.key,
      value: componentRef.checked,
    }
    if (action.value === true) {
      this.result.push({ key: action.key })
    } else {
      this.result = this.result.filter(f => f.key !== action.key)
    }

    this.clickCheckBox.emit(this.result);
  }

  /**
   * Transformer to convert nes`ted node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new ItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    flatNode.key = node.key;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);

    //select data already existing
    if (this.checkedData.includes(flatNode.key)) {
      this.checklistSelection.select(flatNode)
    }

    return flatNode;
  };

  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  getChildren = (node: ItemNode): ItemNode[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants or all are selected */
  descendantsPartiallyOrAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result || this.descendantsAllSelected(node) || this.checklistSelection.isSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ItemFlatNode, componentRef): void {
    this.checkBoxClicked(node, componentRef)
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);

    if (!this.checklistSelection.isSelected(node)) {
      this.checklistSelection.deselect(...descendants);
      this.removeDescendants(descendants);
    }

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  //remove descendants from result
  removeDescendants(descendants) {
    let newResult = this.result.map(m => {
      return m.key
    })
    descendants.forEach(d => {
      if (newResult.includes(d.key)) {
        this.result = this.result.filter(f => f.key !== d.key)
      }
    })
    this.clickCheckBox.emit(this.result)
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: ItemFlatNode, componentRef): void {
    this.checkBoxClicked(node, componentRef)
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ItemFlatNode): void {
    let parent: ItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    this.checklistSelection.select(node);
    this.addParent(node);
  }

  //add parent to result when child clicked
  addParent(node) {
    let newResult = this.result.map(m => {
      return m.key
    })
    if (!newResult.includes(node.key)) {
      this.result.push({ key: node.key });
      this.clickCheckBox.emit(this.result)
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
