import { Component, ComponentRef, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Survey } from '../../../models/survey/survey';
import { SurveyService } from '../../../services/survey.service';
import { IdValueObject, LookupModel, LookupModelDto } from '../../../models/shared/lookup.model';
import { DialogService } from '../../../services/shared/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { LookupService } from '../../../services/lookups/lookup.service';
import { FormControllerGeneratorService } from '../../form-controller-generator.service';
import { Utils } from '../../../utils/survey-utils';
import { DatePipe } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { DropdownComparatorWithBrowserListener } from '../../shared/component-base/dropdown-comparator-with-browser';
import { SettingsService } from '../../../shared/services/settings.service';
import { ISurveyAuthenticationService } from '../../../shared/services/interfaces/authentication.interface';
import { OpenLinkedSurveyQuestionService } from '../../../services/shared/open-linked-survey-question.service';
import { SurveyFormQuestionHandler } from '../../../services/survey-form/survey-form-question-handler.service';

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.sass']
})
export class SurveyFormComponent extends DropdownComparatorWithBrowserListener implements OnInit, OnDestroy {
  @Input() isEditMode: boolean = true;

  public title: string;
  public form: FormGroup;
  public error: string;
  survey: Survey;
  id: number;
  index: number;
  @Output() questionChangeEventSubject: Subject<Map<IdValueObject, IdValueObject[]>> = new Subject<Map<IdValueObject, IdValueObject[]>>();
  surveyTypeParam: number;

  // UI ELEMENTS BEHAVIORAL VARIABLES
  isPreview: boolean = false;
  headerOpenState = false;

  // SURVEY ACTIONS ACCESS RIGHT
  consumerPermissionEnum;
  publishAccess: boolean = false;
  deleteAccess: boolean = false;
  cloneAccess: boolean = false;
  enableAccess: boolean = false;
  saveAccess: boolean = false;
  disableSubmit: boolean = false;
  surveyTypes: LookupModel[];

  isFormLoaded: boolean = false;

  public answerTypes: LookupModel[];

  private _subscription = new Subscription();
  // USED FOR THE INJECTABLE COMPONENTS
  @ViewChild('surveyHeaderAdditionalFormContainer', { read: ViewContainerRef, static: false }) surveyHeaderAdditionalFormContainer: ViewContainerRef;
  componentRefMap: Map<string, ComponentRef<any>> = new Map();

  constructor(
    private _surveyService: SurveyService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _openLinkedSurveyQuestionService: OpenLinkedSurveyQuestionService,
    private _snackbar: MatSnackBar,
    private _dialogService: DialogService,
    public readonly _translateService: TranslateService,
    private _lookupService: LookupService,
    private _datePipe: DatePipe,
    private _settingsService: SettingsService,
    private _authenticationService: ISurveyAuthenticationService,
    public surveyFormQuestionHandler: SurveyFormQuestionHandler) {
    super();
    let surveyTypeParamValue = this._route.snapshot.params['surveyType'];
    if (surveyTypeParamValue && this.isNumber(surveyTypeParamValue)) this.surveyTypeParam = surveyTypeParamValue;

    this.consumerPermissionEnum = this._authenticationService.getPermissionEnumeration();
    this.publishAccess = this._authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.PUBLISH_SURVEY);
    this.deleteAccess = this._authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.DELETE_SURVEY);
    this.cloneAccess = this._authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.CLONE_SURVEY);
    this.enableAccess = this._authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.ENABLE_SURVEY);
    this.saveAccess = this._authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SAVE_SURVEY);
  }

  ngOnInit(): void {
    this._subscription.add(
      this._route.data.subscribe((params) => {
        this.surveyTypes = params.surveyLookups.surveyTypes;
        this.answerTypes = params.surveyLookups.lookups.AnswerType;
        this.surveyFormQuestionHandler.addLookupByName("Gender", params.surveyLookups.lookups.Gender);
        this.surveyFormQuestionHandler.addLookupByName("Region", params.surveyLookups.lookups.Region);
      }),
    );
    this._subscription.add(
      this._route.paramMap.subscribe((params) => {
        this.id = Number(params.get('id'));
        if (this.id && this.id > 0) this.getData();
      })
    );
    this._lookupService.getLookupObjectsService(["Achievement"], false, true).subscribe(response => {
      this.surveyFormQuestionHandler.addLookupByName("Achievement", response.body["Achievement"]);
    });

    this.createFormGroups();

    this.surveyFormQuestionHandler?.resetQuestions();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  getAssetsUrl(assetPath: string): string {
    let url = assetPath;
    if (this._settingsService && this._settingsService.settings) {
      url = this._settingsService.settings.surveyAssetsBasePath;
      if (!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  isNumber(value: string | number): boolean {
    return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
  }

  createFormGroups() {
    this.form = new FormGroup({
      'id': new FormControl({ value: this.survey?.id, disabled: true }),
      'startDateTime': new FormControl({ value: this.survey?.endDateTime ? new Date(this.survey?.startDateTime) : undefined, disabled: !this.isEditMode }),
      'endDateTime': new FormControl({ value: this.survey?.endDateTime ? new Date(this.survey?.endDateTime) : undefined, disabled: !this.isEditMode }),
      'title': new FormControl({ value: this.survey?.title, disabled: !this.isEditMode }, Validators.required),
      'description': new FormControl({ value: this.survey?.description, disabled: !this.isEditMode }, Validators.required),
      'surveyType': new FormControl({ value: this.survey?.surveyType ? this.survey?.surveyType : this.surveyTypeParam, disabled: !this.isEditMode }, Validators.required),
      'maxGrade': new FormControl({ value: this.survey?.maxGrade, disabled: !this.isEditMode }),
      'enabled': new FormControl({ value: this.survey?.enabled ? this.survey?.enabled : false, disabled: !this.isEditMode }),
      'graded': new FormControl({ value: this.survey?.graded ? this.survey?.graded : false, disabled: !this.isEditMode }),
      'published': new FormControl({ value: this.survey?.published ? this.survey?.published : false, disabled: !this.isEditMode }),
      'editable': new FormControl({ value: this.survey?.editable ? this.survey?.editable : false, disabled: !this.isEditMode }),
      'sections': new FormArray([]),
      'index': new FormControl({ value: this.survey?.index, disabled: true })
    });

    if (this.surveyTypeParam && this.form) {
      let surveyTypeControl: FormControl = this.form.get('surveyType') as FormControl;
      if (surveyTypeControl) surveyTypeControl.patchValue(new LookupModelDto(this.surveyTypeParam, undefined, undefined, undefined, undefined));
    }
  }

  isSurveyGraded(): boolean {
    return this.form?.get('graded') != undefined ? this.form?.get('graded')?.getRawValue() : true;
  }

  hasResponse() {
    return this.survey && this.survey.id && this.survey.id > 0 ? this.survey?.hasResponse : false
  }

  getData() {
    if (this.id && this.id > 0) {
      this._surveyService.getObject(this.id).subscribe(response => {
        this.survey = response.body;
        this.surveyFormQuestionHandler.setSurvey(this.survey);
        this.title = "Survey Edit Form";
        this.isEditMode = this.survey.editable && this.saveAccess;
        this.form.patchValue(this.survey);
        this.isEditMode ? this.form.enable() : this.form.disable();
        if (this.survey?.startDateTime) this.form.get('startDateTime').patchValue(new Date(this.survey?.startDateTime))
        if (this.survey?.endDateTime) this.form.get('endDateTime').patchValue(new Date(this.survey?.endDateTime))
        let sectionsFormArray = this.getSectionsFormArray() as FormArray;
        if (sectionsFormArray) sectionsFormArray.clear();
        for (let section of this.survey?.sections) {
          let sectionFormGroup = FormControllerGeneratorService.createSectionFormGroup(this.isEditMode);
          sectionFormGroup.patchValue(section);
          let questionsFormArray = this.getQuestionsFormGroup(sectionFormGroup) as FormArray;
          if (questionsFormArray) questionsFormArray.clear();
          for (let question of section?.questions) {
            let questionFormGroup = FormControllerGeneratorService.createQuestionFormGroup(this.isEditMode, this.isSurveyGraded());
            questionFormGroup.patchValue(question);
            if (question?.condition && question?.condition != undefined) {
              let conditionFormGroup = FormControllerGeneratorService.createConditionFormGroup(this.isEditMode);
              questionFormGroup.addControl('condition', conditionFormGroup);
              let blocksFormArray = conditionFormGroup.get("blocks") as FormArray;
              if (blocksFormArray) blocksFormArray.clear();
              let condition = question?.condition;
              conditionFormGroup.patchValue(condition);
              if (condition?.blocks) {
                for (let blocks of condition.blocks) {
                  let blockFormGroup = FormControllerGeneratorService.createConditionBlockFormGroup(this.isEditMode);
                  blockFormGroup.patchValue(blocks);
                  blocksFormArray.push(blockFormGroup);
                  let statementFormArray = blockFormGroup.get("statements") as FormArray;
                  if (statementFormArray) statementFormArray.clear();
                  if (blocks?.statements) {
                    for (let statements of blocks.statements) {
                      let statementFormGroup = FormControllerGeneratorService.createStatementFormGroup(this.isEditMode);
                      statementFormGroup.patchValue(statements);
                      statementFormArray.push(statementFormGroup);
                    }
                  }
                  let customStatementFormArray = blockFormGroup.get("customStatements") as FormArray;
                  if (customStatementFormArray) customStatementFormArray.clear();
                  if (blocks?.customStatements) {
                    for (let customStatements of blocks.customStatements) {
                      let customStatementFormGroup = FormControllerGeneratorService.createCustomStatementFormGroup(this.isEditMode);
                      customStatementFormGroup.patchValue(customStatements);
                      customStatementFormArray.push(customStatementFormGroup);
                    }
                  }
                }
              }

            }
            let questionBank = questionFormGroup.get("question") as FormGroup;
            questionBank.patchValue(question?.question);
            if (question?.question?.answers) {
              for (let answer of question.question.answers) {
                let answerFormGroup = FormControllerGeneratorService.createAnswerFormGroup(this.isEditMode);
                answerFormGroup.patchValue(answer);
                let answersFormArray = questionBank.get("answers") as FormArray;
                answersFormArray.push(answerFormGroup);
              }
            }
            questionsFormArray.push(questionFormGroup);
          }
          sectionsFormArray.push(sectionFormGroup);
        }
        this.surveyFormQuestionHandler.calculateQuestionAnswerMap();

        this.isFormLoaded = true;
      });
    }
  }

  editable(): boolean {
    return this.isEditMode && this.saveAccess;
  }

  getSurveySectionFormControlElement(parentCntrol: any, index: number): FormGroup {
    parentCntrol.patchValue({ index: index });
    return parentCntrol ? parentCntrol as FormGroup : null;
  }


  validateFormForSave(): string {
    let error: string = undefined;
    if (this.form) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      let rawValue = this.form.getRawValue();
      let startDate = rawValue.startDateTime && !isNaN(rawValue.startDateTime) ? new Date(rawValue.startDateTime.toISOString().slice(0, 10)) : undefined;
      let endDate = rawValue.endDateTime && !isNaN(rawValue.endDateTime) ? new Date(rawValue.endDateTime.toISOString().slice(0, 10)) : undefined;
      let titleValue = this.form.controls["title"].getRawValue();
      if (!titleValue || titleValue == undefined) {
        error = this._translateService.instant('siren-survey-translation.error.survey-title-mandatory');
      } else if (startDate && endDate && startDate > endDate) {
        error = this._translateService.instant('siren-survey-translation.error.end-date-smaller-start-date');
      } else if (this.form?.get('graded') && this.form?.get('published')) {
        let isGraded: boolean = this.form?.get('graded')?.getRawValue();
        let isPublished: boolean = this.form?.get('published')?.getRawValue();
        let maxGrade = this.form?.get('maxGrade')?.getRawValue();
        if (isGraded && isPublished && (maxGrade == undefined || maxGrade <= 0)) {
          error = this._translateService.instant('siren-survey-translation.survey-form.error.missing-max-grade');
          this.toogleValidatorsOnMaxGrade(true);
        }
      }
      if (!error) error = this.surveyFormQuestionHandler?.validateFormForSave();
      // if(!error && !this.validateComponentRefMapElements()) error = "the organization is mandatory in the survey topics";
      if (!error) error = !this.form.valid ? this._translateService.instant('siren-survey-translation.error.form_invalid') : undefined;
    }
    return error;
  }

  adaptOrderAndGradedFlagForFormLists() {
    this.form.controls['sections'] = Utils.adaptOrderField(this.form.controls['sections'] as FormArray);
    let surveyIsGraded = this.isSurveyGraded();
    let sectionsArray = this.form.controls['sections'] as FormArray;
    sectionsArray.controls.forEach(section => {
      if (!surveyIsGraded) {
        section?.get('weight')?.patchValue(1);
        section?.get('passingGrade')?.patchValue(0);
        section?.get('totalGrade')?.patchValue(0);
      }
      let surveyQuestionFormArray = section.get('questions') as FormArray;
      surveyQuestionFormArray = Utils.adaptOrderField(surveyQuestionFormArray as FormArray);

      surveyQuestionFormArray.controls.forEach(surveyQuestion => {
        let question = surveyQuestion.get('question') as FormGroup;
        let answers = question.get('answers') as FormArray;
        answers = Utils.adaptOrderField(answers as FormArray);

        if (!surveyIsGraded) {
          surveyQuestion.get('graded').patchValue(false);
        } else {
          let isGradeOnly = question?.get('answerType')?.getRawValue()?.key == "GRADE_ONLY";
          if (isGradeOnly) surveyQuestion.get('graded').patchValue(true);
        }
      });
    });
  }

  saveSurvey() {
    this.toogleValidatorsOnMaxGrade(false);
    let error = this.validateFormForSave();
    if (!error) {
      this.adaptOrderAndGradedFlagForFormLists();
      this.executeSave();
    } else {
      this._snackbar.open(error, null, { duration: 3000 });
    }
  }

  canDisableSurvey(): boolean {
    return this.survey && this.survey.id != undefined && this.survey.id > 0 && this.survey.published && this.survey.enabled && this.enableAccess;
  }

  canEnableSurvey(): boolean {
    return this.survey && this.survey.id != undefined && this.survey.id > 0 && this.survey.published && !this.survey.enabled && this.enableAccess;
  }

  disableSurvey() {
    if (this.canDisableSurvey()) {
      this._surveyService.disableSurvey(this.survey.id).subscribe(response => {
        if (response) this.setSurveyEnabledFlag(false);
      });
    }
  }

  enableSurvey() {
    if (this.canEnableSurvey()) {
      this._surveyService.enableSurvey(this.survey.id).subscribe(response => {
        if (response) this.setSurveyEnabledFlag(true);
      });
    }
  }

  setSurveyEnabledFlag(value: boolean) {
    if (this.survey && this.form) {
      this.survey.enabled = value;
      this.form.get('enabled').patchValue(value);
    }
  }

  executeSave() {
    let rawValue = this.form.getRawValue();
    this.survey = rawValue;
    this.survey.startDateTime = this.survey.startDateTime ? this._datePipe.transform(this.survey.startDateTime, 'yyyy-MM-dd hh:mm:ss') : undefined;
    this.survey.endDateTime = this.survey.endDateTime ? this._datePipe.transform(this.survey.endDateTime, 'yyyy-MM-dd hh:mm:ss') : undefined;
    this.survey.title = this.survey.title != null ? this.survey.title.trim() : "";
    this.survey.description = this.survey.description != null ? this.survey.description.trim() : "";
    this.surveyFormQuestionHandler.setSurvey(this.survey);
    this.disableSubmit = true;
    if (this.survey.id) {
      this._surveyService.updateObject(this.survey.id, this.survey).subscribe(response => {
        if (response) {
          this.surveyFormQuestionHandler?.triggerSurveySaveEvent();
          this.survey = response.body;
          this.surveyFormQuestionHandler.setSurvey(this.survey);
          this.id = this.survey.id;
          this.disableSubmit = false;
          this.handleAfterSaveResponse(true);
        } else {
          this.error = "Could not update survey!";
          this.disableSubmit = false;
        }
      }, error => {
        this.error = error.error.message;
        this.disableSubmit = false;
      });
    } else {
      this._surveyService.postObject(this.survey).subscribe(response => {
        if (response) {
          this.disableSubmit = false;
          this.surveyFormQuestionHandler?.triggerSurveySaveEvent();
          this.survey = response.body;
          this.surveyFormQuestionHandler.setSurvey(this.survey);
          this.id = this.survey.id;
          this.handleAfterSaveResponse(false);
          this.reloadPage();
        } else {
          this.disableSubmit = false;
          this.error = "Could not save survey!";
        }
      }, error => {
        this.disableSubmit = false;
        this.error = error.error.message;
      });
    }
  }

  reloadPage() {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  handleAfterSaveResponse(isUpdate: boolean) {
    if (isUpdate) {
      this.getData();
      this._snackbar.open(this._translateService.instant('siren-survey-translation.survey-form.survey-updated'), null, { duration: 3000 });
    } else {
      this._router.navigate([('../' + this.survey?.id)], { relativeTo: this._route });
      this.getData();
      this._snackbar.open(this._translateService.instant('siren-survey-translation.survey-form.survey-saved'), null, { duration: 3000 });
    }
  }

  canPublish(): boolean {
    return this.survey && this.survey.id != undefined && this.survey.id > 0 && !this.survey?.published && this.publishAccess;
  }

  canUnPublish(): boolean {
    return this.survey?.published && this.publishAccess;
  }

  toogleValidatorsOnStartDate(enable: boolean) {
    if (enable) {
      this.form?.controls['startDateTime']?.setValidators(Validators.required);
      this.form?.controls['startDateTime']?.updateValueAndValidity();
    } else {
      this.form?.controls['startDateTime']?.removeValidators(Validators.required);
      this.form?.controls['startDateTime']?.updateValueAndValidity();
    }
  }

  toogleValidatorsOnMaxGrade(enable: boolean) {
    if (enable) {
      this.form?.controls['maxGrade']?.setValidators(Validators.required);
      this.form?.controls['maxGrade']?.updateValueAndValidity();
    } else {
      this.form?.controls['maxGrade']?.removeValidators(Validators.required);
      this.form?.controls['maxGrade']?.updateValueAndValidity();
    }
  }

  publishSurvey() {
    let rawValue = this.form.getRawValue()
    let startDate = rawValue.startDateTime && !isNaN(rawValue.startDateTime) ? new Date(rawValue.startDateTime.toISOString().slice(0, 10)) : undefined;
    let endDate = rawValue.endDateTime && !isNaN(rawValue.endDateTime) ? new Date(rawValue.endDateTime.toISOString().slice(0, 10)) : undefined;
    let nowDate = new Date(new Date().toISOString().slice(0, 10));
    this.toogleValidatorsOnMaxGrade(false);
    if (this.canPublish()) {
      let error: string = undefined;
      if ((startDate && endDate && startDate > endDate)) {
        error = this._translateService.instant('siren-survey-translation.survey-form.error.end-date-smaller-start-date');
      } else if (endDate && endDate < nowDate) {
        error = this._translateService.instant('siren-survey-translation.survey-form.error.end-date-smaller-now');
      } else if (this.form?.get('graded')) {
        let isGraded: boolean = this.form?.get('graded')?.getRawValue();
        let maxGrade = this.form?.get('maxGrade')?.getRawValue();
        if (isGraded && (maxGrade == undefined || maxGrade <= 0)) {
          error = this._translateService.instant('siren-survey-translation.survey-form.error.missing-max-grade');
          this.toogleValidatorsOnMaxGrade(true);
        }
      }
      if (!error) {
        error = this.validateFormForSave();
        if (!error) {
          this.executeSubmit();
        } else {
          this._snackbar.open(error, null, { duration: 3000 });
        }
      } else {
        this._snackbar.open(error, null, { duration: 3000 });
      }
    } else {
      this._snackbar.open("Survey cannot be published", null, { duration: 3000 });
    }
  }

  executeSubmit() {
    this.adaptOrderAndGradedFlagForFormLists();
    let rawValue = this.form.getRawValue();
    // executing save
    this.survey = rawValue;
    this.survey.startDateTime = this.survey.startDateTime ? this._datePipe.transform(this.survey.startDateTime, 'yyyy-MM-dd hh:mm:ss') : undefined;
    this.survey.endDateTime = this.survey.endDateTime ? this._datePipe.transform(this.survey.endDateTime, 'yyyy-MM-dd hh:mm:ss') : undefined;

    if (this.survey.id) {
      this._surveyService.updateObject(this.survey.id, this.survey).subscribe(response => {
        if (response) {
          this.surveyFormQuestionHandler?.triggerSurveySaveEvent();
          this.survey = response.body;
          this.surveyFormQuestionHandler.setSurvey(this.survey);
          this.id = this.survey.id;
          this.sendPublishRequest();
        } else {
          this.error = "Could not update survey!";
        }
      }, error => {
        this.error = error.error.message;
      });
    }
  }

  sendPublishRequest() {
    this._surveyService.publishSurvey(this.survey.id).subscribe(response => {
      if (response) {
        this._snackbar.open(this._translateService.instant('siren-survey-translation.survey-form.survey-published'), null, { duration: 3000 });
        this.getData();
      } else {
        this.error = "Could not publish survey!";
      }
    }, error => {
      this._snackbar.open(error?.error?.message, null, { duration: 3000 });
    });
  }

  unPublishSurvey() {
    if (this.canUnPublish()) {
      this._surveyService.unPublishSurvey(this.survey.id).subscribe(response => {
        if (response) {
          this._snackbar.open(this._translateService.instant('siren-survey-translation.survey-form.survey-unpublished'), null, { duration: 3000 });
          this.getData();
        } else {
          this.error = "Could not unpublish survey!";
        }
      }, error => {
        this._snackbar.open(error?.error?.message, null, { duration: 3000 });
      });
    } else {
      this._snackbar.open("Survey cannot be published", null, { duration: 3000 });
    }
  }

  getSectionsFormArray(): FormArray {
    return this.form.get('sections') as FormArray
  }

  getQuestionsFormArray(sectionIndex: number): FormArray {
    let sectionsArray = this.getSectionsFormArray();
    let section = sectionsArray.at(sectionIndex) as FormGroup;
    return this.getQuestionsFormGroup(section) as FormArray;
  }


  getQuestionsFormGroup(section: FormGroup): FormArray {
    let questions = section?.get('questions') as FormArray;
    return questions;
  }

  addSection() {
    let sectionsFormArray = this.getSectionsFormArray() as FormArray;
    let sectionFormGroup = FormControllerGeneratorService.createSectionFormGroup(this.isEditMode);
    sectionFormGroup.get('title').patchValue("Section " + (sectionsFormArray?.length + 1));
    sectionsFormArray.push(sectionFormGroup);
  }


  deleteSurvey() {
    this._dialogService.confirmDialog({
      "title": this._translateService.instant('siren-survey-translation.survey-form.messages.delete-title'),
      "message": this._translateService.instant('siren-survey-translation.survey-form.messages.delete-message'),
      "confirmText": this._translateService.instant('siren-survey-translation.survey-form.actions.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.survey-form.actions.no')
    })
      .subscribe(response => {
        if (response) {
          if (this.survey && this.survey.id && this.survey.id != undefined) {
            this._surveyService.deleteObject(this.survey.id).subscribe(response => {
              this._router.navigate(["../"], { relativeTo: this._route });
            }, error => {
              let message = error && error.error && error.error.message.includes("ConstraintViolationException")
                ? this._translateService.instant('error.record-depending-error')
                : this._translateService.instant('error.record-error');
              this._snackbar.open(message, null, { duration: 3000 });
            });
          }
        }
      });
  }
  questionChangeEventHandler(setUnsavedChanges: boolean): Map<IdValueObject, IdValueObject[]> {
    return this.surveyFormQuestionHandler?.calculateQuestionAnswerMap();
  }

  openPreviewPage() {
    this.surveyFormQuestionHandler?.resetQuestions();
    if (this.survey && this.survey.id != undefined) this.isPreview = true;
  }

  closePreviewPage() {
    if (this.survey && this.survey.id != undefined) this.isPreview = false;
  }

  goBack() {
    this._router.navigateByUrl('/survey/list');
    this.surveyFormQuestionHandler?.resetQuestions();
  }

  canCloneSurvey() {
    return this.id != undefined && this.id > 0 && this.cloneAccess;
  }

  cloneSurvey() {
    if (this.canCloneSurvey()) {
      this._surveyService.cloneSurvey(this.id).subscribe(response => {
        let surveyResponse = response?.body;
        if (surveyResponse && surveyResponse.id > 0) {
          this._router.navigateByUrl(`/survey/list/${surveyResponse.id}`);
          this.id = surveyResponse.id;
          this.getData();
        } else {
          this._snackbar.open("Unable to clone survey", null, { duration: 3000 });
        }
      }, error => {
        console.error(error.error.message);
      });
    }
  }

  onKeyDownEvent(event: any) {
    if (event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }

  //==================================================================================
  //========================== LINKED OBJECTS FEATURE ================================
  //==================================================================================


  openLinkedSurveysTable() {
    this._openLinkedSurveyQuestionService.openLinkedSurveysTable(this.id, undefined);
  }

  //==================================================================================
  //===================== INJECTABLE COMPONENT MANAGEMENT ============================
  //==================================================================================

}
