import { Injectable, Injector } from '@angular/core';
import { PageBaseClass } from '../base/page-base-class';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';

@Injectable()
export class CanActivatePageGuard extends PageBaseClass implements CanActivate {

	constructor(private readonly authenticationService: AuthenticationService, public injector: Injector) {
		super(injector);
	}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const canAccess = route?.data?.pageAccess?.key ? this.authenticationService.userModuleAccessRightValidation(route?.data?.pageAccess) : this.authenticationService.isModuleAccessible(route?.data?.pageAccess);

     if (canAccess) {
         return true;
     } else {
       this.router.navigate(['/']);
       return false;
     }
   }
}
