import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatatableInlineEdit } from '@siren-survey/app/component/shared/datatable/datatable-inline-edit.service';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { ResponsiveListInlineEditObject, TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { Subject, Subscription } from 'rxjs';
import { FormType } from 'src/app/models/admin/program/formType/formType';
import { LookUpType } from 'src/app/models/admin/program/lookupType/LookUpType';
import { LookUpList } from 'src/app/models/admin/program/lookupType/lookupList';
import { FormTypeService } from 'src/app/services/admin/form-type.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { MarchModuleEnum, MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';

@Component({
  selector: 'app-form-type',
  templateUrl: './form-type.component.html',
  styleUrls: ['./form-type.component.sass']
})
export class FormTypeComponent extends  DatatableInlineEdit implements OnInit {

  public tableTitleLabel: string;
  public reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  public inlineEditEventSubject: Subject<ResponsiveListInlineEditObject> = new Subject<ResponsiveListInlineEditObject>();
  public filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  public initiateSearchFromFilter = true;
  public canAdd: boolean = false;
  public hasListAccess: boolean = false;
  public predefinedFilter: Map<string, any>;
  public addBtnLabel: string;

  private _lastCreatedId = 0;
  private _marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  public _marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  _booleanIconColumnDisplayList: string[] = [
    "deprecated"
  ]

  _tableColumns  : DataTableModel<FormType> []= [
    {  columnDef: 'key', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "lookup-type.fields.key", header: this._translateService.instant('lookup-type.fields.key'),  cell: (element: FormType) => `${element?.key}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'value', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "lookup-type.fields.english-value", header: this._translateService.instant('lookup-type.fields.english-value'),  cell: (element: FormType) => `${ element?.value }`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'valueAr', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "lookup-type.fields.arabic-value", header: this._translateService.instant('lookup-type.fields.arabic-value'),  cell: (element: FormType) => `${ element?.valueAr }`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'linkTo', type: TableFilterTypeEnum.Lookup, lookupName: 'SurveyTypeLinkTo', lookupsValueById: undefined, headerTranslationPath: "lookup-type.fields.link-to", header: this._translateService.instant('lookup-type.fields.link-to'),  cell: (element: FormType) => `${element?.linkTo ? this._translateService.instant('lookup-type.fields.'+element?.linkTo?.value?.toLowerCase()) : ""}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'order', type: TableFilterTypeEnum.Number, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "lookup-type.fields.order", header: this._translateService.instant('lookup-type.fields.order'),  cell: (element: FormType) => `${element?.order}`, width: undefined},
    {  columnDef: '_menu_inline', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;

  filters: TableFilterElement[] = [
    { name: "value", translationPath: "lookup-type.fields.english-value", displayName: this._translateService.instant('lookup-type.fields.english-value'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "valueAr", translationPath: "lookup-type.fields.arabic-value", displayName: this._translateService.instant('lookup-type.fields.arabic-value'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "linkTo", translationPath: "lookup-type.fields.link-to", displayName: this._translateService.instant('lookup-type.fields.link-to'), type: TableFilterTypeEnum.Lookup, lookupName: "SurveyTypeLinkTo", lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg", allowedAction: this._authService.userModuleAccessRightValidation(this._marchPermissionEnum.SAVE_LOOKUPS), accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ]) },
    { name: this._translateService.instant('global.deprecate'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "../../../../assets/icons/delete-icon.svg", allowedAction: this._authService.userModuleAccessRightValidation(this._marchPermissionEnum.DELETE_LOOKUP) , accessRightExpressions: new Map<string, string>([ ["deprecated", "deprecated == undefined || deprecated == false"], ["id", "id > 0"] ])}
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string{
    return this._translateService.instant('global.delete');
  }

  constructor(
    public service : FormTypeService,
    private readonly _translateService: TranslateService,
    private _snackBar: MatSnackBar,
    private _authService: AuthenticationService) {
    super();

    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        if(key && key == 'values'){
          let calculatedValue = "";
          for(let element of data[key]) calculatedValue += element.value;
          return currentTerm + calculatedValue;
        } else{
          return currentTerm + data[key];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this._authService.userModuleAccessRightValidation(this._marchPermissionEnum.SAVE_LOOKUPS);
    this.hasListAccess = this._authService.isModuleAccessible(this._marchModuleEnum.LOOKUPS_MGT.module);
  }

  ngOnInit(): void {
  }

  usePostForUpdate(){
    return true;
  }
  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.CREATE_MODE) {
      this._lastCreatedId--;
      let editFormGroup = new FormGroup({
        'id': new FormControl({value: this._lastCreatedId, disabled: true}),
        'key': new FormControl({value:'', disabled: false},[Validators.required, CustomValidators.noWhitespaceValidator]),
        'value': new FormControl({value:'', disabled: false},[Validators.required, CustomValidators.noWhitespaceValidator]),
        'valueAr': new FormControl({value:'', disabled: false},[Validators.required, CustomValidators.noWhitespaceValidator]),
        'linkTo': new FormControl({value:'', disabled: false},[Validators.required]),
        'order': new FormControl({value:'', disabled: false},[Validators.required]),
      })
      let inlineEditObject = new ResponsiveListInlineEditObject(this._lastCreatedId, this._lastCreatedId, editFormGroup,false);
      this.inlineEditEventSubject.next(inlineEditObject);
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object){

      if(menuAction.actionName == "DELETE_ACTION"){
        if(this._authService.userModuleAccessRightValidation(this._marchPermissionEnum.DELETE_LOOKUP)){
          this.service.deleteObject(menuAction.object.id).subscribe( response =>{
            this.reloadEventSubject.next(true);
          }, error =>{
              let message = error && error.error && error.error.message.includes("ConstraintViolationException")
                ? this._translateService.instant('error.record-depending-error')
                : this._translateService.instant('error.record-error');
              this._snackBar.open(message, null, { duration: 3000 });
          });
        }
      } else{
        this.handleInlineEditingRequests(menuAction,this._snackBar,this._translateService);
      }
    }
  }

  handleOnSubmitError(){
    return this._translateService.instant('lookup-type.fields.error-message');
  }

  isInlineEditable(): boolean {
    return true;
  }

  getTableFormGroupModel(): FormGroup<any> {
    return  new FormGroup({
      'id': new FormControl({value: this._lastCreatedId, disabled: true}),
      'key': new FormControl({value:'', disabled: false},[Validators.required, CustomValidators.noWhitespaceValidator]),
      'value': new FormControl({value:'', disabled: false},[Validators.required, CustomValidators.noWhitespaceValidator]),
      'valueAr': new FormControl({value:'', disabled: false},[Validators.required, CustomValidators.noWhitespaceValidator]),
      'linkTo': new FormControl({value:'', disabled: false},[Validators.required]),
      'order': new FormControl({value:'', disabled: false},[Validators.required]),
      })
  }

  triggerInlineEditEventSubject(inlineEditObject: ResponsiveListInlineEditObject): void {
    if(this.inlineEditEventSubject) this.inlineEditEventSubject.next(inlineEditObject);
  }

  getTableService() {
    return this.service;
  }

  getValidForm(menuAction:MenuActionEmittedObject): boolean {
    menuAction.form.markAllAsTouched();
    menuAction.form.updateValueAndValidity();
    return menuAction.form.valid;
  }

  formatObject(object: any): any{
    object.key = object?.key?.trim();
    object.value = object?.value?.trim();
    object.valueAr = object?.valueAr?.trim();
    object.linkTo = object?.linkTo?.key
    return object;
  }

  withCheckUnicity(): boolean{
    return true;
  }

  objectClassName(): string{
    return "FormType";
  }

  customUnicity(): boolean{
    return true;
  }

}
