import { NgModule, Provider } from '@angular/core';
import { AuthGuard } from '../guards/auth.guard';
import { CanActivatePageGuard } from '../guards/can-activate-page.guard';

@NgModule({
})
export class GuardsModule {
    static forShared(): Provider[] {
        return [
            AuthGuard,
            // CanDeactivateGuard,
            CanActivatePageGuard
        ];
    }
}
