import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { ClassType } from '../../../models/admin/program/classType/classType';
import { ClassTypeList } from '../../../models/admin/program/classType/classTypeList';

@Injectable({
  providedIn: 'root'
})
export class ClassTypeService  extends BaseService<ClassTypeList, ClassType> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/class/class-type", http, injector);
  }

}
