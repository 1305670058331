export interface UserModule {
  module: string;
  key: string;
}
export interface MarchModule {
  module: string;
}
export const MarchModuleEnum: { [index: string]: MarchModule } = {
  SURVEY_MGT:                     { module: 'SURVEY_MGT' },
  USERS_MGT:                      { module: 'USERS_MGT' },
  ROLES_MGT:                      { module: 'ROLES_MGT' },
  ORGANIZATION_MGT:               { module: 'ORGANIZATION_MGT' },
  SURVEY_RESPONSE_ENTITIES_MGT:   { module: 'SURVEY_RESPONSE_ENTITIES_MGT' },
  PROGRAM_ENTITIES_MGT:           { module: 'PROGRAM_ENTITIES_MGT' },
  ACTIVITY_ENTITIES_MGT:          { module: 'ACTIVITY_ENTITIES_MGT' },
  ACTIVITY_CATEGORY_ENTITIES_MGT: { module: 'ACTIVITY_CATEGORY_ENTITIES_MGT' },
  CLASS_ENTITIES_MGT:             { module: 'CLASS_ENTITIES_MGT' },
  CLASS_TYPE_ENTITIES_MGT:        { module: 'CLASS_TYPE_ENTITIES_MGT' },
  QUESTION_ENTITIES_MGT:          { module: 'QUESTION_ENTITIES_MGT' },
  BENEFICIARY_ENTITIES_MGT:       { module: 'BENEFICIARY_ENTITIES_MGT' },
  LOOKUPS_MGT:                    { module: 'LOOKUPS_MGT' },
  LOGGING_ACTIVITIES_MGT:         { module: 'LOGGING_ACTIVITIES_MGT' },
  INCIDENTS_MGT:                  { module: 'INCIDENTS_MGT' },
  REPORTS_MGT:                    { module: 'REPORTS_MGT' },
  DATA_ADMIN:                     { module: 'DATA_ADMIN' },
  ASSESSMENT_MGMT:                { module: 'ASSESSMENT_MGMT'},
}

export const MarchPermissionEnum: { [index: string]: UserModule } = {
  SURVEY_LIST:                    { module: 'SURVEY_MGT', key: 'SURVEY_LIST' },
  SURVEY_DETAILS:                 { module: 'SURVEY_MGT', key: 'SURVEY_DETAILS' },
  PUBLISH_SURVEY:                 { module: 'SURVEY_MGT', key: 'PUBLISH_SURVEY' },
  SAVE_SURVEY:                    { module: 'SURVEY_MGT', key: 'SAVE_SURVEY' },
  DELETE_SURVEY:                  { module: 'SURVEY_MGT', key: 'DELETE_SURVEY' },
  ENABLE_SURVEY:                  { module: 'SURVEY_MGT', key: 'ENABLE_SURVEY' },
  CLONE_SURVEY:                   { module: 'SURVEY_MGT', key: 'SAVE_SURVEY' }, // clone and save survey has same accessRight from BE.
  ASSIGN_USER_SURVEY:             { module: 'SURVEY_MGT', key: 'ASSIGN_USER_SURVEY' },

  SURVEY_RESPONSE_LIST:           { module: 'SURVEY_RESPONSE_ENTITIES_MGT', key: 'SURVEY_RESPONSE_LIST' },
  SURVEY_RESPONSE_DETAILS:        { module: 'SURVEY_RESPONSE_ENTITIES_MGT', key: 'SURVEY_RESPONSE_DETAILS' },
  SAVE_SURVEY_RESPONSE:           { module: 'SURVEY_RESPONSE_ENTITIES_MGT', key: 'SAVE_SURVEY_RESPONSE' },
  DELETE_SURVEY_RESPONSE:         { module: 'SURVEY_RESPONSE_ENTITIES_MGT', key: 'DELETE_SURVEY_RESPONSE' },

  USER_LIST:                      { module: 'USERS_MGT', key: 'USER_LIST' },
  USER_GET:                       { module: 'USERS_MGT', key: 'USER_GET' },
  USER_CREATE:                    { module: 'USERS_MGT', key: 'USER_CREATE' },
  USER_EDIT:                      { module: 'USERS_MGT', key: 'USER_EDIT' },
  USER_DELETE:                    { module: 'USERS_MGT', key: 'USER_DELETE' },
  USER_REVOKE:                    { module: 'USERS_MGT', key: 'USER_REVOKE' },

  ROLE_LIST:                      { module: 'ROLES_MGT', key: 'ROLE_LIST' },
  ROLE_GET:                       { module: 'ROLES_MGT', key: 'ROLE_GET' },
  ROLE_CREATE:                    { module: 'ROLES_MGT', key: 'ROLE_CREATE' },
  ROLE_EDIT:                      { module: 'ROLES_MGT', key: 'ROLE_EDIT' },
  ROLE_DELETE:                    { module: 'ROLES_MGT', key: 'ROLE_DELETE' },

  ROLES_PERMISSIONS:              { module: 'ROLES_MGT', key: 'ROLES_PERMISSIONS' },
  PERMISSON_LIST:                 { module: 'ROLES_MGT', key: 'PERMISSON_LIST' },

  ORGANIZATION_LIST:              { module: 'ORGANIZATION_MGT', key: 'ORGANIZATION_LIST' },
  ORGANIZATION_GET:               { module: 'ORGANIZATION_MGT', key: 'ORGANIZATION_GET' },
  ORGANIZATION_CREATE:            { module: 'ORGANIZATION_MGT', key: 'ORGANIZATION_CREATE' },
  ORGANIZATION_UPDATE:            { module: 'ORGANIZATION_MGT', key: 'ORGANIZATION_UPDATE' },
  ORGANIZATION_DELETE:            { module: 'ORGANIZATION_MGT', key: 'ORGANIZATION_DELETE' },

  PROGRAM_LIST:                   { module: 'PROGRAM_ENTITIES_MGT', key: 'PROGRAM_LIST' },
  PROGRAM_GET:                    { module: 'PROGRAM_ENTITIES_MGT', key: 'PROGRAM_GET' },
  PROGRAM_MODIFY:                 { module: 'PROGRAM_ENTITIES_MGT', key: 'PROGRAM_MODIFY' },

  ACTIVITY_LIST:                  { module: 'ACTIVITY_ENTITIES_MGT', key: 'ACTIVITY_LIST' },
  ACTIVITY_GET:                   { module: 'ACTIVITY_ENTITIES_MGT', key: 'ACTIVITY_GET' },
  ACTIVITY_CREATE:                { module: 'ACTIVITY_ENTITIES_MGT', key: 'ACTIVITY_CREATE' },
  ACTIVITY_UPDATE:                { module: 'ACTIVITY_ENTITIES_MGT', key: 'ACTIVITY_UPDATE' },
  ACTIVITY_ENABLE:                { module: 'ACTIVITY_ENTITIES_MGT', key: 'ACTIVITY_ENABLE' },

  ACTIVITY_CATEGORY_LIST:         { module: 'ACTIVITY_CATEGORY_ENTITIES_MGT', key: 'ACTIVITY_CATEGORY_LIST' },
  ACTIVITY_CATEGORY_GET:          { module: 'ACTIVITY_CATEGORY_ENTITIES_MGT', key: 'ACTIVITY_CATEGORY_GET' },
  ACTIVITY_CATEGORY_CREATE:       { module: 'ACTIVITY_CATEGORY_ENTITIES_MGT', key: 'ACTIVITY_CATEGORY_CREATE' },
  ACTIVITY_CATEGORY_UPDATE:       { module: 'ACTIVITY_CATEGORY_ENTITIES_MGT', key: 'ACTIVITY_CATEGORY_UPDATE' },
  ENABLE_ACTIVITY_CATEGORY:       { module: 'ACTIVITY_CATEGORY_ENTITIES_MGT', key: 'ENABLE_ACTIVITY_CATEGORY' },

  CLASS_LIST:                     { module: 'CLASS_ENTITIES_MGT', key: 'CLASS_LIST' },
  CLASS_GET:                      { module: 'CLASS_ENTITIES_MGT', key: 'CLASS_GET' },
  CLASS_CREATE:                   { module: 'CLASS_ENTITIES_MGT', key: 'CLASS_CREATE' },
  CLASS_UPDATE:                   { module: 'CLASS_ENTITIES_MGT', key: 'CLASS_UPDATE' },
  DELETE_CLASS:                   { module: 'CLASS_ENTITIES_MGT', key: 'DELETE_CLASS' },

  CLASS_TYPE_LIST:                { module: 'CLASS_TYPE_ENTITIES_MGT', key: 'CLASS_TYPE_LIST' },
  CLASS_TYPE_GET:                 { module: 'CLASS_TYPE_ENTITIES_MGT', key: 'CLASS_TYPE_GET' },
  CLASS_TYPE_CREATE:              { module: 'CLASS_TYPE_ENTITIES_MGT', key: 'CLASS_TYPE_CREATE' },
  CLASS_TYPE_UPDATE:              { module: 'CLASS_TYPE_ENTITIES_MGT', key: 'CLASS_TYPE_UPDATE' },
  DELETE_CLASS_TYPE:              { module: 'CLASS_TYPE_ENTITIES_MGT', key: 'DELETE_CLASS_TYPE' },

  QUESTION_LIST:                  { module: 'QUESTION_ENTITIES_MGT', key: 'QUESTION_LIST' },
  QUESTION_DETAILS:               { module: 'QUESTION_ENTITIES_MGT', key: 'QUESTION_DETAILS' },
  SAVE_QUESTION:                  { module: 'QUESTION_ENTITIES_MGT', key: 'SAVE_QUESTION' },
  DELETE_QUESTION:                { module: 'QUESTION_ENTITIES_MGT', key: 'DELETE_QUESTION' },

  BENEFICIARY_LIST:               { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_LIST' },
  BENEFICIARY_GET:                { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_GET' },
  BENEFICIARY_MODIFY:             { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_MODIFY' },
  BENEFICIARY_DELETE:             { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_DELETE' },

  BASELINE_SUBMIT:                { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BASELINE_SUBMIT' },
  BENEFICIARY_RESPONSE_LIST:      { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_RESPONSE_LIST' },
  BENEFICIARY_STATUS_UPDATE:      { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_STATUS_UPDATE' },
  BENEFICIARY_LEGAL_CASE_LIST:    { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_LEGAL_CASE_LIST' },


  ASSIGN_BENEFICIARY_ACHIEVEMENT: { module: 'BENEFICIARY_ENTITIES_MGT', key: 'ASSIGN_BENEFICIARY_ACHIEVEMENT' },

  ADD_BENEFICIARY_COMMENT:        { module: 'BENEFICIARY_ENTITIES_MGT', key: 'ADD_BENEFICIARY_COMMENT' },
  DELETE_BENEFICIARY_COMMENT:     { module: 'BENEFICIARY_ENTITIES_MGT', key: 'DELETE_BENEFICIARY_COMMENT' },
  ADD_SUCCESS_STORY:              { module: 'BENEFICIARY_ENTITIES_MGT', key: 'ADD_SUCCESS_STORY' },
  REMOVE_SUCCESS_STORY:           { module: 'BENEFICIARY_ENTITIES_MGT', key: 'REMOVE_SUCCESS_STORY' },

  SAVE_LEGAL_CASES:               { module: 'BENEFICIARY_ENTITIES_MGT', key: 'SAVE_LEGAL_CASES' },

  BENEFICIARY_COMMENT_LIST:       { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_COMMENT_LIST' },
  BENEFICIARY_STATUS_HISTORY_LIST:{ module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_STATUS_HISTORY_LIST' },
  BENEFICIARY_SUCCESS_STORY_LIST: { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_SUCCESS_STORY_LIST' },
  BENEFICIARY_ACHIEVEMENTS_LIST:  { module: 'BENEFICIARY_ENTITIES_MGT', key: 'BENEFICIARY_ACHIEVEMENTS_LIST' },

  LOOKUPS_MGT:                    { module: 'LOOKUPS_MGT', key: 'LOOKUPS_MGT' },
  BENEFICIARY_STATUS_LIST:        { module: 'LOOKUPS_MGT', key: 'BENEFICIARY_STATUS_LIST' },
  DELETE_LOOKUP:                  { module: 'LOOKUPS_MGT', key: 'DELETE_LOOKUP' },
  SAVE_LOOKUPS:                   { module: 'LOOKUPS_MGT', key: 'SAVE_LOOKUPS' },
  CLASSIFICATION_MODIFY:          { module: 'LOOKUPS_MGT', key: 'CLASSIFICATION_MODIFY'},
  CLASSIFICATION_LIST:            { module: 'LOOKUPS_MGT', key: 'CLASSIFICATION_LIST'},
  CLASSIFICATION_GET:             { module: 'LOOKUPS_MGT', key: 'CLASSIFICATION_GET'},
  DELETE_CLASSIFICATION:          { module: 'LOOKUPS_MGT', key: 'DELETE_CLASSIFICATION'},

  LOGGING_ACTIVITY_LIST:          { module: 'LOGGING_ACTIVITIES_MGT', key: 'LOGGING_ACTIVITY_LIST' },

  INCIDENT_LIST:                  { module: 'INCIDENTS_MGT', key: 'INCIDENT_LIST' },
  SAVE_INCIDENT:                  { module: 'INCIDENTS_MGT', key: 'SAVE_INCIDENT' },
  DELETE_INCIDENT:                { module: 'INCIDENTS_MGT', key: 'DELETE_INCIDENT' },

  SURVEY_REPORT:                  { module: 'REPORTS_MGT', key: 'SURVEY_REPORT' },

  DELETE_FILE:                    { module: 'FILE_MGMT', key: 'DELETE_FILE' },
  DOWNLOAD_FILE:                  { module: 'FILE_MGMT', key: 'DOWNLOAD_FILE' },

  CUSTOM_REPORT_LIST:             { module: 'REPORTS_MGT', key: 'CUSTOM_REPORT_LIST' },
  CUSTOM_REPORT_GET:              { module: 'REPORTS_MGT', key: 'CUSTOM_REPORT_GET' },
  CUSTOM_REPORT_MODIFY:           { module: 'REPORTS_MGT', key: 'CUSTOM_REPORT_MODIFY' },
  CUSTOM_REPORT_DELETE:           { module: 'REPORTS_MGT', key: 'CUSTOM_REPORT_DELETE' },
  CUSTOM_REPORT_GENERATE:         { module: 'REPORTS_MGT', key: 'CUSTOM_REPORT_GENERATE' },
  ASSESSMENT_REPORT:              { module: 'REPORTS_MGT', key: 'ASSESSMENT_REPORT' },

  ASSESSMENTS_LIST:               { module: 'ASSESSMENT_MGMT', key: 'ASSESSMENTS_LIST' },
  ASSESSMENTS_GET:                { module: 'ASSESSMENT_MGMT', key: 'ASSESSMENTS_GET' },
  ASSESSMENTS_MODIFY:             { module: 'ASSESSMENT_MGMT', key: 'ASSESSMENTS_MODIFY' },
  ASSESSMENTS_BENEFICIARY_LINK:   { module: 'ASSESSMENT_MGMT', key: 'ASSESSMENTS_BENEFICIARY_LINK' },
  ASSESSMENTS_ACTIVITY_LINK:      { module: 'ASSESSMENT_MGMT', key: 'ASSESSMENTS_ACTIVITY_LINK' },
  ASSIGN_USER_ASSESSMENT:         { module: 'ASSESSMENT_MGMT', key: 'ASSIGN_USER_ASSESSMENT' }, 
}

export enum UserRolesEnum {
    SYSTEM_ADMIN = 'SYSTEM_USER',
    APPLICANT = 'APPLICATION_USER',
    WFP_ADMIN = 'WFP_USER',
    SUPERVISOR = 'SUPERVISOR_USER',
    SURVEYOR = 'SURVEYOR_USER',
    SUPPORT_ADMIN_USER = 'SUPPORT_ADMIN_USER',
    SUPPORT_OPERATOR_USER = 'SUPPORT_OPERATOR_USER',
    NPTP_USER = 'NPTP_USER',
    CI_INSPECTOR_USER = "CI_INSPECTOR_USER",
    MEHE_USER = 'MEHE_USER'
}

export enum UserRolesActionsEnum {
    LIST = 'LIST',
    GET = 'GET',
    STATUS_UPDATE = 'STATUS_UPDATE',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    SCHEDULE = 'SCHEDULE',
    REVIEW = 'REVIEW',
    AUTHORIZE = 'AUTHORIZE',
    EXECUTE = 'EXECUTE',
    MODERATE = 'MODERATE',
    VIEW = 'VIEW'
}
