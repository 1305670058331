import { formatDate } from '@angular/common';
import { Component, Input, ViewChild, Injectable, Self, SimpleChanges, OnChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';


export const PICK_FORMATS = {
  parse: { dateInput: { month: 'numeric', year: 'numeric', day: 'short' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'short' },
    monthYearA11yLabel: { year: 'long', month: 'long' }
  }
};

@Injectable()
export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'dd MM yyyy', 'ar');
    } else {
      return date.toDateString();
    }
  }
}
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.sass'],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
  ]
})
export class DatepickerComponent implements  ControlValueAccessor, OnChanges {
  @ViewChild('picker') picker: MatDatepicker<any>;
  @Input() startView: string = 'month';
  @Input() onlyYear: boolean = false;
  @Input() onlyMonth: boolean = false;
  @Input() minDate = null;
  @Input() maxDate = null;
  @Input() withClear = false;
  @Input() placeholdertext: string;
  clearBtnDisabled = false;


  constructor(private dateAdapter: DateAdapter<Date>, @Self() public ngControl: NgControl) {
    this.dateAdapter.setLocale('ar-LB');
    this.ngControl.valueAccessor = this;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.onlyMonth || changes.onlyYear) {
      if ((this.onlyMonth || this.onlyYear) && this.startView == 'month') {
        this.startView = 'multi-year';
      }
    }
  }

  writeValue(obj: any): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
    this.clearBtnDisabled = isDisabled;
  }

  public openPicker(): void {
    this.picker.open();
  }

  public closePicker(): void {
    this.picker.close();
  }


  fieldInvalid(): boolean {
    const field = this.ngControl.control;
    return field.touched && field.invalid;
    
  }

  fieldRequired() {
    const field = this.ngControl.control
    if(field['validator']){
      const validator =  field.validator({} as AbstractControl)
      if (validator && validator.required) {
        return true;
      }
    } return false;
  }

  clear() {
    this.ngControl.control.setValue(null);
  }

  chosenYearHandler(event) {
    if (this.onlyYear) {
      this.ngControl.control.setValue(event);
      this.picker.close();
    }
  }

  chosenMonthHandler(event) {
    if (this.onlyMonth) {
      this.ngControl.control.setValue(event);
      this.picker.close();
    }
  }
}
