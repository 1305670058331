import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAdminAuthenticationService } from '../shared/services/adminAuthentication.interface';
import { AdminModules, AdminRoleModules, menuItems } from './admin.config';
import { AdminModule } from './admin.models';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass'],
})
export class AdminComponent implements OnInit {
  innerWidth: number;
  mode: string;
  menuItems =  JSON.parse(JSON.stringify(menuItems));
  filteredMenuItems: any;
  public adminModules: AdminModule[];
  profile: string;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.mode = 'over';
    } else {
      this.mode = 'side';
    }
  }
  constructor(
    private authService: IAdminAuthenticationService
  ) { }

  ngOnInit(): void {
    // this.getAccessableModules();
  }

  // getAccessableModules() {

  //   this.profile = this.authService.getUserRole();
  //   let adminRoleModules = AdminRoleModules.find(f => f.role === this.profile);
  //   this.adminModules = adminRoleModules.modules.map(m => { return AdminModules.find(f => f.name === m) });
  //   this.adminModules.forEach(f => {
  //     if (!this.authService.isModuleAccessible(f.name)) {
  //       //remove the whole module from menuItems
  //       this.filteredMenuItems = this.menuItems.filter(item => item.link !== f.path)

  //       //remove child from menuItems
  //       this.filteredMenuItems.forEach(menuItem => {
  //         if(menuItem.children){
  //           menuItem.children = menuItem.children.filter(child => child.link !== f.path);
  //         }
  //       })
  //     }
  //   })
  // }

}
