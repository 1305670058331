<div class="title-container">
    <span class="march-page-title">{{ title }}</span>
    <div class="bottom-border">
      <button type="button" (click)="goBack(); $event.stopPropagation();" class="btn button-flat"
       [disabled]="false">
       <i class="fa fa-angle-left" aria-hidden="true"></i>
       <span  class="locate-text ">{{'siren-survey-translation.global.back' | translate}}</span>
     </button>
   </div>
  </div>
