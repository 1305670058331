import { MatTableDataSource } from '@angular/material/table';

export class CustomDataSource<T> extends MatTableDataSource<T> {

  constructor(data: T[], private additionalParam: boolean) {
    super(data);
  }

  public getAdditionalParam(): boolean {
    return this.additionalParam;
  }
}
