import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Organization } from '../../models/admin/organization/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService  extends BaseService<Organization, Organization> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/organization", http, injector);
  }

}
