import { Component, ElementRef, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { LookupModel, LookupModelDto } from '@siren-survey/app/models/shared/lookup.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { Program, ProgramActivity, ProgramOrganization, ProgramTag } from 'src/app/models/admin/program/program/program';
import { ProgramService } from 'src/app/services/admin/program/program.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { Organization } from 'src/app/models/admin/organization/organization';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { ProgramActivityExecutionDisplay } from 'src/app/models/admin/program/activities/activity-execution';
import { SurveyLinkSelectionComponent } from '../survey-link-selection/survey-link-selection.component';
import { Observable, Subscription, map, of, startWith } from 'rxjs';
import { TagLookupModel } from 'src/app/models/shared/tag.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ProgramOrganizationFormComponent } from '../program-organization-form/program-organization-form.component';

@Component({
  selector: 'app-program-form',
  templateUrl: './program-form.component.html',
  styleUrls: ['./program-form.component.sass']
})
export class ProgramFormComponent extends DropdownComparatorWithBrowserListener implements OnInit {
  @Input() isEditMode: boolean = true;

  public form: FormGroup;
  public error: string;
  public program: Program;
  public id: number;
  public index: number;

  private _userActiveOrganizations: LookupModel[] = [];
  private _canGetProgram: boolean = false;
  private _marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  private _programActivityOccurrence: Map<number, number> = new Map();

  public organizations: LookupModel[] = [];
  public classes: LookupModel[] = [];
  public activities: LookupModel[] = [];
  public beneficiaries: LookupModel[] = [];
  public tags: TagLookupModel[];

  public canModifyProgram: boolean = false;
  public hasUnsavedBeneficiariesCount: number = 0;
  public hasUnsavedClassesCount: number = 0;
  public concernedOrganization: LookupModel[] = [];
  public activityAttendees: ProgramActivityExecutionDisplay[];

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('activityPanel') activityPanel!: MatExpansionPanel;
  @ViewChild('programSurveyLink') programSurveyLink!: SurveyLinkSelectionComponent;
  @ViewChild('activitySurveyLink') activitySurveyLink!: SurveyLinkSelectionComponent;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChildren(ProgramOrganizationFormComponent) programOrganizationFormComponents: QueryList<ProgramOrganizationFormComponent>;

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public tagCtrl = new FormControl('');
  public filteredTags: Observable<TagLookupModel[]>;
  public selectedTags: TagLookupModel[] = [];

  filteredUsers: Observable<User[]>;
  lastFilter: string = '';

  loading = false;

  private _subscription: Subscription;

  constructor(
    private _programService: ProgramService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _snackbar: MatSnackBar,
    private _dialogService: DialogService,
    private readonly _translateService: TranslateService,
    private _lookupService: LookupService,
    private _authService: AuthenticationService) {
    super();
    this._userActiveOrganizations = this._authService.getSessionService().getActiveSession().getActiveOrganizations();
    this._canGetProgram = this._authService.userModuleAccessRightValidation(this._marchPermissionEnum.PROGRAM_GET);
    this.canModifyProgram = this._authService.userModuleAccessRightValidation(this._marchPermissionEnum.PROGRAM_MODIFY);
  }

  // FORM INIT
  ngOnInit(): void {
    this.goBack = this.goBack.bind(this);
    this._lookupService.getLookupObjectsService(["Classes", "Activity", "Beneficiary"], false, true).subscribe(response => {
      if (response?.body) {
        this.classes = response.body["Classes"];
        this.activities = response.body["Activity"];
        this.beneficiaries = response.body["Beneficiary"];
      }
    });

    this.createFormGroups();
    this._subscription = this._route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id && this.id > 0 && this._canGetProgram) this.getData();
    });

    this._subscription.add(
      this._route.data.subscribe((params) => {
        this.tags = params.data.lookups.Tag.map(item =>
          new TagLookupModel(undefined, new LookupModelDto(item.id, item.key, item.value, item.order, item.deprecated))
        );
        this.organizations = params.data.lookupObjects.MarchOrganization;
      })
    );

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((filteredTag: string | TagLookupModel | null) => {
        if (typeof filteredTag === 'string') {
          const tagLookupModel = new TagLookupModel(0, new LookupModelDto(0, "", filteredTag, false, 0));
          return this.tagFilter(tagLookupModel);
        } else if (filteredTag instanceof TagLookupModel) {
          return [filteredTag];
        } else {
          return this.tags.slice();
        }
      })
    );
    this._subscription.add(
      this.tagCtrl.valueChanges.subscribe(newValue => {
        if (typeof newValue === 'string') {
          const tagLookupModel = new TagLookupModel(0, new LookupModelDto(0, "", newValue, false, 0));
          this.tagFilter(tagLookupModel);
        } else if (this.isTagLookupModel(newValue)) {
          this.tagFilter(newValue);
        }
      })
    )
  }

  private isTagLookupModel(value: TagLookupModel): boolean {
    return value instanceof TagLookupModel;
  }

  public removeTag(tag: TagLookupModel): void {
    const tagsFormArray = this.form.get('programTags') as FormArray;
    const index = tagsFormArray.controls.findIndex((control) => control.value?.tag?.id === tag?.tag?.id);

    const selectedIndex = this.selectedTags.indexOf(tag);

    if (selectedIndex >= 0) {
      this.selectedTags.splice(selectedIndex, 1);
      tagsFormArray.removeAt(index);
      this.filteredTags = of(this.tags.filter(tag => !this.selectedTags.some(selectedTag => selectedTag.tag.id === tag.tag.id)));
    }
  }

  public selectTag(event: MatAutocompleteSelectedEvent): void {
    this.selectedTags.push(event.option.value);
    this.filteredTags = of(this.tags.filter(tag => !this.selectedTags.some(selectedTag => selectedTag.tag.id === tag.tag.id)));
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);

    const tagsFormArray = this.form.get('programTags') as FormArray;

    // Check if event.option.value is not undefined
    if (event.option.value) {
      const newTag = this.createProgramTagFormGroup(event.option.value);
      tagsFormArray.push(newTag);
    }
  }

  private tagFilter(filterValue: TagLookupModel): TagLookupModel[] {
    this.filteredTags = of(this.tags.filter(tag =>
      !this.selectedTags.some(selectedTag => selectedTag.tag.id === tag.tag.id) &&
      tag.tag.value.toLowerCase().includes(filterValue?.tag?.value?.toLowerCase())
    ));
    return this.tags.filter(tag => tag.tag.value.toLowerCase().includes(filterValue?.tag?.value?.toLowerCase()));
  }

  optionClicked(event: Event, tag: TagLookupModel) {
    event.stopPropagation();
    this.toggleSelection(tag);
  }

  toggleSelection(tag: TagLookupModel) {
    tag.selected = !tag.selected;
    if (tag.selected) {
      this.selectedTags.push(tag);
    } else {
      const i = this.selectedTags.findIndex(value => value.tag.id === tag.tag.id);
      this.selectedTags.splice(i, 1);
    }
  }


  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  createFormGroups() {
    this.form = new FormGroup({
      'id': new FormControl({ value: this.program?.id, disabled: true }),
      'name': new FormControl({ value: this.program?.name, disabled: !this.isEditMode }, [Validators.required, CustomValidators.noWhitespaceValidator]),
      'startDate': new FormControl({ value: this.program?.startDate, disabled: !this.isEditMode }),
      'endDate': new FormControl({ value: this.program?.endDate, disabled: !this.isEditMode }),
      'goal': new FormControl({ value: this.program?.goal, disabled: !this.isEditMode }, [Validators.required, CustomValidators.noWhitespaceValidator]),
      'isDeleted': new FormControl({ value: this.program?.isDeleted ? this.program?.isDeleted : false, disabled: true }),
      'programOrganizations': new FormArray([]),
      'programActivities': new FormArray([]),
      'programTags': new FormArray([]),
    });
    this.form?.get('isDeleted').disable();
  }

  getProgramActivityOriginalOccurrence(programActivityId: number): number {
    return this._programActivityOccurrence != undefined ? this._programActivityOccurrence.get(programActivityId) : 0;
  }

  getData() {
    if (this.id && this.id > 0) {
      this.createFormGroups();
      this._programService.getObject(this.id).subscribe(response => {
        this.program = response.body;
        this.isEditMode = !this.program.isDeleted;
        this.form.patchValue(this.program);
        this.isEditMode ? this.form.enable() : this.form.disable();
        this.patchProgramTags();
        // load activities
        if (this.program.programActivities) {
          let programActivityFormArray: FormArray = this.programActivities as FormArray;
          if (programActivityFormArray) {
            for (let progActivity of this.program.programActivities) {
              if (progActivity.id != undefined && progActivity.id > 0) this._programActivityOccurrence.set(progActivity.id, progActivity.occurrence);
              programActivityFormArray.push(this.createProgramActivityFormGroup(progActivity));
            }
          }
        }

        // load program organizations
        this.concernedOrganization = [];
        if (this.program.programOrganizations) {
          for (let progOrganization of this.program.programOrganizations) {
            if (progOrganization?.organization?.id) {
              let progOrgLookupModel: LookupModel = {
                id: progOrganization.organization.id,
                key: undefined,
                order: undefined,
                value: progOrganization.organization.name,
                deprecated: false
              };
              this.concernedOrganization.push(progOrgLookupModel);
            }
            let programOrganizationsFormArray: FormArray = this.programOrganizations as FormArray;
            if (programOrganizationsFormArray) {
              programOrganizationsFormArray.push(this.createProgramOrganizationFormGroup(progOrganization));
            }
          }
        }
      });
    }
  }

  private patchProgramTags(): void {
    if (this.form && this.program) {
      let tagsArray: FormArray = this.form.get('programTags') as FormArray;
      tagsArray.clear();
      if (this.program.programTags) {
        for (let programTag of this.program.programTags) {
          let tag = this.tags.find(tag => tag.tag.id === programTag.tag.id);
          tagsArray.push(this.createProgramTagFormGroup(tag));
        }
      }
      this.selectedTags = tagsArray.getRawValue();
      this.filteredTags = of(this.tags.filter(tag => !this.selectedTags.some(selectedTag => selectedTag.tag.id === tag.tag.id)));
      // this.filteredTags = of(this.tags.map(tag => {
      //   const isSelected = this.selectedTags.some(selectedTag => selectedTag.tag.id === tag.tag.id);
      //   if (isSelected) {
      //     return { ...tag, selected: true };
      //   }
      //   return tag;
      // }));
    }
  }

  private createProgramTagFormGroup(programTag: ProgramTag): FormGroup {
    return new FormGroup({
      'id': new FormControl({ value: programTag?.id, disabled: true }),
      'tag': new FormGroup({
        'id': new FormControl({ value: programTag?.tag?.id, disabled: true }),
        'key': new FormControl({ value: programTag?.tag?.key, disabled: true }),
        'value': new FormControl({ value: programTag?.tag?.value, disabled: true }),
      })
    });
  }
  // ORGANIZATIONS MANAGEMENT

  get programOrganizations(): FormArray {
    return this.form.get('programOrganizations') as FormArray;
  }

  getProgramOrganizationByOrgId(organizationId: number): ProgramOrganization {
    let progOrg: ProgramOrganization;
    if (organizationId != undefined && this.program && this.program?.programOrganizations) {
      for (let current of this.program?.programOrganizations) {
        if (current?.organization?.id == organizationId) progOrg = current;
      }
    }
    return progOrg
  }

  getOrganizationName(id: number): string {
    let title = "";
    if (this.organizations) {
      for (let curr of this.organizations) {
        if (curr.id == id) {
          title = curr.value;
          const splitText = title.split("MARCH");
          if (splitText.length > 1) {
            title = this._translateService.instant('program.fields.beneficiaries-of') + splitText[1];
          }
        }
      }
    }
    return title;
  }

  addProgramOrganizationForOrganization(organization: Organization) {
    if (organization != undefined) {
      let programOrganizationFormArray: FormArray = this.programOrganizations as FormArray;
      let progOrg: ProgramOrganization;
      if (organization.id) progOrg = this.getProgramOrganizationByOrgId(organization.id);
      if (progOrg == undefined) {
        progOrg = {
          id: undefined,
          organization: organization,
          programBeneficiaries: [],
          programClasses: []
        };
      }
      if (programOrganizationFormArray) programOrganizationFormArray.push(this.createProgramOrganizationFormGroup(progOrg));
    }
  }

  removeProgramOrganization(index: number, event: MatCheckboxChange) {
    this._dialogService.confirmDialog({
      "title": this._translateService.instant('popup-module.titles.remove-organization'),
      "message": this._translateService.instant('popup-module.messages.organization-remove'),
      "confirmText": this._translateService.instant('popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('popup-module.buttons.no')
    })
      .subscribe(response => {
        if (response) {
          let programOrganizationFormArray: FormArray = this.programOrganizations as FormArray;
          if (programOrganizationFormArray) programOrganizationFormArray.removeAt(index);
        } else {
          if (event.source != undefined) event.source.checked = true;
        }
      });
  }

  createProgramOrganizationFormGroup(progOrg: ProgramOrganization): FormGroup {
    let isEditableProgOrgData: boolean = this.isEditMode && this.hasAccessToOrganization(progOrg?.organization?.id);
    let programOrgFormGroup: FormGroup = new FormGroup({
      'id': new FormControl({ value: progOrg?.id, disabled: true }),
      'organization': new FormControl({ value: progOrg?.organization, disabled: !isEditableProgOrgData }, Validators.required),
      'programClasses': new FormArray([]),
      'programBeneficiaries': new FormArray([]),
      'title': new FormControl({ value: this.getOrganizationName(progOrg?.organization?.id), disabled: true })
    });
    return programOrgFormGroup;
  }

  // ACTIVITIES MANAGEMENT

  get programActivities(): FormArray {
    return this.form.get('programActivities') as FormArray;
  }

  addProgramActivity() {
    let programActivityFormArray: FormArray = this.programActivities as FormArray;
    if (programActivityFormArray) {
      programActivityFormArray.push(this.createProgramActivityFormGroup(undefined));
      this.activityPanel.open();
    }
  }

  removeProgramActivity(index: number) {
    this._dialogService.confirmDialog({
      "title": this._translateService.instant('popup-module.titles.remove-activity'),
      "message": this._translateService.instant('popup-module.messages.activity-remove'),
      "confirmText": this._translateService.instant('popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('popup-module.buttons.no')
    })
      .subscribe(response => {
        if (response) {
          let programActivityFormArray: FormArray = this.programActivities as FormArray;
          if (programActivityFormArray) programActivityFormArray.removeAt(index);
        }
      });
  }


  createProgramActivityFormGroup(progActivity: ProgramActivity): FormGroup {
    return new FormGroup({
      'id': new FormControl({ value: progActivity?.id, disabled: true }),
      'activity': new FormControl({ value: progActivity?.activity, disabled: !this.isEditMode }, Validators.required),
      'occurrence': new FormControl({ value: progActivity?.occurrence, disabled: !this.isEditMode }, Validators.required),
      'fromDate': new FormControl({ value: progActivity?.fromDate, disabled: !this.isEditMode }),
      'toDate': new FormControl({ value: progActivity?.toDate, disabled: !this.isEditMode }),
      'deletable': new FormControl({ value: progActivity?.deletable, disabled: !this.isEditMode })
    });
  }

  onKeyDownEvent(event: any) {
    if (event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }

  // FORM MANAGEMENT

  editable(): boolean {
    return this.isEditMode && this.canModifyProgram;
  }

  validateFormForSave(): string {
    let error: string = undefined;
    if (this.form) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      let rawValue = this.form.getRawValue();
      let nameValue = this.form.controls["name"].getRawValue();
      if (!nameValue || nameValue == undefined) error = this._translateService.instant('popup-module.error.empty');
      if (!error) error = this.validateProgramOrganization();
      if (!error) error = !this.form.valid
        ? this._translateService.instant('popup-module.error.invalid')
        : undefined;
    }
    if (!error) error = this.validateActivityFromToDates();
    return error;
  }

  validateProgramOrganization(): string {
    let programOrganizationFormArray: FormArray = this.programOrganizations as FormArray;
    return programOrganizationFormArray == undefined || programOrganizationFormArray.length == 0
      ? this._translateService.instant('popup-module.error.not-selected-organization')
      : undefined;
  }

  validateActivityFromToDates(): string {
    let error: string;
    let programActivityFormArray: FormArray = this.programActivities as FormArray;
    if (programActivityFormArray) {
      let programStartDate: Date = this.formatDateOnly(this.form.get('startDate').getRawValue() as Date);
      let programEndDate: Date = this.formatDateOnly(this.form.get('endDate').getRawValue() as Date);
      if (programEndDate != undefined && programStartDate != undefined && programStartDate > programEndDate) error = this._translateService.instant('popup-module.error.program-dates');
      let progActivitiesList = programActivityFormArray.getRawValue();
      for (let progActivity of progActivitiesList) {
        if (!error) {
          let fromDate: Date = progActivity.fromDate != undefined ? this.formatDateOnly(progActivity.fromDate as Date) : undefined;
          let toDate: Date = progActivity.toDate != undefined ? this.formatDateOnly(progActivity.toDate as Date) : undefined;
          if (!error && fromDate != undefined && toDate != undefined && fromDate > toDate) error = this._translateService.instant('popup-module.error.activity-dates');
          if (!error && fromDate != undefined && programStartDate != undefined && fromDate < programStartDate) error = this._translateService.instant('popup-module.error.activity=program-start-dates');
          if (!error && toDate != undefined && programEndDate != undefined && toDate > programEndDate) error = this._translateService.instant('popup-module.error.activity=program-end-dates');
        }
      }
    }
    return error;
  }

  saveProgram() {
    let error = this.validateFormForSave();
    if (!error) {
      this.executeSave();
    } else {
      this._snackbar.open(error, null, { duration: 3000 });
    }
  }

  formatDateOnly(date: Date): any {
    if (date) {
      date = new Date(date);
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let year = date.getFullYear();
      return year + "-" + month + "-" + day;
    } else undefined;
  }

  executeSave() {
    this.loading = true;
    let rawValue = this.form.getRawValue();
    this.program = rawValue;
    this.program.startDate = this.formatDateOnly(this.program.startDate);
    this.program.endDate = this.formatDateOnly(this.program.endDate);
    if (this.program.programActivities) {
      for (let currProgActivity of this.program.programActivities) {
        if (currProgActivity.fromDate != undefined) currProgActivity.fromDate = this.formatDateOnly(currProgActivity.fromDate);
        if (currProgActivity.toDate != undefined) currProgActivity.toDate = this.formatDateOnly(currProgActivity.toDate);
      }
    }
    if (this.program.programOrganizations) {
      for (let progOrganization of this.program.programOrganizations) {
        if (progOrganization.programBeneficiaries) {
          for (let progBenef of progOrganization.programBeneficiaries) {
            if (progBenef.enrollmentDate != undefined) progBenef.enrollmentDate = this.formatDateOnly(progBenef.enrollmentDate);
            if (progBenef.exitDate != undefined) progBenef.exitDate = this.formatDateOnly(progBenef.exitDate);
          }
        }
      }
    }
    if (this.program.id) {
      this._programService.putObject(this.program.id, this.program).subscribe(response => {
        if (response) {
          this.hasUnsavedBeneficiariesCount = 0;
          this.hasUnsavedClassesCount = 0;
          this.program = response.body;
          this.id = this.program.id;
          this.getData();
          this._snackbar.open(this._translateService.instant('snackbar.program-updated'), null, { duration: 3000 });
        } else {
          this.error = this._translateService.instant('popup-module.error.unable-to-update');
        }
        this.loading = false;
      }, error => {
        this.error = error.error.message;
        this.loading = false;
      });
    } else {
      this._programService.postObject(this.program).subscribe(response => {
        if (response) {
          this.hasUnsavedBeneficiariesCount = 0;
          this.hasUnsavedClassesCount = 0;
          this.program = response.body;
          this.id = this.program.id;
          if (this._authService.userModuleAccessRightValidation(this._marchPermissionEnum.PROGRAM_MODIFY)) {
            this._router.navigate([('../' + this.id)], { relativeTo: this._route });
          } else {
            this._router.navigate([('../')], { relativeTo: this._route });
          }
          this.getData();
          this._snackbar.open(this._translateService.instant('snackbar.program-saved'), null, { duration: 3000 });
        } else {
          this.error = this._translateService.instant('popup-module.error.unable-to-save');
        }
        this.loading = false;
      }, error => {
        this.error = error.error.message;
        this.loading = false;
      });
    }
  }

  goBack() {
    this._router.navigateByUrl('/enrollment/program');
  }

  get isDeleted() {
    return this.form.get('isDeleted');
  }

  // onToggleSlideToggle(event: any) {
  //   this.isDeleted.setValue(!this.isDeleted.value);
  // }

  // PROGRAM ORGANIZATION SELECTORS HANDLERS
  hasAccessToOrganization(organizationId: number) {
    let hasAccess: boolean = false;
    if (this._userActiveOrganizations && organizationId != null && organizationId > 0) {
      for (let model of this._userActiveOrganizations) {
        if (model && model.id != undefined && !hasAccess) hasAccess = model.id == organizationId;
      }
    }
    return hasAccess;
  }

  isOrganizationSelected(organization: LookupModel): boolean {
    let index = this.getOrganizationIndexFromConcernedListById(organization?.id);
    return index != undefined && index >= 0;
  }

  handleOrganizationSelection(event: MatCheckboxChange, organizationLookupModel: LookupModel) {
    if (organizationLookupModel && this.concernedOrganization) {
      if (event.checked) {
        this.concernedOrganization.push(organizationLookupModel);
        let org: Organization = {
          creationDate: undefined,
          id: organizationLookupModel.id,
          index: undefined,
          name: organizationLookupModel.value,
          prefix: undefined
        }
        this.addProgramOrganizationForOrganization(org);
      } else {
        let index = this.getOrganizationIndexFromConcernedListById(organizationLookupModel.id);
        if (index != undefined && index >= 0) this.concernedOrganization.splice(index, 1);

        let arrayIndex = this.getOrganizationIndexFromFormArrayById(organizationLookupModel.id);
        if (arrayIndex != undefined && arrayIndex >= 0) this.removeProgramOrganization(arrayIndex, event);
      }
    }
  }

  getOrganizationIndexFromConcernedListById(organizationId: number): number {
    let index: number;
    if (organizationId && this.concernedOrganization) {
      let countIndex = 0;
      for (let element of this.concernedOrganization) {
        if (index) break;
        if (element?.id == organizationId) index = countIndex;
        countIndex++;
      }
    }
    return index;
  }

  getOrganizationIndexFromFormArrayById(organizationId: number): number {
    let index: number;
    let programOrganizationFormArray: FormArray = this.programOrganizations as FormArray;
    if (organizationId && programOrganizationFormArray) {
      let countIndex = 0;
      for (let element of programOrganizationFormArray.controls) {
        if (index) break;
        if (element && element?.get('organization')?.getRawValue()?.id == organizationId) index = countIndex;
        countIndex++;
      }
    }
    return index;
  }

  getConcerenedProgramOrganization(): ProgramOrganization[] {
    let programOrganizationList: ProgramOrganization[] = [];
    if (this.concernedOrganization && this.concernedOrganization.length > 0 && this.program && this.program.programOrganizations) {
      for (let progOrg of this.program.programOrganizations) {
        if (progOrg?.organization?.id) {
          let found: boolean = false;
          for (let currLookup of this.concernedOrganization) {
            if (!found && currLookup?.id && currLookup.id == progOrg?.organization?.id) {
              programOrganizationList.push(progOrg);
              found = true;
            }
          }
        }
      }
    }
    return programOrganizationList;
  }

  toogleAccordions(action: string, event: any) {
    if (action === 'open') {
      this.accordion.openAll();
      this.programSurveyLink.openAll();
      this.programOrganizationFormComponents.forEach(component => component.openAll());

    } else {
      this.accordion.closeAll();
      this.programSurveyLink.closeAll();
      this.programOrganizationFormComponents.forEach(component => component.closeAll());
    }
    event.stopPropagation();
  }

  getActivityAttendees(activityAttendees: ProgramActivityExecutionDisplay[]) {
    this.activityAttendees = activityAttendees;
  }
}

export class User {
  constructor(public firstname: string, public lastname: string, public selected?: boolean) {
    if (selected === undefined) selected = false;
  }
}
