<form [formGroup]="customReportForm" >
  <div class="container mat-focus-indicator" >
    
    <app-title [title]="'report.fields.custom-report' | translate"  [goBackCallback]="goBack"></app-title>
    
    <div class="white-background mb-4 program-form-container-header"  [ngClass]=" !_isMobile ? 'program-title-container' : '' " >
      <div class="container survey-title-container" *ngIf="!_isMobile">
        <div class="full-width-element title-box">
          <mat-form-field class="full-width-element ">
            <mat-label class="">{{'report.fields.title' | translate}}</mat-label>
            <input matInput class="" formControlName="title">
          </mat-form-field>
        </div>
        <div class="program-form-action-btn-container align-right" >
          <div class="slider-container">
            <label class="slider-label">{{'report.fields.status' | translate}}</label>
            <mat-slide-toggle class=" color-slide-toogle margin-start-20" [checked]="!disabled.value" (change)="onToggleSlideToggle($event)">
              <mat-label *ngIf="customReportForm.get('disabled').value" class=" slide-toogle-label">{{'report.fields.disabled' | translate}}</mat-label>
              <mat-label *ngIf="!customReportForm.get('disabled').value" class=" slide-toogle-label-danger">{{'report.fields.enabled' | translate}}</mat-label>
            </mat-slide-toggle>
          </div>

          <div class="slider-container">
            <label class="slider-label">{{'report.fields.program' | translate}}</label>
            <mat-slide-toggle class=" color-slide-toogle margin-start-20" formControlName="includeProgramAttendance">
              <mat-label *ngIf="customReportForm.get('includeProgramAttendance').value" class=" slide-toogle-label">{{'report.fields.program-attendance' | translate}}</mat-label>
              <mat-label *ngIf="!customReportForm.get('includeProgramAttendance').value" class=" slide-toogle-label-danger">{{'report.fields.no-program-attendance' | translate}}</mat-label>
            </mat-slide-toggle>
          </div>
          <button *ngIf='editable()' mat-flat-button color="accent" type="submit" (click)="submit()" [disabled]="!isFormDataChanged">
            <span style="white-space: nowrap;">{{'global.save' | translate}}</span>
          </button>
        </div>
      </div>
      <div class="container survey-form-container-header survey-title-container" *ngIf="_isMobile">
        <mat-accordion style="width: 100%;">
          <mat-expansion-panel (opened)="headerOpenState = true" (closed)="headerOpenState = false" style="border: none; box-shadow: none; padding: 5px">
            <mat-expansion-panel-header style="padding: 0px;">
              <div class="title-input-container">
                <mat-form-field class="full-width-element title-input flat-input" appearance="none">
                  <input matInput placeholder="{{'report.fields.title' | translate}}" formControlName="title">
                </mat-form-field>
              </div>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body style="padding: 0px;">
              <div class="survey-form-action-btn-container align-right" >
                <div class="slider-container">
                  <label class="slider-label">{{'report.fields.status' | translate}}</label>
                  <mat-slide-toggle class=" color-slide-toogle margin-start-20" [checked]="!disabled.value" (change)="onToggleSlideToggle($event)">
                    <mat-label *ngIf="customReportForm.get('disabled').value" class=" slide-toogle-label">{{'report.fields.disabled' | translate}}</mat-label>
                    <mat-label *ngIf="!customReportForm.get('disabled').value" class=" slide-toogle-label-danger">{{'report.fields.enabled' | translate}}</mat-label>
                  </mat-slide-toggle>
                </div>
                <div class="slider-container">
                  <label class="slider-label">{{'report.fields.program' | translate}}</label>
                  <mat-slide-toggle class=" color-slide-toogle margin-start-20" formControlName="includeProgramAttendance">
                    <mat-label *ngIf="customReportForm.get('includeProgramAttendance').value" class=" slide-toogle-label">{{'report.fields.program-attendance' | translate}}</mat-label>
                    <mat-label *ngIf="!customReportForm.get('includeProgramAttendance').value" class=" slide-toogle-label-danger">{{'report.fields.no-program-attendance' | translate}}</mat-label>
                  </mat-slide-toggle>
                </div>
                <button *ngIf='editable()' type="submit" (click)="submit()" class="btn btn-submit" [disabled]="!isFormDataChanged">
                  <span style="white-space: nowrap;">{{'report.fields.save-report' | translate}}</span>
                </button>
              </div>
            </mat-expansion-panel-body>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div class="white-background">
      <div *ngIf="isSubmitted">
        <app-report-element-list
          [customReportElements]="customReportElements"
          [ordersChanged]="ordersChanged"
          [customReportId]="customReportId"
          [isEditMode]="isEditMode"
          (cellListEventEmitter)="handleCellList($event)"
          class="report-element-list"></app-report-element-list>
      </div>
    </div>
  </div>
</form>
