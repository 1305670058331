import { HttpResponse } from "@angular/common/http";
import { ComponentRef } from "@angular/core";
import { Observable } from "rxjs";

export abstract class ISurveyTopicService {

  public abstract saveOrganizationSurvey(body : any): Observable<HttpResponse<any>>;
}


