<div class="login-container">
  <div class="login-bkg-image"></div>
  <div class="login-bkg-dimmer"></div>
  <div class="login-container-col">
    <img src="assets/icons/MARCH_LOGO_TRANSPARENT.svg" class="login-march-icon" />
    <form [formGroup]="form" (ngSubmit)="submit()" class="login-form">
      <div class="login-form-container">
        <div class="input-container">
          <div class="alert alert-danger custom_alert" role="alert" *ngIf="invalidLogin">
            <p>Incorrect username/password combination</p>
            <!-- <p>{{"login.login_error" | translate}}</p> -->
          </div>
          <div class="input_styling username_field mt-2">
            <mat-form-field appearance="outline" class="login-input-field">
              <!-- <mat-label>{{ "login.username" | translate }}</mat-label> -->
              <mat-label>Username</mat-label>
              <input matInput autocomplete="off" formControlName="username" class="font-weight-bold" />
            </mat-form-field>
          </div>
          <div class="input_styling mt-2">
            <mat-form-field appearance="outline" class="login-input-field">
              <!-- <mat-label>{{ "login.password" | translate }}</mat-label> -->
              <mat-label>Password</mat-label>
              <input autocomplete="off" matInput formControlName="password" [type]="showPassword ? 'text' : 'password'"
                class="font-weight-bold" />
              <mat-icon *ngIf="showPassword" matSuffix (click)="tooglePassword()"><i style="font-size: 24px;"
                  class="fa fa-eye"></i></mat-icon>
              <mat-icon *ngIf="!showPassword" matSuffix (click)="tooglePassword()"><i style="font-size: 24px;"
                  class="fa fa-eye-slash"></i></mat-icon>
            </mat-form-field>
          </div>
          <button type="submit" class="login-button" mat-raised-button color="accent">
            <img src="assets/icons/lock_white.svg" class="login-button-icon" />
            <!-- <span class="login-button-text">{{"login.login" | translate}}</span> -->
            <span class="login-button-text">Login</span>
          </button>
        </div>
        <div class="forgot_password_wrapper pointer">
          <!-- <a class="forgot_password_button" (click)="navigateTo('reset-password')">{{"login.forgot_password" | translate}}</a> -->
          <a class="forgot_password_button" (click)="navigateTo('reset-password')">Forgot your password</a>
        </div>
      </div>
    </form>
  </div>
</div>