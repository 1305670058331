import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncidentService extends BaseService<any, any> {

  constructor(http: MyHttpHandler, protected injector: Injector){
    super("/v1/incident", http, injector);
  }

  getLastDaysIncidents(): Observable<HttpResponse<any>> {
    return this.http.get<any>("/v1/incident/feed");
  }

}
