import { Inject, Injectable, Injector } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpRequestOptions } from '../../shared/models/http-request-options.model';
import { ISurveyConfig } from '../../config/survey-config.interface';
import { ISurveyAuthenticationService } from '../../shared/services/interfaces/authentication.interface';
import { BaseService } from '../shared/base-service';
import { MyHttpHandler } from '../shared/http-handler.service';
import { SettingsService } from '../../shared/services/settings.service';

@Injectable({
    providedIn: 'root'
  })
export class LookupService extends BaseService<any, any>{

  shouldFetchLookups = false;
  lookupUrlPath: string = this.settingsService.settings?.lookupUrlPath;
  lookupObjectUrlPath: string = this.settingsService.settings?.lookupObjectUrlPath;

	constructor(injector: Injector, public settingsService: SettingsService,
      protected authService: ISurveyAuthenticationService, http: MyHttpHandler) {
		super(settingsService.settings?.backendBaseUrl + settingsService.settings?.lookupUrlPath, http, injector);
	}

	/**
	 * Function used to get any lookup from the server
	 */
	public getLookupsService(types: string[], forceFetchData = false, showLoader = true): Observable<any> {
		const requestOptions = new HttpRequestOptions();
		requestOptions.headers = new HttpHeaders();
		const shouldFetchData = forceFetchData ? forceFetchData : this.shouldFetchLookups;
		requestOptions.params = shouldFetchData ? requestOptions.params = { types, 'ngsw-bypass': shouldFetchData } : { types};
		return this.http.get<any>(this.lookupUrlPath, requestOptions, showLoader);
	}

	public forceFetchLookups(shouldFetch: boolean): void {
		this.shouldFetchLookups = shouldFetch;
	}

  	/**
	 * Function used to get any object lookup from the server
	 */
	public getLookupObjectsService(types: string[], forceFetchData = false, showLoader = true): Observable<any> {
		const requestOptions = new HttpRequestOptions();
		requestOptions.headers = new HttpHeaders();
		const shouldFetchData = forceFetchData ? forceFetchData : this.shouldFetchLookups;
		requestOptions.params = shouldFetchData ? requestOptions.params = { types, 'ngsw-bypass': shouldFetchData } : { types};
		return this.http.get(this.lookupObjectUrlPath, requestOptions, showLoader);
	}

}
