
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  // this flag and subject allows to prevent multiple refresh calls in case multiple calls return 401
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private authService: AuthenticationService, private router: Router) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (request.url.includes('/v1/auth/refresh')) { // if refresh token API returned 401
            this.router.navigate(['login']); // navigate to login screen to clear the session because the refresh didn't work
            return throwError(error);
          } else if (!request.url.includes('/v1/auth/login')){
            return this.handle401Error(request, next);
          }
        } else {
          return throwError(error);
        }
		  })
    );
	}

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);
        return this.authService.refreshToken().pipe(
            switchMap(response => {
              let token = response.accessToken;
              this.isRefreshing = false;
              this.refreshTokenSubject.next(token);
              return next.handle(this.attachTokenToRequest(request, response.accessToken));
        }),
        finalize(() => this.isRefreshing = false));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null), take(1),
        switchMap(jwt => {
          return next.handle(this.attachTokenToRequest(request, jwt));
        })
      );
    }
  }

  private attachTokenToRequest(request: HttpRequest<any>, token: any): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}

/*


import { Observable, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SpinnerService } from '@siren-auth/app/shared/services/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PageBaseClass } from '../base/page-base-class';

@Injectable()
export class ResponseInterceptor extends PageBaseClass implements HttpInterceptor {

	constructor( //public auth: AuthenticationService,
		public snackBar: MatSnackBar,
		public translate: TranslateService,
		public injector: Injector,
		public dialog: MatDialog) {
    super(injector);
  }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const redirectOnError = (req.headers.get('redirectOnError') === 'true') ? true : false;
		return next.handle(req).pipe(catchError(response => {
			if (response instanceof HttpErrorResponse) {
        const body = req.method === 'GET' ? req.urlWithParams : JSON.stringify(req.body);
				if (response.status === 400) {
					this.snackBar.open(response.error.message, null, { duration: 3000 });
					// this.spinnerService.emptyRequests();
				} else if (response.status === 409) {
					this.snackBar.open(response.error.message, null, { duration: 2500 });
					// this.spinnerService.emptyRequests();
				} else if (response.status === 401) {
					this.openAlertEndSession();
					// this.spinnerService.emptyRequests();
				} else if (response.status === 500 || response.status === 0) {
					this.snackBar.open(this.translate.instant('Something went wrong!'), null, { duration: 2500 });
				} else if (response.status === 403 || response.status === 404) {
					// this.spinnerService.emptyRequests();
					if(redirectOnError) {
						this.router.navigate(['/error'],
							{state: { message: response.status === 403 ? 'error.error_msg_403' : 'error.error_msg_404',
									  imageName: response.status === 403 ? 'code-403.svg' : 'code-404.svg'}});
					}
				}
				const errorObj = this.setAPIErrorObject(req, response, body);
				if(!redirectOnError) {
					return throwError(errorObj);
				}
      } // TODO: Implement the error handling logic here
			return throwError(response);
		}));
	}

  private setAPIErrorObject(req, response, body) {
    return {
     'type': "Calling API",
     'apiEndPoint': req.url,
     'method':req.method,
     'status':response?.status?.toString(),
     'message': response?.error?.message?.toString(),
     body
   };
  }

  public openAlertEndSession(): void {
    const dialogRef = this.dialog.open(AlertDialog, {
      disableClose: true,
      width: '50%',
      data: {
        title: this.translate.instant('dialog.session_ended')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['login']);
        // this.spinnerService.popScreenLoading();
      }
    });
  }
}


*/
