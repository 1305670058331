import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MarchUserService, UserActivity } from 'src/app/services/admin/march-user.service';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.sass']
})
export class UserActivityComponent implements OnInit {
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  form: FormGroup;

  userList: LookupModel[] = [];
  selectedUserId: number;

  hasRight: boolean = false;

  activitiesList: UserActivity[] = [];

  currentPage: number = 0;
  maxPage: number = 0;
  pageSize: number = 10;

  from: Date;
  to: Date;

  constructor(public service: MarchUserService, public snackBar: MatSnackBar, public authService: AuthenticationService) {
    this.form = new FormGroup({
      'lookup': new FormControl({value: undefined, disabled: false}),
    });

    this.hasRight = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.LOGGING_ACTIVITY_LIST);
  }

  ngOnInit(): void {
    this.service.getUserLookupList().subscribe(response => {
      if(response && response.body) this.userList = response.body;
    });
  }

  changePageSize(event: any){
    this.resetPagination();
    if(event && event.value != undefined) this.pageSize = event.value;
    this.getPageData();
  }

  resetPagination(){
    this.currentPage = 0;
    this.maxPage = 0;
    this.pageSize = 10;
  }

  lookupChanged(event: any){
    this.resetPagination();
    this.from = undefined;
    this.to = undefined;
    this.activitiesList = [];
    this.selectedUserId = event.value.id;
    this.getPageData();
  }

  getPageData(){
    if(this.selectedUserId != undefined && this.selectedUserId > 0){
      if(this.currentPage == undefined) this.currentPage = 0;
      if(this.pageSize == undefined) this.pageSize = 10;
      this.service.getUserActivities(this.selectedUserId, this.getFromDateStringValue(), this.getFromToateStringValue(), this.currentPage, this.pageSize).subscribe(response => {
        if(response && response.body) {
          this.activitiesList = response.body.content;
          this.maxPage = response.body.totalPages;
        }
      });
    }
  }

  getNextPage(){
    if(this.currentPage < this.maxPage -1){
      this.currentPage++;
      this.getPageData();
      // this.service.getUserActivities(this.selectedUserId, this.getFromDateStringValue(), this.getFromToateStringValue(), this.currentPage, this.pageSize).subscribe(response => {
      //   if(response && response.body) this.activitiesList = response.body;
      // });
    }
  }

  getPreviousPage(){
    if(this.currentPage > 0){
      this.currentPage--;
      this.getPageData();
      // this.service.getUserActivities(this.selectedUserId, this.getFromDateStringValue(), this.getFromToateStringValue(), this.currentPage, this.pageSize).subscribe(response => {
      //   if(response && response.body) this.activitiesList = response.body;
      // });
    }
  }

  // hasMorePages(){
  //   return this.currentPage < this.maxPage;
  // }

  getFromDateStringValue(): string{
    return this.formatDateOnly(this.from);
  }

  getFromToateStringValue(): string{
    return this.formatDateOnly(this.to);
  }

  formatDateOnly(date: Date): string{
    if(date){
      date = new Date(date);
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let year = date.getFullYear();
      return year + "-" + month + "-" + day;
    } else undefined;
  }

  getFormattedJsonForBody(value: string): any{
    try{
      return JSON.parse(value);
    }
    catch(e) {
    };
    return "N/A";
  }
}

