import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { SurveyResponseList } from "../../../models/response/survey-response-list";
import { DataTableModel } from "../../../models/shared/cell-data-model";
import { TableAction } from "../../../models/shared/table-action";
import { TableFilterElement, TableFilterTypeEnum, FilterOperator, TableFilterOutput } from "../../../models/shared/table-filter.model";
import { SurveyResponseService } from "../../../services/survey-response.service";
import { SurveyService } from "../../../services/survey.service";
import { MenuActionObject, MenuActionEmittedObject } from "../../../models/shared/datatable-objects.model";
import { ISurveyAuthenticationService } from "../../../shared/services/interfaces/authentication.interface";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ISurveyResponseClientService } from "../../../shared/services/interfaces/survey-response-client.interface";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-response-table',
  templateUrl: './response-table.component.html',
  styleUrls: ['./response-table.component.sass']
})
export class ResponseTableComponent implements OnInit, OnDestroy {

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  canCreateResponse: boolean = false;

  canAdd: boolean = false;
  hasListAccess: boolean = false;
  consumerPermissionEnum;

  pageTitle: string;
  private _subscription = new Subscription();

  _booleanIconColumnDisplayList: string[] = [
    "enabled", "published"
  ]

  _tableColumns: DataTableModel<SurveyResponseList>[] = [
    //{  columnDef: 'title', headerTranslationPath: "siren-survey-translation.survey-response-form.title", header: 'Title',  cell: (element: SurveyResponseList) => `${element?.survey.title}`, width: undefined},
    { columnDef: 'description', headerTranslationPath: "siren-survey-translation.survey-response-form.description", header: this._translateService.instant('siren-survey-translation.survey-response-form.description'), cell: (element: SurveyResponseList) => `${element.survey.description}`, width: undefined, contentClass: 'leftAlign' },
    { columnDef: 'totalGrade', headerTranslationPath: "siren-survey-translation.survey-response-form.total-grade", header: this._translateService.instant('siren-survey-translation.survey-response-form.total-grade'), cell: (element: SurveyResponseList) => `${element.totalGrade}`, width: undefined },
    { columnDef: 'submitDateTime', headerTranslationPath: "siren-survey-translation.survey-response-form.fields.submit-date", header: this._translateService.instant('siren-survey-translation.survey-response-form.submit-date'), cell: (element: SurveyResponseList) => `${element.submitDate ? this._datePipe.transform(element.submitDate, 'dd / MM / yyyy') : "-"}`, width: undefined },
    { columnDef: '_menu_inline', headerTranslationPath: "", header: '', cell: undefined, width: '25px' }
  ];

  filters: TableFilterElement[] = [
    // { name: "survey.title", translationPath: "siren-survey-translation.survey-response-form.title", displayName: "Title", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "survey.description", translationPath: "siren-survey-translation.survey-response-form.description", displayName: "Description", type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "totalGrade", translationPath: "siren-survey-translation.survey-response-form.total-grade", displayName: "Total Grade", type: TableFilterTypeEnum.Number, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    // { name: "submitDateTime", translationPath: "survey-form.fields.submit-date", displayName: "Submit Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "submitDate", translationPath: "siren-survey-translation.survey-response-form.submit-date", displayName: "Submit Date", type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal, cssClass: 'col-xs-12 col-sm-6 col-md-4' },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/delete-icon.svg" }
  ]

  public surveyId: number;
  _sortingDataAccessor: (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate: (data: any, filter: string) => boolean;

  constructor(
    public surveyService: SurveyService,
    public responseService: SurveyResponseService,
    private router: Router,
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService,
    private route: ActivatedRoute,
    private _datePipe: DatePipe,
    public authenticationService: ISurveyAuthenticationService,
    private clientResponseService: ISurveyResponseClientService
  ) {
    this.surveyId = this.route.snapshot.params['surveyId'];
    this.responseService.setFatherSurveyId(this.surveyId);

    this.consumerPermissionEnum = this.authenticationService.getPermissionEnumeration();
    this.hasListAccess = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SURVEY_RESPONSE_LIST);
    this.canAdd = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.SAVE_SURVEY_RESPONSE);
    this._tableMenuAction.forEach(column => {
      if (column.actionName == 'DELETE_ACTION') column.allowedAction = this.authenticationService.userModuleAccessRightValidation(this.consumerPermissionEnum.DELETE_SURVEY_RESPONSE);
    });

    this._sortingDataAccessor = (item: any, property: string) => {
      switch (property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string) => {
      const accumulator = (currentTerm: any, key: any) => {
        return currentTerm + data[key];
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.clientResponseService.getResponseTableAdditionalColumns().forEach(element => {
      this._tableColumns.unshift(element);
    });
  }


  ngOnInit(): void {
    this.surveyId = this.route.snapshot.params['surveyId'];
    this.setCanCreateResponse();
    this._subscription.add(
      this.responseService.data$.subscribe(data => {
        const surveyContent = data["content"];
        const surveyName = this.route.snapshot.params['surveyName'];
        const translationKey = 'siren-survey-translation.response-table.title';
        const translatedTitle = this._translateService.instant(translationKey);
        if (surveyContent.length > 0) {
          this.pageTitle = surveyContent[0].survey.title;
        } else if (surveyName && surveyName.includes('create-response')) {
          this.pageTitle = translatedTitle;
        } else if (surveyName) {
          this.pageTitle = `${surveyName} - ${translatedTitle}`;
        } else {
          this.pageTitle = translatedTitle;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction) {
    if (event.mode == TableAction.EDIT_MODE) {
      this.router.navigate([event.id], { relativeTo: this.route });
    } else if (event.mode == TableAction.CREATE_MODE) {
      this.router.navigate(["create-response"], { relativeTo: this.route });
    }
  }

  applyFilter(event: TableFilterOutput) {
    if (event) {
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        // if(filter.value.includes('submitDate')){
        //   let submitDate = filter.value.split('submitDate:')[1];
        //   let submitDateFormatted = this.datePipe.transform(submitDate, ' yyyy-MM-dd');
        //   filter.value = 'submitDate:'+submitDateFormatted;
        // }

        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject) {
    if (menuAction && menuAction.actionName && menuAction.object) {
      if (menuAction.actionName == "DELETE_ACTION") {
        this.responseService.deleteObject(menuAction.object.id).subscribe(response => {
          this.reloadEventSubject.next(true);
        }, error => {
          let message = error && error.error && error.error.message.includes("ConstraintViolationException")
            ? this._translateService.instant('error.record-depending-error')
            : this._translateService.instant('error.record-error');
          this._snackbar.open(message, null, { duration: 3000 });
        });
      }
    }
  }

  setCanCreateResponse() {
    if (this.surveyId) {
      this.surveyService.isSurveyActive(this.surveyId).subscribe(response => {
        this.canCreateResponse = response.body && this.canAdd;// add -- canAdd access permission
      });
    }
  }
}
