// import { DatePipe } from '@angular/common';

export class TableFilterElement{
  name: string;
  displayName: string;
  translationPath: string;
  type: TableFilterTypeEnum;
  operator: FilterOperator;
  lookupName: string;
  lookupsValueById: boolean;
  value: any;
  valueFrom: any;
  valueTo: any;
  values: any[];
  visible?: boolean = true;
  cssClass?: string;
  staticValue?: { [key: string]: string } = {};
  isMultiSelect?: boolean = false;
}

export enum FilterOperator{
  "Like"='~',
  "Equal"=':',
  "Not Equal"='!',
  "Greater Than"='>',
  "Greater Or Equal"='>=',
  "Less Than"='<',
  "Less Or Equal"='<='
}

export class TableFilter{
  filterComponents: TableFilterElement[] = [];
}

export class TableFilterOutputElement{
  name: string;
  value: any;
}

export class TableFilterOutput{
  filterComponents: TableFilterOutputElement[] = [];
}

export enum TableFilterTypeEnum{
  String="String",
  Number="Number",
  Boolean="Boolean",
  Date="Date",
  DateRange="DateRange",
  // Year="Year",
  Month="Month",
  Lookup="Lookup",
  LookupObject="LookupObject",
  LookupObjectEnumeration="LookupObjectEnumeration",
  BenefScores="BenefScores",
  Static="Static"
}
