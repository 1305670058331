export enum Microservice {
  ADMIN = 'moj_admin',
  BASE = 'impact_aid_card/aid_card/v1',
  BO = 'impact_aid_card_bo/aid_card_bo/v1',
  CI = 'impact_aid_card_bo/aid_card_bo/v1/ciaudit',
  AUTH = 'impact_aid_card_auth_bo/auth/v2',
  CONFIG = 'impact_aid_card_bo/aid_card_config/v1',
  Flyway = 'impact_aid_card_bo/aid_card_bo/v1',
  NEWS = 'moj_news',
  MEASURES = 'moj_ministerial_measure',
  HR = 'moj_hr',
  SMS = 'impact_aid_card_sms',
  // SECUIRITY = 'impact_aid_card_auth/security_mgt/v1',
  SECUIRITY = '/v1/security_mgt',
  CAMPAIGNS = 'siren_campaign',
  // PAYMENT = 'impact_aid_payment/aid_payment/v1',
  // PRIORITIZATION = 'impact_aid_card_prioritization'
}

//'https://moj.sirenanalytics.com/mojservices/moj_news',

//'https://moj.sirenanalytics.com/mojservices/
//moj_news
