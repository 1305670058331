<div class="right-div">
  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)" *ngIf="!(this.singleBlobObject && this.files?.length > 0 ) && showUploadPanel">
    <div class="text-wrapper">
      <div class="upload-centered">
        <input type="file" class="hide" name="file{{fileIdForUnicity}}" id="file{{fileIdForUnicity}}" (change)="onFileChange($event.target?.files)" multiple
          [accept]="getAllowedExtensions()">
        <label for="file{{fileIdForUnicity}}" class="center-label">
          <span class="textLink">
            <b>{{ 'blob.select-files' | translate }}.</b>
            <p *ngIf="getAllowedExtensionsConfig()" style="padding-top: 10px">{{ 'blob.allowed-extensions' | translate }} {{ getAllowedExtensionsConfig() }}</p>

            <p *ngIf="getAllowedExtensionsConfig()">{{ 'blob.max-size' | translate }} {{ maxFileSizeInMb }}{{ 'snackbar.mb' | translate }}</p>
          </span>
          <!-- <br *ngIf="getAllowedExtensionsConfig()"/> -->
          <!-- <span *ngIf="getAllowedExtensionsConfig()" class="textLink" style="background-color: var(--app-data-card-list-label-color);">The allowed extensions are: {{ getAllowedExtensionsConfig() }}</span> -->
        </label>
      </div>
    </div>
  </div>
  <hr *ngIf="showUploadPanel">
  <app-blob-file  [files]="files" [canDeleteFile]="canDeleteFile"  [canDownloadFile]="canDownloadFile"  [showPreviewPanel]="showPreviewPanel"  [displayMode]="displayMode" [hideTitle]="showUploadPanel" [pendingUploadFileList]="pendingUploadFileList" (filesUpdated)="updateFiles($event)" (pendingUploadFileListUpdated)="updatePendingUploadFileList($event)"></app-blob-file>
</div>
<div class="page-footer" *ngIf="!singleBlobObject">
  <span style="margin-inline-end: 15px;" >{{ 'global.total-responses' | translate}} {{ totalElements }}</span>
  <mat-form-field style="width: 100px; margin-inline-end: 10px;">
    <mat-label class="" >{{ 'global.page-size' | translate}}</mat-label>
    <mat-select class="" [(value)]="pageSize" (selectionChange)="changePageSize($event)">
      <mat-option [value]="5">5</mat-option>
      <mat-option [value]="10">10</mat-option>
      <mat-option [value]="20">20</mat-option>
      <mat-option [value]="50">50</mat-option>
      <mat-option [value]="100">100</mat-option>
      <mat-option [value]="150">150</mat-option>
      <mat-option [value]="200">200</mat-option>
    </mat-select>
  </mat-form-field>
  <button *ngIf="currentPage > 0" type="button" (click)="getPreviousPage()" class="btn btn-previous-page">
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
  </button>
  <span>{{ 'global.page' | translate}} {{ currentPage + 1 }} {{ 'global.out-of' | translate}} {{ maxPage }}</span>
  <button *ngIf="currentPage < maxPage -1" type="button" (click)="getNextPage()" class="btn btn-next-page">
    <i class="fa fa-arrow-right" aria-hidden="true"></i>
  </button>
</div>
