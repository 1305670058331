<div [formGroup]="form">
  <div class="dialog-header">
    <span>{{ 'activity-execution.fields.title' | translate }}</span>
  </div>
  <div class="dialog-container">
    <div>
      <div>
        <mat-form-field class="full-width-element">
          <mat-label class="">
            {{ 'activity-execution.fields.date-range' | translate }}
          </mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="fromDate">
            <input matEndDate formControlName="toDate">
          </mat-date-range-input>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <!-- Activity TAGS -->
    <div class="col-12 attendees-div">
      <mat-form-field  class="chip-list full-width-element">
        <mat-label>{{'activity-execution.fields.tags' | translate}}</mat-label>
        <mat-chip-grid #chipGrid aria-label="Tag selection">
          <mat-chip-row *ngFor="let selectedTag of selectedTags" (removed)="removeTag(selectedTag)">
            {{selectedTag.tag.value}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectedTag">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="{{'activity-execution.fields.new-tag' | translate}}" #tagInput [formControl]="tagCtrl"
          [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
            <mat-option *ngFor="let filteredTag of filteredTags | async" [value]="filteredTag">
              {{filteredTag.tag.value}}
            </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="attendees-div">
      <div class="title-execution">
        <h5 class="attendees-title">{{'activity-execution.fields.activity-attendees' | translate}}</h5>
        <span class="beneficiary-selection" *ngIf="noSelections">{{ 'activity-execution.fields.select-beneficiary' | translate }}</span>
      </div>

      <div class="selector-panel-container">
        <div *ngFor="let programOrganization of programOrganizations; let i = index;"
          class="organization-benef-container"
          [ngClass]="(i + 1) < programOrganizations?.length ? 'not-last-container': ''">
          <span class="org-name-font">{{programOrganization.organization.name }}</span>
          <!-- <app-multiple-searchable-selection
            [placeHolder]="'dashboard.fields.tags' | translate"
            formControlName="tagsIds"
            [lookups]="programBeneficiaries"
            [formSubmitted]="formSubmitted"
            [formControl]="form.get('tagsIds')"
          ></app-multiple-searchable-selection> -->
          <app-program-beneficiary-selection-list [embedded]="true" [programId]="programId"
            [programOrganizationId]="programOrganization.id" [showTitle]="false" [selectedElements]="selectedElements" (selectedBeneficiaries)="handleSelectedBeneficiaries($event)">
          </app-program-beneficiary-selection-list>
        </div>
      </div>
    </div>
    <div class="dialog-button-container">
      <button *ngIf="editable()" type="button" (click)="saveActivityExecution()" class="btn btn-submit">
        <span class=" button-label">{{'global.save' | translate}}</span>
      </button>
    </div>
  </div>
</div>
