import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { FormControl, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.sass']
})

export class ContentHeaderComponent implements OnInit {

  @ViewChild(MatExpansionPanel) matExpansionPanelElement: MatExpansionPanel;
  @Input() titleKey : string = "siren-admin-translation.admin_module.list_title";
  @Input() addLabel : string = "siren-admin-translation.admin_module.add_choice";
  @Input() showAddButton : boolean = false;
  @Input() showBackButton : boolean = false;
  @Input() withFilter: boolean = false;

  @Output() newRecord = new EventEmitter<void>();
  form: FormGroup;

  constructor(
    private location: Location
  ) { }

  newClicked(): void {
    this.newRecord.emit();
  }

  backClicked() : void {
    this.location.back();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      filter: new FormControl(null)
    });
  }

  togglePanel() {
    this.matExpansionPanelElement.toggle();
  }
}
