import { Injectable, Injector } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { HttpStatusCodes, SivisOTPModel } from './sivis-otp.model';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '../shared/session.service';

@Injectable({
  providedIn: 'root',
})
export class SivisService {

  constructor(
    private http: HttpClient,
    protected injector: Injector,
    private _settingsService: SettingsService,
    private sessionService: SessionService,) {
  }

  public getOTP(): Observable<string> {
    return of(this.sessionService.getActiveSession()).pipe(
      take(1),
      map((activeSession) => {
        if (!activeSession) {
          throw new Error("Active session not found");
        }
        return {
          accessToken: activeSession.getAccessToken(),
          refreshToken: activeSession.getRefreshToken(),
          username: activeSession.getUserProfile().username,
          accessTokenExpiresIn: activeSession.getAccessTokenExpiresIn(),
          refreshTokenExpiresIn: activeSession.getRefreshTokenExpiresIn(),
          data: undefined, // [{"filterkey": "Caza","filterValue": "Maten"},{"filterkey": "QuarryCode","filterValue": "Charbin5" }]
        };
      }),
      switchMap((body) => {
        return this.http.post<SivisOTPModel>(
          this._settingsService.settings.sivisOTPproviderURL,
          body
        );
      }),
      map((result: any) => {
        if (result && result.code != null) {
          return result.code;
        }
        return null;
      }),
      catchError((error) => {
        return throwError(error);
      }));
  }
}
