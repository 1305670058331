import { Injectable, Injector } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PageBaseClass } from '../base/page-base-class';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';

@Injectable()
export class AuthGuard extends PageBaseClass implements CanLoad {
  constructor(private readonly authenticationService: AuthenticationService, public injector: Injector) {
      super(injector);
  }

  // TODO: add refresh
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    const currentRoute = window.location.href;
    if (this.authenticationService.checkIfUserIsAvailable()) {
        return true;
    } else if (this.authenticationService.getSessionService().isRefreshTokenValid()) {

      return timer(1).pipe(switchMap(() => this.authenticationService.refreshToken().pipe(map(data => true
        ), catchError(err => {
            this.router.navigate(['/login']);
            return of(false);
        }))));
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
