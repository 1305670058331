import { Injectable } from "@angular/core";
import { ISessionService } from "@siren-survey/app/services/shared/interfaces/session-service.interface";
import { Subject } from "rxjs";
import { Authority, LoginResponse } from "src/app/models/auth/login-response.model";
import { SessionModel } from "src/app/models/shared/session.model";
import { UserProfile } from "src/app/models/shared/user-profile.model";

@Injectable({
    providedIn: 'root'
  })
export class SessionService implements ISessionService{

  public static readonly FIVE_MINUTES_IN_SECONDS : number = 300;

  sessonNotifier : Subject<SessionModel> = new Subject<SessionModel>();

  constructor(){
  }

  public getActiveLanguage(): string{
    return localStorage.getItem('activeLanguage');
  }

  public setActiveLanguage(lang: string){
    localStorage.setItem('activeLanguage', lang);
  }

  public getActiveSession() : SessionModel{
    return Object.assign(new SessionModel(), JSON.parse(localStorage.getItem('activeSession')));
  }

  public isAccessTokenValid(addition? : number) : boolean{
    let valid: boolean = false;
    try{
        let sessionStart : number = this.getSessionActionDateMillis();
        let tokenLifetime : number = Number(this.getActiveSession()?.getAccessTokenExpiresIn()) * 1000;
        let tokenExpiry : number = tokenLifetime + sessionStart;
        let periodToAdd = 0;
        if(addition && addition > 0) periodToAdd = addition * 1000;
        let dateToCompare = Date.now() + periodToAdd;
        valid = tokenExpiry ? dateToCompare < tokenExpiry : false;
    } catch(ex){
        console.error(ex);
    }
    return valid;
  }

  public isRefreshTokenValid(addition? : number) : boolean{
    let valid: boolean = false;
    try{
        let sessionStart : number = this.getSessionActionDateMillis();
        let tokenLifetime : number = Number(this.getActiveSession()?.getRefreshTokenExpiresIn()) * 1000;
        let tokenExpiry : number = tokenLifetime + sessionStart;
        let periodToAdd = 0;
        if(addition && addition > 0) periodToAdd = addition * 1000;
        let dateToCompare = Date.now() + periodToAdd;
        valid = tokenExpiry ? dateToCompare < tokenExpiry : false;
    } catch(ex){
        console.error(ex);
    }
    return valid;
  }

  public getSessionStatus(addition? : number): number {
    if(this.isAccessTokenValid(addition)){
        return 0;
    } else if(this.isRefreshTokenValid(addition)){
        return 1;
    } else{
        return 2;
    }
  }

  public getSessionActionDate() : Date{
    let millis = this.getSessionActionDateMillis();
    return millis ? new Date(millis) : undefined;
  }

  public getSessionActionDateMillis() : number{
    let loginTime = localStorage.getItem('login_time');
    return loginTime ? Number(loginTime) : 0;
  }

  // public startSession(session: SessionModel) : void{
  //     this.closeSession();
  //     this.activeSession = session;
  //     localStorage.setItem('login_time', Date.now().toString());
  //     localStorage.setItem('activeSession', JSON.stringify(this.activeSession));
  //     this.sessonNotifier.next(this.activeSession);
  // }

  public closeSession(){
    // this.activeSession = undefined;
    localStorage.removeItem('activeLanguage');
    localStorage.removeItem('activeSession');
    localStorage.removeItem('login_time');
  }

  public startSession(loginResponse: LoginResponse, profile?: UserProfile, keepProfile?: boolean) : void{
    this.handleStartSession(loginResponse, true, profile, keepProfile);
  }

  public refreshSession(loginResponse: LoginResponse, profile?: UserProfile) : void{
    this.handleStartSession(loginResponse, false, profile);
  }

  public handleStartSession(loginResponse: LoginResponse, closeActiveSession: boolean, profile?: UserProfile, keepProfile?: boolean) : void{
    let currentProfile = keepProfile ? this.getActiveSession()?.user_profile : undefined;
    if(closeActiveSession) this.closeSession();
    let activeSession: SessionModel = new SessionModel();
    activeSession.access_token = loginResponse.accessToken;
    activeSession.access_token_expires_in = loginResponse.expiresIn.toString();
    activeSession.token_type = loginResponse.tokenType;
    activeSession.authorities = loginResponse.authorities;
    activeSession.activeOrganizationRoles = loginResponse.activeOrganizationRoles;
    activeSession.user_id = loginResponse.userId;
    activeSession.refresh_token = loginResponse.refreshToken;
    activeSession.login_time = new Date();
    if(keepProfile) {
      activeSession.user_profile = currentProfile;
    } else{
      activeSession.user_profile = profile ? profile : new UserProfile();
    }
    localStorage.setItem('login_time', Date.now().toString());
    localStorage.setItem('activeSession', JSON.stringify(activeSession));
    this.sessonNotifier.next(activeSession);
  }

  public clearSession(){
    this.closeSession();
    this.sessonNotifier.next(undefined);
  }

  public logout(){
    this.clearSession();
  }

  public isActiveSession(): boolean{
    let activeSession = this.getActiveSession();
    return activeSession != undefined && this.getSessionStatus() < 2;
  }

  public getActiveUserProfile() : UserProfile{
    let activeSession = this.getActiveSession();
    return this.isActiveSession() ? activeSession.getUserProfile() : undefined;
  }

  public getAuthorities(): Authority<String>[]{
    let activeSession = this.getActiveSession();
    return activeSession ? activeSession.getAuthorities(): [];
  }

  public getAuthoritiesStringList(): String[]{
    let activeSession = this.getActiveSession();
    return activeSession ? activeSession.getAuthoritiesStringList(): [];
  }

  // public isAdmin() : boolean {
  //   return this.getActiveUserProfile() && this.activeSession.getUserProfile().getRole() ?
  //     this.activeSession.getUserProfile().getRole().getAdmin() : false;
  // }

  public isLoggedIn() : boolean{
    return this.getActiveSession() ? true : false;
  }

  public getUsername() : string{
    let activeSession = this.getActiveSession();
    return activeSession && activeSession.getUserProfile() && activeSession.getUserProfile().username
      ? activeSession.getUserProfile().username : "";
  }

  public getUserId() : number{
    return this.getActiveSession() && this.getActiveSession().getUserId();
  }
}
