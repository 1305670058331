import { Component, Input, Output, OnInit, EventEmitter, Inject, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormControllerGeneratorService } from '../../../../component/form-controller-generator.service';
import { DialogService } from '../../../../services/shared/dialog.service';
import { SettingsService } from '../../../../shared/services/settings.service';
import { SurveyFormQuestionHandler } from '../../../../../app/services/survey-form/survey-form-question-handler.service';
import { QuestionStatement } from '../../../../models/survey/survey';

@Component({
  selector: 'app-question-conditions',
  templateUrl: './question-conditions.component.html',
  styleUrls: ['./question-conditions.component.sass']
})
export class QuestionConditionsComponent implements OnInit {
  @Input() condition: FormGroup;
  @Input() isEditMode: boolean = true;
  @Input() parentQuestionControl: FormGroup;
  @Input() hasUnsavedQuestionChanges: boolean = false;
  @Input() surveyFormQuestionHandler: SurveyFormQuestionHandler;

  onlyShowActionAllowed = true;

  answerTypeValue : any;
  actions = new Map<String, String>();

  @Output()  questionStatementChanged = new EventEmitter<QuestionStatement>();

  public static readonly CONDITION_ACTION_SHOW: String = "SHOW";
  public static readonly CONDITION_ACTION_HIDE: String = "HIDE";

  constructor( private readonly _translateService: TranslateService, private dialogService: DialogService, private settingsService: SettingsService) {
    this.actions.set(QuestionConditionsComponent.CONDITION_ACTION_SHOW,"siren-survey-translation.question-conditions-form.fields.show");
    this.actions.set(QuestionConditionsComponent.CONDITION_ACTION_HIDE,"siren-survey-translation.question-conditions-form.fields.hide");
  }

  ngOnInit(): void {
    if(this.onlyShowActionAllowed && this.condition != undefined)
      this.condition?.get('action')?.patchValue(QuestionConditionsComponent.CONDITION_ACTION_SHOW);
  }

  getBlocksFormArray(): FormArray{
    return this.condition.controls['blocks'] as FormArray
  }

  addBlock(){
    let blocksFormArray = this.getBlocksFormArray() as FormArray;
    let blocksFormGroup = FormControllerGeneratorService.createConditionBlockFormGroup(this.isEditMode);
    // let defaultStatement = FormControllerGeneratorService.createStatementFormGroup(this.isEditMode);
    // blocksFormGroup.controls['statements'].push(defaultStatement);
    blocksFormArray.push(blocksFormGroup);
  }

  canDeleteBlock(): boolean{
    return this.isEditMode;
  }

  deleteBlock(index: number){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-block'),
      "message": this._translateService.instant('siren-survey-translation.popup-module.messages.block-delete'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response){
        let blocksArray = this.condition.controls['blocks'] as FormArray;
        blocksArray.removeAt(index);
      }
    });
  }

  moveUpBlock(index: number) {
    if (index > 0) {
      const blockFormArray = this.condition.controls['blocks'] as FormArray;
      const currentObject = blockFormArray.at(index) as FormGroup;
      blockFormArray.insert(index - 1, currentObject);
      blockFormArray.removeAt(index + 1);
    }
  }

  moveDownBlock(index: number) {
    const blockFormArray = this.condition.controls['blocks'] as FormArray;
    if (blockFormArray && index != undefined && index < blockFormArray.length - 1) {
      const currentObject = blockFormArray.at(index) as FormGroup;
      blockFormArray.insert(index + 2, currentObject);
      blockFormArray.removeAt(index);
    }
  }

  getStatementsFormControlElement(parentCntrol: any) : FormGroup{
    let blockControl = parentCntrol as FormGroup;
    return blockControl?.get('statements') as FormGroup;
  }

  getCustomStatementsFormControlElement(parentCntrol: any) : FormGroup{
    let blockControl = parentCntrol as FormGroup;
    return blockControl?.get('customStatements') as FormGroup;
  }

  addStatement(index: number, parentCntrol: any){
    if(!this.hasUnsavedQuestionChanges){
      let statementsFormArray = this.getStatementFormArray(index) as FormArray;
      let statementsFormGroup = FormControllerGeneratorService.createStatementFormGroup(this.isEditMode);
      statementsFormArray.push(statementsFormGroup);
    }
  }

  getStatementFormArray(index: number){
    let blockArr =  this.condition.controls['blocks'] as FormArray;
    let statementController = blockArr.at(index) as FormGroup;
    let statementControllerArr = statementController.controls['statements'] as FormArray;
    return statementControllerArr;
  }

  getCustomStatementFormArray(index: number){
    let blockArr =  this.condition.controls['blocks'] as FormArray;
    let statementController = blockArr.at(index) as FormGroup;
    let statementControllerArr = statementController.controls['customStatements'] as FormArray;
    return statementControllerArr;
  }

  getStatementsControlElement(parentCntrol: any) : FormGroup{
    let blockControl = parentCntrol as FormGroup;
    return blockControl as FormGroup;
  }

  editable() : boolean{
    return this.isEditMode;
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  onQuestionStatementChange(questionStatement: QuestionStatement){
    this.questionStatementChanged.emit(questionStatement);
  }
}
