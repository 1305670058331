import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Survey } from '../models/survey/survey';
import { SurveyList } from '../models/survey/surveyList';
import { BaseService } from './shared/base-service';
import { MyHttpHandler } from './shared/http-handler.service';
import { HttpResponse } from '@angular/common/http';
import { SettingsService } from '../shared/services/settings.service';
import { LookupModel } from '../models/shared/lookup.model';

@Injectable({ providedIn: 'root' })
export class SurveyService extends BaseService<SurveyList, Survey> {

  constructor(http: MyHttpHandler, protected injector: Injector, private settingsService: SettingsService){
    super(settingsService.settings.surveyUrlPath, http, injector);
  }

  cloneSurvey(surveyId: number): Observable<HttpResponse<Survey>> {
    return this.http.post<Survey>(this.settingsService.settings.surveyUrlPath + "/clone/" + surveyId, null);
  }

  isSurveyActive(surveyId: number): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>(this.settingsService.settings.surveyUrlPath + "/active/" + surveyId);
  }

  publishSurvey(surveyId: number): Observable<HttpResponse<Survey>> {
    return this.http.post<Survey>(this.settingsService.settings.surveyUrlPath + "/publish-survey/" + surveyId, null);
  }

  unPublishSurvey(surveyId: number): Observable<HttpResponse<Survey>> {
    return this.http.post<Survey>(this.settingsService.settings.surveyUrlPath + "/unpublish-survey/" + surveyId+"?deleteDraftResponse=false", null);
  }

  enableSurvey(surveyId: number): Observable<HttpResponse<Survey>> {
    return this.http.post<Survey>(this.settingsService.settings.surveyUrlPath + "/enable-survey/" + surveyId+"?enable=true", null);
  }

  disableSurvey(surveyId: number): Observable<HttpResponse<Survey>> {
    return this.http.post<Survey>(this.settingsService.settings.surveyUrlPath + "/enable-survey/" + surveyId+"?enable=false", null);
  }

  getLinkedSurveyForObject(queryParams: string): Observable<HttpResponse<any>> {
    let url = this.settingsService.settings.surveyUrlPath;
    if(queryParams != undefined && queryParams.trim() != '') url += queryParams.trim();
    return this.http.get<any>(url);
  }

  getSurveyTypes(lan: string) : Observable<HttpResponse<LookupModel[]>> {
    return this.http.get<LookupModel[]>(`/v1/survey/surveyType/lookup?lan=${lan}`);
  }
}
