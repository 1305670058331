import { Injectable } from '@angular/core';
import {
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Resolve
} from '@angular/router';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { Observable, forkJoin, map, of } from 'rxjs';
import { LookupModel } from 'src/app/models/shared/lookup.model';

@Injectable({
  providedIn: 'root'
})

export class ProgramResolver implements Resolve<{ lookups: LookupModel[], lookupObjects: LookupModel[] }> {

  constructor(private _lookupService: LookupService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ lookups: LookupModel[], lookupObjects: LookupModel[] }> {
    return forkJoin([this._lookupService.getLookupsService(["Tag"]), this._lookupService.getLookupObjectsService(["MarchOrganization"])]).pipe(
      map((allResponses: any) => {
        return {
          lookups: allResponses[0]?.body,
          lookupObjects: allResponses[1]?.body
        };
      })
    );
  }
}
