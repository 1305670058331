import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { QuestionAnswer } from '../../../models/question/question-answer';
import { SurveyQuestion } from '../../../models/question/survey-question';
import { FormControllerGeneratorService } from '../../form-controller-generator.service';
import { DropdownComparator } from '../../shared/component-base/dropdown-comparator';

@Component({
  selector: 'app-question-bank-response-form-embedded',
  templateUrl: './question-bank-response-form-embedded.component.html',
  styleUrls: ['./question-bank-response-form-embedded.component.sass']
})
export class QuestionBankResponseFormEmbeddedComponent extends DropdownComparator implements OnInit {
  @Input() questionBankForm: FormGroup;
  @Input() isEditMode: boolean = true;
  @Input() surveyGraded: boolean;
  @Input() questionIndex: number;
  @Input() surveyMaxGrade: number;
  @Input() surveyQuestion: SurveyQuestion;
  @Input() isInvalid : boolean = false;
  @Input() isVisible: boolean;

  public error:string;
  public showOther: boolean = false;
  public selection = new SelectionModel(true, []);

  constructor(public readonly _translateService: TranslateService,private _snackbar: MatSnackBar) {
    super();
  }

  ngOnInit(): void {

  }

  handleShowOtherValueChange(){
    this.showOther = !this.showOther;
  }

  removeShowOther(){
    this.showOther = false;
    this.getOtherFormGroup().reset();
  }

  isSingleSelection(): boolean{
    return this.surveyQuestion?.question?.answerType?.key != undefined && this.surveyQuestion?.question?.answerType?.key == 'SINGLE_SELECT';
  }

  isMultiSelection(): boolean{
    return this.surveyQuestion?.question?.answerType?.key != undefined && this.surveyQuestion?.question?.answerType?.key == 'MULTI_SELECT';
  }

  addShowOther(isManuallyTriggered: boolean){
    if((this.isEditMode && isManuallyTriggered) || !isManuallyTriggered){
      if(this.isSingleSelection()){
        let selectionArray: FormArray = this.getSelectionArray();
        if(selectionArray) selectionArray.clear();
      }
      this.showOther = true;
    }
  }

  getOtherFormGroup() : FormGroup{
    return this.questionBankForm?.get('otherResponse') as FormGroup;
  }

  getSelectionArray() : FormArray{
    return this.questionBankForm?.get('answers') as FormArray;
  }

  setSelectedSingleChoice(element: QuestionAnswer){
    if(this.isEditMode){
      this.removeShowOther();
      let selectionArray: FormArray = this.getSelectionArray();
      if(selectionArray){
        selectionArray.clear();
        let selectionFormGroup = FormControllerGeneratorService.createQuestionResponseSelectionFormGroup(this.isEditMode);
        selectionFormGroup.get('questionAnswer').get('id').patchValue(element.id);
        selectionArray.push(selectionFormGroup);
      }
    }
  }
  toogleSelectionMultipleChoice(event: MatCheckboxChange, element: any){
    if(this.isEditMode){
      let selectionArray: FormArray = this.getSelectionArray();
      if(selectionArray){
        if(event.checked) {
          let selectionFormGroup = FormControllerGeneratorService.createQuestionResponseSelectionFormGroup(this.isEditMode);
          selectionFormGroup.get('questionAnswer').get('id').patchValue(element.id);
          selectionArray.push(selectionFormGroup);
        } else {
          this.unselectChoice(selectionArray, element.id);
        }
      }
    }
  }
  unselectChoice(selectionArray: FormArray, elementId: number){
    if(this.isEditMode){
      if(selectionArray && elementId && elementId > 0){
        let removed = false;
        let currentIndex = 0;
        for (const control of selectionArray.controls) {
          if(!removed && control.get('questionAnswer').get('id').getRawValue() == elementId){
            selectionArray.removeAt(currentIndex);
            removed = true;
            break;
          }
          currentIndex++;
        }
      }
    }
  }

  checkIfSelected(answerId: number) : boolean{
    let selected = false;
    if(answerId != undefined && answerId > 0){
      let selectionArray: FormArray = this.getSelectionArray();
      if(selectionArray){
        selectionArray.controls.forEach(control => {
          if(!selected) selected = control?.get('questionAnswer')?.get('id')?.getRawValue() == answerId;
        });
      }
    }
    return selected;
  }

  checkIfHasOther(){
    let answersLen = (this.questionBankForm.get('answers') as FormArray) ? (this.questionBankForm.get('answers') as FormArray)?.length : 0;
    let withOther = (this.isMultiSelection() || (this.isSingleSelection() && answersLen == 0)) && this.questionBankForm?.get('otherResponse')?.getRawValue() != undefined
    && this.questionBankForm?.get('otherResponse')?.getRawValue().trim() != "";
    if(withOther) this.addShowOther(false);
    return withOther;
  }

  onKeyDownEvent(event: any){
    if(event.key.toLowerCase() == "e" || event.key == "+" || event.key == "-") event.preventDefault();
  }
}
