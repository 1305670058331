<div class="card-list-form">
  <div class="datatable-action-bar">
    <div class="flexDivStart table-title">{{tableTitleLabel}}</div>
    <div class="flexDivEnd">
      <button type="button" #filterbutton data-bs-toggle="collapse" data-bs-target="#filterElementContainer" title="Filter" *ngIf="showFilterButton"
        aria-controls="filterElementContainer" aria-expanded="false" aria-label="Toggle navigation" class="button-flat">
        <img [src]="getAssetsUrl('assets/icons/filter-yellow-icon.svg')"  class="iconWidth"> <span class="table-filter-label">{{"siren-survey-translation.global.filter" | translate}}</span>
      </button>
      <!-- <input matInput class="datatable-search-textbox" (keyup)="applyFilter($event)" placeholder="Quick Filter" #input [value]="filterValue"> -->
      <div class="datatable-action-button-container row">
        <!-- <button type="button" class="btn btn-md table-action-button" *ngIf="withAdd" (click)="add()"><span class="fa fa-plus"></span> Add</button> -->
        <!-- <button type="button" class="btn btn-md table-action-button marginLeft" *ngIf="deleteMode == tableDeleteMode.SELECTION_MODE" (click)="deleteSelection()"><span class="fa fa-minus"></span> Delete</button> -->
        <button type="button" class="btn btn-md table-action-button" *ngIf="withAdd" (click)="add()">
          {{ ( addButtonLabel | translate) != undefined ? " " + ( addButtonLabel | translate) : ("siren-survey-translation.global.add" | translate) }}
        </button>
        <button type="button" class="btn btn-md table-action-button marginLeft" *ngIf="deleteMode == tableDeleteMode.SELECTION_MODE"  (click)="deleteSelection()"><span class="fa fa-minus"></span>
          {{ ( "siren-survey-translation.global.delete" | translate) != undefined ? " " + ( "siren-survey-translation.global.delete" | translate) : ("siren-survey-translation.global.delete" | translate)}}
        </button>
      </div>

      <div class="bottom-border" *ngIf="showBackButton" >
        <button type="button" (click)="goBack(); $event.stopPropagation();" class="btn button-flat"
         [disabled]="false">
         <i class="fa fa-angle-left" aria-hidden="true"></i>
         <span  class="locate-text ">{{'siren-survey-translation.global.back' | translate}}</span>
       </button>
     </div>
    </div>
  </div>
  <ng-content></ng-content>
  <!-- <div class="flexDiv">
    <div class="flexDivStart table-title">{{tableTitleLabel}}</div>
    <div class="flexDivEnd">
      <input matInput class="datatable-search-textbox" (keyup)="applyFilter($event)" placeholder="Filter" #input>
      <div class="row flexRow ">
        <button type="button" class="btn btn-md table-action-button" *ngIf="withAdd" (click)="add()">
          {{ ( addButtonLabel | translate) != undefined ? " " + ( addButtonLabel | translate) : " Add" }}
        </button>
        <button type="button" class="btn btn-md table-action-button marginLeft" *ngIf="deleteMode == tableDeleteMode.SELECTION_MODE"  (click)="deleteSelection()"><span class="fa fa-minus"></span>
          {{ ( "global.delete" | translate) != undefined ? " " + ( "global.delete" | translate) : " Delete" }}
        </button>
      </div>
    </div>
  </div> -->

  <div #cardListContainer class="card-list-container"  > <!-- (scroll)="onScroll($event)" -->
    <div *ngIf="isLoading" style="text-align:center;">
      <i class="fa fa-spinner fa-spin fa-2x" style="margin-bottom:10px;"></i>
    </div>
    <ng-container *ngIf="!isLoading">
      <div *ngFor="let element of this.tableDataSource?.filteredData; let i = index;" class="card-list-row flex-div-nowrap" (click)="edit(element.id)">
        <div class="row card-list-element-container" *ngIf="!isCellEditable(element.id)">
          <div *ngFor="let column of tableColumns" class="card-list-element col-12 col-sm-6">
            <div class="card-list-element-row" *ngIf="column.columnDef != '_delete_inline' && column.columnDef != '_select_inline' && column.columnDef != '_menu_inline'">
              <label class="card-list-element-label" >
                {{ column.headerTranslationPath && (column.headerTranslationPath | translate) != undefined
                && (column.headerTranslationPath | translate) != column.headerTranslationPath ? (column.headerTranslationPath | translate) : column.header}}
              </label>
              <label class="card-list-element-value" *ngIf="column.type == tableFilterTypeEnum.Month && (column.cell(element) != 'null' && column.cell(element) != undefined && column.cell(element) != null)">
                {{ getMonthLookupValueForObject(column.cell(element)) }}
              </label>
              <label class="card-list-element-value" *ngIf="column.type == tableFilterTypeEnum.BenefScores && (column.cell(element) != 'null' && column.cell(element) != undefined && column.cell(element) != null)">
                  <div class="benef-score-level" [style.borderColor]="getBorderColor(column.cell(element))">
                    {{ getBenefScores(column.cell(element)) }}
                  </div>
              </label>
              <label [style]="column.style != undefined ? column?.style(element) : ''"   class="card-list-element-value" *ngIf="column.type != tableFilterTypeEnum.Month && column.type != tableFilterTypeEnum.BenefScores && column.cell(element) != 'null' && column.cell(element) != undefined && column.cell(element) != null">
                {{(column.cell(element).length > maxStringLength) ? (column.cell(element) | slice:0:maxStringLength) + '...' : (column.cell(element))}}
              </label>
              <label class="card-list-element-value" *ngIf="column.type != tableFilterTypeEnum.Month && column.cell(element) == 'null' || column.cell(element) == undefined || column.cell(element) == null">
                -
              </label>
            </div>
          </div>
        </div>

        <form [formGroup]="getObjectFormGroup(element.id)" *ngIf="isCellEditable(element.id)" class="row card-list-element-container" style="width: 100%">
          <div *ngFor="let column of tableColumns" class="card-list-element col-12 col-sm-6">
            <div class="card-list-element-row" *ngIf="column.columnDef != '_delete_inline' && column.columnDef != '_select_inline' && column.columnDef != '_menu_inline'">
              <label class="card-list-element-label">
                {{ column.headerTranslationPath && (column.headerTranslationPath | translate) != undefined
                && (column.headerTranslationPath | translate) != column.headerTranslationPath ? (column.headerTranslationPath | translate) : column.header}}
              </label>
              <div class="card-list-element-value">
                <div *ngIf="column.type == tableFilterTypeEnum.String">
                  <mat-form-field class="full-width-element" appearance="legacy">
                    <mat-label class=""> {{ column.header }} </mat-label>
                    <input  matInput class="" autocomplete="off" formControlName="{{column.columnDef}}">
                  </mat-form-field>
                </div>
                <div *ngIf="column.type == tableFilterTypeEnum.Lookup">
                  <mat-form-field required class="full-width-element">
                    <mat-label class=""> {{ column.header }} </mat-label>
                    <mat-select class="" formControlName="{{column.columnDef}}" *ngIf="column?.lookupsValueById" [compareWith]="objectComparisonFunction">
                      <mat-option *ngFor="let lookupElement of getLookupByName(column.lookupName)" [value]="lookupElement">
                        {{ lookupElement.value }}
                      </mat-option>
                    </mat-select>
                    <mat-select *ngIf="!column?.lookupsValueById" class="" formControlName="{{column.columnDef}}" [compareWith]="objectKeyComparisonFunction">
                      <mat-option *ngFor="let lookupElement of getLookupByName(column.lookupName)" [value]="lookupElement">
                        {{ lookupElement.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="column.type == tableFilterTypeEnum.LookupObject">
                  <mat-form-field required class="full-width-element">
                    <mat-label class=""> {{ column.header }} </mat-label>
                    <mat-select class="" formControlName="{{column.columnDef}}" *ngIf="column?.lookupsValueById" [compareWith]="objectComparisonFunction">
                      <mat-option *ngFor="let lookupElement of getLookupObjectByName(column.lookupName)" [value]="lookupElement">
                        {{ lookupElement.value }}
                      </mat-option>
                    </mat-select>
                    <mat-select *ngIf="!column?.lookupsValueById" class="" formControlName="{{column.columnDef}}" [compareWith]="objectKeyComparisonFunction">
                      <mat-option *ngFor="let lookupElement of getLookupObjectByName(column.lookupName)" [value]="lookupElement">
                        {{ lookupElement.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="column.type == tableFilterTypeEnum.Month">
                  <mat-form-field  required class="full-width-element">
                    <mat-label class="" >
                      {{ column.header }}
                    </mat-label>
                    <mat-select  class="" formControlName="{{column.columnDef}}" [compareWith]="enumerationObjectComparisonFunction">
                      <mat-option *ngFor="let lookupElement of getLookupObjectByName(column.lookupName)" [value]="lookupElement" >
                        {{ lookupElement.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="column.type == tableFilterTypeEnum.Number">
                  <mat-form-field class="full-width-element" appearance="legacy">
                    <mat-label class=""> {{ column.header }} </mat-label>
                    <input matInput type="number" class="" (keydown)="onKeyDownEvent($event)" autocomplete="off" formControlName="{{column.columnDef}}">
                  </mat-form-field>
                </div>
                <div *ngIf="column.type == tableFilterTypeEnum.Date">
                  <mat-form-field class="full-width-element">
                    <mat-label> {{ column.header }} </mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="{{column.columnDef}}">
                    <mat-hint>DD/MM/YYYY</mat-hint> <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div *ngIf="column.type == tableFilterTypeEnum.Boolean">
                  <mat-form-field class="full-width-element" class="full-width-element">
                    <mat-label class=""> {{ column.header }} </mat-label>
                    <mat-select class="" formControlName="{{column.columnDef}}">
                      <mat-option [value]="false">False</mat-option>
                      <mat-option [value]="true">True</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="card-menu-div">
          <button class="button-flat marginLeft" type="button" *ngIf="deleteMode != undefined && deleteMode == tableDeleteMode.INLINE_MODE && element.editable" (click)="delete(element.id); $event.stopPropagation();">
            <img [src]="getAssetsUrl('assets/icons/delete-icon.svg')" class="widthIcon">
          </button>
          <mat-checkbox (click)="$event.stopPropagation()" *ngIf="this.selectionMode != tableSelectionMode.NONE_MODE"
              (change)="handleSelection($event, element)" [checked]="selection.isSelected(element)">
          </mat-checkbox>
          <button type="button" class="no-hover" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" *ngIf="withInlineMenu">
            <img [src]="getAssetsUrl('assets/icons/horizontal-menu-icon.svg')" style="width: 20px; height: 20px;">
          </button>
          <mat-menu #menu="matMenu" >
            <!-- <button type="button" *ngFor="let menuAction of _tableMenuAction" mat-menu-item (click)="onContextMenuClicked(element.id, menuAction.actionName); $event.stopPropagation();">
              <img *ngIf="menuAction.iconUrl" src="{{menuAction.iconUrl}}" [style]="menuAction.iconStyle">{{ menuAction.name }}
            </button> -->
            <!-- <div *ngFor="let menuAction of _tableMenuAction">
              <button *ngIf="isAllowedAction(element, menuAction)" type="button" mat-menu-item (click)="onContextMenuClicked(element, menuAction.actionName); $event.stopPropagation();">
                <img *ngIf="menuAction.iconUrl" [src]="getAssetsUrl(menuAction.iconUrl)" [style]="menuAction.iconStyle">{{ menuAction.name }}
              </button>
            </div> -->
            <ng-container *ngFor="let menuAction of _tableMenuAction">
              <div *ngIf="(menuAction.actionName != 'EDIT_ACTION' && menuAction.actionName != 'DELETE_ACTION') || !isCellEditable(element.id)">
                <button *ngIf="isAllowedAction(element, menuAction)" type="button" mat-menu-item (click)="onContextMenuClicked(element, menuAction.actionName,getObjectFormGroup(element.id)); $event.stopPropagation();">
                  <img *ngIf="menuAction.actionName != 'EDIT_ACTION' && menuAction.iconUrl" [src]="getAssetsUrl(menuAction.iconUrl)" [style]="menuAction.iconStyle">
                  <i *ngIf="menuAction.actionName == 'EDIT_ACTION'" class="fa fa-edit" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                  <i *ngIf="menuAction.actionName == 'DISABLE_ACTION'" class="fa fa-times-circle" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                  {{ menuAction.name }}
                </button>
              </div>
            </ng-container>
            <!-- <div *ngIf="inlineEditable  && !isCellEditable(element.id)">
              <button *ngIf="isAllowedAction(element, editInlineButton)" type="button" mat-menu-item (click)="onContextMenuClicked(element, editInlineButton.actionName,getObjectFormGroup(element.id)); $event.stopPropagation();">
                <img *ngIf="editInlineButton.iconUrl" [src]="getAssetsUrl(editInlineButton.iconUrl)" [style]="editInlineButton.iconStyle">
                {{ editInlineButton.name }}
              </button>
            </div> -->
            <div *ngIf="inlineEditable  && isCellEditable(element.id)">
              <button *ngIf="isAllowedAction(element, saveInlineButton)" type="button" mat-menu-item (click)="onContextMenuClicked(element, saveInlineButton.actionName,getObjectFormGroup(element.id)); $event.stopPropagation();">
                <!-- <img *ngIf="saveInlineButton.iconUrl" [src]="getAssetsUrl(saveInlineButton.iconUrl)" [style]="saveInlineButton.iconStyle"> -->
                <i class="fa fa-save" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;"></i>
                {{ saveInlineButton.name }}
              </button>
            <!-- </div>
            <div *ngIf="inlineEditable  && isCellEditable(element.id)"> -->
              <button *ngIf="isAllowedAction(element, cancelInlineButton)" type="button" mat-menu-item (click)="onContextMenuClicked(element, cancelInlineButton.actionName,getObjectFormGroup(element.id)); $event.stopPropagation();">
                <!-- <img *ngIf="cancelInlineButton.iconUrl" [src]="getAssetsUrl(cancelInlineButton.iconUrl)" [style]="cancelInlineButton.iconStyle"> -->
                <i class="fa fa-window-close" style="font-size: 20px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
                {{ cancelInlineButton.name }}
              </button>
            </div>
            <div class="no-action-menu" *ngIf="hasNoMenuElement(element)">
              <span>No Actions</span>
            </div>
          </mat-menu>
        </div>
      </div>
    </ng-container>
  </div>
</div>
