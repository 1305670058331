<div class="texxt">
    <div class="text_sec" dir="ltr">
        {{ data.english | translate }}
    </div>
    <div class="text_sec">
        {{ data.arabic | translate }}
    </div>

    <button *ngIf="canClose" class="app-button cancel-btn" mat-raised-button (click)="buttonClicked(false)">
        {{ data.closeAction ? (data.closeAction | translate) : 'siren-admin-translation.confirmation_dialog.close' | translate }}</button>

        <button *ngIf="data.action" class="app-button cancel-btn" mat-raised-button (click)="buttonClicked(true)">{{
            data.action |
            translate }}</button>

</div>
