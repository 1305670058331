import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { DeleteLegalStatusObject, LegalCaseStatus } from 'src/app/models/legal-case/legalcase';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { LegalCaseService } from 'src/app/services/legal-case/legal-case.service';
import { MarchPermissionEnum, MarchModuleEnum } from 'src/app/shared/enum/user-role.enum';
import { Location } from '@angular/common';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { LookupModel, LookupModelDto } from '@siren-survey/app/models/shared/lookup.model';
import { DropdownComparatorWithBrowserListener } from '@siren-survey/app/component/shared/component-base/dropdown-comparator-with-browser';
import { DeleteLegalCaseStatusFormInput } from '../legal-case-status-table/legal-case-status-table.component';

@Component({
  selector: 'app-legal-case-status-form',
  templateUrl: './legal-case-status-form.component.html',
  styleUrls: ['./legal-case-status-form.component.sass']
})
export class LegalCaseStatusFormComponent  extends DropdownComparatorWithBrowserListener implements OnInit {
  @Input() isEditMode: boolean = true;

  form: FormGroup;
  public error : string;
  legalCase: LegalCaseStatus;
  id: number;
  index: number;
  legalCaseStatuses: LookupModel[] = [];


  canGetLegalCaseStatus: boolean = false;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;


  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(private legalCaseService: LegalCaseService,public dialogRef: MatDialogRef<DeleteLegalCaseStatusFormInput>,
    private route : ActivatedRoute, private router: Router, private _snackbar: MatSnackBar,
    private dialogService: DialogService, public readonly _translateService: TranslateService, public lookupService: LookupService,
    private location: Location, public authService: AuthenticationService,@Inject(MAT_DIALOG_DATA) public data: DeleteLegalCaseStatusFormInput){
      super();
      this.id = data.statusId;
      this.canGetLegalCaseStatus = this.authService.isModuleAccessible(this.marchModuleEnum.LOOKUPS_MGT.module);
  }

  ngOnInit(): void {
    this.createFormGroups();
    if(this.id && this.id > 0 && this.canGetLegalCaseStatus) this.getData();
  }

  createFormGroups(){

    this.form = new FormGroup({
      'legalCaseStatus': new FormControl({value: undefined, disabled:false }, Validators.required),
    });
  }

  getData(){
    if(this.id && this.id > 0){
      let updatedStatuses: LookupModel[] = [];

      this.lookupService.getLookupObjectsService(["LegalCaseStatus"], false, true).subscribe(response => {
        if(response?.body) {
          response.body["LegalCaseStatus"].forEach(status => {
              if(status.id != this.id){
                this.legalCaseStatuses.push(status);
              }
          });
        }
      });
    }
  }

  editable() : boolean{
    return this.isEditMode;
  }

  validateFormForSave() : string{
    let error : string = undefined;
    if(this.form){
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      error = !this.form.valid ? this._translateService.instant('legal-case.error.form-not-valid') : undefined;
    }
    return error;
  }

  saveLegalCaseStatus(){
    let error = this.validateFormForSave();
    if(!error){
      this.executeSave();
    } else{
      this._snackbar.open(error, null, { duration: 3000 });
    }
  }

  executeSave(){
    let rawValue = this.form.getRawValue();

    if(rawValue?.legalCaseStatus?.id){
      this.legalCaseService.deleteLegalStatus(this.id).subscribe( response =>{
        this.dialogRef?.close(response.body);
      }, error =>{
          let message = error && error.error && error.error.message.includes("ConstraintViolationException")
            ? this._translateService.instant('error.record-depending-error')
            : this._translateService.instant('error.record-error');
          this._snackbar.open(message, null, { duration: 3000 });
        });
    }
  }

  goBack(){
    this.location.back();
  }

  objectClassName(): string{
    return "LegalCaseStatus";
  }

}

