<form [formGroup]="form" (ngSubmit)="fillAssessment()">
	<div class="assessment-form container">
		<div class="title-container">
			<span class="march-page-title">{{ activityName }} - {{'assessment-module.assessment.title' | translate}}</span>
			<div class="bottom-border">
				<button type="button" (click)="goBack(); $event.stopPropagation();" class="btn button-flat" [disabled]="false">
					<i class="fa fa-angle-left" aria-hidden="true"></i>
					<span class="locate-text ">{{'siren-survey-translation.global.back' | translate}}</span>
				</button>
			</div>
		</div>

		<div class="container assessment-container" *ngIf="this?.id > 0">
			<div class="assessment-form-container-header assessment-title-container">
				<div class="full-width-element flex-container">
					<div class="col-3 locate-input" *ngIf="actionMode=='ADD_MODE'">
						<mat-form-field class="full-width-element mat-input-with-no-space">
							<mat-label>{{'assessment-module.assessment.fields.assessment-types' | translate}}</mat-label>
							<mat-select (selectionChange)="assessmentTypeChanged($event)">
								<mat-option *ngFor="let assessmentType of assessmentTypes" [value]="assessmentType">{{ assessmentType.value }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-9 locate-input">
						<mat-form-field class="full-width-element mat-input-with-no-space">
							<mat-label>{{'assessment-module.assessment.fields.assessments' | translate}}</mat-label>
							<mat-select (selectionChange)="assessmentChanged($event)" formControlName="assessment">
								<mat-option *ngFor="let assessment of assessments" [value]="assessment">{{ assessment.value }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<button type="submit" [ngClass]="currentLang == 'ar' ? 'btn-submit-rtl' : '' " class="btn btn-submit">
					<span style="white-space: nowrap;">{{'global.save' | translate}}</span>
				</button>
			</div>
			<div>
				<div class="col-3 date-input locate-input" *ngIf="showSubmitDate">
					<march-datepicker [title]="'assessment-module.assessment.fields.submit-date' | translate" [dateFormControl]="this.form?.controls?.submitDate"></march-datepicker>
				</div>
			</div>
		</div>


		<div class="container mat-focus-indicator assessment-form-container">
			<div class="assessment-form-content container" id="color-list" style="overflow-y: scroll">
				<div class="row">
					<div *ngIf="this.assessmentId">
						<div class="grid-container" [style.grid-template-columns]="gridTemplateColumns" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1" [scrollWindow]="false" (scrolled)="onScroll($event)">
							<div class="padding-benef empty-corner"></div>
							<ng-container *ngFor="let assessmentDetail of assessmentDetails">
								<div class="header">{{ assessmentDetail.section }}
									<hr>
									<div class="section-criteria">
										<div *ngFor="let criterion of assessmentDetail.criteria" [style.width]="assessmentDetail.width">
											<span>{{ criterion}}</span>
										</div>
									</div>
								</div>
							</ng-container>
							<ng-container *ngFor="let benficiaryResult of beneficiariesFormArray.controls" [formGroup]="benficiaryResult">
								<div class="padding-benef item fixed-beneficiaries">
									<span>{{ benficiaryResult.value.beneficiaryName }}</span>
								</div>
								<ng-container *ngFor="let section of benficiaryResult?.controls.sections.controls; let sectionResultIndex = index;">
									<div [formGroup]="section">
										<div class="item criterion-container">
											<ng-container *ngFor="let criterion of section?.controls.assessmentCriterion.controls; let criterionIndex = index;">
												<div [formGroup]="criterion" class="criterion">
													<mat-form-field class="score">
														<mat-label>{{'assessment-module.assessment.score' | translate}} {{'('}}
															{{criterion.value.minValue ?
															criterion.value.minValue : 0 }} {{'-'}}
															{{criterion?.value.maxValue ?
															criterion.value.maxValue : 999}} {{')'}}</mat-label>
														<input matInput type="number" min="{{criterion?.value.minValue ? criterion.value.minValue : 0 }}" max="{{criterion?.value.maxValue ? criterion.value.maxValue : 999}}" placeholder="{{ 'assessment-module.assessment.score' | translate }}" formControlName="value">
													</mat-form-field>
													<mat-form-field class="custom-width-element">
														<mat-label>{{'assessment-module.assessment.comment' | translate}}</mat-label>
														<input matInput formControlName="comment">
													</mat-form-field>
												</div>
											</ng-container>
										</div>

										<div class="item section-comment-container">
											<div class="criteria-fields" (click)="$event.stopPropagation();">
												<mat-form-field class="full-width-element">
													<mat-label>{{ 'assessment-module.assessment.comment' | translate}}</mat-label>
													<textarea matInput class="" type="text" formControlName="comment"></textarea>
												</mat-form-field>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</div>
					<div *ngIf="!this.assessmentId" class="center-info-text">
						<span>{{'assessment-module.assessment.message.select-assessment' | translate}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
