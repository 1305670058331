import { ISessionModel, IUserProfile } from "../../../models/session.interface";

export abstract class ISessionService{
  public abstract getActiveLanguage(): string;

  public abstract setActiveLanguage(lang: string);

  public abstract getActiveSession() : ISessionModel;

  public abstract isAccessTokenValid(addition? : number) : boolean;

  public abstract isRefreshTokenValid(addition? : number) : boolean;

  public abstract getSessionStatus(addition? : number): number ;

  public abstract getSessionActionDate() : Date;

  public abstract getSessionActionDateMillis() : number;

  public abstract startSession(session: any, profile?: any) : void;

  public abstract closeSession();

  public abstract logout();

  public abstract isActiveSession();

  // public abstract getActiveUserProfile() : IUserProfile;

  // public abstract isAdmin() : boolean ;

  public abstract isLoggedIn() : boolean;

  public abstract getUsername() : string;

  public abstract getUserId() : number;
}
