<div [formGroup]="surveySectionForm">
  <div class="form-section-title">
    <div class="full-width-element flex-row">
      <button type="button" *ngIf="this.contentExpanded" title="Colapse Section" class="button-flat margin-after-10" (click)="toogleContentExpansion()">
        <i class="fa fa-caret-up"></i>
      </button>

      <button type="button" *ngIf="!this.contentExpanded" title="Expand Section" class="button-flat margin-after-10" (click)="toogleContentExpansion()">
        <i class="fa fa-caret-down"></i>
      </button>
      <mat-form-field class="full-width-element">
        <input matInput class=" survey-title-input survey-title" placeholder="{{'siren-survey-translation.survey-form.fields.title' | translate}}" formControlName="title">
      </mat-form-field>
    </div>
    <div *ngIf="editable()" class="form-section-action-btn-container">
      <button type="button" title="Delete Section" class="button-flat" (click)="deleteSection(surveySectionForm.controls['index'].value)" *ngIf="canDeleteSection()">
        <img [src]="getAssetsUrl('assets/icons/delete-icon-grey.svg')" class="iconWidth">
      </button>
      <button type="button" title="Move Section Up" *ngIf="elementIndex > 0" class="button-flat" (click)="moveUp(surveySectionForm.controls['index'].value)">
        <img [src]="getAssetsUrl('assets/icons/arrow-down-icon.svg')" class="iconWidth rotateIcon">
      </button>
      <button type="button" title="Move Section Down" *ngIf="elementIndex < upperBoundIndex" class="button-flat" (click)="moveDown(surveySectionForm.controls['index'].value)">
        <img [src]="getAssetsUrl('assets/icons/arrow-down-icon.svg')" class="iconWidth">
      </button>
    </div>
  </div>
  <div class="section-form-content" [id]="'section-content-' + elementIndex">
    <div class="row submitRow">
      <div class="col-12 col-md-6 no-padding-before" >
        <mat-form-field class="margin-right-element full-width-element">
          <mat-label>{{'siren-survey-translation.survey-section-form.fields.weight' | translate}}</mat-label>
          <input matInput formControlName="weight" type="number" autocomplete="off" min="0" (keydown)="onKeyDownEvent($event)">
        </mat-form-field>
      </div>
      <div *ngIf="gradedSurvey" class="col-12 col-md-6 no-padding-after">
        <mat-form-field class="margin-right-element full-width-element">
          <mat-label>{{'siren-survey-translation.survey-section-form.fields.passingGrade' | translate}}</mat-label>
          <input matInput formControlName="passingGrade" type="number" autocomplete="off" min="0" (keydown)="onKeyDownEvent($event)">
        </mat-form-field>
      </div>
    </div>
    <div class="no-margin no-padding" >
      <div formArrayName="questions" class="no-padding">
        <div *ngIf="getQuestionsFormSectionArray()?.length > 0">
          <div  class="question-embedded-container" *ngFor="let question of  getQuestionsFormSectionArray().controls; let q = index;" [formGroupName]="q">
            <app-survey-question class="full-width-element"
              [surveyId]="surveyId"
              [questionFormGroup]="question"
              [isEditMode]="isEditMode"
              [gradedSurvey]="gradedSurvey"
              [surveyFormQuestionHandler]="surveyFormQuestionHandler"
              [questionsFormArray]="getQuestionsFormSectionArray()"
              [questionIndex]="q" [sectionUuid]="sectionUuid"
              [hasResponse]="hasResponse"
              [answerTypes]="answerTypes">
              <!--
              [questionChangeEventSubject]="questionChangeEventSubject"
              (questionChangeEventEmitter)="questionChangeEventHandler($event)"
              [hasUnsavedQuestionChanges]="hasUnsavedQuestionChanges"
              [questionAnswerMap]="questionAnswerMap"
              (editQuestionNotificationEmitter)="handleEditQuestionNotification($event)"-->
            </app-survey-question>
          </div>
        </div>
      </div>

      <div class="section-btn-container" *ngIf="editable()">
        <h3 class="survey-section-add-title">{{'siren-survey-translation.survey-section-form.fields.addQuestion' | translate}}</h3>
        <div [ngClass]="_translateService?.currentLang == 'ar' ? 'question-embedded-btn-container-rtl' : 'question-embedded-btn-container' ">
          <button type="button" *ngIf="editable()" class="btn btn-md table-action-button" style="vertical-align: middle;" (click)="addSectionQuestion(); $event.stopPropagation();">
            <img [src]="getAssetsUrl('assets/icons/add-btn-white-circle.svg')" class="iconWidth">
          </button>
          <button type="button" *ngIf="editable()" class="btn btn-md table-action-button" (click)="selectQuestion(); $event.stopPropagation();">
            <i class="fa fa-search" style="color: #FFFFFF; font-size: 17px;"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
