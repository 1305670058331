import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@siren-survey/app/services/shared/base-service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { Observable } from 'rxjs';
import { LookUpList } from 'src/app/models/admin/program/lookupType/lookupList';
import { LookUpType } from 'src/app/models/admin/program/lookupType/LookUpType';

@Injectable({
  providedIn: 'root'
})
export class LookupTypeService  extends BaseService<LookUpType, LookUpType> {

  constructor(http: MyHttpHandler, protected injector: Injector){

    super("/v1/admin/lookups/list/SurveyType", http, injector);
  }

  getLookupList(): Observable<HttpResponse<LookUpList>> {
    return this.http.get<LookUpList>("/v1/admin/lookups/types");
  }

  protected formatCreationObject(object: LookUpType): any{
    return [{
      "id": object?.id != undefined && object?.id > 0 ? object?.id : null,
      "key": object?.key,
      "value": object?.valueEn,
      "order": object?.order,
      "deprecated": object?.deprecated ? object?.deprecated : false,
      "values": [
          {
              "locale": "en",
              "value": object?.valueEn
          },
          {
              "locale": "ar",
              "value": object?.valueAr
          }
      ]
    }]
  }

}
