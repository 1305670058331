import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@siren-survey/app/component/shared/confirm-dialog/confirm-dialog.component';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { Observable, Subject, Subscription, interval, takeWhile } from 'rxjs';
import { BlobObject, UploadStatus } from '@siren-survey/app/models/blob/blob';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { BlobService } from '@siren-survey/app/services/blob/blob.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';

@Component({
  selector: 'app-blob',
  templateUrl: './blob.component.html',
  styleUrls: ['./blob.component.sass']
})
export class BlobComponent implements OnInit, OnDestroy {
  @Input() public files: BlobObject[];
  @Input() public singleBlobObject: boolean = true;
  @Input() public type: string;
  @Input() public fromComponent: string;
  @Input() public objectId: string;
  @Input() public saveBlobOnTrigger: boolean = false;
  @Input() public displayMode: string;
  @Input() public showPreviewPanel: boolean = false;
  @Input() public saveBlobSubject: Subject<string>;
  @Input() public showUploadPanel: boolean = true;
  @Input() public reloadBlobSubject: Subject<boolean>;
  // @Input() public subscription: Subscription ;

  public subscription: Subscription;
  public reloadBlobSubscription: Subscription;
  public fileSubscription: Subscription;

  public pendingUploadFileList: FormData[] = [];
  public fileCounter: number = 0;
  public fileIdForUnicity: string;
  public maxFileSizeInMb: number;

  public currentPage: number = 0;
  public maxPage: number = 0;
  public pageSize: number = 10;
  public totalElements: number = 0;
  public hasInProgressFiles: boolean = false;

  public canDeleteFile: boolean = false;
  public canDownloadFile: boolean = false;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;

  constructor(public _snackbar: MatSnackBar, public dialog: MatDialog, public dialogService: DialogService,
    public readonly _translateService: TranslateService, public blobService: BlobService, public http: HttpClient,
    public settingsService: SettingsService,public authService: AuthenticationService ) {

  }

  public ngOnInit(): void {
    this.blobComponentInputs();
    this.canDeleteFile = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_FILE);
    this.canDownloadFile = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.DOWNLOAD_FILE);

    this.fileSubscription = this.startScheduler().subscribe(() => {
      if (this.hasInProgressFiles) this.blobComponentInputs();
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.reloadBlobSubscription) this.reloadBlobSubscription.unsubscribe();
    if (this.fileSubscription) this.fileSubscription.unsubscribe();
  }

  public constructUrl = () => {
    return 'page=' + this.currentPage + '&size=' + this.pageSize + '&type=' + this.type + '&id=' + Number(this.objectId);
  }
  public getProvidedUrl = () => {
    return undefined;
  }

  public isProperlyConfigured = () => {
    return false;
  }

  public blobComponentInputs() {
    this.filesFlow();

    if ((this.objectId && Number(this.objectId) > 0) || this.isProperlyConfigured()) {
      let params = this.constructUrl();
      this.blobService.getBlobObjects(params, this.getProvidedUrl()).subscribe(response => {
        if (response?.body) {
          this.files = (response.body as any).content;
          this.files.forEach(file => {
            file.isUploaded = (file.status == UploadStatus.DONE);
            file.isFailed = (file.status == UploadStatus.FAILED);
            if (!this.hasInProgressFiles) {
              this.hasInProgressFiles = (file.status == UploadStatus.IN_PROGRESS) ? true : false;
            }
          });
          this.totalElements = response.body.totalElements;
          this.maxPage = response.body.totalPages;
        }
      }, error => {
      });
    }

  }

  private startScheduler(): Observable<number> {
    const intervalMinutes = 1; // Change this value to the desired interval in minutes
    const intervalMilliseconds = intervalMinutes * 60 * 1000; // Convert minutes to milliseconds

    return interval(intervalMilliseconds)
      .pipe(
        takeWhile(() => true)
      );
  }

  public filesFlow() {
    let filesTemp = this.files;
    this.fileIdForUnicity = "_blobInput_" + this.type + "_" + this.objectId;
    if (this.saveBlobOnTrigger && this.saveBlobSubject) {
      this.subscription = this.saveBlobSubject.subscribe((id) => {
        if (id && (this.objectId == undefined || (this.objectId && this.objectId == id))) {
          this.objectId = this.objectId ? this.objectId : id;
          this.pendingUploadFileList.forEach(bloblFormData => {
            bloblFormData.set('id', this.objectId);
          });
          this.uploadBlobFile();
        }
      });
    }

    if (this.reloadBlobSubject != undefined) {
      this.reloadBlobSubscription = this.reloadBlobSubject.subscribe((reload) => {
        if (reload) {
          this.currentPage = 0;
          this.blobComponentInputs();
        }
      });
    }
    this.maxFileSizeInMb = this.settingsService?.settings?.uploadedFileMaxSizeInMb;
  }

  public getAllowedExtensions() {
    let extensions = this.settingsService?.settings?.allowedFileExtensions;
    if (extensions) {
      extensions = "." + extensions.replaceAll(",", ",.");
    } else {
      extensions = "*";
    }
    return extensions;
  }

  public isFileValid(file: File): string {
    let error = undefined;
    if (file != undefined) {
      if (this.settingsService?.settings != undefined) {
        const fileExtension = file?.name?.split('.').pop();
        let fileSizeInMb = this.getFileSizeForComparaisonInMB(file.size);
        if (fileExtension && this.settingsService?.settings.allowedFileExtensions) {
          const allowedFileExtensions = this.settingsService?.settings.allowedFileExtensions.split(',');
          if (!allowedFileExtensions.includes(fileExtension))
            error = this._translateService.instant('snackbar.invalid-file')
              + this.settingsService?.settings.allowedImageExtensions
              + this._translateService.instant('snackbar.are-allowed');
        }
        if (!error && this.maxFileSizeInMb != undefined && fileSizeInMb > this.maxFileSizeInMb)
          error = this._translateService.instant('snackbar.file-too-large')
            + this.maxFileSizeInMb
            + this._translateService.instant('snackbar.mb');
      }
    } else {
      error = this._translateService.instant('snackbar.unable-load-file');
    }
    return error;
  }

  public getFileSizeForComparaisonInKB(fileSize: number) {
    return fileSize / 1024;
  }

  public getFileSizeForComparaisonInMB(fileSize: number) {
    return fileSize / 1024 / 1024;
  }

  public getAllowedExtensionsConfig() {
    let extensions = this.settingsService?.settings.allowedFileExtensions;
    return extensions ? extensions.replaceAll(",", ", ") : undefined;
  }

  public onFileChange(pFileList: File[]) {
    if (pFileList) {
      let file: File = pFileList[0];
      let error = this.isFileValid(file);
      if (error != undefined) {
        this._snackbar.open(error, this._translateService.instant('snackbar.unable-upload'), { duration: 2000 });
      } else {
        this.fileCounter++;
        const formData = new FormData();
        formData.append("uploadedFile", file);
        formData.append("type", this.type);
        formData.append("id", this.objectId);
        formData.append("uuid", '');
        formData.append("formId", this.fileCounter.toString());
        formData.append("isUploaded", "false");

        this.pendingUploadFileList.push(formData);

        let fileObj: any = {
          objectId: this.objectId,
          fileName: file.name,
          fileSize: file.size,
          formId: this.fileCounter.toString(),
          isUploaded: false
        };

        if (this.saveBlobOnTrigger) {
          if (this.files == undefined) this.files = [];
          this.files.push(fileObj);
        } else {
          this.uploadBlobFile();
        }
      }
    }
  }

  public uploadBlobFile(formData?) {
    let initialSize = this.pendingUploadFileList ? this.pendingUploadFileList.length : 0;
    let currentlyUploaded = 0;
    this.pendingUploadFileList?.forEach(bloblFormData => {
      this.blobService.postUploadBlob(bloblFormData).subscribe(response => {
        let blobObj: BlobObject = response.body;
        let fileObj: any = {
          id: blobObj.id,
          fileName: blobObj.fileName,
          fileSize: blobObj.fileSize,
          fileType: blobObj.fileType,
          objectId: blobObj.objectId,
          objectType: blobObj.objectType,
          isUploaded: "true"
        };

        this.files.push(fileObj);
        this._snackbar.open(this._translateService.instant('snackbar.successful-upload'), 'Close', {
          duration: 2000,
        });
        currentlyUploaded++;
        if (currentlyUploaded == initialSize) this.pendingUploadFileList = [];
      }, error => {
        currentlyUploaded++;
        if (currentlyUploaded == initialSize) this.pendingUploadFileList = [];
        this._snackbar.open(this._translateService.instant('snackbar.error-upload'), 'Close', {
          duration: 2000,
        });
      });
    });
  }

  public updatePendingUploadFileList(filesArray: any[]) {
    this.pendingUploadFileList = filesArray;
  }

  public updateFiles(filesArray: any[]) {
    this.files = filesArray;
  }

  public openConfirmDialog(pIndex): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'modal-xs',
      direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });
    dialogRef.componentInstance.fName = this.files[pIndex].fileName;
    dialogRef.componentInstance.fIndex = pIndex;

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.deleteFromArray(result);
      }
    });
  }

  public deleteFromArray(index) {
    this.files.splice(index, 1);
  }


  public resetPagination() {
    this.currentPage = 0;
    this.maxPage = 0;
    this.pageSize = 10;
  }

  public getNextPage() {
    if (this.currentPage < this.maxPage - 1) {
      this.currentPage++;
      this.blobComponentInputs();
    }
  }

  public getPreviousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.blobComponentInputs();
    }
  }

  public changePageSize(event: any) {
    this.resetPagination();
    if (event && event.value != undefined) this.pageSize = event.value;
    this.blobComponentInputs();
  }
}
