import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DATA_TABLE_COLUMN_FORMAT } from '../../enums/data-table-column-format';
import { LanguageHelper } from '../../helpers/lang.helper';
import { DataTableColumnDefinition } from '../../models/data-table-column-definition.model';
import { DataTable } from '../../models/data-table.model';
import { PageChangeEvent } from '../../models/page-change-event.interface';
import { PaginatorConfig } from '../../models/paginator-config.model';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm-component';
import { FormControl } from '@angular/forms';
import { FilterConfig } from '../../models/filter-config.model';
import { ConfigTypeEnum } from '../../enums/config.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'list-data-table',
  templateUrl: './list-data-table.component.html',
  styleUrls: ['./list-data-table.component.sass']
})
export class ListDataTableComponent implements OnInit {
  @Input('columnsDefinition') columnsDefinition?: DataTableColumnDefinition[];
  @Input('expColumnsDefinition') expColumnsDefinition?: DataTableColumnDefinition[];
  @Input('paginatorConfig') paginatorConfig: PaginatorConfig = new PaginatorConfig();
  @Input('filterConfig') filterConfig: FilterConfig = new FilterConfig();
  @Input('data') data?: DataTable<any>;
  @Input('currentRolePermissions') rolePermissions?: any;
  @Input('noResultMessage') noResultMessage = 'No Results Found.';
  @Output('more') more = new EventEmitter<PageChangeEvent>();
  @Output('clickAction') clickAction = new EventEmitter<any>();
  @Output('clickToggle') clickToggle = new EventEmitter<any>();

  columnFormat = DATA_TABLE_COLUMN_FORMAT;
  columnsProperty?: string[];
  private pageChangeEvent?: PageChangeEvent;
  languageHelper = LanguageHelper;
  filterControl: FormControl;
  configTypeEnum = ConfigTypeEnum;

  constructor(
    public dialog: MatDialog,
    private _translateService: TranslateService
  ) {
  }

  /** Table Related */

  ngOnChanges(changes: SimpleChanges) {
    const columnsChange = changes['columnsDefinition'];
    const dataChange = changes['data'];
    if (dataChange && this.data) {
      // Show and hide the load more button if all the rows were loaded
      this.fillData();

    }

    // Update the columns in case they changed
    if (columnsChange) {
      const columnsDidChange = columnsChange.previousValue !== columnsChange.currentValue;
      if (columnsDidChange) {
        this.extractValuableInfoFromColumns(this.columnsDefinition);
      }
    }
  }

  /**
   * init the paginator
   */
  ngOnInit() {
    this.initFilter();
  }

  getCurrentRows() {
    if (this.paginatorConfig.enabled !==this.configTypeEnum.NONE ) {
      let start = this.pageChangeEvent.page * this.pageChangeEvent.size;
      return this.data.filteredVisibleRows.slice(start, start + this.pageChangeEvent.size);
    }
    else {
      return this.data.filteredVisibleRows;
    }
  }

  private applyPagination() {
    if (this.paginatorConfig.enabled !== this.configTypeEnum.BE) {
      this.data.currentlyVisibleRows = this.getCurrentRows();
    }
    else {
      this.data.currentlyVisibleRows = this.data.filteredVisibleRows;
    }
  }

  /**
   * Clear the data table
   */
  clearDataTable() {
    if (this.filterConfig.enabled) {
      this.filterControl.setValue(null);
    }
    this.data = {
      visibleRows: [],
      filteredVisibleRows: [],
      currentlyVisibleRows: [],
      totalNumberOfVisibleRows: this.paginatorConfig.length
    };
  }

  /**
   * On reset table: reset data in table and paginator
   */
  resetDataTable() {
    this.clearDataTable();
    this.resetPaginator();
  }

  extractValuableInfoFromColumns(columns: DataTableColumnDefinition[]) {
    if (columns) {
      this.columnsProperty = columns.filter(col => col.visible).map(col => col.property);
    }
  }

  /**
   * Add a class to the cell element based on the column type
   * @param column
   * @returns
   */
  getCellClass(column: DataTableColumnDefinition) {
    if (column) {
      var result = {};
      switch (column.format) {
        case this.columnFormat.Date:
          result = { 'date-cell': true };
          break;
        case this.columnFormat.Link:
          result = { 'link-cell': true };
          break;
        case this.columnFormat.Toggle:
          result = { 'toggle-cell': true };
          break;
        case this.columnFormat.Action:
          const actionClass = `${column.property}-action-cell`;
          result[actionClass] = true;
        default:
          break;
      }
      if (column.customClasses) {
        column.customClasses.forEach(className => result[className] = true);
      }
    }
    return result;
  }

  /**
   * Format the displayed value based on the column type
   * @param column
   * @param row
   * @returns
   */
  formatCellValue(column: DataTableColumnDefinition, row: any): string {
    let columnValue = '';
    if (column) {
      switch (column.format) {
        case DATA_TABLE_COLUMN_FORMAT.Combination:
          columnValue = this.languageHelper.getValueFromCombinedProp(row, column.property);
          break;
        default:
          columnValue = this.languageHelper.getPropValue(row, column.property);
          break;
      }
    }
    return columnValue;
  }

  /**
   * Open an external link in new tab
   * @param link
   */
  actionClicked(col, row) {
    let action = { name: col.property, data: row }
    if (col.withConfirmation) {
      this.confirm().afterClosed().subscribe((isConfirmed) => {
        isConfirmed && this.clickAction.emit(action);
      });
    } else {
      this.clickAction.emit(action);
    }
  }

  toggleClicked(col, row, ev, componentRef) {
    ev.returnValue = false;
    let action = {
      name: col.property,
      data: row,
      value: !componentRef.checked,
      element: componentRef
    }
    if (col.withConfirmation) {
      this.confirm().afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          componentRef.toggle();
          this.clickToggle.emit(action);
        }
      });
    }
    else {
      componentRef.toggle();
      this.clickToggle.emit(action);
    }
  }


  confirm(): MatDialogRef<any> {
    return this.dialog.open(ConfirmComponent, {
      panelClass: "confirm-dialog",
      data: {
        confirmationMessage: 'siren-admin-translation.confirmation_dialog.are_you_sure',
      },
      direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });
  }

  /** Pagination Related */

  /**
   * Increment the offset to indicate the next page
   * Emit the event to get the data for next page
   */
  public clickPage(isNext, all?) {
    let offset = 0;
    if (all) {
      offset = isNext ? this.getPageNb() - 1 : 0;
    }
    else {
      offset = this.pageChangeEvent.page + (isNext ? 1 : -1);
    }
    this.setPageChangeEvent(
      {
        page: offset,
        size: this.pageChangeEvent.size
      }
    )
    if (this.paginatorConfig.enabled === this.configTypeEnum.BE) {
      this.more.emit(this.pageChangeEvent);
    }
    this.applyPagination();
  }

  resetPaginator() {
    this.setPageChangeEvent(this.paginatorConfig);
  }

  /**
   *
   * @param pageEvent
   */
  setPageChangeEvent(pageEvent: PageChangeEvent) {
    if (this.paginatorConfig.enabled !== ConfigTypeEnum.NONE) {
      this.pageChangeEvent = {
        page: pageEvent.page,
        size: pageEvent.size
      };
    }
  }

  getPageChangeEvent() {
    return this.pageChangeEvent;
  }

  getCheckedVal(col, row) {
    return col.property.startsWith('!') ? !row[col.property.substring(1)] : row[col.property];
  }

  public getPageNb() {
    return Math.ceil(this.data.totalNumberOfVisibleRows / this.pageChangeEvent.size);
  }

  /* filter */
  private initFilter() {
    if (this.filterConfig.enabled) {
      this.filterControl = new FormControl('');
    }
  }

  private applyFEFilter() {
    let columnsToFilter = this.columnsDefinition.filter(f => f.filterable);
    this.data.filteredVisibleRows = [];
    if (this.data.visibleRows.length != 0) {
      for (let element of this.data.visibleRows) {

        for (let col of columnsToFilter) {
          if (this.formatCellValue(col, element) && this.formatCellValue(col, element).toString().trim().toLowerCase().includes(this.filterControl.value.toString().trim().toLowerCase())) {
            this.data.filteredVisibleRows.push(element);
            break;
          }
        }
      }

    }
  }

  public executeFilter() {
    this.applyFEFilter();
    this.data.totalNumberOfVisibleRows = this.data.filteredVisibleRows.length;
    this.resetPaginator();
    this.applyPagination();
  }

  private fillData() {
    if (this.filterConfig.enabled && this.filterControl.value) {
      this.applyFEFilter();
    }
    else {
      this.data.filteredVisibleRows = this.data.visibleRows;
    }
    if (!this.pageChangeEvent && this.paginatorConfig.enabled !== this.configTypeEnum.NONE) {
      this.resetPaginator();
    }
    this.applyPagination();
  }

  public clearFilter() {
    if (this.filterConfig.enabled) {
      this.filterControl.setValue(null);
    }
    this.data.filteredVisibleRows = this.data.visibleRows;
    this.data.totalNumberOfVisibleRows = this.data.filteredVisibleRows.length;
    this.resetPaginator();
    this.applyPagination();
  }
}
