export interface PaginationParametersModel {
    start: number;
    count: number;
}

export const DefaultPaginationParametersModel = {
    start: 0,
    count: 6,
};
export const DefaultJudgePaginationParametersModel = {
    start: 0,
    count: 10,
};

export interface SpringPaginationParametersModel {
    page: number;
    size: number;
}

export const SpringDefaultPaginationParametersModel = {
    page: 0,
    size: 10,
};
export const SpringJudgeDefaultPaginationParametersModel = {
    page: 0,
    size: 10,
};