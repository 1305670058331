import { Injectable } from '@angular/core';
import { Menu } from './menu.model';
import { MarchModuleEnum, MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {


  public menuItems = [];
  constructor(private _authService: AuthenticationService,) {
    this.menuItems = this.getMenuItems();

  }

  public getMenuItems(): Menu[] {
    let menuItems: Array<Menu> = [
      // PROGRAMS
      {
        toggled: false,
        label: 'landing-page.membership',
        routerLink: '/enrollment/',
        iconClass: 'fa fa-th-large',
        permissions: [
          MarchPermissionEnum.BENEFICIARY_LIST.module,
          MarchPermissionEnum.PROGRAM_LIST.module,
          MarchPermissionEnum.ACTIVITY_LIST.module,
        ],
        items: [
          {
            label: 'landing-page.programs',
            routerLink: '/enrollment/program',
            permissions: [MarchPermissionEnum.PROGRAM_LIST.module],
            activeIconUrl: 'assets/icons/admin/program-icon-black.png',
            inactiveIconUrl: 'assets/icons/admin/program-icon-white.png',
            inactiveIconUrlMobile: 'assets/icons/admin/program-icon-black.png',
            activeIconUrlMobile: 'assets/icons/admin/program-icon-yellow.png',
          },
          {
            label: 'landing-page.beneficiaries',
            routerLink: '/enrollment/beneficiary',
            permissions: [MarchPermissionEnum.BENEFICIARY_LIST.module],
            activeIconUrl: 'assets/icons/menu/beneficiary-icon-black.svg',
            inactiveIconUrl: 'assets/icons/menu/beneficiary-icon-white.svg',
            activeIconUrlMobile: 'assets/icons/menu/beneficiary-icon-yellow.svg',
            inactiveIconUrlMobile: 'assets/icons/menu/beneficiary-icon-black.svg',
          },
          {
            label: 'landing-page.activities',
            routerLink: '/enrollment/activities',
            permissions: [MarchPermissionEnum.ACTIVITY_LIST.module],
            activeIconUrl: 'assets/icons/admin/activities-icon-black.png',
            inactiveIconUrl: 'assets/icons/admin/activities-icon-white.png',
            activeIconUrlMobile: 'assets/icons/menu/activities-icon-yellow.svg',
            inactiveIconUrlMobile: 'assets/icons/admin/activities-icon-black.png',
          }
        ],
      },
      // SURVEYS
      {
        toggled: false,
        label: 'landing-page.surveys',
        routerLink: '/survey/',
        activeIconUrl: 'assets/icons/menu/survey-icon-black.svg',
        inactiveIconUrl: 'assets/icons/menu/survey-icon-white.svg',
        activeIconUrlMobile: 'assets/icons/menu/survey-icon-yellow.svg',
        inactiveIconUrlMobile: 'assets/icons/menu/survey-icon-black.svg',
        permissions: [MarchPermissionEnum.SURVEY_LIST.module, MarchPermissionEnum.SURVEY_RESPONSE_LIST.module, MarchPermissionEnum.QUESTION_LIST.module],
        items: [
          {
            label: 'landing-page.surveys',
            routerLink: '/survey/list',
            permissions: [MarchPermissionEnum.SURVEY_LIST.module],
            activeIconUrl: 'assets/icons/menu/survey-icon-black.svg',
            inactiveIconUrl: 'assets/icons/menu/survey-icon-white.svg',
            activeIconUrlMobile: 'assets/icons/menu/survey-icon-yellow.svg',
            inactiveIconUrlMobile: 'assets/icons/menu/survey-icon-black.svg',
          },
          {
            label: 'landing-page.question-bank',
            routerLink: '/survey/question',
            permissions: [MarchPermissionEnum.QUESTION_LIST.module],
            iconClass: 'fa fa-bank',
          }
        ],
      },
      // Evaluation MODULE
      {
        toggled: false,
        iconClass: 'fa fa-clipboard',
        label: 'landing-page.assessment',
        routerLink: '/assessment/',
        permissions: [MarchPermissionEnum.ASSESSMENTS_LIST.module],
        items: [
          {
            label: 'landing-page.assessments-list',
            routerLink: '/assessment/list',
            permissions: [MarchPermissionEnum.ASSESSMENTS_LIST.module],
            iconClass: 'fa fa-clipboard',
          }
        ],
      },

      {
        toggled: false,
        iconClass: 'fa fa-exclamation-triangle',
        label: 'landing-page.incidents',
        routerLink: '/incident/',
        permissions: [MarchPermissionEnum.INCIDENT_LIST.module],
        items: [
          {
            label: 'landing-page.incidents',
            routerLink: '/incident/incidents',
            permissions: [MarchPermissionEnum.INCIDENT_LIST.module],
            iconClass: 'fa fa-exclamation-triangle',
          },
          {
            label: 'landing-page.incident-type',
            routerLink: '/incident/incident-type',
            permissions: [MarchPermissionEnum.INCIDENT_LIST.module],
            iconClass: 'fa fa-tag'
          },
        ],
      },

      // REPORT MODULE
      {
        toggled: false,
        iconClass: 'fa fa-file',
        label: 'landing-page.report',
        routerLink: '/report/',
        permissions: [MarchPermissionEnum.CUSTOM_REPORT_LIST.module],
        items: [
          {
            label: 'landing-page.report-list',
            routerLink: '/report/report-list',
            permissions: [MarchPermissionEnum.CUSTOM_REPORT_LIST.module],
            iconClass: 'fa fa-file',
          },
        ],
      },


      // SYSTEM ADMINISTRATION
      {
        toggled: false,
        iconClass: 'fa fa-cogs',
        label: 'landing-page.settings',
        routerLink: '/org-settings/',
        permissions: [
          MarchPermissionEnum.ORGANIZATION_LIST.module,
          MarchModuleEnum.LOOKUPS_MGT.module,
          MarchPermissionEnum.CLASS_TYPE_LIST.module,
          MarchPermissionEnum.CLASS_LIST.module,
          MarchPermissionEnum.ACTIVITY_CATEGORY_LIST.module,
          MarchPermissionEnum.ACTIVITY_LIST.module,
        ],
        items: [
          {
            label: 'landing-page.organizations',
            routerLink: '/org-settings/organization',
            permissions: [MarchPermissionEnum.ORGANIZATION_LIST.module],
            iconClass: 'fa fa-building',
          },
          {
            label: 'landing-page.classification',
            routerLink: '/org-settings/classification',
            permissions: [MarchPermissionEnum.CLASSIFICATION_LIST.module],
            iconClass: 'fa fa-bar-chart',
          },
          {
            label: 'landing-page.activities-settings',
            routerLink: '/org-settings/activities',
            permissions: [MarchPermissionEnum.ACTIVITY_LIST.module],
            iconClass: 'fa fa-sliders',
          },
          // {
          //   label: 'landing-page.classes',
          //   routerLink: '/org-settings/classes',
          //   permissions: [MarchPermissionEnum.CLASS_LIST.module],
          //   activeIconUrl: 'assets/icons/admin/class-icon-black.png',
          //   inactiveIconUrl: 'assets/icons/admin/class-icon-white.png',
          //   activeIconUrlMobile: 'assets/icons/admin/class-icon-yellow.png',
          //   inactiveIconUrlMobile: 'assets/icons/admin/class-icon-black.png',
          // },
          {
            label: 'landing-page.beneficiaries-status',
            routerLink: '/org-settings/beneficiaries-status',
            permissions: [MarchPermissionEnum.BENEFICIARY_STATUS_LIST.module],
            iconClass: 'fa fa-flag',
          },
          {
            label: 'landing-page.activity-categories',
            routerLink: '/org-settings/activity-category',
            permissions: [MarchPermissionEnum.ACTIVITY_CATEGORY_LIST.module],
            iconClass: 'fa fa-folder-open'
          },
          // {
          //   label: 'landing-page.class-types',
          //   routerLink: '/org-settings/classType',
          //   permissions: [MarchPermissionEnum.CLASS_TYPE_LIST.module],
          //   iconClass: 'fa fa-tag',
          // },
          {
            label: 'landing-page.legal-case-status',
            routerLink: '/org-settings/legalCaseStatuses',
            permissions: [MarchPermissionEnum.LOOKUPS_MGT.module],
            iconClass: 'fa fa-balance-scale',
          },
          {
            label: 'landing-page.achievement',
            routerLink: '/org-settings/achievement',
            permissions: [MarchPermissionEnum.LOOKUPS_MGT.module],
            iconClass: 'fa fa-trophy',
          },
          {
            label: 'landing-page.lookups',
            routerLink: '/org-settings/lookups',
            permissions: [MarchModuleEnum.LOOKUPS_MGT.module],
            iconClass: 'fa fa-database',
          },
          {
            label: 'landing-page.form-types',
            routerLink: '/org-settings/formType',
            permissions: [MarchPermissionEnum.BENEFICIARY_STATUS_LIST.module],//Same permission as Beneficiary status
            iconClass: 'fa fa-wpforms',
          },
        ],
      },
      // USER MANAGEMENT
      {
        toggled: false,
        iconClass: 'fa fa-users',
        label: 'landing-page.user-mgnt',
        routerLink: '/usermanagement/',
        permissions: [
          MarchPermissionEnum.USER_LIST.module,
          MarchPermissionEnum.ROLE_LIST.module,
          MarchPermissionEnum.LOGGING_ACTIVITY_LIST.module
        ],
        items: [
          {
            label: 'landing-page.users',
            routerLink: '/usermanagement/users/users',
            permissions: [MarchPermissionEnum.USER_LIST.module],
            iconClass: 'fa fa-user-plus',
          },
          {
            label: 'landing-page.roles',
            routerLink: '/usermanagement/users/roles',
            permissions: [MarchPermissionEnum.ROLE_LIST.module],
            iconClass: 'fa fa-shield',
          },
          {
            label: 'landing-page.user-activities',
            routerLink: '/usermanagement/activity',
            permissions: [MarchPermissionEnum.LOGGING_ACTIVITY_LIST.module],
            iconClass: 'fa fa-history',
          },
        ]
      }

      // // USER PROFILE
      // {
      //   toggled: false,
      //   iconClass: 'fa fa-user profile-picture-menu-icon',
      //   label: 'landing-page.profile',
      //   routerLink: '/profile',
      // },
    ];

    return menuItems;

  }


}

