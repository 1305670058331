import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { IncidentFeed } from 'src/app/models/incident/incident';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-incident-feed',
  templateUrl: './incident-feed.component.html',
  styleUrls: ['./incident-feed.component.sass']
})
export class IncidentFeedComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  passwordComplexityMessage:string;
  @Input() incident: IncidentFeed;
  myDateString :string;

  constructor(public readonly _translateService: TranslateService,private datePipe: DatePipe){
  }

  ngOnInit(): void {
    this.myDateString = this.incident && this.incident.dateTime ?
                      this.datePipe.transform(this.incident.dateTime, 'yyyy-MM-dd hh:mm:ss') : "";
  }

  getIncidentSubTitle(): string{
    let subTitle = "";
    if(this.incident){
      if(this.incident.incidentType) subTitle += this.incident.incidentType;
      if(this.incident.region) subTitle = this.appendToString(subTitle, ", ", this.incident.region);
      // subTitle = this.appendToString(subTitle, " - ", (this.incident.external ? "External": "Internal"));
    }
    return subTitle;
  }

  appendToString(base: string, divider: string, extension: string): string{
    let value: string = base;
    if(extension &&  extension.trim() != ""){
      if(value && value.trim() != "") value += divider;
      value += extension;
    }
    return value;
  }
}
