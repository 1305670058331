<div class="section-header" (click)="toogleContentExpansion()">
  <div class="section-title">
    <div>
      {{ section?.title }}<span class="section-title-index"> - {{'siren-survey-translation.survey-response-form.section' | translate}} {{ sectionIndex + 1 }}</span>
    </div>
    <div *ngIf="!isSubmitted">
      <i class="fa fa-chevron-down" *ngIf="!contentExpanded"></i>
      <i class="fa fa-chevron-up" *ngIf="contentExpanded"></i>
    </div>
  </div>

  <div  class="question-grade-div" *ngIf="isSubmitted">
    <div class="max-grade-display" *ngIf="getResponseTotalScore() != undefined">
      <span [ngClass]="getResponseTotalScore() >= section.passingGrade ? 'passed-grade' : 'not-passed-grade' ">{{ getResponseTotalScore() }}</span>
      <span> / </span>
      <span>{{ surveyMaxGrade }} - <span style="font-size: large; color: darkgrey;"> {{'siren-survey-translation.survey-response-form.weight' | translate}} {{ section.weight }}</span></span>
    </div>
    <div>
      <i class="fa fa-chevron-down" *ngIf="!contentExpanded"></i>
      <i class="fa fa-chevron-up" *ngIf="contentExpanded"></i>
    </div>
  </div>
</div>
<div class="section-question-container" [id]="'section-content-' + sectionIndex">
  <div class="section-question-input"  *ngFor="let surveyQuestion of section?.questions; let questionIndex = index;">
    <app-question-bank-response-form-embedded [isVisible]="isVisibleQuestion(surveyQuestion)" [surveyGraded]="surveyGraded"
      [isInvalid]="invalidQuestionResponseIds?.includes(surveyQuestion?.id)" [surveyMaxGrade]="surveyMaxGrade" [questionIndex]="questionIndex"
      [surveyQuestion]="surveyQuestion" [isEditMode]="isEditMode" [questionBankForm]="getQuestionFormGroup(surveyQuestion?.id)">
    </app-question-bank-response-form-embedded>
  </div>
</div>


<!-- <mat-accordion style="width: 100%;">
  <mat-expansion-panel (opened)="contentExpanded = true" (closed)="contentExpanded = false" style="border: none; box-shadow: none; padding: 5px">
    <mat-expansion-panel-header style="padding: 0px;">
      <div class="section-header">
        <div class="section-title">{{ section?.title }}<span class="section-title-index"> - Section {{ sectionIndex + 1 }}</span></div>
      </div>
    </mat-expansion-panel-header>
    <mat-expansion-panel-body style="padding: 0px;">
      <div class="section-question-container" >
        <div class="section-question-input"  *ngFor="let surveyQuestion of section?.questions; let questionIndex = index;">
          <app-question-bank-response-form-embedded [isVisible]="isVisibleQuestion(surveyQuestion)" [surveyGraded]="surveyGraded"
            [isInvalid]="invalidQuestionResponseIds?.includes(surveyQuestion?.id)" [surveyMaxGrade]="surveyMaxGrade" [questionIndex]="questionIndex"
            [surveyQuestion]="surveyQuestion" [isEditMode]="isEditMode" [questionBankForm]="getQuestionFormGroup(surveyQuestion?.id)">
          </app-question-bank-response-form-embedded>
        </div>
      </div>
    </mat-expansion-panel-body>
  </mat-expansion-panel>
</mat-accordion> -->
