import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { SettingsService } from '@siren-survey/app/shared/services/settings.service';
import { BlobObject } from '@siren-survey/app/models/blob/blob';
import { BlobService } from '@siren-survey/app/services/blob/blob.service';

@Component({
  selector: 'app-blob-file',
  templateUrl: './blob-file.component.html',
  styleUrls: ['./blob-file.component.sass']
})
export class BlobFileComponent implements OnInit {
  @Input() displayMode: string = 'LIST'; // by default List mode
  @Input() files: BlobObject[];
  @Input() showPreviewPanel: boolean = false;
  @Input() public canDeleteFile: boolean = false;
  @Input() public canDownloadFile: boolean = false;
  @Input() pendingUploadFileList: FormData[];
  @Input() hideTitle: boolean = false;
  @Output() filesUpdated = new EventEmitter<any[]>();
  @Output() pendingUploadFileListUpdated = new EventEmitter<any[]>();

  url: string;
  viewBlob: boolean = false;
  previewDisplayMode: typeof PreviewDisplayMode = PreviewDisplayMode;

  constructor(private _snackbar: MatSnackBar, private dialog: MatDialog,private dialogService: DialogService,
    public readonly _translateService: TranslateService, private blobService: BlobService, protected http: HttpClient, private settingsService: SettingsService){
  }

  ngOnInit(): void {
  }

  previewFile(file: BlobObject){
    this.viewBlob = true;
    this.url = file?.id ? this.settingsService.settings.backendBaseUrl + "/v1/blob/" + file.id : undefined;
  }

  deleteFile(file: BlobObject){
    this.dialogService.confirmDialog({"title": this._translateService.instant('popup-module.titles.delete-file'),
          "message": this._translateService.instant('popup-module.messages.permanent-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no') })
        .subscribe(response=>{
          if(response){
            if(file.id == undefined){
              this.files = this.files.filter(function(w:any){ return w.formId != file.formId });
              this.pendingUploadFileList = this.pendingUploadFileList.filter(function(w:any){ return w.formId != file.formId });
              this.filesUpdated.emit(this.files);
              this.pendingUploadFileListUpdated.emit(this.pendingUploadFileList);
              this.url = '';
              this.viewBlob = false;
            }
            else{
              this.blobService.deleteBlobObject(file.id).subscribe(response=>{
              this.files = this.files.filter(function(w:any){ return w.id != file.id });
              this.pendingUploadFileList = this.pendingUploadFileList.filter(function(w:any){ return w.id != file.id });
              this.filesUpdated.emit(this.files);
              this.pendingUploadFileListUpdated.emit(this.pendingUploadFileList);
              this.url = '';
              this.viewBlob = false;
              this._snackbar.open(this._translateService.instant('snackbar.successful-delete'), 'Close', {
                duration: 2000,
              });
              }, error=>{
                this._snackbar.open(this._translateService.instant('snackbar.error-delete'), 'Close', {
                  duration: 2000,
                });
              });
              }
          }
        });
  }

  downloadFile(file: BlobObject){
    this.blobService.downloadBlobObjects(file.id).subscribe((response) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response.body);
      link.download = file.fileName+"."+file.fileType;
      link.click();
      window.URL.revokeObjectURL(link.href);
      this._snackbar.open(this._translateService.instant('snackbar.successful-download'), 'Close', {
        duration: 2000,
      });
    },error=>{
      this._snackbar.open(this._translateService.instant('snackbar.error-download'), 'Close', {
        duration: 2000,
      });
    });
  }
}



export const PreviewDisplayMode: { [index: string]: string } = {
  THUMBNAILS:   'THUMBNAILS' ,
  LIST:   'LIST'
}
