import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { MyHttpHandler } from '@siren-survey/app/services/shared/http-handler.service';
import { IdNameObject } from 'src/app/models/shared/session.model';
import { MarchMyHttpHandler } from 'src/app/shared/services/march-http-handler.service';
import { PasswordComplexity } from '../../password-complexity';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  passwordComplexityMessage: string;

  constructor(public readonly _translateService: TranslateService, public passwordComplexity: PasswordComplexity){

    this.passwordComplexityMessage = this.passwordComplexity.getPasswordInfoMessage();
  }



}
