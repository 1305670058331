import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IAdminAuthenticationService } from '../services/adminAuthentication.interface';
// import { AuthService } from '../services/auth.service';

@Injectable()
export class CanActivatePageGuard implements CanLoad, CanActivate {
    constructor(public authService: IAdminAuthenticationService, private router: Router) { }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const canAccess = this.authService.isModuleAccessible(route?.data?.pageAccess);
        if (canAccess) {
            return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const canAccess = route?.data?.pageAccess?.key ? this.authService.userModuleAccessRightValidation(route?.data?.pageAccess) : this.authService.isModuleAccessible(route?.data?.pageAccess);
    
         if (canAccess) {
             return true;
         } else {
           this.router.navigate(['/']);
           return false;
         }
       }

}
