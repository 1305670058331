import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, Form, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { QuestionBankService } from '../../../services/question-bank.service';
import { DialogService } from '../../../services/shared/dialog.service';
import { OrderHandler } from '../../../utils/order-handler.utilities';
import { FormControllerGeneratorService } from '../../form-controller-generator.service';
import { SettingsService } from '../../../shared/services/settings.service';
import { EditQuestionNotification } from '../../../models/question/survey-question';
import { SurveyFormQuestionHandler } from '../../../services/survey-form/survey-form-question-handler.service';
import { OpenLinkedSurveyQuestionService } from '../../../services/shared/open-linked-survey-question.service';
import { QuestionStatement } from '../../../models/survey/survey';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';

@Component({
  selector: 'app-survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.sass']
})
export class SurveyQuestionComponent implements OnInit, AfterViewInit {
  @Input() questionFormGroup: FormGroup;
  @Input() isEditMode: boolean = true;
  @Input() gradedSurvey: boolean;
  @Input() questionsFormArray: FormArray;
  @Input() questionIndex: number;
  @Input() surveyId: number;
  @Input() sectionUuid: string;
  @Input() hasResponse: boolean = false;
  @Input() answerTypes: LookupModel[];

  canEditQuestion: boolean = true;
  editQuestionBankObject: boolean = false;

  @Input() surveyFormQuestionHandler: SurveyFormQuestionHandler;


  constructor(private parentFormGroup: FormGroupDirective, private dialogService: DialogService, private questionBankService: QuestionBankService,
    private matDialog: MatDialog, public readonly _translateService: TranslateService, private settingsService: SettingsService,
    private openLinkedSurveyQuestionServicesService: OpenLinkedSurveyQuestionService) {
  }

  getAssetsUrl(assetPath: string): string{
    let url = assetPath;
    if(this.settingsService && this.settingsService.settings){
      url = this.settingsService.settings.surveyAssetsBasePath ;
      if(!assetPath.startsWith("/")) url += "/";
      url += assetPath;
    }
    return url;
  }

  ngAfterViewInit(): void {
    this.canEditQuestion = this.questionFormGroup?.get('question')?.get('editable') ? this.questionFormGroup?.get('question')?.get('editable')?.getRawValue() : true;
  }

  ngOnInit(): void {
    let questionId = this.getQuestionId();
    this.editQuestionBankObject = this.isEditMode && (questionId == undefined || questionId <= 0);
  }

  questionChangeEventHandler(value: any){
    this.editQuestionBankObject = !value;
  }

  editable() : boolean{
    return this.isEditMode;
  }

  canDeleteQuestion(): boolean{
    return this.editable() && !this.hasResponse;
  }

  editQuestion(){
    this.editQuestionBankObject = true;
    this.questionFormGroup?.get('question')?.enable();
    this.surveyFormQuestionHandler.handleEditQuestionNotification({
      questionId: this.getQuestionId(),
      sectionUuid: this.sectionUuid,
      decreaseEditCount: false,
      encreaseEditCount: true,
      addToUnsavedQuestionList:false ,
      removeFromUnsavedQuestionList: false
    });
  }

  getQuestionId(){
    return this.questionFormGroup?.get('question')?.getRawValue()?.id;
  }

  deleteQuestion(){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-question'),
      "message": this._translateService.instant('siren-survey-translation.popup-module.messages.survey-question-delete'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response){
        this.surveyFormQuestionHandler.handleEditQuestionNotification({
          questionId: this.questionsFormArray?.at(this.questionIndex)?.get('question')?.getRawValue()?.id,
          sectionUuid: this.sectionUuid,
          decreaseEditCount: this.editQuestionBankObject,
          encreaseEditCount: false,
          addToUnsavedQuestionList:false ,
          removeFromUnsavedQuestionList: true
        });
        this.questionsFormArray?.removeAt(this.questionIndex);
      }
    });
  }

  moveUpQuestion() {
    this.mappingQuestionWithParentIds(false);
    let conditionAlert = false;
    const currentObject = this.questionsFormArray.controls.at(this.questionIndex).getRawValue();
    const previousObject = this.questionsFormArray.controls.at(this.questionIndex-1).getRawValue();
    if(currentObject && currentObject.parentIds != undefined && currentObject.condition != undefined){
      currentObject.parentIds.forEach(parentId => {
        if(!conditionAlert &&  previousObject.id == parentId){
          conditionAlert = true;
        }
      });
    }
    else if(!conditionAlert && previousObject && previousObject.parentIds != undefined ){
      previousObject.parentIds.forEach(parentId => {
        if(!conditionAlert &&  currentObject.id == parentId){
          conditionAlert = true;
        }
      });
    }

    if(conditionAlert){
      this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.order-cannot-be-used'),
        "message": this._translateService.instant('siren-survey-translation.popup-module.messages.order-cannot-be-used'),
        "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
        "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no'),
        "hideCancelButton": true })
      .subscribe(response=>{
        if(response) {
          //no action to be done
        }
      });
    }
    else{
      OrderHandler.moveUp(this.questionIndex, this.questionsFormArray);
    }

  }

  moveDownQuestion() {
    this.mappingQuestionWithParentIds(false);
    let conditionAlert = false;
    const currentObject = this.questionsFormArray.controls.at(this.questionIndex).getRawValue();
    const nextObject = this.questionsFormArray.controls.at(this.questionIndex+1).getRawValue();
    if(currentObject && currentObject.parentIds != undefined && currentObject.condition == undefined){
      currentObject.parentIds.forEach(parentId => {
        if(!conditionAlert &&  nextObject.id == parentId){
          conditionAlert = true;
        }
      });
    }
    else if(!conditionAlert && nextObject && nextObject.parentIds != undefined){
      nextObject.parentIds.forEach(parentId => {
        if(!conditionAlert &&  currentObject.id == parentId){
          conditionAlert = true;
        }
      });
    }

    if(conditionAlert){
      this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.order-cannot-be-used'),
        "message": this._translateService.instant('siren-survey-translation.popup-module.messages.order-cannot-be-used'),
        "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
        "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no'),
        "hideCancelButton": true })
      .subscribe(response=>{
        if(response) {
          OrderHandler.moveDown(this.questionIndex, this.questionsFormArray);
        }
      });
    }
    else{
      OrderHandler.moveDown(this.questionIndex, this.questionsFormArray);
    }
  }

  hasCondition(): boolean{
    let hasCondition = false;
    if(this.questionFormGroup){
      let condition = this.questionFormGroup?.get('condition') as FormGroup;
      hasCondition = condition != undefined;
    }
    return hasCondition;
  }

  canAddCondition(): boolean{
    return !this.hasCondition() && this.questionsFormArray && this.questionsFormArray?.length > 1 && !this.surveyFormQuestionHandler?.hasQuestionsInEditMode();
  }

  canDeleteCondition(): boolean{
    return this.editable();
  }

  addQuestionCondition(){
    let condition = this.questionFormGroup.get('condition') as FormGroup;
    if(condition == undefined){
      let newQuestionConditionForm = FormControllerGeneratorService.createConditionFormGroup(this.editable());
      this.questionFormGroup.addControl('condition', newQuestionConditionForm);
    }
  }

  deleteCondition(conditionIndex: number){
    this.dialogService.confirmDialog({"title": this._translateService.instant('siren-survey-translation.popup-module.titles.delete-condition'),
      "message": this._translateService.instant('siren-survey-translation.popup-module.messages.condition-delete'),
      "confirmText": this._translateService.instant('siren-survey-translation.popup-module.buttons.yes'),
      "cancelText": this._translateService.instant('siren-survey-translation.popup-module.buttons.no') })
    .subscribe(response=>{
      if(response){
          this.questionFormGroup?.removeControl('condition');
      }
    });
  }

  get optional() {
    return this.questionFormGroup.get('optional');
  }

  onToggleSlideToggle(event: any) {
    this.optional.setValue(!this.optional.value);
  }

  handleEditQuestionNotification(event: EditQuestionNotification){
    event.sectionUuid = this.sectionUuid;
    this.surveyFormQuestionHandler.handleEditQuestionNotification(event);
  }

  openLinkedSurveysTable(id: number){
    this.openLinkedSurveyQuestionServicesService.openLinkedSurveysTable(undefined, this.getQuestionId());
  }

  onQuestionStatementChange(questionStatement: QuestionStatement){
    this.mappingQuestionWithParentIds(true);
  }

  mappingQuestionWithParentIds(callReorder: boolean){
    this.questionsFormArray.controls.forEach(question => {
      if(question?.get('condition')?.get('blocks')){
        let parentIds: number[] = [];
          if(question?.get('condition')?.get('blocks')){
            (question?.get('condition')?.get('blocks') as FormArray).controls.forEach(block=>{
              if(block && block.get('statements')){
                (block.get('statements') as FormArray).controls.forEach(statement=>{
                  let sureveyQuestionid : number = statement.get('surveyQuestion').get('id').getRawValue();
                  parentIds.push(sureveyQuestionid);
                })
              }
            })
          }
        question.get('parentIds').patchValue(parentIds);
      }
    });

    if(callReorder) this.reorderQuestionsBasedOnParentIds(this.questionsFormArray);
  }

  reorderQuestionsBasedOnParentIds(questions: FormArray): void {
    const visited: Set<number> = new Set();

    // Create arrays to hold controls with and without dependencies
    const orderedControlsWithDeps: AbstractControl[] = [];
    const orderedControlsNoDeps: AbstractControl[] = [];
    // Perform topological sort for each control in the array
    for (let i = 0; i < questions.length; i++) {
      const control = questions.at(i) as FormGroup;
      if (!visited.has(control.getRawValue().id)) {
        this.orderQuestionsbyParentID(control, orderedControlsWithDeps,visited,questions);
      }
    }
    // Collect controls with no dependencies
    for (let i = 0; i < questions.length; i++) {
      const control = questions.at(i) as FormGroup;
      const id = control.get('id').value;
      if (!visited.has(id)) {
        orderedControlsNoDeps.push(control);
      }
    }
    // Clear the FormArray
    questions.clear();
    // Re-insert controls with dependencies first
    orderedControlsWithDeps.forEach((control) => questions.push(control));
    // Re-insert controls with no dependencies while preserving the original order
    orderedControlsNoDeps.forEach((control) => questions.push(control));
  }

  // Helper function to perform depth-first search
  orderQuestionsbyParentID(control: AbstractControl, orderedControls: AbstractControl[],visited: Set<number>,questions: FormArray) {
    if (!control || visited.has(control.getRawValue().id)) return;
    visited.add(control.getRawValue().id);
    // First visit the parentIds recursively
    if (control.get('parentIds').value) {
      for (const parentId of control.get('parentIds').value) {
        const parentControl = questions.controls.find((element) => element.get('id').getRawValue() === parentId)
        this.orderQuestionsbyParentID(parentControl, orderedControls,visited,questions);
      }
    }
    // Then add the control to the orderedControls array
    orderedControls.push(control);
  }
}
