import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SurveySelectionTableComponent, SurveySelectionTableInput } from '../../component/survey/survey-selection-table/survey-selection-table.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class OpenLinkedSurveyQuestionService {

  constructor(private dialog: MatDialog, private _translateService: TranslateService) { }

  openLinkedSurveysTable(surveyId: number,questionId: number){
    if(surveyId || questionId){
      let input: SurveySelectionTableInput = {
        questionId: questionId ? questionId : null,
        surveyId: surveyId ? surveyId : null
      };
      const surveySelectionTableDialog = this.dialog.open(SurveySelectionTableComponent, {
        "width": '6000px',
        "maxWidth": '80vw',
        "maxHeight": '80vh',
        "autoFocus": false,
        data: input,
        direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
      });
    }
  }

  closeLinkedSurveysTable(){
    this.dialog.closeAll();
  }
}
