import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableBaseClass } from '../../../shared/bases/data-table.base';
import { DetailsDialogComponent } from '../../../shared/components/details-dialog/details-dialog.component';
import { PageChangeEvent } from '../../../shared/models/page-change-event.interface';
import { PaginatorConfig } from '../../../shared/models/paginator-config.model';
import { SnackbarService } from '../../../shared/services/snackbar.service';
import { FilterConfig } from '../../models/filter-config.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-main-list',
  templateUrl: './main-list.component.html',
  styleUrls: ['./main-list.component.sass']
})
export class MainListComponent extends DataTableBaseClass<any> implements OnInit {

  @Input('columnDef') dataTableColumnsDefinition;
  @Input('detailsFields') detailsFields;
  @Input('dataTableData') dataTableData;
  @Input('formDisabled') formDisabled = false;
  @Input('service') service;
  @Input('lookups') lookups = {};
  @Input('paginatorConfig') paginatorConfig = new PaginatorConfig();
  @Input('filterConfig') filterConfig = new FilterConfig();
  @Input('title') title;

  constructor(
    private dialog: MatDialog,
    public injector: Injector,
    private _translateService: TranslateService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getListing(null, true);
  }

  getListing(paginationObj: PageChangeEvent, start: boolean) {
    this.service.getList().subscribe(
      (data) => {
        if (data && data.body && data.body.list.length > 0) {
          this.setDataTableData(data.body.list, start);
        }
      },
      (err) => { }
    );
  }

  public openDetails(element?: any) {
    const dialogRef = this.dialog.open(DetailsDialogComponent, {
      panelClass: "details-dialog",
      disableClose: true,
      width: '80%',
      maxWidth: '650px',
      data: {
        data: element,
        fields: this.detailsFields,
        lookups: this.lookups,
        isDisabled: element ? this.formDisabled : false
      },
      direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (element && result) {
        let objToPost = result;
        this.service.editRecord(result.REF, objToPost).subscribe(res => {
          let index = this.dataTableData.visibleRows.findIndex(x => x.REF === element.REF);
          index >= 0 ? this.dataTableData.visibleRows[index] = objToPost : null;
          this.snacks.openSnackBar("siren-admin-translation.snackbar.edit_success");
        }, error => {
          this.snacks.openWarningSnackBar("siren-admin-translation.snackbar.edit_error");
        })
      }
      else if (result) {
        let objToPost = result;
        objToPost.deprecated = false;
        this.service.addRecord(objToPost).subscribe(res => {
          if (res?.body) {
            this.dataTableData.visibleRows.unshift(res.body);
            this.dataTableData.totalNumberOfVisibleRows++;
          }
          this.snacks.openSnackBar("siren-admin-translation.snackbar.add_success");
        }, error => {
          this.snacks.openWarningSnackBar("siren-admin-translation.snackbar.add_error");
        });
      }
    });

  }

  actionClicked(ev) {
    switch (ev.name) {
      case 'edit':
        this.openDetails(ev.data);
        break;
      case 'delete':
        this.delete(ev.data);
        break;
      default:
        break;
    }
  }

  toggleClicked(ev) {
    let prop = ev.name.startsWith('!') ? ev.name.substring(1) : ev.name;
    ev.data[prop] = ev.name.startsWith('!') ? !ev.value : ev.value;

    this.service.editRecord(ev.data.REF, ev.data).subscribe(res => {
      let index = this.dataTableData.visibleRows.findIndex(x => x.REF === ev.data.REF);
      index >= 0 ? this.dataTableData.visibleRows[index][prop] = ev.data[prop] : null;
      this.snacks.openSnackBar("siren-admin-translation.snackbar.edit_success");
    }, error => {
      this.snacks.openWarningSnackBar("siren-admin-translation.snackbar.edit_error");
    });

  }

  private deprecatedToggle(object, prop, value) {

  }

  private delete(row) {
    this.service.deleteRecord(row.REF).subscribe(s => {
      this.snacks.openSnackBar("siren-admin-translation.snackbar.delete_success");
      let index = this.dataTableData.visibleRows.findIndex(x => x.REF === row.REF);
      index >= 0 && this.dataTableData.visibleRows.splice(index,1);
    }, error => {
      this.snacks.openWarningSnackBar("siren-admin-translation.snackbar.delete_error");
    });
  }
}
