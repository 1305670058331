<div class="list_wrapper">

    <content-header (newRecord)="openDetails()" [showBackButton]="false" [showAddButton]="true" [titleKey]="title"
        addLabel="siren-admin-translation.admin_module.sms_module.server.add"></content-header>


    <div class="table_wrapper">
        <list-data-table [data]="dataTableData" [columnsDefinition]="dataTableColumnsDefinition"
            [paginatorConfig]="paginatorConfig" (more)="onPage($event)" (clickAction)="actionClicked($event)"
            (clickToggle)="toggleClicked($event)" [filterConfig]="filterConfig">
        </list-data-table>
    </div>
</div>
