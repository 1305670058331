<form [formGroup]="form" (ngSubmit)="viewReport ? buildReport() : generateReport()">
  <div class="dialog-header">
    <span>{{'report.fields.generate-report' | translate}}</span>
    <mat-icon (click)="close()" class="close-btn">close</mat-icon>
  </div>
  <div class="dialog-container">
    <div class="col-lg-6 col-sm-6 col-12" *ngIf="showProgramAttendance">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="includeProgramAtttendance($event)">
        {{ 'report.fields.include-program-attendance' | translate }}
      </mat-checkbox>
      <app-info-tooltip message="{{'report.fields.include-program-tooltip' | translate}}"></app-info-tooltip>
    </div>
    <div class="col-12">
      <div class="row locate-inputs" >
        <div class="col-lg-3 col-sm-6 col-12">
          <mat-form-field class="full-width-element">
            <mat-label >
              {{'dashboard.fields.creation-date' | translate}}
            </mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate formControlName="fromDate">
              <input matEndDate formControlName="toDate">
            </mat-date-range-input>
            <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <app-multiple-searchable-selection
            [placeHolder]="'dashboard.fields.activities' | translate"
            formControlName="activitiesIds"
            [lookups]="activities"
            [formSubmitted]="formSubmitted"
            [formControl]="form.get('activitiesIds')"
          ></app-multiple-searchable-selection>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <app-multiple-searchable-selection
            [placeHolder]="'dashboard.fields.programs' | translate"
            formControlName="programIds"
            [lookups]="programs"
            [formSubmitted]="formSubmitted"
            [formControl]="form.get('programIds')"
          ></app-multiple-searchable-selection>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <mat-form-field class="full-width-element">
            <mat-label >{{ 'dashboard.fields.organizations' | translate }}</mat-label>
            <mat-select  formControlName="organizationId">
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let organization of organizations" [value]="organization.id">
                {{ organization?.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row locate-inputs" >
        <div class="col-lg-3 col-sm-6 col-12">
          <mat-form-field class="full-width-element">
            <mat-label >{{ 'dashboard.fields.beneficiary-status' | translate }}</mat-label>
            <mat-select  formControlName="beneficiaryStatusId">
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let beneficiaryStatus of beneficiaryStatuses" [value]="beneficiaryStatus.id">
                {{ beneficiaryStatus?.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <app-multiple-searchable-selection
            [placeHolder]="'dashboard.fields.tags' | translate"
            formControlName="tagsIds"
            [lookups]="tags"
            [formSubmitted]="formSubmitted"
            [formControl]="form.get('tagsIds')"
          ></app-multiple-searchable-selection>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <mat-form-field class="full-width-element">
            <mat-label >{{'dashboard.fields.tagOperator' | translate}} </mat-label>
            <mat-select formControlName="tagOperator">
              <mat-option [value]="'OR'">{{ 'dashboard.fields.or' | translate}}</mat-option>
              <mat-option [value]="'AND'">{{ 'dashboard.fields.and' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <mat-form-field class="full-width-element">
            <mat-label >{{'dashboard.fields.gender' | translate}} </mat-label>
            <mat-select formControlName="genderId">
              <mat-option [value]=""></mat-option>
              <mat-option *ngFor="let gender of genders" [value]="gender.id">
                {{ gender?.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="dialog-button-container">
      <button type="submit" [ngClass]="_translateService.currentLang == 'ar' ? 'btn-submit-rtl' : '' "
        class="btn btn-submit">
        <span style="white-space: nowrap;">{{viewReport ? ('report.fields.view-report' | translate) : ('global.save' | translate) }}</span>
      </button>
    </div>
  </div>
</form>
