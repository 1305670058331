import { IdObject } from "@siren-survey/app/models/shared/lookup.model";

export interface CustomReport {
  id: number;
  creationDate: string;
  title: string;
  uuid: string;
  disabled: boolean;
  includeProgramAttendance: boolean;
  customReportElements: CustomReportElement[];
}

export interface CustomReportElement{
  id: number;
  order: number;
  title: string;
  type: string;
  customReportSurveyElement?: CustomReportSurveyElement;
  customReportObjectElement?: CustomReportObjectElement;
  customReportFlagElement?: CustomReportFlagElement;
  customReportAssessmentElement?: CustomReportAssessmentElement;
  index?: number;
}

export enum ReportEnum {
  SURVEY = "SURVEY",
  BENEFICIARY = "BENEFICIARY",
  FLAGS = "FLAGS",
  ASSESSMENT = "RISK_ASSESSMENT",
  EVALUATION = "EVALUATION",
  OBJECT = "OBJECT"
}

export enum ReportObjectEnum {
  BENEFICIARY = "BENEFICIARY"
}

export enum ReportObjectAttributesEnum {
  FULL_NAME = "FULL_NAME",
  AGE = "AGE",
  CODE = "CODE",
  INIT_CODE = "INIT_CODE",
  NATIONALITY = "NATIONALITY",
  GENDER = "GENDER"
}

export enum ReportFlagEnum {
  ACHIEVEMENT = "ACHIEVEMENT",
  TAG = "TAG"
}

export class CustomReportSurveyElement{
  id: number;
  question: IdObject;
  surveyUuid: string;
  baselineValue: boolean;
}

export class CustomReportObjectElement{
  id: number;
  objectType: string;
  objectAttribute: string;
}

export class CustomReportFlagElement{
  id: number;
  objectType: string;
  objectId: number;
}

export class CustomReportAssessmentElement{
  id: number;
  customReportElement: IdObject;
  assessmentCriterion: IdObject;
  assessmentId: number;
}

export class StringValueObject {
  id: string;
  value: string;

  constructor(id: string, value: string) {
    this.id = id;
    this.value = value;
  }
}


export interface GenerateCustomReportObject {
  id: number;
  customReportId: number;
  beneficiaryIds?: string;
  programId?: number;
  programIds: string;
  beneficiaryFromDate?: string;
  beneficiaryToDate?: string;
  programStartDate: string;
  programEndDate: string;
  includeProgramAttendance: boolean;
  organizationId: number;
  fromDate: string;
  toDate: string;
  genderId: number;
  beneficiaryStatusId: number;
  tagsIds: string;
  tagOperator: string;
  activitiesIds: string;
  assessmentIds: string;
  surveyIds: string;
}

export interface CustomReportTable{
  order: number;
  values: CustomReportTableValue[];
  headers: CustomReportTableHeader[];
}

export interface CustomReportTableHeader{
  value: string;
  order: number;
}

export interface CustomReportTableValue{
  beneficiaryId: number;
  beneficiaryName: string;
  order: number;
  values: valueOrder[];
}

export interface valueOrder{
  value: string;
  order: number;
}
