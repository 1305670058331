<div class="fullwidth locateForm">
  <!-- <app-table-filter [filters]="filters" (filterOutput)="applyFilter($event)"></app-table-filter> -->
  <app-responsive-list-view
       [tableColumns]="_tableColumns"
       [sortingDataAccessor]="_sortingDataAccessor"
       [filteringPredicate]="_filteringPredicate"
       [service]="service"
       [withAdd]="canAdd"
       [hasListAccess]="hasListAccess"
       [maxCellStringLength]="80"
       [maxStringLength]="20"
       [inputFilterEventSubject]="filterEventSubject.asObservable()"
       (mode)="updateViewMode($event)"
       [_tableMenuAction]="_tableMenuAction"
       [booleanIconColumnDisplayList]="_booleanIconColumnDisplayList"
       (menuActionEventEmitter)="tableMenuActionHandler($event)"
       addButtonLabel="{{'siren-survey-translation.survey-table.create-survey' | translate}}"
       tableTitleLabel="{{ tableTitle }}"
       [inputReloadEventSubject]="reloadEventSubject.asObservable()"
       [showFilterButton]="true"
       [showBackButton]="false"
       [initiateSearchFromFilter]="initiateSearchFromFilter"
       initialPageSize="10">
    <app-table-filter filter-content [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter>
    <app-table-filter filter-content-mobile [filters]="filters" (filterOutput)="applyFilter($event)" [showFilterButton]="false" [predefinedFilter]="predefinedFilter" [initiateSearchFromFilter]="initiateSearchFromFilter"></app-table-filter>
  </app-responsive-list-view>
  <app-popup *ngIf="showAssignUsers" (closePopupEvent)="hideAssignUsers()">
    <app-survey-users [surveyId]="assignUsersSurveyId"></app-survey-users>
  </app-popup>
  <app-loading-spinner *ngIf="showLoadingSpinner"></app-loading-spinner>
</div>
