<div class="profile-picture">
  <div class="image-wrapper" [ngClass]="{ 'rounded': isRound }">
    <img *ngIf="profileImageUrl" [src]="profileImageUrl | mediaBlobService:true | async"  alt="Profile Picture" [ngClass]="{ 'rounded': isRound }">
    <mat-icon *ngIf="!profileImageUrl" class="profile-navbar-icon">account_circle</mat-icon>
  </div>
</div>
<div class="upload-centered" *ngIf="hasAccess">
  <input #fileInput type="file" (change)="onFileSelected($event)" style="display: none;" [accept]="getAllowedExtensions()" />
  <button (click)="fileInput.click();"  (click)="preventDefault($event)" class="btn btn-submit">{{ 'beneficiary-profile-picture.change-picture' | translate }}</button>
</div>
