import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { ResponsiveListInlineEditObject, TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IncidentType } from 'src/app/models/incident/incident';
import { IncidentTypeService } from 'src/app/services/incident/incident-type.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/services/shared/validators.service';
import { DatatableInlineEdit } from '@siren-survey/app/component/shared/datatable/datatable-inline-edit.service';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { SimpleIdName } from '@siren-auth/app/admin/user-roles/user-roles.model';

@Component({
  selector: 'app-incident-type-table',
  templateUrl: './incident-type-table.component.html',
  styleUrls: ['./incident-type-table.component.sass']
})
export class IncidentTypeTableComponent extends DatatableInlineEdit implements OnInit {

  tableTitleLabel: string = "Incident Types";

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  inlineEditEventSubject: Subject<ResponsiveListInlineEditObject> = new Subject<ResponsiveListInlineEditObject>();

  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;

  predefinedFilter: Map<string, any>;

  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  canAdd : boolean = false;
  hasListAccess : boolean = false;

  _booleanIconColumnDisplayList: string[] = [
    "deprecated"
  ]

  _tableColumns  : DataTableModel<IncidentType> []= [
    {  columnDef: 'name', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "incident-type.fields.name", header: this._translateService.instant('incident-type.fields.name'),  cell: (element: IncidentType) => `${element?.name}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'parentType', type: TableFilterTypeEnum.LookupObject, lookupName: "IncidentType", lookupsValueById: true, headerTranslationPath: "incident-type.fields.parent-type", header:  this._translateService.instant('incident-type.fields.parent-type'),  cell: (element: IncidentType) => `${element?.parentType ? element?.parentType?.name : ""}`, width: undefined,contentClass: 'leftAlign'},
    {  columnDef: 'deprecated', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "incident-type.fields.deprecated", header:  this._translateService.instant('incident-type.fields.deprecated'),  cell: (element: IncidentType) => `${element.deprecated}`, width: '150px'},
    {  columnDef: '_menu_inline', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: '',  cell: undefined, width: '25px'}
  ] ;


  filters: TableFilterElement[] = [
    { name: "name", translationPath: "incident-type.fields.name", displayName:  this._translateService.instant('incident-type.fields.name'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "deprecated", translationPath: "incident-type.fields.deprecated", displayName:  this._translateService.instant('incident-type.fields.deprecated'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "parentType.id", translationPath: "incident-type.fields.parent-type", displayName:  this._translateService.instant('incident-type.fields.parent-type'), type: TableFilterTypeEnum.LookupObject, lookupName: "IncidentType", lookupsValueById: true, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    ];

  _tableMenuAction: MenuActionObject[] = [
    { name:  this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_INCIDENT), accessRightExpressions: new Map<string, string>([ ["id", "id > 0"] ]) },
  ]

  _sortingDataAccessor : (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate : (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string{
    return this._translateService.instant('global.delete');
  }

  constructor(public service : IncidentTypeService, private router: Router, private route : ActivatedRoute, private readonly _translateService: TranslateService,
    private dialogService: DialogService, public lookupService: LookupService, public snackBar: MatSnackBar, public authService: AuthenticationService) {
    super();
    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch(property) {
        case 'parentType': return item[property]?.name;
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string)  => {
      const accumulator = (currentTerm: any, key: any) => {
        if(key && key == 'parentType'){
           return currentTerm + data[key]?.name;
        } else{
          return currentTerm + data[key];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ORGANIZATION_CREATE);
    this.hasListAccess = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.ORGANIZATION_LIST);
  }

  ngOnInit(): void {
  }

  lastCreatedId = 0;
  updateViewMode(event: TableAction){
    if(event.mode == TableAction.CREATE_MODE) {
      this.lastCreatedId--;
      let editFormGroup = new FormGroup({
        'id': new FormControl({value: this.lastCreatedId, disabled: true}),
        'name': new FormControl({value:'', disabled: false},  [Validators.required, CustomValidators.noWhitespaceValidator]),
        'deprecated': new FormControl({value: false, disabled: false}),
        'parentType': new FormControl({value: undefined, disabled: false}),
      });
      let inlineEditObject = new ResponsiveListInlineEditObject(this.lastCreatedId, this.lastCreatedId, editFormGroup,false);
      this.inlineEditEventSubject.next(inlineEditObject);
    }
  }

  applyFilter(event: TableFilterOutput){
    if(event){
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject){
    if(menuAction && menuAction.actionName && menuAction.object) this.handleInlineEditingRequests(menuAction,this.snackBar,this._translateService,true);
  }

  isInlineEditable(): boolean {
    return true;
  }

  getTableFormGroupModel(): FormGroup<any> {
    return new FormGroup({
      'id': new FormControl({value: this.lastCreatedId, disabled: true}),
      'name': new FormControl({value:'', disabled: false},  [Validators.required, CustomValidators.noWhitespaceValidator]),
      'deprecated': new FormControl({value: false, disabled: false}),
      'parentType': new FormControl({value: undefined, disabled: false}),
    });
  }

  triggerInlineEditEventSubject(inlineEditObject: ResponsiveListInlineEditObject): void {
    if(this.inlineEditEventSubject) this.inlineEditEventSubject.next(inlineEditObject);
  }

  getTableService() {
    return this.service;
  }

  usePostForUpdate(): boolean{
    return true;
  }

  getValidForm(menuAction:MenuActionEmittedObject): boolean {
    menuAction.form.markAllAsTouched();
    menuAction.form.updateValueAndValidity();
    return menuAction.form.valid;
  }

  withCheckUnicity(): boolean{
    return true;
  }

  objectClassName(): string{
    return "IncidentType";
  }
}
