<form #questionBankModal [formGroup]="questionBankForm" class="container"  [ngClass]="isEditMode ? 'margined-form' : '' ">
  <!-- (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" (focusout)="onQuestionFormFocusOut()" -->
  <div class="row">
    <div class="col-lg-7 col-sm-12 col-12" [ngClass]="!_isMobile ? (_translateService?.currentLang == 'ar' ? 'padding-left-25' : 'padding-right-25' ) : ''" style="margin-bottom: 15px;">
      <div class="full-width-element">
        <mat-form-field *ngIf="questionBankForm && questionBankForm?.get('question')" class="full-width-element">
          <mat-label>{{'siren-survey-translation.question-bank-form.fields.question' | translate}}</mat-label>
          <textarea  matInput type="text-area" autocomplete="off" min="0" class="" formControlName="question"></textarea>
        </mat-form-field>
      </div>
      <div class="full-width-element" formGroupName="answerType">
        <mat-form-field class="full-width-element">
          <mat-label class="">{{'siren-survey-translation.question-bank-form.fields.answerType' | translate}}</mat-label>
          <mat-select class="" formControlName="key">
            <mat-option *ngFor="let answerType of answerTypes" [value]="answerType.key">
              <img [src]="getAssetsUrl('assets/icons/long-text-choice-icon.svg')" *ngIf="answerType?.key != undefnied && answerType?.key == 'LONG_TEXT'" class="dropdownIcon dropdownIconMargin">
              <img [src]="getAssetsUrl('assets/icons/text-choice-icon.svg')" *ngIf="answerType?.key != undefnied && answerType?.key == 'TEXT'" class="dropdownIcon dropdownIconMargin">
              <img [src]="getAssetsUrl('assets/icons/single-choice-icon.svg')" *ngIf="answerType?.key != undefnied && answerType?.key == 'SINGLE_SELECT'" class="dropdownIcon dropdownIconMargin">
              <img [src]="getAssetsUrl('assets/icons/multi-choice-icon.svg')" *ngIf="answerType?.key != undefnied && answerType?.key == 'MULTI_SELECT'" class="dropdownIcon dropdownIconMargin">
              <i *ngIf="answerType?.key != undefnied && (answerType?.key == 'NUMBER' || answerType?.key == 'DECIMAL')" class="fa-input-numeric" class="dropdownIcon dropdownIconMargin"></i>
              <i *ngIf="answerType?.key == undefnied || (answerType?.key != undefnied && answerType?.key != 'NUMBER' && answerType?.key != 'DECIMAL'
                  && answerType?.key != 'MULTI_SELECT' && answerType?.key != 'SINGLE_SELECT') && answerType?.key != 'LONG_TEXT' && answerType?.key != 'TEXT'" class="dropdownIcon dropdownIconMargin"></i>
              {{ 'siren-survey-translation.answer-types.'+answerType.key.toLowerCase().replace("_","-") | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="margin-bottom: 15px;" *ngIf="questionBankForm.controls['answerType'].get('key').getRawValue() == 'SINGLE_SELECT' || questionBankForm.controls['answerType'].get('key').getRawValue() == 'MULTI_SELECT'">
        <div formArrayName="answers">
          <div *ngFor="let answer of getAnswersFormArray().controls; let i = index;" [formGroupName]="i" >
            <div class="answers-container" *ngIf="answer">
              <mat-form-field class="full-width-element">
                <mat-label>{{'siren-survey-translation.question-bank-form.fields.answer' | translate}}</mat-label>
                <input matInput formControlName="answer" >
              </mat-form-field>
              <mat-form-field *ngIf="questionBankForm.get('gradeByAnswer').value" class="grade-div">
                <mat-label>{{'siren-survey-translation.question-bank-form.fields.grade' | translate}}</mat-label>
                <input matInput type="number" autocomplete="off" min="0" class="" formControlName="grade" (keydown)="onKeyDownEvent($event)">
              </mat-form-field>
              <div *ngIf="isEditMode" class="flex-row">
                <button title="Delete Answer" class="button-flat" type="button" *ngIf='canDeleteAnswer()'  (click)="deleteAnswer(i)">
                  <img [src]="getAssetsUrl('assets/icons/x-icon.svg')" class="widthIcon">
                </button>
                <div class="answer-order-button-container">
                  <button type="button" title="Move Answer Up" class="button-flat" *ngIf="i > 0" (click)="moveUpAnswer(i)">
                    <i class="fa fa-chevron-up"></i>
                  </button>
                  <button type="button" title="Move Answer Down" class="button-flat" *ngIf="i < (getAnswersFormArray().controls.length -1)" (click)="moveDownAnswer(i)">
                    <i class="fa fa-chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button *ngIf="isEditMode" type="button" class="btn btn-md table-action-button" (click)="addAnswer()"><span class="fa fa-plus"></span> {{'siren-survey-translation.question-bank-form.fields.add-option' | translate}}</button>
      </div>
      <div *ngIf="isEditMode">
        <button *ngIf="!saveQuestionsOnFocusOut" type="button"(click)="save()" class="btn app-button-primary btn-save-embedded">{{'siren-survey-translation.global.save' | translate}}</button>
        <button type="button" *ngIf="questionBank && questionBank?.id && questionBank?.id > 0" (click)="cancel()" class="btn app-button-primary">{{'siren-survey-translation.global.cancel' | translate}}</button>
      </div>
      <div class="alert-box-action error-message error-message-style" *ngIf="error">
        {{error}}
      </div>
    </div>


    <div class="col-lg-5 col-sm-12 col-12 question-flags-container" [ngClass]="!_isMobile ? (_translateService?.currentLang == 'ar' ? 'padding-right-25' : 'padding-left-25' ) : ''" >
      <ng-content select="[survey-question-flags-content]"></ng-content>
      <hr class="divider">
      <div class="full-width-element">
        <mat-slide-toggle class=" color-slide-toogle" formControlName="withExplanation">
          <mat-label *ngIf="questionBankForm.get('withExplanation').value" class=" slide-toogle-label">{{'siren-survey-translation.question-bank-form.fields.with-explanation' | translate}}</mat-label>
          <mat-label *ngIf="!questionBankForm.get('withExplanation').value" class=" slide-toogle-label-danger">{{'siren-survey-translation.question-bank-form.fields.without-explanation' | translate}}</mat-label>
        </mat-slide-toggle>
      </div>
      <div class="full-width-element">
        <mat-slide-toggle class=" color-slide-toogle" formControlName="withOther">
          <mat-label *ngIf="questionBankForm.get('withOther').value" class=" slide-toogle-label">{{'siren-survey-translation.question-bank-form.fields.with-other' | translate}}</mat-label>
          <mat-label *ngIf="!questionBankForm.get('withOther').value" class=" slide-toogle-label-danger">{{'siren-survey-translation.question-bank-form.fields.without-other' | translate}}</mat-label>
        </mat-slide-toggle>
      </div>
      <div class="full-width-element">
        <mat-slide-toggle class=" color-slide-toogle" formControlName="gradeByAnswer" *ngIf="questionBankForm.controls['answerType'].get('key').getRawValue() == 'SINGLE_SELECT' || questionBankForm.controls['answerType'].get('key').getRawValue() == 'MULTI_SELECT'">
          <mat-label *ngIf="questionBankForm.get('gradeByAnswer').value" class=" slide-toogle-label">{{'siren-survey-translation.question-bank-form.fields.grade-by-answer' | translate}}</mat-label>
          <mat-label *ngIf="!questionBankForm.get('gradeByAnswer').value" class=" slide-toogle-label-danger">{{'siren-survey-translation.question-bank-form.fields.not-grade-by-answer' | translate}}</mat-label>
        </mat-slide-toggle>
      </div>
      <hr class="divider">
      <ng-content style="margin-top: 5px" select="[survey-question-conditions-content]"></ng-content>
    </div>
  </div>
</form>
