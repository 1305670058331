import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'arabicNumber',
pure: false
})
export class ArabicNumberPipe implements PipeTransform {
numbers = ["٠","١","٢","٣","٤","٥","٦","٧","٨","٩"]
  transform(value: any): string {
    let valueAsString = value?.toString() || ""
    let newValue = ""
    if(typeof valueAsString === 'string')
    {
      for(var i=0; i<valueAsString.length; i++)
      {
        const newdigit = this.numbers[valueAsString.charAt(i)] || valueAsString.charAt(i);
        newValue = newValue.concat(newdigit);
      }
    }
    return newValue;
  }
}