
<div [formGroup]="questionFormGroup" class="question-embedded-container">
  <div class="question-embedded-content">
    <div>
      <app-question-bank-form-embedded
        [canEditQuestion]="canEditQuestion"
        [isEditMode]="editQuestionBankObject"
        [questionBankForm]="questionFormGroup?.get('question')"
        [surveyId]="surveyId"
        (editQuestionNotificationEmitter)="handleEditQuestionNotification($event)"
        (questionChangeEventEmitter)="questionChangeEventHandler($event)"
        [answerTypes]="answerTypes">
        <!-- INJECTE QUESTION FLAGS CONTENT -->
        <div survey-question-flags-content >
          <div class="full-width-element">
            <mat-slide-toggle class=" color-slide-toogle" formControlName="graded" [disabled]="!gradedSurvey">
              <mat-label *ngIf="questionFormGroup?.get('graded')?.value" class=" slide-toogle-label" >{{'siren-survey-translation.question-bank-form.fields.graded' | translate}}</mat-label>
              <mat-label *ngIf="!questionFormGroup?.get('graded')?.value" class=" slide-toogle-label-danger" >{{'siren-survey-translation.question-bank-form.fields.not-graded' | translate}}</mat-label>
            </mat-slide-toggle>
          </div>
          <div class="full-width-element">
            <mat-slide-toggle class=" color-slide-toogle" [checked]="!optional.value" (change)="onToggleSlideToggle($event)">
              <mat-label *ngIf="questionFormGroup?.get('optional')?.value" class=" slide-toogle-label" >{{'siren-survey-translation.question-bank-form.fields.optional' | translate}}</mat-label>
              <mat-label *ngIf="!questionFormGroup?.get('optional')?.value" class=" slide-toogle-label-danger" >{{'siren-survey-translation.question-bank-form.fields.not-optional' | translate}}</mat-label>
            </mat-slide-toggle>
          </div>
        </div>
        <!-- INJECT QUESTION CONDITIONS CONTENT -->
        <div survey-question-conditions-content >
          <app-question-conditions *ngIf="questionFormGroup?.get('condition')"
            [hasUnsavedQuestionChanges]="hasUnsavedQuestionChanges"
            [isEditMode]="isEditMode"
            [parentQuestionControl]="questionFormGroup"
            [condition]="questionFormGroup?.get('condition')"
            [surveyFormQuestionHandler]="surveyFormQuestionHandler"
            (questionStatementChanged)="onQuestionStatementChange($event)">
          </app-question-conditions>
        </div>
      </app-question-bank-form-embedded>
    </div>
  </div>
  <div *ngIf="editable()" class="flexDisplay" [ngClass]="_translateService?.currentLang == 'ar' ? 'question-embedded-btn-container-rtl' : 'question-embedded-btn-container' ">
    <button *ngIf="getQuestionId() != undefined && getQuestionId() > 0" type="button" (click)="openLinkedSurveysTable(id);  $event.stopPropagation();" class="button-flat question-embedded-btn" style="color: #FFFFFF;" [disabled]="false">
      <i title="Linked Surveys" class="fa fa-external-link" style="font-size: 17px; margin-left: 5px; margin-right: 5px; width: 20px;" aria-hidden="true"></i>
    </button>
    <button type="button" *ngIf="!editQuestionBankObject && canEditQuestion" title="{{'siren-survey-translation.survey-section-form.button.edit-question' | translate}}" class="button-flat question-embedded-btn" (click)="editQuestion(); $event.stopPropagation();">
      <i class="fa fa-edit" style="font-size: 18px; color: #FFFFFF;"></i>
    </button>
    <button *ngIf='canDeleteQuestion()' title="{{'siren-survey-translation.survey-section-form.button.delete-question' | translate}}" class="button-flat question-embedded-btn" type="button" (click)="deleteQuestion();$event.stopPropagation();">
      <img [src]="getAssetsUrl('assets/icons/delete-icon-white.svg')" class="iconWidth">
    </button>
    <button type="button" title="{{'siren-survey-translation.survey-section-form.button.move-question-up' | translate}}" *ngIf="questionIndex > 0" class="button-flat question-embedded-btn" (click)="moveUpQuestion(); $event.stopPropagation();">
      <i class="fa fa-chevron-up" style="color: #FFFFFF; "></i>
    </button>
    <button type="button" title="{{'siren-survey-translation.survey-section-form.button.move-question-down' | translate}}" *ngIf="questionIndex < (questionsFormArray?.length -1)" class="button-flat question-embedded-btn table-action-button" (click)="moveDownQuestion(); $event.stopPropagation();">
      <i class="fa fa-chevron-down" style="color: #FFFFFF; "></i>
    </button>
    <button type="button" title="{{'siren-survey-translation.question-conditions-form.button.add-condition' | translate}}" *ngIf="editable() && canAddCondition(question)" class="btn btn-md table-action-button" (click)="addQuestionCondition(); $event.stopPropagation();">
      <img [src]="getAssetsUrl('assets/icons/add-condition-btn.svg')" class="iconWidth">
    </button>
    <button *ngIf='canDeleteCondition() && hasCondition(question)' title="{{'siren-survey-translation.question-conditions-form.button.delete-condition' | translate}}" class="button-flat table-action-button" type="button" (click)="deleteCondition(conditionIndex);$event.stopPropagation();">
      <img [src]="getAssetsUrl('assets/icons/x-icon-white.svg')" class="iconWidth">
    </button>
  </div>
</div>
