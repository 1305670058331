import { CommonModule } from '@angular/common';
import {  NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';

import { SurveyAppModule } from '@siren-survey/app/survey-app.module';
import { OrganizationAdminTableComponent } from '../admin/organization/orgaization-admin-table/organization-admin-table.component';
import { ActivitiesAdminTableComponent } from '../admin/program/activities-admin-table/activities-admin-table.component';
import { FileDragNDropDirective } from '../../shared/directives/file-drag-n-drop.directive';
import { ClassesAdminTableComponent } from '../admin/program/classes-admin-table/classes-admin-table.component';
import { ClassTypeAdminTableComponent } from '../admin/program/class-type-admin-table/class-type-admin-table.component';
import { ProgramTableComponent } from '../admin/program/program-table/program-table.component';
import { ProgramFormComponent } from '../admin/program/program-form/program-form.component';
import { LandingPageComponent } from './landing-page.component';
import { HomepageComponent } from '../homepage/homepage.component';
import { LandingRoutingModule } from './landing-routing.module';
import { AdminModule } from '@siren-auth/app/admin/admin.module';
import { ActivityCategoriesAdminTableComponent } from '../admin/program/activity-categories-admin-table/activity-categories-admin-table.component';
import { ChangePasswordComponent } from '../profile/change-password/change-password.component';
import { ChangeOrganizationComponent } from '../profile/change-organization/change-organization.component';
import { UserProfileComponent } from '../profile/user-profile/user-profile.component';
import { SnackbarService } from '@siren-survey/app/shared/services/snackbar.service';
import { ActivityExecutionListComponent } from '../admin/program/activity-execution-list/activity-execution-list.component';
import { ActivityExecutionFormComponent } from '../admin/program/activity-execution-form/activity-execution-form.component';
import { LookupsAdminTableComponent } from '../admin/organization/lookups-admin-table/lookups-admin-table.component';
import { AchievementTableComponent } from '../admin/achievement/achievement-table/achievement-table.component';
import { AchievementFormComponent } from '../admin/achievement/achievement-form/achievement-form.component';
import { MarchSurveyResponseHeaderComponent } from '../survey/march-survey-response/march-survey-response-header/march-survey-response-header.component';
import { UserActivityComponent } from '../admin/user-activity/user-activity.component';
import { IncidentFeedComponent } from '../incident/incident-feed/incident-feed.component';
import { IncidentTableComponent } from '../incident/incident-table/incident-table.component';
import { IncidentFormComponent } from '../incident/incident-form/incident-form.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { LegalCaseStatusTableComponent } from '../legal-case/legal-case-status-table/legal-case-status-table.component';
import { LegalCaseStatusFormComponent } from '../legal-case/legal-case-status-form/legal-case-status-form.component';
import { ProgramOrganizationFormComponent } from '../admin/program/program-organization-form/program-organization-form.component';
import { IncidentTypeTableComponent } from '../incident/incident-type-table/incident-type-table.component';
import { SurveySelectionComponent } from '../admin/program/survey-selection/survey-selection.component';
import { SurveyLinkSelectionComponent } from '../admin/program/survey-link-selection/survey-link-selection.component';
import { ReportListComponent } from '../report/report-list/report-list.component';
import { ReportElementComponent } from '../report/report-element/report-element.component';
import { ReportDataComponent } from '../report/report-data/report-data.component';
import { ReportElementListComponent } from '../report/report-element-list/report-element-list.component';
import { MarchDatepciker } from '../../shared/components/march-datepicker/march-datepicker.component';
import { CustomReportGenerateComponent } from '../report/custom-report-generate/custom-report-generate.component';
import { CustomReportGeneratedListComponent } from '../report/custom-report-generated-list/custom-report-generated-list.component';
import { SharedModule } from '@siren-survey/app/component/shared/shared.module';
import { MenuPermissionsDirective } from './permissions.directive';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { ActivityResultComponent } from '../enrollment/activity-result/activity-result.component';
import { BlobModule } from '../beneficiary/blob/blob.module';
import { CanActivatePageGuard } from 'src/app/shared/guards/can-activate-page.guard';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClassificationFormComponent } from '../admin/classification/classification-form/classification-form.component';
import { ClassificationTableComponent } from '../admin/classification/classification-table/classification-table.component';
import { ProgramBeneficiarySelectionListComponent } from '../beneficiary/program-beneficiary-selection-list/program-beneficiary-selection-list.component';
import { ImageFileTypeModule } from 'src/app/services/blob/image-file-type/image-file-type.module';
import { TitleComponent } from '../title/title.component';
import { IsOrganizationEnabledPipe } from 'src/app/services/admin/program/organization-enabled.pipe';
import { IsOrganizationSelectedPipe } from 'src/app/services/admin/program/organization-selected.pipe';
import { CustomReportTableComponent } from '../report/custom-report-table/custom-report-table.component';
import { NgChartsModule  } from 'ng2-charts';
import { ExternalModule } from 'src/app/shared/external/external.module';
import { FormTypeComponent } from '../admin/form-type/form-type.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    FileDragNDropDirective,
    OrganizationAdminTableComponent,
    ClassesAdminTableComponent,
    ClassTypeAdminTableComponent,
    ActivitiesAdminTableComponent,
    ProgramTableComponent,
    ProgramFormComponent,
    LandingPageComponent,
    HomepageComponent,
    FileDragNDropDirective,
    OrganizationAdminTableComponent,
    ClassesAdminTableComponent,
    ClassTypeAdminTableComponent,
    ActivitiesAdminTableComponent,
    ProgramTableComponent,
    ProgramFormComponent,
    ActivityCategoriesAdminTableComponent,
    ChangePasswordComponent,
    ChangeOrganizationComponent,
    UserProfileComponent,
    ActivityExecutionFormComponent,
    ActivityExecutionListComponent,
    LookupsAdminTableComponent,
    MarchSurveyResponseHeaderComponent,
    AchievementTableComponent,
    AchievementFormComponent,
    UserActivityComponent,
    IncidentFeedComponent,
    IncidentTableComponent,
    IncidentFormComponent,
    LegalCaseStatusTableComponent,
    LegalCaseStatusFormComponent,
    ProgramOrganizationFormComponent,
    IncidentTypeTableComponent,
    SurveySelectionComponent,
    SurveyLinkSelectionComponent,
    ReportListComponent,
    ReportElementComponent,
    ReportDataComponent,
    ReportElementListComponent,
    CustomReportGenerateComponent,
    CustomReportGeneratedListComponent,
    ActivityResultComponent,
    MenuPermissionsDirective,
    SideMenuComponent,
    ClassificationFormComponent,
    ClassificationTableComponent,
    ProgramBeneficiarySelectionListComponent,
    TitleComponent,
    IsOrganizationEnabledPipe,
    IsOrganizationSelectedPipe,
    CustomReportTableComponent,
    FormTypeComponent,
  ],
  imports: [
    CommonModule,
    LandingRoutingModule,
    BlobModule,
    MarchDatepciker,
    HttpClientModule,
    ExternalModule,
    ImageFileTypeModule,
    TranslateModule.forChild({
      defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			},
			extend: true
		}),
    SurveyAppModule,
    SharedModule,
    AdminModule,
    MatSnackBarModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    ColorPickerModule,
    NgChartsModule ,
  ],
  providers: [
    SnackbarService,
    CanActivatePageGuard,
    // { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    // { provide: ISurveyAuthenticationService, useExisting: AuthenticationService },
    // { provide: ISessionService, useExisting: SessionService },
    // { provide: APP_INITIALIZER, useFactory: appInit, deps: [SettingsHttpService], multi: true },
    // { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    // { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } } ,
    // // { provide: HTTP_INTERCEPTORS, useClass: AssetsPathHandlerInterceptor, multi: true }
    // DatePipe
  ]
})
export class LandingModule { }
