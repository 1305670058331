import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { MarchModuleEnum, MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Incident } from 'src/app/models/incident/incident';
import { IncidentService } from 'src/app/services/incident/incident.service';
import { IncidentFormComponent } from '../incident-form/incident-form.component';
import { DialogService } from '@siren-survey/app/services/shared/dialog.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-incident-table',
  templateUrl: './incident-table.component.html',
  styleUrls: ['./incident-table.component.sass']
})
export class IncidentTableComponent implements OnInit {
  tableTitleLabel: string;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;
  canAdd: boolean = false;
  hasListAccess: boolean = false;
  predefinedFilter: Map<string, any>;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;
  _booleanIconColumnDisplayList: string[] = [
    "external"
  ]

  _tableColumns: DataTableModel<Incident>[] = [
    { columnDef: 'title', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "incident.fields.title", header: this._translateService.instant('incident.fields.title'), cell: (element: Incident) => `${element?.title}`, width: undefined },
    { columnDef: 'description', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "incident.fields.description", header: this._translateService.instant('incident.fields.description'), cell: (element: Incident) => `${element?.description}`, width: undefined },
    { columnDef: 'incidentType', type: TableFilterTypeEnum.LookupObject, lookupName: "IncidentType", lookupsValueById: true, headerTranslationPath: "incident.fields.incident-type", header: this._translateService.instant('incident.fields.incident-type'), cell: (element: Incident) => `${element.incidentType?.name}`, width: undefined },
    { columnDef: 'region', type: TableFilterTypeEnum.Lookup, lookupName: "Region", lookupsValueById: true, headerTranslationPath: "incident.fields.region", header: this._translateService.instant('incident.fields.region'), cell: (element: Incident) => `${element.region?.name}`, width: undefined },
    { columnDef: 'external', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "incident.fields.is-external", header: this._translateService.instant('incident.fields.is-external'), cell: (element: Incident) => `${element.external}`, width: undefined },
    { columnDef: 'dateTime', type: TableFilterTypeEnum.Date, headerTranslationPath: "incident.fields.date-time", header: this._translateService.instant('incident.fields.date-time'), cell: (element: Incident) => `${element.dateTime ? this.datePipe.transform(element.dateTime, 'dd / MM / yyyy  HH:mm:ss') : "-"}`, width: undefined },
    { columnDef: 'creationDate', type: TableFilterTypeEnum.Date, headerTranslationPath: "incident.fields.creation-date", header: this._translateService.instant('incident.fields.creation-date'), cell: (element: Incident) => `${element.creationDate ? this.datePipe.transform(element.creationDate, 'dd / MM / yyyy') : "-"}`, width: undefined },
    { columnDef: '_menu_inline', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: '', cell: undefined, width: '25px' }
  ];


  filters: TableFilterElement[] = [
    { name: "title", translationPath: "incident.fields.title", displayName: this._translateService.instant('incident.fields.title'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "description", translationPath: "incident.fields.description", displayName: this._translateService.instant('incident.fields.description'), type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Like },
    { name: "incidentType.id", translationPath: "incident.fields.incident-type", displayName: this._translateService.instant('incident.fields.incident-type'), type: TableFilterTypeEnum.LookupObject, lookupName: "IncidentType", lookupsValueById: true, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "region.id", translationPath: "incident.fields.region", displayName: this._translateService.instant('incident.fields.region'), type: TableFilterTypeEnum.Lookup, lookupName: "Region", lookupsValueById: true, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "external", translationPath: "incident.fields.is-external", displayName: this._translateService.instant('incident.fields.is-external'), type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal },
    { name: "dateTime", translationPath: "incident.fields.date-time", displayName: this._translateService.instant('incident.fields.date-time'), type: TableFilterTypeEnum.DateRange, lookupName: undefined, lookupsValueById: undefined, value: undefined, valueFrom: undefined, valueTo: undefined, values: undefined, operator: FilterOperator.Equal, cssClass: 'col-xs-12 col-sm-6 col-md-4' },
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.delete'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "../../../../assets/icons/delete-icon.svg", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_INCIDENT), accessRightExpressions: new Map<string, string>([["id", "id > 0"]]) },
    { name: this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "", allowedAction: this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_INCIDENT), accessRightExpressions: new Map<string, string>([["id", "id > 0"]]) }
  ]

  _sortingDataAccessor: (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate: (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string {
    return this._translateService.instant('global.delete');
  }

  constructor(
    public service: IncidentService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly _translateService: TranslateService,
    public lookupService: LookupService,
    private dialog: MatDialog,
    public authService: AuthenticationService,
    private datePipe: DatePipe,
    public dialogService: DialogService,
    private snackbar: MatSnackBar
  ) {
    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch (property) {
        case 'region': return item[property]?.name;
        case 'incidentType': return item[property]?.name;
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string) => {
      const accumulator = (currentTerm: any, key: any) => {
        if (key && (key == 'region' || key == 'incidentType')) {
          return currentTerm + data[key]?.name;
        } else {
          return currentTerm + data[key];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.canAdd = this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_INCIDENT);
    this.hasListAccess = this.authService.isModuleAccessible(this.marchModuleEnum.INCIDENTS_MGT.module);

  }

  ngOnInit(): void {
  }

  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction) {
    if (event.mode == TableAction.EDIT_MODE && this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_INCIDENT)) {
      const dialogRef = this.openChangeStatusFormWindow(event?.id);
      dialogRef.afterClosed().subscribe((incident: Incident) => {
        this.reloadEventSubject.next(true);
      });
    } else if (event.mode == TableAction.CREATE_MODE) {
      const dialogRef = this.openChangeStatusFormWindow();
      dialogRef.afterClosed().subscribe((incident: Incident) => {
        this.reloadEventSubject.next(true);
      });
    }
  }

  applyFilter(event: TableFilterOutput) {
    if (event) {
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }

  openChangeStatusFormWindow(incidentId?: number): any {
    let input: IncidentFormInput = new IncidentFormInput(incidentId);
    const activityExecutionFormDialog = this.dialog.open(IncidentFormComponent, {
      "width": '6000px',
      "maxWidth": '80vw',
      "maxHeight": '80vh',
      "autoFocus": false,
      data: input,
      direction: this._translateService.currentLang == 'ar' ? 'rtl':'ltr'
    });
    return activityExecutionFormDialog;
  }

  tableMenuActionHandler(menuAction: MenuActionEmittedObject) {
    if (menuAction && menuAction.actionName && menuAction.object) {
      if (menuAction.actionName == "DELETE_ACTION") {
        this.dialogService.confirmDialog({
          "title": this._translateService.instant('popup-module.titles.delete-incident'),
          "message": this._translateService.instant('popup-module.messages.generic-delete'),
          "confirmText": this._translateService.instant('popup-module.buttons.yes'),
          "cancelText": this._translateService.instant('popup-module.buttons.no')
        })
          .subscribe(response => {
            if (response) {
              if (this.authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_INCIDENT)) {
                this.service.deleteObject(menuAction.object.id).subscribe(response => {
                  this.reloadEventSubject.next(true);
                }, error => {
                  this.snackbar.open(this._translateService.instant('snackbar.unable-delete'), null, { duration: 3000 });
                });
              }
            }
          });
      } else if (menuAction.actionName == "EDIT_ACTION") {
        const dialogRef = this.openChangeStatusFormWindow(menuAction.object.id);
        dialogRef.afterClosed().subscribe((incident: Incident) => {
          this.reloadEventSubject.next(true);
        });
      }
    }
  }

}

export class IncidentFormInput {
  constructor(public incidentId: number) { }
}
