export interface BlobObject {
  id: string;
  fileName: string;
  fileType: string;
  objectType: string;
  objectId: number;
  fileSize: number;
  formId?: string;
  totalElements?: number;
  totalPages?: number;
  size?: number;
  status: UploadStatus;
  isUploaded?: boolean;
  isFailed?: boolean;
  creationDate: Date;
}

export enum UploadStatus{
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED',
}
