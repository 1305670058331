<div class="pop-up-container">
    <div class="header">
        <div>
            <div>
                {{'siren-admin-translation.phone_verif.text' | translate }}
            </div>
        </div>
        <mat-icon (click)="close()" class="close-btn">close</mat-icon>
    </div>
    <div mat-dialog-content class="inputs-container">
        <form [formGroup]="numbersForm" class="form">
            <mat-form-field appearance="outline" class="code-inputs">
                <input matInput formControlName="number" autocomplete="off">
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions class="buttons-container">
        <button class="btns bg_dark_blue" mat-button (click)="send()"> {{'siren-admin-translation.phone_verif.send' |
            translate }}</button>
    </div>
</div>
