import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpHeaderResponse, HttpSentEvent, HttpProgressEvent, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const showLoader = req.headers.get('showLoader') ? req.headers.get('showLoader')?.toLowerCase() === 'true' : true;
        if (showLoader) {} // TODO: Display the spinner
        return next.handle(req).pipe(tap(response => {
            if (((response instanceof HttpResponse) || (response instanceof HttpErrorResponse)) && showLoader) {} //TODO: Hide the spinner
        }));
    }
}
