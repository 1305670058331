<div class="backdrop" (click)="onClose()"></div>
<div class="modal-form">
    <div class="modal-title">
        <h2 class="paddingPopUp">{{ 'global.error-message' | translate }}</h2>
    </div>
    <div class="padding16">
        <div class="messageDiv"> {{ message }}</div>
        <div class="alert-box-actions align-right">
            <button (click)="onClose()" class="btn btn-primary alert-box-action-button">Close</button>
        </div>
    </div>
</div>
