<div *ngIf="displayMode==previewDisplayMode.LIST">
  <h3 mat-subheader *ngIf="hideTitle">{{ 'blob-file.files' | translate }}</h3>

  <div class="flex-display">
    <div class="row fileItem">
      <div class="flex-file-list" *ngFor="let file of files; let ind = index">
        <span *ngIf="file.fileType">{{file.fileName +"." + file.fileType}}</span>

        <span *ngIf="!file.fileType">{{file.fileName}}</span>

        <div class="icons-file-list">
          <span class="file-size">{{file.fileSize | convertBytesToSize}} </span>

          <ng-container *ngIf="!file.isUploaded && file.id != undefined && !file.isFailed">
            <span class="file-status">{{ 'blob-file.in-progress' | translate }}</span>
          </ng-container>

          <ng-container *ngIf="!file.isUploaded && file.id != undefined && file.isFailed">
            <span class="file-status failed">{{ 'blob-file.failed' | translate }}</span>
          </ng-container>

          <ng-container *ngIf="file.id == undefined">
            <span class="file-status">{{ 'blob-file.pending' | translate }}</span>
          </ng-container>

          <ng-container *ngIf="file.isUploaded">
            <i class="fa fa-download display-icon" *ngIf="canDownloadFile"
              aria-hidden="true" (click)="downloadFile(file)"></i>
            <i class="fa fa-trash display-icon" *ngIf="canDeleteFile || file.isFailed"
              aria-hidden="true" (click)="deleteFile(file)"></i>
            <i *ngIf="showPreviewPanel &&  (file.fileType | imageFileType)" class="fa fa-eye display-icon"  aria-hidden="true"
              (click)="previewFile(file)"></i>
          </ng-container>
        </div>
      </div>

      <div *ngIf="this.files == undefined || this.files?.length == 0">
        <span class="empty-list-label">{{"siren-admin-translation.generic.no_files" | translate}}</span>
      </div>
    </div>

    <div class="previewPanel" *ngIf="showPreviewPanel && viewBlob">
      <img [src]="this.url | mediaBlobService:true | async" alt="Cannot preview this file" class="fix-heigt-width">
    </div>
  </div>
</div>
