import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { AssessmentService } from '../../../services/assessment/assessment.service';
import { HttpResponse } from '@angular/common/http';
import { AssessmentLookup } from '../models/assessment.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';

@Injectable({
  providedIn: 'root'
})
export class AssessmentResultResolver implements Resolve<{ lookups: HttpResponse<LookupModel[]>, assessments: HttpResponse<AssessmentLookup[]> }> {

  constructor(private _assessmentService: AssessmentService, private _lookupService : LookupService){
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ lookups: HttpResponse<LookupModel[]>, assessments: HttpResponse<AssessmentLookup[]> }> {
    const segments = route.url.map(segment => segment.path);
    return route.params.action == 'ADD_MODE' ?
      this._lookupService.getLookupsService(["AssessmentType"]).pipe(map((allResponses: any) => {
        return {
          lookups: allResponses?.body,
          assessments: new HttpResponse<AssessmentLookup[]>({ body: [] }) // You need to populate this with the appropriate data
        };
      }))
    : this._assessmentService.getLinkedAssessmentList(route.params.id,segments.indexOf('activity') >= 0 ? 'activityExecution' : 'program').pipe(map((allResponses: any) => {
      return {
        lookups: [],
        assessments: new HttpResponse<AssessmentLookup[]>({ body: allResponses?.body }) // You need to populate this with the appropriate data
      };
    }))
    ;
  }
}

